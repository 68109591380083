import * as types from '@/vuex/mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Location Store
 *
 * @returns {{locale: string}}
 */
const defaultState = () => {
  return {
    locale: null
  }
}

const state = defaultState()

const getters = {
  get (state) {
    return state.locale
  }
}

const actions = {
  async init ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.language, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.LANGUAGE_SET, response.data.preferred_language)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async set ({ rootState, commit }, language) {
    apiMethods.request('patch', routes.language, {
      preferred_language: language
    }, rootState.backendAPI.login.token)
    commit(types.LANGUAGE_SET, language)
  }
}

const mutations = {
  [types.LANGUAGE_SET] (state, language) {
    state.locale = language
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
