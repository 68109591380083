<template>
  <g v-if="underlayImage && underlayShow">
    <image
      id="surfaceUnderlay"
      :height="height"
      :width="width"
      :x="x"
      :xlink:href="underlayImage.src"
      :y="y"
      opacity="0.5"
    />
  </g>
</template>

<script>
import { mapState } from 'vuex'
// import * as mutationTypes from '@/vuex/mutation-types'

/**
     * Shows the image in the background of the drawing area
     * displayName Underlay
     */
export default {
  name: 'Underlay',
  computed: {
    ...mapState({
      underlayImage: state => state.files.currentSurfaceUnderlay,
      underlayShow: state => state.files.show.underlay
    }),
    width () {
      return this.underlayImage.width ? this.underlayImage.width : null
    },
    height () {
      return this.underlayImage.height ? this.underlayImage.height : null
    },
    x () {
      return 0
    },
    y () {
      return 0
    }
  }
}
</script>
