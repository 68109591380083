<template>
  <div
    id="app"
    class="u-bgcolor-w2"
  >
    <bug-report-dialog v-if="isDialogVisible('bugReport')" />
    <RequestErrorDialog />
    <RequestOverlay />
    <router-view />
  </div>
</template>

<script>
import './assets/scss/main.scss'
import * as mutationTypes from '@/vuex/mutation-types'
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import RequestOverlay from '@/components/common/RequestOverlay'
import RequestErrorDialog from '@/components/common/RequestErrorDialog'
import BugReportDialog from '@/components/editor/dialogs/BugReport'
import MixinDialogHandler from '@/mixins/dialogHandler'

export default {
  name: 'MelosProjectPlanner',
  components: { BugReportDialog, RequestOverlay, RequestErrorDialog },
  mixins: [MixinDialogHandler],
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      sidenav: state => state.navigation.sidenav,
      requestError: state => state.backendAPI.error
    }),
    ...mapGetters({
      getTheme: 'gui/getTheme',
      isDialogVisible: 'dialogs/isDialogVisible'
    })
  },
  watch: {
    requestError (val) {
      if (val !== null) {
        this.$_mx_dialogHandler_openDialog({ dialog: 'requestError', onOff: true })
      }
    },
    getTheme (val) {
      this.setTheme(val)
    }
  },
  created () {
    this.$store.watch((state) => {
      return state.backendAPI.group.settings
    }, (settings, old) => {
      if (settings) {
        this.$world.initCurrency(this.$settings.currencyShort)
        this.$units.init(this.$settings.currencyShort)
      } else {
        this.$world.initCurrency('USD')
        this.$units.init('USD')
      }
    })
  },
  mounted () {
    document.oncontextmenu = document.body.oncontextmenu = function () {
      return false
    }
    document.body.ondrop = function (evt) {
      evt.preventDefault()
      evt.stopPropagation()
    }
    this.navigator({
      is_chrome: navigator.userAgent.indexOf('Chrome') > -1,
      is_explorer: navigator.userAgent.indexOf('MSIE') > -1 || navigator.userAgent.indexOf('Edge') > -1,
      is_firefox: navigator.userAgent.indexOf('Firefox') > -1,
      is_safari: navigator.userAgent.indexOf('Safari') > -1,
      is_opera: navigator.userAgent.toLowerCase().indexOf('op') > -1
    })
    document.addEventListener('click', this.documentClick)
    this.setTheme()
  },
  methods: {
    ...mapMutations({
      setSideNav: 'navigation/' + mutationTypes.HEADNAV_SET_SIDENAV
    }),
    ...mapActions({
      navigator: 'editor/browser',
      setTheme: 'gui/setTheme'
    }),
    documentClick (evt) {
      const element = document.getElementById('sub-nav-button-head-side-nav')
      let target = null
      if (evt && evt.target) {
        target = evt.target
      }
      if (
        (element &&
          element !== target &&
          !element.contains(target) &&
          this.sidenav === 'editor') ||
        (evt.target.id === 'head-side-nav-close')
      ) {
        this.setSideNav(null)
      }
    }
  }
}
</script>

<style lang="scss">
    * {
        -webkit-user-select: none; /* Chrome 49+ */
        -moz-user-select: none; /* Firefox 43+ */
        -ms-user-select: none; /* No support yet */
        user-select: none; /* Likely future */
    }

    text, tspan {
        font-family: 'Roboto', sans-serif !important;
        font-weight: bold !important;
    }

    .no-pointer-events {
        pointer-events: none;
    }
</style>
