/**
 * !!! Important !!!
 * Add new mutations to: ./tests/unit/mutations/backendAPI.spec.js
 */

// COMMON
export const COMMON_SET_ERROR = 'COMMON_SET_ERROR'
export const COMMON_SET_LOGIN = 'COMMON_SET_LOGIN'
export const COMMON_SET_LOGOUT = 'COMMON_SET_LOGOUT'

// RESET VUEX STATES
export const RESET_EXPORT_STATE = 'RESET_EXPORT_STATE'
export const RESET_USER_STATE = 'RESET_USER_STATE'
export const RESET_GROUP_STATE = 'RESET_GROUP_STATE'
export const RESET_ADMIN_STATE = 'RESET_ADMIN_STATE'
export const RESET_MANUFACTURE_STATE = 'RESET_MANUFACTURE_STATE'
export const RESET_DISTRIBUTOR_STATE = 'RESET_DISTRIBUTOR_STATE'
export const RESET_EMPLOYEE_STATE = 'RESET_EMPLOYEE_STATE'
export const RESET_LOCATION_STATE = 'RESET_LOCATION_STATE'
export const RESET_CLIENT_STATE = 'RESET_CLIENT_STATE'
export const RESET_PROJECT_STATE = 'RESET_PROJECT_STATE'
export const RESET_SUBPRODUCT_STATE = 'RESET_SUBPRODUCT_STATE'
export const RESET_TURF_STATE = 'RESET_TURF_STATE'
export const RESET_INFILL_STATE = 'RESET_INFILL_STATE'
export const RESET_SUBBASE_STATE = 'RESET_SUBBASE_STATE'
export const RESET_PAD_STATE = 'RESET_PAD_STATE'
export const RESET_PEDESTAL_STATE = 'RESET_PEDESTAL_STATE'
export const RESET_SEAMTAPE_STATE = 'RESET_SEAMTAPE_STATE'
export const RESET_ADHESIVES_STATE = 'RESET_ADHESIVES_STATE'
export const RESET_STATS_STATE = 'RESET_STATS_STATE'

// RESET VUEX STATES ONLY PIM DATA
export const RESET_SUBPRODUCT_PIM_STATE = 'RESET_SUBPRODUCT_PIM_STATE'
export const RESET_TURF_PIM_STATE = 'RESET_TURF_PIM_STATE'
export const RESET_INFILL_PIM_STATE = 'RESET_INFILL_PIM_STATE'
export const RESET_SUBBASE_PIM_STATE = 'RESET_SUBBASE_PIM_STATE'
export const RESET_PAD_PIM_STATE = 'RESET_PAD_PIM_STATE'
export const RESET_PEDESTAL_PIM_STATE = 'RESET_PEDESTAL_PIM_STATE'
export const RESET_SEAMTAPE_PIM_STATE = 'RESET_SEAMTAPE_PIM_STATE'
export const RESET_ADHESIVES_PIM_STATE = 'RESET_ADHESIVES_PIM_STATE'

// RESET VUEX STATES LOADED PROJECT
export const RESET_LOADED_PROJECT_STATES = 'RESET_LOADED_PROJECT_STATES'

// 1.1. GROUP API
export const GROUP_SET_GROUPS = 'GROUP_SET_GROUPS'
export const GROUP_ADD_GROUP = 'GROUP_ADD_GROUP'
export const GROUP_DELETE_GROUP = 'GROUP_DELETE_GROUP'
export const GROUP_UPDATE_GROUP = 'GROUP_UPDATE_GROUP'
export const GROUP_SET_SETTINGS = 'GROUP_SET_SETTINGS'

// 1.2. ADMIN
export const ADMIN_SET_ADMIN = 'ADMIN_SET_ADMIN'
export const ADMIN_ADD_ADMIN = 'ADMIN_ADD_ADMIN'
export const ADMIN_UPDATE_ADMIN = 'ADMIN_UPDATE_ADMIN'
export const ADMIN_DELETE_ADMIN = 'ADMIN_DELETE_ADMIN'

// 1.3. MANUFACTURE
export const MANUFACTURE_SET_MANUFACTURE = 'MANUFACTURE_SET_MANUFACTURE'
export const MANUFACTURE_ADD_MANUFACTURE = 'MANUFACTURE_ADD_MANUFACTURE'
export const MANUFACTURE_UPDATE_MANUFACTURE = 'MANUFACTURE_UPDATE_MANUFACTURE'
export const MANUFACTURE_DELETE_MANUFACTURE = 'MANUFACTURE_DELETE_MANUFACTURE'

// 1.4. DISTRIBUTOR
export const DISTRIBUTOR_SET_DISTRIBUTORS = 'DISTRIBUTOR_SET_DISTRIBUTORS'
export const DISTRIBUTOR_ADD_DISTRIBUTOR = 'DISTRIBUTOR_ADD_DISTRIBUTOR'
export const DISTRIBUTOR_UPDATE_DISTRIBUTOR = 'DISTRIBUTOR_UPDATE_DISTRIBUTOR'
export const DISTRIBUTOR_DELETE_DISTRIBUTOR = 'DISTRIBUTOR_DELETE_DISTRIBUTOR'

// 1.5. EMPLOYEE API
export const EMPLOYEE_SET_EMPLOYEES = 'EMPLOYEE_SET_EMPLOYEES'
export const EMPLOYEE_ADD_EMPLOYEE = 'EMPLOYEE_ADD_EMPLOYEE'
export const EMPLOYEE_UPDATE_EMPLOYEE = 'EMPLOYEE_UPDATE_EMPLOYEE'
export const EMPLOYEE_DELETE_EMPLOYEE = 'EMPLOYEE_DELETE_EMPLOYEE'

// 1.6. GLOBAL USER API
export const GLOBAL_USER_SET_ME = 'GLOBAL_USER_SET_ME'
export const GLOBAL_USER_READ_USER = 'GLOBAL_USER_READ_USER'
export const GLOBAL_USER_UPDATE_USER = 'GLOBAL_USER_UPDATE_USER'

// 1.7. LOCATION API
export const LOCATION_SET_LOCATIONS = 'LOCATION_SET_LOCATIONS'
export const LOCATION_ADD_LOCATION = 'LOCATION_ADD_LOCATION'
export const LOCATION_UPDATE_LOCATION = 'LOCATION_UPDATE_LOCATION'
export const LOCATION_DELETE_LOCATION = 'LOCATION_DELETE_LOCATION'

// 1.7.1. DISTRIBUTOR PRODUCT API (LOCATION BASED)
export const PRODUCT_SET_LOCATION_ID = 'PRODUCT_SET_LOCATION_ID'

// GLOBAL SUB-PRODUCTS HANDLER (1.7.2 - 1.7.8)

// 1.7.2. DISTRIBUTOR TURF API

export const PRODUCT_TURF_SET_TURFS = 'PRODUCT_TURF_SET_TURFS'
export const PRODUCT_TURF_SET_SPECIAL_TURFS = 'PRODUCT_TURF_SET_SPECIAL_TURFS'
export const PRODUCT_TURF_ADD_SPECIAL_TURF = 'PRODUCT_TURF_ADD_SPECIAL_TURF'
export const PRODUCT_TURF_UPDATE_SPECIAL_TURF = 'PRODUCT_TURF_UPDATE_SPECIAL_TURF'
export const PRODUCT_TURF_SET_SPECIAL_TURF_TO_UPDATE = 'PRODUCT_TURF_SET_SPECIAL_TURF_TO_UPDATE'
export const PRODUCT_TURF_DELETE_SPECIAL_TURF = 'PRODUCT_TURF_DELETE_SPECIAL_TURF'
export const PRODUCT_TURF_SET_META = 'PRODUCT_TURF_SET_META'

// 1.7.3. DISTRIBUTOR INFILL API

export const PRODUCT_INFILL_SET_INFILLS = 'PRODUCT_INFILL_SET_INFILLS'
export const PRODUCT_INFILL_SET_SPECIAL_INFILLS = 'PRODUCT_INFILL_SET_SPECIAL_INFILLS'
export const PRODUCT_INFILL_ADD_SPECIAL_INFILL = 'PRODUCT_INFILL_ADD_SPECIAL_INFILL'
export const PRODUCT_INFILL_UPDATE_SPECIAL_INFILL = 'PRODUCT_INFILL_UPDATE_SPECIAL_INFILL'
export const PRODUCT_INFILL_SET_SPECIAL_INFILL_TO_UPDATE = 'PRODUCT_INFILL_SET_SPECIAL_INFILL_TO_UPDATE'
export const PRODUCT_INFILL_DELETE_SPECIAL_INFILL = 'PRODUCT_INFILL_DELETE_SPECIAL_INFILL'
export const PRODUCT_INFILL_SET_META = 'PRODUCT_INFILL_SET_META'

// 1.7.4. DISTRIBUTOR SUBBASE API
export const PRODUCT_SUBBASE_SET_SUBBASES = 'PRODUCT_SUBBASE_SET_SUBBASES'
export const PRODUCT_SUBBASE_SET_SPECIAL_SUBBASES = 'PRODUCT_SUBBASE_SET_SPECIAL_SUBBASES'
export const PRODUCT_SUBBASE_ADD_SPECIAL_SUBBASE = 'PRODUCT_SUBBASE_ADD_SPECIAL_SUBBASE'
export const PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE = 'PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE'
export const PRODUCT_SUBBASE_SET_SPECIAL_SUBBASE_TO_UPDATE = 'PRODUCT_SUBBASE_SET_SPECIAL_SUBBASE_TO_UPDATE'
export const PRODUCT_SUBBASE_DELETE_SPECIAL_SUBBASE = 'PRODUCT_SUBBASE_DELETE_SPECIAL_SUBBASE'
export const PRODUCT_SUBBASE_SET_META = 'PRODUCT_SUBBASE_SET_META'

// 1.7.5. DISTRIBUTOR PAD API
export const PRODUCT_PAD_SET_PADS = 'PRODUCT_PAD_SET_PADS'
export const PRODUCT_PAD_SET_SPECIAL_PADS = 'PRODUCT_PAD_SET_SPECIAL_PADS'
export const PRODUCT_PAD_ADD_SPECIAL_PAD = 'PRODUCT_PAD_ADD_SPECIAL_PAD'
export const PRODUCT_PAD_UPDATE_SPECIAL_PAD = 'PRODUCT_PAD_UPDATE_SPECIAL_PAD'
export const PRODUCT_PAD_SET_SPECIAL_PAD_TO_UPDATE = 'PRODUCT_PAD_SET_SPECIAL_PAD_TO_UPDATE'
export const PRODUCT_PAD_DELETE_SPECIAL_PAD = 'PRODUCT_PAD_DELETE_SPECIAL_PAD'
export const PRODUCT_PAD_SET_META = 'PRODUCT_PAD_SET_META'

// 1.7.6. DISTRIBUTOR PEDESTAL API
export const PRODUCT_PEDESTAL_SET_PEDESTALS = 'PRODUCT_PEDESTAL_SET_PEDESTALS'
export const PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTALS = 'PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTALS'
export const PRODUCT_PEDESTAL_ADD_SPECIAL_PEDESTAL = 'PRODUCT_PEDESTAL_ADD_SPECIAL_PEDESTAL'
export const PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL = 'PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL'
export const PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTAL_TO_UPDATE = 'PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTAL_TO_UPDATE'
export const PRODUCT_PEDESTAL_DELETE_SPECIAL_PEDESTAL = 'PRODUCT_PEDESTAL_DELETE_SPECIAL_PEDESTAL'
export const PRODUCT_PEDESTAL_SET_META = 'PRODUCT_PEDESTAL_SET_META'

// 1.7.7. DISTRIBUTOR SEAMTAPE API
export const PRODUCT_SEAMTAPE_SET_SEAMTAPES = 'PRODUCT_SEAMTAPE_SET_SEAMTAPES'
export const PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPES = 'PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPES'
export const PRODUCT_SEAMTAPE_ADD_SPECIAL_SEAMTAPE = 'PRODUCT_SEAMTAPE_ADD_SPECIAL_SEAMTAPE'
export const PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE = 'PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE'
export const PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPE_TO_UPDATE = 'PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPE_TO_UPDATE'
export const PRODUCT_SEAMTAPE_DELETE_SPECIAL_SEAMTAPE = 'PRODUCT_SEAMTAPE_DELETE_SPECIAL_SEAMTAPE'
export const PRODUCT_SEAMTAPE_SET_META = 'PRODUCT_SEAMTAPE_SET_META'

// 1.7.8. DISTRIBUTOR ADHESIVE API
export const PRODUCT_ADHESIVE_SET_ADHESIVES = 'PRODUCT_ADHESIVE_SET_ADHESIVES'
export const PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVES = 'PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVES'
export const PRODUCT_ADHESIVE_ADD_SPECIAL_ADHESIVE = 'PRODUCT_ADHESIVE_ADD_SPECIAL_ADHESIVE'
export const PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE = 'PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE'
export const PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVE_TO_UPDATE = 'PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVE_TO_UPDATE'
export const PRODUCT_ADHESIVE_DELETE_SPECIAL_ADHESIVE = 'PRODUCT_ADHESIVE_DELETE_SPECIAL_ADHESIVE'
export const PRODUCT_ADHESIVE_SET_META = 'PRODUCT_ADHESIVE_SET_META'

// 1.8. CLIENT API
export const CLIENT_SET_CLIENTS = 'CLIENT_SET_CLIENTS'
export const CLIENT_ADD_CLIENT = 'CLIENT_ADD_CLIENT'
export const CLIENT_DELETE_CLIENT = 'CLIENT_DELETE_CLIENT'
export const CLIENT_UPDATE_CLIENT = 'CLIENT_UPDATE_CLIENT'

// 1.9. PROJECT API
export const PROJECT_SET_PROJECTS = 'PROJECT_SET_PROJECTS'
export const PROJECT_ADD_PROJECT = 'PROJECT_ADD_PROJECT'
export const PROJECT_DELETE_PROJECT = 'PROJECT_DELETE_PROJECT'
export const PROJECT_UPDATE_PROJECT = 'PROJECT_UPDATE_PROJECT'
export const PROJECT_SET_PROJECT_RESPONSE = 'PROJECT_SET_PROJECT_RESPONSE'
export const PROJECT_SET_PROJECT_UPDATED_RELATED_PRODUCTS = 'PROJECT_SET_PROJECT_UPDATED_RELATED_PRODUCTS'

// 1.9.1 AVAILABLE SUBITEMS FOR PROJECT
export const PROJECT_SET_PRODUCT_TURFS = 'PROJECT_SET_PRODUCT_TURFS'
export const PROJECT_SET_PRODUCT_INFILLS = 'PROJECT_SET_PRODUCT_INFILLS'
export const PROJECT_SET_PRODUCT_SUBBASES = 'PROJECT_SET_PRODUCT_SUBBASES'
export const PROJECT_SET_PRODUCT_PADS = 'PROJECT_SET_PRODUCT_PADS'
export const PROJECT_SET_PRODUCT_PEDESTALS = 'PROJECT_SET_PRODUCT_PEDESTALS'
export const PROJECT_SET_PRODUCT_SEAMTAPES = 'PROJECT_SET_PRODUCT_SEAMTAPES'
export const PROJECT_SET_PRODUCT_ADHESIVES = 'PROJECT_SET_PRODUCT_ADHESIVES'

// 1.10 STATISTICS API

export const STATS_SET_GROUP_STATS = 'STATS_SET_GROUP_STATS'

// API CLIENT API
export const APICLIENT_SET_CLIENT = 'APICLIENT_SET_CLIENT'
export const APICLIENT_ADD_CLIENT = 'APICLIENT_ADD_CLIENT'
export const APICLIENT_UPDATE_CLIENT = 'APICLIENT_UPDATE_CLIENT'
export const APICLIENT_REMOVE_CLIENT = 'APICLIENT_REMOVE_CLIENT'
export const APICLIENT_SET_API_KEY = 'APICLIENT_SET_API_KEY'
