<template>
  <svg
    id="template-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      class="u-fill-w"
      d="M8.51,36.44h26a1,1,0,0,0,1-1V10.91a1,1,0,0,0-1-1H11.68V7.73A4.13,4.13,0,0,0,7.51,3.55,4,4,0,0,0,3.33,7.73V31.26A4.93,4.93,0,0,0,8.51,36.44Zm-3.1-5.1a2,2,0,0,1,2.17-2.17,2,2,0,0,1,2.1,2.09c0,1.18,2,1.11,2,0V11.91H33.52V34.44s-23.36,0-25,0A2.91,2.91,0,0,1,5.41,31.34Z"
    />
    <g>
      <path
        class="u-fill-w"
        d="M16.88,18.82h-1.5v-2.5a.76.76,0,0,1,.75-.75h2.5v1.5H16.88Z"
      />
      <rect
        class="u-fill-w"
        x="20.8"
        y="15.57"
        width="3.62"
        height="1.5"
      />
      <path
        class="u-fill-w"
        d="M29.85,18.82h-1.5V17.07H26.6v-1.5h2.5a.76.76,0,0,1,.75.75Z"
      />
      <path
        class="u-fill-w"
        d="M29.1,23.8H26.6V22.3h1.75V20.55h1.5v2.5a.75.75,0,0,1-.75.75Z"
      />
      <path
        class="u-fill-w"
        d="M23.37,25.54h-1.5V23a.71.71,0,0,1,.22-.53.75.75,0,0,1,.53-.22h2.5v1.5H23.37Z"
      />
      <path
        class="u-fill-w"
        d="M22.62,30.51h-2.5V29h1.75V27.26h1.5v2.5A.75.75,0,0,1,22.62,30.51Z"
      />
      <path
        class="u-fill-w"
        d="M18.63,30.51h-2.5a.75.75,0,0,1-.75-.75v-2.5h1.5V29h1.75Z"
      />
      <rect
        class="u-fill-w"
        x="15.38"
        y="21.12"
        width="1.5"
        height="3.84"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TemplateIcon'
}
</script>
