import { Point } from '@/classes/objects'
import * as editorEventTypes from '@/events/editor-event-types'
import config from '@/config'

import MixinValidate from '@/mixins/validate'

/**
 * Tools - Default Object Pattern
 * @displayName Polygon Object
 */
export default {
  name: 'ToolsPolygonMixin',
  data: () => {
    return {
      start: null
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.DRAW_DEFAULT_CUT_HIT_START, this.$_mx_tools_polygon_finishPolygon)
    this.$events.on(editorEventTypes.DRAW_DEFAULT_ENTER_PRESSED, this.$_mx_tools_polygon_finnishButtonPressed)
  },
  mixins: [
    MixinValidate
  ],
  methods: {
    /**
         * Creates a polygon
         * @returns {null|VNode}
         * @public
         */
    mx_tools_polygon_polygon (keyboardInput) {
      this.pseudoPoints = []
      let circles = this.defaultObject.points.map((point, index, points) => {
        if (point.type !== 'M' && index <= points.length - 1) {
          this.pseudoPoints.push({ x: point.x, y: point.y })
          return this.defaultCircle(point)
        }
      })

      circles = circles.filter(item => {
        if (item !== undefined) {
          return item
        }
      })
      let nextPoint = this.mouse

      const lastPoint = this.pseudoPoints.slice(-1).pop()
      if (this.pseudoPoints.length > 0 && keyboardInput > 0) {
        nextPoint = this.mx_math_getPointInDistance(lastPoint, this.mouse, keyboardInput, this.measure)
      }

      if (circles.length > 0) {
        const lines = circles.map((circle, index) => {
          const circleData = circle.data.attrs

          return this.defaultLine({
            x1: circleData.cx,
            y1: circleData.cy,
            x2: (circles.length - 1 === index) ? nextPoint.x : circles[index + 1].data.attrs.cx,
            y2: (circles.length - 1 === index) ? nextPoint.y : circles[index + 1].data.attrs.cy
          })
        })

        circles.push(this.defaultCircle(nextPoint))
        this.pseudoPoints.push(nextPoint)
        return this.$createElement('g', {
          class: {
            'c-object c-object__group': true
          }
        }, [this.defaultPattern(this.pseudoPoints, null, false), lines, circles])
      }
      return null
    },
    $_mx_tools_polygon_finishPolygon () {
      this.defaultObject.points.push(new Point(0, 0, 'Z'))

      this.$events.fire(editorEventTypes.TOOLBAR_RESET_SUBMENU_CONTAINERS)
      if (!this.cut) {
        this.finishPredefinedObject()
      } else {
        this.preparePredefinedObject()
      }
    },
    $_mx_tools_polygon_finnishButtonPressed () {
      if (this.objectType === config.objects.DEFAULT_POLYGON && this.defaultObject.points.length > 3) {
        if (this.mx_validate_validatePathClosing(this.defaultObject.points, this.defaultObject.points[0])) {
          this.$_mx_tools_polygon_finishPolygon()
        }
      }
    }
  }
}
