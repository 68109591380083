<template>
  <div>
    <div style="width: 100%; margin-top: 80px">
      <div style="width: 100%; text-align: center">
        <icon classes="fas fa-tools fa-10x u-color-s3" />
        <h2 class="c-typo__h2 u-color-s3 u-mt-size-60">
          Under Construction
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import MixinValidate from '@/mixins/validate'
import Icon from '@/components/common/Icon'

/**
 * @displayName Location Material EPDMs List
 */
export default {
  name: 'LocationsEPDM',
  components: {
    Icon
  },
  mixins: [
    MixinValidate
  ],
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>
