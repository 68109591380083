<template>
  <dialogs-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.deleteApiClient.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.deleteApiClient.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <icon classes="fas fa-2x fa-exclamation-triangle u-color-o u-mt-size-standard u-mb-size-standard" />
        <p>{{ $t('dialogs.deleteApiClient.info') }}</p>
        <span
          v-if="client"
          class="u-color-b1 u-mt-size-standard"
        >
          {{ client.name }}
        </span>
        <p
          class="u-mt-size-standard"
          v-html="$t('dialogs.deleteApiClient.warning')"
        />
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="api-key-close"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
          />
          <button-base
            id="api-key-delete"
            style-color="gray"
            :label="$t('dialogs.deleteApiClient.button')"
            :on-click-action="() => { return deleteClient() }"
          />
        </div>
      </div>
    </template>
  </dialogs-frame>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DialogsFrame from '@/components/common/DialogsFrame'

import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

export default {
  name: 'DeleteApiClientDialog',
  components: {
    Icon,
    DialogsFrame,
    ButtonBase
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'deleteApiClient',
      cannotClose: false,
      client: null
    }
  },
  computed: {
    ...mapGetters({
      getDialogProps: 'dialogs/getDialogProps'
    })
  },
  mounted () {
    this.client = this.getDialogProps(this.targetDialog)
  },
  methods: {
    ...mapActions({
      deleteApiClient: 'backendAPI/apiClient/delete'
    }),
    close () {
      this.client = null
      this.mx_dialogs_closeDialog()
    },
    async deleteClient () {
      const client = this.getDialogProps(this.targetDialog)
      await this.deleteApiClient(client)
      this.close()
    }
  }
}
</script>
