<template>
  <context-menu
    v-if="menu.ctrl === 'menu-mouse-circle'"
    :id="'circle'"
    :title=" $t('editor.contextMenu.circle.title') "
  >
    <button
      v-if="hasEnoughPoints()"
      @click="removePoint()"
    >
      <icon classes="fas fa-2x fa-trash-alt" />
      <span>{{ $t('editor.contextMenu.circle.delete') }}</span>
    </button>
    <button
      v-if="pointType !== 'C'"
      @click="transformTo('C')"
    >
      <icon classes="c-context-menu__icon">
        <handles-curve-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.circle.curve') }}</span>
    </button>
    <button
      v-if="pointType !== 'L'"
      @click="transformTo('L')"
    >
      <icon classes="c-context-menu__icon">
        <handles-line-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.circle.line') }}</span>
    </button>
    <button
      v-if="pointType !== 'Q'"
      @click="transformTo('Q')"
    >
      <icon classes="c-context-menu__icon">
        <handles-quadratic-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.circle.quadratic') }}</span>
    </button>
  </context-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import ContextMenu from '@/components/common/ContextMenu'
import MixinTrackState from '@/mixins/trackstate'
import MixinValidate from '@/mixins/validate'
import Icon from '@/components/common/Icon'
import HandlesCurveIcon from '@/components/common/icons/handles/HandlesCurveIcon'
import HandlesLineIcon from '@/components/common/icons/handles/HandlesLineIcon'
import HandlesQuadraticIcon from '@/components/common/icons/handles/HandlesQuadraticIcon'

export default {
  name: 'MenuCircle',
  components: {
    HandlesQuadraticIcon,
    HandlesLineIcon,
    HandlesCurveIcon,
    Icon,
    ContextMenu
  },
  mixins: [
    MixinTrackState,
    MixinValidate
  ],
  data () {
    return {
      workingObject: null
    }
  },
  computed: {
    ...mapState({
      menu: state => state.events.contextMenu
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      getCutObject: 'project/objects/getCutObject',
      getPointIndex: 'project/objects/getPointIndex',
      findObject: 'project/objects/findObject',
      getChildByPoint: 'project/objects/getChildByPoint',
      getPointFromChild: 'project/objects/getPointFromChild'
    }),
    pointType () {
      let point = this.workingObject.getPoint(this.menu.circle)

      if (point === null) {
        point = this.getPointFromChild(this.workingObject.id, this.menu.circle)
      }

      return point.type
    }
  },
  methods: {
    ...mapActions({
      transformPoint: 'project/transformObjectPoint'
    }),
    ...mapMutations({
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU
    }),
    hasEnoughPoints () {
      this.workingObject = (!this.menu.isCutOut) ? this.getObject(this.menu.object) : this.getCutObject

      const pointIndex = this.getPointIndex(this.workingObject, this.menu.circle)

      if (pointIndex === -1) {
        const child = this.getChildByPoint(this.workingObject.id, this.menu.circle, this.menu.isCutOut)
        return (child !== null)
      }
      return (this.workingObject !== null && (this.workingObject.points.length - 2) > 3)
    },
    transformTo (type) {
      this.hideMouseMenu()
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.transformPoint({
            circle: this.menu.circle,
            object: this.menu.object,
            isCutOut: this.menu.isCutOut,
            type: type
          })
        },
        runSave: !this.workingObject.isCutOut
      })
    },
    removePoint () {
      this.hideMouseMenu()
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.mx_validate_removingPointsWithValidation(
            this.menu.object,
            this.menu.circle
          )
        },
        runSave: !this.workingObject.isCutOut
      })
    }
  }
}
</script>
