/**
 * Module: LineOfSight, Submodule: Waste Management
 */
import Vue from 'vue'
import * as types from '@/vuex/mutation-types'

const defaultState = () => {
  return {
    active: false,
    for: {
      area: null,
      track: null
    },
    area: null,
    track: null
  }
}

const state = defaultState()

const getters = {
  isActive: (state) => {
    return state.active
  },
  getSelectedArea: (state) => {
    return state.area
  },
  getSelectedTrack: (state) => {
    return state.track
  },
  getFor: (state) => {
    return state.for
  },
  isWasteForById: (state) => (id) => {
    if (state.for !== null) {
      return state.for.area.id === id
    }
    return false
  },
  isWasteAreaById: (state) => (id) => {
    if (state.area !== null) {
      return state.area.id === id
    }
    return false
  }
}

const actions = {
  cancel ({ commit }) {
    commit(types.WASTE_MANAGEMENT_CANCEL)
  },
  setFor ({ commit }, params) {
    commit(types.WASTE_MANAGEMENT_SET_ACTIVE, true)
    commit(types.WASTE_MANAGEMENT_SET_FOR, params)
  },
  setArea ({ commit }, area) {
    commit(types.WASTE_MANAGEMENT_SET_AREA, area)
  },
  setTrack ({ commit }, track) {
    commit(types.WASTE_MANAGEMENT_SET_TRACK, track)
  }
}

const mutations = {
  [types.WASTE_MANAGEMENT_CANCEL] (state) {
    const dstate = defaultState()
    const keys = Object.keys(dstate)

    for (const index in keys) {
      const key = keys[index]
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        Vue.set(state, key, dstate[key])
      }
    }
  },
  [types.WASTE_MANAGEMENT_SET_ACTIVE] (state, active) {
    state.active = active
  },
  [types.WASTE_MANAGEMENT_SET_FOR] (state, params) {
    state.for = params
  },
  [types.WASTE_MANAGEMENT_SET_AREA] (state, area) {
    state.area = area
  },
  [types.WASTE_MANAGEMENT_SET_TRACK] (state, track) {
    state.track = track
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
