<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.updateGroup.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.updateGroup.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
          <div class="c-dialog__instruction-col">
            <file-upload
              v-if="group !== null && (group.logo && group.logo.conversions || group.logo === null)"
              id="update-group-logo"
              :accept="'image/png, image/jpeg'"
              :current="group.logo"
              :label-default-message="$t('dialogs.createGroup.imageFrame.dropCompanyLogo')"
              :file-max-size="config.files.single.backend"
              style-drop-area-height="200px"
              style-drop-area-width="200px"
              @change="prepareFile"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </div>
          <div class="c-dialog__instruction-col u-text-center">
            <form
              v-if="group !== null"
              class="c-form"
            >
              <input-field
                id="update-group-name"
                v-model="group.address.name"
                :focus-on-mount="true"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-group-address-website"
                v-model="group.address.website"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.website')"
                :required="true"
                :validate="(v) => {return mx_validate_validateURL(v)}"
                classes="u-mt-size-40"
                placeholder="https://google.com"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-group-address-street"
                v-model="group.address.street"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.address.street')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-group-address-city"
                v-model="group.address.city"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.address.city')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-group-address-zip"
                v-model="group.address.zip"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.address.zip')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-group-address-state"
                v-model="group.address.state"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.address.state')"
                :validate="(v) => {return true}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-group-address-country"
                v-model="group.address.country"
                :is-store-mode="false"
                :label="$t('dialogs.updateGroup.address.country')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <drop-down
                v-if="isAdmin"
                id="update-group-measurement"
                v-model="group.measurement"
                :current-item-allowed-signs="15"
                :items="config.measureSystem"
                :label="$t('dialogs.updateGroup.measure')"
                :required="true"
                classes="u-mt-size-standard"
                label-key="label"
                style-current-item-width="240px"
                value-key="label"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="update-group-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="update-group-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return update() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import config from '@/config'
import { mapActions, mapGetters, mapState } from 'vuex'
import { apiMethods, roles } from '@/helper/apiHelper'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinFile from '@/mixins/file'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import FileUpload from '@/components/common/FileUpload'
import ButtonBase from '@/components/common/ButtonBase'
import DropDown from '@/components/common/DropDown'

/**
 * @displayName Dialog Update Group
 */
export default {
  name: 'UpdateGroup',
  components: {
    DropDown,
    ButtonBase,
    DialogFrame,
    InputField,
    FileUpload
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinFile
  ],
  data: () => {
    return {
      config: config,
      targetDialog: 'updateGroup',
      cannotClose: false,
      group: null
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.updateGroup
    }),
    ...mapGetters({
      getMyGroup: 'backendAPI/user/getGroup',
      getMyRole: 'backendAPI/user/getRole'
    }),
    isAdmin () {
      return this.getMyRole === roles.admin
    },
    isManufacture () {
      return this.getMyRole === roles.manufacture
    },
    isMyGroup () {
      return this.group && this.getMyGroup ? this.group.id === this.getMyGroup.id : null
    }
  },
  beforeMount () {
    this.editGroup(this.dialogProps.groupData.id)
  },
  methods: {
    ...mapActions({
      readGroup: 'backendAPI/group/read',
      updateGroup: 'backendAPI/group/update'
    }),
    prepareFile (e) {
      this.mx_validate_updateErrorsArray(e)
      this.mx_file_file = e.value
    },
    async editGroup (groupId) {
      const serverGroup = await this.readGroup(groupId)
      if (serverGroup.address === null) {
        serverGroup.address = apiMethods.getModel('group').address
      }
      this.group = serverGroup
    },
    async update () {
      this.group.name = this.group.address.name
      const isUpdated = await this.updateGroup({ group: this.group, fileCallback: this.mx_file_doMediaRequests })
      if (isUpdated) {
        this.group = null
        this.$emit('group-updated')
      }
      this.close()
    },
    close () {
      this.group = null
      this.mx_file_clearData()
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
