<template>
  <div>
    <slot :formData="formData" />
    <button-base
      v-if="renderHelper"
      id="material-update-helper-submit"
      :disabled="isConfirmBlocked"
      :label="$t('common.save')"
      classes="c-button-base--alt-disabled u-float-right u-mr-size-0 u-mb-size-100"
      style-size="s"
      :on-click-action="() => { return doSubmit() }"
    />
  </div>
</template>

<script>
/**
 * Location based !!!
 *
 * This product update helper is only for location based data
 */
import { mapActions } from 'vuex'
import ButtonBase from '@/components/common/ButtonBase'
import _ from 'lodash'

/**
 * @displayName Location Material Update Helper
 */
export default {
  name: 'MaterialUpdateHelper',
  components: { ButtonBase },
  props: {
    items: {
      type: [Array, null],
      required: true
    },
    itemType: {
      type: String,
      required: true
    },
    useStore: {
      type: Boolean,
      required: false,
      default: true
    },
    isConfirmBlocked: {
      type: Boolean,
      required: true
    },
    renderHelper: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    formData () {
      if (this.items === null || this.items.length === 0) {
        return []
      }
      return _.cloneDeep(this.items)
    }
  },
  methods: {
    ...mapActions({
      updateData: 'backendAPI/product/subProductHandler/update'
    }),
    async doSubmit () {
      const update = this.formData.map(item => {
        return item.overlay
      })

      if (this.useStore) {
        await this.updateData({ update: update, type: this.itemType }).then(response => {
          if (response === true) {
            this.$toasted.show(this.$t('toast.locations.materials.updated'), { duration: 7000, type: 'success' })
          }
        })
      } else {
        this.$emit('doProductUpdate', update)
      }
    }
  }
}
</script>
