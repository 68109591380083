<template>
  <backend>
    <template #dialogs>
      <create-project-dialog />
      <update-project-dialog />
      <delete-project-dialog />
      <project-need-update-dialog />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <button-base
            id="projects-create-project"
            :label="$t('backend.projects.newProject')"
            :on-click-action="() => { return _event() }"
            classes="u-mt-size-40 u-ml-size-0"
            icon-classes="fa fa-plus"
            style-size="s"
          />
          <p>{{ $t('backend.projects.totalRecords') }} {{ totalRecords }}</p>
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <async-project-list @updateTotalRecords="updateTotalRecords" />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import { mapActions } from 'vuex'
import Backend from '@/views/Backend'

import CreateProjectDialog from '@/components/backend/projects/dialogs/CreateProject'
import UpdateProjectDialog from '@/components/backend/projects/dialogs/UpdateProject'
import DeleteProjectDialog from '@/components/backend/projects/dialogs/DeleteProject'
import ProjectNeedUpdateDialog from '@/components/backend/projects/dialogs/ProjectNeedUpdate'
import AsyncProjectList from '@/components/backend/projects/AsyncProjectList'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Projects Component
     */
export default {
  name: 'Projects',
  components: {
    ButtonBase,
    Backend,
    CreateProjectDialog,
    DeleteProjectDialog,
    UpdateProjectDialog,
    ProjectNeedUpdateDialog,
    AsyncProjectList
  },
  mixins: [MixinDialogHandler],
  data: () => {
    return {
      totalRecords: 0
    }
  },
  methods: {
    ...mapActions({
      initClients: 'backendAPI/client/listShort'
    }),
    async _event () {
      await this.initClients()
      this.$_mx_dialogHandler_openDialog({
        dialog: 'createProject',
        onOff: true,
        dialogProps: { resetState: true }
      })
    },
    updateTotalRecords (totalRecords) {
      this.totalRecords = totalRecords
    }
  }
}
</script>
