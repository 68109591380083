<template>
  <svg
    id="canvas-add-anim"
    viewBox="0 0 200 200"
    width="150px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>add-anim</title>
    <path
      id="add-anim-object-3"
      class="anim"
      d="M178.85,85.92v89.94a3,3,0,0,1-3,3H85.92a3,3,0,0,1-3-3v-60H24a3,3,0,0,1-3-3V23a3,3,0,0,1,3-3h89.94a3,3,0,0,1,3,3v60h59A3,3,0,0,1,178.85,85.92Z"
    />
    <rect
      id="add-anim-object-1"
      class="anim"
      x="20.98"
      y="19.98"
      width="95.92"
      height="95.92"
      rx="2.99"
    />
    <rect
      id="add-anim-object-2"
      class="anim"
      x="82.93"
      y="82.93"
      width="95.92"
      height="95.92"
      rx="2.99"
    />
    <path
      id="icon"
      class="anim"
      d="M175.69,32.37h-9.15V23.25A3.25,3.25,0,0,0,163.29,20h-19A3.26,3.26,0,0,0,141,23.25v19a3.25,3.25,0,0,0,3.25,3.25h9.15v9.14a3.24,3.24,0,0,0,3.24,3.25h19.05a3.26,3.26,0,0,0,3.25-3.25v-19A3.26,3.26,0,0,0,175.69,32.37Zm-2.5,14.34h-5.43v5.44a1.7,1.7,0,0,1-3.4,0V46.71h-5.43a1.67,1.67,0,0,1-1.61-1.17,1.5,1.5,0,0,1-.09-.52,1.7,1.7,0,0,1,1.7-1.71h5.43V37.88a1.7,1.7,0,0,1,1.7-1.69,1.51,1.51,0,0,1,.48.07,1.69,1.69,0,0,1,1.22,1.62v5.43h5.43a1.7,1.7,0,0,1,0,3.4Z"
    />
    <path
      id="cursor"
      class="anim"
      d="M55.65,149.34,63,144.58,39,136l8.58,24,4.76-7.35,5.15,5.15a2.34,2.34,0,0,0,3.31-3.31Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">

    #cursor {
        fill: #fff;
    }

    #icon {
        fill: var(--w);
    }

    #add-anim-object-1, #add-anim-object-2 {
        fill: var(--b1);
        opacity: 0.7;
    }

    #add-anim-object-3 {
        fill: var(--b1);
        opacity: 0.001; // Mozilla fix (cannot be 0)
    }

    /* Animations */

    .anim {
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;

        &#cursor {
            animation-name: move-cursor;
            animation-timing-function: ease-in-out;
        }

        &#icon {
            animation-name: click-button;
        }

        &#add-anim-object-1 {
            animation-name: select-add-anim-object-1, make-invisible;
        }

        &#add-anim-object-2 {
            animation-name: select-add-anim-object-2, make-invisible;
        }

        &#add-anim-object-3 {
            animation-name: make-visible;
        }
    }

    @keyframes move-cursor {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(80px, -10px);
        }
        30% {
            transform: translate(80px, -10px);
        }
        50% {
            transform: translate(20px, -80px);
        }
        55% {
            transform: translate(20px, -80px);
        }
        75% {
            transform: translate(120px, -90px);
        }
        100% {
            transform: translate(120px, -90px);
        }
    }

    @keyframes select-add-anim-object-1 {
        0% {
            fill: var(--b1);
        }
        50% {
            fill: var(--b1);
        }
        53% {
            fill: var(--o);
        }
        100% {
            fill: var(--o);
        }
    }

    @keyframes select-add-anim-object-2 {
        0% {
            fill: var(--b1);
        }
        25% {
            fill: var(--b1);
        }
        28% {
            fill: var(--o);
        }
        100% {
            fill: var(--o);
        }
    }

    @keyframes click-button {
        0% {
            fill: var(--w);
        }
        75% {
            fill: var(--w);
        }
        75.1% {
            fill: var(--b1);
        }
        100% {
            fill: var(--b1);
        }
    }

    @keyframes make-invisible {
        0% {
            opacity: 0.7;
        }
        75% {
            opacity: 0.7;
        }
        75.1% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        100% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
    }

    @keyframes make-visible {
        0% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        75% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        75.1% {
            opacity: 0.7;
        }
        100% {
            opacity: 0.7;
        }
    }
</style>
