import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import config from '@/config'
import * as editorEventTypes from '@/events/editor-event-types'
import kbListener from 'keyboardjs'

import MixinTrackState from '@/mixins/trackstate'
import MixinEventActionsObject from '@/mixins/eventActions/object'
import MixinMath from '@/mixins/math'

/**
 * Provides events
 * @display EventHandler
 */
export default {
  name: 'EventHandlerMixin',
  data () {
    return {
      /** @private **/
      $_mx_eventHandler_keyTimeout: null,
      /** @private **/
      $_mx_eventHandler_scrollCounter: 0
    }
  },
  mixins: [
    MixinEventActionsObject, MixinTrackState, MixinMath
  ],
  mounted () {
    if (this.$_mx_eventHandler_editor !== null) {
      this.$_mx_eventHandler_initEvents()
    }
  },
  beforeDestroy () {
    window.onkeydown = null
    window.onkeyup = null
    if (window.PointerEvent) {
      this.$_mx_eventHandler_editor.removeEventListener('pointerdown', (evt) => {
        this.$events.fire(editorEventTypes.EDITOR_MOUSEDOWN, evt)
      })
      this.$_mx_eventHandler_editor.removeEventListener('pointerup', (evt) => {
        this.$events.fire(editorEventTypes.EDITOR_MOUSEUP, evt)
      })
    } else {
      this.$_mx_eventHandler_editor.removeEventListener('mousedown', (evt) => {
        this.$events.fire(editorEventTypes.EDITOR_MOUSEDOWN, evt)
      })
      this.$_mx_eventHandler_editor.removeEventListener('mouseup', (evt) => {
        this.$events.fire(editorEventTypes.EDITOR_MOUSEUP, evt)
      })
    }
    this.$_mx_eventHandler_editor.removeEventListener('wheel', (evt) => {
      this.$events.fire(editorEventTypes.EDITOR_WHEEL, evt)
    })
    kbListener.reset()
  },
  computed: {
    ...mapState({
      /** @private **/
      $_mx_eventHandler_target: state => state.events.target,
      /** @private **/
      $_mx_eventHandler_eventCtrl: state => state.events.ctrl,
      /** @private **/
      $_mx_eventHandler_contextMenu: state => state.events.contextMenu,
      /** @private **/
      $_mx_eventHandler_panMode: state => state.events.mode.pan,
      /** @private **/
      $_mx_eventHandler_losMode: state => state.events.mode.los,
      /** @private **/
      $_mx_eventHandler_cutMode: state => state.events.mode.cut,
      /** @private **/
      $_mx_eventHandler_currentPath: state => state.pseudo.path,
      /** @private **/
      $_navigator: state => state.editor.navigator,
      /** @private **/
      $_mx_eventHandler_currentSelectedObjects: state => state.events.currentSelectedObjects,
      /** @private **/
      $_mx_eventHandler_inputFocus: state => state.events.inputFocus,
      $_mx_eventHandler_keyboardInput: state => state.pseudo.keyboardInput
    }),
    ...mapGetters({
      /** @private **/
      $_mx_eventHandler_editor: 'editor/get',
      /** @private **/
      $_mx_eventHandler_dialogVisible: 'dialogs/dialogVisible',
      /** @private **/
      $_mx_eventHandler_navigationDialogVisible: 'navigation/dialogVisible',
      mx_eventHandler_shouldBlockShortcuts: 'events/shouldBlockShortcuts'
    })
  },
  methods: {
    ...mapActions({
      /** @private **/
      $_mx_eventHandler_removeObject: 'project/removeObject',
      /** @private **/
      $_mx_eventHandler_stepBack: 'history/stepBack',
      /** @private **/
      $_mx_eventHandler_stepForward: 'history/stepForward',
      /** @private **/
      $_mx_eventHandler_duplicateObject: 'project/duplicateObject',
      /** @private **/
      $_mx_eventHandler_clearObjectSelection: 'events/clearObjectSelection',
      /** @private **/
      $_mx_eventHandler_editorDeleteUnderlay: 'files/deleteSurfaceUnderlay',
      /** @private **/
      $_mx_eventHandler_setEventCtrl: 'events/setEditorCTRL'
    }),
    ...mapMutations({
      /** @private **/
      $_mx_eventHandler_setTarget: 'events/' + mutationTypes.EVT_TARGET,
      /** @private **/
      $_mx_eventHandler_updateEditorMode: 'events/' + mutationTypes.EVT_UPDATE_EDITOR_MODE,
      /** @private **/
      $_mx_eventHandler_editorToggleUnderlay: 'files/' + mutationTypes.FILES_TOGGLE_SURFACE_UNDERLAY,
      /** @private **/
      $_mx_eventHandler_zoomIn: 'editor/viewbox/' + mutationTypes.SET_VIEWBOX_ZOOM_IN,
      /** @private **/
      $_mx_eventHandler_zoomOut: 'editor/viewbox/' + mutationTypes.SET_VIEWBOX_ZOOM_OUT,
      /** @private **/
      $_mx_eventHandler_zoomReset: 'editor/viewbox/' + mutationTypes.SET_VIEWBOX_ZOOM_RESET,
      /** @private **/
      $_mx_eventHandler_toggleGridSnap: 'editor/grid/' + mutationTypes.TOGGLE_GRID_SNAP,
      /** @private **/
      $_mx_eventHandler_toggleGridShow: 'editor/grid/' + mutationTypes.TOGGLE_GRID_SHOW
    }),
    /**
     * Init all event listeners
     * @private
     */
    $_mx_eventHandler_initEvents () {
      this.$_mx_eventHandler_keyboardEvents()
      this.$_mx_eventHandler_mouseEvents()
      this.$_mx_eventHandler_menuEvents()
      this.$_mx_eventHandler_editorEvents()
      this.$_mx_eventHandler_projectEvents()
      this.mx_eventActions_object_objectEvents()
    },
    /**
     * Init project events
     * @requires @/mixins/eventActions/object
     * @private
     */
    $_mx_eventHandler_projectEvents () {
      this.$events.on(editorEventTypes.PROJECT_REMOVE_SELECTED_OBJECTS, () => {
        this.mx_trackstate_trackState(() => {
          this.$_mx_eventHandler_removeObject(this.mx_eventActions_object_selectedObjects)
          this.$_mx_eventHandler_clearObjectSelection()
        })
      })
      this.$events.on(editorEventTypes.PROJECT_UNDO, () => {
        this.$_mx_eventHandler_stepBack()
      })
      this.$events.on(editorEventTypes.PROJECT_REDO, () => {
        this.$_mx_eventHandler_stepForward()
      })

      this.$events.on(editorEventTypes.OBJECT_DUPLICATE, () => {
        this.mx_trackstate_trackState(() => {
          this.$_mx_eventHandler_duplicateObject(this.mx_eventActions_object_selectedObjects)
          this.$_mx_eventHandler_clearObjectSelection()
        })
      })
    },
    /**
     * Init editor events
     * @private
     */
    $_mx_eventHandler_editorEvents () {
      this.$events.on(editorEventTypes.EDITOR_SCALE_IN, this.$_mx_eventHandler_zoomIn)
      this.$events.on(editorEventTypes.EDITOR_SCALE_OUT, this.$_mx_eventHandler_zoomOut)
      this.$events.on(editorEventTypes.UNDERLAY_TOGGLE, this.$_mx_eventHandler_editorToggleUnderlay)
      this.$events.on(editorEventTypes.UNDERLAY_DELETE, this.$_mx_eventHandler_editorDeleteUnderlay)
      this.$events.on(editorEventTypes.EDITOR_SCALE_RESET, this.$_mx_eventHandler_zoomReset)
      this.$events.on(editorEventTypes.GRID_SNAP_SWITCHED, this.$_mx_eventHandler_toggleGridSnap)
      this.$events.on(editorEventTypes.GRID_SHOW_SWITCHED, this.$_mx_eventHandler_toggleGridShow)
    },
    /**
     * Init menu events
     * @private
     */
    $_mx_eventHandler_menuEvents () {
      this.$events.on(editorEventTypes.OBJECT_MODIFIER_MOUSEDOWN, this.$_mx_eventHandler_handleCircles)
      this.$events.on(editorEventTypes.OBJECT_HANDLE_MODIFIER_MOUSEDOWN, this.$_mx_eventHandler_handleCircles)
    },
    /**
     * Init keyboard events
     * @private
     */
    $_mx_eventHandler_keyboardEvents () {
      // Viewbox - Zoom

      this.$_mx_eventHandler_keyDown(config.keyboard.EDITOR_SCALE_IN.shortcuts, () => {
        this.$events.fire(editorEventTypes.EDITOR_SCALE_IN)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.EDITOR_SCALE_OUT.shortcuts, () => {
        this.$events.fire(editorEventTypes.EDITOR_SCALE_OUT)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.EDITOR_SCALE_RESET.shortcuts, () => {
        this.$events.fire(editorEventTypes.EDITOR_SCALE_RESET)
      }, true)

      // Viewbox - Arrows panning

      this.$_mx_eventHandler_keyDown(config.keyboard.UP_LEFT.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_UP_LEFT)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.UP_RIGHT.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_UP_RIGHT)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.DOWN_LEFT.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_DOWN_LEFT)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.DOWN_RIGHT.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_DOWN_RIGHT)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.UP.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_UP)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.DOWN.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_DOWN)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.RIGHT.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_RIGHT)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.LEFT.shortcuts, () => {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_KEY, config.arrowsEnum.ARROW_LEFT)
      }, true)

      // Viewbox - Free panning

      this.$_mx_eventHandler_keyDown(config.keyboard.VIEWBOX_FREE_PANNING.shortcuts, () => {
        if (!this.$_mx_eventHandler_inputFocus) {
          this.$_mx_eventHandler_activateMouseMove()
          this.$_mx_eventHandler_updateEditorMode({ pan: true })
        }
      },
      false,
      () => {
        if (!this.$_mx_eventHandler_inputFocus) {
          if (this.$_mx_eventHandler_panMode && this.$_mx_eventHandler_target === 'viewBox') {
            this.$_mx_eventHandler_deactivateMouseMove()
          }
          this.$_mx_eventHandler_updateEditorMode({ pan: false })
        }
      },
      true,
      false)

      // Project State

      this.$_mx_eventHandler_keyDown(config.keyboard.PROJECT_SAVE.shortcuts, () => {
        this.$events.fire(editorEventTypes.PROJECT_SAVE)
      })
      this.$_mx_eventHandler_keyDown(config.keyboard.PROJECT_UNDO.shortcuts, () => {
        this.$events.fire(editorEventTypes.PROJECT_UNDO)
      }, true)
      this.$_mx_eventHandler_keyDown(config.keyboard.PROJECT_REDO.shortcuts, () => {
        this.$events.fire(editorEventTypes.PROJECT_REDO)
      }, true)

      // Objects

      this.$_mx_eventHandler_keyDown(config.keyboard.OBJECT_DELETE.shortcuts, () => {
        if (this.$_mx_eventHandler_eventCtrl === 'move') {
          this.$events.fire(editorEventTypes.PROJECT_REMOVE_SELECTED_OBJECTS)
        }
      })
      this.$_mx_eventHandler_keyDown(config.keyboard.OBJECT_DUPLICATE.shortcuts, () => {
        if (this.$_mx_eventHandler_currentSelectedObjects.length === 1) {
          this.$events.fire(editorEventTypes.OBJECT_DUPLICATE)
        }
      })
      this.$_mx_eventHandler_keyDown(config.keyboard.GRID_SNAP.shortcuts, () => {
        this.$events.fire(editorEventTypes.GRID_SNAP_SWITCHED)
      })
      this.$_mx_eventHandler_keyDown(config.keyboard.GRID_SHOW.shortcuts, () => {
        this.$events.fire(editorEventTypes.GRID_SHOW_SWITCHED)
      })
      this.$_mx_eventHandler_keyDown(config.keyboard.SNAP.shortcuts, () => {
        this.$events.fire(editorEventTypes.OBJECT_SNAP_ON)
      },
      false,
      () => {
        this.$events.fire(editorEventTypes.OBJECT_SNAP_OFF)
      })

      // Confirm (Enter)

      this.$_mx_eventHandler_keyDown(config.keyboard.ENTER.shortcuts, () => {
        if (this.$_mx_eventHandler_eventCtrl === 'draw') {
          this.$events.fire(editorEventTypes.DRAW_ENTER_PRESSED)
        }
        if (this.$_mx_eventHandler_eventCtrl === 'drawDefault') {
          this.$events.fire(editorEventTypes.DRAW_DEFAULT_ENTER_PRESSED)
        }
      })

      // Reject (Esc)

      this.$_mx_eventHandler_keyDown(config.keyboard.ESC.shortcuts, () => {
        if (!this.$_mx_eventHandler_dialogVisible && !this.$_mx_eventHandler_navigationDialogVisible) {
          if (this.$_mx_eventHandler_eventCtrl === 'draw' && this.$_mx_eventHandler_keyboardInput === 0) {
            this.$events.fire(editorEventTypes.DRAW_ESCAPE_PRESSED)
          }
          if (this.$_mx_eventHandler_eventCtrl === 'drawDefault' && this.$_mx_eventHandler_keyboardInput === 0) {
            this.$events.fire(editorEventTypes.DRAW_DEFAULT_ESCAPE_PRESSED)
          }
          if (this.$_mx_eventHandler_eventCtrl === 'refline') {
            this.$events.fire(editorEventTypes.REFERENCE_LINE_INTERRUPT)
          }
          if (this.$_mx_eventHandler_contextMenu.ctrl !== 'null') {
            this.$events.fire(editorEventTypes.OBJECT_ESCAPE_PRESSED)
          }
        } else {
          this.$events.fire(editorEventTypes.DIALOG_ESC_PRESSED)
        }
      },
      false,
      () => {
      },
      true)
    },
    /**
     * Init mouse events
     * @private
     */
    $_mx_eventHandler_mouseEvents () {
      if (window.PointerEvent) {
        this.$_mx_eventHandler_editor.addEventListener('pointerdown', (evt) => {
          this.$events.fire(editorEventTypes.EDITOR_MOUSEDOWN, evt)
        })
        this.$_mx_eventHandler_editor.addEventListener('pointerup', (evt) => {
          this.$events.fire(editorEventTypes.EDITOR_MOUSEUP, evt)
        })
      } else {
        this.$_mx_eventHandler_editor.addEventListener('mousedown', (evt) => {
          this.$events.fire(editorEventTypes.EDITOR_MOUSEDOWN, evt)
        })
        this.$_mx_eventHandler_editor.addEventListener('mouseup', (evt) => {
          this.$events.fire(editorEventTypes.EDITOR_MOUSEUP, evt)
        })
      }
      this.$_mx_eventHandler_editor.addEventListener('wheel', (evt) => {
        this.$events.fire(editorEventTypes.EDITOR_WHEEL, evt)
      })

      this.$events.on(editorEventTypes.EDITOR_MOUSEDOWN, this.$_mx_eventHandler_mouseDown)
      this.$events.on(editorEventTypes.EDITOR_MOUSEMOVE, this.$_mx_eventHandler_mouseMove)
      this.$events.on(editorEventTypes.EDITOR_MOUSEUP, this.$_mx_eventHandler_mouseUp)
      this.$events.on(editorEventTypes.EDITOR_WHEEL, this.$_mx_eventHandler_grabScroll)
      this.$events.on(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, this.$_mx_eventHandler_changeEventCtrl)
    },
    /**
     * Activate mouse move event listener
     * @private
     */
    $_mx_eventHandler_activateMouseMove () {
      if (window.PointerEvent && !this.$_navigator.is_safari) {
        this.$_mx_eventHandler_editor.addEventListener('pointermove', this.$_mx_eventHandler_callMouseMove)
      } else {
        this.$_mx_eventHandler_editor.addEventListener('mousemove', this.$_mx_eventHandler_callMouseMove)
      }
    },
    /**
     * Deactivate the mouse move event listener
     * @private
     */
    $_mx_eventHandler_deactivateMouseMove () {
      if (window.PointerEvent && !this.$_navigator.is_safari) {
        this.$_mx_eventHandler_editor.removeEventListener('pointermove', this.$_mx_eventHandler_callMouseMove)
      } else {
        this.$_mx_eventHandler_editor.removeEventListener('mousemove', this.$_mx_eventHandler_callMouseMove)
      }
    },
    /**
     * Fire mouse move event
     * @param evt
     * @private
     */
    $_mx_eventHandler_callMouseMove (evt) {
      this.$events.fire(editorEventTypes.EDITOR_MOUSEMOVE, evt)
    },
    /**
     * Fire handle circle events
     * @param attrs
     * @private
     */
    $_mx_eventHandler_handleCircles (attrs) {
      if (attrs.evt.which === 3 && attrs.ctrl !== null) {
        this.$events.fire(editorEventTypes.OBJECT_MODIFIER_OPEN_MENU, attrs)
      }
      if (attrs.evt.which === 1 && !attrs.handle) {
        this.$events.fire(editorEventTypes.OBJECT_MODIFIER_DRAG, attrs)
      }
      if (attrs.evt.which === 1 && attrs.handle) {
        this.$events.fire(editorEventTypes.OBJECT_HANDLE_MODIFIER_DRAG, attrs)
      }
    },
    /**
     * Handle mouse down events
     * @param evt
     * @private
     */
    $_mx_eventHandler_mouseDown (evt) {
      this.$_mx_eventHandler_activateMouseMove()
      if ((evt.target.id === 'surfaceUnderlay' || evt.target.id === 'editor-grid' || evt.target.id === 'svg-editor') &&
                this.$_mx_eventHandler_eventCtrl === 'move') {
        this.$_mx_eventHandler_setTarget('viewBox')
      }
      if (this.$_mx_eventHandler_eventCtrl === 'draw') {
        if (this.$_mx_eventHandler_currentPath === null) {
          this.mx_trackstate_beginStateTransaction()
        }
        this.$_mx_eventHandler_setTarget('drawSvg')
        this.$events.fire(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, evt)
      }
      if (this.$_mx_eventHandler_eventCtrl === 'refline') {
        this.$_mx_eventHandler_setTarget('drawRefLine')
        this.$events.fire(editorEventTypes.REFERENCE_LINE_UPDATE_MOUSE, evt)
      }
      if (this.$_mx_eventHandler_eventCtrl === 'ruler') {
        this.$_mx_eventHandler_setTarget('drawRulerLine')
        this.$events.fire(editorEventTypes.RULER_UPDATE_MOUSE, evt)
      }
      if (this.$_mx_eventHandler_eventCtrl === 'drawDefault') {
        this.$_mx_eventHandler_setTarget('drawDefaultObject')
        this.$events.fire(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, evt)
      }
    },
    /**
     * Fire mouse move events
     * @param evt
     * @private
     */
    $_mx_eventHandler_mouseMove (evt) {
      switch (this.$_mx_eventHandler_target) {
        case 'viewBox':
          this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_MOUSE, evt)
          break
        case 'drawSvg':
          this.$events.fire(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, evt)
          break
        case 'drawRefLine':
          this.$events.fire(editorEventTypes.REFERENCE_LINE_UPDATE_MOUSE, evt)
          break
        case 'moveRefPointStart':
          this.$events.fire(editorEventTypes.REFERENCE_LINE_MODIFY_START_POINT, evt)
          break
        case 'moveRefPointEnd':
          this.$events.fire(editorEventTypes.REFERENCE_LINE_MODIFY_END_POINT, evt)
          break
        case 'drawRulerLine':
          this.$events.fire(editorEventTypes.RULER_UPDATE_MOUSE, evt)
          break
        case 'moveRulerPointStart':
          this.$events.fire(editorEventTypes.RULER_MODIFY_START_POINT, evt)
          break
        case 'moveRulerPointEnd':
          this.$events.fire(editorEventTypes.RULER_MODIFY_END_POINT, evt)
          break
        case 'drawDefaultObject':
          this.$events.fire(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, evt)
          break
      }
      if (this.$_mx_eventHandler_panMode) {
        this.$events.fire(editorEventTypes.VIEWBOX_MOVE_BY_MOUSE, evt)
      }
    },
    /**
     * Fire mouse up events
     * @param evt
     * @private
     */
    $_mx_eventHandler_mouseUp (evt) {
      switch (this.$_mx_eventHandler_target) {
        case 'viewBox':
          this.$_mx_eventHandler_setTarget(null)
          if (!this.$_mx_eventHandler_losMode && !this.$_mx_eventHandler_cutMode) {
            this.$events.fire(editorEventTypes.PROJECT_CLEAR_OBJECT_SELECTION)
          }
          this.$_mx_eventHandler_deactivateMouseMove()
          break
        case 'drawSvg':
          this.$events.fire(editorEventTypes.DRAW_ADD_POINT_TO_PATH, evt)
          break
        case 'drawRefLine':
          this.$events.fire(editorEventTypes.REFERENCE_LINE_ADD_POINT, evt)
          break
        case 'drawRulerLine':
          this.$events.fire(editorEventTypes.RULER_ADD_POINT, evt)
          break
        case 'drawDefaultObject':
          this.$events.fire(editorEventTypes.DRAW_ADD_POINT_TO_DEFAULT_PATH, evt)
          break
      }
    },
    /**
     * Grab scroll event
     * @param evt
     * @private
     */
    $_mx_eventHandler_grabScroll (evt) {
      const mouseData = { trigger: evt.deltaMode === 0 ? 42 : 1, delta: evt.wheelData ? -(evt.wheelDeltaY || evt.wheelDelta || 0) : evt.deltaY }
      this.$data.$_mx_eventHandler_scrollCounter += mouseData.delta
      this.$_mx_eventHandler_fireScale(mouseData.trigger, evt)
      evt.preventDefault()
      evt.stopPropagation()
    },
    /**
     * Fire scale events if asked amount of scroll was achieved
     * @param trigger - asked amount of scroll
     * @param e - event
     */
    $_mx_eventHandler_fireScale (trigger, e) {
      if (Math.abs(this.$data.$_mx_eventHandler_scrollCounter) > trigger) {
        if (this.$data.$_mx_eventHandler_scrollCounter > 0) {
          this.$events.fire(editorEventTypes.EDITOR_SCALE_OUT, { x: e.offsetX, y: e.offsetY, event: e })
        } else {
          this.$events.fire(editorEventTypes.EDITOR_SCALE_IN, { x: e.offsetX, y: e.offsetY, event: e })
        }
        this.$data.$_mx_eventHandler_scrollCounter = 0
      }
    },
    /**
     * Change the ctrl
     * @param ctrl
     * @private
     */
    $_mx_eventHandler_changeEventCtrl (ctrl) {
      this.$_mx_eventHandler_setEventCtrl(ctrl)
      if (this.ctrl === 'move') {
        this.$_mx_eventHandler_setTarget(null)
      }
      if (this.ctrl === 'draw' && this.ctrl === 'refline') {
        this.$_mx_eventHandler_clearObjectSelection()
      }
    },
    /**
     * Loop key down events
     * @param {string|string[]} shortcut       shortcut or array of them which activates commands
     * @param {function} commandsOnPress       function on press (write () => {} if not needed)
     * @param {boolean} repeatable             repeat it if keys are hold
     * @param {function} commandsOnRelease     function on release (write () => {} if not needed)
     * @param {boolean} force                  do the stuff even if 'mx_eventHandler_shouldBlockShortcuts' is true
     * @param {boolean} preventDefault         just prevent default actions in browser for this shortcut
     */
    $_mx_eventHandler_keyDown (shortcut, commandsOnPress, repeatable = false, commandsOnRelease = () => {
    }, force = false, preventDefault = true) {
      kbListener.bind(
        shortcut,
        (e) => {
          // devlog.log('Block shortcuts: ', this.mx_eventHandler_shouldBlockShortcuts)
          if (!this.mx_eventHandler_shouldBlockShortcuts || force) {
            if (preventDefault && e.preventDefault) {
              e.preventDefault()
            }
            if (this.$data.$_mx_eventHandler_keyTimeout === null) {
              if (repeatable) {
                this.$data.$_mx_eventHandler_keyTimeout = setTimeout(() => {
                  this.$data.$_mx_eventHandler_keyTimeout = null
                }, config.keyInterval)
              } else {
                e.preventRepeat()
              }
              commandsOnPress()
            }
          }
        },
        (e) => {
          if (!this.mx_eventHandler_shouldBlockShortcuts || force) {
            clearTimeout(this.$data.$_mx_eventHandler_keyTimeout)
            this.$data.$_mx_eventHandler_keyTimeout = null
            commandsOnRelease()
          }
        }
      )
    }
  },
  watch: {
    $_mx_eventHandler_editor (n) {
      if (n !== null) {
        this.$_mx_eventHandler_initEvents()
      }
    }
  }
}
