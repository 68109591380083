<template>
  <transition name="c-nav-head-side">
    <nav
      v-show="sidenav === 'editor'"
      :class="`c-nav-head-side--${getTheme}-theme`"
      class="c-nav-head-side"
      @click="_handleClickEvent"
    >
      <!-- Close button -->

      <button-icon
        id="head-side-nav-close"
        classes="c-nav-head-side__close"
        icon-classes="fas fa-2x fa-times"
        style-extra="display: grid"
        style-color="darker"
        style-font-size="m"
        @click="setSideNav(null)"
      />

      <!-- Menu -->
      <language-switch />
      <button
        v-if="$router.currentRoute.name === 'Melos-Editor'"
        class="c-nav-head-side__item"
        @click="doCloseProject('Projects')"
      >
        <icon classes="fas fa-2x fa-home" />
        {{ $t('headNav.dashboard') }}
      </button>
      <button
        v-if="$router.currentRoute.name === 'Melos-Editor'"
        class="c-nav-head-side__item"
        disabled
        @click="doCloseProject('Settings')"
      >
        <icon classes="fas fa-2x fa-cog" />
        {{ $t('headNav.settings') }}
      </button>
      <button
        v-if="$router.currentRoute.name === 'Melos-Editor'"
        class="c-nav-head-side__item"
        @click="doCloseProject('Profile')"
      >
        <icon classes="fas fa-2x fa-user-circle" />
        {{ $t('headNav.profile') }}
      </button>
      <button
        class="c-nav-head-side__item"
        @click="doCloseProject('Login')"
      >
        <icon classes="fas fa-2x fa-sign-out-alt" />
        {{ $t('headNav.logout') }}
      </button>

      <div class="c-nav-head-side__logo" />
    </nav>
  </transition>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ButtonIcon from '@/components/common/ButtonIcon'
import MixinDialogHandler from '@/mixins/dialogHandler'
import * as mutationTypes from '@/vuex/mutation-types'
import Icon from '@/components/common/Icon'
import LanguageSwitch from '@/modules/language/Switch'

export default {
  name: 'HeadSideNav',
  components: { LanguageSwitch, Icon, ButtonIcon },
  mixins: [MixinDialogHandler],
  computed: {
    ...mapState({
      sidenav: state => state.navigation.sidenav
    }),
    ...mapGetters({
      getTheme: 'gui/getTheme'
    })
  },
  methods: {
    ...mapMutations({
      setSideNav: 'navigation/' + mutationTypes.HEADNAV_SET_SIDENAV
    }),
    ...mapActions({
      projectHasChanges: 'history/projectHasChanges',
      navCloseProject: 'navigation/closeProject'
    }),
    /**
     * Stop any further actions on click
     */
    _handleClickEvent (e) {
      e.preventDefault()
      e.stopPropagation()
    },
    /**
     * Close the project - check, if project has changes
     * @returns {Promise<void>}
     */
    async doCloseProject (route) {
      const changes = await this.projectHasChanges()
      if (this.$router.currentRoute.name === 'Melos-Editor' && changes) {
        this.$_mx_dialogHandler_openDialog({
          dialog: 'closeProject',
          onOff: true,
          dialogProps: { route: route }
        })
      } else {
        await this.navCloseProject(route)
        await this.$router.push({ name: route })
      }
    }
  }
}
</script>
