<template>
  <g
    v-if="currentSelectedObjects.length > 0 && !hideBox && mx_bbox_bounds !== null"
    id="boundingBox"
    :transform="transform"
  >
    <rect
      v-if="rotating === null"
      :x="mx_bbox_bounds.x - mx_bbox_bBoxOffset"
      :y="mx_bbox_bounds.y - mx_bbox_bBoxOffset"
      :width="mx_bbox_bounds.width + 2 * mx_bbox_bBoxOffset"
      :height="mx_bbox_bounds.height + 2 * mx_bbox_bBoxOffset"
      fill="none"
      stroke="grey"
      :stroke-width="mx_bbox_strokeWidth"
      :stroke-dasharray="mx_bbox_dashArray"
    />
    <template v-if="rotating === null && !mx_bbox_isCutOutObject">
      <circle
        v-for="(handle, index) in mx_bbox_handles"
        :key="'bbox-handles-' + index"
        :class="'handle-'+handle.handle"
        :r="mx_bbox_circleRadius"
        :cx="handle.x"
        :cy="handle.y"
        fill="rgba(0,0,0,1)"
        stroke="black"
        :stroke-width="mx_bbox_strokeWidth"
        @mousedown="startScale($event,handle)"
      />
    </template>
    <svg
      v-if="mx_bbox_rotationHandle !== null && !mx_bbox_isCutOutObject"
      :key="'bbox-handles-' + mx_bbox_rotationHandle.handle"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      :width="mx_bbox_rotationHandle.width"
      :height="mx_bbox_rotationHandle.height"
      :class="'handle-' + mx_bbox_rotationHandle.handle"
      :x="mx_bbox_rotationHandle.x"
      :y="mx_bbox_rotationHandle.y"
      fill="rgba(0,0,0,1)"
      stroke="black"
      :stroke-width="mx_bbox_strokeWidth"
      @mousedown="startRotation($event,mx_bbox_rotationHandle.handle)"
    >
      <rect
        stroke-width="0"
        fill="black"
        width="100%"
        height="100%"
        fill-opacity="0"
      />
      <path
        transform="scale(1)"
        fill="currentColor"
        d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"
      />
    </svg>
  </g>
</template>

<script>
import config from '@/config'

import { mapState } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'

import MixinBbox from '@/mixins/bbox'
import MixinMath from '@/mixins/math'
import MixinTrackState from '@/mixins/trackstate'

/**
     * Shows a bounding box over all selected objects
     * @displayName Object Bounding Box
     */
export default {
  name: 'GlobalBoundingBox',
  mixins: [MixinBbox, MixinMath, MixinTrackState],
  data: () => {
    return {
      resizingStart: null,
      resizingStartSize: null,
      rotating: null
    }
  },
  computed: {
    ...mapState({
      translateObjects: state => state.project.object.translate,
      rotateObject: state => state.project.object.rotate
    }),
    transform () {
      return (this.mx_bbox_isCutOutObject) ? '' : `translate(${this.translateObjects.x} ${this.translateObjects.y}) rotate(${this.rotateObject.deg} ${this.rotateObject.cx} ${this.rotateObject.cy})`
    },
    objectAllowedToRotate () {
      if (this.currentSelectedObjects.length === 1) {
        const obj = this.mx_bbox_getObjectById(this.currentSelectedObjects[0])

        return (obj.type !== config.objects.types.CIRCLE)
      }

      return true
    },
    hideBox () {
      if (this.currentSelectedObjects.length === 1) {
        const obj = this.mx_bbox_getObjectById(this.currentSelectedObjects[0])

        if (obj !== undefined) {
          return (obj.selectionMode === config.objects.modes.EDIT) ||
            (obj.selectionMode === config.objects.modes.LOS)
        }
      }
      return false
    }
  },
  watch: {
    currentSelectedObjects: {
      handler (n, o) {
        this.setBoundingBoxProperties()
      },
      deep: true
    }
  },
  mounted () {
    document.addEventListener('mouseup', this.stopScale)
    document.addEventListener('mousemove', this.mx_bbox_resize)
    document.addEventListener('mouseup', this.stopRotation)
    document.addEventListener('mousemove', this.mx_bbox_rotate)
  },
  beforeDestroy () {
    document.removeEventListener('mouseup', this.stopScale)
    document.removeEventListener('mousemove', this.mx_bbox_resize)
    document.removeEventListener('mouseup', this.stopRotation)
    document.removeEventListener('mousemove', this.mx_bbox_rotate)
  },
  beforeMount () {
    this.$store.watch((state) => state.project.objects.objects, (nObj, oObj) => {
      if (nObj.length > 0) {
        this.$nextTick(() => {
          this.setBoundingBoxProperties()
        })
      }
    }, { deep: true })
  },
  methods: {
    setBoundingBoxProperties () {
      let x, y, w, h

      x = y = Number.POSITIVE_INFINITY
      w = h = Number.NEGATIVE_INFINITY

      if (!this.mx_bbox_isCutOutObject) {
        this.elsBounds = []

        this.currentSelectedObjects.forEach(selected => {
          const el = document.getElementById(selected)
          if (el) {
            const bounds = el.getBBox()

            this.elsBounds.push({
              id: selected,
              bounds: bounds
            })

            if (bounds.x < x) x = bounds.x
            if (bounds.y < y) y = bounds.y
            if (bounds.x + bounds.width > w) w = bounds.x + bounds.width
            if (bounds.y + bounds.height > h) h = bounds.y + bounds.height
          }
        })

        if (x !== Number.POSITIVE_INFINITY && y !== Number.POSITIVE_INFINITY && w !== Number.NEGATIVE_INFINITY && h !== Number.NEGATIVE_INFINITY) {
          this.mx_bbox_bounds = {
            x: x,
            y: y,
            width: w - x,
            height: h - y
          }
        } else {
          this.mx_bbox_bounds = null
        }
      }
    },
    startScale (evt, handle) {
      if (handle) {
        this.mx_trackstate_beginStateTransaction()
        this.resizingStart = this.mx_math_getCoordinatesFromEvent(evt)
        this.resizing = handle
        this.resizingStartElsBbox = Object.assign(this.elsBounds, {})
        this.resizingStartBbox = Object.assign(this.mx_bbox_bounds, {})
        this.setLastInternalAction({ action: 'scale', ids: this.currentSelectedObjects })
      }
    },
    stopScale () {
      if (this.resizing) {
        this.mx_trackstate_endStateTransaction()
        this.setLastInternalAction({ action: 'scaleend', ids: this.currentSelectedObjects })
        this.resizing = null
        this.resizingStart = null
        this.resizingStartBbox = null
        this.resizingStartElsBbox = null
        // this.productNeedUpdate(this.object.id)
      }
    },
    startRotation (e, handle) {
      if (handle) {
        this.mx_trackstate_beginStateTransaction()
        this.rotating = handle
        this.$events.fire(editorEventTypes.OBJECT_ROTATE, this.rotating)
      }
    },
    stopRotation () {
      if (this.rotating) {
        this.rotating = null
        this.$_rotateObjects()
        this.$events.fire(editorEventTypes.OBJECT_ROTATE, null)
        this.mx_trackstate_endStateTransaction()
      }
    },
    $_scaleObjects (scaleBy) {
      this.scaleObjects({
        ids: this.currentSelectedObjects,
        elements: this.resizingStartElsBbox,
        scale: scaleBy,
        origBbox: this.resizingStartBbox,
        direction: this.resizing.handle,
        cut: false
      })
    },
    $_rotateObjects (rotateBy) {
      this.rotateObjects({
        ids: this.currentSelectedObjects,
        cut: false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    .handle-tr:hover, .handle-bl:hover {
        cursor: nesw-resize;
    }

    .handle-tm:hover, .handle-bm:hover {
        cursor: ns-resize;
    }

    .handle-lm:hover, .handle-rm:hover {
        cursor: ew-resize;
    }

    .handle-tl:hover, .handle-br:hover {
        cursor: nwse-resize;
    }
</style>
