
/**
 * @displayName File
 */
import { apiMethods, mediaRequestNames } from '@/helper/apiHelper'
import _ from 'lodash'

const defaultState = function () {
  return {
    // used for old forms (ex. group)
    mx_file_file: null,

    // used for products
    mx_file_image: null,
    mx_file_media_images: null,
    mx_file_media_documents: null
  }
}

export default {
  name: 'LineOfSightMixin',
  data: () => { return defaultState() },
  methods: {
    /**
     * Reset mixin state
     * @public
     */
    mx_file_clearData () {
      Object.assign(this.$data, defaultState())
    },
    /**
     * Does all media requests for files attached to form
     * @param route
     * @param token
     * @param dispatch
     * @public
     * @returns {{ postMediaResponse: Promise<T>, deleteMediaResponse: Promise<T> }}
     */
    async mx_file_doMediaRequests (route, token, dispatch) {
      const responses = {}
      const allNewFiles = []
      let allToDelete = []

      /* COLLECT NEW FILES */
      // File
      if (Object.hasOwnProperty.call(this.mx_file_file !== null &&
        this.mx_file_file, 'newFiles') && this.mx_file_file.newFiles.length) {
        allNewFiles.push({
          files: this.mx_file_file.newFiles,
          name: mediaRequestNames.file
        })
      }
      // Image
      if (Object.hasOwnProperty.call(this.mx_file_image !== null &&
        this.mx_file_image, 'newFiles') && this.mx_file_image.newFiles.length) {
        allNewFiles.push({
          files: this.mx_file_image.newFiles,
          name: mediaRequestNames.image
        })
      }
      // Media images
      if (Object.hasOwnProperty.call(this.mx_file_media_images !== null &&
        this.mx_file_media_images, 'newFiles') && this.mx_file_media_images.newFiles.length) {
        allNewFiles.push({
          files: this.mx_file_media_images.newFiles,
          name: mediaRequestNames.media_images
        })
      }
      // Media documents
      if (Object.hasOwnProperty.call(this.mx_file_media_documents !== null &&
        this.mx_file_media_documents, 'newFiles') && this.mx_file_media_documents.newFiles.length) {
        allNewFiles.push({
          files: this.mx_file_media_documents.newFiles,
          name: mediaRequestNames.media_documents
        })
      }

      /* COLLECT FILES TO DELETE */
      // Theres no Delete for 'file' media right now
      // Image
      if (Object.hasOwnProperty.call(this.mx_file_image !== null &&
        this.mx_file_image, 'toDelete') && this.mx_file_image.toDelete.length) {
        allToDelete = [
          ...allToDelete,
          ...this.mx_file_image.toDelete
        ]
      }
      // Media images
      if (Object.hasOwnProperty.call(this.mx_file_media_images !== null &&
        this.mx_file_media_images, 'toDelete') && this.mx_file_media_images.toDelete.length) {
        allToDelete = [
          ...allToDelete,
          ...this.mx_file_media_images.toDelete
        ]
      }
      // Media documents
      if (Object.hasOwnProperty.call(this.mx_file_media_documents !== null &&
        this.mx_file_media_documents, 'toDelete') && this.mx_file_media_documents.toDelete.length) {
        allToDelete = [
          ...allToDelete,
          ...this.mx_file_media_documents.toDelete
        ]
      }

      // first delete for the case of using the same file again
      // (it will be deleted and then saved again on a sever)
      responses.deleteMediaResponse = !_.isEmpty(allToDelete)
        ? await apiMethods.deleteFiles(route, token, dispatch, allToDelete)
        : null
      responses.postMediaResponse = !_.isEmpty(allNewFiles)
        ? await apiMethods.uploadFiles(route, token, dispatch, allNewFiles)
        : null

      return responses
    }
  }
}
