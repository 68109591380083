<template>
  <g
    v-if="show"
    id="editor-grid-group"
  >
    <defs>
      <pattern
        id="patternGrid"
        patternUnits="userSpaceOnUse"
        :width="wh"
        :height="wh"
      >
        <line
          :stroke-width="strokeWidth"
          :y2="wh"
          stroke="#ccc"
          x1="0"
          x2="0"
          y1="0"
        />
        <line
          :stroke-width="strokeWidth"
          :x2="wh"
          :y1="wh"
          :y2="wh"
          stroke="#ccc"
          x1="0"
        />
      </pattern>
    </defs>
    <rect
      id="editor-grid"
      :fill="'url(#patternGrid)'"
      :x="rectX"
      :y="rectY"
      height="100%"
      width="100%"
    />
  </g>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import * as mutationTypes from '@/vuex/mutation-types'

import MixinMath from '@/mixins/math'

/**
     * Displays the grid-system in the surface
     * @displayName Surface Grid
     */
export default {
  name: 'EditorGrid',
  mixins: [
    MixinMath
  ],
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      raster: state => state.editor.grid.raster,
      show: state => state.editor.grid.show,
      commonLineStrokeWidth: state => state.common.svg.lineStrokeWidth
    }),
    ...mapGetters({
      rectX: 'editor/viewbox/x',
      rectY: 'editor/viewbox/y'
    }),
    strokeWidth () {
      return this.mx_math_handleElementsOnScale(this.commonLineStrokeWidth)
    },
    wh () {
      return (parseFloat(this.raster) / this.mx_math_replaceDecimalPointWithDot(this.measure.toUnit)) * this.mx_math_replaceDecimalPointWithDot(this.measure.fromPixels)
    }
  },
  methods: {}
}
</script>
