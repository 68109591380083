<template>
  <g
    v-if="isObjectSelectedById(id) || waste"
  >
    <defs>
      <marker
        id="marker--arrow"
        viewBox="0 0 10 10"
        refX="5"
        refY="5"
        markerWidth="6"
        markerHeight="6"
        orient="auto"
      >
        <path d="M 0 0 L 10 5 L 0 10 z" />
      </marker>
      <g v-if="trackPattern !== null">
        <pattern
          :id="`angle-arrows-${id}${waste ? '-waste' : ''}`"
          patternUnits="userSpaceOnUse"
          patternContentUnits="userSpaceOnUse"
          preserveAspectRatio="none"
          :patternTransform="trackPattern.rotation"
          :width="trackPattern.width"
          :height="trackPattern.width"
          :x="trackPattern.topLeft.x"
          :y="trackPattern.topLeft.y"
        >
          <image
            :xlink:href="require(`@/assets/img/los-pattern.svg`)"
            :width="trackPattern.width"
            :height="trackPattern.width"
            opacity=".2"
          />
        </pattern>
        <pattern
          :id="`angle-arrows-selected-${id}`"
          patternUnits="userSpaceOnUse"
          patternContentUnits="userSpaceOnUse"
          preserveAspectRatio="none"
          :patternTransform="trackPattern.rotation"
          :width="trackPattern.width"
          :height="trackPattern.width"
          :x="trackPattern.topLeft.x"
          :y="trackPattern.topLeft.y"
        >
          <image
            :xlink:href="require(`@/assets/img/los-pattern-selected.svg`)"
            :width="trackPattern.width"
            :height="trackPattern.width"
            opacity=".9"
          />
        </pattern>
        <pattern
          :id="`angle-arrows-oversize-${id}`"
          patternUnits="userSpaceOnUse"
          patternContentUnits="userSpaceOnUse"
          preserveAspectRatio="none"
          :patternTransform="trackPattern.rotation"
          :width="trackPattern.width"
          :height="trackPattern.width"
          :x="trackPattern.topLeft.x"
          :y="trackPattern.topLeft.y"
        >
          <image
            :xlink:href="require(`@/assets/img/los-pattern-oversize.svg`)"
            :width="trackPattern.width"
            :height="trackPattern.width"
            opacity=".7"
          />
        </pattern>
      </g>
    </defs>
    <vnodes :vnodes="pseudoTracks" />
    <g id="trackBBox">
      <vnodes
        v-if="showTracks"
        :vnodes="tracks"
      />
    </g>
    <los-lines
      v-if="!showPseudoTracks"
      :lines="(showObjectLines) ? getObjectLines : getTrackCutLines"
      :angle="getObjectProduct.angle"
      :measure="getMeasure"
      @objectLineSelected="changeObjectProductAngle"
    />
  </g>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import MixinMath from '@/mixins/math'
import Paper from 'paper'
import config from '@/config'

import LosLines from '@/components/modules/lineOfSight/svg/LosLines'

const convert_value_to_pixels = (measure, value) => {
  return (value / measure.toUnit) * measure.fromPixels
}

export default {
  name: 'LosTracks',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    },
    LosLines
  },
  mixins: [
    MixinMath
  ],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    waste: {
      type: [Boolean],
      required: true
    }
  },
  data: () => {
    return {
      trackPattern: null,
      showTracks: true,
      showPseudoTracks: false,
      showObjectLines: false,
      pseudoAngle: null,
      pseudoShift: null
    }
  },
  computed: {
    ...mapState({
      commonSvgSettings: state => state.common.svg
    }),
    ...mapGetters({
      getMeasure: 'project/measurement/getMeasure',
      getObject: 'lineOfSightNew/getObject',
      getObjectTracks: 'lineOfSightNew/getObjectTracks',
      getObjectProduct: 'lineOfSightNew/getObjectProduct',
      getObjectLines: 'lineOfSightNew/getObjectLines',
      isObjectSelectedById: 'lineOfSightNew/isObjectSelectedById',
      isWasteObjectById: 'lineOfSightNew/isWasteObjectById',
      isWasteObjectTrackByName: 'lineOfSightNew/isWasteObjectTrackByName',
      isUseFromObjectTrackByName: 'lineOfSightNew/isUseFromObjectTrackByName',
      isWasteObjectSet: 'lineOfSightNew/isWasteObjectSet',
      getTrackCutLines: 'lineOfSightNew/getTrackCutLines',
      getSelectedTrackCutLine: 'lineOfSightNew/getSelectedTrackCutLine',
      isTrackSelected: 'lineOfSightNew/isTrackSelected',
      isUseFromObjectById: 'lineOfSightNew/isUseFromObjectById',
      getWasteData: 'lineOfSightNew/getWasteData',
      getUseFromWaste: 'lineOfSightNew/getUseFromWaste'
    }),
    pseudoTracks () {
      const vnodeLines = []

      if (this.getObjectProduct && !this.isWasteObjectById(this.getObject.id) && this.showPseudoTracks) {
        const scope = new Paper.PaperScope().setup(1, 1)

        const properties = {
          angle: (this.pseudoAngle !== null) ? this.pseudoAngle : this.getObjectProduct.angle,
          width: convert_value_to_pixels(this.getMeasure, this.getObjectProduct.width),
          shift: convert_value_to_pixels(this.getMeasure, (this.pseudoShift !== null) ? this.pseudoShift : this.getObjectProduct.shift),
          alignment: this.getObjectProduct.alignment
        }

        const cp = new scope.CompoundPath(this.$_get_object_path_data())
        const center = cp.bounds.clone().center
        cp.rotate(-properties.angle, center)

        let max_infinity_turf_rolls = Math.ceil((cp.bounds.width + properties.shift) / properties.width)
        let i = (properties.alignment) ? 0 : -1
        max_infinity_turf_rolls += (properties.alignment) ? 1 : 0

        for (i; i < max_infinity_turf_rolls; i++) {
          const x = (properties.alignment) ? (cp.bounds.x - properties.shift) + (properties.width * i)
            : (cp.bounds.x + cp.bounds.width + properties.shift) - (properties.width * (i + 1))

          const from = new Paper.Point(x, cp.bounds.y)
          const to = new Paper.Point(x, cp.bounds.y + cp.bounds.height)

          const line = new Paper.Path.Line(from, to)

          const start = `${from.x},${from.y}`
          const middle = `${line.bounds.center.x},${line.bounds.center.y}`
          const end = `${to.x},${to.y}`

          const vLine = this.$createElement('polyline', {
            attrs: {
              points: `${start} ${middle} ${end}`,
              stroke: 'red',
              'stroke-width': this.mx_math_handleElementsOnScale(1),
              fill: 'none',
              'stroke-opacity': 1,
              transform: `rotate(${properties.angle} ${center.x} ${center.y})`,
              'stroke-dasharray': this.commonSvgSettings.line.dashLength + ',' + this.commonSvgSettings.line.dashSpace,
              'marker-start': 'url(#marker--arrow)',
              'marker-mid': 'url(#marker--arrow)',
              'marker-end': 'url(#marker--arrow)'
            }
          })

          vnodeLines.push(vLine)
          line.remove()
        }

        cp.remove()
      }

      return (vnodeLines.length > 0) ? vnodeLines : null
    },
    tracks () {
      if (this.getObjectTracks && !this.getObject.productNeedUpdate) {
        const objTracks = []
        const tracks = (this.waste) ? this.getUseFromWaste.object.product.tracks : this.getObjectTracks

        const normalTracks = tracks.filter(t => !this.isWasteObjectTrackByName(t.name, this.id))
        const wasteTrack = tracks.filter(t => this.isWasteObjectTrackByName(t.name, this.id))

        normalTracks.forEach(track => {
          if (!this.waste && this.isWasteObjectSet) {
            return
          }
          const isSelectedTrack = this.isTrackSelected(track, this.id)

          const fillOpacity = (isSelectedTrack) ? 1 : 0.5
          const strokeWidth = (isSelectedTrack) ? this.mx_math_handleElementsOnScale(2) : this.mx_math_handleElementsOnScale(1)
          const fill = (Number.parseFloat(track.oversize) !== 0) ? `url(#angle-arrows-oversize-${this.id})` : (isSelectedTrack) ? `url(#angle-arrows-selected-${this.id})` : `url(#angle-arrows-${this.id}${this.waste ? '-waste' : ''})`

          const _track = this.$createElement('path', {
            class: {},
            attrs: {
              id: track.name,
              d: track.getPathData(),
              fill: fill,
              'fill-opacity': fillOpacity,
              stroke: this.isUseFromObjectTrackByName(track.name, this.id) ? 'yellow' : track.usedWaste ? 'orange' : 'black',
              'stroke-width': strokeWidth
            },
            on: {
              click: e => {
                if (this.getSelectedTrackCutLine === null && !this.isWasteObjectSet) {
                  this.setTrackSelected({
                    insert: e.shiftKey,
                    track: track
                  })
                } else {
                  this.setUseFromObjectTrack(track)
                }
              }
            }
          })
          objTracks.push(_track)
        })

        wasteTrack.forEach(track => {
          const _track = this.$_get_object_tracks(track, 1, this.mx_math_handleElementsOnScale(1), `url(#angle-arrows-${this.id}${this.waste ? '-waste' : ''}`, true)

          objTracks.push(_track)
        })

        this.createTrackPattern(objTracks)

        return objTracks
      }

      return null
    }
  },
  mounted () {
    this.$events.on('LINE_OF_SIGHT_HIDE_PSEUDO_TRACKS', this.evtShowTracks)
    this.$events.on('LINE_OF_SIGHT_SHOW_PSEUDO_TRACKS', this.evtShowPseudoTracks)
    this.$events.on('LINE_OF_SIGHT_SELECT_OBJECT_LINE', this.evtShowObjectLines)
  },
  beforeDestroy () {
    this.$events.off('LINE_OF_SIGHT_HIDE_PSEUDO_TRACKS', this.evtShowTracks)
    this.$events.off('LINE_OF_SIGHT_SHOW_PSEUDO_TRACKS', this.evtShowPseudoTracks)
    this.$events.off('LINE_OF_SIGHT_SELECT_OBJECT_LINE', this.evtShowObjectLines)
  },
  methods: {
    ...mapActions({
      setTrackSelected: 'lineOfSightNew/setTrackSelected',
      setUseFromObjectTrack: 'lineOfSightNew/setUseFromObjectTrack'
    }),
    evtShowObjectLines (value) {
      this.showObjectLines = value
      this.showTracks = !value
    },
    evtShowTracks () {
      this.showTracks = true
      this.showPseudoTracks = false
      this.pseudoAngle = null
      this.pseudoShift = null
    },
    evtShowPseudoTracks (data) {
      this.pseudoAngle = Object.prototype.hasOwnProperty.call(data, 'angle') ? data.angle : null
      this.pseudoShift = Object.prototype.hasOwnProperty.call(data, 'shift') ? data.shift : null
      this.showTracks = false
      this.showPseudoTracks = true
    },
    createTrackPattern (tracks) {
      if (tracks.length > 0) {
        const scope = new Paper.PaperScope().setup(1, 1)
        const cpObject = new scope.CompoundPath(this.$_get_object_path_data())
        const cpTracks = new scope.CompoundPath(tracks.map(t => t.data.attrs.d).join(''))

        const angle = (!this.waste) ? this.getObjectProduct.angle : this.getUseFromWaste.object.product.angle

        cpTracks.rotate(-Number(angle), cpObject.bounds.center)

        this.trackPattern = {
          width: cpTracks.firstChild.bounds.width,
          topLeft: cpTracks.bounds.topLeft,
          rotation: `rotate(${angle},${cpObject.bounds.center.x},${cpObject.bounds.center.y})`
        }
      }
    },
    changeObjectProductAngle (data) {
      this.$events.fire('LINE_OF_SIGHT_SELECT_OBJECT_LINE', false)
      this.$events.fire('LINE_OF_SIGHT_PRODUCT_PROPERTIES_CHANGED', { action: 'productAngleChanged', data: { angle: data.angle } })
    },
    $_get_object_tracks (track, fillOpacity, strokeWidth, fill, isWasteObjectTrack) {
      return this.$createElement('path', {
        class: {},
        attrs: {
          id: track.name,
          d: track.getPathData(),
          fill: fill,
          'fill-opacity': fillOpacity,
          stroke: isWasteObjectTrack ? 'red' : 'black',
          'stroke-width': strokeWidth
        }
      })
    },
    $_get_object_path_data () {
      if (this.getObject.type === config.objects.types.CIRCLE) {
        const props = this.getObject.getCircleProperties()
        return new Paper.CompoundPath(new Paper.Path.Circle({
          center: [props.middle.x, props.middle.y],
          radius: props.radius
        })).pathData
      } else {
        return new Paper.CompoundPath(this.getObject.d(true)).pathData
      }
    }
  }
}
</script>
