<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createEmployee.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createEmployee.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <drop-down
            id="create-employee-salutation"
            v-model="employee.salutation"
            :items="mx_languages_getSalutations"
            :label="$t('dialogs.createEmployee.salutation')"
            :required="true"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="label"
            style-current-item-width="240px"
            value-key="value"
          />
          <input-field
            id="create-employee-first-name"
            v-model="employee.firstname"
            :focus-on-mount="true"
            :is-store-mode="false"
            :label="$t('dialogs.createEmployee.firstName')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-employee-last-name"
            v-model="employee.lastname"
            :is-store-mode="false"
            :label="$t('dialogs.createEmployee.lastName')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-employee-email"
            v-model="employee.email"
            :is-store-mode="false"
            :label="$t('dialogs.createEmployee.email')"
            :required="true"
            :validate="(v) => {return mx_validate_validateEmail(v)}"
            placeholder="email@company.com"
            style-input-width="240px"
            type="email"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-employee-tel"
            v-model="employee.phone"
            :is-store-mode="false"
            :label="$t('dialogs.createEmployee.phone')"
            :required="true"
            :validate="(v) => {return mx_validate_validatePhone(v)}"
            placeholder="+49 1234 567 890"
            style-input-width="240px"
            type="tel"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <drop-down
            id="create-employee-group"
            v-model="employee.group_id"
            :items="getLocations"
            :label="$t('dialogs.createEmployee.location')"
            :required="true"
            :style-up="true"
            classes="u-mt-size-standard"
            label-key="name"
            style-current-item-width="240px"
            value-key="id"
          />
          <drop-down
            id="create-employee-language"
            v-model="employee.preferred_language"
            :items="mx_languages_getAvailableLanguages"
            :label="$t('dialogs.createEmployee.preferred_language')"
            :required="true"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="label"
            style-current-item-width="240px"
            value-key="value"
          />
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-employee-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-employee-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewEmployee() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import config from '@/config'

import { apiMethods } from '@/helper/apiHelper'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinLanguages from '@/mixins/languages'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Create Employee
     */
export default {
  name: 'CreateEmployee',
  components: {
    ButtonBase,
    DropDown,
    DialogFrame,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinLanguages
  ],
  data: () => {
    return {
      employee: apiMethods.getModel('employee'),
      targetDialog: 'createEmployee',
      cannotClose: false,
      config: config
    }
  },
  computed: {
    ...mapGetters({
      getLocations: 'backendAPI/location/getAll'
    })
  },
  methods: {
    ...mapActions({
      createEmployee: 'backendAPI/employee/create'
    }),
    async createNewEmployee () {
      await this.createEmployee(this.employee)
      this.close()
    },
    close () {
      this.employee = apiMethods.getModel('employee')
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
