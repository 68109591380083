import config from '@/config'

/**
 * Creates modifier elements
 * use: create.js
 * @displayName Create modifier elements
 */
export default {
  name: 'CreateModifierMixin',
  computed: {
    mx_create_modifier_childModifier () {
      return this.$_mx_create_modifier_defaultChildModifier()
    },
    mx_create_modifier_modifier () {
      switch (this.object.type) {
        case config.objects.types.PATH:
          return this.$_mx_create_modifier_defaultModifier()
        case config.objects.types.CIRCLE:
          return this.$_mx_create_modifier_circleModifier()
        default:
          return null
      }
    }
  },
  methods: {
    /**
         * @requires @/mixins/create/elements
         * @returns {*|VNode}
         * @private
         */
    $_mx_create_modifier_circleModifier () {
      const cp = this.object.getPointByIndex(1)
      const mp = this.object.getPointByIndex(2)

      const modifierPoint = this.mx_create_elements_circle(mp.id, mp.x, mp.y, false)
      const centerPoint = this.mx_create_elements_centerCircle(cp.id, cp.x, cp.y)
      const radiusLine = this.mx_create_elements_radiusLine(cp.x, cp.y, mp.x, mp.y)

      return this.mx_create_elements_group([
        radiusLine,
        centerPoint,
        modifierPoint
      ])
    },
    /**
         * @requires @/mixins/create/elements
         * @requires @/mixins/create
         * @returns {[]}
         * @private
         */
    $_mx_create_modifier_defaultChildModifier () {
      const modifier = []

      this.object.children.forEach(child => {
        const _circles = this.mx_create_createCirclesFromPoints(child.points, true)
        const _lines = this.mx_create_createLinesFromCircles(_circles, true)
        const _group = this.mx_create_elements_group([
          _lines,
          _circles
        ])

        modifier.push(_group)
      })

      return modifier
    },
    /**
         * @requires @/mixins/create/elements
         * @returns {*|VNode}
         */
    $_mx_create_modifier_defaultModifier () {
      const _circles = this.mx_create_createCirclesFromPoints(this.object.getPoints())
      const _lines = this.mx_create_createLinesFromCircles(_circles)

      return this.mx_create_elements_group([
        _lines,
        _circles
      ])
    }
  }
}
