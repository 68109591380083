import axios from 'axios'

import * as types from '@/vuex/backend-api-mutation-types'

import i18n from '@/i18n/language'
import { apiMethods, routes } from '@/helper/apiHelper'
// Import modules for api calls
import exportModule from '@/vuex/modules/backendAPI/export'
import groupModule from '@/vuex/modules/backendAPI/group'
import adminModule from '@/vuex/modules/backendAPI/admin'
import manufactureModule from '@/vuex/modules/backendAPI/manufacture'
import distributorModule from '@/vuex/modules/backendAPI/distributor'
import employeeModule from '@/vuex/modules/backendAPI/employee'
import locationModule from '@/vuex/modules/backendAPI/location'
import clientModule from '@/vuex/modules/backendAPI/client'
import userModule from '@/vuex/modules/backendAPI/user'
import projectModule from '@/vuex/modules/backendAPI/project'
import productModule from '@/vuex/modules/backendAPI/product'
import statsModule from '@/vuex/modules/backendAPI/stats'
import csvModule from '@/vuex/modules/backendAPI/csv'
import apiClientModule from '@/vuex/modules/backendAPI/apiClient'

const state = {
  login: localStorage.getItem('login') ? JSON.parse(localStorage.getItem('login')) : {
    token: null
  },
  error: null
}

const getters = {
  routes (state) {
    return routes
  },
  loggedIn (state) {
    return state.login.token !== null
  },
  getToken (state) {
    return state.login.token
  }
}

const actions = {
  async login ({ dispatch, commit }, credentials) {
    commit(types.COMMON_SET_ERROR, null)
    try {
      const data = {
        grant_type: 'password',
        client_id: process.env.VUE_APP_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
        username: credentials.username,
        password: credentials.password,
        scope: '*'
      }

      const result = await axios.post(routes.login, data)
      commit(types.COMMON_SET_LOGIN, { token: result.data.access_token })
      await dispatch('backendAPI/user/me', null, { root: true })
      return true
    } catch (error) {
      if (error.response.status === 401 || error.response.status === 400) {
        return { error: true, message: i18n.t('errors.login.wrongCredentials') }
      } else {
        commit(types.COMMON_SET_ERROR, error)
        return false
      }
    }
  },
  async logout ({ state, commit, dispatch }) {
    dispatch('resetStoreStates')
    const logoutResponse = await apiMethods.request('post', routes.logout, null, state.login.token)
      .then(response => {
        commit(types.COMMON_SET_LOGOUT)
        return true
      }).catch(() => {
        commit(types.COMMON_SET_LOGOUT)
        // commit(types.COMMON_SET_ERROR, error)
        return true
      })

    dispatch('backendAPI/user/clearUserMe', null, { root: true })

    return logoutResponse
  },
  /**
   * Reset all storage
   *
   * @param dispatch
   */
  resetStoreStates ({ dispatch }) {
    dispatch('backendAPI/client/resetState', null, { root: true })
    dispatch('backendAPI/admin/resetState', null, { root: true })
    dispatch('backendAPI/manufacture/resetState', null, { root: true })
    dispatch('backendAPI/distributor/resetState', null, { root: true })
    dispatch('backendAPI/employee/resetState', null, { root: true })
    dispatch('backendAPI/group/resetState', null, { root: true })
    dispatch('backendAPI/location/resetState', null, { root: true })
    dispatch('backendAPI/project/resetState', null, { root: true })
    dispatch('backendAPI/user/resetState', null, { root: true })
    dispatch('backendAPI/product/resetState', null, { root: true })
    dispatch('backendAPI/stats/resetState', null, { root: true })
  },
  error ({ state, commit }, error) {
    commit(types.COMMON_SET_ERROR, error.response)
  },
  clearLoggedIn ({ commit }) {
    commit(types.COMMON_SET_LOGOUT)
  }
}

const mutations = {
  [types.COMMON_SET_ERROR] (state, error) {
    state.error = error
  },
  [types.COMMON_SET_LOGIN] (state, payload) {
    state.login = payload
    localStorage.setItem('login', JSON.stringify(payload))
  },
  [types.COMMON_SET_LOGOUT] (state) {
    state.login = {
      token: null
    }
    localStorage.removeItem('login')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    export: exportModule,
    group: groupModule,
    admin: adminModule,
    manufacture: manufactureModule,
    distributor: distributorModule,
    employee: employeeModule,
    location: locationModule,
    client: clientModule,
    user: userModule,
    project: projectModule,
    product: productModule,
    stats: statsModule,
    csv: csvModule,
    apiClient: apiClientModule
  }
}
