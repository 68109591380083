import Vue from 'vue'
import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Subbase Store
 *
 * @returns {{specialToUpdate: null, specialSubbases: [], subbases: []}}
 */
const defaultState = () => {
  return {
    specialSubbases: [],
    subbases: [],
    specialToUpdate: null,
    meta: null
  }
}

const state = defaultState()

const getters = {
  getMeta (state) {
    return state.meta
  },
  /**
     * Has subbases
     *
     * @param state
     * @returns {boolean}
     */
  hasSubbases (state) {
    return !_.isEmpty(state.subbases)
  },
  /**
     * Get subbases
     *
     * @param state
     * @returns {[]}
     */
  getSubbases (state) {
    return state.subbases
  },
  /**
     * Has special subbases
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialSubbases (state) {
    return !_.isEmpty(state.specialSubbases)
  },
  /**
     * Get special subbases
     *
     * @param state
     * @returns {[]}
     */
  getSpecialSubbases (state) {
    return state.specialSubbases
  },
  /**
     * Has product to update
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialToUpdate (state) {
    return state.specialToUpdate !== null
  },
  /**
     * Get special subbase for update
     *
     * @param state
     * @returns {{}|null}
     */
  getSpecialForUpdate: (state) => {
    return (state.specialToUpdate !== null) ? _.cloneDeep(state.specialToUpdate) : null
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_SUBBASE_STATE)
  },
  /**
     * Reset PIM state
     *
     * @param dispatch
     * @param commit
     */
  resetPIMState ({ dispatch, commit }) {
    commit(types.RESET_SUBBASE_PIM_STATE)
  },
  /**
     * Set special product to update
     *
     * @param commit
     * @param specialSubbase
     */
  setSpecialToUpdate ({ commit }, specialSubbase) {
    commit(types.PRODUCT_SUBBASE_SET_SPECIAL_SUBBASE_TO_UPDATE, specialSubbase)
  },
  /**
     * Get all subbases for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.location + `/${rootState.backendAPI.product.locationId}/subbases`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SUBBASE_SET_SUBBASES, response.data.data)
        commit(types.PRODUCT_SUBBASE_SET_META, response.data.meta)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update location subbase
     *
     * @param dispatch
     * @param subbase
     */
  updateLocation ({ dispatch }, subbase) {
    subbase.relate_to_latest = true
    dispatch('update', [subbase])
  },
  /**
     * Update all subbases for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param state
     * @param subbases
     * @returns {Promise<void>}
     */
  async update ({ rootState, dispatch, commit, state }, subbases) {
    await apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/subbases`, { update: subbases }, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SUBBASE_SET_SUBBASES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get all special subbases for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listSpecial ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.subbase, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SUBBASE_SET_SPECIAL_SUBBASES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a special subbase for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialSubbase
     * @returns {Promise<boolean>}
     */
  async createSpecial ({ rootState, dispatch, commit }, specialSubbase) {
    if (!Object.prototype.hasOwnProperty.call(specialSubbase.data, 'group_id')) {
      specialSubbase.data.group_id = rootState.backendAPI.user.me.group.id
    }

    const createdSubbase = await apiMethods.request('post', routes.subbase, specialSubbase.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SUBBASE_ADD_SPECIAL_SUBBASE, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return true
      })

    if (createdSubbase !== null) {
      const response = await specialSubbase.fileCallback(routes.subbase + `/${createdSubbase.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE, response.postMediaResponse)
        }
      }
    }
  },
  /**
     * Update a special subbase for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialSubbase
     * @returns {Promise<void>}
     */
  async updateSpecial ({ rootState, state, dispatch, commit }, specialSubbase) {
    const updatedSubbase = await apiMethods.request('patch', routes.subbase + `/${state.specialToUpdate.id}`, specialSubbase.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE, response.data.data)
        commit(types.PRODUCT_SUBBASE_SET_SPECIAL_SUBBASE_TO_UPDATE, null)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })

    if (updatedSubbase !== null) {
      const response = await specialSubbase.fileCallback(routes.subbase + `/${updatedSubbase.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Delete a special subbase for distributor, manufacture
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {[Number]} subbaseIds
   * @returns {Promise<void>}
   */
  async deleteSpecial ({ rootState, dispatch, commit }, subbaseIds) {
    await apiMethods.request('delete', routes.subbase, { ids: subbaseIds }, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.PRODUCT_SUBBASE_DELETE_SPECIAL_SUBBASE, subbaseIds)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_SUBBASE_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.RESET_SUBBASE_PIM_STATE] (state) {
    state.subbases = []
  },
  [types.PRODUCT_SUBBASE_SET_SUBBASES] (state, subbases) {
    state.subbases = subbases
  },
  [types.PRODUCT_SUBBASE_SET_SPECIAL_SUBBASES] (state, specialSubbases) {
    state.specialSubbases = specialSubbases
  },
  [types.PRODUCT_SUBBASE_ADD_SPECIAL_SUBBASE] (state, specialSubbase) {
    state.specialSubbases.push(specialSubbase)
  },
  [types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE] (state, specialSubbase) {
    const index = state.specialSubbases.findIndex(i => i.id === specialSubbase.id)

    if (index !== -1) {
      Vue.set(state.specialSubbases, index, specialSubbase)
    }
  },
  [types.PRODUCT_SUBBASE_SET_SPECIAL_SUBBASE_TO_UPDATE] (state, specialSubbase) {
    state.specialToUpdate = specialSubbase
  },
  [types.PRODUCT_SUBBASE_DELETE_SPECIAL_SUBBASE] (state, subbaseIds) {
    state.specialSubbases = state.specialSubbases.filter(subbase => {
      return subbase.id !== subbaseIds.filter(subbaseId => subbaseId === subbase.id)[0]
    })
  },
  [types.PRODUCT_SUBBASE_SET_META] (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
