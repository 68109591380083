<template>
  <vnodes :vnodes="referenceLine" />
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

import { RefPoint } from '@/classes/objects'
import MixinMath from '@/mixins/math'

export default {
  name: 'ReferenceLine',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  mixins: [
    MixinMath
  ],
  data: () => {
    return {
      clicks: 0,
      refPoint: {
        hover: null,
        drag: false,
        selected: null
      }
    }
  },
  computed: {
    ...mapState({
      refPointStart: state => state.project.measurement.reference.start,
      refPointEnd: state => state.project.measurement.reference.end,
      mouse: state => state.pseudo.mouse,
      commonSvgSettings: state => state.common.svg
    }),
    referenceLine () {
      if (this.refPointStart !== null) {
        const _nextPoint = (this.refPointEnd !== null) ? this.refPointEnd : {
          x: this.mouse.x2,
          y: this.mouse.y2
        }

        const _start = {
          class: {
            'c-object c-object__circle c-object__circle--active': true,
            'c-object__circle--selected': this.refPoint.selected === 'circle-ref-start',
            'c-object__circle--hover': this.refPoint.hover === 'circle-ref-start'
          },
          attrs: {
            id: 'circle-ref-start',
            cx: this.refPointStart.x,
            cy: this.refPointStart.y,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          }
        }

        const _end = {
          class: {
            'c-object c-object__circle c-object__circle--active': true,
            'c-object__circle--selected': this.refPoint.selected === 'circle-ref-end',
            'c-object__circle--hover': this.refPoint.hover === 'circle-ref-end'
          },
          attrs: {
            id: 'circle-ref-end',
            cx: _nextPoint.x,
            cy: _nextPoint.y,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          }
        }

        if (this.refPointStart !== null && this.refPointEnd !== null) {
          _start.on = {
            mouseenter: e => {
              this.refPoint.hover = 'circle-ref-start'
            },
            mouseleave: e => {
              this.refPoint.hover = null
            },
            mousedown: e => {
              this.refPoint.drag = true
              this.refPoint.selected = 'circle-ref-start'
              this.setTarget('moveRefPointStart')
            },
            mouseup: e => {
              this.refPoint.drag = false
              this.refPoint.selected = null
              this.setTarget(null)
            }
          }

          _end.on = {
            mouseenter: e => {
              this.refPoint.hover = 'circle-ref-end'
            },
            mouseleave: e => {
              this.refPoint.hover = null
            },
            mousedown: e => {
              this.refPoint.drag = true
              this.refPoint.selected = 'circle-ref-end'
              this.setTarget('moveRefPointEnd')
            },
            mouseup: e => {
              this.refPoint.drag = false
              this.refPoint.selected = null
              this.setTarget(null)
            }
          }
        }

        const _line = this.$createElement('line', {
          class: {
            'c-object c-object__line': true
          },
          attrs: {
            id: 'line-ref',
            x1: this.refPointStart.x,
            y1: this.refPointStart.y,
            x2: _nextPoint.x,
            y2: _nextPoint.y,
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
          }
        })

        return this.$createElement('g', {
          attrs: {
            id: 'project-RefLine'
          }
        }, [
          _line,
          this.$createElement('circle', _start),
          this.$createElement('circle', _end)
        ])
      }

      return null
    }
  },
  watch: {
    clicks (n, o) {
      if (n > 1) {
        this.clicks = 0
        this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
        this.setPseudoMouseRef({ x1: 0, y1: 0, x2: 0, y2: 0 })
      }
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.REFERENCE_LINE_ADD_POINT, this.addPointToRefLine)
    this.$events.on(editorEventTypes.REFERENCE_LINE_UPDATE_MOUSE, this.updateMouseRef)
    this.$events.on(editorEventTypes.REFERENCE_LINE_CREATE, this.resetReferenceLine)
    this.$events.on(editorEventTypes.REFERENCE_LINE_MODIFY_START_POINT, this.$_updateRefPointStart)
    this.$events.on(editorEventTypes.REFERENCE_LINE_MODIFY_END_POINT, this.$_updateRefPointEnd)
    this.$events.on(editorEventTypes.REFERENCE_LINE_INTERRUPT, this.interruptReferenceLine)
  },
  methods: {
    ...mapMutations({
      setRefPointStart: 'project/measurement/' + mutationTypes.SET_REF_POINT_START,
      setRefPointEnd: 'project/measurement/' + mutationTypes.SET_REF_POINT_END,
      updateRefPointStart: 'project/measurement/' + mutationTypes.UPDATE_REF_POINT_START,
      updateRefPointEnd: 'project/measurement/' + mutationTypes.UPDATE_REF_POINT_END,
      setPseudoMouseRef: 'pseudo/' + mutationTypes.UPDATE_PSEUDO_MOUSE_COORDINATES,
      setTarget: 'events/' + mutationTypes.EVT_TARGET,
      stopRefLine: 'project/measurement/' + mutationTypes.RESET_REF_POINTS
    }),
    ...mapActions({
      clearObjectSelection: 'events/clearObjectSelection'
    }),
    updateMouseRef (evt) {
      const _evtPoint = this.$_prepareRefPoint(evt)
      this.setPseudoMouseRef({ x2: _evtPoint.x, y2: _evtPoint.y })
    },
    $_prepareRefPoint (evt) {
      let _refPoint = this.mx_math_getCoordinatesFromEvent(evt)

      if (evt.shiftKey) {
        _refPoint = this.mx_math_ortho(_refPoint, this.refPointStart)
      }

      return _refPoint
    },
    addPointToRefLine (evt) {
      const evtPoint = this.$_prepareRefPoint(evt)
      const _id = (this.clicks > 0) ? 'end' : 'start'
      const refPoint = new RefPoint(_id, evtPoint.x, evtPoint.y)

      if (_id === 'start') {
        this.setRefPointStart(refPoint)
      } else {
        this.setRefPointEnd(refPoint)
      }

      this.clicks += 1
    },
    $_updateRefPointStart (evt) {
      if (this.refPoint.drag && this.refPoint.selected === 'circle-ref-start') {
        const _p = this.$_prepareRefPoint(evt)
        this.updateRefPointStart(_p)
      }
    },
    $_updateRefPointEnd (evt) {
      if (this.refPoint.drag && this.refPoint.selected === 'circle-ref-end') {
        const _p = this.$_prepareRefPoint(evt)
        this.updateRefPointEnd(_p)
      }
    },
    resetReferenceLine () {
      this.setRefPointStart(null)
      this.setRefPointEnd(null)
      this.clearObjectSelection()
    },
    interruptReferenceLine () {
      this.clicks = 0
      this.stopRefLine()
      this.setPseudoMouseRef({ x1: 0, y1: 0, x2: 0, y2: 0 })
    }
  }
}
</script>
