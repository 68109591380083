<template>
  <div class="c-layout u-bgcolor-s4">
    <surface-underlay-dialog v-if="isDialogVisible('addSurfaceUnderlay')" />
    <product-page-dialog />
    <help-page-dialog />
    <first-product-added-dialog />
    <abort-measurements-dialog />
    <checkout-dialog v-if="isDialogVisible('checkout')" />
    <collar-dialog v-if="isDialogVisible('collar')" />
    <templates-dialog v-if="isDialogVisible('templates')" />
    <add-templates-dialog v-if="isDialogVisible('addTemplate')" />
    <import-file-dialog />
    <!--        <edit-product-dialog :objectToEdit="getObjectToEdit"/>-->
    <create-project-dialog />
    <close-project-dialog />
    <page-header
      :editor-nav="true"
      :main-nav="true"
    />
    <div class="c-layout__content c-layout__content--editor">
      <div
        v-if="cover"
        class="c-layout__cover"
      />
      <div class="c-layout__editor">
        <div
          id="editor"
          :class="cursor"
        >
          <toolbar />
          <sidebar v-if="hasProducts" />
          <scale-panel />
          <!--                    <arrows-panel/>-->
          <div class="editor-surface-container">
            <surface />
          </div>
          <editor-actions />
          <transition name="c-los__panel-translate">
            <los-panel v-if="losModeActive" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import Surface from '@/components/editor/Surface'
import EditorActions from '@/components/editor/Actions'
import SurfaceUnderlayDialog from '@/components/editor/dialogs/SurfaceUnderlay'
import ProductPageDialog from '@/components/editor/dialogs/ProductPage'
import HelpPageDialog from '@/components/editor/dialogs/HelpPage'
import FirstProductAddedDialog from '@/components/editor/dialogs/FirstProductAdded'
import AbortMeasurementsDialog from '@/components/editor/dialogs/AbortMeasurements'
import CreateProjectDialog from '@/components/backend/projects/dialogs/CreateProject'
import CheckoutDialog from '@/components/editor/dialogs/Checkout'
import ImportFileDialog from '@/components/editor/dialogs/Import'
import CollarDialog from '@/components/editor/dialogs/Collar'
import TemplatesDialog from '@/components/editor/dialogs/Templates'
import AddTemplatesDialog from '@/components/editor/dialogs/AddTemplate'

import CloseProjectDialog from '@/components/backend/projects/dialogs/CloseProject'

import PageHeader from '@/views/partials/Header'
// import SubNav from '@/components/editor/SubNav'
import Toolbar from '@/components/editor/Toolbar'
import Sidebar from '@/components/editor/Sidebar'
import ScalePanel from '@/components/editor/ScalePanel'
import LosPanel from '@/components/modules/lineOfSight/LineOfSight'
// import LosPanel from '@/components/editor/sidebar/LineOfSight'
/*    import ArrowsPanel from '@/components/editor/ArrowsPanel' */

export default {
  name: 'Editor',
  components: {
    Surface,
    EditorActions,
    SurfaceUnderlayDialog,
    ProductPageDialog,
    HelpPageDialog,
    FirstProductAddedDialog,
    AbortMeasurementsDialog,
    CheckoutDialog,
    CreateProjectDialog,
    CloseProjectDialog,
    PageHeader,
    /* SubNav, */
    Toolbar,
    Sidebar,
    ScalePanel,
    /*            ArrowsPanel, */
    LosPanel,
    ImportFileDialog,
    TemplatesDialog,
    CollarDialog,
    AddTemplatesDialog
  },
  data () {
    return {
      rerender: null,
      editLoS: false
    }
  },
  computed: {
    ...mapState({
      cover: state => state.editor.coverActive,
      ctrl: state => state.events.ctrl,
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      panModeActive: state => state.events.mode.pan,
      losModeActive: state => state.events.mode.los
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      hasProducts: 'products/hasProducts',
      isDialogVisible: 'dialogs/isDialogVisible'
    }),
    cursor: function () {
      return {
        'editor-draw': this.ctrl !== 'move',
        'editor-grab': this.panModeActive
      }
    },
    getObjectToEdit () {
      return this.getObject(this.currentSelectedObjects[0])
    }
  },
  mounted () {
    // Open surface underlay dialog
    const query = this.$route.query
    if (Object.prototype.hasOwnProperty.call(query, 'uploadUnderlay')) {
      if (query.uploadUnderlay) {
        this.$events.fire('dialog-add-surface-plan')
      }
      this.$router.replace(query)
    }
  }
}
</script>

<style lang="scss">
    body {
        padding: 0;
        margin: 0;
    }

    #editor {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 100;
        cursor: initial;

        &.editor {
            &-draw {
                cursor: crosshair !important;
            }

            &-grab {
                cursor: grab !important;
            }
        }
    }

    .editor-surface-container {
        width: 100%;
        height: 100%;
    }
</style>
