/** =============================================================================================================
 * TABLE OF CONTENT
 *
 * 1. Backend
 * 1.1. URL
 * 1.2. Navigation
 * 1.3. Forms
 *
 * 2. Editor
 *
 * 3. Units / Currency / etc
 *
 * 4. Files
 *
 * 5. Keyboard input
 * 5.1. Viewbox - Zoom
 * 5.2. Viewbox - Arrows panning
 * 5.3. Viewbox - Free panning
 * 5.4. Project State
 * 5.5. Objects
 * 5.6. Confirm
 * 5.7. Reject
 *============================================================================================================= */

export default {

  /** =============================================================================================================
   * 1. Backend
   *============================================================================================================= */
  // 1.1. URL
  frontend: {
    baseUrl: process.env.BASE_URL
  },
  downloads: {
    csvImport: {
      en: { path: 'ProjecxPlanner_CSV_Import.pdf', name: 'CSV Import Instructions', language: 'EN', size: '1.9 MB' },
      de: { path: 'ProjecxPlanner_Anleitung_CSV_de.pdf', name: 'CSV Import Anleitung', language: 'DE', size: '1.9 MB' },
      es: { path: 'ProjecxPlanner_Anleitung_CSV_es.pdf', name: 'Guía de importación CSV', language: 'ES', size: '1.9 MB' },
      fr: { path: 'ProjecxPlanner_Anleitung_CSV_fr.pdf', name: 'Instructions pour l\'importation de fichiers CSV', language: 'FR', size: '1.9 MB' }
    }
  },
  backend: {
    baseUrl: process.env.VUE_APP_BACKEND_BASE_URL,
    auth: {
      clientId: process.env.VUE_APP_BACKEND_CLIENT_ID,
      clientSecret: process.env.VUE_APP_BACKEND_CLIENT_SECRET
    }
  },

  // 1.2. Navigation
  locations: {
    materials: {
      products: [
        'turf',
        'epdm'
      ],
      subbases: [
        'pads',
        'pedestals',
        'others'
      ],
      infills: [],
      consumables: [
        'seamtape',
        'adhesives'
      ]
    }
  },
  materials: {
    USER_ROLE_DISTRIBUTOR: {
      subbases: [
        'pads',
        'pedestals',
        'others'
      ],
      infills: [],
      consumables: [
        'seamtape',
        'adhesives'
      ]
    },
    USER_ROLE_MANUFACTURE: {
      products: [
        'turf',
        'epdm'
      ],
      subbases: [
        'pads',
        'pedestals',
        'others'
      ],
      infills: [],
      consumables: [
        'seamtape',
        'adhesives'
      ]
    }
  },
  userManagement: [
    'manufacturers',
    'admins'
  ],
  // 1.3. Forms
  salutation: [
    {
      id: 1,
      label: 'Mr.'
    },
    {
      id: 2,
      label: 'Ms.'
    }
  ],
  themes: [
    {
      id: 1,
      label: 'dark'
    },
    {
      id: 2,
      label: 'light'
    }
  ],
  measureSystem: [
    {
      id: 1,
      label: 'imperial'
    },
    {
      id: 2,
      label: 'international'
    }
  ],
  currencySystem: [
    {
      id: 1,
      label: '$',
      short: 'USD'
    },
    {
      id: 2,
      label: '€',
      short: 'EUR'
    }
  ],

  /** =============================================================================================================
   * 2. Editor
   *============================================================================================================= */

  editor: {
    panMovement: 10,
    edge_tolerance: {
      top: 30,
      left: 30,
      right: 30,
      bottom: 30
    }
  },
  arrowsEnum: {
    ARROW_UP: 1,
    ARROW_RIGHT: 2,
    ARROW_DOWN: 3,
    ARROW_LEFT: 4,
    ARROW_UP_RIGHT: 5,
    ARROW_UP_LEFT: 6,
    ARROW_DOWN_RIGHT: 7,
    ARROW_DOWN_LEFT: 8
  },
  objects: {
    CHILD_OFFSET_TO_BOUNDS: 5,
    DUPLICATE_OFFSET: 10.0,
    SHOW_LENGTHS_ANGLES_SCALE: 0.15,
    DEFAULT_RECTANGLE: 'rect',
    DEFAULT_TRIANGLE: 'tri',
    DEFAULT_CIRCLE: 'circle',
    DEFAULT_POLYGON: 'polygon',
    ID: 'object-',
    types: {
      PATH: 'OBJECT_PATH',
      CIRCLE: 'OBJECT_CIRCLE'
    },
    modes: {
      UNSELECTED: 0,
      SELECTED: 1,
      EDIT: 2,

      LOS: 4
    }
  },
  OBJECT_CUT_ID: 'object-predefined',
  orderEnum: {
    UP: 0,
    DOWN: 1,
    STEP_UP: 2,
    STEP_DOWN: 3
  },

  LINE_OF_SIGHT_IMPACT_LEFT: 1,
  LINE_OF_SIGHT_IMPACT_RIGHT: 2,
  los: {
    modes: {
      product: 0,
      substructures: 1,
      infills: 2
    }
  },

  /** =============================================================================================================
   * 3. Units / Currency / etc
   *============================================================================================================= */

  units: [
    {
      id: 1,
      label: 'ft',
      inches: 12,
      square: 'sq ft',
      square_svg: 'sq ft'
    }
    /* {
        id: 2,
        label: 'inch',
        inches: 1,
        square: 'sq in',
        square_svg: 'sq in'
    },
    {
        id: 3,
        label: 'm',
        inches: (100 / 2.54),
        square: 'm<sup>2</sup>',
        square_svg: 'm<tspan baseline-shift = "super">2</tspan>'
    },
    {
        id: 4,
        label: 'cm',
        inches: (1 / 2.54),
        square: 'cm<sup>2</sup>',
        square_svg: 'm<tspan baseline-shift = "super">2</tspan>'
    } */
  ],
  unit: {
    EUR: 'de-DE',
    USD: 'en-US'
  },
  currency: {
    EUR: {
      separator: '.',
      decimal: ',',
      symbol: '€ ',
      precision: 2,
      formatWithSymbol: true
    },
    USD: {
      separator: ',',
      decimal: '.',
      symbol: '$ ',
      precision: 2,
      formatWithSymbol: true
    }
  },
  locales: {
    0: 'en',
    1: 'de',
    2: 'es',
    3: 'fr'
  },

  /** =============================================================================================================
   * 4. Files
   *============================================================================================================= */

  files: {
    single: {
      underlay: 15,
      backend: 3,
      report: 3
    },
    total: {
      report: 10
    }
  },

  /** =============================================================================================================
   * 5. Keyboard input
   *============================================================================================================= */

  keyInterval: 50,
  keyboard: {

    /** --------------------------------------------------------------------------------------------------------
     * All keyboard shortcuts from 'shortcuts' field can be found here:
     * https://github.com/RobertWHurst/KeyboardJS/blob/master/locales/us.js

     * COMPLETE SAMPLE:

     THE_SAME_NAME_AS_IN_TRANSLATION_FILE: {
         shortcuts: ['shortcut + keys', 'another + shortcuts + keys'],
         mac: [
             {special: ['⌘ cmd', 'alt'], key: 'key', icons: ['font_awesome_icon_code_or_another_html']},
         ],
         win: [
             {special: ['ctrl', 'alt'], key: 'key', icons: ['font_awesome_icon_code_or_another_html']},
         ]
     },
     -------------------------------------------------------------------------------------------------------- */

    // 5.1. Viewbox - Zoom
    EDITOR_SCALE_IN: {
      shortcuts: ['ctrl + numadd', 'ctrl + nine', 'command + numadd', 'command + nine'],
      mac: [
        { special: ['⌘ cmd'], key: 'num +' },
        { special: ['⌘ cmd'], key: '9' }
      ],
      win: [
        { special: ['ctrl'], key: 'num +' },
        { special: ['ctrl'], key: '9' }
      ]
    },
    EDITOR_SCALE_OUT: {
      shortcuts: ['ctrl + numsubtract', 'ctrl + eight', 'command + numsubtract', 'command + eight'],
      mac: [
        { special: ['⌘ cmd'], key: 'num -' },
        { special: ['⌘ cmd'], key: '8' }
      ],
      win: [
        { special: ['ctrl'], key: 'num -' },
        { special: ['ctrl'], key: '8' }
      ]
    },
    EDITOR_SCALE_RESET: {
      shortcuts: ['ctrl + numzero', 'ctrl + zero', 'command + numzero', 'command + zero'],
      mac: [
        { special: ['⌘ cmd'], key: 'num 0' },
        { special: ['⌘ cmd'], key: '0' }
      ],
      win: [
        { special: ['ctrl'], key: 'num 0' },
        { special: ['ctrl'], key: '0' }
      ]
    },

    // 5.2. Viewbox - Arrows panning
    UP_LEFT: {
      shortcuts: 'up + left',
      mac: [
        { icons: ['fas fa-arrow-up', 'fas fa-arrow-left'] }
      ],
      win: [
        { icons: ['fas fa-arrow-up', 'fas fa-arrow-left'] }
      ]
    },
    UP_RIGHT: {
      shortcuts: 'up + right',
      mac: [
        { icons: ['fas fa-arrow-up', 'fas fa-arrow-right'] }
      ],
      win: [
        { icons: ['fas fa-arrow-up', 'fas fa-arrow-right'] }
      ]
    },
    DOWN_LEFT: {
      shortcuts: 'down + left',
      mac: [
        { icons: ['fas fa-arrow-down', 'fas fa-arrow-left'] }
      ],
      win: [
        { icons: ['fas fa-arrow-down', 'fas fa-arrow-left'] }
      ]
    },
    DOWN_RIGHT: {
      shortcuts: 'down + right',
      mac: [
        { icons: ['fas fa-arrow-down', 'fas fa-arrow-right'] }
      ],
      win: [
        { icons: ['fas fa-arrow-down', 'fas fa-arrow-right'] }
      ]
    },
    UP: {
      shortcuts: 'up',
      mac: [
        { icons: ['fas fa-arrow-up'] }
      ],
      win: [
        { icons: ['fas fa-arrow-up'] }
      ]
    },
    DOWN: {
      shortcuts: 'down',
      mac: [
        { icons: ['fas fa-arrow-down'] }
      ],
      win: [
        { icons: ['fas fa-arrow-down'] }
      ]
    },
    LEFT: {
      shortcuts: 'left',
      mac: [
        { icons: ['fas fa-arrow-left'] }
      ],
      win: [
        { icons: ['fas fa-arrow-left'] }
      ]
    },
    RIGHT: {
      shortcuts: 'right',
      mac: [
        { icons: ['fas fa-arrow-right'] }
      ],
      win: [
        { icons: ['fas fa-arrow-right'] }
      ]
    },

    // 5.3. Viewbox - Free panning
    VIEWBOX_FREE_PANNING: { // Unit tests done
      shortcuts: 'space',
      mac: [
        { key: 'space' }
      ],
      win: [
        { key: 'space' }
      ]
    },

    // 5.4. Project State
    PROJECT_SAVE: {
      shortcuts: ['ctrl + s', 'command + s'],
      mac: [
        { special: ['⌘ cmd'], key: 's' }
      ],
      win: [
        { special: ['ctrl'], key: 's' }
      ]
    },
    PROJECT_UNDO: { // Unit tests done
      shortcuts: ['ctrl + z', 'ctrl + shift + y', 'command + z', 'command + shift + y'],
      mac: [
        { special: ['⌘ cmd'], key: 'z' },
        { special: ['⌘ cmd', '⇧ shift'], key: 'y' }
      ],
      win: [
        { special: ['ctrl'], key: 'z' },
        { special: ['ctrl', '⇧ shift'], key: 'y' }
      ]
    },
    PROJECT_REDO: { // Unit tests done
      shortcuts: ['ctrl + y', 'ctrl + shift + z', 'command + y', 'command + shift + z'],
      mac: [
        { special: ['⌘ cmd'], key: 'y' },
        { special: ['⌘ cmd', '⇧ shift'], key: 'z' }
      ],
      win: [
        { special: ['ctrl'], key: 'y' },
        { special: ['ctrl', '⇧ shift'], key: 'z' }
      ]
    },

    // 5.5. Objects
    OBJECT_DELETE: {
      shortcuts: ['backspace', 'del'],
      mac: [
        { key: 'backspace ⌫' },
        { key: 'del' }
      ],
      win: [
        { key: 'backspace ⟵' },
        { key: 'del' }
      ]
    },
    OBJECT_DUPLICATE: {
      shortcuts: ['ctrl + d', 'command + d'],
      mac: [
        { special: ['⌘ cmd'], key: 'd' }
      ],
      win: [
        { special: ['ctrl'], key: 'd' }
      ]
    },
    GRID_SHOW: { // Unit tests done
      shortcuts: ['ctrl + g', 'command + g'],
      mac: [
        { special: ['⌘ cmd'], key: 'g' }
      ],
      win: [
        { special: ['ctrl'], key: 'g' }
      ]
    },
    GRID_SNAP: { // Unit tests done
      shortcuts: 'g',
      mac: [
        { key: 'g' }
      ],
      win: [
        { key: 'g' }
      ]
    },
    SNAP: { // Unit tests done
      shortcuts: 's',
      mac: [
        { key: 's' }
      ],
      win: [
        { key: 's' }
      ]
    },

    // 5.6. Drawing with 45° or 90°
    SHIFT: {
      shortcuts: 'shift',
      mac: [
        { special: ['⇧ shift'] }
      ],
      win: [
        { special: ['⇧ shift'] }
      ]
    },

    // 5.7. Confirm
    ENTER: { // Unit tests done
      shortcuts: 'enter',
      mac: [
        { key: 'enter ↩' }
      ],
      win: [
        { key: 'enter ↵' }
      ]
    },

    // 5.8. Reject
    ESC: { // Unit tests done
      shortcuts: 'esc',
      mac: [
        { key: 'esc' }
      ],
      win: [
        { key: 'esc' }
      ]
    }
  },
  algorithm: {
    url: process.env.VUE_APP_ALGORITHM_URL
  }
}
