<template>
  <svg
    id="cut-tri"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M35.3,33.69a2,2,0,0,1-1-3.73,2.06,2.06,0,0,1,2.74.72,2,2,0,0,1-.72,2.73A2,2,0,0,1,35.3,33.69Zm-5.55-9.5a2,2,0,0,1-1.73-1L25.5,18.88a2,2,0,0,1,.72-2.73,2,2,0,0,1,2.73.71l2.53,4.32a2,2,0,0,1-.72,2.74A2,2,0,0,1,29.75,24.19ZM22.69,12.1a2,2,0,0,1-1.73-1L18,6.11a2,2,0,0,1,.72-2.74,2.06,2.06,0,0,1,2.74.72l2.92,5a2,2,0,0,1-.72,2.74A2,2,0,0,1,22.69,12.1Z"
    />
    <path
      class="u-fill-w"
      d="M4.63,33.69a2,2,0,0,1-1.74-3A2,2,0,0,1,5.62,30a2,2,0,0,1,.75,2.73A2,2,0,0,1,4.63,33.69Zm5.44-9.56a2,2,0,0,1-1-.26,2,2,0,0,1-.94-1.21,2,2,0,0,1,.19-1.52l2.47-4.35A2.06,2.06,0,0,1,13.53,16a2,2,0,0,1,.94,1.21,2,2,0,0,1-.19,1.52l-2.47,4.35A2,2,0,0,1,10.07,24.13ZM17,12a2,2,0,0,1-1.93-1.47A2,2,0,0,1,15.26,9L18,4.11a2,2,0,0,1,2.73-.75,2,2,0,0,1,.94,1.21,2,2,0,0,1-.19,1.52L18.73,11A2,2,0,0,1,17,12Z"
    />
    <path
      class="u-fill-w"
      d="M35.54,34.77h-2a2,2,0,0,1,0-4h2a2,2,0,0,1,0,4Zm-13,0h-5a2,2,0,1,1,0-4h5a2,2,0,0,1,0,4Zm-16,0H4.87a2,2,0,0,1,0-4H6.54a2,2,0,0,1,0,4Z"
    />
    <circle
      class="u-fill-b1"
      cx="35"
      cy="32.77"
      r="5"
    />
    <circle
      class="u-fill-b1"
      cx="5"
      cy="32.76"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'CutTriIcon'
}
</script>
