<template>
  <panel-container :style-bottom="true">
    <div class="c-los__labeling">
      <span>{{ $t('editor.lineOfSight.infill.summary.infills') }}</span>
      <span>{{ getObjectProduct.getUsedInfillsParts() }}</span>
      <span>{{ $t('editor.lineOfSight.infill.summary.total', {unit: $settings.infillUnits.weight_total}) }}</span>
      <span>{{ getCosts.totalWeight }}</span>
    </div>

    <div class="c-los__labeling">
      <h3>{{ $t('editor.lineOfSight.infill.summary.price') }}</h3>
      <h3>{{ getCosts.total.formatted }}</h3>
    </div>
  </panel-container>
</template>

<script>
import PanelContainer from '@/components/common/PanelContainer'
import { mapGetters } from 'vuex'
import MixinMath from '@/mixins/math'
import MixinCosts from '@/mixins/costs'

/**
     * Displays total infill costs
     * @displayName LineOfSight Panel - Infill Costs
     */
export default {
  name: 'InfillsCosts',
  components: {
    PanelContainer
  },
  mixins: [
    MixinMath,
    MixinCosts
  ],
  computed: {
    ...mapGetters({
      getObject: 'lineOfSightNew/getObject',
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    getCosts () {
      if (this.getObjectProduct.infills.length > 0) {
        return this.mx_costs_total_infills(this.getObjectProduct.infills, this.getObject.area)
      }

      return {
        total: {
          formatted: this.$world.currencyConvert(0, true),
          value: 0
        },
        totalWeight: 0
      }
    },
    totalDepth () {
      const infills = this.getObjectProduct.infills
      let totalDepth = 0

      if (infills.length > 0) {
        for (const infill of infills) {
          totalDepth += parseFloat(infill.amount)
        }
      }

      return MixinMath.methods.mx_math_roundDecimal(totalDepth, 3)
    }
  }
}
</script>
