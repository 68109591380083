<template>
  <div
    v-if="location"
    class="c-backend-cat-menu o-grid"
  >
    <div class="o-grid__col u-4/12 c-backend-cat-menu__header-container">
      <h3 class="c-typo__h3 u-color-w u-mt-size-0 u-mb-size-0">
        {{ location.name }}
      </h3>
    </div>
    <div class="c-backend-cat-menu__menu o-grid__col u-8/12">
      <router-link
        v-for="category in menu.categories"
        :key="category"
        :class="[currentCat === category ? 'c-backend-cat-menu__menu-item--active': '', 'c-backend-cat-menu__menu-item']"
        :to="`/backend/locations/${locationId}/${category}/${firstSub(category)}`"
        tag="button"
      >
        {{ $t(`backend.materials.menu.${category}.default`) }}
      </router-link>
    </div>

    <div class="c-backend-cat-menu__menu c-backend-cat-menu__menu-left o-grid__col u-8/12 u-mt-size-80">
      <router-link
        v-for="subCategory in menu.subcategories"
        :id="subCategory"
        :key="subCategory"
        :class="[currentSub === subCategory ? 'c-backend-cat-menu__menu-item--active': '',
                 subCategory === 'epdm' ? 'c-backend-cat-menu__menu-item--uppercase' : '',
                 'c-backend-cat-menu__menu-item c-backend-cat-menu__menu-item--sub']"
        :to="`/backend/locations/${locationId}/${currentCat}/${subCategory}`"
        tag="button"
      >
        {{ $t(`backend.materials.menu.${currentCat}.${subCategory}`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import { mapState } from 'vuex'
import MixinDialogHandler from '@/mixins/dialogHandler'

/**
 * @displayName Location Materials Category Menu
 */
export default {
  name: 'LocationCatMenu',
  mixins: [MixinDialogHandler],
  props: {
    locationId: {
      type: Number,
      required: true
    },
    currentCat: {
      type: String,
      required: true
    },
    currentSub: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      config: config
    }
  },
  computed: {
    ...mapState({
      locations: store => store.backendAPI.location.locations
    }),
    location () {
      return this.locations.filter(loc => loc.id === this.locationId)[0]
    },
    menu () {
      const categories = Object.keys(this.config.locations.materials)
      const subcategories = this.config.locations.materials[this.currentCat]
      return { categories: categories, subcategories: subcategories }
    }
  },
  methods: {
    firstSub (cat) {
      const sub = this.config.locations.materials[cat][0]
      return sub !== undefined ? sub : ''
    },
    _copy () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'copyMaterials',
        onOff: true
      })
    }
  }
}
</script>
