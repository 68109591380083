<template>
  <los-infill-subbase-card
    :index="index"
    @cancelItem="cancelInfill"
    @deleteItem="removeFromInfill"
    @editItem="editInfill"
    @saveItem="saveInfill"
  >
    <template #content>
      <p>{{ name }}</p>
      <hr class="u-mb-size-standard u-mt-size-standard">

      <div v-if="!isEditable">
        <p>{{ $t('editor.lineOfSight.infill.weight', {unit: $settings.infillUnits.recommended_weight}) }}: {{ infill.weight }}</p>
      </div>

      <div v-else>
        <input-field
          id="infill-card-weight"
          v-model="inputWeight"
          :label="$t('editor.lineOfSight.infill.weight', {unit: $settings.infillUnits.recommended_weight})"
          :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
        />
      </div>
    </template>
  </los-infill-subbase-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Infill } from '@/classes/products'

import LosInfillSubbaseCard from '@/components/modules/lineOfSight/tools/common/LosInfillSubbaseCard'
import InputField from '@/components/common/InputField'
import MixinValidate from '@/mixins/validate'
import MixinMath from '@/mixins/math'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

/**
     * Displays a infill item
     * @displayName LineOfSight Panel - Infill Card
     */
export default {
  name: 'InfillCard',
  components: {
    InputField,
    LosInfillSubbaseCard
  },
  mixins: [
    MixinValidate,
    MixinMath,
    MixinProductInLanguage
  ],
  props: {
    index: {
      type: Number,
      required: true
    },
    infill: {
      type: Infill,
      required: true
    }
  },
  data: () => {
    return {
      tmpWeight: null
    }
  },
  computed: {
    ...mapGetters({
      getInfillById: 'products/getInfillById'
    }),
    isEditable () {
      return this.tmpWeight !== null
    },
    inputWeight: {
      get () {
        return this.tmpWeight
      },
      set (payload) {
        if (!payload.error) {
          this.tmpWeight = payload.value
        }
      }
    },
    name () {
      const infill = this.getInfillById(this.infill.id)

      return this.mx_product_in_language(infill, ['name'], this.$i18n.locale)
    }
  },
  methods: {
    ...mapActions({
      removeInfillFromProduct: 'lineOfSightNew/removeInfillFromProduct',
      updateInfillProperties: 'lineOfSightNew/updateInfillProperties'
    }),
    removeFromInfill () {
      this.removeInfillFromProduct(this.index)
    },
    editInfill () {
      this.tmpWeight = this.infill.weight
      this.$emit('disableDrag', true)
    },
    saveInfill () {
      this.updateInfillProperties({
        index: this.index,
        props: {
          weight: this.mx_math_roundDecimal(this.tmpWeight, 3)
        }
      })
      this.cancelInfill()
    },
    cancelInfill () {
      this.tmpWeight = null
      this.$emit('disableDrag', false)
    }
  }
}
</script>
