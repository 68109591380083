<template>
  <div
    v-if="ctrl === 'move' && mx_operations_selectedObjectsCount === 1 && !isCutOut"
    class="c-nav-sub__group c-nav-sub__group--no-margin"
  >
    <button-head-nav
      id="sub-nav-button-add-template"
      :category="$t('subNav.categories.object')"
      :disabled="shouldBlockGui"
      :label="$t('subNav.object.collar')"
      :on-click-action="()=>{return createObjectCollar()}"
      :theme="getTheme"
      icon-classes="fas fa-bullseye"
      style-color="light"
    />
  </div>
</template>

<script>

import { mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import MixinOperations from '@/mixins/operations'

import ButtonHeadNav from '@/components/common/ButtonHeadNav'

export default {
  name: 'CollarPan',
  components: {
    ButtonHeadNav
  },
  mixins: [
    MixinOperations
  ],
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      isCutOut: state => state.project.objects.cutOut
    }),
    ...mapGetters({
      shouldBlockGui: 'events/shouldBlockGui',
      getTheme: 'gui/getTheme'
    })
  },
  methods: {
    ...mapMutations({
      openDialog: 'dialogs/' + mutationTypes.DIALOGS_TOGGLE
    }),
    createObjectCollar () {
      this.openDialog({ dialog: 'collar', onOff: true })
    }
  }
}
</script>
