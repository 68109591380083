<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createLocation.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createLocation.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <input-field
            id="create-location-name"
            v-model="location.name"
            :focus-on-mount="true"
            :is-store-mode="false"
            :label="$t('dialogs.createLocation.name')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-location-address-street"
            v-model="location.address.street"
            :is-store-mode="false"
            :label="$t('dialogs.createLocation.address.street')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-location-address-city"
            v-model="location.address.city"
            :is-store-mode="false"
            :label="$t('dialogs.createLocation.address.city')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-location-address-zip"
            v-model="location.address.zip"
            :is-store-mode="false"
            :label="$t('dialogs.createLocation.address.zip')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-location-address-state"
            v-model="location.address.state"
            :is-store-mode="false"
            :label="$t('dialogs.createLocation.address.state')"
            :validate="(v) => {return true}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-location-address-country"
            v-model="location.address.country"
            :is-store-mode="false"
            :label="$t('dialogs.createLocation.address.country')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <drop-down
            id="create-location-currency"
            v-model="location.currency_short"
            :items="$world.getCurrencyList()"
            :label="$t('dialogs.createLocation.currency')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="name"
            style-current-item-width="240px"
            value-key="code"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-location-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-location-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewLocation() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>

import { mapActions } from 'vuex'

import { apiMethods } from '@/helper/apiHelper'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Create Location
     */
export default {
  name: 'CreateLocation',
  components: {
    ButtonBase,
    DialogFrame,
    InputField,
    DropDown
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      location: apiMethods.getModel('location'),
      targetDialog: 'createLocation',
      cannotClose: false
    }
  },
  methods: {
    ...mapActions({
      createLocation: 'backendAPI/location/create'
    }),
    async createNewLocation () {
      await this.createLocation(this.location)
      this.close()
    },
    close () {
      this.location = apiMethods.getModel('location')
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
