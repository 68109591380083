import { mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import moment from 'moment'
import { VueGoodTable } from 'vue-good-table'

export default {
  name: 'AsyncVueGoodTableHelper',
  components: {
    VueGoodTable
  },
  data: () => {
    return {
      isLoading: false,
      rows: [],
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        searchstring: '',
        sort: {
          field: '',
          type: ''
        },
        page: 1,
        perPage: 20
      }
    }
  },
  computed: {
    ...mapGetters({
      getToken: 'backendAPI/getToken'
    })
  },
  methods: {
    ...mapActions({
      setError: 'backendAPI/error'
    }),
    updateParams (newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },
    onPageChange (params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },
    onPerPageChange (params) {
      this.updateParams({
        page: 1,
        perPage: params.currentPerPage
      })
      this.loadItems()
    },
    onSearch (params) {
      this.updateParams({
        searchstring: params.searchTerm
      })
      this.loadItems()
    },
    onSortChange (params) {
      this.updateParams({
        page: 1,
        sort: {
          type: params[0].type,
          field: params[0].field
        }
      })
      this.loadItems()
    },
    onColumnFilter (params) {
      this.updateParams(params)
      this.loadItems()
    },
    doRequest (route, token, method = 'get') {
      let url = `${route}?page=${this.serverParams.page}&perPage=${this.serverParams.perPage}`

      if (this.serverParams.sort.field !== '' && this.serverParams.sort.type !== '') {
        url += `&sort[field]=${this.serverParams.sort.field}&sort[type]=${this.serverParams.sort.type}`
      }

      if (this.serverParams.searchstring !== '') {
        url += `&searchstring=${this.serverParams.searchstring}`
      }

      axios({
        method: 'get',
        url: url,
        data: null,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.getToken
        }
      })
        .then(response => {
          this.totalRecords = response.data.meta.count
          this.rows = response.data.data
          this.$emit('updateTotalRecords', this.totalRecords)
        }).catch(error => {
          this.setError(error)
        })
    },
    $_formatDate (date) {
      return moment(date).format('DD/MM/yy HH:mm:ss')
    },
    $_formatClientName (client) {
      if (client === null) return ''
      const name = `${client.name}${(client.address && client.address.name) ? ', ' + client.address.name : ''}`
      return name.trim()
    },
    $_formatObjectName (obj) {
      if (obj === null) return ''
      const name = `${obj.firstname} ${obj.lastname}`
      return name.trim()
    },
    $_formatStatus (value) {
      return this.$t(`backend.projects.statusLabel.${value}`)
    },
    loadItems () {
      // place code in your file
    }
  }
}
