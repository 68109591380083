<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createClient.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createClient.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
            <!--<div class="o-grid__col u-4/12 u-text-center">
                            Logo
                        </div>-->
            <div class="c-dialog__instruction-col u-text-center u-pl-size-30">
              <drop-down
                id="create-client-salutation"
                v-model="client.salutation"
                :items="mx_languages_getSalutations"
                :label="$t('dialogs.createClient.salutation')"
                :required="true"
                :style-up="false"
                classes="u-mt-size-standard"
                label-key="label"
                style-current-item-width="240px"
                value-key="value"
              />
              <input-field
                id="create-client-name"
                v-model="client.firstname"
                :focus-on-mount="true"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-surname"
                v-model="client.lastname"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.surname')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-email"
                v-model="client.email"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.email')"
                :validate="(v) => {return mx_validate_validateEmail(v) || mx_validate_validateEmpty(v)}"
                placeholder="email@company.com"
                style-input-width="240px"
                type="email"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-address-phone"
                v-model="client.address.phone"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.phone')"
                :validate="(v) => {return mx_validate_validatePhone(v) || mx_validate_validateEmpty(v)}"
                placeholder="+49 1234 567 890"
                style-input-width="240px"
                type="tel"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />

              <drop-down
                v-if="isDistributor"
                id="create-distributor-group"
                v-model="client.group_id"
                :items="getLocations"
                :label="$t('dialogs.createClient.location')"
                :required="true"
                :style-up="true"
                classes="u-mt-size-30"
                label-key="name"
                style-current-item-width="240px"
                value-key="id"
              />
            </div>
            <div class="c-dialog__instruction-col u-text-center">
              <input-field
                id="create-client-address-name"
                v-model="client.address.name"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.name')"
                :validate="(v) => {return true}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-address-street"
                v-model="client.address.street"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.street')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-address-city"
                v-model="client.address.city"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.city')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-address-zip"
                v-model="client.address.zip"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.zip')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-address-state"
                v-model="client.address.state"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.state')"
                :validate="(v) => {return true}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-client-address-country"
                v-model="client.address.country"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.country')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-client-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-client-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewClient() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import config from '@/config'

import { apiMethods, roles } from '@/helper/apiHelper'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinLanguages from '@/mixins/languages'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Create Client
     */
export default {
  name: 'CreateClient',
  components: {
    ButtonBase,
    DropDown,
    DialogFrame,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinLanguages
  ],
  data: () => {
    return {
      client: apiMethods.getModel('client'),
      targetDialog: 'createClient',
      cannotClose: false,
      config: config
    }
  },
  computed: {
    ...mapGetters({
      getMyRole: 'backendAPI/user/getRole',
      getLocations: 'backendAPI/location/getAll'
    }),
    isDistributor () {
      return this.getMyRole === roles.distributor
    }
  },
  methods: {
    ...mapActions({
      createClient: 'backendAPI/client/create'
    }),
    async createNewClient () {
      await this.createClient(this.client)
      this.$events.fire('NEW_CLIENT_CREATED')
      this.close()
    },
    close () {
      this.client = apiMethods.getModel('client')
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
