import * as types from './../mutation-types'

const state = {
  visible: {
    bugReport: false,
    updateProfile: false,

    createApiClient: false,
    clientApiKey: false,
    createNewApiKey: false,
    deleteApiClient: false,
    editApiClient: false,

    createGroup: false,
    updateGroup: false,
    deleteGroup: false,

    createAdmin: false,
    deleteAdmin: false,

    createManufacture: false,
    deleteManufacture: false,

    createDistributor: false,
    deleteDistributor: false,

    downgradeDistributor: false,
    upgradeEmployee: false,

    createEmployee: false,
    deleteEmployee: false,

    createLocation: false,
    updateLocation: false,
    deleteLocation: false,

    createTurf: false,
    updateTurf: false,

    createPad: false,
    updatePad: false,

    createPedestal: false,
    updatePedestal: false,

    createSubbase: false,
    updateSubbase: false,

    createInfill: false,
    updateInfill: false,

    createSeamtape: false,
    updateSeamtape: false,

    createAdhesive: false,
    updateAdhesive: false,

    deleteMaterial: false,

    createClient: false,
    updateClient: false,
    deleteClient: false,

    createProject: false,
    updateProject: false,
    closeProject: false,
    deleteProject: false,
    projectNeedUpdate: false,

    addSurfaceUnderlay: false,
    firstProductAdded: false,
    abortMeasurements: false,
    checkout: false,
    editProduct: false,
    productPage: false,
    helpPage: false,

    importFile: false,
    importMaterial: false,

    collar: false,

    requestOverlay: false,
    requestError: false,
    templates: false,
    addTemplate: false
  },
  subscreen: {
    checkout: 0,
    editProduct: 0
  },
  tab: 0,
  // Sometimes we shouldn't spam with some Dialog screen and show it only once
  showedOnce: {
    firstProductAdded: false
  },
  dialogProps: {
    createNewApiKey: null,
    deleteApiClient: null,
    editApiClient: null,

    updateGroup: null,
    deleteGroup: null,

    deleteAdmin: null,

    deleteManufacture: null,

    deleteDistributor: null,

    downgradeDistributor: null,
    upgradeEmployee: null,

    deleteEmployee: null,

    updateLocation: null,
    deleteLocation: null,

    deleteMaterial: null,

    updateClient: null,
    deleteClient: null,

    createProject: null,
    updateProject: null,
    closeProject: null,
    deleteProject: null,
    projectNeedUpdate: null,
    addTemplate: null,
    importMaterial: null
  }
}

const getters = {
  dialogVisible: (state) => {
    for (const dialog in state.visible) {
      if (Object.prototype.hasOwnProperty.call(state.visible, dialog)) {
        if (state.visible[dialog] === true) {
          return true
        }
      }
    }
    return false
  },
  isDialogVisible: (state) => (dialog) => {
    if (Object.prototype.hasOwnProperty.call(state.visible, dialog)) {
      return state.visible[dialog]
    }
    return false
  },
  getDialogProps: (state) => (target) => {
    if (Object.prototype.hasOwnProperty.call(state.dialogProps, target)) {
      return state.dialogProps[target]
    }
    return null
  }
}

const actions = {
  dialogToggle ({ commit }, payload) {
    commit(types.DIALOGS_TOGGLE, payload)
  }
}

const mutations = {
  [types.DIALOGS_TOGGLE] (state, payload) {
    state.visible[payload.dialog] = payload.onOff
    if (Object.prototype.hasOwnProperty.call(state.subscreen, payload.dialog)) {
      state.subscreen[payload.dialog] = 0
    }
    if (Object.prototype.hasOwnProperty.call(state.showedOnce, payload.dialog)) {
      state.showedOnce[payload.dialog] = true
    }
    if (payload.dialogProps !== undefined) {
      state.dialogProps[payload.dialog] = payload.dialogProps
    }
  },
  [types.DIALOGS_SUBSCREEN] (state, payload) {
    state.subscreen[payload.dialog] = payload.val
  },
  [types.DIALOGS_TAB] (state, payload) {
    state.tab = payload.val
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
