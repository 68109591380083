<template>
  <div
    v-show="currentTab === tabKey"
    :class="{'o-grid o-grid--center c-dialog__instruction--no-wrap' : (detailFields.length > 0)}"
  >
    <div :class="{ 'c-dialog__instruction-col' : (detailFields.length > 0) }">
      <vnodes
        v-for="(field, i) in fields"
        :key="`idPrefix-${lang}-${i}`"
        :vnodes="field"
      />
    </div>
    <div
      v-if="detailFields.length > 0"
      class="c-dialog__instruction-col u-text-right"
    >
      <vnodes
        v-for="(field, i) in detailFields"
        :key="`idPrefix-${lang}-${i}`"
        :vnodes="field"
      />
    </div>
  </div>
</template>

<script>
import InputField from '@/components/common/InputField'
import TextareaField from '@/components/common/TextareaField'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    InputField,
    // eslint-disable-next-line vue/no-unused-components
    TextareaField,
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    formData: {
      type: Object,
      required: true
    },
    formDataTypes: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    idPrefix: {
      type: String,
      required: true
    },
    lang: {
      type: String,
      required: true
    },
    currentTab: {
      type: Number,
      required: true
    },
    tabKey: {
      type: Number,
      required: true
    },
    i18nKey: {
      type: String,
      required: true
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
      fields: null,
      detailFields: [],
      tempFields: [],
      tempDetailFields: [],
      notAllowedFields: []
    }
  },
  beforeMount () {
    this.createTranslationFields(this.formData, this.formDataTypes)
  },
  beforeDestroy () {
    this.fields = null
    this.detailFields = []
    this.tempFields = []
    this.tempDetailFields = []
  },
  methods: {
    createTranslationFields (formData, formDataTypes) {
      for (const [k, v] of Object.entries(formData.translations[this.lang])) {
        if (k !== 'details') {
          const type = (v !== null && Object.prototype.hasOwnProperty.call(formData.translations[this.lang][k], '__type'))
            ? formData.translations[this.lang][k] : formDataTypes.translations[this.lang][k]

          this.tempFields.push((this.isNew) ? this.createField(type, k) : this.updateField(type, k))
        } else {
          this.walkThroughDetails(v)
        }
      }
      this.fields = this.tempFields
      this.detailFields = this.tempDetailFields
    },
    walkThroughDetails (data, i18nKey = 'details.', path = []) {
      for (const [i, j] of Object.entries(data)) {
        if (!this.notAllowedFields.includes(i)) {
          if (this.isNew && j !== null && Object.prototype.hasOwnProperty.call(j, '_value')) {
            this.tempDetailFields.push(this.createField(j, ['details', ...path, i], i18nKey))
          }

          if (this.isNew && j !== null && !Object.prototype.hasOwnProperty.call(j, '_value')) {
            this.walkThroughDetails(j, i18nKey, [i])
          }

          if (!this.isNew && (j === null || typeof j === 'string' || typeof j === 'number')) {
            const type = (path.length === 1)
              ? this.formDataTypes.translations[this.lang].details[path[0]][i]
              : this.formDataTypes.translations[this.lang].details[i]
            this.tempDetailFields.push(this.updateField(type, ['details', ...path, i], i18nKey))
          }

          if (!this.isNew && j !== null && typeof j === 'object') {
            this.walkThroughDetails(j, i18nKey, [i])
          }
        }
      }
    },
    createField (type, k, i18nKey = '') {
      if (type.__type === 'VARCHAR' || type.__type === 'INTEGER') {
        return (typeof k === 'string') ? <input-field
          id={`${this.idPrefix}-${k}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k]._value}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${i18nKey}${k}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-input-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : (k.length === 2) ? <input-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]]._value}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-input-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : <input-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${k[2]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]][k[2]]._value}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}.${k[2]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-input-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        />
      }

      if (type.__type === 'TEXT') {
        return (typeof k === 'string') ? <textarea-field
          id={`${this.idPrefix}-${k}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k]._value}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${i18nKey}${k}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-textarea-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : (k.length === 2) ? <textarea-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]]._value}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-textarea-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : <textarea-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${k[2]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]][k[2]]._value}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}.${k[2]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-textarea-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        />
      }
    },
    updateField (type, k, i18nKey = '') {
      if (type.__type === 'VARCHAR' || type.__type === 'INTEGER') {
        return (typeof k === 'string') ? <input-field
          id={`${this.idPrefix}-${k}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k]}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${i18nKey}${k}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-input-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : (k.length === 2) ? <input-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]]}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-input-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : <input-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${k[2]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]][k[2]]}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}.${k[2]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-input-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        />
      }

      if (type.__type === 'TEXT') {
        return (typeof k === 'string') ? <textarea-field
          id={`${this.idPrefix}-${k}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k]}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${i18nKey}${k}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-textarea-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : (k.length === 2) ? <textarea-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]]}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-textarea-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        /> : <textarea-field
          id={`${this.idPrefix}-${k[0]}-${k[1]}-${k[2]}-${this.lang}`}
          v-model={this.formData.translations[this.lang][k[0]][k[1]][k[2]]}
          type={type._type}
          label={this.$i18n.t(`${this.i18nKey}.${k[0]}.${k[1]}.${k[2]}`)}
          is-store-mode={false}
          validate={(v) => { return true }}
          style-textarea-width="240px"
          onChange={this.emitChangeField}
          onRegister={this.emitRegisterField}
        />
      }
    },
    emitRegisterField (payload) {
      this.$emit('register-field', payload)
    },
    emitChangeField (payload) {
      this.$emit('change-field', payload)
    }
  }
}
</script>
