<template>
  <svg
    id="canvas-subtract-anim"
    viewBox="0 0 200 200"
    width="150px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>subtract-anim</title>
    <path
      id="subtract-anim-object-3"
      class="anim"
      d="M116.9,23v60h-31a3,3,0,0,0-3,3v30H24a3,3,0,0,1-3-3V23a3,3,0,0,1,3-3h89.94A3,3,0,0,1,116.9,23Z"
    />
    <rect
      id="subtract-anim-object-1"
      class="anim"
      height="95.92"
      rx="2.99"
      width="95.92"
      x="20.98"
      y="19.98"
    />
    <rect
      id="subtract-anim-object-2"
      class="anim"
      height="95.92"
      rx="2.99"
      width="95.92"
      x="82.93"
      y="82.93"
    />
    <g
      id="icon"
      class="anim"
    >
      <path
        id="icon-2"
        d="M175.35,31.44h-9.21V23.2A3.19,3.19,0,0,0,163,20H144.2a3.19,3.19,0,0,0-3.2,3.2V42a3.19,3.19,0,0,0,3.2,3.19h8.24v9.2a3.75,3.75,0,0,0,3.75,3.74h19.16a3.75,3.75,0,0,0,3.74-3.74V35.18A3.75,3.75,0,0,0,175.35,31.44Zm2.24,22.9a2.24,2.24,0,0,1-2.24,2.24H156.19a2.24,2.24,0,0,1-2.25-2.24V35.18A2.22,2.22,0,0,1,156.09,33h19.26a2.24,2.24,0,0,1,2.24,2.24Z"
      />
      <path
        id="icon-1"
        d="M174.27,44.66a1.66,1.66,0,0,1-1.67,1.65H158.72a1.65,1.65,0,1,1,0-3.3H172.6A1.65,1.65,0,0,1,174.27,44.66Z"
      />
    </g>
    <path
      id="cursor"
      class="anim"
      d="M55.65,149.34,63,144.58,39,136l8.58,24,4.76-7.35,5.15,5.15a2.34,2.34,0,0,0,3.31-3.31Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

    #cursor {
        fill: #fff;
    }

    #icon {
        fill: var(--w);
    }

    #subtract-anim-object-1, #subtract-anim-object-2 {
        fill: var(--b1);
        opacity: 0.7;
    }

    #subtract-anim-object-3 {
        fill: var(--b1);
        opacity: 0.001; // Mozilla fix (cannot be 0)
    }

    /* Animations */

    .anim {
        animation-duration: 4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;

        &#cursor {
            animation-name: move-cursor;
            animation-timing-function: ease-in-out;
        }

        &#icon {
            animation-name: click-button;
        }

        &#subtract-anim-object-1 {
            animation-name: select-subtract-anim-object-1, make-invisible;
        }

        &#subtract-anim-object-2 {
            animation-name: select-subtract-anim-object-2, make-invisible;
        }

        &#subtract-anim-object-3 {
            animation-name: make-visible;
        }
    }

    @keyframes move-cursor {
        0% {
            transform: translate(0, 0);
        }
        25% {
            transform: translate(80px, -10px);
        }
        30% {
            transform: translate(80px, -10px);
        }
        50% {
            transform: translate(20px, -80px);
        }
        55% {
            transform: translate(20px, -80px);
        }
        75% {
            transform: translate(120px, -90px);
        }
        100% {
            transform: translate(120px, -90px);
        }
    }

    @keyframes select-subtract-anim-object-1 {
        0% {
            fill: var(--b1);
        }
        50% {
            fill: var(--b1);
        }
        53% {
            fill: var(--o);
        }
        100% {
            fill: var(--o);
        }
    }

    @keyframes select-subtract-anim-object-2 {
        0% {
            fill: var(--b1);
        }
        25% {
            fill: var(--b1);
        }
        28% {
            fill: var(--o);
        }
        100% {
            fill: var(--o);
        }
    }

    @keyframes click-button {
        0% {
            fill: var(--w);
        }
        75% {
            fill: var(--w);
        }
        75.1% {
            fill: var(--b1);
        }
        100% {
            fill: var(--b1);
        }
    }

    @keyframes make-invisible {
        0% {
            opacity: 0.7;
        }
        75% {
            opacity: 0.7;
        }
        75.1% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        100% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
    }

    @keyframes make-visible {
        0% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        75% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        75.1% {
            opacity: 0.7;
        }
        100% {
            opacity: 0.7;
        }
    }
</style>
