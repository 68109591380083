<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.upgradeEmployee.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.upgradeEmployee.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p
          v-html="$t('dialogs.upgradeEmployee.text_1')
            .replace('%1', '<br/>&quot;'+dialogProps.employeeData.email+'&quot; ')"
        />
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="upgrade-employee-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
          />
          <button-base
            id="upgrade-employee-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return doFlagAsDistributor() }"
            style-color="gray"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Upgrade Employee to Distributor
     */
export default {
  name: 'UpgradeEmployee',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'upgradeEmployee',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.upgradeEmployee
    })
  },
  methods: {
    ...mapActions({
      flagAsDistributor: 'backendAPI/user/flagAsDistributor'
    }),
    ...mapMutations({}),
    close () {
      this.mx_dialogs_closeDialog()
    },
    async doFlagAsDistributor () {
      await this.flagAsDistributor(this.dialogProps.employeeData.id)
      this.close()
    }
  }
}
</script>
