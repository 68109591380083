<template>
  <div class="c-dialog__instruction u-p-size-0">
    <div class="c-dialog__instruction c-dialog__instruction--no-center">
      <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
        <div class="c-dialog__instruction-col">
          <utility-file
            accept="image/svg+xml"
            @svgAdded="handleAddedSvg"
          />
        </div>
        <div class="c-dialog__instruction-col u-text-center">
          <input-field
            id="import-file-width"
            v-model="object.width"
            :disabled="!isLoaded"
            :is-store-mode="false"
            :label="$t('dialogs.import.width',{unit:$settings.lengthUnit})"
            style-input-width="240px"
            step="0.01"
            type="number"
            @input="$_calculateHeight"
          />
          <input-field
            id="import-file-height"
            v-model="object.height"
            :disabled="!isLoaded"
            :is-store-mode="false"
            :label="$t('dialogs.import.height',{unit:$settings.lengthUnit})"
            style-input-width="240px"
            step="0.01"
            type="number"
            @input="$_calculateWidth"
          />
          <input-checkbox
            id="import-file-aspect-ratio"
            v-model="aspectRatio"
            :disabled="!isLoaded"
            :is-store-mode="false"
            :label="$t('dialogs.import.aspectRatio')"
            style-component="1"
          />
        </div>
      </div>
    </div>
    <div class="c-dialog__confirm">
      <div class="c-dialog__confirm-child">
        <button-base
          id="import-file-cancel"
          :label="$t('common.cancel')"
          :on-click-action="() => { return mx_dialogs_closeDialog('importFile') }"
          style-color="gray"
        />
        <button-base
          id="import-file-confirm"
          :disabled="!isLoaded"
          :label="$t('dialogs.import.button')"
          :on-click-action="() => { return importData() }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import * as mutationTypes from '@/vuex/mutation-types'
import { mapActions, mapMutations, mapState } from 'vuex'

import Paper from 'paper'
import _ from 'lodash'

import PaperLayer from '@/classes/paperLayer'

import MixinMath from '@/mixins/math'
import MixinDialog from '@/mixins/dialogs'

import UtilityFile from '@/components/import/utilities/File'
import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'
import InputCheckbox from '@/components/common/InputCheckbox'

/**
 * Import a file as object(s)
 * @displayName Import File
 */
export default {
  name: 'ImportFile',
  components: {
    InputCheckbox,
    ButtonBase,
    InputField,
    UtilityFile
  },
  mixins: [MixinMath, MixinDialog],
  data: () => {
    return {
      dataToImport: null,
      isLoaded: false,
      aspectRatio: true,
      width: 0,
      height: 0,
      object: {
        width: 0,
        height: 0
      },
      ratio: {
        width: 0,
        height: 0
      },
      block: false
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    })
  },
  methods: {
    ...mapActions({
      $_mx_operations_operationActions: 'project/operationActions'
    }),
    ...mapMutations({
      closeDialog: 'dialogs/' + mutationTypes.DIALOGS_TOGGLE
    }),
    /**
     * Gets called when the user clicks on the import button.
     * Handles data by type (e.g. Paper.CompoundPath)
     * @public
     */
    async importData () {
      if (typeof this.dataToImport === 'object') {
        this.isLoaded = false
        await this.$_importPaperSvgObject(this.dataToImport)
      }
      this.closeDialog({ dialog: 'importFile', onOff: false })
    },
    /**
     * Gets called when the file upload component fires the svgAdded event
     * @param svg
     * @public
     */
    handleAddedSvg (svg) {
      this.isLoaded = false
      const scope = new Paper.PaperScope().setup(new Paper.Size(1, 1))
      scope.project.importSVG(svg, {
        expandShapes: false,
        onLoad: this.$_onLoadSVG
      })
    },
    /**
     * Called on input height is changed
     * @private
     */
    $_calculateWidth () {
      if (this.aspectRatio && !this.block) {
        this.block = true
        this.object.width = this.mx_math_roundDecimal(this.object.height * this.ratio.width)
        this.$nextTick(() => {
          this.block = false
        })
      }
    },
    /**
     * Called on input width is changed
     * @private
     */
    $_calculateHeight () {
      if (this.aspectRatio && !this.block) {
        this.block = true
        this.object.height = this.mx_math_roundDecimal(this.object.width * this.ratio.height)
        this.$nextTick(() => {
          this.block = false
        })
      }
    },
    /**
     * Called onLoad from importSVG
     * @private
     */
    $_onLoadSVG (item) {
      this.dataToImport = item

      this.width = item.bounds.width
      this.height = item.bounds.height
      this.object.width = this.mx_math_roundDecimal(this.$_mx_math_getCl(item.bounds.width, this.measure))
      this.object.height = this.mx_math_roundDecimal(this.$_mx_math_getCl(item.bounds.height, this.measure))

      this.ratio.width = this.object.width / this.object.height
      this.ratio.height = this.object.height / this.object.width

      if (this.object.width > 0 && this.object.height > 0) {
        this.isLoaded = true
      }
    },
    /**
     * Handles the given svg data to import
     * @param dataToImport
     * @private
     */
    async $_importPaperSvgObject (dataToImport) {
      const width = this.mx_math_getPixelByLength(this.object.width, this.measure)
      const height = this.mx_math_getPixelByLength(this.object.height, this.measure)

      const scaleHor = this.mx_math_roundDecimal(width / this.width)
      const scaleVer = this.mx_math_roundDecimal(height / this.height)

      dataToImport.scale(scaleHor, scaleVer)

      dataToImport.translate(new Paper.Point(0 - dataToImport.bounds.x, 0 - dataToImport.bounds.y))

      const paths = _.sortBy(dataToImport.getItems({
        class: Paper.Path,
        parent: function (value) {
          return !(value instanceof Paper.CompoundPath)
        }
      }), ['id'])
      const shapes = _.sortBy(dataToImport.getItems({ class: Paper.Shape }), ['id'])
      const cps = _.sortBy(dataToImport.getItems({ class: Paper.CompoundPath }), ['id'])

      // default elements
      const elements = _.sortBy(_.union(paths, shapes), ['id'])

      if (elements.length > 0) {
        this.$_addElementsToSurface(elements)
      }

      // combined elements
      cps.forEach(cp => {
        const a = cp.getItems({ clockwise: true })
        const b = cp.getItems({ clockwise: false })

        const isEmptyA = !(Array.isArray(a) && a.length)
        const isEmptyB = !(Array.isArray(b) && b.length)

        if (isEmptyA && !isEmptyB) {
          this.$_addElementsToSurface(b)
        } else if (!isEmptyA && isEmptyB) {
          this.$_addElementsToSurface(a)
        } else if (!isEmptyA && !isEmptyB) {
          let basePath = null
          let childrenPath = null

          // base item can be only one single element
          if (a.length === 1) {
            basePath = new Paper.CompoundPath(a)
            childrenPath = new Paper.CompoundPath(b)
          } else if (b.length === 1) {
            basePath = new Paper.CompoundPath(b)
            childrenPath = new Paper.CompoundPath(a)
          }

          if (basePath instanceof Paper.CompoundPath && childrenPath instanceof Paper.CompoundPath) {
            // Re-Orient objects to clockwise
            basePath.reorient(false, true)
            childrenPath.reorient(false, true)

            // remove elements from base item
            const subtract = (basePath.isInside(childrenPath.bounds)) ? childrenPath.subtract(basePath) : basePath.subtract(childrenPath)

            // transform points
            const result = PaperLayer._transformPoints(subtract)

            // create objects from result
            for (const key in result) {
              const outcome = result[key]
              if (outcome) {
                const clockwise_object = outcome.filter(obj => !obj.inside)
                const counterclockwise_objects = outcome.filter(obj => obj.inside)

                const object_properties = clockwise_object.shift()

                this.$_mx_operations_operationActions({
                  points: object_properties.points,
                  children: counterclockwise_objects,
                  action: 'create-new-object-from-points',
                  cutObjectMode: this.$_mx_operations_cut_object_mode
                })
              }
            }
          }
        }
      })
    },
    /**
     * Add single imported elements to store
     * @param elements
     */
    $_addElementsToSurface (elements) {
      for (const key in elements) {
        const element = elements[key]
        let path = (element instanceof Paper.Shape) ? element.toPath() : element

        if (!path.clockwise) {
          path = path.reorient(false, true)
        }

        const result = PaperLayer._transformPoints(path)

        if (result !== null && result.points) {
          this.$_mx_operations_operationActions({
            points: result.points,
            children: [],
            action: 'create-new-object-from-points',
            cutObjectMode: false
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
    .c-import-file {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;

        .c-import-file__upload,
        .c-import-file__actions {
            width: 50%;
        }
    }
</style>
