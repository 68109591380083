<template>
  <svg
    id="grid-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        class="u-fill-w"
        d="M28.47,36.8A1.58,1.58,0,0,1,27,35.17l0-30.45A1.56,1.56,0,0,1,28.43,3.1h0a1.57,1.57,0,0,1,1.5,1.62l0,30.45a1.57,1.57,0,0,1-1.49,1.63Z"
      />
      <path
        class="u-fill-w"
        d="M11.82,36.82a1.57,1.57,0,0,1-1.5-1.63V4.7a1.5,1.5,0,1,1,3,0V35.19A1.57,1.57,0,0,1,11.82,36.82Z"
      />
      <path
        class="u-fill-w"
        d="M20,36.82a1.57,1.57,0,0,1-1.5-1.63V4.7a1.5,1.5,0,1,1,3,0V35.19A1.57,1.57,0,0,1,20,36.82Z"
      />
    </g>
    <g>
      <path
        class="u-fill-w"
        d="M3.3,28.27a1.57,1.57,0,0,1,1.62-1.5l30.45,0A1.57,1.57,0,0,1,37,28.23h0a1.57,1.57,0,0,1-1.62,1.5l-30.46,0a1.57,1.57,0,0,1-1.62-1.5Z"
      />
      <path
        class="u-fill-w"
        d="M3.28,11.62a1.57,1.57,0,0,1,1.62-1.5H35.4A1.57,1.57,0,0,1,37,11.62a1.57,1.57,0,0,1-1.62,1.5H4.9A1.57,1.57,0,0,1,3.28,11.62Z"
      />
      <path
        class="u-fill-w"
        d="M3.28,19.8A1.57,1.57,0,0,1,4.9,18.3H35.4a1.5,1.5,0,1,1,0,3H4.9A1.57,1.57,0,0,1,3.28,19.8Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'GridIcon'
}
</script>
