<template>
  <div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :totalRows="totalRecords"
      :pagination-options="{
        mode: 'pages',
        enabled: true,
        perPage: 20,
        perPageDropdown: [20, 50, 100, 200, 500],
        dropdownAllowAll: false,
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: '',
        allLabel: texts.allLabel,
      }"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        trigger: 'enter',
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'updated_at', type: 'desc'}]
      }"
      theme="melos"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearch"
    >
      <template #emptystate>
        <div
          class="u-m-size-40 c-backend__nothing-to-show"
        >
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open" />
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template #table-column="props">
        {{ props.column.label }}
      </template>
      <template #table-row="props">
        <span
          v-if="props.column.field ==='name'"
        >
          <span>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </span>

        <span v-if="props.column.field === 'id'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field === 'client.label'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field === 'createdBy.fullname'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field === 'updated_at'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field === 'updatedBy.fullname'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field === 'location.name'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span
          v-if="props.column.field ==='status'"
          class="vgt-status-dot-wrapper"
        >
          <span :class="'vgt-status-dot vgt-status-dot__' + props.row.status" />
          {{ props.formattedRow[props.column.field] }}
        </span>

        <!-- Buttons -->
        <span
          v-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <button-base
            :id="`project-list-edit-project-${props.row.originalIndex}`"
            :label="$t('backend.projects.edit')"
            :on-click-action="() => { return doLoadProject(props.row.id) }"
            classes="vgt-button"
            icon-classes="fa fa-wrench"
          />
          <button-base
            :id="`project-list-update-group-${props.row.originalIndex}`"
            :on-click-action="() => { openDialog({ type: 'updateProject', projectData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-edit"
          >
            <template #popup>
              {{ $t('common.edit') }}
            </template>
          </button-base>
          <button-base
            :id="`project-list-delete-project-${props.row.originalIndex}`"
            :disabled="!props.row.isDeletable"
            :on-click-action="() => { return openDialog({type: 'deleteProject', projectData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AsyncVueGoodTableHelper from '@/mixins/AsyncVueGoodTableHelper'
import { roles, routes } from '@/helper/apiHelper'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

/**
 * @displayName ASYNC Project List Component
 */
export default {
  name: 'AsyncProjectList',
  components: {
    ButtonBase,
    Icon
  },
  mixins: [MixinDialogHandler, AsyncVueGoodTableHelper],
  computed: {
    ...mapGetters({
      getMyRole: 'backendAPI/user/getRole'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    },
    columns () {
      return [
        {
          field: 'settings',
          sortable: false,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          label: this.$t('backend.projects.tableLabel.pName'),
          field: 'name'
        },
        {
          label: this.$t('backend.projects.tableLabel.projectNo'),
          field: 'id'
        },
        {
          label: this.$t('backend.projects.tableLabel.cName'),
          field: 'client.label'
        },
        {
          label: this.$t('backend.projects.tableLabel.createdBy'),
          field: 'createdBy.fullname'
        },
        {
          label: this.$t('backend.projects.tableLabel.lastChange'),
          field: 'updated_at',
          formatFn: this.$_formatDate,
          thClass: 'u-text-left',
          tdClass: 'u-text-left vgt-min-width'
        },
        {
          label: this.$t('backend.projects.tableLabel.changedBy'),
          field: 'updatedBy.fullname'
        },
        {
          label: this.$t('backend.projects.tableLabel.location'),
          field: 'location.name',
          hidden: !this.isDistributor
        },
        {
          label: this.$t('backend.projects.tableLabel.status'),
          field: 'status',
          formatFn: this.$_formatStatus
        }
      ]
    },
    isDistributor () {
      return this.getMyRole === roles.distributor
    }
  },
  mounted () {
    this.updateParams({
      sort: {
        type: 'desc',
        field: 'updated_at'
      }
    })
  },
  methods: {
    ...mapActions({
      loadProject: 'backendAPI/project/load',
      setError: 'backendAPI/error',
      initClients: 'backendAPI/client/listShort'
    }),
    loadItems () {
      this.doRequest(routes.project, this.getToken)
    },
    async doLoadProject (projectId) {
      await this.loadProject(projectId)
    },

    async openDialog (payload) {
      if (payload.type === 'updateProject') {
        await this.initClients()
      }
      this.$_mx_dialogHandler_openDialog({
        dialog: payload.type,
        onOff: true,
        dialogProps: { projectData: payload.projectData }
      })
    }
  }
}

</script>
