<template>
  <v-popover
    :disabled="tooltipDisabled || !(hasPopupSlot || tooltipLabel || tooltipCategory)"
    :placement="tooltipPlacement"
    :style="styleExtra"
    popover-inner-class="popover-inner popover-no-pointer-events"
  >
    <!-- Popover target (for the events and position) -->
    <button
      :id="id"
      :class="[buttonClasses, classes]"
      :disabled="disabled"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <icon
        v-if="iconClasses"
        :classes="iconClasses"
      />
      <!--
          @slot Content slot
      -->
      <slot name="content" />
    </button>

    <!-- Popover content -->
    <template #popover>
      <div class="c-tooltip">
        <div :class="headerClasses">
          <h4
            class="c-tooltip-label"
            v-html="tooltipLabel"
          />
          <p
            :class="categoryClasses"
            v-html="tooltipCategory"
          />
        </div>
        <div v-if="hasPopupSlot">
          <hr
            v-if="tooltipLabel || tooltipCategory"
            :class="lineClasses"
          >
          <!--
              @slot Popup slot
          -->
          <slot name="popup" />
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Icon from '@/components/common/Icon'
/**
 * The ButtonIcon Component.
 * @displayName Button Icon
 */
export default {
  name: 'ButtonIcon',
  components: { Icon },
  props: {
    styleExtra: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Additional CSS classes for the button
     */
    classes: {
      type: [String, Array],
      required: false,
      default: ''
    },
    /**
     * Disable the button
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Icon CSS classes - mostly Font Awesome
     */
    iconClasses: {
      type: [String, Array],
      required: true
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Inline function for onClick action<br>
     * <b>Example:</b><br>
     * <i>() => {return someFunction}</i>
     * @values false, ()=>{return someFunction}
     */
    onClickAction: {
      type: [Function, Boolean],
      default: false,
      required: false
    },
    /**
     * Sets corrections in css correlated with theme
     */
    theme: {
      type: String,
      default: 'dark',
      required: false
    },
    /**
     * The button color
     * @values white, dark, darker
     */
    styleColor: {
      type: String,
      default: 'white',
      required: false
    },
    /**
     * The font size for the button
     */
    styleFontSize: {
      type: String,
      default: 'm',
      required: false
    },
    /**
     * Category label displayed on top of the tooltip
     */
    tooltipCategory: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Disables the tooltip
     */
    tooltipDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Description label displayed on top of the tooltip
     */
    tooltipLabel: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines tooltip placement
     * @values auto, auto-start, auto-end, top, top-start, top-end, right, right-start, right-end, bottom, bottom-start, bottom-end, left, left-start, left-end
     */
    tooltipPlacement: {
      type: String,
      default: 'auto',
      required: false
    }
  },
  computed: {
    buttonClasses () {
      return [
        'c-button-icon',
        this.styleColor === 'dark' ? 'c-button-icon--color-gray-dark' : '',
        this.styleColor === 'darker' ? 'c-button-icon--color-gray-darker' : '',
        'u-fsize-' + this.styleFontSize
      ]
    },
    /**
     * Sets CSS classes of the category label
     */
    categoryClasses () {
      return [
        'c-tooltip-category',
        `c-tooltip-category--${this.theme}-theme`
      ]
    },
    /**
     * Sets CSS classes of the line
     */
    lineClasses () {
      return [
        'c-tooltip-line',
        `c-tooltip-line--${this.theme}-theme`
      ]
    },
    /**
     * Sets CSS classes of the header
     */
    headerClasses () {
      return [
        'c-tooltip-header',
        this.hasPopupSlot ? '' : 'c-tooltip-header--no-slot'
      ]
    },
    /**
     * Checks if default slot is empty
     */
    hasPopupSlot () {
      return !!this.$slots.popup
    }
  },
  methods: {
    onClick () {
      if (this.onClickAction) {
        this.onClickAction()
      }
      /**
       * Triggers when the button is clicked
       *
       * @event click
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('click', { id: this.id })
    },
    onMouseEnter () {
      /**
       * Triggers when the cursor enters the button
       *
       * @event mouseenter
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('mouseenter', { id: this.id })
      this.mouseOver = true
    },
    onMouseLeave () {
      /**
       * Triggers when the cursor leaves the button
       *
       * @event mouseleave
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('mouseleave', { id: this.id })
      this.mouseOver = false
    }
  }
}
</script>
