<template>
  <div
    id="drawInput"
    class="c-draw-input"
  >
    <div
      v-if="showInputTip"
      class="c-draw-input__info"
    >
      <span>{{ $t('editor.drawInput.input') }}</span>
    </div>
    <div
      v-else
      class="c-draw-input__container"
    >
      <span class="c-draw-input__input">{{ input }}</span>
      <span class="c-draw-input__info c-draw-input__info--end">
        <span>{{ $t('editor.drawInput.confirm') }}</span>
        <span>{{ $t('editor.drawInput.reject') }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KeyboardDraw',
  data () {
    return {
      input: ''
    }
  },
  computed: {
    showInputTip () {
      return this.input === ''
    }
  },
  watch: {
    input (n, o) {
      this.$emit('changedDrawLengthByKeyboard', Number(n.replace(',', '.')))
    }
  },
  mounted () {
    window.addEventListener('keydown', this.keyboardHandler)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.keyboardHandler)
  },
  methods: {
    resetInput () {
      this.input = ''
    },
    keyboardHandler (evt) {
      if (evt.code === 'Escape') {
        this.input = ''
      }

      if ((this.input.includes('.') || this.input.includes(',')) && evt.code !== 'Backspace') {
        const period = this.input.split('.')
        const comma = this.input.split(',')

        if ((period.length === 2 && period[1].length === 2) ||
          (comma.length === 2 && comma[1].length === 2)) {
          return
        }
      }

      if ((evt.code.includes('Digit') ||
        (evt.code.includes('Numpad') && evt.code !== 'NumpadEnter')) && !evt.shiftKey) {
        this.input += evt.key
      }

      if (!evt.shiftKey && this.input !== '') {
        if (evt.code === 'Backspace') {
          this.input = this.input.slice(0, -1)
        }

        if ((evt.code === 'Period' || evt.code === 'Comma') &&
          (!this.input.includes('.') && !this.input.includes(','))) {
          this.input += evt.key
        }
      }
    }
  }
}

</script>
