<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.abortMeasurements.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.abortMeasurements.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p>{{ $t('dialogs.abortMeasurements.text_1') }}</p>
        <br>
        <p v-html="$t('dialogs.abortMeasurements.text_2')" />
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="abort-measurements-no"
            :label="$t('common.no')"
            :on-click-action="() => { return resetMeasurements() }"
            style-color="gray"
          />
          <button-base
            id="abort-measurements-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return saveMeasurements() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import * as editorEventTypes from '@/events/editor-event-types'
import ButtonBase from '@/components/common/ButtonBase'

export default {
  name: 'AbortMeasurements',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'abortMeasurements',
      cannotClose: true
    }
  },
  computed: {
    ...mapState({})
  },
  methods: {
    ...mapActions({
      updateFromPixels: 'project/measurement/updateFromPixels'
    }),
    close () {
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
      this.mx_dialogs_closeDialog()
    },
    saveMeasurements () {
      this.updateFromPixels()
      this.close()
    },
    resetMeasurements () {
      this.mx_dialogs_resetMeasurements()
      this.close()
    }
  }
}
</script>
