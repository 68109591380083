<template>
  <panel-container :style-bottom="true">
    <div class="c-los__labeling">
      <span>{{ $t('editor.lineOfSight.subbase.summary.subbases') }}</span>
      <span>{{ getObjectProduct.getUsedSubstructuresParts() }}</span>
      <span>{{ $t('editor.lineOfSight.subbase.summary.total',{unit: $settings.subbaseUnits.thickness}) }}</span>
      <span>{{ totalDepth }}</span>
    </div>

    <div class="c-los__labeling">
      <h3>{{ $t('editor.lineOfSight.subbase.summary.price') }}</h3>
      <h3>{{ getCosts }}</h3>
    </div>
  </panel-container>
</template>

<script>
import PanelContainer from '@/components/common/PanelContainer'
import { mapGetters } from 'vuex'
import MixinMath from '@/mixins/math'
import MixinCosts from '@/mixins/costs'

/**
     * Displays total subbase costs
     * @displayName LineOfSight Panel - Subbase Costs
     */
export default {
  name: 'SubstructuresCosts',
  components: {
    PanelContainer
  },
  mixins: [
    MixinMath,
    MixinCosts
  ],
  computed: {
    ...mapGetters({
      getObject: 'lineOfSightNew/getObject',
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    getCosts () {
      if (this.getObjectProduct.subbases.length > 0) {
        const costs = this.mx_costs_total_subbases(this.getObjectProduct.subbases, this.getObject.area)

        return costs.total.formatted
      }

      return 0
    },
    totalDepth () {
      const subbases = this.getObjectProduct.subbases
      let totalDepth = 0

      if (subbases.length > 0) {
        totalDepth = subbases.reduce((a, b) => parseFloat(a) + (parseFloat(b.thickness) || 0), 0)
      }

      return MixinMath.methods.mx_math_roundDecimal(totalDepth, 3)
    }
  }
}
</script>
