import Vue from 'vue'
import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Adhesive Store
 *
 * @returns {{specialToUpdate: null, adhesives: [], specialAdhesives: []}}
 */
const defaultState = () => {
  return {
    specialAdhesives: [],
    adhesives: [],
    specialToUpdate: null,
    meta: null
  }
}

const state = defaultState()

const getters = {
  getMeta (state) {
    return state.meta
  },
  /**
     * Has adhesives
     *
     * @param state
     * @returns {boolean}
     */
  hasAdhesives (state) {
    return !_.isEmpty(state.adhesives)
  },
  /**
     * Get adhesives
     *
     * @param state
     * @returns {[]}
     */
  getAdhesives (state) {
    return state.adhesives
  },
  /**
     * Has special adhesives
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialAdhesives (state) {
    return !_.isEmpty(state.specialAdhesives)
  },
  /**
     * Get special adhesives
     *
     * @param state
     * @returns {[]}
     */
  getSpecialAdhesives (state) {
    return state.specialAdhesives
  },
  /**
     * Has product to update
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialToUpdate (state) {
    return state.specialToUpdate !== null
  },
  /**
     * Get special adhesive for update
     *
     * @param state
     * @returns {{}|null}
     */
  getSpecialForUpdate: (state) => {
    return (state.specialToUpdate !== null) ? _.cloneDeep(state.specialToUpdate) : null
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_ADHESIVES_STATE)
  },
  /**
     * Reset PIM state
     *
     * @param dispatch
     * @param commit
     */
  resetPIMState ({ dispatch, commit }) {
    commit(types.RESET_ADHESIVES_PIM_STATE)
  },
  /**
     * Set special product to update
     *
     * @param commit
     * @param specialAdhesive
     */
  setSpecialToUpdate ({ commit }, specialAdhesive) {
    commit(types.PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVE_TO_UPDATE, specialAdhesive)
  },
  /**
     * Get all adhesives for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.location + `/${rootState.backendAPI.product.locationId}/adhesives`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_ADHESIVE_SET_ADHESIVES, response.data.data)
        commit(types.PRODUCT_ADHESIVE_SET_META, response.data.meta)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update location adhesive
     *
     * @param dispatch
     * @param adhesive
     */
  updateLocation ({ dispatch }, adhesive) {
    adhesive.relate_to_latest = true
    dispatch('update', [adhesive])
  },
  /**
     * Update all adhesives for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param state
     * @param adhesives
     * @returns {Promise<void>}
     */
  async update ({ rootState, dispatch, commit, state }, adhesives) {
    await apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/adhesives`, { update: adhesives }, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_ADHESIVE_SET_ADHESIVES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get all special adhesive for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listSpecial ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.adhesive, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a special adhesive for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialAdhesive
     * @returns {Promise<boolean>}
     */
  async createSpecial ({ rootState, dispatch, commit }, specialAdhesive) {
    if (!Object.prototype.hasOwnProperty.call(specialAdhesive.data, 'group_id')) {
      specialAdhesive.data.group_id = rootState.backendAPI.user.me.group.id
    }

    const createdAdhesive = await apiMethods.request('post', routes.adhesive, specialAdhesive.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_ADHESIVE_ADD_SPECIAL_ADHESIVE, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return true
      })

    if (createdAdhesive !== null) {
      const response = await specialAdhesive.fileCallback(routes.adhesive + `/${createdAdhesive.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE, response.postMediaResponse)
        }
      }
    }
  },
  /**
     * Update a special adhesive for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialAdhesive
     * @returns {Promise<void>}
     */
  async updateSpecial ({ rootState, state, dispatch, commit }, specialAdhesive) {
    const updatedAdhesive = await apiMethods.request('patch', routes.adhesive + `/${state.specialToUpdate.id}`, specialAdhesive.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE, response.data.data)
        commit(types.PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVE_TO_UPDATE, null)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })

    if (updatedAdhesive !== null) {
      const response = await specialAdhesive.fileCallback(routes.adhesive + `/${updatedAdhesive.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Delete a special adhesive for distributor, manufacture
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {[Number]} adhesiveIds
   * @returns {Promise<void>}
   */
  async deleteSpecial ({ rootState, dispatch, commit }, adhesiveIds) {
    await apiMethods.request('delete', routes.adhesive, { ids: adhesiveIds }, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.PRODUCT_ADHESIVE_DELETE_SPECIAL_ADHESIVE, adhesiveIds)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_ADHESIVES_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.RESET_ADHESIVES_PIM_STATE] (state) {
    state.adhesives = []
  },
  [types.PRODUCT_ADHESIVE_SET_ADHESIVES] (state, adhesives) {
    state.adhesives = adhesives
  },
  [types.PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVES] (state, specialAdhesives) {
    state.specialAdhesives = specialAdhesives
  },
  [types.PRODUCT_ADHESIVE_ADD_SPECIAL_ADHESIVE] (state, specialAdhesive) {
    state.specialAdhesives.push(specialAdhesive)
  },
  [types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE] (state, specialAdhesive) {
    const index = state.specialAdhesives.findIndex(i => i.id === specialAdhesive.id)

    if (index !== -1) {
      Vue.set(state.specialAdhesives, index, specialAdhesive)
    }
  },
  [types.PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVE_TO_UPDATE] (state, specialAdhesive) {
    state.specialToUpdate = specialAdhesive
  },
  [types.PRODUCT_ADHESIVE_DELETE_SPECIAL_ADHESIVE] (state, adhesiveIds) {
    state.specialAdhesives = state.specialAdhesives.filter(adhesive => {
      return adhesive.id !== adhesiveIds.filter(adhesiveId => adhesiveId === adhesive.id)[0]
    })
  },
  [types.PRODUCT_ADHESIVE_SET_META] (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
