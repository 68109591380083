import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Employee Store
 *
 * @returns {{employees: []}}
 */
const defaultState = () => {
  return {
    employees: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all employees
     *
     * @returns {[]}
     */
  getAll (state) {
    return state.employees
  },
  /**
     * Has employees
     *
     * @param state
     * @returns {boolean}
     */
  hasEmployees (state) {
    return (state.employees.length > 0)
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_EMPLOYEE_STATE)
  },
  /**
     * Update an employee
     *
     * @param commit
     * @param {Object} employee
     */
  update ({ commit }, employee) {
    commit(types.EMPLOYEE_UPDATE_EMPLOYEE, employee)
  },
  /**
     * Delete an employee
     *
     * @param commit
     * @param {Object} employee
     */
  delete ({ commit }, employee) {
    commit(types.EMPLOYEE_DELETE_EMPLOYEE, employee)
  },
  /**
     * Set employee list
     * Roles: Distributor
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.employee + `/group/${rootState.backendAPI.user.me.group.id}`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.EMPLOYEE_SET_EMPLOYEES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Set list of all employees
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listAll ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.employee, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.EMPLOYEE_SET_EMPLOYEES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get employees by Group
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} groupId
     * @returns {Promise<Array>}
     */
  async getListByGroup ({ rootState, dispatch, commit }, groupId) {
    return await apiMethods.request('get', routes.employee + `/group/${groupId}`, null, rootState.backendAPI.login.token)
      .then(response => {
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return []
      })
  },
  /**
     * Create a employee
     * Roles: Manufacture, Distributor
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} employee
     * @returns {Promise<void>}
     */
  async create ({ rootState, dispatch, commit }, employee) {
    await apiMethods.request('post', routes.employee, employee, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.EMPLOYEE_ADD_EMPLOYEE, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_EMPLOYEE_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.EMPLOYEE_SET_EMPLOYEES] (state, employees) {
    state.employees = employees.map(employee => {
      employee.created_at = apiMethods.convertDate(employee.created_at)
      employee.updated_at = apiMethods.convertDate(employee.updated_at)

      return employee
    })
  },
  [types.EMPLOYEE_ADD_EMPLOYEE] (state, employee) {
    employee.created_at = apiMethods.convertDate(employee.created_at)
    employee.updated_at = apiMethods.convertDate(employee.updated_at)
    state.employees.push(employee)
  },
  [types.EMPLOYEE_DELETE_EMPLOYEE] (state, employeeId) {
    state.employees = state.employees.filter(employee => employee.id !== employeeId)
  },
  [types.EMPLOYEE_UPDATE_EMPLOYEE] (state, employee) {
    const e = state.employees.find(e => e.id === employee.id)

    if (e !== undefined) {
      employee.created_at = apiMethods.convertDate(employee.created_at)
      employee.updated_at = apiMethods.convertDate(employee.updated_at)
      apiMethods.updateStoreObjectProperties(e, employee)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
