<template>
  <backend>
    <template #dialogs>
      <create-api-client-dialog v-if="isDialogVisible('createApiClient')" />
      <client-api-key-dialog v-if="isDialogVisible('clientApiKey')" />
      <create-new-api-key-dialog v-if="isDialogVisible('createNewApiKey')" />
      <delete-api-client-dialog v-if="isDialogVisible('deleteApiClient')" />
      <edit-api-client-dialog v-if="isDialogVisible('editApiClient')" />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <button-base
            id="client-create-client"
            :label="$t('backend.apiClients.new')"
            classes="u-mt-size-40 u-ml-size-0"
            icon-classes="fa fa-plus"
            style-size="s"
            @click="$_mx_dialogHandler_openDialog({
              dialog: 'createApiClient',
              onOff: true
            })"
          />
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <api-client-list />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import { mapGetters } from 'vuex'
import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'

import ApiClientList from '@/components/backend/apiClients/ApiClientList'

import ButtonBase from '@/components/common/ButtonBase'

import CreateApiClientDialog from '@/components/backend/apiClients/dialogs/CreateApiClient'
import ClientApiKeyDialog from '@/components/backend/apiClients/dialogs/ClientApiKey'
import CreateNewApiKeyDialog from '@/components/backend/apiClients/dialogs/CreateNewApiKey'
import DeleteApiClientDialog from '@/components/backend/apiClients/dialogs/DeleteApiClient'
import EditApiClientDialog from '@/components/backend/apiClients/dialogs/EditApiClient'

export default {
  name: 'ApiClients',
  components: {
    DeleteApiClientDialog,
    Backend,
    ApiClientList,
    ButtonBase,
    CreateApiClientDialog,
    ClientApiKeyDialog,
    CreateNewApiKeyDialog,
    EditApiClientDialog
  },
  mixins: [
    MixinDialogHandler
  ],
  computed: {
    ...mapGetters({
      apiKey: 'backendAPI/apiClient/getApiKey',
      isDialogVisible: 'dialogs/isDialogVisible'
    })
  },
  watch: {
    apiKey (n, o) {
      this.$nextTick(() => {
        if (n) {
          this.$_mx_dialogHandler_openDialog({
            dialog: 'clientApiKey',
            onOff: true
          })
        }
      })
    }
  }
}
</script>
