import * as types from '@/vuex/mutation-types'
// import MixinMath from '@/mixins/math'

/* const $_get_angle_by_points = (v1, v2) => {
  const srX = (v2.x - v1.x)
  const srY = (v2.y - v1.y)

  let angle = (Math.atan2(srY, srX) * 180 / Math.PI) - 90

  if (angle < 0) {
    angle += 360
  }

  return MixinMath.methods.mx_math_roundDecimal(angle)
} */

const state = {
  lines: [],
  selected: null,
  hovered: null
}

const getters = {
  get: (state) => {
    return state.lines
  },
  getSelectedElement: (state) => {
    return state.lines.find(el => {
      return el.id === state.selected
    })
  },
  isTrackSelectedByCut: (state) => (trackID) => {
    return state.selected.includes(trackID)
  },
  isElementSelected: (state) => (id) => {
    return state.selected === id
  },
  isElementHovered: (state) => (id) => {
    return state.hovered === id
  }
}

const actions = {
  set ({ commit }, elements) {
    commit(types.LOS_SET_MOVABLES, elements)
  },
  addSelected ({ commit }, elementID) {
    commit(types.LOS_ADD_MOVABLE_SELECTED, elementID)
  },
  removeSelected ({ commit }) {
    commit(types.LOS_REMOVE_MOVABLE_SELECTED)
  },
  addHovered ({ commit }, elementID) {
    commit(types.LOS_ADD_MOVABLE_HOVERED, elementID)
  },
  removeHovered ({ commit }) {
    commit(types.LOS_REMOVE_MOVABLE_HOVERED)
  },
  clearMovableData ({ commit }) {
    commit(types.LOS_CLEAR_MOVABLE_DATA)
  }
}

const mutations = {
  [types.LOS_SET_MOVABLES] (state, elements) {
    state.lines = elements
  },
  [types.LOS_ADD_MOVABLE_SELECTED] (state, id) {
    state.selected = id
  },
  [types.LOS_REMOVE_MOVABLE_SELECTED] (state) {
    state.selected = null
  },
  [types.LOS_ADD_MOVABLE_HOVERED] (state, id) {
    state.hovered = id
  },
  [types.LOS_REMOVE_MOVABLE_HOVERED] (state) {
    state.hovered = null
  },
  [types.LOS_CLEAR_MOVABLE_DATA] (state) {
    state.lines = []
    state.selected = null
    state.hovered = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
