<template>
  <div
    v-if="debug"
    class="debug-actions"
  >
    <div class="debug-actions__inner">
      <div id="editor-actions">
        <span>
          <button
            class="add-product-button"
            @click="mx_export_pdf_lawn_overview"
          >Export PDF Lawn Overview</button>
          <button
            class="add-product-button"
            @click="mx_export_pdf_lawn_installation_plan"
          >Export PDF Lawn Installation Plan</button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EditorActions',
  mixins: [
  ],
  data () {
    return {
      debug: false
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
    .debug {

        &-shift {
            bottom: 50px !important;
        }

        &-actions {
            padding: 15px;
            width: 100%;
            position: fixed;
            bottom: 100px;
            border-top: 1px solid #2e3135;
            background-color: #2e3135;
            color: chartreuse;
            font-family: monospace;
            height: 50px;

            &__inner {
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                    margin-left: auto;
                }
            }
        }
    }
    #editor-actions {
        button {
            float: left;

            margin-right: 10px;
            padding: 5px 10px;

            background-color: #fff;
            font-size: 12px;
            font-family: Roboto, sans-serif;
            white-space: nowrap;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;

            border: none;
            border-radius: 4px;

            outline: none;

            &:active {
                color: #fff;
                background-color: rgb(74, 166, 255);
            }
        }
    }

</style>
