import axios from 'axios'
import store from './vuex/store'
import router from './router'

axios.interceptors.request.use(config => {
  store.dispatch('dialogs/dialogToggle', { dialog: 'requestOverlay', onOff: true })
  return config
})

axios.interceptors.response.use(response => {
  store.dispatch('dialogs/dialogToggle', { dialog: 'requestOverlay', onOff: false })
  return response
}, (error) => {
  if (error.response && error.response.status === 401) {
    store.dispatch('backendAPI/clearLoggedIn')
    router.push({ name: 'Login' })
  }
  store.dispatch('dialogs/dialogToggle', { dialog: 'requestOverlay', onOff: false })
  return Promise.reject(error)
})
