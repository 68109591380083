import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Manufacture Store
 *
 * @returns {{manufactures: []}}
 */
const defaultState = () => {
  return {
    manufactures: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all manufactures
     *
     * @returns {[]}
     */
  getAll (state) {
    return state.manufactures
  },
  /**
     * Has manufactures
     *
     * @param state
     * @returns {boolean}
     */
  hasManufactures (state) {
    return (state.manufactures.length > 0)
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_MANUFACTURE_STATE)
  },
  /**
     * Update a manufacture
     *
     * @param commit
     * @param {Object} manufacture
     */
  update ({ commit }, manufacture) {
    commit(types.MANUFACTURE_UPDATE_MANUFACTURE, manufacture)
  },
  /**
     * Delete a manufacture
     *
     * @param commit
     * @param {Object} manufacture
     */
  delete ({ commit }, manufacture) {
    commit(types.MANUFACTURE_DELETE_MANUFACTURE, manufacture)
  },
  /**
     * Set manufacture list
     * Roles: Admin
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.manufacture, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.MANUFACTURE_SET_MANUFACTURE, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a manufacture
     * Roles: Admin
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} manufacture
     * @returns {Promise<void>}
     */
  async create ({ rootState, dispatch, commit }, manufacture) {
    await apiMethods.request('post', routes.manufacture, manufacture, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.MANUFACTURE_ADD_MANUFACTURE, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_MANUFACTURE_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.MANUFACTURE_SET_MANUFACTURE] (state, manufactures) {
    state.manufactures = manufactures.map(manufacture => {
      manufacture.created_at = apiMethods.convertDate(manufacture.created_at)
      manufacture.updated_at = apiMethods.convertDate(manufacture.updated_at)

      return manufacture
    })
  },
  [types.MANUFACTURE_ADD_MANUFACTURE] (state, manufacture) {
    manufacture.created_at = apiMethods.convertDate(manufacture.created_at)
    manufacture.updated_at = apiMethods.convertDate(manufacture.updated_at)
    state.manufactures.push(manufacture)
  },
  [types.MANUFACTURE_DELETE_MANUFACTURE] (state, manufactureId) {
    state.manufactures = state.manufactures.filter(manufacture => manufacture.id !== manufactureId)
  },
  [types.MANUFACTURE_UPDATE_MANUFACTURE] (state, manufacture) {
    const m = state.manufactures.find(m => m.id === manufacture.id)

    if (m !== undefined) {
      manufacture.created_at = apiMethods.convertDate(manufacture.created_at)
      manufacture.updated_at = apiMethods.convertDate(manufacture.updated_at)
      apiMethods.updateStoreObjectProperties(m, manufacture)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
