import * as types from '@/vuex/mutation-types'
// import Vue from 'vue'

const state = {
  elements: []
}

const getters = {
  get: (state) => {
    return state.elements
  }
}

const actions = {
  set ({ commit }, elements) {
    commit(types.LOS_SET_OUTSIDES, elements)
  },
  clearOutlineData ({ commit }) {
    commit(types.LOS_CLEAR_OUTSIDES_DATA)
  }
}

const mutations = {
  [types.LOS_SET_OUTSIDES] (state, elements) {
    state.elements = elements
  },
  [types.LOS_CLEAR_OUTSIDES_DATA] (state) {
    state.elements = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
