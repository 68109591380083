<template>
  <svg
    id="handles-curve-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-b1"
      d="M16,31.62A1.52,1.52,0,0,1,14.77,31l-1.83-2.38a1.5,1.5,0,0,1,2.38-1.83l1.83,2.38A1.51,1.51,0,0,1,16,31.62Zm-4.88-6.35a1.51,1.51,0,0,1-1.19-.58L8.06,22.31a1.5,1.5,0,0,1,2.38-1.83l1.83,2.38A1.5,1.5,0,0,1,12,25,1.52,1.52,0,0,1,11.08,25.27Z"
    />
    <path
      class="u-fill-b1"
      d="M31.07,19.92a1.51,1.51,0,0,1-1.19-.59L28.05,17a1.5,1.5,0,0,1,2.38-1.83l1.82,2.38a1.51,1.51,0,0,1-1.18,2.42Zm-4.88-6.35A1.5,1.5,0,0,1,25,13l-1.83-2.38a1.5,1.5,0,0,1,2.38-1.83l1.83,2.38a1.5,1.5,0,0,1-.28,2.1A1.47,1.47,0,0,1,26.19,13.57Z"
    />
    <path
      class="u-fill-w"
      d="M18.82,36.33c-6-7.56-2.81-12.72,0-17.26,2.67-4.36,4.79-7.81.31-13.55l2.36-1.84c5.76,7.38,2.64,12.47-.12,17s-4.86,7.91-.18,13.83Z"
    />
    <circle
      class="u-fill-w"
      cx="20.3"
      cy="4.6"
      r="4"
    />
    <circle
      class="u-fill-w"
      cx="20"
      cy="35.4"
      r="4"
    />
    <circle
      class="u-fill-b1"
      cx="36.14"
      cy="23.7"
      r="4"
    />
    <circle
      class="u-fill-b1"
      cx="5.74"
      cy="16"
      r="4"
    />
  </svg>
</template>

<script>
export default {
  name: 'HandlesCurveIcon'
}
</script>
