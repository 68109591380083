import * as types from '@/vuex/mutation-types'
import * as apiTypes from '@/vuex/backend-api-mutation-types'
import objectsModule from '@/vuex/modules/submodules/objects'
import measurementModule from '@/vuex/modules/submodules/measurement'
import layersModule from '@/vuex/modules/submodules/layers'
import Vue from 'vue'

import { withHistoryMutation } from '../history-helper'

const logAllLayers = (state) => {
  state.layers.forEach(layer => {
    // const currentLayer = Object.assign({}, layer)
    // devlog.log('%cLayer: %s, Order: %s,%c object: %s', 'color: yellow; background: #222', currentLayer.id, currentLayer.order, '', currentLayer.object)
  })
}

/**
 * Default state for: Loaded Project Store
 *
 * @returns {{consumables: [], lastObjectId: number, backendMeta: {address: null, updated_at: null, name: null, client: null, created_at: null, location: null, id: null, status: number}, tools: [], object: {rotate: {cx: number, cy: number, deg: number}, translate: {x: number, y: number}}, collisionPoints: []}}
 */
const defaultState = () => {
  return {
    additionalCosts: [],
    lastObjectId: 0,
    object: {
      translate: {
        x: 0,
        y: 0
      },
      rotate: {
        cx: 0,
        cy: 0,
        deg: 0
      }
    },
    collisionPoints: [],
    backendMeta: {
      id: null,
      name: null,
      client: null,
      location: null,
      address: null,
      status: 0,
      created_at: null,
      updated_at: null
    },
    tools: [],
    consumables: []
  }
}

const state = defaultState()

const getters = {
  backendMeta (state) {
    return state.backendMeta
  },
  toolIsActive: (state) => (tool) => {
    const toolID = tool.id
    const nTool = state.tools.find((_tool) => {
      if (Number(_tool.id) === Number(toolID)) {
        return _tool
      }
    })
    return state.tools.indexOf(nTool)
  },
  consumableIsActive: (state) => (objName, consumable) => {
    const isChecked = state.consumables.find((_consumable) => {
      if (_consumable.c_id === String(consumable.id + objName)) {
        return _consumable
      }
    })

    return isChecked !== undefined
  },
  getAdditionalCosts: (state) => {
    return (state.additionalCosts !== undefined && state.additionalCosts) ? state.additionalCosts : []
  }
}

const actions = {
  /**
   * Reset state
   *
   * @param dispatch
   * @param commit
   */
  resetState ({ dispatch, commit }) {
    commit(apiTypes.RESET_LOADED_PROJECT_STATES)
    dispatch('products/resetState', null, { root: true })
  },
  addAdditionalCostsToProject ({ commit }, costs) {
    commit(types.PROJECT_ADD_ADDITIONAL_COSTS, costs)
  },
  addObjectToProject ({ dispatch }, object) {
    dispatch('project/objects/addObjectToProject', object, { root: true })
  },
  addObjectToCutOut ({ dispatch }, object) {
    dispatch('project/objects/addObjectToCutOut', object, { root: true })
  },
  translateObjects ({ state, dispatch, commit }, objectIDs) {
    const props = { objectIDs: objectIDs, translate: state.object.translate }
    dispatch('project/objects/updateObjectsByTranslate', props, { root: true })
  },
  resetTranslate ({ state, dispatch, commit }) {
    commit(types.SET_TRANSLATE_COORDINATES_FOR_OBJECTS, { x: 0, y: 0 })
  },
  setRotation ({ commit }, payload) {
    commit(types.SET_ROTATION_FOR_OBJECTS, payload)
  },
  transformObjectPoint ({ dispatch, commit }, props) {
    dispatch('project/objects/transformPointTo', props, { root: true })
  },
  scaleObject ({ dispatch, commit }, props) {
    dispatch('project/objects/updateObjectByScale', props, { root: true })
  },
  scaleObjects ({ dispatch }, props) {
    dispatch('project/objects/updateObjectsByScale', props, { root: true })
  },
  rotateObjects ({ dispatch, commit }, props) {
    dispatch('project/objects/updateObjectsByRotation', {
      ids: props.ids,
      props: state.object.rotate
    }, { root: true })
    commit(types.SET_ROTATION_FOR_OBJECTS, { deg: 0, cx: 0, cy: 0 })
  },
  snapObject ({ dispatch, commit }, props) {
    dispatch('project/objects/updateObjectBySnap', props, { root: true })
    commit(types.SET_TRANSLATE_COORDINATES_FOR_OBJECTS, { x: 0, y: 0 })
  },
  snapMultipleObjects ({ dispatch, commit }, objects) {
    objects.forEach(props => {
      dispatch('project/objects/updateObjectBySnap', props, { root: true })
    })
    commit(types.SET_TRANSLATE_COORDINATES_FOR_OBJECTS, { x: 0, y: 0 })
  },
  resetPointHandle ({ dispatch, commit }, props) {
    dispatch('project/objects/resetHandleForPoint', props, { root: true })
  },
  operationActions ({ state, dispatch, commit }, props) {
    switch (props.action) {
      case 'override-object-points':
        dispatch('project/objects/overrideObjectPoints', props, { root: true })
        break
      case 'create-new-object-from-points': {
        const objID = state.lastObjectId + 1
        props.id = objID
        dispatch('project/objects/createObjectFromPoints', props, { root: true })
        commit(types.UPDATE_PROJECT_LAST_OBJECT_ID, objID)
        dispatch('project/layers/addNewObjectToLayer', objID, { root: true })
        break
      }
      case 'override-object-add-children':
        dispatch('project/objects/overrideObjectPointsAddChildren', props, { root: true })
        break
    }
  },
  removeObject ({ dispatch }, objIDs) {
    dispatch('project/layers/removeLayer', objIDs, { root: true })
    dispatch('project/objects/remove', objIDs, { root: true })
  },
  removeObjectCut ({ dispatch }) {
    dispatch('project/objects/removeCut', null, { root: true })
  },
  duplicateObject ({ dispatch, commit, state, rootState }, id) {
    const newObjectID = state.lastObjectId + 1
    logAllLayers(rootState.project.layers)

    dispatch('project/objects/duplicate', { idToDuplicate: id, id: newObjectID }, { root: true })
    commit(types.UPDATE_PROJECT_LAST_OBJECT_ID, newObjectID)
    logAllLayers(rootState.project.layers)
  },
  setBackendMeta ({ commit }, backendMeta) {
    commit(types.PROJECT_SET_BACKEND_META, backendMeta)
  },
  updateProjectTools ({ commit, getters }, tool) {
    const i = getters.toolIsActive(tool)
    if (i > -1) {
      commit(types.PROJECT_TOOL_REMOVE, i)
    } else {
      commit(types.PROJECT_TOOL_ADD, tool)
    }
  },
  addProjectToConsumables ({ commit, getters }, objName) {
    commit(types.PROJECT_CONSUMABLE_OBJECT_UPDATE, objName)
  },
  updateProjectConsumables ({ commit, getters }, data) {
    commit(types.PROJECT_CONSUMABLE_ADD, data)
    // let i = getters['consumableIsActive'](data['obj'], data['consumable'])
    // if(i > -1) {
    //     commit(types.PROJECT_CONSUMABLE_REMOVE, {obj: data['obj'], index: i})
    // } else {
    //     commit(types.PROJECT_CONSUMABLE_ADD, {obj: data['obj'], consumable: data['consumable'], price: data['price']})
    // }
  }
}

const mutations = withHistoryMutation({
  [apiTypes.RESET_LOADED_PROJECT_STATES] (state) {
    Object.assign(state, defaultState())
  },
  [types.TRANSLATE_COORDINATES_FOR_OBJECTS] (state, attrs) {
    state.object.translate.x = attrs.x
    state.object.translate.y = attrs.y
  },
  [types.SET_TRANSLATE_COORDINATES_FOR_OBJECTS] (state, attrs) {
    state.object.translate.x = attrs.x
    state.object.translate.y = attrs.y
  },
  [types.SET_COLLISION_POINTS] (state, collisionPoints) {
    state.collisionPoints = collisionPoints
  },
  [types.UPDATE_PROJECT_LAST_OBJECT_ID] (state, id) {
    state.lastObjectId = id
  },
  [types.PROJECT_SET_BACKEND_META] (state, backendMeta) {
    for (const prop in backendMeta) {
      if (Object.prototype.hasOwnProperty.call(state.backendMeta, prop)) {
        state.backendMeta[prop] = backendMeta[prop]
      }
    }
  },
  [types.SET_ROTATION_FOR_OBJECTS] (state, payload) {
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(state.object.rotate, key)) {
        Vue.set(state.object.rotate, key, payload[key])
      }
    }
  },
  [types.PROJECT_TOOL_ADD] (state, tool) {
    state.tools.push(tool)
  },
  [types.PROJECT_TOOL_REMOVE] (state, toolIndex) {
    state.tools.splice(toolIndex, 1)
  },
  [types.PROJECT_CONSUMABLE_OBJECT_UPDATE] (state, objName) {
    if (!Object.prototype.hasOwnProperty.call(state.consumables, objName)) {
      Vue.set(state.consumables, objName, [])
      // state.consumables[objName] = []
    }
  },
  [types.PROJECT_CONSUMABLE_ADD] (state, data) {
    const _consumable = state.consumables.find((consumable) => {
      if (consumable.c_id === String(data.consumable.id + data.obj)) {
        return consumable
      }
    })

    if (_consumable === undefined) {
      state.consumables.push({
        id: data.consumable.id,
        obj: data.obj,
        name: data.consumable.name,
        amount: data.amount,
        price: data.price,
        c_id: String(data.consumable.id + data.obj)
      })
    } else {
      state.consumables = state.consumables.filter(consumable => consumable.c_id !== _consumable.c_id)
    }
  },
  [types.PROJECT_CONSUMABLE_REMOVE] (state, data) {
  },
  [types.PROJECT_ADD_ADDITIONAL_COSTS] (state, costs) {
    Vue.set(state, 'additionalCosts', costs)
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    objects: objectsModule,
    measurement: measurementModule,
    layers: layersModule
  }
}
