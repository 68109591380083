<template>
  <g v-if="lines && lines.length > 0 && !isWasteObjectSet">
    <g
      v-for="(line, lineIndex) in lines"
      :key="'line-'+lineIndex"
    >
      <path
        :d="line.path"
        :stroke="pathStroke(line).stroke"
        :stroke-width="pathStroke(line).strokeWidth"
        fill="none"
      />
    </g>
    <los-cut-move-point
      v-if="getSelectedTrackCutLine !== null"
      :line="getSelectedTrackCutLine"
      :angle="angle"
      :measure="measure"
    />
    <nearest-paths
      v-if="!hideNearestPath && getSelectedTrackCutLine === null"
      :data="lines"
      @click="handleClickEvent"
      @hover="handleHoverEvent"
    />
  </g>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import MixinMath from '@/mixins/math'

import NearestPaths from '@/components/editor/tools/NearestPaths'
import LosCutMovePoint from '@/components/modules/lineOfSight/svg/LosCutMovePoint'

/**
 * Displays the cut lines of the lawn tracks
 *
 * @displayName Object tools - LineOfSight Lines
 */
export default {
  name: 'LosLines',
  components: {
    NearestPaths,
    LosCutMovePoint
  },
  mixins: [
    MixinMath
  ],
  props: {
    lines: {
      type: [Array, Object],
      required: true
    },
    angle: {
      type: Number,
      required: true
    },
    measure: {
      type: [Array, Object],
      required: true
    }
  },
  data: () => {
    return {
      hideNearestPath: false,
      cutModifierActive: false,
      currentPosition: null,
      lineHover: null,
      lineSelected: null
    }
  },
  computed: {
    ...mapState({
      commonSvgSettings: state => state.common.svg
    }),
    ...mapGetters({
      getObjectProduct: 'lineOfSightNew/getObjectProduct',
      getSelectedTrackCutLine: 'lineOfSightNew/getSelectedTrackCutLine',
      isTrackCutLineSelected: 'lineOfSightNew/isTrackCutLineSelected',
      isWasteObjectSet: 'lineOfSightNew/isWasteObjectSet'
    })
  },
  methods: {
    ...mapActions({
      setSelectedTrackCutLine: 'lineOfSightNew/setSelectedTrackCutLine',
      setTrackSelected: 'lineOfSightNew/setTrackSelected'
    }),
    handleClickEvent (data) {
      if (data.selected.id) {
        this.setTrackSelected(null)
        if (data.selected.type === 'objectLine' && data.evt.type === 'click') {
          const objectLine = this.lines.find(line => {
            return line.id === data.selected.id
          })
          this.$emit('objectLineSelected', objectLine)
          this.lineHover = null
        }

        if (data.selected.type === 'trackCutLine' && data.evt.type === 'click') {
          if (!this.isTrackCutLineSelected(data.selected.id)) {
            this.setSelectedTrackCutLine(data.selected)
          } else {
            this.setSelectedTrackCutLine(null)
          }
        }
      }
    },
    handleHoverEvent (data) {
      this.lineHover = data.selected
    },
    pathStroke (line) {
      let stroke = '#327EFF'
      let strokeWidth = this.mx_math_handleElementsOnScale(2)

      if (this.lineHover && this.lineHover.id === line.id) {
        stroke = '#00F6FF'
        strokeWidth = this.mx_math_handleElementsOnScale(3)
      }

      if (this.isTrackCutLineSelected(line.id)) {
        this.lineHover = null
      }
      // if (this.hasSelectedCut) {
      //   if (this.isCutSelected(line.id)) {
      //     stroke = '#327EFF' // blue
      //     strokeWidth = this.mx_math_handleElementsOnScale(2)
      //   } else {
      //     stroke = '#000000' // black
      //     strokeWidth = this.mx_math_handleElementsOnScale(1)
      //   }
      // } else if (this.isCutHovered(line.id)) {
      //   stroke = '#00F6FF' // cyan
      //   strokeWidth = this.mx_math_handleElementsOnScale(3)
      // }
      return { stroke: stroke, strokeWidth: strokeWidth }
    }
  }
}
</script>
