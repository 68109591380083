import Vue from 'vue'
import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Turf Store
 *
 * @returns {{ products: [], specialToUpdate: null, specialTurfs: []}}
 */
const defaultState = () => {
  return {
    specialTurfs: [],
    turfs: [],
    specialToUpdate: null,
    meta: null
  }
}

const state = defaultState()

const getters = {
  getMeta (state) {
    return state.meta
  },
  /**
   * Has turfs
   *
   * @param state
   * @returns {boolean}
   */
  hasTurfs (state) {
    return !_.isEmpty(state.turfs)
  },
  /**
   * Get turfs (turf, epdm)
   *
   * @param state
   * @returns {[]}
   */
  getTurfs (state) {
    return state.turfs
  },
  /**
   * Has special turfs
   *
   * @param state
   * @returns {boolean}
   */
  hasSpecialTurfs (state) {
    return !_.isEmpty(state.specialTurfs)
  },
  /**
   * Get special turfs
   *
   * @param state
   * @returns {[]}
   */
  getSpecialTurfs (state) {
    return state.specialTurfs
  },
  /**
   * Has product to update
   *
   * @param state
   * @returns {boolean}
   */
  hasSpecialToUpdate (state) {
    return state.specialToUpdate !== null
  },
  /**
   * Get special turf for update
   *
   * @param state
   * @returns {{}|null}
   */
  getSpecialForUpdate: (state) => {
    return (state.specialToUpdate !== null) ? _.cloneDeep(state.specialToUpdate) : null
  }
}

const actions = {
  /**
   * Reset state
   *
   * @param commit
   */
  resetState ({ commit }) {
    commit(types.RESET_TURF_STATE)
  },
  /**
   * Reset PIM state
   *
   * @param dispatch
   * @param commit
   */
  resetPIMState ({ dispatch, commit }) {
    commit(types.RESET_TURF_PIM_STATE)
  },
  /**
   * Set special turf to update
   *
   * @param commit
   * @param specialTurf
   */
  setSpecialToUpdate ({ commit }, specialTurf) {
    commit(types.PRODUCT_TURF_SET_SPECIAL_TURF_TO_UPDATE, specialTurf)
  },
  /**
   * Get all turfs for location
   * - Location Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.turf + `/${rootState.backendAPI.product.locationId}/turfs`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_TURF_SET_TURFS, response.data.data)
        commit(types.PRODUCT_TURF_SET_META, response.meta)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
   * Update location turf
   * @param dispatch
   * @param turf
   */
  updateLocation ({ dispatch }, turf) {
    turf.relate_to_latest = true
    dispatch('update', [turf])
  },
  /**
   * Update all turfs for location
   * - Location Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param state
   * @param turfs
   * @returns {Promise<void>}
   */
  async update ({ rootState, dispatch, commit, state }, turfs) {
    await apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/turfs`, { update: turfs }, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_TURF_SET_TURFS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
   * Get all special turfs for distributor, manufacture
   * - Distributor Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async listSpecial ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.turf, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_TURF_SET_SPECIAL_TURFS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
   * Create a special turf for distributor, manufacture
   * - Distributor Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {{data: Object, fileCallback: CallableFunction}} specialTurf
   * @returns {Promise<boolean>}
   */
  async createSpecial ({ rootState, dispatch, commit }, specialTurf) {
    if (!Object.prototype.hasOwnProperty.call(specialTurf.data, 'group_id')) {
      specialTurf.data.group_id = rootState.backendAPI.user.me.group.id
    }

    const createdTurf = await apiMethods.request('post', routes.turf, specialTurf.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_TURF_ADD_SPECIAL_TURF, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return true
      })

    if (createdTurf !== null) {
      const response = await specialTurf.fileCallback(routes.turf + `/${createdTurf.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_TURF_UPDATE_SPECIAL_TURF, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_TURF_UPDATE_SPECIAL_TURF, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Update a special turf for distributor, manufacture
   * - Distributor Based
   *
   * @param rootState
   * @param state
   * @param dispatch
   * @param commit
   * @param {{data: Object, fileCallback: CallableFunction}} specialTurf
   * @returns {Promise<void>}
   */
  async updateSpecial ({ rootState, state, dispatch, commit }, specialTurf) {
    const updatedTurf = await apiMethods.request('patch', routes.turf + `/${state.specialToUpdate.id}`, specialTurf.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_TURF_UPDATE_SPECIAL_TURF, response.data.data)
        commit(types.PRODUCT_TURF_SET_SPECIAL_TURF_TO_UPDATE, null)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })

    if (updatedTurf !== null) {
      const response = await specialTurf.fileCallback(routes.turf + `/${updatedTurf.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_TURF_UPDATE_SPECIAL_TURF, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_TURF_UPDATE_SPECIAL_TURF, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Delete a special turf for distributor, manufacture
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {[Number]} turfIds
   * @returns {Promise<void>}
   */
  async deleteSpecial ({ rootState, dispatch, commit }, turfIds) {
    await apiMethods.request('delete', routes.turf, { ids: turfIds }, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.PRODUCT_TURF_DELETE_SPECIAL_TURF, turfIds)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_TURF_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.RESET_TURF_PIM_STATE] (state) {
    state.products = []
  },
  [types.PRODUCT_TURF_SET_TURFS] (state, turfs) {
    state.turfs = turfs
  },
  [types.PRODUCT_TURF_SET_SPECIAL_TURFS] (state, specialTurfs) {
    state.specialTurfs = specialTurfs
  },
  [types.PRODUCT_TURF_ADD_SPECIAL_TURF] (state, specialTurf) {
    state.specialTurfs.push(specialTurf)
  },
  [types.PRODUCT_TURF_UPDATE_SPECIAL_TURF] (state, specialTurf) {
    const index = state.specialTurfs.findIndex(i => i.id === specialTurf.id)

    if (index !== -1) {
      Vue.set(state.specialTurfs, index, specialTurf)
    }
  },
  [types.PRODUCT_TURF_SET_SPECIAL_TURF_TO_UPDATE] (state, specialTurf) {
    state.specialToUpdate = specialTurf
  },
  [types.PRODUCT_TURF_DELETE_SPECIAL_TURF] (state, turfIds) {
    state.specialTurfs = state.specialTurfs.filter(turf => {
      return turf.id !== turfIds.filter(turfId => turfId === turf.id)[0]
    })
  },
  [types.PRODUCT_TURF_SET_META] (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
