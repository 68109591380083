<template>
  <svg
    id="cut-circle-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M35.46,21.5H18.93a1.5,1.5,0,0,1,0-3H35.46a1.5,1.5,0,0,1,0,3Z"
    />
    <path
      class="u-fill-w"
      d="M19.49,37.94H19.4a18,18,0,0,1-5.61-1.17A2,2,0,0,1,15.22,33a13.85,13.85,0,0,0,4.36.91,2,2,0,0,1-.09,4Zm8.81-2.19a2,2,0,0,1-1-3.72,13.74,13.74,0,0,0,3.42-2.84,2,2,0,0,1,3,2.63,18.23,18.23,0,0,1-4.41,3.66A2.07,2.07,0,0,1,28.3,35.75Zm-21-4.37a2,2,0,0,1-1.61-.82A17.59,17.59,0,0,1,3.1,25.45a2,2,0,0,1,3.81-1.21,14.14,14.14,0,0,0,2,4A2,2,0,0,1,7.3,31.38ZM36,24.26l-.29,0A2,2,0,0,1,34,22a13.68,13.68,0,0,0-.09-4.45v-.09a2,2,0,0,1,3.93-.73s0,.07,0,.12a18.05,18.05,0,0,1,.29,3.2A17.69,17.69,0,0,1,38,22.54,2,2,0,0,1,36,24.26ZM4.82,17.77a2,2,0,0,1-.53-.07,2,2,0,0,1-1.4-2.46A18,18,0,0,1,5.28,10,2,2,0,0,1,8.6,12.25,14.15,14.15,0,0,0,6.75,16.3,2,2,0,0,1,4.82,17.77Zm27-6.75a2,2,0,0,1-1.45-.62,14.14,14.14,0,0,0-3.54-2.7,2,2,0,1,1,1.89-3.52,17.72,17.72,0,0,1,4.55,3.47A2,2,0,0,1,31.78,11ZM13.91,7.34a2,2,0,0,1-.78-3.84A18,18,0,0,1,17,2.34c.55-.1,1.11-.18,1.66-.23a2,2,0,0,1,.35,4c-.44,0-.87.09-1.3.17a14.5,14.5,0,0,0-3,.91A2,2,0,0,1,13.91,7.34Z"
    />
    <circle
      class="u-fill-b1"
      cx="35"
      cy="19.85"
      r="5"
    />
    <circle
      class="u-fill-b1"
      cx="20.48"
      cy="19.96"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'CutCircleIcon'
}
</script>
