import Vue from 'vue'
import * as types from '@/vuex/mutation-types'
import config from '@/config'
import _ from 'lodash'
import MixinMath from '@/mixins/math'
import { productTypes } from '@/helper/api/types'

const defaultState = () => {
  return {
    measure: null,
    object: null,
    objectLines: [],
    trackCutLines: [],
    selectedTrackCutLine: null,
    selectedTracks: [],
    subbasesAmount: [],
    objectWaste: null,
    objectWasteTrack: null,
    useWasteFrom: {
      object: null,
      track: null
    }
  }
}

const state = defaultState()

const getters = {
  getObject: (state) => {
    return state.object
  },
  getObjectId: (state) => {
    return (state.object) ? state.object.id : null
  },
  getObjectProduct: (state) => {
    return (state.object) ? state.object.product : null
  },
  getObjectProductSubbases: (state) => {
    return (state.object && state.object.product) ? state.object.product.subbases : null
  },
  getObjectTracks: (state) => {
    return (state.object && state.object.product && state.object.product.tracks) ? state.object.product.tracks : null
  },
  getObjectLines: (state) => {
    return state.objectLines
  },
  isObjectSelectedById: (state) => (objectId) => {
    return (state.object) ? state.object.id === objectId : false
  },
  getTrackCutLines: (state) => {
    return state.trackCutLines
  },
  getSelectedTrackCutLine: (state) => {
    return state.selectedTrackCutLine
  },
  isTrackCutLineSelected: (state) => (lineId) => {
    return (state.selectedTrackCutLine) ? state.selectedTrackCutLine.id === lineId : false
  },
  getSelectedTracks: (state) => {
    return state.selectedTracks
  },
  getLongestTrackBySelected: (state) => {
    const tracks = state.object.product.tracks.filter(el => typeof state.selectedTracks.find(t => t.name === el.name) !== 'undefined')
    let longestTrack = null
    let max = -Infinity

    for (const track of tracks) {
      if (track.length > max) {
        longestTrack = track
        max = track.length
      }
    }

    return longestTrack
  },
  isTrackSelected: (state) => (track, id) => {
    return state.selectedTracks.find(t => t.name === track.name && state.object.id === id)
  },
  getProductSubstructures: (state) => {
    if (state.object.product !== null && state.object.product.subbases.length > 0) {
      return state.object.product.subbases
    }

    return null
  },
  getProductInfills: (state) => {
    if (state.object.product !== null && state.object.product.infills.length > 0) {
      return state.object.product.infills
    }

    return null
  },
  getSubbaseAmountByIdType: (state) => (data) => {
    const found = state.subbasesAmount.find(el => {
      return el.id === data.id && el.type === data.type
    })

    return (found !== undefined) ? found.amount : null
  },
  getWasteObject: (state) => {
    return state.objectWaste
  },
  getWasteObjectTrack: (state) => {
    return state.objectWasteTrack
  },
  getWasteData: (state) => {
    return {
      object: state.objectWaste,
      track: state.objectWasteTrack
    }
  },
  getUseFromWaste: (state) => {
    return {
      object: state.useWasteFrom.object,
      track: state.useWasteFrom.track
    }
  },
  isWasteObjectById: (state) => (objectId) => {
    return (state.objectWaste) ? state.objectWaste.id === objectId : false
  },
  isWasteObjectTrackByName: (state) => (name, id) => {
    return (state.objectWasteTrack && state.objectWaste) ? state.objectWasteTrack.name === name && state.objectWaste.id === id : false
  },
  isWasteObjectSet: (state) => {
    return state.objectWaste !== null
  },
  isUseFromObjectById: (state) => (id) => {
    return (state.useWasteFrom.object) ? state.useWasteFrom.object.id === id : false
  },
  isUseFromObjectTrackByName: (state) => (name, id) => {
    return (state.useWasteFrom.track && state.useWasteFrom.object) ? state.useWasteFrom.track.name === name && state.useWasteFrom.object.id === id : false
  }
}

const actions = {
  setObjectToWorkWith ({ rootState, commit, dispatch, state }, object) {
    if (rootState.events.mode.los) {
      if (state.object && state.object.id !== object.id) {
        dispatch('saveObjectData', state.object)
        commit(types.LOS_CLEAR_STATE)
      }
      commit(types.LOS_SET_OBJECT_TO_WORK_WITH, object)
      commit(types.LOS_SET_MEASURE, rootState.project.measurement.measure)
      dispatch('project/objects/setSelectionMode', {
        object: object.id,
        selectionMode: config.objects.modes.LOS
      }, { root: true })
    } else {
      dispatch('clearState')
    }
  },
  setObjectLines ({ commit }, lines) {
    commit(types.LOS_SET_OBJECT_LINES, lines)
  },
  updateObjectProduct ({ state, dispatch, commit }, properties) {
    commit(types.LOS_UPDATE_OBJECT_PRODUCT_PROPERTIES, properties)
    dispatch('project/objects/removeWasteConnections', state.object.id, { root: true })
  },
  saveObjectData ({ dispatch }, object) {
    dispatch('project/objects/updateObjectByLineOfSight', {
      object: object.id,
      product: object.product
    }, { root: true })
  },
  setTrackCutLines ({ commit }, lines) {
    commit(types.LOS_SET_TRACK_CUT_LINES, lines)
  },
  setSelectedTrackCutLine ({ commit }, line) {
    commit(types.LOS_SET_TRACK_CUT_LINE_SELECTED, line)
  },
  removeSelectedTrackCutLine ({ commit }, data) {
    commit(types.LOS_REMOVE_TRACK_CUT_LINE, data)
  },
  setTrackSelected ({ state, commit }, data) {
    if (data !== null) {
      const found = state.selectedTracks.find(t => t.name === data.track.name)

      if (typeof found === 'undefined' && data.insert) {
        commit(types.LOS_SET_TRACK_SELECTED, data.track)
      } else if (typeof found !== 'undefined') {
        commit(types.LOS_REMOVE_TRACK_SELECTED, data.track)
      } else {
        if (!data.insert) {
          commit(types.LOS_CLEAR_TRACKS_SELECTED)
        }
        commit(types.LOS_SET_TRACK_SELECTED, data.track)
      }
    } else {
      commit(types.LOS_CLEAR_TRACKS_SELECTED)
    }
  },
  updateObjectProductTracks ({ commit }, data) {
    commit(types.LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_MOVED, data)
  },
  updateObjectProductTrackBySplit ({ commit }, data) {
    commit(types.LOS_UPDATE_OBJECT_PRODUCT_TRACK_BY_SPLIT, data)
    commit(types.LOS_CLEAR_TRACKS_SELECTED)
  },
  updateObjectProductTracksByOversize ({ commit }, oversize) {
    commit(types.LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_OVERSIZE, oversize)
  },
  addSubbaseToProduct ({ commit }, subbase) {
    commit(types.LOS_ADD_SUBBASE_TO_PRODUCT, subbase)
    if (subbase.type !== productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
      commit(types.LOS_CACHE_SUBBASE_AMOUNT, { id: subbase.id, amount: subbase.amount, type: subbase.type })
    }
  },
  removeSubbaseFromProduct ({ commit }, key) {
    commit(types.LOS_REMOVE_SUBBASE_FROM_PRODUCT, key)
  },
  updateSubbaseProperties ({ state, commit }, data) {
    data.elements = state.object.product.getSubstructures()
    commit(types.LOS_UPDATE_SUBBASE_INFILL_PROPERTIES, data)
  },
  updateSubstructuresSorting ({ commit }, substructures) {
    commit(types.LOS_OVERRIDE_SUBSTRUCTURES_BY_SORTING, substructures)
  },
  addInfillToProduct ({ commit }, infill) {
    commit(types.LOS_ADD_INFILL_TO_PRODUCT, infill)
  },
  updateInfillProperties ({ state, commit }, data) {
    data.elements = state.object.product.getInfills()
    commit(types.LOS_UPDATE_SUBBASE_INFILL_PROPERTIES, data)
  },
  removeInfillFromProduct ({ commit }, key) {
    commit(types.LOS_REMOVE_INFILL_FROM_PRODUCT, key)
  },
  updateInfillsSorting ({ commit }, infills) {
    commit(types.LOS_OVERRIDE_INFILLS_BY_SORTING, infills)
  },
  updateSubbasesAfterChanges ({ commit }, subbases) {
    commit(types.LOS_UPDATE_SUBBASES_AFTER_CHANGES, subbases)
  },
  setWasteData ({ dispatch, commit }, data) {
    dispatch('saveObjectData', state.object)
    commit(types.LOS_SET_WASTE_OBJECT, data.object)
    commit(types.LOS_SET_WASTE_TRACK, data.track)
  },
  setUseFromObject ({ commit }, object) {
    commit(types.LOS_SET_USE_WASTE_FROM_OBJECT, object)
    commit(types.LOS_SET_USE_WASTE_FROM_OBJECT_TRACK, null)
  },
  setUseFromObjectTrack ({ commit }, track) {
    commit(types.LOS_SET_USE_WASTE_FROM_OBJECT_TRACK, track)
  },
  saveWasteData ({ state, commit, dispatch }) {
    const target = _.cloneDeep(state.useWasteFrom)
    const sourceTarget = { object: state.object.id, track: state.objectWasteTrack.name }

    commit(types.LOS_SAVE_USED_WASTE, {
      objectWasteTrack: _.cloneDeep(state.objectWasteTrack),
      useWasteFrom: target,
      sourceTarget: (target.object.id === state.object.id) ? sourceTarget : null
    })

    target.object.product.tracks.map(track => {
      if (track.name === state.useWasteFrom.track.name) {
        return track.sourceTargetFor.push(sourceTarget)
      }
      return track
    })

    dispatch('saveObjectData', target.object)

    dispatch('clearWasteData')
  },
  removeWasteData ({ dispatch, commit }, track) {
    dispatch('project/objects/removeSourceTrackFromTrackByLineOfSight', {
      wasteData: _.cloneDeep(track.wasteData),
      waste: { object: state.object.id, track: track.name }
    }, { root: true })
    commit(types.LOS_REMOVE_WASTE_FROM_TRACK, track.name)
  },
  clearWasteData ({ commit }) {
    commit(types.LOS_CLEAR_WASTE)
  },
  clearState ({ dispatch, commit, state }) {
    if (state.object) {
      dispatch('saveObjectData', state.object)
    }
    commit(types.LOS_CLEAR_STATE)
  }
}

const mutations = {
  [types.LOS_SET_OBJECT_TO_WORK_WITH] (state, object) {
    state.object = _.cloneDeep(object)
  },
  [types.LOS_SET_MEASURE] (state, measure) {
    state.measure = measure
  },
  [types.LOS_SET_OBJECT_LINES] (state, lines) {
    state.objectLines = lines
  },
  [types.LOS_UPDATE_OBJECT_PRODUCT_PROPERTIES] (state, properties) {
    for (const key in properties) {
      if (Object.prototype.hasOwnProperty.call(state.object.product, key)) {
        Vue.set(state.object.product, key, properties[key])
      }
    }

    Vue.set(state.object, 'productNeedUpdate', false)
  },
  [types.LOS_SET_TRACK_CUT_LINES] (state, lines) {
    state.trackCutLines = lines
  },
  [types.LOS_SET_TRACK_CUT_LINE_SELECTED] (state, line) {
    state.selectedTrackCutLine = line
  },
  [types.LOS_REMOVE_TRACK_CUT_LINE] (state, data) {
    const trackToUpdate = state.object.product.tracks.find(track => track.name === data.trackIdToUpdate)

    const indexToRemove = state.object.product.tracks.findIndex(track => track.name === data.trackIdToDelete)

    state.object.product.tracks.splice(indexToRemove, 1)

    Vue.set(trackToUpdate, 'length', data.trackLength)
    Vue.set(trackToUpdate, 'points', data.trackPoints)
  },
  [types.LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_MOVED] (state, data) {
    const fkt_updatePoints = (track, op, np) => {
      do {
        const i = track.points.findIndex(p => p.x === op.x && p.y === op.y)

        track.points[i].x = MixinMath.methods.mx_math_roundDecimal(np.x)
        track.points[i].y = MixinMath.methods.mx_math_roundDecimal(np.y)
      } while (track.points.findIndex(p => p.x === op.x && p.y === op.y) !== -1)
    }

    const fkt_updateTrackLength = (track, measure) => {
      const pFiltered = track.points.filter(p => p.type !== 'M' && p.type !== 'Z')
      pFiltered.splice(-1, 1)

      return MixinMath.methods.mx_math_calculateLengthsFromPoints(pFiltered, measure)[0].cl.toFixed(2)
    }

    const searchID = data.points.old.id
    const tracksToUpdate = state.object.product.tracks.filter(track => searchID.includes(track.name))

    const p1Search = data.points.old.p1
    const p2Search = data.points.old.p2

    // update each track points
    tracksToUpdate.forEach(track => {
      fkt_updatePoints(track, p1Search, data.points.new.p1)
      fkt_updatePoints(track, p2Search, data.points.new.p2)
      track.setLength(fkt_updateTrackLength(track, state.measure))
    })
  },
  [types.LOS_SET_TRACK_SELECTED] (state, track) {
    state.selectedTracks.push(track)
  },
  [types.LOS_REMOVE_TRACK_SELECTED] (state, track) {
    state.selectedTracks = state.selectedTracks.filter(t => t.name !== track.name)
  },
  [types.LOS_CLEAR_TRACKS_SELECTED] (state) {
    state.selectedTracks = []
  },
  [types.LOS_UPDATE_OBJECT_PRODUCT_TRACK_BY_SPLIT] (state, data) {
    const turfTrackToUpdate = state.object.product.tracks.find(el => el.name === data.turfTrack.name)
    const turfTrackToUpdateIndex = state.object.product.tracks.findIndex(el => el.name === data.turfTrack.name)

    turfTrackToUpdate.setPoints(data.turfTrack.points)
    turfTrackToUpdate.setLength(data.turfTrack.length)
    turfTrackToUpdate.setOversize(0)

    state.object.product.lastTrackId += 1
    state.object.product.tracks.splice(turfTrackToUpdateIndex, 0, data.new)
  },
  [types.LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_OVERSIZE] (state, oversize) {
    const tracksToUpdate = state.object.product.tracks.filter(el => typeof state.selectedTracks.find(t => t.name === el.name) !== 'undefined')

    if (tracksToUpdate.length > 0) {
      tracksToUpdate.forEach(track => {
        track.oversize = oversize
      })
    }
  },

  [types.LOS_ADD_SUBBASE_TO_PRODUCT] (state, subbase) {
    state.object.product.addSubbase(subbase)
  },
  [types.LOS_REMOVE_SUBBASE_FROM_PRODUCT] (state, key) {
    state.object.product.removeSubbase(key)
  },
  [types.LOS_OVERRIDE_SUBSTRUCTURES_BY_SORTING] (state, substructures) {
    state.object.product.setSubstructures(substructures)
  },
  [types.LOS_ADD_INFILL_TO_PRODUCT] (state, infill) {
    state.object.product.addInfill(infill)
  },
  [types.LOS_REMOVE_INFILL_FROM_PRODUCT] (state, key) {
    state.object.product.removeInfill(key)
  },
  [types.LOS_OVERRIDE_INFILLS_BY_SORTING] (state, infills) {
    state.object.product.setInfills(infills)
  },
  [types.LOS_UPDATE_SUBBASE_INFILL_PROPERTIES] (state, data) {
    for (const prop in data.props) {
      if (Object.prototype.hasOwnProperty.call(data.elements[data.index], prop)) {
        Vue.set(data.elements[data.index], prop, data.props[prop])
      }
    }
  },
  [types.LOS_UPDATE_SUBBASES_AFTER_CHANGES] (state, subbases) {
    state.subbasesAmount = []
    if (state.object.product.subbases.length > 0) {
      state.object.product.subbases = subbases
    }
  },
  [types.LOS_CACHE_SUBBASE_AMOUNT] (state, data) {
    state.subbasesAmount.push(data)
  },
  [types.LOS_SET_WASTE_OBJECT] (state, object) {
    state.objectWaste = object
  },
  [types.LOS_SET_WASTE_TRACK] (state, track) {
    state.objectWasteTrack = track
  },
  [types.LOS_SET_USE_WASTE_FROM_OBJECT] (state, object) {
    state.useWasteFrom.object = object
  },
  [types.LOS_SET_USE_WASTE_FROM_OBJECT_TRACK] (state, track) {
    state.useWasteFrom.track = track
  },
  [types.LOS_SAVE_USED_WASTE] (state, data) {
    const track = state.object.product.tracks.find(track => track.name === data.objectWasteTrack.name)
    if (typeof track !== 'undefined') {
      track.setUsedWaste(true)
      track.setWasteFrom({ object: data.useWasteFrom.object.id, track: data.useWasteFrom.track.name })
    }

    if (data.sourceTarget !== null) {
      state.object.product.tracks.map(track => {
        if (track.name === state.useWasteFrom.track.name) {
          return track.sourceTargetFor.push(data.sourceTarget)
        }
        return track
      })
    }
  },
  [types.LOS_REMOVE_WASTE_FROM_TRACK] (state, trackName) {
    const track = state.object.product.tracks.find(track => track.name === trackName)
    if (typeof track !== 'undefined') {
      track.removeWaste()
    }

    const sourceTrack = state.object.product.tracks.find(track => {
      if (track.sourceTargetFor && track.sourceTargetFor.some(target => {
        return target.object === state.object.id && target.track === trackName
      })) {
        return track
      }
    })

    if (typeof sourceTrack !== 'undefined') {
      const index = sourceTrack.sourceTargetFor.findIndex(target => {
        return target.object === state.object.id && target.track === trackName
      })

      if (index !== -1) {
        sourceTrack.sourceTargetFor.splice(index, 1)
      }
    }
  },
  [types.LOS_CLEAR_WASTE] (state) {
    state.objectWaste = null
    state.objectWasteTrack = null
    state.useWasteFrom = {
      object: null,
      track: null
    }
  },
  [types.LOS_CLEAR_STATE] (state) {
    const dState = defaultState()
    const properties = Object.keys(dState)

    for (const key in properties) {
      Vue.set(state, properties[key], dState[properties[key]])
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
