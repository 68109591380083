<template>
  <div
    v-if="ctrl === 'move' && mx_operations_selectedObjectsCount >= 1 && !isCutOut"
    class="c-nav-sub__group c-nav-sub__group--no-margin"
  >
    <button-head-nav
      id="sub-nav-button-order"
      :category="$t('subNav.categories.object')"
      :classes="['c-nav-sub__submenu-group-container']"
      :disabled="shouldBlockGui"
      :label="$t('subNav.object.order')"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
      @mouseenter="showSubmenu.order = true"
      @mouseleave="showSubmenu.order = false"
    >
      <template #icon>
        <order-menu-icon />
      </template>
      <template #submenu>
        <transition name="c-nav-sub__submenu-group">
          <div
            v-if="showSubmenu.order && !shouldBlockGui"
            class="c-nav-sub__submenu-group"
          >
            <button
              id="sub-nav-button-order-up"
              :disabled="isInForeground"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="changeObjectOrder(order.up)"
            >
              <icon classes="c-nav-sub__icon">
                <order-up-total-icon />
              </icon>
              {{ $t('editor.contextMenu.order.up-total') }}
            </button>
            <button
              id="sub-nav-button-order-step-up"
              :disabled="isInForeground"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="changeObjectOrder(order.stepUp)"
            >
              <icon classes="c-nav-sub__icon">
                <order-up-icon />
              </icon>
              {{ $t('editor.contextMenu.order.up') }}
            </button>
            <button
              id="sub-nav-button-order-step-down"
              :disabled="isInBackground"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="changeObjectOrder(order.stepDown)"
            >
              <icon classes="c-nav-sub__icon">
                <order-down-icon />
              </icon>
              {{ $t('editor.contextMenu.order.down') }}
            </button>
            <button
              id="sub-nav-button-order-down"
              :disabled="isInBackground"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="changeObjectOrder(order.down)"
            >
              <icon classes="c-nav-sub__icon">
                <order-down-total-icon />
              </icon>
              {{ $t('editor.contextMenu.order.down-total') }}
            </button>
          </div>
        </transition>
      </template>
    </button-head-nav>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import config from '@/config'

import MixinOperations from '@/mixins/operations'
import MixinTrackState from '@/mixins/trackstate'

import ButtonHeadNav from '@/components/common/ButtonHeadNav'
import Icon from '@/components/common/Icon'
import OrderUpIcon from '@/components/common/icons/order/OrderUpIcon'
import OrderUpTotalIcon from '@/components/common/icons/order/OrderUpTotalIcon'
import OrderDownIcon from '@/components/common/icons/order/OrderDownIcon'
import OrderDownTotalIcon from '@/components/common/icons/order/OrderDownTotalIcon'
import OrderMenuIcon from '@/components/common/icons/order/OrderMenuIcon'

export default {
  name: 'OrderPan',
  components: {
    OrderMenuIcon,
    OrderDownTotalIcon,
    OrderDownIcon,
    OrderUpTotalIcon,
    OrderUpIcon,
    Icon,
    ButtonHeadNav
  },
  mixins: [
    MixinOperations,
    MixinTrackState
  ],
  data: () => {
    return {
      showSubmenu: {
        order: false
      },
      order: {
        up: config.orderEnum.UP,
        down: config.orderEnum.DOWN,
        stepUp: config.orderEnum.STEP_UP,
        stepDown: config.orderEnum.STEP_DOWN
      }
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      isCutOut: state => state.project.objects.cutOut,
      currentSelectedObjects: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      isObjectInBackground: 'project/layers/isObjectInBackground',
      isObjectInForeground: 'project/layers/isObjectInForeground',
      shouldBlockGui: 'events/shouldBlockGui',
      getTheme: 'gui/getTheme'
    }),
    isInBackground () {
      return this.isObjectInBackground(this.currentSelectedObjects)
    },
    isInForeground () {
      return this.isObjectInForeground(this.currentSelectedObjects)
    }
  },
  methods: {
    ...mapActions({
      up: 'project/layers/objectUp',
      down: 'project/layers/objectDown',
      stepUp: 'project/layers/objectStepUp',
      stepDown: 'project/layers/objectStepDown'
    }),
    changeObjectOrder (order) {
      switch (order) {
        case config.orderEnum.UP:
          this.mx_trackstate_trackState(() => {
            this.up(this.currentSelectedObjects)
          })
          break
        case config.orderEnum.DOWN:
          this.mx_trackstate_trackState(() => {
            this.down(this.currentSelectedObjects)
          })
          break
        case config.orderEnum.STEP_UP:
          this.mx_trackstate_trackState(() => {
            this.stepUp(this.currentSelectedObjects)
          })
          break
        case config.orderEnum.STEP_DOWN:
          this.mx_trackstate_trackState(() => {
            this.stepDown(this.currentSelectedObjects)
          })
          break
      }
      this.showSubmenu.order = false
    }
  }
}
</script>
