import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import config from '@/config'

/**
 * Handles the BoundingBox of selected elements
 * @displayName BoundingBox
 */
export default {
  name: 'MixinBbox',
  /**
     * Data
     * @returns {{mx_bbox_bounds: null, mx_bbox_isCutOutObject: boolean}}
     */
  data: () => {
    return {
      mx_bbox_bounds: null,
      mx_bbox_isCutOutObject: false
    }
  },
  beforeMount () {
    this.$store.watch((state) => state.project.objects.cutOut, (nObj, oObj) => {
      this.mx_bbox_isCutOutObject = (nObj !== null)
    }, { deep: true })
  },
  computed: {
    ...mapState({
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      commonSvgSettings: state => state.common.svg,
      viewboxScale: state => state.editor.viewbox.scale,
      cutOutObject: state => state.project.objects.cutOut
    }),
    ...mapGetters({
      mx_bbox_getObjectById: 'project/objects/getById'
    }),
    /**
         * Returns position x
         * @returns {*}
         */
    mx_bbox_x () {
      return this.mx_bbox_bounds.x
    },
    /**
         * Returns position y
         * @returns {*}
         */
    mx_bbox_y () {
      return this.mx_bbox_bounds.y
    },
    /**
         * Returns width
         * @returns {*}
         */
    mx_bbox_width () {
      return this.mx_bbox_bounds.width
    },
    /**
         * Returns height
         * @returns {*}
         */
    mx_bbox_height () {
      return this.mx_bbox_bounds.height
    },
    /**
         * Returns the offset between object and bounding box
         * @returns {number}
         */
    mx_bbox_bBoxOffset () {
      return this.commonSvgSettings.bBox.space / this.viewboxScale
    },
    /**
         * Returns the svg stroke width
         * @returns {number|*}
         */
    mx_bbox_strokeWidth () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
    },
    /**
         * Returns the radius of an circle object
         * @returns {number|*}
         */
    mx_bbox_circleRadius () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius)
    },
    /**
         * Returns the svg stroke dashArray as string
         * @returns {string}
         */
    mx_bbox_dashArray () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashLength) +
                ',' + this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashSpace)
    },
    /**
         * Returns the bounding box handles
         * - TopRight, BottomRight, BottomLeft, TopLeft
         * - TopMiddle, BottomMiddle, LeftMiddle, RightMiddle
         * @returns {[{x, y, handle: string}, {x, y, handle: string}, {x, y, handle: string}, {x, y, handle: string}]}
         */
    mx_bbox_handles () {
      const handles = [
        { handle: 'tr', x: this.mx_bbox_x + this.mx_bbox_bBoxOffset + this.mx_bbox_width, y: this.mx_bbox_y - this.mx_bbox_bBoxOffset },
        {
          handle: 'br',
          x: this.mx_bbox_x + this.mx_bbox_bBoxOffset + this.mx_bbox_width,
          y: this.mx_bbox_y + this.mx_bbox_bBoxOffset + this.mx_bbox_height
        },
        { handle: 'bl', x: this.mx_bbox_x - this.mx_bbox_bBoxOffset, y: this.mx_bbox_y + this.mx_bbox_bBoxOffset + this.mx_bbox_height },
        { handle: 'tl', x: this.mx_bbox_x - this.mx_bbox_bBoxOffset, y: this.mx_bbox_y - this.mx_bbox_bBoxOffset }
      ]

      if ((this.mx_bbox_isCutOutObject && config.objects.types.CIRCLE !== this.cutOutObject.type) || (this.currentSelectedObjects.length === 1 && config.objects.types.CIRCLE !== this.mx_bbox_getObjectById(this.currentSelectedObjects[0]).type)) {
        handles.push({ handle: 'tm', x: this.mx_bbox_x + (this.mx_bbox_width / 2), y: this.mx_bbox_y - this.mx_bbox_bBoxOffset },
          { handle: 'bm', x: this.mx_bbox_x + (this.mx_bbox_width / 2), y: this.mx_bbox_y + this.mx_bbox_bBoxOffset + this.mx_bbox_height },
          { handle: 'lm', x: this.mx_bbox_x - this.mx_bbox_bBoxOffset, y: this.mx_bbox_y + (this.mx_bbox_height / 2) },
          { handle: 'rm', x: this.mx_bbox_x + this.mx_bbox_width + this.mx_bbox_bBoxOffset, y: this.mx_bbox_y + (this.mx_bbox_height / 2) })
      }

      return handles
    },
    /**
         * Returns the rotation handle
         * @returns {{x: number, width: (number|*), y: number, handle: string, height: (number|*)}}
         */
    mx_bbox_rotationHandle () {
      return {
        handle: 'tmr',
        x: this.mx_bbox_x + (this.mx_bbox_width / 2) - this.mx_math_handleElementsOnScale(8),
        y: this.mx_bbox_y - this.mx_math_handleElementsOnScale(30) - this.mx_bbox_bBoxOffset,
        width: this.mx_math_handleElementsOnScale(16),
        height: this.mx_math_handleElementsOnScale(16)
      }
    }
  },
  methods: {
    ...mapActions({
      scaleObjects: 'project/scaleObjects',
      rotateObjects: 'project/rotateObjects',
      setObjectRotation: 'project/setRotation'
    }),
    ...mapMutations({
      setLastInternalAction: 'editor/' + mutationTypes.SET_LAST_INTERNAL_ACTION
    }),
    /**
         * Called by Resizing the bounding box
         * @param evt Event
         * @public
         */
    mx_bbox_resize (evt) {
      if (this.resizing) {
        const coords = this.mx_math_getCoordinatesFromEvent(evt)
        const d = {
          x: coords.x - this.resizingStart.x,
          y: coords.y - this.resizingStart.y
        }
        this.$_scaleObjects(d)
      }
    },
    /**
         * Called by rotating the bounding box
         * @param e Event
         * @public
         */
    mx_bbox_rotate (e) {
      if (this.rotating) {
        const mouse_point = this.mx_math_convertPointToCoordinates({
          x: e.clientX,
          y: e.clientY
        })

        const center_point = {
          x: this.mx_bbox_bounds.x + (this.mx_bbox_bounds.width / 2),
          y: this.mx_bbox_bounds.y + (this.mx_bbox_bounds.height / 2)
        }

        const atan = Math.atan2(mouse_point.x - center_point.x, mouse_point.y - center_point.y)
        const deg = Math.round((-atan / (Math.PI / 180) + 180))

        this.setObjectRotation({
          deg: deg,
          cx: center_point.x,
          cy: center_point.y
        })
      }
    }
  }
}
