<template>
  <dialog-frame
    :subtitle=" $t('dialogs.underlay.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.underlay.title') "
  >
    <template #content>
      <underlay-upload
        @store="store"
        @open-measurement-settings="openMeasurementSettings"
      />
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions } from 'vuex'

import MixinDialog from '@/mixins/dialogs'

import DialogFrame from '@/components/common/DialogsFrame'
import UnderlayUpload from '@/components/editor/underlay/UnderlayUpload'

export default {
  name: 'SurfaceUnderlayDialog',
  components: {
    DialogFrame,
    UnderlayUpload
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'addSurfaceUnderlay',
      deleteActivated: false
    }
  },
  methods: {
    ...mapActions({
      storeSurfaceUnderlay: 'files/storeSurfaceUnderlay'
    }),
    openMeasurementSettings () {
      this.mx_dialogs_closeDialog()
      this.$events.fire('sub-nav-dialog-measurement-settings')
    },
    /* ------------------------------------------
     *  Functionality - Store, Delete, Toggle
     *------------------------------------------ */

    store (data) {
      let file = null

      if (typeof data === 'object') {
        file = data
      }
      if (typeof data === 'string') {
        const dataURLtoFile = (dataurl, filename) => {
          const arr = dataurl.split(',')
          const mime = arr[0].match(/:(.*?);/)[1]
          const bstr = atob(arr[1])
          let n = bstr.length
          const u8arr = new Uint8Array(n)

          while (n--) {
            u8arr[n] = bstr.charCodeAt(n)
          }

          return new File([u8arr], filename, { type: mime })
        }

        file = dataURLtoFile(data, 'converted.png')
      }

      const size = file.size
      const re = /(?:\.([^.]+))?$/
      const format = re.exec(file.name)[1].toUpperCase()

      this.storeSurfaceUnderlay({ file: file, size: size, type: format })
    }
  }
}
</script>
