import paper from 'paper'

import Vue from 'vue'
import * as types from '@/vuex/mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

const fkt_prepareDataMap = (templates) => {
  const dataMap = new Map()
  templates.forEach(template => {
    const paths = JSON.parse(template.template)
    const group = new paper.Group()
    paths.forEach(path => {
      const cp = new paper.CompoundPath(path)
      cp.fillRule = 'evenodd'
      cp.strokeColor = 'black'
      cp.fillColor = new paper.Color(0.5, 0.4)
      group.addChild(cp.clone())
      cp.remove()
    })

    group.translate(new paper.Point(
      0 - group.bounds.x,
      0 - group.bounds.y
    ))

    dataMap.set(template.id, {
      svg: `<svg id="${group.id}-${Date.now()}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="${group.bounds.width}" height="${group.bounds.height}" viewBox="0,0,${group.bounds.width},${group.bounds.height}">${group.exportSVG({ asString: true })}</svg>`,
      group: group
    })
  })

  return dataMap.size > 0 ? dataMap : null
}

/**
 * default state
 * @returns {{templates: [], dataMap: null}}
 */
const defaultState = () => {
  return {
    templates: [],
    dataMap: null
  }
}

/**
 * state
 * @type {{templates: [], dataMap: null}}
 */
const state = defaultState()

const getters = {
  has: (state) => {
    return state.templates.length > 0
  },
  hasDataMap: () => {
    return state.dataMap !== null
  },
  get: (state) => {
    return state.templates
  },
  getDataMap: (state) => (templateId) => {
    return state.dataMap.get(templateId)
  }
}

const actions = {
  async load ({ rootState, dispatch, commit }) {
    return await apiMethods.request('get', routes.template, null, rootState.backendAPI.login.token)
      .then(response => {
        const templates = response.data.data
        commit(types.TEMPLATES_SET, templates)

        const dataMap = fkt_prepareDataMap(templates)
        commit(types.TEMPLATES_SET_DATA_MAP, dataMap)
        return true
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  async add ({ rootState, dispatch, commit }, data) {
    await apiMethods.request('post', routes.template, data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.TEMPLATES_ADD, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async update ({ rootState, dispatch, commit }, data) {
    await apiMethods.request('patch', `${routes.template}/${data.id}`, data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.TEMPLATES_UPDATE, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async delete ({ rootState, dispatch, commit }, id) {
    await apiMethods.request('delete', `${routes.template}/${id}`, null, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.TEMPLATES_DELETE, id)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  clear ({ commit }) {
    commit(types.TEMPLATES_CLEAR)
  }
}

const mutations = {
  [types.TEMPLATES_SET] (state, templates) {
    if (Object.prototype.hasOwnProperty.call(state, 'templates')) {
      Vue.set(state, 'templates', templates)
    }
  },
  [types.TEMPLATES_SET_DATA_MAP] (state, dataMap) {
    if (Object.prototype.hasOwnProperty.call(state, 'dataMap')) {
      Vue.set(state, 'dataMap', dataMap)
    }
  },
  [types.TEMPLATES_ADD] (state, template) {
    state.templates.push(template)
  },
  [types.TEMPLATES_UPDATE] (state, template) {
    const templateIndex = state.templates.findIndex(t => t.id === template.id)

    if (templateIndex !== -1) {
      Vue.set(state.templates, templateIndex, template)
    }
  },
  [types.TEMPLATES_DELETE] (state, id) {
    const templateIndex = state.templates.findIndex(t => t.id === id)

    if (templateIndex !== -1) {
      Vue.delete(state.templates, templateIndex)
    }
  },
  [types.TEMPLATES_CLEAR] (state) {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
