<template>
  <g v-if="defaultObject !== null">
    <vnodes
      :key="keyboardInput"
      :vnodes="element"
    />
    <text
      v-for="(len, index) in lengths"
      :key="'default-object-' + index"
      :font-size="mx_text_fontSizeLength"
      :stroke-width="mx_text_strokeWidth"
      :x="len.center.x + mx_text_lengthSpacePointX"
      :y="len.center.y + mx_text_lengthSpacePointY"
      stroke="white"
      fill="black"
      text-anchor="middle"
      paint-order="stroke"
    >
      {{ $units.convert(len.cl) }} {{ $settings.lengthUnit }}
      <!--            TODO: Refactor for transform unit -->
      <!--            <tspan :stroke-width="mx_text_strokeWidth" v-if="len.clt"> / {{ len.clt }} {{ measure.transformUnit.label-->
      <!--                }}-->
      <!--            </tspan>-->
    </text>
  </g>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

import { Path, Point } from '@/classes/objects'
import MixinMath from '@/mixins/math'
import MixinText from '@/mixins/text'
import MixinClockwise from '@/mixins/clockwise'
import MixingCollision from '@/mixins/collision'
import MixingDrawCollision from '@/mixins/drawCollision'
import MixinValidate from '@/mixins/validate'

import Rectangle from '@/mixins/tools/rectangle'
import Triangle from '@/mixins/tools/triangle'
import Circle from '@/mixins/tools/circle'
import Polygon from '@/mixins/tools/polygon'

import config from '@/config'

import MixinTrackState from '@/mixins/trackstate'

export default {
  name: 'DefaultObject',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  mixins: [
    MixinMath,
    MixinText,
    MixinClockwise,
    MixingCollision,
    Rectangle,
    Triangle,
    Circle,
    Polygon,
    MixinTrackState,
    MixingDrawCollision,
    MixinValidate
  ],
  data () {
    return {
      cut: false,
      objectType: null,
      defaultObject: null,
      pseudoPoints: [],
      startPoint: null,
      mouse: {
        x: 0,
        y: 0
      },
      activeCtrl: 'drawDefault'
    }
  },
  computed: {
    ...mapState({
      keyboardInput: state => state.pseudo.keyboardInput,
      measure: state => state.project.measurement.measure,
      commonSvgSettings: state => state.common.svg,
      lastObjectId: state => state.project.lastObjectId,
      currentObjectSelection: state => state.events.currentSelectedObjects,
      gridSnap: state => state.editor.grid.snap
    }),
    ...mapGetters({
      getObjectByKey: 'project/objects/getById',
      editor: 'editor/get'
    }),
    fontSize () {
      const size = this.mx_math_handleElementsOnScale(this.commonSvgSettings.widthTextSize)
      return `font-size: ${size}px;`
    },
    element () {
      if (this.defaultObject.points && this.defaultObject.points.length > 0) {
        switch (this.objectType) {
          case config.objects.DEFAULT_RECTANGLE:
            return this.mx_tools_rectangle_rectangle(this.keyboardInput)
          case config.objects.DEFAULT_TRIANGLE:
            return this.mx_tools_trianlge_triangle()
          case config.objects.DEFAULT_CIRCLE:
            return this.mx_tools_circle_circle(this.keyboardInput)
          case config.objects.DEFAULT_POLYGON:
            return this.mx_tools_polygon_polygon(this.keyboardInput)
        }
      }

      return null
    },
    lengths () {
      return this.mx_math_calculateLengthsFromPoints(this.pseudoPoints, this.measure)
    }
  },
  watch: {
    defaultObject: {
      handler: function (n, o) {
        if (this.objectType === config.objects.DEFAULT_RECTANGLE && n.points && n.points.length === 3) {
          this.$events.fire(editorEventTypes.DRAW_DEFAULT_FINISH_RECTANGLE, this.keyboardInput)
          this.$events.fire(editorEventTypes.TOOLBAR_RESET_SUBMENU_CONTAINERS)
        }
        if (this.objectType === config.objects.DEFAULT_TRIANGLE && n.points && n.points.length === 3) {
          this.$events.fire(editorEventTypes.DRAW_DEFAULT_FINISH_TRIANGLE)
          this.$events.fire(editorEventTypes.TOOLBAR_RESET_SUBMENU_CONTAINERS)
        }
        if (this.objectType === config.objects.DEFAULT_CIRCLE && n.points && n.points.length === 3) {
          this.$events.fire(editorEventTypes.DRAW_DEFAULT_FINISH_CIRCLE)
          this.$events.fire(editorEventTypes.TOOLBAR_RESET_SUBMENU_CONTAINERS)
        }
      },
      deep: true
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.DRAW_DEFAULT_OBJECT, payload => {
      this.mx_trackstate_beginStateTransaction()
      this.cut = payload.cut
      this.objectType = payload.type
      this.pseudoPoints = []
      this.defaultObject = new Path('predefined')
      if (this.objectType === 'circle') {
        this.defaultObject.type = config.objects.types.CIRCLE
      }
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, this.activeCtrl)
      if (this.cut) {
        this.setEventMode({ cut: true })
        const currentSelectedObject = this.getObjectByKey(this.currentObjectSelection[0])
        this.setSelectionMode({ object: currentSelectedObject, selectionMode: config.objects.modes.SELECTED })
      }
    })
    this.$events.on(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, this.updateDefaultObjectMouse)
    this.$events.on(editorEventTypes.DRAW_ADD_POINT_TO_DEFAULT_PATH, this.addDefaultObjectPoint)
    this.$events.on(editorEventTypes.DRAW_DEFAULT_ESCAPE_PRESSED, this.resetOrUndoDrawDefault)
  },
  methods: {
    ...mapActions({
      addObjectToProject: 'project/addObjectToProject',
      addObjectToCutOut: 'project/addObjectToCutOut',
      addObjectToSelection: 'events/addObjectToSelection',
      setSelectionMode: 'project/objects/setSelectionMode',
      setKeyboardInput: 'pseudo/setKeyboardInput'
    }),
    ...mapMutations({
      updateLastObjectId: 'project/' + mutationTypes.UPDATE_PROJECT_LAST_OBJECT_ID,
      setEventMode: 'events/' + mutationTypes.EVT_UPDATE_EDITOR_MODE
    }),
    updateDefaultObjectMouse (evt) {
      const evtPoint = this.mx_math_getCoordinatesFromEvent(evt)
      if (this.gridSnap && !this.snapKeyPressed) {
        this.mouse = this.mx_collision_closestGridPoint(evtPoint)
      } else {
        this.mouse = { x: evtPoint.x, y: evtPoint.y }
      }
    },
    addDefaultObjectPoint (evt) {
      let evtPoint = this.mx_math_getCoordinatesFromEvent(evt)

      if (this.keyboardInput > 0 && this.defaultObject.points.length > 0) {
        const point = this.defaultObject.points.slice(-1).pop()

        if (this.objectType === config.objects.DEFAULT_RECTANGLE) {
          evtPoint = this.mx_tools_rectangle_get_point(this.mouse, this.keyboardInput, point)
        } else {
          evtPoint = this.mx_math_getPointInDistance(point,
            this.mouse, this.keyboardInput, this.measure)
        }
      }
      if (!this.mx_validate_validatePathClosing(
        this.defaultObject.points,
        new Point(evtPoint.x, evtPoint.y, 'L')
      )) {
        return false
      }

      if (this.gridSnap && !this.snapKeyPressed) {
        evtPoint = this.mx_collision_closestGridPoint(evtPoint)
        this.mouse = evtPoint
      }

      if (this.snapKeyPressed && this.mx_collision_collisionPoints.length) {
        const cpT = this.mx_collision_getCollisionPointTupleWithSmallestDistance(this.mx_collision_collisionPoints)
        evtPoint = { x: cpT.p1.x, y: cpT.p1.y }
        this.mouse = { x: evtPoint.x, y: evtPoint.y }
      } else if (this.gridSnap) {
        evtPoint = this.mx_collision_closestGridPoint(evtPoint)
        this.mouse = evtPoint
      }

      if (!this.defaultObject.hasPoints(0)) {
        this.defaultObject.points.push(new Point(evtPoint.x, evtPoint.y, 'M'))
        this.$events.fire(editorEventTypes.DRAW_START)
      }

      const _p = new Point(evtPoint.x, evtPoint.y)
      const _hitTest = this.defaultObject.hitTest(_p)

      if (_hitTest.hit) {
        if (this.objectType === 'circle') {
          this.defaultObject.points.push(_p)
        }
        this.$events.fire(editorEventTypes.DRAW_DEFAULT_CUT_HIT_START)
      } else {
        this.defaultObject.points.push(_p)
        if (this.mx_collision_collisionPoints.length) {
          this.mx_collision_setCollisionPoints([])
        }
      }

      if (this.defaultObject.points.length > 0) {
        this.$emit('defaultObjectHasPoints', true)
      }

      if (this.objectType === 'polygon') {
        this.setKeyboardInput(0)
        this.$emit('defaultObjectResetDrawInput')
      }
    },
    defaultCircle (point) {
      return this.$createElement('circle', {
        class: {
          'c-object c-object__circle': true,
          'c-object__circle--active': true
        },
        attrs: {
          cx: point.x,
          cy: point.y,
          r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
        }
      })
    },
    defaultLine (coords) {
      return this.$createElement('line', {
        class: {
          'c-object c-object__line c-object--calc': true
        },
        attrs: {
          x1: coords.x1,
          y1: coords.y1,
          x2: coords.x2,
          y2: coords.y2,
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth),
          'stroke-dasharray': this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashLength) +
                            ',' + this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashSpace)
        }
      })
    },
    defaultPattern (payload, renderType = null, withLine = true) {
      let pathData = ''

      switch (renderType) {
        case 'ellipse': {
          const middle = payload[0]
          const radius = this.mx_math_getDistance(payload[0], payload[1])
          pathData = `M ${middle.x} ${middle.y} m-${radius},
                            0 a${radius},${radius} 0 1,0 ${radius * 2},
                            0 a${radius},${radius} 0 1,0 -${radius * 2},
                            0Z`
          break
        }
        case 'polygon':
          break
        default:
          payload.forEach((point, index) => {
            if (point.type === undefined) {
              if (index === 0) {
                pathData += `M ${point.x} ${point.y}`
              }
              pathData += `L ${point.x} ${point.y}`
            }
          })
          break
      }
      pathData += 'Z'

      return this.$createElement('path', {
        class: {
          'c-object c-object__default-form': true,
          'c-object__pattern c-object__pattern--cut': this.cut
        },
        attrs: {
          d: pathData,
          'stroke-width': (withLine) ? this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth) : 0,
          'stroke-dasharray': this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashLength) +
                            ',' + this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashSpace)
        }
      })
    },
    defaultObjectReset () {
      this.cut = false
      this.objectType = null
      this.mouse = {
        x: 0,
        y: 0
      }
      this.defaultObject = null
      this.pseudoPoints = []

      this.$emit('defaultObjectHasPoints', false)

      this.$events.fire('CUT_DRAW_TOOL', null)
    },
    updateObjectId () {
      const newId = this.lastObjectId + 1
      this.defaultObject.id = this.defaultObject.id.replace('predefined', '') + newId
      this.updateLastObjectId(newId)
    },
    updateObjectPointIds () {
      this.defaultObject.points = this.defaultObject.points.map(point => {
        const _id = this.defaultObject.lastPointId + 1
        point.setID(`${this.defaultObject.id.replace(config.objects.ID, '')}-${_id}`)
        this.defaultObject.lastPointId = _id
        return point
      })
    },
    finishPredefinedObject () {
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
      this.updateObjectId()
      this.defaultObject.points = this.mx_clockwise_clockwise(this.defaultObject.points)
      this.updateObjectPointIds()
      this.addObjectToProject(this.defaultObject)
      this.defaultObjectReset()
      this.mx_trackstate_endStateTransaction()
    },
    preparePredefinedObject () {
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
      this.defaultObject.isCutOut = true
      this.updateObjectPointIds()
      this.addObjectToCutOut(this.defaultObject)
      this.defaultObjectReset()
    },
    resetOrUndoDrawDefault () {
      if (this.defaultObject.hasPoints(2) && this.objectType === 'polygon') {
        // const point = this.defaultObject.removeLastPoint()
        this.defaultObject.removeLastPoint()
      } else {
        this.setEventMode({ cut: false })
        this.defaultObjectReset()
        this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
        this.mx_trackstate_breakStateTransaction()
      }
    }
  }
}
</script>
