<template>
  <g
    v-if="active"
    :id="'lengthsGroup-' + id"
    class="no-pointer-events"
  >
    <text
      v-for="(edge,index) in lineLengths"
      :key="'length-text-' + index"
      :font-size="mx_text_fontSizeLength"
      :stroke-width="mx_text_strokeWidth"
      :x="edge.center.x + mx_text_lengthSpacePointX"
      :y="edge.center.y + mx_text_lengthSpacePointY"
      stroke="white"
      fill="black"
      text-anchor="middle"
      paint-order="stroke"
    >
      {{ $units.convert(edge.cl) }} {{ $settings.lengthUnit }}
    </text>
  </g>
</template>
<script>

import MixinMath from '@/mixins/math'
import MixinPoints from '@/mixins/points'
import MixinDom from '@/mixins/dom'
import MixinText from '@/mixins/text'
import { Path } from '@/classes/objects'

import { mapState, mapGetters } from 'vuex'

/**
     * Shows the object outline length
     * @displayName Object length
     */
export default {
  name: 'Lengths',
  mixins: [MixinMath, MixinPoints, MixinDom, MixinText],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    line: {
      type: [Object, Array],
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      default: true
    },
    drawing: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lineLengths: []
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    }),
    ...mapGetters({
      getObject: 'project/objects/getById'
    }),
    currentObject () {
      return this.getObject(this.id)
    }
  },
  watch: {
    active (val) {
      if (!val) {
        this.lineLengths = []
      }
    },
    mx_points_filteredPoints () {
      this.setLengths()
    },
    currentObject: {
      handler (val) {
        if (val instanceof Path) {
          this.setLengths()
        }
      },
      deep: true
    },
    measure: {
      handler () {
        this.setLengths()
      },
      deep: true
    }
  },
  mounted () {
    this.setLengths()
  },
  methods: {
    setLengths () {
      this.lineLengths = this.currentObject ? this.getLengthsFromDomLines() : this.getLengthsFromPoints()
    },
    getLengthsFromDomLines () {
      return this.mx_math_calculateLengthsFromDomLines(this.mx_dom_getLinesFromObject(this.id, this.id === 'pseudoLengths' ? 'lengthsGroup' : 'group'), this.measure)
    },
    getLengthsFromPoints () {
      const l = this.mx_math_calculateLengthsFromPoints(this.mx_points_filteredPoints, this.measure)
      if (this.line) {
        l.pop()
      }
      return l
    }
  }
}
</script>
