<template>
  <dialogs-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.editApiClient.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.editApiClient.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <div class="o-grid o-grid--center">
          <div class="o-grid__col u-4/4 u-text-center">
            <icon classes="fas fa-2x fa-exclamation-triangle u-color-o u-mt-size-standard u-mb-size-standard" />
            <p
              class="u-mb-size-40"
              v-html="$t('dialogs.editApiClient.info')"
            />
            <form class="c-form">
              <input-field
                v-if="apiClient"
                id="create-api-client-username"
                v-model="apiClient.name"
                :focus-on-mount="false"
                :is-store-mode="false"
                :label="$t('dialogs.editApiClient.form.username')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="update-client-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="update-client-save"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.save')"
            :on-click-action="() => { return editApiClient() }"
          />
        </div>
      </div>
    </template>
  </dialogs-frame>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash'

import DialogsFrame from '@/components/common/DialogsFrame'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

export default {
  name: 'EditApiClientDialog',
  components: {
    Icon,
    DialogsFrame,
    ButtonBase,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      targetDialog: 'editApiClient',
      cannotClose: false,
      apiClient: null
    }
  },
  computed: {
    ...mapGetters({
      getDialogProps: 'dialogs/getDialogProps'
    })
  },
  mounted () {
    this.apiClient = _.cloneDeep(this.getDialogProps(this.targetDialog))
  },
  methods: {
    ...mapActions({
      updateApiClient: 'backendAPI/apiClient/update'
    }),
    async editApiClient () {
      await this.updateApiClient(this.apiClient)
      this.close()
    },
    close () {
      this.apiClient = null
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
