<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.deleteClient.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.deleteClient.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p>{{ $t('dialogs.deleteClient.info') }}</p>
        <span
          class="u-color-b1 u-mt-size-standard"
        >
          {{ clientName }}
        </span>
        <p class="u-mt-size-standard">
          {{ $t('common.noUndo') }}
        </p>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="delete-client-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
          />
          <button-base
            id="delete-client-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return doDeleteClient() }"
            style-color="gray"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Delete Client
     */
export default {
  name: 'DeleteClient',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'deleteClient',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.deleteClient
    }),
    ...mapGetters({
      getClients: 'backendAPI/client/getAll'
    }),
    clientName () {
      const client = this.getClients.find(client => client.id === this.dialogProps.clientData.id)
      return (client !== undefined) ? client.label : null
    }
  },
  methods: {
    ...mapActions({
      deleteClient: 'backendAPI/client/delete'
    }),
    ...mapMutations({}),
    close () {
      this.mx_dialogs_closeDialog()
    },
    async doDeleteClient () {
      await this.deleteClient(this.dialogProps.clientData.id)
      this.close()
    }
  }
}
</script>
