import Vue from 'vue'
import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Pedestal Store
 *
 * @returns {{specialToUpdate: null, specialPedestals: [], pedestals: []}}
 */
const defaultState = () => {
  return {
    specialPedestals: [],
    pedestals: [],
    specialToUpdate: null,
    meta: null
  }
}

const state = defaultState()

const getters = {
  getMeta (state) {
    return state.meta
  },
  /**
     * Has pedestals
     *
     * @param state
     * @returns {boolean}
     */
  hasPedestals (state) {
    return !_.isEmpty(state.pedestals)
  },
  /**
     * Get pedestals
     *
     * @param state
     * @returns {[]}
     */
  getPedestals (state) {
    return state.pedestals
  },
  /**
     * Has special pedestals
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialPedestals (state) {
    return !_.isEmpty(state.specialPedestals)
  },
  /**
     * Get special pedestals
     *
     * @param state
     * @returns {[]}
     */
  getSpecialPedestals (state) {
    return state.specialPedestals
  },
  /**
     * Has product to update
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialToUpdate (state) {
    return state.specialToUpdate !== null
  },
  /**
     * Get special pedestal for update
     *
     * @param state
     * @returns {{}|null}
     */
  getSpecialForUpdate: (state) => {
    return (state.specialToUpdate !== null) ? _.cloneDeep(state.specialToUpdate) : null
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_PEDESTAL_STATE)
  },
  /**
     * Reset PIM state
     *
     * @param dispatch
     * @param commit
     */
  resetPIMState ({ dispatch, commit }) {
    commit(types.RESET_PEDESTAL_PIM_STATE)
  },
  /**
     * Set special product to update
     *
     * @param commit
     * @param specialPedestal
     */
  setSpecialToUpdate ({ commit }, specialPedestal) {
    commit(types.PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTAL_TO_UPDATE, specialPedestal)
  },
  /**
     * Get all pedestals for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.location + `/${rootState.backendAPI.product.locationId}/pedestals`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PEDESTAL_SET_PEDESTALS, response.data.data)
        commit(types.PRODUCT_PEDESTAL_SET_META, response.data.meta)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update location pedestal
     *
     * @param dispatch
     * @param pedestal
     */
  updateLocation ({ dispatch }, pedestal) {
    pedestal.relate_to_latest = true
    dispatch('update', [pedestal])
  },
  /**
     * Update all pedestals for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param state
     * @param pedestals
     * @returns {Promise<void>}
     */
  async update ({ rootState, dispatch, commit, state }, pedestals) {
    await apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/pedestals`, { update: pedestals }, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PEDESTAL_SET_PEDESTALS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get all special pedestals for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listSpecial ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.pedestal, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTALS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a special pedestal for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialPedestal
     * @returns {Promise<boolean>}
     */
  async createSpecial ({ rootState, dispatch, commit }, specialPedestal) {
    if (!Object.prototype.hasOwnProperty.call(specialPedestal.data, 'group_id')) {
      specialPedestal.data.group_id = rootState.backendAPI.user.me.group.id
    }

    const createdPedestal = await apiMethods.request('post', routes.pedestal, specialPedestal.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PEDESTAL_ADD_SPECIAL_PEDESTAL, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return true
      })

    if (createdPedestal !== null) {
      const response = await specialPedestal.fileCallback(routes.pedestal + `/${createdPedestal.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL, response.postMediaResponse)
        }
      }
    }
  },
  /**
     * Update a special pedestal for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialPedestal
     * @returns {Promise<void>}
     */
  async updateSpecial ({ rootState, state, dispatch, commit }, specialPedestal) {
    const updatedPedestal = await apiMethods.request('patch', routes.pedestal + `/${state.specialToUpdate.id}`, specialPedestal.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL, response.data.data)
        commit(types.PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTAL_TO_UPDATE, null)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })

    if (updatedPedestal !== null) {
      const response = await specialPedestal.fileCallback(routes.pedestal + `/${updatedPedestal.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Delete a special pedestal for distributor, manufacture
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {[Number]} pedestalIds
   * @returns {Promise<void>}
   */
  async deleteSpecial ({ rootState, dispatch, commit }, pedestalIds) {
    await apiMethods.request('delete', routes.pedestal, { ids: pedestalIds }, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.PRODUCT_PEDESTAL_DELETE_SPECIAL_PEDESTAL, pedestalIds)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_PEDESTAL_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.RESET_PEDESTAL_PIM_STATE] (state) {
    state.pedestals = []
  },
  [types.PRODUCT_PEDESTAL_SET_PEDESTALS] (state, pedestals) {
    state.pedestals = pedestals
  },
  [types.PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTALS] (state, specialPedestals) {
    state.specialPedestals = specialPedestals
  },
  [types.PRODUCT_PEDESTAL_ADD_SPECIAL_PEDESTAL] (state, specialPedestal) {
    state.specialPedestals.push(specialPedestal)
  },
  [types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL] (state, specialPedestal) {
    const index = state.specialPedestals.findIndex(i => i.id === specialPedestal.id)

    if (index !== -1) {
      Vue.set(state.specialPedestals, index, specialPedestal)
    }
  },
  [types.PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTAL_TO_UPDATE] (state, specialPedestal) {
    state.specialToUpdate = specialPedestal
  },
  [types.PRODUCT_PEDESTAL_DELETE_SPECIAL_PEDESTAL] (state, pedestalIds) {
    state.specialPedestals = state.specialPedestals.filter(pedestal => {
      return pedestal.id !== pedestalIds.filter(pedestalId => pedestalId === pedestal.id)[0]
    })
  },
  [types.PRODUCT_PEDESTAL_SET_META] (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
