<template>
  <context-menu
    v-if="menu.ctrl === 'menu-object' && !menu.isCutOut"
    :id="'object'"
    :title=" $t('editor.contextMenu.object.title') "
  >
    <!-- Duplicate -->
    <button
      v-if="mx_operations_selectedObjectsCount === 1"
      @click="cloneObject()"
    >
      <icon classes="far fa-clone" />
      <span>{{ $t('editor.contextMenu.object.duplicate') }}</span>
    </button>

    <!-- Collar -->
    <button
      v-if="mx_operations_selectedObjectsCount === 1"
      @click="createObjectCollar()"
    >
      <icon classes="fas fa-bullseye" />
      <span>{{ $t('editor.contextMenu.object.collar') }}</span>
    </button>

    <!-- Template -->
    <button
      @click="addAsTemplate()"
    >
      <icon classes="c-context-menu__icon">
        <template-add-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.object.template') }}</span>
    </button>

    <!-- Operations -->
    <button
      v-if="mx_operations_selectedObjectsCount === 2"
      id="objectMetaMenuUnite"
      :disabled="!unite"
      @click="mx_operations_booleanOperation('unite')"
    >
      <icon classes="c-context-menu__icon">
        <operations-add-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.object.add') }}</span>
    </button>
    <button
      v-if="mx_operations_selectedObjectsCount === 2"
      id="objectMetaMenuSubtract"
      :disabled="!subtract"
      @click="mx_operations_booleanOperation('subtract')"
    >
      <icon classes="c-context-menu__icon">
        <operations-subtract-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.object.subtract') }}</span>
    </button>
    <button
      v-if="mx_operations_selectedObjectsCount === 2"
      id="objectMetaMenuExclude"
      :disabled="!exclude"
      @click="mx_operations_booleanOperation('exclude')"
    >
      <icon classes="c-context-menu__icon">
        <operations-exclude-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.object.exclude') }}</span>
    </button>
    <button
      v-if="mx_operations_selectedObjectsCount === 2"
      id="objectMetaMenuFragment"
      :disabled="!fragment"
      @click="mx_operations_booleanOperation('fragment')"
    >
      <icon classes="c-context-menu__icon">
        <operations-fragment-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.object.fragment') }}</span>
    </button>

    <!-- Order -->
    <button
      class="c-context-menu__group-container"
      @mouseenter="groups.order.show = true"
      @mouseleave="groups.order.show = false"
    >
      <icon classes="c-context-menu__icon">
        <order-menu-icon />
      </icon>
      <span>{{ $t('editor.contextMenu.order.title') }}</span>

      <transition name="c-context-menu__group">
        <span
          v-if="groups.order.show"
          class="c-context-menu__group"
        >
          <button
            :disabled="isInForeground"
            @click="changeObjectOrder(order.up)"
          >
            <icon classes="c-context-menu__icon">
              <order-up-total-icon />
            </icon>
            <span>{{ $t('editor.contextMenu.order.up-total') }}</span>
          </button>
          <button
            :disabled="isInForeground"
            @click="changeObjectOrder(order.stepUp)"
          >
            <icon classes="c-context-menu__icon">
              <order-up-icon />
            </icon>
            <span>{{ $t('editor.contextMenu.order.up') }}</span>
          </button>
          <button
            :disabled="isInBackground"
            @click="changeObjectOrder(order.stepDown)"
          >
            <icon classes="c-context-menu__icon">
              <order-down-icon />
            </icon>
            <span>{{ $t('editor.contextMenu.order.down') }}</span>
          </button>
          <button
            :disabled="isInBackground"
            @click="changeObjectOrder(order.down)"
          >
            <icon classes="c-context-menu__icon">
              <order-down-total-icon />
            </icon>
            <span>{{ $t('editor.contextMenu.order.down-total') }}</span>
          </button>
        </span>
      </transition>
    </button>

    <!-- Product -->
    <button
      v-if="objectHasProduct"
      @click="toggleLosMode"
    >
      <icon classes="c-context-menu__icon">
        <los-eye-blue-icon />
      </icon>
      <span> {{ $t('editor.contextMenu.object.editProduct') }}</span>
    </button>

    <button
      v-if="currentSelectedProducts.length > 0"
      @click="removeProductFromObjects()"
    >
      <icon classes="far fa-times-circle" />
      <span v-if="currentSelectedObjects.length === 1">{{ $t('editor.contextMenu.object.removeProduct') }}</span>
      <span v-else>{{ $t('editor.contextMenu.object.removeProducts') }}</span>
    </button>

    <!-- Delete -->
    <button @click="deleteObject()">
      <icon classes="fas fa-2x fa-trash-alt" />
      <span>{{ $t('editor.contextMenu.object.delete') }}</span>
    </button>
  </context-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'
import config from '@/config'

import ContextMenu from '@/components/common/ContextMenu'

import MixinOperations from '@/mixins/operations'
import MixinTrackState from '@/mixins/trackstate'
import MixinDialog from '@/mixins/dialogs'
import Icon from '@/components/common/Icon'
import OperationsAddIcon from '@/components/common/icons/operations/OperationsAddIcon'
import OperationsSubtractIcon from '@/components/common/icons/operations/OperationsSubtractIcon'
import OperationsExcludeIcon from '@/components/common/icons/operations/OperationsExcludeIcon'
import OperationsFragmentIcon from '@/components/common/icons/operations/OperationsFragmentIcon'
import OrderMenuIcon from '@/components/common/icons/order/OrderMenuIcon'
import OrderUpTotalIcon from '@/components/common/icons/order/OrderUpTotalIcon'
import OrderUpIcon from '@/components/common/icons/order/OrderUpIcon'
import OrderDownTotalIcon from '@/components/common/icons/order/OrderDownTotalIcon'
import OrderDownIcon from '@/components/common/icons/order/OrderDownIcon'
import LosEyeBlueIcon from '@/components/common/icons/los/LosEyeBlueIcon'
import TemplateAddIcon from '@/components/common/icons/templates/TemplateAddIcon'

export default {
  name: 'MenuObject',
  components: {
    TemplateAddIcon,
    LosEyeBlueIcon,
    OrderDownIcon,
    OrderDownTotalIcon,
    OrderUpIcon,
    OrderUpTotalIcon,
    OrderMenuIcon,
    OperationsFragmentIcon,
    OperationsExcludeIcon,
    OperationsSubtractIcon,
    OperationsAddIcon,
    Icon,
    ContextMenu
  },
  mixins: [
    MixinOperations,
    MixinTrackState,
    MixinDialog
  ],
  data () {
    return {
      groups: {
        order: {
          show: false
        }
      },
      order: {
        up: config.orderEnum.UP,
        down: config.orderEnum.DOWN,
        stepUp: config.orderEnum.STEP_UP,
        stepDown: config.orderEnum.STEP_DOWN
      }
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      menu: state => state.events.contextMenu,
      currentSelectedProducts: state => state.events.currentSelectedProducts,
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      unite: state => state.events.booleanOperations.unite,
      subtract: state => state.events.booleanOperations.subtract,
      exclude: state => state.events.booleanOperations.exclude,
      fragment: state => state.events.booleanOperations.fragment
    }),
    ...mapGetters({
      isObjectInBackground: 'project/layers/isObjectInBackground',
      isObjectInForeground: 'project/layers/isObjectInForeground',
      hasProduct: 'project/objects/hasProduct',
      getObject: 'project/objects/getById'
    }),
    isInBackground () {
      return this.isObjectInBackground(this.currentSelectedObjects)
    },
    isInForeground () {
      return this.isObjectInForeground(this.currentSelectedObjects)
    },
    objectHasProduct () {
      if (this.currentSelectedObjects.length === 1 && this.ctrl === 'move' && !this.isCutOut) {
        if (this.currentSelectedProducts.length > 0) {
          return true
        }
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      up: 'project/layers/objectUp',
      down: 'project/layers/objectDown',
      stepUp: 'project/layers/objectStepUp',
      stepDown: 'project/layers/objectStepDown',
      removeProductFromSelectedObjects: 'project/objects/removeProductFromSelectedObjects',
      toggleLineOfSightMode: 'events/toggleLineOfSightMode',
      setObjectToWorkWith: 'lineOfSightNew/setObjectToWorkWith'
    }),
    ...mapMutations({
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU,
      openDialog: 'dialogs/' + mutationTypes.DIALOGS_TOGGLE
    }),
    changeObjectOrder (order) {
      switch (order) {
        case config.orderEnum.UP:
          this.mx_trackstate_trackState(() => {
            this.up(this.currentSelectedObjects)
          })
          break
        case config.orderEnum.DOWN:
          this.mx_trackstate_trackState(() => {
            this.down(this.currentSelectedObjects)
          })
          break
        case config.orderEnum.STEP_UP:
          this.mx_trackstate_trackState(() => {
            this.stepUp(this.currentSelectedObjects)
          })
          break
        case config.orderEnum.STEP_DOWN:
          this.mx_trackstate_trackState(() => {
            this.stepDown(this.currentSelectedObjects)
          })
          break
      }
      this.groups.order.show = false
      this.hideMouseMenu()
    },
    toggleLosMode () {
      const object = this.getObject(this.currentSelectedObjects[0])
      this.toggleLineOfSightMode()
      this.setObjectToWorkWith(object)
      this.hideMouseMenu()
    },
    cloneObject () {
      this.$events.fire(editorEventTypes.OBJECT_DUPLICATE)
      this.hideMouseMenu()
    },
    createObjectCollar () {
      this.openDialog({ dialog: 'collar', onOff: true })
      this.hideMouseMenu()
    },
    addAsTemplate () {
      this.openDialog({ dialog: 'addTemplate', onOff: true, dialogProps: { objectIds: this.currentSelectedObjects } })
      this.hideMouseMenu()
    },
    deleteObject () {
      this.$events.fire(editorEventTypes.PROJECT_REMOVE_SELECTED_OBJECTS)
      this.hideMouseMenu()
    },
    removeProductFromObjects () {
      this.mx_trackstate_trackState(() => {
        this.removeProductFromSelectedObjects({ objects: this.currentSelectedObjects, product: null })
      })
      this.hideMouseMenu()
    }
  }
}
</script>
