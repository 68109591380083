<template>
  <svg
    id="operation-fragment-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M25.4,14.58v8.18a2.66,2.66,0,0,1-2.66,2.67H14.6V17.24a2.66,2.66,0,0,1,2.66-2.66Z"
    />
    <path
      class="u-fill-w"
      d="M22.09,3.68v7.54H13.91a2.68,2.68,0,0,0-2.68,2.69v8.18H3.68A2.67,2.67,0,0,1,1,19.41V3.68A2.67,2.67,0,0,1,3.68,1H19.41A2.68,2.68,0,0,1,22.09,3.68Z"
    />
    <path
      class="u-fill-w"
      d="M17.91,36.32V28.78h8.18a2.68,2.68,0,0,0,2.68-2.69V17.91h7.55A2.67,2.67,0,0,1,39,20.59V36.32A2.67,2.67,0,0,1,36.32,39H20.59A2.68,2.68,0,0,1,17.91,36.32Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OperationsFragmentIcon'
}
</script>
