import { v4 as uuidv4 } from 'uuid'
/**
 * Class Turf
 * It contains a collection of settings
 * @class Turf
 */
class Turf {
  /**
     * @param {number} id Turf ID
     * @param {object} price Turf price each linear length
     * @param {float} width Turf Width
     * @param {float} length Turf Length
     * @param {string} unit unit and width unit
     * @param {number} version
    * @param {number} overlay_id
     */
  constructor (id, price, width, length, unit, version, overlay_id) {
    this.className = 'Turf'
    this.id = id
    this.lastTrackId = 0
    this.angle = 0
    this.shift = 0
    this.width = width
    this.length = length
    this.unit = unit
    this.threshold = 0
    this.perfectVisualResult = true
    this.max = {
      width: 0,
      length: 0
    }
    this.price = price
    this.alignment = false
    this.tracks = null
    this.subbases = []
    this.infills = []
    this.seamtape = null
    this.adhesive = null
    this.version_nr = version
    this.overlay_id = overlay_id
  }

  /**
     * Returns turf ID
     * @returns {number}
     */
  getId () {
    return this.id
  }

  /**
     * Turf ID
     * @param {number} id
     */
  setId (id) {
    this.id = id
  }

  /**
     * Returns price each linear length
     * @returns {Object}
     */
  getPrice () {
    return this.price
  }

  /**
     * Returns turf ID
     * @returns {number}
     */
  getLastTrackId () {
    return this.lastTrackId
  }

  /**
     * Turf ID
     * @param {number} id
     */
  setLastTrackId (id) {
    this.lastTrackId = id
  }

  /**
     * Returns angle
     * @returns {float}
     */
  getAngle () {
    return this.angle
  }

  /**
     * Angle
     * @param {float} angle
     */
  setAngle (angle) {
    this.angle = angle
  }

  /**
     * Returns shift x
     * @returns {number}
     */
  getShift () {
    return this.shift
  }

  /**
     * Shift x
     * @param shift
     */
  setShift (shift) {
    this.shift = shift
  }

  /**
     * Returns turf width
     * @returns {float}
     */
  getWidth () {
    return this.width
  }

  /**
     * Turf width
     * @param {float} width
     */
  setWidth (width) {
    this.width = width
  }

  /**
     * Returns turf length
     * @returns {float}
     */
  getLength () {
    return this.length
  }

  /**
     * Turf length
     * @param {float} length
     */
  setLength (length) {
    this.length = length
  }

  /**
     * Returns turf unit
     * @returns {string}
     */
  getUnit () {
    return this.unit
  }

  /**
     * Turf unit
     * @param {string} unit
     */
  setUnit (unit) {
    this.unit = unit
  }

  /**
     * Returns turf threshold
     * @returns {float}
     */
  getThreshold () {
    return this.threshold
  }

  /**
     * Turf threshold
     * @param {float} threshold
     */
  setThreshold (threshold) {
    this.threshold = threshold
  }

  /**
     * Returns perfect visual result flag
     * @returns {boolean}
     */
  getPerfectVisualResult () {
    return this.perfectVisualResult
  }

  /**
     * Set perfect visual result flag
     * @param {boolean} perfectVisualResult
     */
  setPerfectVisualResult (perfectVisualResult) {
    this.perfectVisualResult = perfectVisualResult
  }

  /**
     * Set max values for turf track
     * @param width
     * @param length
     */
  setMaxValues (width, length) {
    this.max = {
      width: width,
      length: length
    }
  }

  /**
     * Returns max values for turf track
     * @returns {{width: number, length: number}}
     */
  getMaxValues () {
    return this.max
  }

  /**
     * Returns turf alignment
     * @returns {boolean}
     */
  getAlignment () {
    return this.alignment
  }

  /**
     * Set alignment
     * @param alignment
     */
  setAlignment (alignment) {
    this.alignment = alignment
  }

  /**
     * Returns turf substructures [subbase, subbase, ...]
     * @returns {array}
     */
  getSubstructures () {
    return this.subbases
  }

  /**
     * Set substructures [subbase, subbase, ...]
     * @param subbases
     */
  setSubstructures (subbases) {
    this.subbases = subbases
  }

  /**
     *
     * @param {Subbase} subbase
     */
  addSubbase (subbase) {
    this.subbases.push(subbase)
  }

  /**
     *
     * @param {Number} key
     */
  removeSubbase (key) {
    this.subbases.splice(key, 1)
  }

  /**
     * Returns number of substructures parts
     */
  getUsedSubstructuresParts () {
    return this.subbases.length
  }

  /**
     * Returns turf infills [infill, infill, ...]
     * @returns {array}
     */
  getInfills () {
    return this.infills
  }

  /**
     * Set infills [infill, infill, ...]
     * @param infills
     */
  setInfills (infills) {
    this.infills = infills
  }

  /**
     *
     * @param {Infill} infill
     */
  addInfill (infill) {
    this.infills.push(infill)
  }

  /**
     * Removes infill with given array key
     * @param {Number} key
     */
  removeInfill (key) {
    this.infills.splice(key, 1)
  }

  /**
     * Returns number of track parts
     */
  getUsedInfillsParts () {
    return this.infills.length
  }

  /**
     * Returns number of track parts
     */
  getUsedTrackParts () {
    return (this.tracks) ? this.tracks.filter(t => !t.usedWaste).length : 0
  }

  getUsedWasteTrackParts () {
    return (this.tracks) ? this.tracks.filter(t => t.usedWaste).length : 0
  }

  /**
     * Returns the price each linear length
     * @returns {string|number}
     * @deprecated
     */
  getTotalPrice () {
    return (parseFloat(this.getLinearLengthPrice()) + parseFloat(this.getTotalCutCosts())).toFixed(2)
  }

  /**
     * Returns the price each linear length
     * @returns {string|number}
     * @deprecated
     */
  getLinearLengthPrice () {
    if (this.tracks && this.tracks.length > 0) {
      const linearLength = this.getLinearLengthWithOversize()
      return (Number(linearLength) * this.price.each).toFixed(2)
    }

    return 0.00
  }

  /**
     * Returns the cut costs for tracks smaller than max Turf length
     * @returns {string}
     * @deprecated
     */
  getTotalCutCosts () {
    if (this.tracks) {
      const tracks = this.tracks.filter(track => track.length < this.max.length)
      return (tracks.length * this.price.cut).toFixed(2)
    }
    return 0.00
  }

  /**
     * Returns the linear length of all tracks
     * @returns {string|string|number}
     */
  getLinearLengthWithOversize () {
    if (this.tracks && this.tracks.length > 0) {
      const reducer = (accumulator, currentValue) => {
        if (accumulator instanceof TurfTrack) {
          return Number(accumulator.length) + Number(accumulator.oversize) + Number(currentValue.length) + Number(currentValue.oversize)
        } else {
          return Number(accumulator) + Number(currentValue.length) + Number(currentValue.oversize)
        }
      }

      return (this.tracks.length > 1) ? this.tracks.reduce(reducer).toFixed(2) : Number(this.tracks[0].length + this.tracks[0].oversize).toFixed(2)
    }

    return 0.00
  }

  /**
     * Returns the linear length of all tracks
     * @returns {string|string|number}
     */
  getLinearLength () {
    if (this.tracks && this.tracks.length > 0) {
      const reducer = (accumulator, currentValue) => {
        if (accumulator instanceof TurfTrack) {
          return Number(accumulator.length) + Number(currentValue.length)
        } else {
          return Number(accumulator) + Number(currentValue.length)
        }
      }

      return (this.tracks.length > 1) ? this.tracks.reduce(reducer).toFixed(2) : Number(this.tracks[0].length).toFixed(2)
    }

    return 0.00
  }
}

/**
 * @class TurfTrack
 */
class TurfTrack {
  constructor (name) {
    this.className = 'TurfTrack'
    this.name = name
    this.oversize = 0
    this.width = 0
    this.length = 0
    this.points = []
    this.usedWaste = false
    this.wasteData = null
    this.sourceTargetFor = []
  }

  setUsedWaste (used) {
    this.usedWaste = used
  }

  setWasteFrom (data) {
    this.wasteData = data
  }

  removeWaste () {
    this.usedWaste = false
    this.wasteData = null
  }

  getName () {
    return this.name
  }

  setOversize (oversize) {
    this.oversize = oversize
  }

  getOversize () {
    return this.oversize
  }

  setWidth (width) {
    this.width = width
  }

  getWidth () {
    return this.width
  }

  setLength (length) {
    this.length = length
  }

  getLength () {
    return this.length
  }

  setPoints (points) {
    this.points = points
  }

  getPoints () {
    return this.points
  }

  /**
     * Returns track informations
     * @returns {{oversize: number, name: *, width: number, length: number}}
     */
  getInformation () {
    return {
      name: this.name,
      width: this.width,
      length: this.length,
      oversize: this.oversize
    }
  }

  getPathData () {
    return this.points.map(point => {
      return (point.type !== 'Z') ? `${point.type} ${point.x},${point.y}` : point.type
    }).join(' ')
  }
}

class Subbase {
  constructor (id, name, type, version, overlay_id, thickness = 0) {
    this.className = 'Subbase'
    this.id = id
    this.version_nr = version
    this.overlay_id = overlay_id
    this.name = name
    this.type = type
    this.thickness = thickness
    this.amount = null
    this.uuid = uuidv4()
  }

  /**
     *
     * @param id
     */
  setId (id) {
    this.id = id
  }

  /**
     * Returns id
     * @returns {*}
     */
  getId () {
    return this.id
  }

  /**
     *
     * @param name
     */
  setName (name) {
    this.name = name
  }

  /**
     * Returns name
     * @returns {*}
     */
  getName () {
    return this.name
  }

  /**
     *
     * @param thickness
     */
  setThickness (thickness) {
    this.thickness = thickness
  }

  /**
     * Returns thickness
     * @returns {*}
     */
  getThickness () {
    return this.thickness
  }
}

class Infill {
  constructor (id, name, version, overlay_id, weight = 0) {
    this.className = 'Infill'
    this.id = id
    this.overlay_id = overlay_id
    this.name = name
    this.weight = weight
    this.version_nr = version
    this.uuid = uuidv4()
  }

  /**
     *
     * @param id
     */
  setId (id) {
    this.id = id
  }

  /**
     * Returns id
     * @returns {*}
     */
  getId () {
    return this.id
  }

  /**
     *
     * @param name
     */
  setName (name) {
    this.name = name
  }

  /**
     * Returns name
     * @returns {*}
     */
  getName () {
    return this.name
  }

  /**
     *
     * @param weight
     */
  setWeight (weight) {
    this.weight = weight
  }

  /**
     * Returns weight
     * @returns {*}
     */
  getWeight () {
    return this.weight
  }
}

class Seamtape {
  constructor (id, name, version, overlay_id) {
    this.className = 'Seamtape'
    this.id = id
    this.name = name
    this.version_nr = version
    this.overlay_id = overlay_id
    this.amount = 0
    this.price = 0
    this.uuid = uuidv4()
  }
}

class Adhesive {
  constructor (id, name, version, overlay_id) {
    this.className = 'Adhesive'
    this.id = id
    this.name = name
    this.version_nr = version
    this.overlay_id = overlay_id
    this.amount = 0
    this.price = 0
    this.uuid = uuidv4()
  }
}

export { Turf, TurfTrack, Subbase, Infill, Seamtape, Adhesive }
