/**
 * @displayName DomJS
 */
export default {
  name: 'DomJSMixin',
  methods: {
    /**
     * Gets DOM nodes by class 'object__line' (lines, paths) and returns an array with objects {length:LENGTH, center:CENTRAL_POINT}
     * @param id
     * @param {String} groupPrefix
     * @returns {Array}
     * @public
     */
    mx_dom_getLinesFromObject (id, groupPrefix = 'group') {
      const res = []; const els = document.getElementById(groupPrefix + '-' + id).getElementsByClassName('c-object--calc')
      for (let i = 0; i < els.length; i++) {
        const item = els[i]; let l; let center
        if (typeof item.getTotalLength !== 'function' || typeof item.getPointAtLength !== 'function') {
          const dx = item.getAttribute('x1') - item.getAttribute('x2')
          const dy = item.getAttribute('y1') - item.getAttribute('y2')
          l = this.$_mx_dom_calcLength(dx, dy)
          center = this.$_mx_dom_calcCenterFromItem(item, l, dx, dy)
        } else {
          l = item.getTotalLength()
          center = item.getPointAtLength(l / 2)
        }
        res.push({
          length: l,
          center: center
        })
      }
      return res
    },
    /**
     * Helper to calc length if "getTotalLength" is not implemented in the browser's SVG model
     * @param dx
     * @param dy
     * @returns {number}
     * @private
     */
    $_mx_dom_calcLength (dx, dy) {
      return Math.sqrt(Math.pow(dx, 2) +
                Math.pow(dy, 2))
    },
    /**
     * Helper to calc center if "getPointAtLength" is not implemented in the browser's SVG model
     * @param svgItem
     * @param l
     * @param dx
     * @param dy
     * @returns {{x: number, y: number}}
     * @private
     */
    $_mx_dom_calcCenterFromItem (svgItem, l, dx, dy) {
      const theta = Math.atan2(dy, dx)
      const xp = (l / 2) * Math.cos(theta)
      const yp = (l / 2) * Math.sin(theta)
      return { x: svgItem.getAttribute('x1') - xp, y: svgItem.getAttribute('y1') - yp }
    }
  }
}
