<template>
  <svg
    id="cut-draw-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="u-fill-w"
      cx="6.28"
      cy="5.86"
      r="5"
    />
    <circle
      class="u-fill-w"
      cx="6.2"
      cy="34.14"
      r="5"
    />
    <circle
      class="u-fill-w"
      cx="33.8"
      cy="5.86"
      r="5"
    />
    <circle
      class="u-fill-w"
      cx="33.72"
      cy="34.14"
      r="5"
    />
    <path
      class="u-fill-w"
      d="M33.88,7.86H31.2a2,2,0,1,1,0-4h2.68a2,2,0,1,1,0,4Zm-11.68,0h-5a2,2,0,1,1,0-4h5a2,2,0,1,1,0,4Zm-16,0a2,2,0,1,1,2-2A2,2,0,0,1,6.2,7.86Z"
    />
    <path
      class="u-fill-w"
      d="M34.15,36.14H31.47a2,2,0,0,1,0-4h2.68a2,2,0,0,1,0,4Zm-11.68,0h-5a2,2,0,1,1,0-4h5a2,2,0,0,1,0,4Zm-16,0a2,2,0,1,1,2-2A2,2,0,0,1,6.47,36.14Z"
    />
    <path
      class="u-fill-w"
      d="M6.2,36.14a2,2,0,0,1-2-2V31.46a2,2,0,0,1,4,0v2.68A2,2,0,0,1,6.2,36.14Zm0-11.68a2,2,0,0,1-2-2v-5a2,2,0,0,1,4,0v5A2,2,0,0,1,6.2,24.46Zm0-16a2,2,0,1,1,2-2A2,2,0,0,1,6.2,8.46Z"
    />
    <path
      class="u-fill-w"
      d="M32.34,33.88A2,2,0,0,1,30.69,33l-2.81-4.13a2,2,0,0,1,.53-2.78,2.05,2.05,0,0,1,2.78.53L34,30.75a2,2,0,0,1-1.66,3.13ZM24.47,22.3a2,2,0,0,1-1.12-3.65,2,2,0,0,1,2.78.53A2,2,0,0,1,25.6,22,2.05,2.05,0,0,1,24.47,22.3Z"
    />
    <circle
      class="u-fill-b1"
      cx="24.79"
      cy="20"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'CutDrawIcon'
}
</script>
