import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'
import _ from 'lodash'

/**
 * Default state for: Location Store
 *
 * @returns {{locations: []}}
 */
const defaultState = () => {
  return {
    locations: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all locations
     *
     * @param state
     * @returns {[]}
     */
  getAll (state) {
    return _.sortBy(state.locations, ['name'])
  },
  /**
     *
     * @param state
     * @returns {Object|null}
     */
  getById: (state) => (id) => {
    if (state.locations.length > 0) {
      const location = state.locations.find(location => location.id === id)
      return (location !== undefined) ? location : null
    }

    return null
  },
  /**
     * Has locations
     *
     * @param state
     * @returns {boolean}
     */
  hasLocations (state) {
    return (state.locations.length > 0)
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_LOCATION_STATE)
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async read ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.location, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.LOCATION_SET_LOCATIONS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listSimple ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', `${routes.location}/simple`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.LOCATION_SET_LOCATIONS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{name:String,groupId:Number|null}} location
     * @returns {Promise<void>}
     */
  async create ({ rootState, dispatch, commit }, location) {
    if (location.groupId === null) {
      location.groupId = rootState.backendAPI.user.me.group.id
    }

    await apiMethods.request('post', routes.location, location, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.LOCATION_ADD_LOCATION, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update a location
     * Roles: Distributor
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} location
     * @returns {Promise<boolean>}
     */
  async update ({ rootState, dispatch, commit }, location) {
    return await apiMethods.request('patch', routes.location + `/${location.id}`, {
      name: location.name,
      address: {
        street: location.address.street,
        city: location.address.city,
        zip: location.address.zip,
        state: location.address.state,
        country: location.address.country
      },
      currency_short: location.currency_short
    }, rootState.backendAPI.login.token).then(response => {
      commit(types.LOCATION_UPDATE_LOCATION, response.data.data)
      return true
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
      return false
    })
  },
  /**
   * Delete a location
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {Number} locationId
   */
  async delete ({ rootState, dispatch, commit }, locationId) {
    await apiMethods.request('delete', routes.location + `/${locationId}`, null, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.LOCATION_DELETE_LOCATION, locationId)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_LOCATION_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.LOCATION_SET_LOCATIONS] (state, locations) {
    state.locations = locations
  },
  [types.LOCATION_ADD_LOCATION] (state, location) {
    state.locations.push(location)
  },
  [types.LOCATION_UPDATE_LOCATION] (state, location) {
    const l = state.locations.find(l => l.id === location.id)

    if (l !== undefined) {
      apiMethods.updateStoreObjectProperties(l, location)
    }
  },
  [types.LOCATION_DELETE_LOCATION] (state, locationId) {
    state.locations = state.locations.filter(location => location.id !== locationId)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
