<template>
  <svg
    id="draw-rect-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M5.53,36.55a2,2,0,0,1-2-2V5.45a2,2,0,0,1,4,0v29.1A2,2,0,0,1,5.53,36.55Z"
    />
    <path
      class="u-fill-w"
      d="M34.63,36.55a2,2,0,0,1-2-2V5.45a2,2,0,0,1,4,0v29.1A2,2,0,0,1,34.63,36.55Z"
    />
    <path
      class="u-fill-w"
      d="M34.63,7.45H5.53a2,2,0,0,1,0-4h29.1a2,2,0,0,1,0,4Z"
    />
    <path
      class="u-fill-w"
      d="M34.63,36.55H5.53a2,2,0,0,1,0-4h29.1a2,2,0,1,1,0,4Z"
    />
    <circle
      class="u-fill-b1"
      cx="5.7"
      cy="34.37"
      r="5"
    />
    <circle
      class="u-fill-b1"
      cx="34.45"
      cy="5.7"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'DrawRectIcon'
}
</script>
