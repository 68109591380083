<template>
  <div class="c-checkout__content-container">
    <div class="u-pt-size-standard u-mb-size-20">
      <h4 class="c-typo__h4">
        {{ $t('dialogs.overview.additionalCosts.header') }}
      </h4>
    </div>
    <div class="c-checkout__products-container">
      <additional-costs
        @update="$emit('additionalCostsUpdate', $event)"
      />
    </div>
  </div>
</template>

<script>
import AdditionalCosts from '@/components/modules/costs/AdditionalCosts'

export default {
  name: 'CheckoutAdditional',
  components: {
    AdditionalCosts
  }
}
</script>
