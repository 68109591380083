import { mapGetters, mapState } from 'vuex'
import { productTypes } from '@/helper/api/types'
import { get_needed_length } from '@/helper/seamtapeCalculation'
import MixinMath from '@/mixins/math'

/**
 * Calculate Costs for:
 * - Product
 * - Infill
 * - Subbase (Other, Pad, Pedestal)
 * - Seamtape
 * - Adhesvie
 * @displayName Costs
 */
export default {
  name: 'CostsMixin',
  mixins: [
    MixinMath
  ],
  data: () => {
    return {
      cache: {
        tracks: new Map()
      }
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    }),
    ...mapGetters({
      getProductById: 'products/getProductById',
      getInfillById: 'products/getInfillById',
      getOtherById: 'products/getSubbaseOtherById',
      getPadById: 'products/getSubbasePadById',
      getPedestalById: 'products/getSubbasePedestalById',
      getSeamtapeById: 'products/getSeamtapeById',
      getAdhesiveById: 'products/getAdhesiveById'
    })
  },
  methods: {
    /**
     * Calculate product turf cost
     *
     * @param turf
     * @returns {{total: {formatted: String, value: number}, cut: {formatted: String, value: number}, each: {formatted: String, value: number}}}
     * @public
     */
    mx_costs_turf (turf) {
      const product = this.getProductById(turf.id)

      let total = 0
      let each = 0
      let cut = 0
      let area = 0

      if (turf.tracks !== null && turf.tracks !== undefined) {
        turf.tracks.forEach(track => {
          if (track.usedWaste) {
            return
          }
          const length = Math.ceil(Number(track.length))
          let trackArea = Math.ceil(Number(product.overlay.roll_width)) * length

          trackArea += Math.ceil(Number(track.oversize)) * Math.ceil(Number(product.overlay.roll_width))

          if (length <= product.overlay.price_switch_each_cut) {
            const priceCut = trackArea * product.overlay.price_cut
            total += priceCut
            cut += priceCut
          } else {
            const priceEach = trackArea * product.overlay.price_each
            total += priceEach
            each += priceEach
          }
          area += trackArea
        })
      }

      return {
        cut: {
          formatted: this.$world.currencyConvert(cut, true),
          value: cut
        },
        each: {
          formatted: this.$world.currencyConvert(each, true),
          value: each
        },
        total: {
          formatted: this.$world.currencyConvert(total, true),
          value: total,
          area: area
        }
      }
    },
    /**
     * Calculate total subbases costs
     *
     * @param {[]} subbases
     * @param {number} area
     * @returns {{total: {number: number, value: String}, pads: {number: number, value: String}, pedestals: {number: number, value: String}, others: {number: number, value: String}}}
     * @public
     */
    mx_costs_total_subbases (subbases, area) {
      const others = subbases.filter(s => s.type === productTypes.PRODUCT_OTHER_SUBBASE_TYPE)
      const pads = subbases.filter(s => s.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE)
      const pedestals = subbases.filter(s => s.type === productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE)

      const otherCosts = this.mx_costs_total_others(others, area)
      const padCosts = this.mx_costs_total_pads(pads)
      const pedestalCosts = this.mx_costs_total_pedestals(pedestals)

      const total = (otherCosts.total + padCosts.total + pedestalCosts.total)

      return {
        others: {
          formatted: this.$world.currencyConvert(otherCosts.total, true),
          value: otherCosts.total,
          each: otherCosts.each
        },
        pads: {
          formatted: this.$world.currencyConvert(padCosts.total, true),
          value: padCosts.total,
          each: padCosts.each
        },
        pedestals: {
          formatted: this.$world.currencyConvert(pedestalCosts.total, true),
          value: pedestalCosts.total,
          each: pedestalCosts.each
        },
        total: {
          formatted: this.$world.currencyConvert(total, true),
          value: total
        }
      }
    },
    /**
     * Calculate total others (subbase) costs
     *
     * @param {[]} others
     * @param {number} area
     * @returns {number}
     * @public
     */
    mx_costs_total_others (others, area) {
      let total = 0
      const each = []

      others.forEach(other => {
        const costData = this.mx_costs_other(other, area)

        total += costData.value
        each.push(costData)
      })

      return { total: total, each: each }
    },
    /**
     * Calculate total other (subbase) cost
     * @param other
     * @param area
     * @returns {{value: number, value: String}}
     * @public
     */
    mx_costs_other (other, area) {
      let cost = 0
      const item = this.getOtherById(other.id)

      const thickness = this.$_convert_unit(other.thickness, this.$settings.subbaseUnits.otherThickness)

      cost = (Math.ceil(Number(thickness) * Number(area))) * item.overlay.price_each

      return {
        id: other.id,
        uuid: other.uuid,
        formatted: this.$world.currencyConvert(cost, true),
        value: cost,
        thickness: other.thickness,
        volume: Math.ceil(Number(thickness) * Number(area))
      }
    },
    /**
     * Calculate total pads costs
     *
     * @param {[]} pads
     * @returns {Number}
     * @public
     */
    mx_costs_total_pads (pads) {
      let total = 0
      const each = []

      pads.forEach(pad => {
        const costData = this.mx_costs_pad(pad)

        total += costData.value
        each.push(costData)
      })

      return { total: total, each: each }
    },
    /**
     * Calculate pad costs
     *
     * @param pad
     * @returns {{value: number, value: String}}
     * @public
     */
    mx_costs_pad (pad) {
      let cost = 0
      const item = this.getPadById(pad.id)

      if (item !== undefined && item.overlay.price_each !== null) {
        cost = Number(pad.amount) * Number(item.overlay.price_each)
      }

      return {
        id: pad.id,
        uuid: pad.uuid,
        formatted: this.$world.currencyConvert(cost, true),
        value: cost,
        amount: Number(pad.amount)
      }
    },
    /**
     * Calculate total pedestals costs
     *
     * @param {[]} pedestals
     * @returns {Number}
     * @public
     */
    mx_costs_total_pedestals (pedestals) {
      let total = 0
      const each = []

      pedestals.forEach(pedestal => {
        const costData = this.mx_costs_pedestal(pedestal)
        total += costData.value
        each.push(costData)
      })

      return { total: total, each: each }
    },
    /**
     * Calculate pedestal cost
     *
     * @param pedestal
     * @returns {{value: number, value: String}}
     * @public
     */
    mx_costs_pedestal (pedestal) {
      let cost = 0
      const item = this.getPedestalById(pedestal.id)

      if (item !== undefined && item.overlay.price_each !== null) {
        cost = Number(pedestal.amount) * Number(item.overlay.price_each)
      }

      return {
        id: pedestal.id,
        uuid: pedestal.uuid,
        formatted: this.$world.currencyConvert(cost, true),
        value: cost,
        amount: Number(pedestal.amount)
      }
    },
    /**
     * Calculate total infills costs and weight
     *
     * @param infills
     * @param area
     * @returns {{total: number, totalWeight: number}}
     * @public
     */
    mx_costs_total_infills (infills, area) {
      let total = 0
      let totalWeight = 0
      const each = []

      infills.forEach(infill => {
        const costData = this.mx_costs_infill(infill, area)
        total += costData.value
        totalWeight += costData.weight
        each.push(costData)
      })

      return {
        total: {
          formatted: this.$world.currencyConvert(total, true),
          value: total
        },
        totalWeight: totalWeight,
        each: each
      }
    },
    /**
     * Calculate infill cost
     *
     * @param infill
     * @param area
     * @returns {{value: number, weight: number, packages: number, value: string}}
     * @public
     */
    mx_costs_infill (infill, area) {
      const item = this.getInfillById(infill.id)

      if (item !== null) {
        const needed = Number(area) * Number(infill.weight)

        const packages = Math.ceil(needed / Number(item.overlay.infill_weight_packaging))

        const cost = Number(item.overlay.price_each) * packages

        return {
          id: infill.id,
          uuid: infill.uuid,
          formatted: this.$world.currencyConvert(cost, true),
          value: cost,
          packages: packages,
          weight: packages * Number(item.overlay.infill_weight_packaging),
          recommendedWeight: infill.weight
        }
      }

      return {
        id: infill.id,
        uuid: infill.uuid,
        formatted: 0,
        value: 0,
        packages: 0,
        weight: 0,
        recommendedWeight: 0
      }
    },
    /**
     *
     * @param seamtape
     * @param length
     * @returns {{formatted: string, rolls: number, value: number}}
     * @deprecated
     */
    mx_costs_seamtape_old (seamtape, length, tracks) {
      if (tracks !== null && tracks.length > 1) {
        const item = this.getSeamtapeById(seamtape.id)

        if (item !== undefined) {
          const neededRolls = Math.ceil(Number(length) / Number(item.seamtape_length.value))
          const cost = neededRolls * Number(item.price.each)

          return {
            rolls: neededRolls,
            formatted: this.$world.currencyConvert(cost, true),
            value: cost
          }
        }
      }

      return {
        rolls: 0,
        formatted: this.$world.currencyConvert(0, true),
        value: 0
      }
    },
    /**
     * Calculate adhesive cost
     *
     * @param adhesive
     * @param area
     * @returns {{formatted: string, buckets: number, value: number}}
     * @public
     */
    mx_costs_adhesive (adhesive, area) {
      const item = (Object.prototype.hasOwnProperty.call(adhesive, 'original')) ? adhesive : this.getAdhesiveById(adhesive.id)

      if (item !== undefined) {
        const neededBuckets = Math.ceil(Number(area) / Number(item.overlay.adhesive_coverage))
        const volume = neededBuckets * Number(item.overlay.adhesive_size)
        const cost = neededBuckets * Number(item.overlay.price_each)

        return {
          adhesiveId: item.original.id,
          uuid: item.uuid,
          volume: volume,
          buckets: neededBuckets,
          formatted: this.$world.currencyConvert(cost, true),
          value: cost
        }
      }

      return {
        buckets: 0,
        formatted: this.$world.currencyConvert(0, true),
        value: 0
      }
    },
    mx_costs_seamtape (seamtape, object) {
      if (object.product && object.product.tracks && object.product.tracks.length > 1) {
        const length = get_needed_length(object, object.product.tracks,
          object.product.angle, object.product.alignment, this.measure)
        const item = (Object.prototype.hasOwnProperty.call(seamtape, 'original')) ? seamtape : this.getSeamtapeById(seamtape.id)

        if (item !== undefined) {
          const neededRolls = Math.ceil(Number(length) / Number(item.overlay.seamtape_length))
          const cost = neededRolls * Number(item.overlay.price_each)

          return {
            tapeId: item.original.id,
            uuid: item.uuid,
            length: parseFloat(length).toFixed(2),
            rolls: neededRolls,
            formatted: this.$world.currencyConvert(cost, true),
            value: cost
          }
        }
      }

      return {
        length: 0,
        rolls: 0,
        formatted: this.$world.currencyConvert(0, true),
        value: 0
      }
    },
    $_convert_unit (value, unit) {
      const factor = {
        in: 12,
        mm: 1000,
        cm: 100
      }

      return (Object.prototype.hasOwnProperty.call(factor, unit)) ? value / factor[unit] : 0
    }
  }
}
