import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'
import config from '@/config'

import MixinOperations from '@/mixins/operations'

/**
 * Provides object events
 * @displayName Object Events
 */
export default {
  name: 'EventActionsObjectMixin',
  data: () => {
    return {}
  },
  mixins: [
    MixinOperations
  ],
  computed: {
    ...mapState({
      mx_eventActions_object_selectedObjects: state => state.events.currentSelectedObjects,
      /** @private **/
      $_mx_eventActions_object_scale: state => state.editor.viewbox.scale,
      /** @private **/
      $_mx_eventActions_object_translate: state => state.project.object.translate
    }),
    ...mapGetters({
      /** @private **/
      $_mx_eventActions_object_getObject: 'project/objects/getById'
    })
  },
  methods: {
    ...mapActions({
      /** @private **/
      $_mx_eventActions_object_translateObjects: 'project/translateObjects',
      /** @private **/
      $_mx_eventActions_addObjectToSelection: 'events/addObjectToSelection'
    }),
    ...mapMutations({
      /** @private **/
      $_mx_eventActions_object_setBooleanOperations: 'events/' + mutationTypes.EVT_UPDATE_BOOLEAN_OPERATIONS,
      /** @private **/
      $_mx_eventActions_object_calculateTranslate: 'project/' + mutationTypes.TRANSLATE_COORDINATES_FOR_OBJECTS
    }),
    /**
         * Init the object events like dragging, selection, stop drag
         * @public
         */
    mx_eventActions_object_objectEvents () {
      this.$events.on(editorEventTypes.OBJECT_SELECTED, (payload) => {
        this.$_mx_eventActions_addObjectToSelection(payload)
      })
      this.$events.on(editorEventTypes.OBJECT_START_DRAGGING, (payload) => {
        this.$_mx_eventActions_object_calculateTranslate({
          x: payload.x / this.$_mx_eventActions_object_scale,
          y: payload.y / this.$_mx_eventActions_object_scale
        })
        this.mx_trackstate_beginStateTransaction()
      })
      this.$events.on(editorEventTypes.OBJECT_STOP_DRAGGING, (objectIDs) => {
        // translate all objects only if translate !== 0
        if (this.$_mx_eventActions_object_translate.x !== 0 || this.$_mx_eventActions_object_translate.y !== 0) {
          this.$_mx_eventActions_object_translateObjects(objectIDs)
        }
        this.mx_trackstate_endStateTransaction()
      })
    },
    /**
         * Set allowed operations if objects selected
         * @requires @/mixins/operations
         * @param selectedObjects
         * @private
         */
    $_mx_eventActions_object_updateBooleanOperations (selectedObjects) {
      if (selectedObjects !== null && selectedObjects.length === 2 && !selectedObjects.includes(config.OBJECT_CUT_ID)) {
        const a = this.$_mx_eventActions_object_getObject(selectedObjects[0])
        const b = this.$_mx_eventActions_object_getObject(selectedObjects[1])
        const sortedObjects = this.mx_operations_sortByOrder(a, b)
        const paperObjectA = this.$paper.compoundPath(this.$_mx_eventActions_object_getPath(sortedObjects.first))
        const paperObjectB = this.$paper.compoundPath(this.$_mx_eventActions_object_getPath(sortedObjects.second))

        const intersect = paperObjectA.intersect(paperObjectB)

        this.$_mx_eventActions_object_setBooleanOperations({
          unite: this.$_mx_eventActions_object_uniteAllowed(paperObjectA, paperObjectB, intersect),
          subtract: this.$_mx_eventActions_object_excludeSubtractAllowed(paperObjectA, paperObjectB, intersect),
          exclude: this.$_mx_eventActions_object_excludeSubtractAllowed(paperObjectA, paperObjectB, intersect),
          fragment: this.$_mx_eventActions_object_fragmentAllowed(paperObjectA, paperObjectB, intersect)
        })
      } else {
        this.$_mx_eventActions_object_setBooleanOperations({
          unite: false,
          subtract: false,
          exclude: false,
          fragment: false
        })
      }
    },
    /**
         * Get the object path
         * @param obj
         * @returns {string|*}
         * @private
         */
    $_mx_eventActions_object_getPath (obj) {
      if (obj.type === config.objects.types.PATH) {
        return obj.d()
      } else if (obj.type === config.objects.types.CIRCLE) {
        const data = obj.getCircleProperties()
        const circle = this.$paper.circle(data.middle, data.radius)
        const circlePath = circle.toPath()

        return circlePath.pathData
      }
    },
    /**
         * Check if logical "unite" is allowed
         * @param a
         * @param b
         * @param i
         * @returns {boolean}
         * @private
         */
    $_mx_eventActions_object_uniteAllowed (a, b, i) {
      return !a.intersect(b).compare(a) && a.getCrossings(b).length > 0
    },
    /**
         * Check if logical "subtract" and "exclude" are allowed
         * @param a
         * @param b
         * @param i
         * @returns {boolean}
         * @private
         */
    $_mx_eventActions_object_excludeSubtractAllowed (a, b, i) {
      return i.area > 0
    },
    /**
         * Check if logical "fragment" is allowed
         * @param a
         * @param b
         * @param i
         * @returns {*|boolean}
         * @private
         */
    $_mx_eventActions_object_fragmentAllowed (a, b, i) {
      return this.$_mx_eventActions_object_uniteAllowed(a, b, i) && i.area > 0
    }
  },
  watch: {
    mx_eventActions_object_selectedObjects: {
      handler: function (n, o) {
        if (n) {
          this.$_mx_eventActions_object_updateBooleanOperations(n)
        }
      },
      deep: true
    }
  }
}
