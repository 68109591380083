<template>
  <svg
    id="operations-subtract-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M26.14,4.2v9H16.39a3.21,3.21,0,0,0-3.2,3.21v9.75h-9A3.19,3.19,0,0,1,1,23V4.2A3.19,3.19,0,0,1,4.2,1H23A3.19,3.19,0,0,1,26.14,4.2Z"
    />
    <path
      class="u-fill-w"
      d="M35.35,39.08H16.19a3.75,3.75,0,0,1-3.75-3.74V16.18a3.74,3.74,0,0,1,3.75-3.74H35.35a3.74,3.74,0,0,1,3.74,3.74V35.34A3.75,3.75,0,0,1,35.35,39.08ZM16.19,13.94a2.24,2.24,0,0,0-2.25,2.24V35.34a2.24,2.24,0,0,0,2.25,2.24H35.35a2.24,2.24,0,0,0,2.24-2.24V16.18a2.24,2.24,0,0,0-2.24-2.24Z"
    />
    <path
      class="u-fill-w"
      d="M34.27,25.66a1.66,1.66,0,0,1-1.67,1.65H18.72a1.65,1.65,0,1,1,0-3.3H32.6A1.65,1.65,0,0,1,34.27,25.66Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OperationsSubtractIcon'
}
</script>
