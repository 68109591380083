<template>
  <dialog-frame
    :targetDialog="targetDialog"
    :title=" $t('dialogs.importMaterial.title',{type: $t(`backend.materials.menu.byType.${materialType}`)}) "
    :subtitle=" $t('dialogs.importMaterial.subtitle') "
  >
    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center u-pb-size-0">
        <form class="c-form">
          <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
            <div class="c-dialog__instruction-col  u-text-left">
              <file-upload
                :id="`import-csv-type-${materialType}`"
                :accept="'text/csv'"
                :label-default-message="$t('dialogs.importMaterial.dropCsv')"
                :file-max-size="null"
                :multiple="false"
                style-drop-area-height="180px"
                style-drop-area-width="180px"
                @change="file"
              />
            </div>

            <div class="c-dialog__instruction-col u-text-right u-pt-size-standard">
              <p>{{ $t('dialogs.importMaterial.info') }}</p>
              <div class="u-flex u-jc-end u-mt-size-standard">
                <button-base
                  :id="`import-csv-instructions`"
                  :label="$t('dialogs.importMaterial.button')"
                  :on-click-action="() => { openHelp() }"
                  classes="u-mr-size-0"
                  icon-classes="far fa-file-pdf c-icon"
                  style-size="s"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child c-dialog__confirm-child--flex-end u-ml-size-standard u-mt-size-standard">
          <button-base
            :id="`import-csv-${materialType}`"
            :disabled="csv === null"
            :label="$t('dialogs.importMaterial.title',{type: $t(`backend.materials.menu.byType.${materialType}`)})"
            :on-click-action="() => { doImport() }"
            classes="u-ml-size-0"
            icon-classes="fa fa-download c-icon"
            style-size="s"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MixinDialog from '@/mixins/dialogs'
import DialogFrame from '@/components/common/DialogsFrame'
import FileUpload from '@/components/common/FileUpload'
import ButtonBase from '@/components/common/ButtonBase'
import config from '@/config'

export default {
  name: 'ImportMaterialDialog',
  components: {
    DialogFrame,
    FileUpload,
    ButtonBase
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'importMaterial',
      csv: null
    }
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'language/get',
      getDialogProps: 'dialogs/getDialogProps'
    }),
    materialType () {
      const dialogProps = this.getDialogProps(this.targetDialog)
      return Object.prototype.hasOwnProperty.call(dialogProps, 'materialType') ? dialogProps.materialType : ''
    }
  },
  methods: {
    ...mapActions({
      updateMaterial: 'backendAPI/csv/updateByCsvAndType'
    }),
    file (payload) {
      if (payload.value.newFiles && payload.value.newFiles.length === 1) {
        this.csv = payload.value.newFiles[0]
      } else {
        this.csv = null
      }
    },
    async doImport () {
      await this.updateMaterial({
        file: this.csv,
        type: this.materialType
      })
      this.mx_dialogs_closeDialog()
    },
    openHelp () {
      window.open(`${config.frontend.baseUrl}downloads/${config.downloads.csvImport[this.currentLanguage].path}`, '_blank')
    }
  }
}
</script>
