import { mapActions } from 'vuex'

/**
 * Helper for editor history
 * Provides actions to track states
 * @displayName Trackstate
 */
export default {
  name: 'TrackstateMixin',
  methods: {
    ...mapActions({
      /**
       * @method
       */
      mx_trackstate_beginStateTransaction: 'history/beginStateTransaction',
      /**
       * @method
       */
      mx_trackstate_endStateTransaction: 'history/endStateTransaction',
      /**
       * @method
       */
      mx_trackstate_breakStateTransaction: 'history/breakStateTransaction',
      /**
       * @method
       */
      mx_trackstate_trackState: 'history/trackState',
      /**
       * @method
       */
      mx_trackstate_trackStateWithCondition: 'history/trackStateWithCondition'
    })
  }
}
