import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Admin Store
 *
 * @returns {{admins: []}}
 */
const defaultState = () => {
  return {
    admins: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all admins
     *
     * @returns {[]}
     */
  getAll (state) {
    return state.admins
  },
  /**
     * Has admins
     *
     * @param state
     * @returns {boolean}
     */
  hasAdmins (state) {
    return (state.admins.length > 0)
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_ADMIN_STATE)
  },
  /**
     * Update an admin
     *
     * @param commit
     * @param {Object} admin
     */
  update ({ commit }, admin) {
    commit(types.ADMIN_UPDATE_ADMIN, admin)
  },
  /**
     * Delete an admin
     *
     * @param commit
     * @param {Object} admin
     */
  delete ({ commit }, admin) {
    commit(types.ADMIN_DELETE_ADMIN, admin)
  },
  /**
     * Set admin list
     * Roles: Admin
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.admin, null, rootState.backendAPI.login.token)
      .then(response => {
        const filtered = response.data.data.filter(admin => admin.id !== rootState.backendAPI.user.me.id)
        commit(types.ADMIN_SET_ADMIN, filtered)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create an admin
     * Roles: Admin
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} admin
     * @returns {Promise<void>}
     */
  async create ({ rootState, dispatch, commit }, admin) {
    await apiMethods.request('post', routes.admin, admin, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.ADMIN_ADD_ADMIN, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_ADMIN_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.ADMIN_SET_ADMIN] (state, admins) {
    state.admins = admins.map(admin => {
      admin.created_at = apiMethods.convertDate(admin.created_at)
      admin.updated_at = apiMethods.convertDate(admin.updated_at)

      return admin
    })
  },
  [types.ADMIN_ADD_ADMIN] (state, admin) {
    admin.created_at = apiMethods.convertDate(admin.created_at)
    admin.updated_at = apiMethods.convertDate(admin.updated_at)
    state.admins.push(admin)
  },
  [types.ADMIN_DELETE_ADMIN] (state, adminId) {
    state.admins = state.admins.filter(admin => admin.id !== adminId)
  },
  [types.ADMIN_UPDATE_ADMIN] (state, admin) {
    const a = state.admins.find(a => a.id === admin.id)

    if (a !== undefined) {
      admin.created_at = apiMethods.convertDate(admin.created_at)
      admin.updated_at = apiMethods.convertDate(admin.updated_at)
      apiMethods.updateStoreObjectProperties(a, admin)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
