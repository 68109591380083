import _ from 'lodash'
const DEFAULT_LANGUAGE = 'en'

export default {
  data: () => {
    return {
      currentLang: 'en'
    }
  },
  mounted () {
    this.currentLang = this.$i18n.locale
  },
  methods: {
    mx_product_in_language (product, keys, lang = null) {
      const thisLang = (lang) ?? this.currentLang
      product = (Object.prototype.hasOwnProperty.call(product, 'original')) ? product.original : product
      if (thisLang === DEFAULT_LANGUAGE) {
        return this.walkThroughProduct(product, keys)
      }

      const productInLanguage = this.walkThroughProduct(product, ['translations', thisLang, ...keys])

      if (productInLanguage !== null) {
        return productInLanguage
      }

      const productInDefaultLanguage = this.walkThroughProduct(product, keys)

      return productInDefaultLanguage ?? ''
    },
    walkThroughProduct (product, keys, lang = null) {
      let temp = _.cloneDeep(product)

      keys.forEach((key) => {
        temp = temp[key]
      })

      return temp
    }
  },
  watch: {
    '$i18n.locale': {
      handler (nLang, oLang) {
        this.currentLang = nLang
      },
      deep: true
    }
  }
}
