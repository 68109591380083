/**
 *  START: EDITOR
 */
export const EDITOR_SCALE_IN = 'EDITOR_SCALE_IN'
export const EDITOR_SCALE_OUT = 'EDITOR_SCALE_OUT'
export const EDITOR_SCALE_RESET = 'EDITOR_SCALE_RESET'
export const EDITOR_KEYDOWN = 'EDITOR_KEYDOWN'
export const EDITOR_KEYUP = 'EDITOR_KEYUP'
export const EDITOR_MOUSEDOWN = 'EDITOR_MOUSEDOWN'
export const EDITOR_MOUSEUP = 'EDITOR_MOUSEUP'
export const EDITOR_MOUSEMOVE = 'EDITOR_MOUSEMOVE'
export const EDITOR_WHEEL = 'EDITOR_WHEEL'
export const EDITOR_CHANGE_CTRL_MODE = 'EDITOR_CHANGE_CTRL_MODE'
/**
 *  END: EDITOR
 */

/**
 *  START: DIALOG
 */
export const DIALOG_ESC_PRESSED = 'DIALOG_ESC_PRESSED'
/**
 *  END: DIALOG
 */

/**
 *  START: VIEWBOX
 */
export const VIEWBOX_MOVE_BY_MOUSE = 'VIEWBOX_MOVE_BY_MOUSE'
export const VIEWBOX_MOVE_BY_KEY = 'VIEWBOX_MOVE_BY_KEY'
export const VIEWBOX_UPDATED = 'VIEWBOX_UPDATED'
/**
 *  END: VIEWBOX
 */

/**
 *  START: PROJECT
 */
export const PROJECT_REMOVE_SELECTED_OBJECTS = 'PROJECT_REMOVE_SELECTED_OBJECTS'
export const PROJECT_CLEAR_OBJECT_SELECTION = 'PROJECT_CLEAR_OBJECT_SELECTION'
export const PROJECT_SAVE = 'PROJECT_SAVE'
export const PROJECT_UNDO = 'PROJECT_UNDO'
export const PROJECT_REDO = 'PROJECT_REDO'
export const PROJECT_TOGGLE_UNDERLAY = 'PROJECT_TOGGLE_UNDERLAY'
export const PROJECT_DELETE_TEMPORARY_UNDERLAY_PREVIEW = 'PROJECT_DELETE_TEMPORARY_UNDERLAY_PREVIEW'
export const PROJECT_DELETE_UNDERLAY = 'PROJECT_DELETE_UNDERLAY'
/**
 *  END: PROJECT
 */

/**
 *  START: GRID
 */
export const GRID_SNAP_SWITCHED = 'GRID_SNAP_SWITCHED'
export const GRID_SHOW_SWITCHED = 'GRID_SHOW_SWITCHED'
/**
 *  END: GRID
 */

/**
 *  START: UNDERLAY
 */
export const UNDERLAY_TOGGLE = 'UNDERLAY_TOGGLE'
export const UNDERLAY_DELETE = 'UNDERLAY_DELETE'
/**
 *  END: UNDERLAY
 */

/**
 *  START: OBJECT
 */
export const OBJECT_DUPLICATE = 'OBJECT_DUPLICATE'
export const OBJECT_MODIFIER_MOUSEDOWN = 'OBJECT_MODIFIER_MOUSEDOWN'
export const OBJECT_MODIFIER_DRAG = 'OBJECT_MODIFIER_DRAG'
export const OBJECT_HANDLE_MODIFIER_MOUSEDOWN = 'OBJECT_HANDLE_MODIFIER_MOUSEDOWN'
export const OBJECT_HANDLE_MODIFIER_DRAG = 'OBJECT_HANDLE_MODIFIER_DRAG'
export const OBJECT_MODIFIER_OPEN_MENU = 'OBJECT_MODIFIER_OPEN_MENU'
export const OBJECT_MOVE_BY_MOUSE = 'OBJECT_MOVE_BY_MOUSE'
export const OBJECT_SELECTED = 'OBJECT_SELECTED'
export const OBJECT_START_DRAGGING = 'OBJECT_START_DRAGGING'
export const OBJECT_STOP_DRAGGING = 'OBJECT_STOP_DRAGGING'
export const OBJECT_ROTATE = 'OBJECT_ROTATE'
export const OBJECT_ESCAPE_PRESSED = 'OBJECT_ESCAPE_PRESSED'
export const OBJECT_SNAP_ON = 'OBJECT_SNAP_ON'
export const OBJECT_SNAP_OFF = 'OBJECT_SNAP_OFF'
/**
 *  END: OBJECT
 */

/**
 *  START: REFERENCE LINE
 */
export const REFERENCE_LINE_CREATE = 'REFERENCE_LINE_CREATE'
export const REFERENCE_LINE_ADD_POINT = 'REFERENCE_LINE_ADD_POINT'
export const REFERENCE_LINE_UPDATE_MOUSE = 'REFERENCE_LINE_UPDATE_MOUSE'
export const REFERENCE_LINE_MODIFY_START_POINT = 'REFERENCE_LINE_MODIFY_START_POINT'
export const REFERENCE_LINE_MODIFY_END_POINT = 'REFERENCE_LINE_MODIFY_END_POINT'
export const REFERENCE_LINE_INTERRUPT = 'REFERENCE_LINE_INTERRUPT'
/**
 *  END: REFERENCE LINE
 */

/**
 *  START: RULER
 */
export const RULER_CREATE = 'RULER_CREATE'
export const RULER_ADD_POINT = 'RULER_ADD_POINT'
export const RULER_UPDATE_MOUSE = 'RULER_UPDATE_MOUSE'
export const RULER_MODIFY_START_POINT = 'RULER_MODIFY_START_POINT'
export const RULER_MODIFY_END_POINT = 'RULER_MODIFY_END_POINT'
/**
 *  END: RULER
 */

/**
 *  START: LINE OF SIGHT
 */
export const LINE_OF_SIGHT_CALCULATE = 'LINE_OF_SIGHT_CALCULATE'
export const LINE_OF_SIGHT_SHOW_PSEUDO_ANGLE_FOR_TRACKS = 'LINE_OF_SIGHT_SHOW_PSEUDO_ANGLE_FOR_TRACKS'
export const LINE_OF_SIGHT_SHOW_PSEUDO_SHIFT_FOR_TRACKS = 'LINE_OF_SIGHT_SHOW_PSEUDO_SHIFT_FOR_TRACKS'
export const LINE_OF_SIGHT_ACTIVATE_SELECT_OUTLINE_MODE = 'LINE_OF_SIGHT_ACTIVATE_SELECT_OUTLINE_MODE'
export const LINE_OF_SIGHT_OUTLINE_SELECTED = 'LINE_OF_SIGHT_OUTLINE_SELECTED'
export const LINE_OF_SIGHT_CUT_MOVED = 'LINE_OF_SIGHT_CUT_MOVED'
export const LINE_OF_SIGHT_CREATE_NEW_GHOST_SHIFT = 'LINE_OF_SIGHT_CREATE_NEW_GHOST_SHIFT'
export const LINE_OF_SIGHT_CUT_MOVED_START = 'LINE_OF_SIGHT_CUT_MOVED_START'
export const LINE_OF_SIGHT_CUT_MOVED_STOP = 'LINE_OF_SIGHT_CUT_MOVED_STOP'
export const LINE_OF_SIGHT_CUT_CLEAR_WORKING_DATA = 'LINE_OF_SIGHT_CUT_CLEAR_WORKING_DATA'
export const LINE_OF_SIGHT_CHANGE_OBJECT = 'LINE_OF_SIGHT_CHANGE_OBJECT'
/**
 *  END: LINE OF SIGHT
 */

/**
 *  START: DRAW
 */
export const DRAW_START = 'DRAW_START'
export const DRAW_ADD_POINT_TO_PATH = 'DRAW_ADD_POINT_TO_PATH'
export const DRAW_ADD_POINT_TO_DEFAULT_PATH = 'DRAW_ADD_POINT_TO_DEFAULT_PATH'
export const DRAW_UPDATE_PSEUDO_MOUSE = 'DRAW_UPDATE_PSEUDO_MOUSE'
export const DRAW_ESCAPE_PRESSED = 'DRAW_ESCAPE_PRESSED'
export const DRAW_ENTER_PRESSED = 'DRAW_ENTER_PRESSED'
/**
 *  END: DRAW
 */

/**
 *  START: DRAW DEFAULT
 */
export const DRAW_DEFAULT_OBJECT = 'DRAW_DEFAULT_OBJECT'
export const DRAW_DEFAULT_UPDATE_PSEUDO_MOUSE = 'DRAW_UPDATE_PSEUDO_MOUSE'
export const DRAW_DEFAULT_ENTER_PRESSED = 'DRAW_DEFAULT_ENTER_PRESSED'
export const DRAW_DEFAULT_ESCAPE_PRESSED = 'DRAW_DEFAULT_ESCAPE_PRESSED'
export const DRAW_DEFAULT_FINISH_RECTANGLE = 'DRAW_DEFAULT_FINISH_RECTANGLE'
export const DRAW_DEFAULT_FINISH_TRIANGLE = 'DRAW_DEFAULT_FINISH_TRIANGLE'
export const DRAW_DEFAULT_FINISH_CIRCLE = 'DRAW_DEFAULT_FINISH_CIRCLE'
/**
 *  END: DRAW DEFAULT
 */

/**
 *  START: DRAW DEFAULT CUT
 */
export const DRAW_DEFAULT_CUT_HIT_START = 'DRAW_DEFAULT_CUT_HIT_START'
/**
 *  END: DRAW DEFAULT CUT
 */

/**
 *  START: PRODUCT
 */
export const PRODUCT_START_DRAGGING = 'PRODUCT_START_DRAGGING'
export const PRODUCT_STOP_DRAGGING = 'PRODUCT_STOP_DRAGGING'
export const PRODUCT_DRAG_OVER_OBJECT = 'PRODUCT_DRAG_OVER_OBJECT'
export const PRODUCT_DRAG_LEAVE_OBJECT = 'PRODUCT_DRAG_LEAVE_OBJECT'
export const PRODUCT_DRAG_ENTER_OBJECT = 'PRODUCT_DRAG_ENTER_OBJECT'
export const PRODUCT_SHOW_PAGE = 'PRODUCT_SHOW_PAGE'
/**
 *  END: PRODUCT
 */

/**
 *  START: TOOLBAR
 */
export const TOOLBAR_RESET_SUBMENU_CONTAINERS = 'TOOLBAR_RESET_SUBMENU_CONTAINERS'
/**
 *  END: TOOLBAR
 */
