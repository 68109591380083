import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from '@/i18n/locale/en.json'
import de from '@/i18n/locale/de.json'
import es from '@/i18n/locale/es.json'
import fr from '@/i18n/locale/fr.json'

Vue.use(VueI18n)

const locale = 'en'

const messages = {
  en, de, es, fr
}

const i18n = new VueI18n({
  locale,
  messages
})

export default i18n
