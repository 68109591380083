<template>
  <div>
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :totalRows="totalRecords"
      :pagination-options="{
        mode: 'pages',
        enabled: true,
        perPage: 20,
        perPageDropdown: [20, 50, 100, 200, 500],
        dropdownAllowAll: false,
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: '',
        allLabel: texts.allLabel,
      }"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        trigger: 'enter',
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'firstname', type: 'asc'}]
      }"
      theme="melos"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearch"
    >
      <template #emptystate>
        <div class="u-m-size-40 c-backend__nothing-to-show">
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open" />
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template
        #table-row="props"
      >
        <span v-if="props.column.field ==='salutation'">
          {{ mx_languages_getCurrentSalutation(props.formattedRow[props.column.field]) }}
        </span>

        <span v-if="props.column.field ==='group.name'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='firstname'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='lastname'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='email'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.phone'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.name'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.street'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.city'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.zip'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.state'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.country'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <!-- Buttons -->
        <span
          v-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <button-base
            :id="`client-list-update-client-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'updateClient', clientData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-edit"
          >
            <template #popup>
              {{ $t('common.edit') }}
            </template>
          </button-base>
          <button-base
            :id="`client-list-delete-client-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'deleteClient', clientData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters, mapActions } from 'vuex'
import AsyncVueGoodTableHelper from '@/mixins/AsyncVueGoodTableHelper'
import { roles, routes } from '@/helper/apiHelper'

import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinLanguages from '@/mixins/languages'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

/**
 * @displayName Client List Component
 */
export default {
  name: 'ClientList',
  components: {
    Icon,
    ButtonBase,
    VueGoodTable
  },
  mixins: [MixinDialogHandler, AsyncVueGoodTableHelper, MixinLanguages],
  computed: {
    ...mapGetters({
      getMyRole: 'backendAPI/user/getRole'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    },
    isDistributor () {
      return this.getMyRole === roles.distributor
    },
    columns () {
      return [
        {
          field: 'settings',
          sortable: false,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.salutation,
          field: 'salutation',
          thClass: 'vgt-no-min-width'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.name,
          field: 'firstname'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.surname,
          field: 'lastname'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.email,
          field: 'email'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.phone,
          field: 'address.phone'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.name,
          field: 'address.name'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.street,
          field: 'address.street'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.city,
          field: 'address.city'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.zip,
          field: 'address.zip'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.state,
          field: 'address.state'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.address.country,
          field: 'address.country'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.clients.tableLabel.location,
          field: 'group.name',
          hidden: !this.isDistributor
        }
      ]
    }
  },
  mounted () {
    this.updateParams({
      sort: {
        type: 'asc',
        field: 'firstname'
      }
    })
    this.$events.on('NEW_CLIENT_CREATED', this.updateList)
    this.$events.on('ASYNCLIST_CLIENT_UPDATED', this.loadItems)
  },
  beforeDestroy () {
    this.$events.off('NEW_CLIENT_CREATED', this.updateList)
    this.$events.off('ASYNCLIST_CLIENT_UPDATED', this.loadItems)
  },
  methods: {
    ...mapActions({
      initLocations: 'backendAPI/location/read'
    }),
    async openDialog (payload) {
      if (payload.type === 'updateClient') {
        await this.initLocations()
      }
      this.$_mx_dialogHandler_openDialog(
        { dialog: payload.type, onOff: true, dialogProps: { clientData: payload.clientData } })
    },
    loadItems () {
      this.doRequest(routes.client, this.getToken)
    },
    updateList () {
      this.updateParams({
        columnFilters: {},
        searchstring: '',
        sort: {
          field: '',
          type: ''
        },
        page: 1,
        perPage: 20
      })
      this.loadItems()
    }
  }
}
</script>
