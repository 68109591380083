<template>
  <div
    :id="id + '-context-menu'"
    class="c-context-menu"
    :style="{top:top - 45 + 'px',left:left+'px'}"
  >
    <div class="c-context-menu__container">
      <div class="c-context-menu__header">
        <div class="c-context-menu__title c-typo__label">
          {{ title }}
        </div>

        <button-icon
          id="context-menu-close"
          classes="c-context-menu__close"
          icon-classes="fas fa-2x fa-times"
          style-color="darker"
          style-font-size="xs"
          @click="closeContextMenu"
        />
      </div>
      <div class="c-context-menu__inner">
        <!--
            @slot Default slot for content (mainly buttons and submenus)
        -->
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import ButtonIcon from '@/components/common/ButtonIcon'

/**
 * The ContextMenu Component.
 * @displayName Context Menu
 */

export default {
  name: 'ContextMenuFrame',
  components: { ButtonIcon },
  props: {
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Title label
     */
    title: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      space: 15
    }
  },
  computed: {
    ...mapState({
      contextMenu: state => state.events.contextMenu
    }),
    top () {
      return this.contextMenu && this.contextMenu.position ? this.contextMenu.position.y : 0
    },
    left () {
      return this.contextMenu && this.contextMenu.position ? this.contextMenu.position.x + this.space : 0
    }
  },
  methods: {
    ...mapMutations({
      closeContextMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU
    })
  }
}
</script>
