// import * as types from './../mutation-types'

const state = {
  svg: {
    circleRadius: 5,
    lineStrokeWidth: 1,
    line: {
      dashLength: 10,
      dashSpace: 8
    },
    circle: {
      strokeWidth: 1.5
    },
    snapCircle: {
      inner: 1,
      outer: 10,
      innerStrokeWidth: 1,
      outerStrokeWidth: 2
    },
    text: {
      textStroke: 3,
      angleTextSize: 14,
      areaTextSize: 20,
      lengthTextSize: 14,
      angleSpace: {
        x: 0,
        y: 22
      },
      superscript: {
        area: 16
      },
      lengthSpace: {
        x: 0,
        y: -5
      }
    },
    bBox: {
      space: 10
    },
    pattern: {
      cut: {
        width: 50,
        height: 1,
        rotation: 45,
        rect1: {
          x: 0,
          y: 0,
          width: 20,
          height: 1
        },
        rect2: {
          x: 20,
          y: 0,
          width: 30,
          height: 1
        }
      },
      lineOfSight: {
        line: {
          stroke: 'white',
          strokeWidth: 1,
          dashLength: 3,
          dashSpace: 10
        },
        circle: {
          stroke: 'white',
          strokeWidth: 2,
          strokeLinecap: 'round'
        }
      }
    }
  },
  grid: {
    min: 0.1,
    steps: 0.1,
    max: 3.0
  },
  submenuScales: [
    8,
    4,
    2,
    1.5,
    1,
    0.75,
    0.5,
    0.25,
    0.1]
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
