<template>
  <div class="c-frame__item">
    <drop-down
      id="select-infills-substructures"
      v-model="selected"
      :current-item-allowed-signs="20"
      :items="items"
      :no-item-label="noItems"
      :style-up="false"
      label-key="name"
      @change="change"
      @input="change"
    />

    <slot name="properties" />
    <button-base
      id="select-infills-substructures-add"
      :disabled="selected === null || disabled"
      :label="buttonText"
      classes="u-mr-size-0"
      @click="$emit('addSelectedItem')"
    />
  </div>
</template>

<script>
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * Provides a default drop-down
     * Use: InfillsPanel.vue, Substructures.vue
     * @displayName LineOfSight Panel - Item Drop-down
     */
export default {
  name: 'LosSelectInfillsSubstructures',
  components: {
    ButtonBase,
    DropDown
  },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    noItems: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      selected: null
    }
  },
  methods: {
    change (payload) {
      if (payload) {
        this.$emit('changedSelectedItem', { id: payload.id, type: payload.type })
      }
    }
  }
}
</script>
