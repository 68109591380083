<template>
  <backend>
    <template #dialogs>
      <update-profile />
    </template>

    <template #content>
      <div
        v-if="me"
        class="c-backend-settings"
      >
        <div class="o-grid o-grid--center u-mt-size-20">
          <div class="o-grid__col u-10/12">
            <button-base
              id="profile-update-profile"
              :label="$t('backend.profile.edit')"
              :on-click-action="() => { return updateProfile() }"
              classes="u-mt-size-40 u-ml-size-0"
              style-size="s"
            />
          </div>
        </div>

        <div class="o-grid o-grid--center u-mt-size-30 c-backend-settings">
          <div class="o-grid__col u-10/12">
            <div class="o-grid">
              <div class="o-grid__col u-5/12">
                <table>
                  <tr
                    v-for="(field, index) in fields"
                    :key="index"
                    class="u-align-top"
                  >
                    <th>{{ $t(`backend.profile.${field}`) }}</th>
                    <td v-if="field === 'salutation'">
                      <p v-html="mx_languages_getCurrentSalutation(me[field])" />
                    </td>
                    <td v-else>
                      <p v-html="me[field]" />
                    </td>
                  </tr>
                </table>
              </div>

              <div class="o-grid__col u-4/12">
                <!-- Profile picture-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </backend>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinLanguages from '@/mixins/languages'

import Backend from '@/views/Backend'
import UpdateProfile from '@/components/backend/profile/dialogs/UpdateProfile'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Profile Component
     */
export default {
  name: 'Profile',
  components: {
    ButtonBase,
    Backend,
    UpdateProfile
  },
  mixins: [
    MixinDialogHandler, MixinLanguages
  ],
  data: () => {
    return {
      fields: [
        'email',
        'salutation',
        'firstname',
        'lastname',
        'phone'
      ]
    }
  },
  computed: {
    ...mapGetters({
      me: 'backendAPI/user/get'
    })
  },
  methods: {
    ...mapActions({
      createEmployee: 'backendAPI/employee/create'
    }),
    async updateProfile () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'updateProfile',
        onOff: true
      })
    }
  }
}
</script>
