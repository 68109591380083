<template>
  <dialog-frame
    :key="`templates-${getTemplates.length}-${Date.now()}`"
    :targetDialog="targetDialog"
    :title=" $t('dialogs.templates.title') "
    :subtitle=" $t('dialogs.templates.subtitle') "
    style-dialog-height="600px"
    style-dialog-width="680px"
  >
    <template
      #content
    >
      <div
        v-if="loaded && hasTemplates && hasTemplatesDataMap"
        class="c-templates c-templates--card-list"
      >
        <div class="c-templates__filter">
          <input-field
            id="templates-filter-search"
            v-model="filter.search"
            :is-store-mode="false"
            :label="$t('dialogs.templates.form.filter.search')"
            style-input-width="240px"
            type="text"
          />
          <input-checkbox
            id="templates-filter-my"
            v-model="filter.showOnlyMyTemplates"
            :is-store-mode="false"
            :label="$t('dialogs.templates.form.filter.showOnlyMyTemplates')"
            style-component="1"
          />
        </div>
        <template-card
          v-for="(template,index) in templates"
          :key="`myTemplateCard-${index}-${Date.now()}`"
          :template="template"
          :isMy="isMyTemplate(template.created_by.id)"
          @templateImported="mx_dialogs_closeDialog"
        />
      </div>
      <div
        v-else
        class="c-templates__no-template u-pt-size-60"
      >
        <i class="fas fa-5x fa-pencil-ruler c-icon u-pt-size-100 u-pb-size-20" />
        <h3 class="c-typo__h3">
          {{ $t('dialogs.templates.noTemplate') }}
        </h3>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'

import MixinDialog from '@/mixins/dialogs'

import InputCheckbox from '@/components/common/InputCheckbox'
import InputField from '@/components/common/InputField'

import DialogFrame from '@/components/common/DialogsFrame'
import TemplateCard from '@/components/editor/dialogs/partials/Template'

export default {
  name: 'TemplatesDialog',
  components: {
    DialogFrame,
    TemplateCard,
    InputCheckbox,
    InputField
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'templates',
      loaded: false,
      filter: {
        showOnlyMyTemplates: false,
        search: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      hasTemplates: 'template/has',
      getTemplates: 'template/get',
      hasTemplatesDataMap: 'template/hasDataMap',
      me: 'backendAPI/user/get'
    }),
    myTemplates () {
      return this.getTemplates.filter(template => template.created_by.id === this.me.id)
    },
    otherTemplates () {
      return this.getTemplates.filter(template => template.created_by.id !== this.me.id)
    },
    templates () {
      let templates = _.cloneDeep(this.getTemplates)

      if (templates.length > 0) {
        templates.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at)
        })

        templates = _.sortBy(templates, (t) => t.created_by.id !== this.me.id)

        if (this.filter.showOnlyMyTemplates && templates.length > 0) {
          templates = templates.filter(template => template.created_by.id === this.me.id)
        }

        if (this.filter.search !== '' && templates.length > 0) {
          templates = templates.filter(template => {
            const name = (template.name && template.name !== '') ? template.name.toLowerCase() : ''
            return name.includes(this.filter.search.toLowerCase())
          })
        }
      }

      return templates
    }
  },
  async mounted () {
    this.loaded = await this.loadTemplates()
  },
  beforeDestroy () {
    this.clearTemplates()
  },
  methods: {
    ...mapActions({
      loadTemplates: 'template/load',
      clearTemplates: 'template/clear'
    }),
    isMyTemplate (createdById) {
      return createdById === this.me.id
    }
  }
}
</script>

<style lang="scss">
.templates {
  &--list {
    width: 100%;
    flex-direction: column!important;
  }
  &__card {
    display: flex;
    width: 100%;
    flex-direction: row;
    &__template {
      width: 30%;
      svg {
        width: 100%;
        height: auto;
      }
    }
    &__info {
      width: 70%;
    }
  }
}
</style>
