import Vue from 'vue'
import Router from 'vue-router'
import store from '@/vuex/store'
import { apiMethods, roles } from '@/helper/apiHelper'
/**
 * START: Import Router Views
 */
import Login from '@/views/Login'

import Profile from '@/components/backend/profile/Profile'

import GroupSettings from '@/components/backend/groups/settings/GroupSettings'
import Groups from '@/components/backend/groups/Groups'
import UserManagement from '@/components/backend/userManagement/UserManagement'
import Distributors from '@/components/backend/distributors/Distributors'

import Employees from '@/components/backend/employees/Employees'
import Locations from '@/components/backend/locations/Locations'
import LocationMaterials from '@/components/backend/locations/materials/LocationMaterials'
import Materials from '@/components/backend/materials/Materials'

import Clients from '@/components/backend/clients/Clients'
import Projects from '@/components/backend/projects/Projects'
import ApiClients from '@/components/backend/apiClients/ApiClients'

import GroupStats from '@/components/backend/stats/GroupStats'

import editor from '@/views/Editor'

/**
 * END: Import Router Views
 */

/**
 * START: Test Components
 */
import UnderlayUpload from '@/components/editor/underlay/UnderlayUpload'

/**
 * END: Test Components
 */

Vue.use(Router)

const testRoutesHash = '664b0d61c325469fb22d87577cbfa6ed'

const routerObj = new Router({
  routes: [
    {
      path: `/${testRoutesHash}/underlayupload`,
      name: 'UnderlayUploadTEST',
      component: UnderlayUpload
    },
    {
      path: '/',
      name: 'Login',
      component: Login
    },

    /* ------------------------------------------
     *  All
     *------------------------------------------ */

    {
      path: '/backend/profile',
      name: 'Profile',
      component: Profile,
      meta: {
        allowed: [roles.admin, roles.manufacture, roles.distributor, roles.employee]
      },
      beforeEnter: (to, from, next) => {
        next()
      }
    },

    /* ------------------------------------------
     *  Admin
     *------------------------------------------ */

    {
      path: '/backend/user-management',
      redirect: { name: 'UserManagement', params: { cat: 'manufacturers' } }
    },
    {
      path: '/backend/user-management/:cat(.*)',
      name: 'UserManagement',
      component: UserManagement,
      meta: {
        allowed: [roles.admin]
      },
      props: (route) => ({ cat: route.params.cat }),
      beforeEnter: async (to, from, next) => {
        if (!store.getters['backendAPI/group/hasGroups']) {
          await store.dispatch('backendAPI/group/list')
        }
        if (!store.getters['backendAPI/manufacture/hasManufactures']) {
          await store.dispatch('backendAPI/manufacture/list')
        }
        if (!store.getters['backendAPI/admin/hasAdmins']) {
          await store.dispatch('backendAPI/admin/list')
        }
        next()
      }
    },

    /* ------------------------------------------
     *  Admin, Manufacture
     *------------------------------------------ */

    {
      path: '/backend/groups',
      name: 'Groups',
      component: Groups,
      meta: {
        allowed: [roles.admin, roles.manufacture]
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('backendAPI/group/list')
        next()
      }
    },
    {
      path: '/backend/stats',
      name: 'GroupStats',
      component: GroupStats,
      meta: {
        allowed: [roles.admin, roles.manufacture]
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('backendAPI/stats/listGroupStats')
        next()
      }
    },

    /* ------------------------------------------
     *  Manufacture
     *------------------------------------------ */

    {
      path: '/backend/company-settings',
      name: 'Company',
      component: GroupSettings,
      meta: {
        allowed: [roles.manufacture]
      }
    },
    {
      path: '/backend/distributors',
      name: 'Distributors',
      component: Distributors,
      meta: {
        allowed: [roles.manufacture]
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('backendAPI/group/list')
        await store.dispatch('backendAPI/distributor/list')
        next()
      }
    },

    /* ------------------------------------------
     *  Manufacture, Distributor
     *------------------------------------------ */

    {
      path: '/backend/employees',
      name: 'Employees',
      component: Employees,
      meta: {
        allowed: [/* roles.manufacture, */roles.distributor]
      },
      beforeEnter: async (to, from, next) => {
        if (store.getters['backendAPI/user/getRole'] === roles.distributor) {
          await store.dispatch('backendAPI/location/read')
          await store.dispatch('backendAPI/employee/list')
        } else if (store.getters['backendAPI/user/getRole'] === roles.manufacture) {
          await store.dispatch('backendAPI/employee/listAll')
        }
        next()
      }
    },
    {
      path: '/backend/materials',
      redirect: () => {
        return (store.getters['backendAPI/user/getRole'] === roles.manufacture)
          ? { name: 'Materials', params: { cat: 'products', sub: 'turf' } }
          : { name: 'Materials', params: { cat: 'subbases', sub: 'pads' } }
      }
    },
    {
      path: '/backend/materials/:cat/:sub(.*)',
      name: 'Materials',
      component: Materials,
      meta: {
        allowed: [roles.manufacture, roles.distributor]
      },
      props: (route) => ({ cat: route.params.cat, sub: route.params.sub }),
      beforeEnter: async (to, from, next) => {
        await store.dispatch('backendAPI/product/subProductHandler/listSpecial')
        next()
      }
    },

    /* ------------------------------------------
     *  Distributor
     *------------------------------------------ */

    {
      path: '/backend/locations',
      name: 'Locations',
      component: Locations,
      meta: {
        allowed: [roles.distributor]
      },
      beforeEnter: async (to, from, next) => {
        await store.dispatch('backendAPI/location/read')
        next()
      }
    },
    {
      path: '/backend/api-clients',
      name: 'ApiClients',
      component: ApiClients,
      meta: {
        allowed: [roles.distributor]
      },
      beforeEnter: async (to, from, next) => {
        next()
      }
    },
    {
      path: '/backend/locations/:id/:cat/:sub(.*)',
      name: 'Location-products',
      component: LocationMaterials,
      meta: {
        allowed: [roles.distributor]
      },
      props: (route) => ({ id: parseInt(route.params.id), cat: route.params.cat, sub: route.params.sub }),
      beforeEnter: async (to, from, next) => {
        const id = parseInt(to.params.id)

        if (!store.getters['backendAPI/location/hasLocations']) {
          await store.dispatch('backendAPI/location/read')
        }
        if (store.getters['backendAPI/location/getById'](id)) {
          const location = store.getters['backendAPI/location/getById'](id)
          Vue.world.initCurrency(location.currency_short)
          await store.dispatch('backendAPI/product/setLocationId', id)
          next()
        } else {
          next({ name: 'Locations' })
        }
        next()
      }
    },

    /* ------------------------------------------
     *  Distributor, Employee
     *------------------------------------------ */

    {
      path: '/backend/clients',
      name: 'Clients',
      component: Clients,
      meta: {
        allowed: [roles.distributor, roles.employee]
      }
    },
    {
      path: '/backend/projects',
      name: 'Projects',
      component: Projects,
      meta: {
        allowed: [roles.distributor, roles.employee]
      }
    },
    {
      path: '/editor',
      name: 'Melos-Editor',
      component: editor,
      meta: {
        allowed: [roles.distributor, roles.employee]
      },
      beforeEnter: (to, from, next) => {
        next()
      }
    },
    {
      path: '*',
      redirect: '/backend/projects'
    }
  ]
})

// global auth guard
routerObj.beforeEach(async (to, from, next) => {
  if (store.getters['backendAPI/loggedIn'] && !store.getters['backendAPI/user/hasStateSet']) {
    await store.dispatch('backendAPI/user/me')
  }

  const role = store.getters['backendAPI/user/getRole']

  if (to.name !== 'Login' && !store.getters['backendAPI/loggedIn'] && !to.name.includes('TEST')) {
    next({ name: 'Login' })
  } else if (to.name === 'Login' && store.getters['backendAPI/loggedIn']) {
    next({ name: apiMethods.getDefaultRoute(role) })
  } else if (store.getters['backendAPI/loggedIn']) {
    if (to.meta.allowed) {
      if (apiMethods.isRouteAllowed(role, to.meta.allowed)) {
        next()
      } else {
        next({ name: apiMethods.getDefaultRoute(role) })
      }
    } else {
      next()
    }
  } else {
    next()
  }
})

export default routerObj
