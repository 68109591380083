<template>
  <div
    class="c-templates__card"
  >
    <div class="c-templates__card-template">
      <div class="c-templates__card-actions">
        <button-icon
          v-if="isMy"
          id="button-delete-template"
          :disabled="disabledHide"
          classes="c-templates__card-action"
          icon-classes="fas fa-2x fa-trash"
          style-font-size="xxs"
          style-color="darker"
          @click="removeMyTemplate(template.id)"
        />
        <button-icon
          v-if="isMy"
          id="button-edit-template"
          :disabled="disabledHide"
          classes="c-templates__card-action"
          icon-classes="fas fa-2x fa-edit"
          style-font-size="xxs"
          style-color="darker"
          @click="editMyTemplate(template)"
        />
        <button-icon
          id="button-import-template"
          :disabled="disabledHide"
          classes="c-templates__card-action"
          icon-classes="fas fa-2x fa-file-download"
          style-font-size="xxs"
          style-color="darker"
          @click="importTemplate(template)"
        />
      </div>
      <div
        v-if="svg"
        :key="`svg-template-${Date.now()}`"
        class="c-templates__card-preview"
        v-html="svg"
      />
    </div>
    <div class="c-templates__card-info">
      <div v-if="!disabledHide">
        <table class="u-text-left">
          <tr class="u-align-top">
            <th>{{ $t('dialogs.templates.import.name') }}</th>
            <td class="u-pl-size-standard">
              {{ template.name }}
            </td>
          </tr>
          <tr class="u-align-top">
            <th>{{ $t('dialogs.templates.import.description') }}</th>
            <td class="u-pl-size-standard">
              {{ template.description }}
            </td>
          </tr>
          <tr
            v-if="isMy"
            class="u-align-top"
          >
            <th>{{ $t('dialogs.templates.import.public') }}</th>
            <td class="u-pl-size-standard">
              {{ template.public ? $t('common.yes') : $t('common.no') }}
            </td>
          </tr>
          <tr class="u-align-top">
            <th>{{ $t('dialogs.templates.import.dimension') }}</th>
            <td class="u-pl-size-standard">
              {{ width }} x {{ height }} {{ $settings.lengthUnit }}
            </td>
          </tr>
          <tr class="u-align-top u-color-b">
            <th><small>{{ $t('dialogs.templates.import.createdBy') }}</small></th>
            <td class="u-pl-size-standard">
              <small>{{ createdBy }}</small>
            </td>
          </tr>
        </table>
      </div>
      <!--   Edit mode form   -->
      <div v-if="editMode">
        <input-field
          :id="`template-edit-name-${template.id}`"
          v-model="form.name"
          :is-store-mode="false"
          :label="$t('dialogs.templates.form.name')"
          :required="true"
          :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
          classes="u-mt-size-0"
          style-input-width="240px"
          type="text"
          @change="mx_validate_updateErrorsArray"
          @register="mx_validate_registerInputsInErrorsArray"
        />
        <textarea-field
          :id="`template-edit-description-${template.id}`"
          v-model="form.description"
          :is-store-mode="false"
          :label="$t('dialogs.templates.form.description')"
          :required="true"
          :rows="2"
          :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
          style-textarea-width="240px"
          type="text"
          @change="mx_validate_updateErrorsArray"
          @register="mx_validate_registerInputsInErrorsArray"
        />
        <input-checkbox
          :id="`template-edit-public-${template.id}`"
          v-model="form.public"
          :is-store-mode="false"
          :label="$t('dialogs.templates.form.public')"
          style-component="1"
          @change="mx_validate_updateErrorsArray"
          @register="mx_validate_registerInputsInErrorsArray"
        />
        <div class="u-flex u-fd-row u-jc-end u-mt-size-standard">
          <button-base
            :id="`template-edit-cancel-${template.id}`"
            :label="$t('common.cancel')"
            :on-click-action="() => { return cancelEditMyTemplate() }"
          />
          <button-base
            :id="`template-edit-save-${template.id}`"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.save')"
            :on-click-action="() => { return updateMyTemplate() }"
            classes="u-mr-size-0"
          />
        </div>
      </div>
      <!--  Delete mode form  -->
      <div v-if="deleteMode">
        <p class="u-text-right">
          {{ $t('dialogs.templates.import.deleteTemplate') }}
        </p>
        <div class="u-flex u-fd-row u-jc-end u-mt-size-standard">
          <button-base
            :id="`template-delete-cancel-${template.id}`"
            :label="$t('common.no')"
            :on-click-action="() => { return cancelDeleteMyTemplate() }"
          />
          <button-base
            :id="`template-delete-delete-${template.id}`"
            :label="$t('common.yes')"
            :on-click-action="() => { return deleteMyTemplate() }"
            classes="u-mr-size-0"
          />
        </div>
      </div>
      <!--  Import mode form  -->
      <div v-if="importMode">
        <input-field
          :id="`import-template-height-${template.id}`"
          v-model="form.width"
          :is-store-mode="false"
          :label="$t('dialogs.templates.form.width',{unit:$settings.lengthUnit})"
          classes="u-mt-size-0"
          style-input-width="240px"
          step="0.01"
          type="number"
          @input="$_calculate_height"
        />
        <input-field
          :id="`import-template-height-${template.id}`"
          v-model="form.height"
          :is-store-mode="false"
          :label="$t('dialogs.templates.form.height',{unit:$settings.lengthUnit})"
          style-input-width="240px"
          step="0.01"
          type="number"
          @input="$_calculate_width"
        />
        <input-checkbox
          :id="`import-file-aspect-ratio-${template.id}`"
          v-model="aspectRatio"
          :is-store-mode="false"
          :label="$t('dialogs.templates.form.aspectRatio')"
          style-component="1"
        />
        <div class="u-flex u-fd-row u-jc-end u-mt-size-standard">
          <button-base
            :id="`template-delete-cancel-${template.id}`"
            :label="$t('common.cancel')"
            :on-click-action="() => { return cancelImportTemplate() }"
          />
          <button-base
            :id="`template-delete-delete-${template.id}`"
            :label="$t('dialogs.templates.form.doImport')"
            :on-click-action="() => { return doImportTemplate(template) }"
            classes="u-mr-size-0"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import PaperLayer from '@/classes/paperLayer'

import { mapState, mapActions, mapGetters } from 'vuex'

import MixinMath from '@/mixins/math'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinLanguages from '@/mixins/languages'
import ButtonIcon from '@/components/common/ButtonIcon'
import InputCheckbox from '@/components/common/InputCheckbox'
import ButtonBase from '@/components/common/ButtonBase'
import InputField from '@/components/common/InputField'
import TextareaField from '@/components/common/TextareaField'

export default {
  name: 'TemplateCard',
  components: {
    TextareaField,
    ButtonIcon,
    InputCheckbox,
    ButtonBase,
    InputField
  },
  mixins: [
    MixinMath,
    MixinDialog,
    MixinValidate,
    MixinLanguages
  ],
  props: {
    template: {
      type: Object,
      required: true
    },
    isMy: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      svg: null,
      group: null,
      deleteMode: false,
      editMode: false,
      importMode: false,
      form: null,
      aspectRatio: true,
      importProps: {
        ratio: { w: 0, h: 0 },
        w: 0,
        h: 0
      }
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    }),
    ...mapGetters({
      getTemplateDataMap: 'template/getDataMap'
    }),
    createdBy () {
      return `${this.mx_languages_getCurrentSalutation(this.template.created_by.salutation)} ${this.template.created_by.firstname} ${this.template.created_by.lastname}`
    },
    width () {
      return this.mx_math_roundDecimal(this.mx_math_getCl(this.template.width, this.measure))
    },
    height () {
      return this.mx_math_roundDecimal(this.mx_math_getCl(this.template.height, this.measure))
    },
    disabledHide () {
      return this.editMode || this.deleteMode || this.importMode
    }
  },
  mounted () {
    const dataMap = this.getTemplateDataMap(this.template.id)

    if (typeof dataMap !== 'undefined') {
      this.svg = dataMap.svg
      this.group = dataMap.group
    }
  },
  methods: {
    ...mapActions({
      updateTemplate: 'template/update',
      deleteTemplate: 'template/delete',
      operationActions: 'project/operationActions'
    }),
    removeMyTemplate (id) {
      this.form = id
      this.$_toggle_delete_mode()
    },
    deleteMyTemplate () {
      this.deleteTemplate(this.form)
      this.$_toggle_delete_mode()
      this.$_clear_form()
    },
    editMyTemplate (template) {
      this.form = _.cloneDeep(template)
      this.$_toggle_edit_mode()
    },
    updateMyTemplate () {
      this.updateTemplate(this.form)
      this.$_toggle_edit_mode()
      this.$_clear_form()
    },
    cancelEditMyTemplate () {
      this.$_toggle_edit_mode()
      this.$_clear_form()
    },
    cancelDeleteMyTemplate () {
      this.$_toggle_delete_mode()
    },
    cancelImportTemplate () {
      this.$_toggle_import_mode()
      this.$_clear_form()
    },
    importTemplate (template) {
      this.$_toggle_import_mode()
      this.form = _.cloneDeep(template)
      this.importProps.w = this.form.width
      this.importProps.h = this.form.height
      this.form.width = this.mx_math_roundDecimal(this.mx_math_getCl(this.form.width, this.measure))
      this.form.height = this.mx_math_roundDecimal(this.mx_math_getCl(this.form.height, this.measure))

      this.importProps.ratio.w = this.form.width / this.form.height
      this.importProps.ratio.h = this.form.height / this.form.width
    },
    doImportTemplate () {
      const w = this.mx_math_getPixelByLength(this.form.width, this.measure)
      const h = this.mx_math_getPixelByLength(this.form.height, this.measure)

      const scaleHorizontal = this.mx_math_roundDecimal(w / this.importProps.w)
      const scaleVertical = this.mx_math_roundDecimal(h / this.importProps.h)

      this.group.scale(scaleHorizontal, scaleVertical)

      this.group.clone().children.forEach(child => {
        const result = PaperLayer._transformPoints(child)

        for (const key in result) {
          const outcome = result[key]
          if (outcome) {
            let objectPoints = null
            let childPoints = []

            if (!outcome.some(item => typeof item.inside === 'boolean')) {
              objectPoints = outcome
            } else {
              const clockwise_object = outcome.filter(obj => !obj.inside)
              const counterclockwise_objects = outcome.filter(obj => obj.inside)
              const object_properties = clockwise_object.shift()

              objectPoints = object_properties.points
              childPoints = counterclockwise_objects
            }

            this.operationActions({
              points: objectPoints,
              children: childPoints,
              action: 'create-new-object-from-points',
              cutObjectMode: false
            })
          }
        }
      })

      this.$_toggle_import_mode()
      this.$_clear_form()
      this.$emit('templateImported')
    },
    $_calculate_width () {
      if (this.aspectRatio) {
        this.form.width = this.mx_math_roundDecimal(this.form.height * this.importProps.ratio.w)
      }
    },
    $_calculate_height () {
      if (this.aspectRatio) {
        this.form.height = this.mx_math_roundDecimal(this.form.width * this.importProps.ratio.h)
      }
    },
    $_clear_form () {
      this.form = null
      this.aspectRatio = true
      this.importProps = {
        ratio: { w: 0, h: 0 },
        w: 0,
        h: 0
      }
    },
    $_toggle_edit_mode () {
      this.editMode = !this.editMode
    },
    $_toggle_delete_mode () {
      this.deleteMode = !this.deleteMode
    },
    $_toggle_import_mode () {
      this.importMode = !this.importMode
    }
  }
}
</script>
