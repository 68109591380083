<template>
  <panel-container :title=" $t('editor.lineOfSight.product.shift.title') ">
    <input-slider
      id="product-range"
      v-model="range"
      :classes="['u-mb-size-standard', 'u-mt-size-standard']"
      :max="max"
      :min="min"
      :suffix="$settings.turfUnits.shift"
      step=".01"
      @mouseup="hideShift"
    />

    <div
      v-if="error"
      class="c-los__warning u-mt-size-standard"
      v-html="$t('editor.lineOfSight.product.shift.warning',{maxValue: max})"
    />
  </panel-container>
</template>

<script>
import { mapGetters } from 'vuex'

import PanelContainer from '@/components/common/PanelContainer'
import InputSlider from '@/components/common/InputSlider'

/**
 * Configure the offset to start the calculation
 * @displayName LineOfSight Panel - Product - Shift
 */
export default {
  name: 'ProductShift',
  components: {
    PanelContainer,
    InputSlider
  },
  data () {
    return {
      error: false
    }
  },
  computed: {
    ...mapGetters({
      getObject: 'lineOfSightNew/getObject',
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    range: {
      get () {
        return this.getObjectProduct.shift
      },
      set (payload) {
        this.error = payload.error
        if (!payload.error) {
          if (payload.el === 'slider') this.$events.fire('LINE_OF_SIGHT_SHOW_PSEUDO_TRACKS', { shift: Number(payload.value) })
          if (payload.el === 'input') this.$emit('changeProductShift', { action: 'changeProductShift', data: { shift: Number(payload.value) } })
        }
      }
    },
    min () {
      return 0
    },
    max () {
      return Number(this.getObjectProduct.width)
    }
  },
  methods: {
    hideShift (value) {
      this.$events.fire('LINE_OF_SIGHT_HIDE_PSEUDO_TRACKS')
      this.$emit('changeProductShift', { action: 'changeProductShift', data: { shift: Number(value) } })
    }
  }
}
</script>
