<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.closeProject.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.closeProject.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p>{{ $t('dialogs.closeProject.text_1') }}</p>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="close-project-no"
            :label="$t('common.no')"
            :on-click-action="() => { return closeProject() }"
            style-color="gray"
          />
          <button-base
            id="close-project-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return saveAndClose() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Close Project
     */
export default {
  name: 'CloseProject',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog,
    MixinDialogHandler
  ],
  data: () => {
    return {
      targetDialog: 'closeProject',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.closeProject,
      projectMeta: state => state.project.backendMeta
    })
  },
  methods: {
    ...mapActions({
      navCloseProject: 'navigation/closeProject',
      updateProject: 'backendAPI/project/updateState'
    }),
    ...mapMutations({}),
    async saveAndClose () {
      if (this.projectMeta.id) {
        await this.updateProject()
      } else {
        this.$_mx_dialogHandler_openDialog({ dialog: 'createProject', onOff: true })
      }
      await this.closeProject()
    },
    async closeProject () {
      await this.navCloseProject(this.dialogProps.route)
      await this.$router.push({ name: this.dialogProps.route })
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
