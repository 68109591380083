import * as types from '@/vuex/mutation-types'
import Vue from 'vue'
import _ from 'lodash'

import MixinMath from '@/mixins/math'

import movableLinesModule from '@/vuex/modules/submodules/los/movableLines'
import outsideLinesModule from '@/vuex/modules/submodules/los/outsideLines'
import drawableElementsModule from '@/vuex/modules/submodules/los/drawableElements'
import wasteManagementModule from '@/vuex/modules/submodules/los/waste'

import { Path } from '@/classes/objects'
// import { Infill, Subbase } from '@/classes/products'
import { productTypes } from '@/helper/api/types'

const convert_value_to_pixels = (state, value) => {
  return (value / state.measure.toUnit) * state.measure.fromPixels
}

const state = {
  mode: 0,
  object: null,
  product: null,
  measure: null,
  isSelectedOutline: false,
  selectedTracks: [],
  temporaryOversize: 0,
  temporary: {
    width: 0,
    length: 0,
    oversize: 0
  },
  subbasesAmount: []
}

const getters = {
  getTurfPropertiesForPseudoRotation: (state) => {
    return {
      angle: state.product.angle,
      width: convert_value_to_pixels(state, state.product.width),
      shift: convert_value_to_pixels(state, state.product.shift),
      alignment: state.product.alignment
    }
  },
  getTurfPropertiesForPseudoShift: (state) => {
    return {
      objID: state.object.id,
      alignment: state.product.alignment,
      width: state.product.width
    }
  },
  getTurfPropertiesForCalculation: (state) => {
    return {
      angles: [Number(state.product.angle)],
      perfectVisualResult: state.product.perfectVisualResult,
      alignment: state.product.alignment,
      threshold: convert_value_to_pixels(state, state.product.threshold),
      shift: convert_value_to_pixels(state, state.product.shift),
      turfWidth: convert_value_to_pixels(state, state.product.width),
      turfLength: convert_value_to_pixels(state, state.product.length)
    }
  },
  getTurfPropertiesForAlignment: (state) => {
    return state.product.alignment
  },
  getTurfMaxLength: (state) => {
    return state.product.max.length
  },
  getTurfMaxWidth: (state) => {
    return state.product.max.width
  },
  getProduct: (state) => {
    return state.product
  },
  getProductAlignment: (state) => {
    return state.product.alignment
  },
  getSelectedTurfTracks: (state) => {
    return state.product.tracks.filter(el => state.selectedTracks.includes(el.name))
  },
  getLongestOfSelectedTurfTracks: (state) => {
    const tracks = state.product.tracks.filter(el => state.selectedTracks.includes(el.name))
    let longestTrack = null
    let max = -Infinity

    for (const track of tracks) {
      if (track.length > max) {
        longestTrack = track
        max = track.length
      }
    }
    return longestTrack
  },
  hasTurfTracks: (state) => {
    return state.product.tracks !== null
  },
  isTurfTrackSelected: (state) => (trackName) => {
    return state.selectedTracks.includes(trackName)
  },
  hasTurfTrackSelected: (state) => {
    return state.selectedTracks.length > 0
  },
  getTemporaryWidth: (state) => {
    return state.temporary.width
  },
  getTemporaryLength: (state) => {
    return state.temporary.length
  },
  getTemporaryOversize: (state) => {
    return state.temporary.oversize
  },
  isWorkingDataSet: (state) => {
    return (state.object !== null && state.product !== null)
  },
  getTurfTrackByName: (state) => (trackName) => {
    return state.product.tracks.find(track => {
      return track.name === trackName
    })
  },
  getTracksByCutId: (state) => (ids) => {
    return state.product.tracks.filter(el => {
      return ids.includes(el.name)
    })
  },
  getProductSubstructures: (state) => {
    if (state.product !== null && state.product.subbases.length > 0) {
      return state.product.subbases
    }

    return null
  },
  getProductInfills: (state) => {
    if (state.product !== null && state.product.infills.length > 0) {
      return state.product.infills
    }

    return null
  },
  getSubbaseAmountByIdType: (state) => (data) => {
    const found = state.subbasesAmount.find(el => {
      return el.id === data.id && el.type === data.type
    })

    return (found !== undefined) ? found.amount : null
  },
  getObject: (state) => {
    return state.object
  },
  getObjectId: (state) => {
    return (state.object) ? state.object.id : null
  },
  getObjectProduct: (state) => {
    return (state.product) ? state.product : null
  },
  getObjectTracks: (state) => {
    return (state.product && state.product.tracks) ? state.product.tracks : null
  },
  isObjectSelectedById: (state) => (objectId) => {
    return (state.object) ? state.object.id === objectId : false
  }
}

const actions = {
  setDataToWorkWith ({ commit, rootState }, data) {
    commit(types.LOS_SET_MEASUREMENT_SETTINGS, rootState.project.measurement.measure)

    const workingData = _.cloneDeep(data)
    commit(types.LOS_SET_DATA_TO_WORK_WITH, workingData)

    const settings = { width: workingData.product.width, length: workingData.product.length }
    commit(types.LOS_CHANGE_TEMPORARY_TURF_SETTINGS, settings)
  },
  changeLosMode ({ commit }, modeType) {
    commit(types.LOS_CHANGE_MODE, modeType)
  },
  turfTrackSelection ({ state, commit }, payload) {
    if (!state.selectedTracks.includes(payload.trackName) && payload.insert) {
      commit(types.LOS_ADD_TURF_TRACK_TO_SELECTION, payload.trackName)
      commit(types.LOS_CHANGE_TEMPORARY_TURF_SETTINGS, { oversize: 0 })
    } else if (state.selectedTracks.includes(payload.trackName)) {
      commit(types.LOS_REMOVE_TURF_TRACK_FROM_SELECTION, payload.trackName)
      commit(types.LOS_CHANGE_TEMPORARY_TURF_SETTINGS, { oversize: 0 })
    } else {
      if (!payload.insert) {
        commit(types.LOS_CLEAR_TURF_TRACK_SELECTION)
      }
      commit(types.LOS_ADD_TURF_TRACK_TO_SELECTION, payload.trackName)
      commit(types.LOS_CHANGE_TEMPORARY_TURF_SETTINGS, { oversize: payload.oversize })
    }
  },
  turfTrackSelectionClear ({ commit }) {
    commit(types.LOS_CLEAR_TURF_TRACK_SELECTION)
  },
  updateProductBySplitTurf ({ state, dispatch, commit }, payload) {
    commit(types.LOS_UPDATE_SPLIT_TURF_TRACK, payload)
    dispatch('lineOfSight/drawableElements/setDrawableTracks', state.product.tracks, { root: true })
  },
  changeProductTurfAngle ({ commit }, angle) {
    commit(types.LOS_IS_SELECTED_OUTLINE, false)
    commit(types.LOS_CHANGE_TURF_ANGLE, angle)
  },
  changeProductTurfShift ({ commit }, shift) {
    commit(types.LOS_CHANGE_TURF_SHIFT, shift)
  },
  changeProductTurfWidth ({ commit }, width) {
    commit(types.LOS_CHANGE_TURF_SHIFT, 0)
    commit(types.LOS_CHANGE_TURF_WIDTH, width)
  },
  changeProductTurfLength ({ commit }, length) {
    commit(types.LOS_CHANGE_TURF_LENGTH, length)
  },
  changeProductTurfOversize ({ dispatch, commit }, oversize) {
    commit(types.LOS_CHANGE_TURF_OVERSIZE, oversize)
    dispatch('lineOfSight/drawableElements/setDrawableTracks', state.product.tracks, { root: true })
  },
  changeTemporaryTurfSettings ({ commit }, settings) {
    commit(types.LOS_CHANGE_TEMPORARY_TURF_SETTINGS, settings)
  },
  changeProductTurfResult ({ commit }, result) {
    commit(types.LOS_CHANGE_TURF_RESULT, result)
  },
  changeProductTurfAngleByOutsideLine ({ commit }, angle) {
    commit(types.LOS_IS_SELECTED_OUTLINE, true)
    commit(types.LOS_CHANGE_PRODUCT_ALIGNMENT, false)

    commit(types.LOS_CHANGE_TURF_ANGLE, angle)
  },
  changeProductAlignment ({ commit }, payload) {
    commit(types.LOS_CHANGE_PRODUCT_ALIGNMENT, payload.align)
    commit(types.LOS_CHANGE_TURF_SHIFT, 0)
    if (state.isSelectedOutline) {
      commit(types.LOS_CHANGE_TURF_ANGLE, payload.angle)
    }
  },
  updateObjectProductData ({ state, dispatch, commit }) {
    dispatch('project/objects/updateObjectByLineOfSight', {
      object: state.object.id,
      product: state.product
    }, { root: true })
    dispatch('lineOfSight/movableLines/clearMovableData', null, { root: true })
    dispatch('lineOfSight/drawableElements/clearDrawableData', null, { root: true })
    dispatch('lineOfSight/outsideLines/clearOutlineData', null, { root: true })
    commit(types.LOS_CLEAR_DATA_TO_WORK_WITH)
  },
  updateTracksByRemovingCut ({ state, dispatch, commit }, payload) {
    commit(types.LOS_UPDATE_TRACKS_BY_REMOVING_PATH_STOP, payload)
    dispatch('lineOfSight/drawableElements/setDrawableTracks', state.product.tracks, { root: true })
  },
  manipulateTracksByChangedPathStop ({ dispatch, state, commit }, data) {
    commit(types.LOS_UPDATE_TRACKS_BY_MOVED_PATH_STOP, data)
    dispatch('lineOfSight/drawableElements/setDrawableTracks', state.product.tracks, { root: true })
  },
  /**
   *
   * @param commit
   * @param {Subbase} subbase
   */
  addSubbaseToProduct ({ commit }, subbase) {
    commit(types.LOS_ADD_SUBBASE_TO_PRODUCT, subbase)
    if (subbase.type !== productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
      commit(types.LOS_CACHE_SUBBASE_AMOUNT, { id: subbase.id, amount: subbase.amount, type: subbase.type })
    }
  },
  removeSubbaseFromProduct ({ commit }, key) {
    commit(types.LOS_REMOVE_SUBBASE_FROM_PRODUCT, key)
  },
  updateSubbaseProperties ({ state, commit }, data) {
    data.elements = state.product.getSubstructures()
    commit(types.LOS_UPDATE_SUBBASE_INFILL_PROPERTIES, data)
  },
  updateSubstructuresSorting ({ commit }, substructures) {
    commit(types.LOS_OVERRIDE_SUBSTRUCTURES_BY_SORTING, substructures)
  },
  /**
   *
   * @param commit
   * @param {Infill} infill
   */
  addInfillToProduct ({ commit }, infill) {
    commit(types.LOS_ADD_INFILL_TO_PRODUCT, infill)
  },
  updateInfillProperties ({ state, commit }, data) {
    data.elements = state.product.getInfills()
    commit(types.LOS_UPDATE_SUBBASE_INFILL_PROPERTIES, data)
  },
  removeInfillFromProduct ({ commit }, key) {
    commit(types.LOS_REMOVE_INFILL_FROM_PRODUCT, key)
  },
  updateInfillsSorting ({ commit }, infills) {
    commit(types.LOS_OVERRIDE_INFILLS_BY_SORTING, infills)
  },
  updateSubbasesAfterChanges ({ commit }, subbases) {
    commit(types.LOS_UPDATE_SUBBASES_AFTER_CHANGES, subbases)
  }
}

const mutations = {
  [types.LOS_SET_MEASUREMENT_SETTINGS] (state, measure) {
    state.measure = _.cloneDeep(measure)
  },
  [types.LOS_SET_DATA_TO_WORK_WITH] (state, workingData) {
    if (workingData instanceof Path) {
      state.object = workingData
      state.product = workingData.product
      state.subbasesAmout = []
    }
  },
  [types.LOS_CHANGE_MODE] (state, mode) {
    state.mode = mode
  },
  [types.LOS_CLEAR_DATA_TO_WORK_WITH] (state) {
    state.object = null
    state.product = null
    state.measure = null
    state.isSelectedOutline = false
    state.selectedTracks = []
    state.temporaryOversize = 0
    state.mode = 0
    state.subbasesAmount = []
  },
  [types.LOS_ADD_TURF_TRACK_TO_SELECTION] (state, trackName) {
    state.selectedTracks.push(trackName)
  },
  [types.LOS_REMOVE_TURF_TRACK_FROM_SELECTION] (state, trackName) {
    state.selectedTracks = state.selectedTracks.filter(el => el !== trackName)
  },
  [types.LOS_CLEAR_TURF_TRACK_SELECTION] (state) {
    state.selectedTracks = []
  },
  [types.LOS_CHANGE_TURF_ANGLE] (state, angle) {
    state.product.angle = angle
  },
  [types.LOS_CHANGE_TURF_SHIFT] (state, shift) {
    state.product.shift = shift
  },
  [types.LOS_CHANGE_TURF_WIDTH] (state, width) {
    state.product.width = width
  },
  [types.LOS_CHANGE_TURF_LENGTH] (state, length) {
    state.product.length = length
  },
  [types.LOS_CHANGE_TURF_OVERSIZE] (state, oversize) {
    const tracksToUpdate = state.product.tracks.filter(el => state.selectedTracks.includes(el.name))

    if (tracksToUpdate.length > 0) {
      tracksToUpdate.forEach(track => {
        track.oversize = oversize
      })
    }
  },
  [types.LOS_UPDATE_SPLIT_TURF_TRACK] (state, result) {
    const turfTrackToUpdate = state.product.tracks.find(el => el.name === result.turfTrack.name)
    const turfTrackToUpdateIndex = state.product.tracks.findIndex(el => el.name === result.turfTrack.name)

    turfTrackToUpdate.setPoints(result.turfTrack.points)
    turfTrackToUpdate.setLength(result.turfTrack.length)
    turfTrackToUpdate.setOversize(0)

    state.product.lastTrackId += 1
    state.product.tracks.splice(turfTrackToUpdateIndex, 0, result.new)
  },
  [types.LOS_CHANGE_TURF_RESULT] (state, result) {
    for (const key in result) {
      if (Object.prototype.hasOwnProperty.call(state.product, key)) {
        Vue.set(state.product, key, result[key])
      }
    }
  },
  [types.LOS_IS_SELECTED_OUTLINE] (state, is) {
    state.isSelectedOutline = is
  },
  [types.LOS_CHANGE_PRODUCT_ALIGNMENT] (state, alignment) {
    state.product.alignment = alignment
  },
  [types.LOS_CHANGE_TEMPORARY_TURF_SETTINGS] (state, settings) {
    for (const prop in settings) {
      if (Object.prototype.hasOwnProperty.call(state.temporary, prop)) {
        Vue.set(state.temporary, prop, settings[prop])
      }
    }
  },
  [types.LOS_UPDATE_TRACKS_BY_REMOVING_PATH_STOP] (state, data) {
    const trackToUpdate = state.product.tracks.find(track => track.name === data.trackIdToUpdate)

    const indexToRemove = state.product.tracks.findIndex(track => track.name === data.trackIdToDelete)

    state.product.tracks.splice(indexToRemove, 1)

    Vue.set(trackToUpdate, 'length', data.trackLength)
    Vue.set(trackToUpdate, 'points', data.trackPoints)
  },
  [types.LOS_UPDATE_TRACKS_BY_MOVED_PATH_STOP] (state, data) {
    const fkt_updatePoints = (track, op, np) => {
      do {
        const i = track.points.findIndex(p => p.x === op.x && p.y === op.y)

        track.points[i].x = MixinMath.methods.mx_math_roundDecimal(np.x)
        track.points[i].y = MixinMath.methods.mx_math_roundDecimal(np.y)
      } while (track.points.findIndex(p => p.x === op.x && p.y === op.y) !== -1)
    }

    const fkt_updateTrackLength = (track, measure) => {
      const pFiltered = track.points.filter(p => p.type !== 'M' && p.type !== 'Z')
      pFiltered.splice(-1, 1)

      return MixinMath.methods.mx_math_calculateLengthsFromPoints(pFiltered, measure)[0].cl.toFixed(2)
    }

    const searchID = data.points.old.id
    const tracksToUpdate = state.product.tracks.filter(track => searchID.includes(track.name))

    const p1Search = data.points.old.p1
    const p2Search = data.points.old.p2

    // update each track points
    tracksToUpdate.forEach(track => {
      fkt_updatePoints(track, p1Search, data.points.new.p1)
      fkt_updatePoints(track, p2Search, data.points.new.p2)
      track.setLength(fkt_updateTrackLength(track, state.measure))
    })
  },
  [types.LOS_ADD_SUBBASE_TO_PRODUCT] (state, subbase) {
    state.product.addSubbase(subbase)
  },
  [types.LOS_REMOVE_SUBBASE_FROM_PRODUCT] (state, key) {
    state.product.removeSubbase(key)
  },
  [types.LOS_OVERRIDE_SUBSTRUCTURES_BY_SORTING] (state, substructures) {
    state.product.setSubstructures(substructures)
  },
  [types.LOS_ADD_INFILL_TO_PRODUCT] (state, infill) {
    state.product.addInfill(infill)
  },
  [types.LOS_REMOVE_INFILL_FROM_PRODUCT] (state, key) {
    state.product.removeInfill(key)
  },
  [types.LOS_OVERRIDE_INFILLS_BY_SORTING] (state, infills) {
    state.product.setInfills(infills)
  },
  [types.LOS_UPDATE_SUBBASE_INFILL_PROPERTIES] (state, data) {
    for (const prop in data.props) {
      if (Object.prototype.hasOwnProperty.call(data.elements[data.index], prop)) {
        Vue.set(data.elements[data.index], prop, data.props[prop])
      }
    }
  },
  [types.LOS_UPDATE_SUBBASES_AFTER_CHANGES] (state, subbases) {
    state.subbasesAmount = []
    if (state.product.subbases.length > 0) {
      state.product.subbases = subbases
    }
  },
  [types.LOS_CACHE_SUBBASE_AMOUNT] (state, data) {
    state.subbasesAmount.push(data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    movableLines: movableLinesModule,
    outsideLines: outsideLinesModule,
    drawableElements: drawableElementsModule,
    wasteManagement: wasteManagementModule
  }
}
