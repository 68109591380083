<template>
  <div
    id="losPanel"
    class="c-los__panel"
  >
    <div class="c-los__panel__container">
      <div class="c-los__panel__close">
        <button-icon
          id="button-close-los"
          classes="c-los__panel__close-button"
          icon-classes="fas fa-2x fa-times"
          style-font-size="xxs"
          @click="toggleLineOfSightMode()"
        />
      </div>
      <div class="c-los__panel-tabs">
        <tabs
          :classes="['c-tabs-alt', 'c-tabs--full-width']"
          :current-tab="currentTab"
          :tabs="tabLabelNames"
          @tabClicked="pagination"
        />
      </div>
      <transition-group
        class="c-los__panel-opacity c-los__panel__items-wrapper"
        name="c-los__panel-opacity"
        tag="div"
      >
        <product-options
          v-if="Number(currentTab) === modeTypes.product"
          key="0"
          @productPropertiesChanged="calculateTracks"
        />
        <subbase-options
          v-if="Number(currentTab) === modeTypes.substructures"
          key="1"
        />
        <infill-options
          v-if="Number(currentTab) === modeTypes.infills"
          key="2"
          :infills="infills"
        />
      </transition-group>
    </div>
  </div>
</template>

<script>
import config from '@/config'
import _ from 'lodash'
import paperHelper from '@/helper/paperHelper'

import { mapState, mapGetters, mapActions } from 'vuex'

import Tabs from '@/components/common/Tabs'
import ButtonIcon from '@/components/common/ButtonIcon'

import MixinMath from '@/mixins/math'
import MixinAlgorithmServer from '@/mixins/algorithmServer'
import MixinAlgorithmIntersect from '@/mixins/algorithmIntersect'
import MixinAlgorithmHelper from '@/mixins/algorithm_helper'
import MixinLineOfSight from '@/mixins/lineOfSight'
import PaperLayer from '@/classes/paperLayer'
import { TurfTrack } from '@/classes/products'

import ProductOptions from '@/components/modules/lineOfSight/options/ProductOptions'
import SubbaseOptions from '@/components/modules/lineOfSight/options/SubbaseOptions'
import InfillOptions from '@/components/modules/lineOfSight/options/InfillOptions'
import { productTypes } from '@/helper/api/types'

const convert_value_to_pixels = (measure, value) => {
  return (value / measure.toUnit) * measure.fromPixels
}

const get_angle_by_points = (v1, v2, offset = 90) => {
  const srX = (v2.x - v1.x)
  const srY = (v2.y - v1.y)

  let angle = (Math.atan2(srY, srX) * 180 / Math.PI) - offset

  if (angle < 0) {
    angle += 360
  }

  return Number(angle.toFixed(2))
}

export default {
  name: 'LineOfSight',
  components: {
    Tabs,
    ButtonIcon,
    ProductOptions,
    SubbaseOptions,
    InfillOptions
  },
  mixins: [
    MixinMath,
    MixinAlgorithmServer,
    MixinAlgorithmIntersect,
    MixinAlgorithmHelper,
    MixinLineOfSight
  ],
  data () {
    return {
      currentTab: 0,
      modeTypes: config.los.modes
    }
  },
  computed: {
    ...mapState({
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      infills: state => state.products.infills
    }),
    ...mapGetters({
      getMeasure: 'project/measurement/getMeasure',
      getObject: 'lineOfSightNew/getObject',
      getObjectProduct: 'lineOfSightNew/getObjectProduct',
      getObjectProductSubbases: 'lineOfSightNew/getObjectProductSubbases',
      getObjectLines: 'lineOfSightNew/getObjectLines',
      getOtherById: 'products/getSubbaseOtherById',
      getPadById: 'products/getSubbasePadById',
      getPedestalById: 'products/getSubbasePedestalById'
    }),
    tabLabelNames () {
      return [
        this.$i18n.messages[this.$i18n.locale].editor.lineOfSight.tabs.product,
        this.$i18n.messages[this.$i18n.locale].editor.lineOfSight.tabs.subbases,
        this.$i18n.messages[this.$i18n.locale].editor.lineOfSight.tabs.infills
      ]
    },
    losObject () {
      return this.getObject
    }
  },
  watch: {
    losObject: {
      handler: async function (n, o) {
        if (n !== null) {
          this.createObjectLines()
          if (n.productNeedUpdate || typeof n.product.tracks === 'undefined') {
            await this.calculateTracks(this.getAngles())
          }
          this.createTrackCutLines()
        }
      },
      deep: true
    }
  },
  async mounted () {
    this.createObjectLines()
    if (this.losObject.productNeedUpdate || this.losObject.product.tracks === null) {
      const angles = (this.losObject.product.tracks !== null) ? { angles: [this.losObject.product.angle] } : this.getAngles()
      await this.calculateTracks(angles)
    }
    this.createTrackCutLines()
  },
  methods: {
    ...mapActions({
      updateObjectProduct: 'lineOfSightNew/updateObjectProduct',
      setObjectLines: 'lineOfSightNew/setObjectLines',
      setTrackCutLines: 'lineOfSightNew/setTrackCutLines',
      updateSubbasesAfterChanges: 'lineOfSightNew/updateSubbasesAfterChanges',
      toggleLineOfSightMode: 'events/toggleLineOfSightMode'
    }),
    getAngles () {
      const objLines = this.getObjectLines
      const angles = objLines.map(line => {
        return Number(line.angle)
      })
      angles.sort(function (a, b) {
        return a - b
      })

      return { angles: angles }
    },
    async calculateTracks (override = null) {
      const properties = {
        angles: [Number(this.losObject.product.angle)],
        perfectVisualResult: this.losObject.product.perfectVisualResult,
        alignment: this.losObject.product.alignment,
        threshold: convert_value_to_pixels(this.getMeasure, this.losObject.product.threshold),
        shift: convert_value_to_pixels(this.getMeasure, this.losObject.product.shift),
        turfWidth: convert_value_to_pixels(this.getMeasure, this.losObject.product.width),
        turfLength: convert_value_to_pixels(this.getMeasure, this.losObject.product.length)
      }

      if (override !== null) {
        for (const prop in override) {
          if (Object.prototype.hasOwnProperty.call(properties, prop)) {
            properties[prop] = override[prop]
          }
        }
      }

      const result = await this.mx_algorithmServer_calculate(this.losObject, properties)

      this.$_create_tracks_by_result(result)
    },
    createObjectLines () {
      const objectLines = []

      this.losObject.points.forEach((point, index) => {
        if (point.type !== 'M' && point.type !== 'Z') {
          let linePath = ''

          const pointBefore = this.losObject.points[index - 1]

          if (point.type === 'Q') {
            linePath = `M ${pointBefore.x} ${pointBefore.y} Q ${point.handles.x1} ${point.handles.y1} ${point.x} ${point.y}`
          } else if (point.type === 'C') {
            linePath = `M ${pointBefore.x} ${pointBefore.y} C ${point.handles.x1} ${point.handles.y1} ${point.handles.x2} ${point.handles.y2} ${point.x} ${point.y}`
          } else {
            linePath = `M ${pointBefore.x} ${pointBefore.y} L ${point.x} ${point.y}`
          }

          objectLines.push({
            id: 'objectLines-' + point.id,
            path: linePath,
            angle: get_angle_by_points(pointBefore, point),
            type: 'objectLine'
          })
        }
      })

      this.setObjectLines(objectLines)
    },
    createTrackCutLines () {
      const preparedTracks = paperHelper.prepare_tracks(this.losObject.product.tracks)
      const trackCutLines = paperHelper.prepare_movables_by_tracks(preparedTracks, { angle: this.getObjectProduct.angle, length: this.getObjectProduct.length })

      this.setTrackCutLines(trackCutLines)
    },
    /**
     * Calculate Subbases (Pad, Pedestals)
     * @param {number} angle
     * @ignore
     */
    async $_update_used_subbases (angle) {
      if (this.getObjectProductSubbases && this.getObjectProductSubbases.length > 0) {
        const clone = _.cloneDeep(this.getObjectProductSubbases)

        const getData = async (subbases) => {
          return Promise.all(subbases.map(async subbase => {
            const result = await calc(subbase)

            if (result !== null) {
              subbase.amount = result.rolls_used
            }

            return subbase
          }))
        }

        const calc = async el => {
          const originalSubbase = this.$_get_subbase(el.id, el.type)

          if (originalSubbase.type !== productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
            const width = (originalSubbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) ? originalSubbase.overlay.pad_width : originalSubbase.overlay.pedestal_width
            const length = (originalSubbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) ? originalSubbase.overlay.pad_length : originalSubbase.overlay.pedestal_length
            const properties = {
              angles: [this.losAngle],
              perfectVisualResult: true,
              alignment: this.losAlignment,
              threshold: length,
              shift: 0,
              turfWidth: Number(convert_value_to_pixels(this.getMeasure, width)),
              turfLength: Number(convert_value_to_pixels(this.getMeasure, length))
            }

            return await this.mx_algorithmServer_calculate(this.losObject, properties)
          }

          return null
        }

        const subbases = await getData(clone).then(response => {
          return response
        })

        this.updateSubbasesAfterChanges(subbases)
      }
    },
    /**
     * Get subbase by type
     * @param {number} id
     * @param {string} type
     * @returns {*}
     * @ignore
     */
    $_get_subbase (id, type) {
      switch (type) {
        case productTypes.PRODUCT_OTHER_SUBBASE_TYPE:
          return this.getOtherById(id)
        case productTypes.PRODUCT_PAD_SUBBASE_TYPE:
          return this.getPadById(id)
        case productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE:
          return this.getPedestalById(id)
      }
    },
    /**
     * Returns cheapest result
     * @param results
     * @returns {*}
     */
    $_find_cheapest_tracks_result (results) {
      let cheapest = null
      let lowest = Number.POSITIVE_INFINITY

      for (const [key, value] of results.entries()) {
        if (value.turfRoll.edgeLength < lowest) {
          lowest = Number(value.turfRoll.edgeLength)
          cheapest = key
        }
      }

      return results.get(cheapest)
    },
    async $_create_tracks_by_result (result) {
      if (result) {
        const tracks = []

        result.rolls_details.forEach(track => {
          const segments = track.segments.map(s => {
            const _s = {
              point: {
                x: s.x,
                y: s.y
              },
              handleIn: {}
            }
            return _s
          })
          const points = PaperLayer.makePointsFromPaperSegments(segments)
          const dimensions = this.$_get_track_dimensions(track)

          const _track = new TurfTrack(track.name)
          _track.setPoints(points)
          _track.setWidth(dimensions.width)
          _track.setLength(dimensions.length)
          tracks.push(_track)
        })

        this.updateObjectProduct({
          lastTrackId: result.rolls_used,
          tracks: tracks,
          angle: Number(result.angle)
        })

        this.$_update_used_subbases(Number(result.angle))
      }
    },
    $_get_track_dimensions (track) {
      const _track = _.clone(track)

      return {
        width: this.$_mx_algorithm_helper_get_value_by_measurement(_track.dimensions.width).toFixed(2),
        length: this.$_mx_algorithm_helper_get_value_by_measurement(_track.dimensions.height).toFixed(2)
      }
    },
    pagination (payload) {
      switch (payload) {
        case 0:
          this.currentTab = config.los.modes.product
          break
        case 1:
          this.currentTab = config.los.modes.substructures
          break
        case 2:
          this.currentTab = config.los.modes.infills
          break
      }
    }
  }
}
</script>
