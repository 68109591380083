<template>
  <dialog-frame
    v-if="dialogProps"
    :subtitle=" $tc(`dialogs.delete${productType}.subtitle`, dialogProps.data.length)"
    :target-dialog="targetDialog"
    :title=" $tc(`dialogs.delete${productType}.title`, dialogProps.data.length) "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p
          class="u-mb-size-standard"
          v-html="$tc(`dialogs.delete${productType}.info`, dialogProps.data.length)"
        />
        <template
          v-if="dialogProps.data.length === 1"
        >
          <span class="u-color-b1">
            {{ dialogProps.data[0].name }}
          </span>
        </template>
        <template
          v-else-if="dialogProps.data.length > 1 && dialogProps.data.length <= 3"
        >
          <span
            v-for="(product, key) in dialogProps.data"
            :key="key"
            class="u-color-b1"
          >
            {{ product.name }}
          </span>
        </template>
        <template
          v-else
        >
          <span
            v-for="(product, key) in dialogProps.data"
            :key="key"
            class="u-color-b1"
          >
            <span v-if="key + 1 <= 3 ">
              {{ product.name }}
            </span>
          </span>
          <span class="u-color-b1">
            {{ $t(`dialogs.delete${productType}.more`, {count: dialogProps.data.length - 3}) }}
          </span>
        </template>

        <p class="u-mt-size-standard">
          {{ $t('common.noUndo') }}
        </p>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="delete-material-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
          />
          <button-base
            id="delete-material-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return doDeleteMaterial() }"
            style-color="gray"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
* @displayName Dialog Delete Material
*/
export default {
  name: 'DeleteMaterial',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  props: {
    productType: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.deleteMaterial
    }),
    targetDialog () {
      return 'deleteMaterial'
    }

  },
  methods: {
    ...mapActions({
      loadProjects: 'backend/loadProjects',
      deleteSpecialProduct: 'backendAPI/product/subProductHandler/deleteSpecialProduct'
    }),
    deleteSpecial (ids) {
      this.deleteSpecialProduct({
        delete: ids,
        type: this.productType.toLowerCase()
      })
    },
    close () {
      this.mx_dialogs_closeDialog()
    },
    async doDeleteMaterial () {
      await this.deleteSpecial(this.dialogProps.data.map(mat => mat.id))
      this.close()
    }
  }
}
</script>
