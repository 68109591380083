<template>
  <g
    v-if="collisionPoints.length"
    :id="'colliding-snap-points'"
    class="no-pointer-events"
  >
    <circle
      v-for="(cp, index) in collisionPoints"
      :key="'ch1-' + index"
      :cx="cx(cp)"
      :cy="cy(cp)"
      :r="innerRadius"
      :stroke-width="innerWidth"
      fill="#ff0000"
      stroke="#ff0000"
    />
    <circle
      v-for="(cp, index) in collisionPoints"
      :key="'ch2-' + index"
      :cx="cx(cp)"
      :cy="cy(cp)"
      :r="outerRadius"
      :stroke-width="outerWidth"
      fill="#ff000033"
      stroke="#ff0000"
    />
  </g>
</template>
<script>
import { mapState } from 'vuex'
import MixinMath from '@/mixins/math'

export default {
  name: 'CollidingSnapPoints',
  mixins: [
    MixinMath
  ],
  computed: {
    ...mapState({
      collisionPoints: state => state.project.collisionPoints,
      translate: state => state.project.object.translate,
      commonSvgSettings: state => state.common.svg
    }),
    innerRadius () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.snapCircle.inner)
    },
    outerRadius () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.snapCircle.outer)
    },
    innerWidth () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.snapCircle.innerStrokeWidth)
    },
    outerWidth () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.snapCircle.outerStrokeWidth)
    }
  },
  methods: {
    cx (cp) {
      let x = 0
      switch (cp.handle) {
        case 1:
          x = cp.p1.handles.x1
          break
        case 2:
          x = cp.p1.handles.x2
          break
        default:
          x = cp.p1.x
          break
      }

      return x
    },
    cy (cp) {
      let y = 0
      switch (cp.handle) {
        case 1:
          y = cp.p1.handles.y1
          break
        case 2:
          y = cp.p1.handles.y2
          break
        default:
          y = cp.p1.y
          break
      }

      return y
    }
  }
}
</script>
