import config from '@/config'

export default {
  data: () => {
    return {
      config: config
    }
  },
  computed: {
    languagesForTranslationFields () {
      const entries = Object.entries(config.locales)
      const indexEN = Object.keys(config.locales).find(key => config.locales[key] === 'en')

      delete entries[indexEN]

      return entries.filter(e => e !== null).map(e => [Number(e[0]), e[1]])
    }
  }
}
