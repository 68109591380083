<template>
  <drop-down
    v-if="current"
    id="language-switch-sidenav"
    v-model="current"
    :items="mx_languages_getAvailableLanguages"
    :style-up="false"
    classes="u-mb-size-20 u-ph-size-40"
    label-key="label"
    value-key="value"
  />
</template>

<script>
import DropDown from '@/components/common/DropDown'
import { mapActions, mapGetters } from 'vuex'
import MixinLanguages from '@/mixins/languages'

export default {
  name: 'LanguageSwitch',
  components: { DropDown },
  mixins: [MixinLanguages],
  computed: {
    ...mapGetters({
      currentLanguage: 'language/get'
    }),
    current: {
      get () {
        return this.currentLanguage
      },
      set (language) {
        this.$i18n.locale = language
        this.setLanguage(language)
      }
    }
  },
  methods: {
    ...mapActions({
      setLanguage: 'language/set'
    })
  }
}
</script>
