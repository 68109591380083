<template>
  <div class="c-nav-sub__group c-nav-sub__group--end">
    <v-popover
      placement="bottom"
      popoverInnerClass="popover-inner"
    >
      <button
        v-if="objectsWithProducts.length > 0"
        id="sub-nav-button-checkout"
        :disabled="shouldBlockGui"
        class="c-nav-sub__item c-nav-sub__item u-mr-size-standard"
        @click="openCheckoutDialog"
      >
        <icon classes="fas fa-shopping-cart" />
        <span class="c-nav-sub__item-info-number">
          {{ objectsWithProducts.length }}
        </span>
      </button>

      <template #popover>
        <div class="c-tooltip">
          <div class="c-tooltip-header c-tooltip-header--no-slot">
            <h4 class="c-tooltip-label">
              {{ $t('subNav.overview.overview') }}
            </h4>
            <p class="c-tooltip-category">
              {{ $t('subNav.categories.product') }}
            </p>
          </div>
        </div>
      </template>
    </v-popover>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'

import MixinOperations from '@/mixins/operations'
import MixinDialogHandler from '@/mixins/dialogHandler'
import Icon from '@/components/common/Icon'

export default {
  name: 'ProductsPan',
  components: {
    Icon
  },
  mixins: [
    MixinOperations,
    MixinDialogHandler
  ],
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      isCutOut: state => state.project.objects.cutOut,
      currentSelectedProducts: state => state.events.currentSelectedProducts,
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      projectMeta: state => state.project.backendMeta
    }),
    ...mapGetters({
      objectsWithProducts: 'project/objects/objectsWithProducts',
      shouldBlockGui: 'events/shouldBlockGui'
    })
  },
  methods: {
    ...mapActions({
      projectHasChanges: 'history/projectHasChanges',
      updateProject: 'backendAPI/project/updateState'
    }),
    async openCheckoutDialog () {
      const changes = await this.projectHasChanges()

      if (changes) {
        if (this.projectMeta.id) {
          this.updateProject()
        } else {
          this.$_mx_dialogHandler_openDialog({ dialog: 'createProject', onOff: true })
        }
      }

      this.$events.fire('dialog-checkout')
    }
  }
}
</script>
