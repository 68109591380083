<template>
  <svg
    id="order-up-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M8.64,28.22A2.5,2.5,0,0,1,6.88,24L20.21,10.62,33.34,23.75a2.5,2.5,0,0,1-3.54,3.54l-9.59-9.6-9.8,9.8A2.53,2.53,0,0,1,8.64,28.22Z"
    />

  </svg>
</template>

<script>
export default {
  name: 'OrderUpIcon'
}
</script>
