<template>
  <div class="c-backend-cat-menu o-grid">
    <div class="c-backend-cat-menu__menu o-grid__col">
      <router-link
        v-for="category in menu.categories"
        :id="category"
        :key="category"
        :class="[currentCat === category ? 'c-backend-cat-menu__menu-item--active': '', 'c-backend-cat-menu__menu-item']"
        :to="`/backend/user-management/${category}`"
        tag="button"
      >
        {{ $t(`backend.userManagement.menu.${category}.default`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import config from '@/config'
// import ButtonIcon from '@/components/common/ButtonIcon'
import { mapState } from 'vuex'

/**
     * @displayName User Management Menu Component
     */
export default {
  name: 'UserCatMenu',
  components: { /* ButtonIcon */ },
  props: {
    currentCat: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      config: config
    }
  },
  computed: {
    ...mapState({
      locations: store => store.backendAPI.location.locations
    }),
    menu () {
      const categories = Object.values(this.config.userManagement)
      return { categories: categories }
    }
  }
}
</script>
