<template>
  <svg
    id="order-menu-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M8.55,18.09a2.51,2.51,0,0,1-1.77-4.27L20.11.49,33.24,13.62a2.5,2.5,0,0,1-3.54,3.53L20.11,7.56l-9.8,9.79A2.46,2.46,0,0,1,8.55,18.09Z"
    />
    <path
      class="u-fill-w"
      d="M19.91,39.48,6.78,26.35a2.5,2.5,0,0,1,3.53-3.53l9.6,9.59,9.79-9.79a2.5,2.5,0,0,1,3.54,3.53Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderMenuIcon'
}
</script>
