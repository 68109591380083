<template>
  <dialogs-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.clientApiKey.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.clientApiKey.title') "
  >
    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <div class="o-grid o-grid--center">
          <div class="o-grid__col u-4/4 u-text-center">
            <icon classes="fas fa-2x fa-exclamation-triangle u-color-o u-mt-size-30" />
            <p class="c-typo__h4 u-color-o">
              {{ $t('dialogs.clientApiKey.info') }}
            </p>
            <div class="u-flex u-ai-center u-jc-center u-mt-size-30 u-mb-size-20">
              <input-field
                id="api-key-value"
                v-model="apiKey"
                :is-store-mode="false"
                :is-read-only="true"
                classes="u-m-size-0"
                style-input-width="500px"
                type="text"
              />
              <button-icon
                id="api-key-copy-to-clipboard"
                classes="u-ml-size-standard"
                icon-classes="far fa-copy"
                @click="copy"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child u-jc-center">
          <button-base
            id="api-key-close"
            :label="$t('common.ok')"
            :on-click-action="() => { return close() }"
          />
        </div>
      </div>
    </template>
  </dialogs-frame>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import DialogsFrame from '@/components/common/DialogsFrame'

import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'
import ButtonIcon from '@/components/common/ButtonIcon'
import Icon from '@/components/common/Icon'
import InputField from '@/components/common/InputField'

export default {
  name: 'ClientApiKeyDialog',
  components: {
    InputField,
    Icon,
    DialogsFrame,
    ButtonBase,
    ButtonIcon
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'clientApiKey',
      cannotClose: true
    }
  },
  computed: {
    ...mapGetters({
      apiKey: 'backendAPI/apiClient/getApiKey'
    })
  },
  methods: {
    ...mapActions({
      clearApiKey: 'backendAPI/apiClient/clearApiKey'
    }),
    copy () {
      this.$copyToClipboard.copy(this.apiKey)
      this.$toasted.show(this.$t('toast.copyToClipboard'), { duration: 7000 })
    },
    close () {
      this.clearApiKey()
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
