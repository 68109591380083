/**
 * @displayName Points
 */
export default {
  name: 'PointsMixin',
  props: {
    points: {
      type: Object | Array,
      required: true
    }
  },
  computed: {
    /**
         * Return relevant points without Z and M point
         * @returns {*}
         */
    mx_points_filteredPoints () {
      const fp = (this.drawing) ? this.points.filter(item => item.type !== 'Z')
        : this.points.filter(item => item.type !== 'M' && item.type !== 'Z')
      if (this.line) {
        fp.push(this.mousePoint)
      }
      return fp
    }
  },
  methods: {
    /**
         * Return array point as object
         * @param polyPoints
         * @returns {*}
         * @public
         */
    mx_points_convertToPolyPoints (polyPoints) {
      return polyPoints.map(item => {
        return { x: item[0], y: item[1] }
      })
    }
  },
  watch: {
    line: {
      handler (val) {
        if (val) {
          this.mousePoint = { x: val.x2, y: val.y2 }
        }
      },
      deep: true
    }
  },
  mounted () {
    if (this.line) {
      this.mousePoint = { x: this.line.x2, y: this.line.y2 }
    }
  },
  data () {
    return {
      mousePoint: { x: 0, y: 0 }
    }
  }
}
