<template>
  <backend>
    <template #dialogs>
      <create-group-dialog />
      <delete-group-dialog />
      <update-group-dialog v-if="isDialogVisible('updateGroup')" />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <button-base
            id="group-create-group"
            :label="$t('backend.groups.newGroup')"
            :on-click-action="() => { return _event() }"
            classes="u-mt-size-40 u-ml-size-0"
            icon-classes="fa fa-plus"
            style-size="s"
          />
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <admin-group-list v-if="isAdmin" />
          <group-list v-else />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>

import Backend from '@/views/Backend'

import CreateGroupDialog from '@/components/backend/groups/dialogs/CreateGroup'
import UpdateGroupDialog from '@/components/backend/groups/dialogs/UpdateGroup'
import DeleteGroupDialog from '@/components/backend/groups/dialogs/DeleteGroup'
import AdminGroupList from '@/components/backend/groups/AdminGroupList'
import GroupList from '@/components/backend/groups/GroupList'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'
import { mapGetters } from 'vuex'
import { roles } from '@/helper/apiHelper'

/**
 * @displayName Groups Component
 */
export default {
  name: 'Groups',
  components: {
    ButtonBase,
    Backend,
    CreateGroupDialog,
    UpdateGroupDialog,
    DeleteGroupDialog,
    AdminGroupList,
    GroupList
  },
  mixins: [MixinDialogHandler],
  computed: {
    ...mapGetters({
      getUserRole: 'backendAPI/user/getRole',
      isDialogVisible: 'dialogs/isDialogVisible'
    }),
    isAdmin () {
      return this.getUserRole === roles.admin
    }
  },
  methods: {
    _event () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'createGroup',
        onOff: true
      })
    }
  }
}
</script>
