import { mapState } from 'vuex'

/**
 * Math Operations
 * @displayName Math
 */
export default {
  name: 'MathMixin',
  computed: {
    ...mapState({
      /** @private **/
      $_mx_math_offset: state => state.editor.viewbox.offset,
      /** @private **/
      $_mx_math_pan: state => state.editor.viewbox.pan,
      /** @private **/
      $_mx_math_scale: state => state.editor.viewbox.scale,
      /** @private **/
      $_mx_math_center: state => state.editor.viewbox.center
    })
  },
  methods: {
    /**
     * Remove "," and return "." for string numbers
     * @param value
     * @returns {number}
     */
    mx_math_replaceDecimalPointWithDot (value) {
      const string = value.toString()
      const n = string.replace(',', '.')

      return parseFloat(n)
    },
    /**
     * Return coordinates from a mouse event
     * @param event
     * @returns {{x: number, y: number}}
     */
    mx_math_getCoordinatesFromEvent (event) {
      const coordinate = { x: 0, y: 0 }
      coordinate.x = (event.targetTouches) ? event.targetTouches[0].clientX : event.clientX
      coordinate.y = (event.targetTouches) ? event.targetTouches[0].clientY : event.clientY

      coordinate.x = this.mx_math_roundDecimal(
        ((parseFloat(coordinate.x) - this.$_mx_math_offset.x) / this.$_mx_math_scale) - (this.$_mx_math_pan.x / 0.5) +
                (this.$_mx_math_center.x - (this.$_mx_math_center.x / this.$_mx_math_scale))
        , 2)
      coordinate.y = this.mx_math_roundDecimal(
        ((parseFloat(coordinate.y) - this.$_mx_math_offset.y) / this.$_mx_math_scale) - (this.$_mx_math_pan.y / 0.5) +
                (this.$_mx_math_center.y - (this.$_mx_math_center.y / this.$_mx_math_scale))
        , 2)

      return coordinate
    },
    /**
     * Return coordinates for point
     * @param point
     * @returns {{x: number, y: number}}
     */
    mx_math_convertPointToCoordinates (point) {
      const coordinate = { x: 0, y: 0 }

      coordinate.x = this.mx_math_roundDecimal(
        ((parseFloat(point.x) - this.$_mx_math_offset.x) / this.$_mx_math_scale) - (this.$_mx_math_pan.x / 0.5) +
                (this.$_mx_math_center.x - (this.$_mx_math_center.x / this.$_mx_math_scale))
        , 2)
      coordinate.y = this.mx_math_roundDecimal(
        ((parseFloat(point.y) - this.$_mx_math_offset.y) / this.$_mx_math_scale) - (this.$_mx_math_pan.y / 0.5) +
                (this.$_mx_math_center.y - (this.$_mx_math_center.y / this.$_mx_math_scale))
        , 2)

      return coordinate
    },
    /**
     * Return event points for context menu
     * @param event
     * @returns {{x: number, y: number}}
     */
    mx_math_getPointerCoordinatesForMetaMenu (event) {
      const coordinate = { x: 0, y: 0 }

      coordinate.x = (event.targetTouches) ? event.targetTouches[0].clientX : event.clientX
      coordinate.y = (event.targetTouches) ? event.targetTouches[0].clientY : event.clientY

      coordinate.x = this.mx_math_roundDecimal(
        parseFloat(coordinate.x) - parseFloat(this.$_mx_math_offset.x)
        , 2)
      coordinate.y = this.mx_math_roundDecimal(
        parseFloat(coordinate.y) - parseFloat(this.$_mx_math_offset.y)
        , 2)

      return coordinate
    },
    /**
     * Modify with when objects are scaled
     * @param value
     * @returns {number|*}
     */
    mx_math_handleElementsOnScale (value) {
      if (this.$_mx_math_scale !== 1.0) {
        return value / this.$_mx_math_scale
      }
      return value
    },
    /**
     * Snap the angle
     * @param {Number} x1
     * @param {Number} y1
     * @param {Number} x2
     * @param {Number} y2
     * @returns {{a: *, x: *, y: *}}
     * @private
     */
    $_mx_math_snapToAngle (x1, y1, x2, y2) {
      const snap = Math.PI / 4
      const dx = x2 - x1
      const dy = y2 - y1
      const angle = Math.atan2(dy, dx)
      const dist = Math.sqrt(dx * dx + dy * dy)
      const snapAngle = Math.round(angle / snap) * snap

      return {
        x: x1 + dist + Math.cos(snapAngle),
        y: y1 + dist + Math.sin(snapAngle),
        a: snapAngle
      }
    },
    /**
     * Calculate point in 45° steps
     * @requires @/mixins/draw
     * @param point
     * @param byPoint
     * @returns {{x: number, y: number}}
     */
    mx_math_ortho (point, byPoint = null) {
      const mouse = {
        x: (byPoint === null) ? this.mx_draw_mouse.x1 : byPoint.x,
        y: (byPoint === null) ? this.mx_draw_mouse.y1 : byPoint.y
      }
      const snap = this.$_mx_math_snapToAngle(mouse.x, mouse.y, point.x, point.y)
      const angle = this.$_mx_math_radiansToDegrees(snap.a)
      let ortho = { x: 0, y: 0 }

      switch (angle) {
        case 0:
        case -0:
          ortho = {
            x: snap.x,
            y: mouse.y
          }
          break
        case 45:
        case -45:
          ortho = {
            x: mouse.x + (snap.x - mouse.x),
            y: (Math.sign(angle) === 1) ? mouse.y + (snap.y - mouse.y) : mouse.y - (snap.y - mouse.y)
          }
          break
        case 90:
        case -90:
          ortho = {
            x: mouse.x,
            y: (Math.sign(angle) === 1) ? snap.y : point.y
          }
          break
        case 135:
        case -135:
          ortho = {
            x: mouse.x - (snap.x - mouse.x),
            y: (Math.sign(angle) === 1) ? mouse.y + (snap.y - mouse.y) : mouse.y - (snap.y - mouse.y)
          }
          break
        case 180:
        case -180:
          ortho = {
            x: point.x,
            y: mouse.y
          }
          break
      }

      return ortho
    },
    /**
     * Calculate degrees from radians
     * @param radians
     * @returns {number}
     * @private
     */
    $_mx_math_radiansToDegrees (radians) {
      return radians * (180 / Math.PI)
    },
    /**
     * Distance between two points p1 and p2
     * @param {Object} p1
     * @param {Object} p2
     * @returns {number}
     */
    mx_math_getDistance (p1, p2) {
      return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2))
    },
    /**
     * Get point by distance an angle
     * @param {Object} p
     * @param {number} angle
     * @param {number} distance
     * @returns {{x: *, y: *}}
     */
    mx_math_getPointByDistance (p, angle, distance) {
      return {
        x: p.x + distance + Math.cos(angle),
        y: p.y + distance + Math.sin(angle)
      }
    },
    /**
     * Returns the point on a line between p1 and p2 with the given distance
     * @param distance
     * @param p1
     * @param p2
     * @returns {null|{x: *, y: *}}
     * @private
     */
    $_mx_math_getPointOnLine (distance, p1, p2) {
      if (distance <= 0) {
        return null
      }
      const x = p1.x - ((distance * (p1.x - p2.x)) / this.mx_math_getDistance(p1, p2))
      const y = p1.y - ((distance * (p1.y - p2.y)) / this.mx_math_getDistance(p1, p2))
      return { x: x, y: y }
    },
    /**
     * Calculate the angle between two lines p1p2 and p2p3
     * @param p1
     * @param p2
     * @param p3
     * @returns {*|number}
     * @private
     */
    $_mx_math_getAngle (p1, p2, p3) {
      return this.$_mx_math_radiansToDegrees(Math.atan2(p1.y - p2.y, p1.x - p2.x) - Math.atan2(p3.y - p2.y, p3.x - p2.x))
    },
    /**
     * Calculate all angles for given form points (fp) with respect to clockwise or counter-clockwise orientation
     * @param {Array} fp Form points
     * @param {Boolean} clockwise
     * @param {Boolean} mask
     * @returns {Array}
     */
    mx_math_calculateAngles (fp, clockwise, mask = false) {
      const angles = []
      if (fp.length >= 3) {
        const max = fp.length - 1
        for (let i = 0; i < fp.length; i++) {
          let value = 0
          if (i === 0) {
            const pPrev = this.$_mx_math_subPointWithHandle(fp[max], fp[i], 2)
            const pNext = this.$_mx_math_subPointWithHandle(fp[i + 1], fp[i + 1], 1)
            value = this.$_mx_math_getAngle(pPrev, fp[i], pNext)
          } else if (i > 0 && i < max) {
            const pPrev = this.$_mx_math_subPointWithHandle(fp[i - 1], fp[i], 2)
            const pNext = this.$_mx_math_subPointWithHandle(fp[i + 1], fp[i + 1], 1)
            value = this.$_mx_math_getAngle(pPrev, fp[i], pNext)
          } else if (i === max) {
            const pPrev = this.$_mx_math_subPointWithHandle(fp[i - 1], fp[i], 2)
            const pNext = this.$_mx_math_subPointWithHandle(fp[0], fp[0], 1)
            value = this.$_mx_math_getAngle(pPrev, fp[i], pNext)
          }

          if (clockwise) {
            value = value < 0 ? 360 - Math.abs(value) : Math.abs(value)
          } else {
            value = value < 0 ? Math.abs(value) : 360 - Math.abs(value)
          }

          if (!mask) {
            angles.push({ a: this.mx_math_roundDecimal(value, 0), x: fp[i].x, y: fp[i].y })
          } else {
            angles.push({ a: this.mx_math_roundDecimal(value, 0), x: fp[i].x, y: fp[i].y })
          }
        }
      }

      return angles
    },
    /**
     * Helper method to get curve handles as points to calc angles
     * @param origPoint
     * @param handlePoint
     * @param handleNr
     * @returns {{x: *, y: *}|*}
     * @private
     */
    $_mx_math_subPointWithHandle (origPoint, handlePoint, handleNr) {
      if (handlePoint.handles && Object.keys(handlePoint.handles).length) {
        const h = handlePoint.handles
        if (handleNr === 2) {
          return { x: h.x2 !== undefined ? h.x2 : h.x1, y: h.y2 !== undefined ? h.y2 : h.y1 }
        } else {
          return { x: h.x1, y: h.y1 }
        }
      } else {
        return origPoint
      }
    },
    /**
     * Rounds a number to decimal
     * @param value
     * @param decimals
     * @returns {number}
     */
    mx_math_roundDecimal (value, decimals = 2) {
      return Number((String(value).indexOf('e') === -1 ? Math.round(Number(String(value) + 'e' + decimals)) : Math.round(value)) + 'e-' + decimals)
    },
    /**
     * Check if form of given points is clockwise
     * @param {Array} fp Form points
     * @returns {boolean}
     */
    mx_math_isClockwise (fp) {
      let sum = 0
      for (let i = 0; i < fp.length; i++) {
        const a = fp[i]
        const b = fp[(i + 1 > fp.length - 1 ? 0 : i + 1)]
        sum += (b.x - a.x) * (b.y + a.y)
      }
      return sum < 0
    },
    /**
     * Calculate the surface area of the form given by points
     * @param {Array} fp Form points
     */
    mx_math_calculateArea (fp) {
      let area = 0.0; const n = fp.length
      for (let i = 0; i < n; i++) {
        area += (fp[i].y + fp[(i + 1) % n].y) * (fp[i].x - fp[(i + 1) % n].x)
      }

      return Math.abs(area) / 2
    },
    /**
     * Calculates the center point of a given form by points
     * @param fp
     * @returns {{x: number, y: number}}
     */
    mx_math_getCenter (fp) {
      let minX, minY, maxX, maxY
      maxX = minX = fp[0].x
      maxY = minY = fp[0].y
      for (let i = 1; i < fp.length; i++) {
        maxX = Math.max(fp[i].x, maxX)
        minX = Math.min(fp[i].x, minX)
        maxY = Math.max(fp[i].y, maxY)
        minY = Math.min(fp[i].y, minY)
      }
      const bottomLeft = { x: minX, y: maxY }
      const topRight = { x: maxX, y: minY }
      return this.$_mx_math_getPointOnLine(this.mx_math_getDistance(bottomLeft, topRight) / 2, bottomLeft, topRight)
    },
    /**
     * Calculates lengths of edges and center points
     * @param {Array} fp
     * @param {Object} measure
     * @return {Array<Object>} lengths
     */
    mx_math_calculateLengthsFromPoints (fp, measure) {
      const res = []; const n = fp.length; let cl = 0; let clt = 0
      for (let i = 0; i < n; i++) {
        const l = this.mx_math_getDistance(fp[i], fp[(i + 1) % n])
        if (measure) {
          cl = this.$_mx_math_getCl(l, measure)
          if (measure.unit !== measure.transformUnit) {
            clt = this.$_mx_math_getClt(cl, measure)
          }
        }
        const center = this.$_mx_math_getPointOnLine(l / 2, fp[i], fp[(i + 1) % n])
        if (center !== null) {
          res.push({
            length: l,
            center: center,
            cl: this.mx_math_roundDecimal(cl, 2),
            clt: this.mx_math_roundDecimal(clt, 2)
          })
        }
      }
      return res
    },
    /**
     * Gets DOM lines and return lengths
     * @param {Array<Object>} lines
     * @param {Object} measure
     */
    mx_math_calculateLengthsFromDomLines (lines, measure) {
      const res = []; const n = lines.length; let cl = 0; let clt = 0
      for (let i = 0; i < n; i++) {
        const item = lines[i]
        const l = item.length
        if (measure) {
          cl = this.$_mx_math_getCl(l, measure)
          if (measure.unit !== measure.transformUnit) {
            clt = this.$_mx_math_getClt(cl, measure)
          }
        }
        res.push({
          length: l,
          center: item.center,
          cl: this.mx_math_roundDecimal(cl, 2),
          clt: this.mx_math_roundDecimal(clt, 2)
        })
      }
      return res
    },
    /**
     * Calculates length in given unit
     * @param val
     * @param measure
     * @returns {number}
     * @private
     */
    $_mx_math_getCl (val, measure) {
      return (val / this.mx_math_replaceDecimalPointWithDot(measure.fromPixels)) * this.mx_math_replaceDecimalPointWithDot(measure.toUnit)
    },
    /**
     * Calculates length in given unit
     * @param val
     * @param measure
     * @returns {number}
     */
    mx_math_getCl (val, measure) {
      return (val / this.mx_math_replaceDecimalPointWithDot(measure.fromPixels)) * this.mx_math_replaceDecimalPointWithDot(measure.toUnit)
    },
    /**
     * Converts val to given "transformUnit", e.g. m -> cm
     * @param val
     * @param measure
     * @returns {number}
     * @private
     */
    $_mx_math_getClt (val, measure) {
      return (val * measure.unit.inches) / measure.transformUnit.inches
    },
    mx_math_getPixelByLength (val, measure) {
      return (parseFloat(val) / this.mx_math_replaceDecimalPointWithDot(measure.toUnit)) * this.mx_math_replaceDecimalPointWithDot(measure.fromPixels)
    },
    mx_math_getPointInDistance (start, mouse, length, measure) {
      const distance = this.mx_math_getPixelByLength(length, measure)

      if (Object.hasOwnProperty.call(mouse, 'x1')) {
        const fi = Math.atan2(mouse.y2 - start.y, mouse.x2 - start.x)

        return {
          x1: mouse.x1,
          y1: mouse.y1,
          x2: start.x + (distance * Math.cos(fi)),
          y2: start.y + (distance * Math.sin(fi))
        }
      }

      if (Object.hasOwnProperty.call(mouse, 'x')) {
        const fi = Math.atan2(mouse.y - start.y, mouse.x - start.x)

        return {
          x: start.x + (distance * Math.cos(fi)),
          y: start.y + (distance * Math.sin(fi))
        }
      }

      return null
    }
  }
}
