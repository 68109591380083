import { mapState } from 'vuex'

import MixinMath from '@/mixins/math'

/**
 * Handles text sizes on zoom
 * Provides computed methods
 * @displayName Text Mixin
 */
export default {
  name: 'TextMixin',
  mixins: [MixinMath],
  computed: {
    ...mapState({
      $_mx_text_commonTextSettings: state => state.common.svg.text
    }),
    /**
         * Font size for area size display
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_fontSizeArea () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.areaTextSize)
    },
    /**
         * Font size length
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_fontSizeLength () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.lengthTextSize)
    },
    /**
         * The font size angle
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_fontSizeAngle () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.angleTextSize)
    },
    /**
         * The stroke width
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_strokeWidth () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.textStroke)
    },
    /**
         * Angle x point
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_angleSpacePointX () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.angleSpace.x)
    },
    /**
         * Angle y point
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_angleSpacePointY () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.angleSpace.y)
    },
    /**
         * Angle space point x
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_lengthSpacePointX () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.lengthSpace.x)
    },
    /**
         * Angle space point y
         * @requires @/mixins/math
         * @returns {*}
         * @public
         */
    mx_text_lengthSpacePointY () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.lengthSpace.y)
    },
    /**
         * The font size superscript area
         * @public
         */
    mx_text_superscriptArea () {
      return this.mx_math_handleElementsOnScale(this.$_mx_text_commonTextSettings.superscript.area)
    }
  }
}
