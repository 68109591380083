/* eslint-disable */
import { mapState } from 'vuex'
import store from '@/vuex/store'
import config from "@/config"

function settingsPlugin(Vue, options) {
  if (settingsPlugin.installed) return

  const DEFAULT_AREA = 'area'
  const DEFAULT_LENGTH_MEDIUM = 'lengthMedium'
  const DEFAULT_LENGTH_SMALL = 'lengthSmall'
  const DEFAULT_PACKAGE = 'package'
  const DEFAULT_VOLUME_LIQUID = 'volumeLiquid'
  const DEFAULT_VOLUME_SOLID = 'volumeSolid'
  const DEFAULT_WEIGHT = 'weight'

  const APP_TURF = 'App\\LocationTurf'
  const APP_SUBBASE = 'App\\LocationSubbase'
  const APP_PAD = 'App\\LocationPad'
  const APP_PEDESTAL = 'App\\LocationPedestal'
  const APP_INFILL = 'App\\LocationInfill'
  const APP_SEAMTAPE = 'App\\LocationSeamtape'
  const APP_ADHESIVE = 'App\\LocationAdhesive'

  const settings = new Vue({
    store,
    data() {
      return {
        units: null,
        measurements: null,
        currency: null
      }
    },
    computed: {
      ...mapState({
        loadedData: state => state.backendAPI.group.settings
      }),
      getAllSettings() {
        return this.$data
      },
      currencySign() {
        return (this.currency && this.hasCurrency()) ? this.currency.sign : '$'
      },
      currencyShort() {
        return (this.currency && this.hasCurrency()) ? this.currency.short : 'USD'
      },
      lengthUnit() {
        return (this.measurements && this.hasMeasurementsProperty(DEFAULT_LENGTH_MEDIUM)) ?
          options.i18n.t(`backend.materials.units.${this.measurements[DEFAULT_LENGTH_MEDIUM].short}`) : ''
      },
      areaUnit() {
        return (this.measurements && this.hasMeasurementsProperty(DEFAULT_AREA)) ?
          options.i18n.t(`backend.materials.units.${this.measurements[DEFAULT_AREA].short}`) : ''
      },
      rulerLengthUnit() {
        return (this.measurements && this.hasMeasurementsProperty(DEFAULT_LENGTH_MEDIUM)) ?
          options.i18n.t(`backend.materials.units.${this.measurements[DEFAULT_LENGTH_MEDIUM].short}`) : ''
      },
      turfUnits() {
        const units = {
          shift: '',
          width: '',
          length: ''
        }

        if (this.units && this.hasUnitsProperty(APP_TURF)) {
          units.shift = options.i18n.t(`backend.materials.units.${this.units[APP_TURF].roll_width.short}`)
          units.width = options.i18n.t(`backend.materials.units.${this.units[APP_TURF].roll_width.short}`)
          units.length = options.i18n.t(`backend.materials.units.${this.units[APP_TURF].roll_length.short}`)
        }

        return units
      },
      subbaseUnits() {
        const units = {
          thickness: '',
          volume: '',
          otherThickness: '',
          padThickness: '',
          padWidth: '',
          padLength: '',
          padShort: '',
          pedestalThickness: '',
          pedestalWidth: '',
          pedestalLength: '',
          pedestalShort: ''
        }

        if (this.units && this.hasUnitsProperty(APP_SUBBASE)) {
          units.otherThickness = options.i18n.t(`backend.materials.units.${this.units[APP_SUBBASE].subbase_thickness.short}`)
          units.volume = options.i18n.t(`backend.materials.units.${this.units[APP_SUBBASE].price_each.short}`)
        }

        if (this.units && this.hasUnitsProperty(APP_PAD)) {
          units.padThickness = options.i18n.t(`backend.materials.units.${this.units[APP_PAD].pad_thickness.short}`)
          units.padWidth = options.i18n.t(`backend.materials.units.${this.units[APP_PAD].pad_width.short}`)
          units.padLength = options.i18n.t(`backend.materials.units.${this.units[APP_PAD].pad_length.short}`)
          units.padShort = options.i18n.t(`backend.materials.units.${this.units[APP_PAD].price_each.short}`)
        }

        if (this.units && this.hasUnitsProperty(APP_PEDESTAL)) {
          units.pedestalThickness = options.i18n.t(`backend.materials.units.${this.units[APP_PEDESTAL].pedestal_thickness.short}`)
          units.pedestalWidth = options.i18n.t(`backend.materials.units.${this.units[APP_PEDESTAL].pedestal_width.short}`)
          units.pedestalLength = options.i18n.t(`backend.materials.units.${this.units[APP_PEDESTAL].pedestal_length.short}`)
          units.pedestalShort = options.i18n.t(`backend.materials.units.${this.units[APP_PEDESTAL].price_each.short}`)
        }

        if (this.measurements && this.hasMeasurementsProperty(DEFAULT_LENGTH_SMALL)) {
          units.thickness = options.i18n.t(`backend.materials.units.${this.measurements[DEFAULT_LENGTH_SMALL].short}`)
        }

        return units
      },
      infillUnits() {
        const units = {
          recommended_weight: '',
          weight_packaging: '',
          weight_total: ''
        }

        if (this.units && this.hasUnitsProperty(APP_INFILL)) {
          units.recommended_weight = options.i18n.t(`backend.materials.units.${this.units[APP_INFILL].infill_recommended_weight.short}`)
          units.weight_packaging = options.i18n.t(`backend.materials.units.${this.units[APP_INFILL].infill_weight_packaging.short}`)
        }

        if (this.measurements && this.hasMeasurementsProperty(DEFAULT_WEIGHT)) {
          units.weight_total = options.i18n.t(`backend.materials.units.${this.measurements[DEFAULT_WEIGHT].short}`)
        }

        return units
      }
    },
    watch: {
      loadedData: {
        handler: function (n, o) {
          this.units = (n !== null) ? n.units : null
          this.currency = null
          if (n !== null) {
            const currencyIndex = config.currencySystem.findIndex(el => el.short === n.currency_short)
            const currencySign = config.currencySystem[currencyIndex].label
            this.currency = {
              short: n.currency_short,
              sign: currencySign
            }
          }
          this.measurements = (n !== null) ? n.measurements : null
        },
        deep: true
      }
    },
    methods: {
      hasUnits() {
        return this.units !== null
      },
      hasCurrency() {
        return this.currency !== null
      },
      hasMeasurements() {
        return this.measurements !== null
      },
      hasUnitsProperty(property) {
        return Object.prototype.hasOwnProperty.call(this.units, property)
      },
      hasMeasurementsProperty(property) {
        return Object.prototype.hasOwnProperty.call(this.measurements, property)
      },
      hasCurrencyProperty(property) {
        return Object.prototype.hasOwnProperty.call(this.currency, property)
      },
      getDefault() {
        if (this.measurements) {
          return this.measurements
        }

        return null
      },
      getTurf() {
        if (this.units && this.hasUnitsProperty(APP_TURF)) {
          return this.units[APP_TURF]
        }

        return null
      },
      getSubbase() {
        if (this.units && this.hasUnitsProperty(APP_SUBBASE)) {
          return this.units[APP_SUBBASE]
        }

        return null
      },
      getPad() {
        if (this.units && this.hasUnitsProperty(APP_PAD)) {
          return this.units[APP_PAD]
        }

        return null
      },
      getPedestal() {
        if (this.units && this.hasUnitsProperty(APP_PEDESTAL)) {
          return this.units[APP_PEDESTAL]
        }

        return null
      },
      getInfill() {
        if (this.units && this.hasUnitsProperty(APP_INFILL)) {
          return this.units[APP_INFILL]
        }

        return null
      },
      getSeamtape() {
        if (this.units && this.hasUnitsProperty(APP_SEAMTAPE)) {
          return this.units[APP_SEAMTAPE]
        }

        return null
      },
      getAdhesive() {
        if (this.units && this.hasUnitsProperty(APP_ADHESIVE)) {
          return this.units[APP_ADHESIVE]
        }

        return null
      },
      getCurrency() {
        if (this.currency) {
          return this.currency
        }

        return null
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$settings', {
    get() {
      return settings
    }
  })

  Vue.settings = Vue.prototype.$settings
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(settingsPlugin)
}

export default settingsPlugin
