<template>
  <div>
    <div class="c-backend__new-button-area">
      <button-base
        id="seamtape-create-seamtape"
        :label="$t('backend.materials.newSeamtape')"
        :on-click-action="() => { return openDialog({type: 'createSeamtape'}) }"
        classes="u-ml-size-0 u-mt-size-0 u-mb-size-0"
        icon-classes="fa fa-plus"
        style-size="s"
      />
      <material-csv :by-type="csvType" />
    </div>
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: 20,
        perPageDropdown: [10, 20, 50],
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: texts.pageLabel, // for 'pages' mode
        allLabel: texts.allLabel,
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'name', type: 'asc'}]
      }"
      theme="melos"
    >
      <template #emptystate>
        <div
          class="u-m-size-40 c-backend__nothing-to-show"
        >
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open" />
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template #table-column="props">
        <span v-if="props.column.field ==='_rowSelected' && rows.length">
          <span class="c-backend__select-all">
            <input-checkbox
              id="seamtapes-select-all"
              v-model="isSelectAllRows"
              :is-store-mode="false"
              style-component="2"
            />
          </span>
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <template #table-row="props">
        <span v-if="props.column.field ==='_rowSelected'">
          <input-checkbox
            :id="`seamtapes-row-checked-${props.row.originalIndex}`"
            :key="`seamtapes-row-checked-${props.row.originalIndex}`"
            v-model="rows[props.row.originalIndex]._rowSelected"
            classes="c-backend__select"
            :is-store-mode="false"
            style-component="2"
          />
        </span>

        <span v-if="props.column.field ==='assets.image'">
          <span
            v-if="props.row.assets.image !== null"
            :style="`background-image: url('${props.row.assets.image.conversions.thumb}');`"
            class="vgt-icon"
          />
        </span>

        <span v-if="props.column.field ==='pim_id'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='name'">
          {{ mx_product_in_language(props.row, ['name']) }}
        </span>

        <span v-if="props.column.field ==='description'">
          {{ mx_product_in_language(props.row, ['description']) }}
        </span>

        <span v-if="props.column.field ==='seamtape_width'">
          {{ props.formattedRow[props.column.field] }} {{ $t(`backend.materials.units.${seamtapeSettings.seamtape_width.short}`) }}
        </span>

        <span v-if="props.column.field ==='seamtape_length'">
          {{ props.formattedRow[props.column.field] }} {{ $t(`backend.materials.units.${seamtapeSettings.seamtape_length.short}`) }}
        </span>

        <!-- Buttons -->
        <span
          v-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <button-base
            :id="`seamtape-edit-seamtape-${props.row.originalIndex}`"
            :on-click-action="() => { return setAndOpenDialog(props.row) }"
            :tooltip-disabled="false"
            classes="vgt-button u-ml-size-10"
            icon-classes="fa fa-edit"
          >
            <template #popup>
              {{ $t('common.edit') }}
            </template>
          </button-base>
          <button-base
            :id="`others-delete-other-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({ type: 'deleteMaterial', data: [props.row] }) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>
      </template>
    </vue-good-table>
    <button-base
      id="seamtapes-delete-selected"
      :disabled="!getSelectedRows.length"
      :label="$t('backend.materials.deleteSeamtapes')"
      :on-click-action="() => { return openDialog({ type: 'deleteMaterial', data: getSelectedRows }) }"
      classes="c-button-base--alt-disabled u-ml-size-0 u-mb-size-100"
      icon-classes="fa fa-trash"
      style-size="s"
    />
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import { csvTypes } from '@/helper/apiHelper'

import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

import MaterialCsv from '@/components/backend/materials/partials/MaterialCsv'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'
import InputCheckbox from '@/components/common/InputCheckbox'

/**
 * @displayName Materials Seamtapes List
 */
export default {
  name: 'MaterialsSeamtapes',
  components: {
    InputCheckbox,
    Icon,
    ButtonBase,
    VueGoodTable,
    MaterialCsv
  },
  mixins: [MixinDialogHandler, MixinProductInLanguage],
  data: () => {
    return {
      csvType: csvTypes.seamtape,
      rows: [],
      isSelectAllRows: false
    }
  },
  computed: {
    ...mapGetters({
      hasSpecialSeamtapes: 'backendAPI/product/seamtape/hasSpecialSeamtapes',
      getSpecialSeamtapes: 'backendAPI/product/seamtape/getSpecialSeamtapes',
      getSettings: 'backendAPI/group/getSettings'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    },
    seamtapeSettings () {
      return this.getSettings.units['App\\Seamtape']
    },
    columns () {
      return [
        {
          field: '_rowSelected',
          sortable: false,
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          field: 'settings',
          sortable: false,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          field: 'assets.image',
          sortable: false,
          html: true,
          tdClass: 'vgt-icon-container',
          thClass: 'vgt-no-min-width'
        },
        {
          label: this.$i18n.t('backend.materials.seamtapeTableLabel.id'),
          field: 'pim_id'
        },
        {
          label: this.$i18n.t('backend.materials.seamtapeTableLabel.name'),
          field: 'name'
        },
        {
          label: this.$i18n.t('backend.materials.seamtapeTableLabel.description'),
          field: 'description'
        },
        {
          label: this.$i18n.t('backend.materials.seamtapeTableLabel.width', { unit: this.$i18n.t(`backend.materials.units.${this.seamtapeSettings.seamtape_width.short}`) }),
          field: 'seamtape_width',
          sortable: false
        },
        {
          label: this.$i18n.t('backend.materials.seamtapeTableLabel.length', { unit: this.$i18n.t(`backend.materials.units.${this.seamtapeSettings.seamtape_length.short}`) }),
          field: 'seamtape_length',
          sortable: false
        }
      ]
    },
    getSelectedRows () {
      return this.rows.filter(row => row._rowSelected === true)
    }
  },
  watch: {
    getSpecialSeamtapes: {
      handler (n) {
        this.rows = [...n].map(row => ({ ...row, _rowSelected: false }))
      },
      deep: true
    },
    isSelectAllRows: {
      handler (n) {
        for (const row of this.rows) {
          row._rowSelected = n
        }
      }
    }
  },
  mounted () {
    this.rows = [...this.getSpecialSeamtapes].map(row => ({ ...row, _rowSelected: false }))
  },
  methods: {
    ...mapActions({
      setSpecialToUpdate: 'backendAPI/product/seamtape/setSpecialToUpdate'
    }),
    setAndOpenDialog (payload) {
      this.setSpecialToUpdate(payload)
      this.openDialog({ type: 'updateSeamtape', data: payload })
    },
    openDialog (payload) {
      this.$emit('delete-material', 'Seamtape')
      this.$_mx_dialogHandler_openDialog({
        dialog: payload.type,
        dialogProps: { data: payload.data },
        onOff: true
      })
    }
  }
}
</script>
