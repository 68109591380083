import _ from 'lodash'
import * as types from '@/vuex/mutation-types'
import { productTypes } from '@/helper/api/types'
import { PathFactory } from '@/classes/objects'

const updateProjectState = (projectState, relatedProducts) => {
  const stateObject = JSON.parse(projectState)

  stateObject.objects.map((obj, index) => {
    if (obj.product !== null) {
      const turfVersion = $_get_related_product_version(obj.product.id, relatedProducts.turfs)

      if (turfVersion !== null) {
        obj.product.version_nr = turfVersion
      }
      if (obj.product.seamtape !== null) {
        const seamTapeVersion = $_get_related_product_version(obj.product.seamtape.id, relatedProducts.seamtapes)

        if (seamTapeVersion !== null) {
          obj.product.seamtape.version_nr = seamTapeVersion
        }
      }
      if (obj.product.adhesive !== null) {
        const adhesiveVersion = $_get_related_product_version(obj.product.adhesive.id, relatedProducts.adhesives)
        if (adhesiveVersion !== null) {
          obj.product.adhesive.version_nr = adhesiveVersion
        }
      }
      if (obj.product.subbases.length > 0) {
        const subbases = obj.product.subbases
        subbases.map(subbase => {
          let subbaseVersion = null
          switch (subbase.type) {
            case productTypes.PRODUCT_OTHER_SUBBASE_TYPE:
              subbaseVersion = $_get_related_product_version(subbase.id, relatedProducts.subbases)
              break
            case productTypes.PRODUCT_PAD_SUBBASE_TYPE:
              subbaseVersion = $_get_related_product_version(subbase.id, relatedProducts.pads)
              break
            case productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE:
              subbaseVersion = $_get_related_product_version(subbase.id, relatedProducts.pedestals)
              break
          }

          if (subbaseVersion !== null) {
            subbase.version_nr = subbaseVersion
          }

          return subbase
        })

        obj.product.subbases = subbases
      }

      if (obj.product.infills.length > 0) {
        const infills = obj.product.infills
        infills.map(infill => {
          const infillVersion = $_get_related_product_version(infill.id, relatedProducts.infills)

          if (infillVersion !== null) {
            infill.version_nr = infillVersion
          }
          return infill
        })

        obj.product.infills = infills
      }
    }

    return obj
  })

  return JSON.stringify(stateObject)
}

const $_get_related_product_version = (productId, data) => {
  const key = _.findKey(data, (o) => {
    return o.originalId === productId
  })

  return (key !== undefined) ? data[key].version_nr : null
}

/**
 * Return update data for products update
 *
 * @param rootGetters
 * @param relatedProducts
 * @returns {{need: boolean, state: {seamtapes: [], pads: [], adhesives: [], turfs: [], infills: [], pedestals: [], subbases: []}}}
 */
const checkRelatedProduct = (rootGetters, relatedProducts) => {
  const newRelatedProductsState = $_get_related_products_state()
  let needUpdate = false

  for (const [key] of Object.entries(newRelatedProductsState)) {
    if (Object.prototype.hasOwnProperty.call(relatedProducts, key) && relatedProducts[key].length > 0) {
      relatedProducts[key].forEach(rp => {
        let product = null
        switch (key) {
          case 'turfs':
            product = rootGetters['products/getProductById'](rp.originalId)
            break
          case 'infills':
            product = rootGetters['products/getInfillById'](rp.originalId)
            break
          case 'subbases':
            product = rootGetters['products/getSubbaseByIdType']({
              id: rp.originalId,
              type: productTypes.PRODUCT_OTHER_SUBBASE_TYPE
            })
            break
          case 'pads':
            product = rootGetters['products/getSubbaseByIdType']({
              id: rp.originalId,
              type: productTypes.PRODUCT_PAD_SUBBASE_TYPE
            })
            break
          case 'pedestals':
            product = rootGetters['products/getSubbaseByIdType']({
              id: rp.originalId,
              type: productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE
            })
            break
          case 'seamtapes':
            product = rootGetters['products/getSeamtapeById'](rp.originalId)
            break
          case 'adhesives':
            product = rootGetters['products/getAdhesiveById'](rp.originalId)
            break
        }

        if (product !== null && typeof product !== 'undefined') {
          if (product.versioning.update_needed && typeof product.versioning.latest !== 'undefined') {
            rp.version_nr = product.versioning.latest
            needUpdate = true
          }
        }
        newRelatedProductsState[key].push(rp)
      })
    }
  }

  return {
    state: newRelatedProductsState,
    need: needUpdate
  }
}

/**
 * Returns objects state and related products of the project to be saved
 *
 * @param rootState
 * @returns {{related: ({pads: *[], turfs: *[], infills: *[], pedestals: *[], subbases: *[]}|*[]), state: {objects: *, lastObjectId: (number|lastObjectId), layers: *, measurement: *}}}
 */
const getProjectState = (rootState) => {
  const objects = _.cloneDeep(rootState.project.objects.objects)
  objects.map(object => {
    delete object.selectionMode
    return object
  })

  return {
    state: {
      additionalCosts: rootState.project.additionalCosts,
      lastObjectId: rootState.project.lastObjectId,
      measurement: _.cloneDeep(rootState.project.measurement),
      objects: objects,
      layers: _.cloneDeep(rootState.project.layers),
      underlayVisibility: rootState.files.show.underlay
    },
    related_products: $_get_related_products(objects)
  }
}

/**
 * Restore objects and underlay from project state data
 *
 * @param project
 * @param projectId
 * @param dispatch
 * @param commit
 * @returns {Promise<void>}
 */
const restoreProjectState = async (project, projectId, dispatch, commit) => {
  if (project !== null) {
    const stateObject = JSON.parse(project.state)

    if (stateObject !== null) {
      commit('project/' + types.SET_FROM_HISTORY, {
        lastObjectId: stateObject.lastObjectId,
        measurement: stateObject.measurement,
        layers: stateObject.layers,
        additionalCosts: (stateObject.additionalCosts !== undefined && stateObject.additionalCosts) ? stateObject.additionalCosts.map(c => {
          c.id = null
          return c
        }) : []
      }, { root: true })

      if (stateObject.objects) {
        const restoredObjects = PathFactory.createFromJsonObjects(stateObject.objects)
        commit('project/objects/' + types.SET_FROM_HISTORY, { objects: restoredObjects }, { root: true })
      }
      if (Object.hasOwnProperty.call(stateObject, 'underlayVisibility')) {
        commit('files/' + types.SET_FROM_HISTORY, {
          show: { underlay: stateObject.underlayVisibility }
        }, { root: true })
      }
    }

    if (project.underlay !== null) {
      const underlay = await dispatch('getUnderlay', projectId)
      const currentSurfaceUnderlay = await dispatch(
        'files/loadBase64RemoteImage', {
          base64: underlay.base64
        }, { root: true })

      commit('files/' + types.SET_FROM_HISTORY, {
        currentSurfaceUnderlay: currentSurfaceUnderlay,
        rawDataSurfaceUnderlay: underlay
      }, { root: true })
    }
  }
}

/**
 *
 * @param objects
 * @returns {{pads: [], turfs: [], infills: [], pedestals: [], subbases: []}|*[]}
 */
const $_get_related_products = (objects) => {
  const relatedProducts = $_get_related_products_state()

  if (objects.length > 0) {
    const filtered = objects.filter(object => object.product !== null)

    filtered.forEach(object => {
      const turf = { id: object.product.overlay_id, version_nr: object.product.version_nr, originalId: object.product.id }

      if (relatedProducts.turfs.find(rpTurf => rpTurf.id === turf.id) === undefined) {
        relatedProducts.turfs.push(turf)
      }

      // unique infills
      const uniqueInfills = $_unique_objects(object.product.infills)

      // add each infill to related products if not found
      uniqueInfills.forEach(infill => {
        if (relatedProducts.infills.find(item => item.id === infill.id) === undefined) {
          relatedProducts.infills.push({ id: infill.overlay_id, version_nr: infill.version_nr, originalId: infill.id })
        }
      })

      if (object.product.subbases.length > 0) {
        // unique subbases
        const uniqueSubbases = $_unique_objects(object.product.subbases)

        // group subbases by product type
        const subbases = _.transform(_.groupBy(uniqueSubbases, 'type'), (result, values, key) => {
          result[`${key.toLowerCase()}s`] = _.transform(values, (result, val, key) => {
            result[key] = { id: val.overlay_id, version_nr: val.version_nr, originalId: val.id }
          })
        })

        // add subbases to related products
        for (const key in subbases) {
          if (Object.prototype.hasOwnProperty.call(relatedProducts, key)) {
            relatedProducts[key] = subbases[key]
          }
        }
      }

      if (object.product.seamtape !== null) {
        relatedProducts.seamtapes = [{
          id: object.product.seamtape.overlay_id,
          version_nr: object.product.seamtape.version_nr,
          originalId: object.product.seamtape.id
        }]
      }

      if (object.product.adhesive !== null) {
        relatedProducts.adhesives = [{
          id: object.product.adhesive.overlay_id,
          version_nr: object.product.adhesive.version_nr,
          originalId: object.product.adhesive.id
        }]
      }
    })

    return relatedProducts
  }

  return []
}

const $_get_related_products_state = () => {
  return {
    turfs: [],
    infills: [],
    subbases: [],
    pads: [],
    pedestals: [],
    seamtapes: [],
    adhesives: []
  }
}

const $_unique_objects = (objects) => {
  return objects.map(JSON.stringify).reverse()
    .filter((item, index, objects) => objects.indexOf(item, index + 1) === -1)
    .reverse().map(JSON.parse)
}

export { updateProjectState, checkRelatedProduct, getProjectState, restoreProjectState }
