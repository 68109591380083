<template>
  <panel-container :title=" $t('editor.lineOfSight.product.angle.title') ">
    <input-slider
      id="product-angle"
      v-model="angle"
      :classes="['u-mb-size-standard', 'u-mt-size-standard']"
      :max="360"
      :min="0"
      suffix="°"
      step=".01"
      @mouseup="updateSlider"
    />

    <button-base
      v-if="getObject.type !== config.objects.types.CIRCLE"
      id="activeSnap"
      :label="$t('editor.lineOfSight.product.angle.snap')"
      :on-click-action="() => { return selectObjectLine() }"
      classes="u-mr-size-0 u-mt-size-0"
      style-min-width="180px"
    />

    <div
      v-if="error"
      class="c-los__warning"
      v-html="$t('editor.lineOfSight.product.angle.warning')"
    />
  </panel-container>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'lodash'

import config from '@/config'

import PanelContainer from '@/components/common/PanelContainer'
import InputSlider from '@/components/common/InputSlider'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * Configure the angle
     * @displayName LineOfSight Panel - Product - Angle
     */
export default {
  name: 'ProductAngle',
  components: {
    ButtonBase,
    PanelContainer,
    InputSlider
  },
  data: () => {
    return {
      config: config,
      error: false
    }
  },
  computed: {
    ...mapGetters({
      getObject: 'lineOfSightNew/getObject',
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    angle: {
      get () {
        return _.clone(this.getObjectProduct.angle)
      },
      set (payload) {
        this.error = payload.error
        if (!payload.error) {
          if (payload.el === 'slider') this.$events.fire('LINE_OF_SIGHT_SHOW_PSEUDO_TRACKS', { angle: Number(payload.value) })
          if (payload.el === 'input') this.$emit('changeProductAngle', { action: 'changeProductAngle', data: { angle: Number(payload.value) } })
        }
      }
    }
  },
  methods: {
    selectObjectLine () {
      this.$events.fire('LINE_OF_SIGHT_SELECT_OBJECT_LINE', true)
    },
    updateSlider (value) {
      this.$events.fire('LINE_OF_SIGHT_HIDE_PSEUDO_TRACKS')
      this.$emit('changeProductAngle', { action: 'changeProductAngleBySlider', data: { angle: Number(value) } })
    }
  }
}
</script>
