<template>
  <backend>
    <template #dialogs>
      <create-distributor-dialog />
      <downgrade-distributor-dialog />
      <delete-distributor-dialog />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <button-base
            id="distributor-create-distributor"
            :label="$t('backend.distributors.newDistributor')"
            :on-click-action="() => { return _event() }"
            classes="u-mt-size-40 u-ml-size-0"
            icon-classes="fa fa-plus"
            style-size="s"
          />
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <distributor-list />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'

import CreateDistributorDialog from '@/components/backend/distributors/dialogs/CreateDistributor'
import DowngradeDistributorDialog from '@/components/backend/distributors/dialogs/DowngradeDistributor'
import DeleteDistributorDialog from '@/components/backend/distributors/dialogs/DeleteDistributor'
import DistributorList from '@/components/backend/distributors/DistributorList'

import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Distributors Component
     */
export default {
  name: 'Distributors',
  components: {
    ButtonBase,
    Backend,
    CreateDistributorDialog,
    DowngradeDistributorDialog,
    DeleteDistributorDialog,
    DistributorList
  },
  mixins: [MixinDialogHandler],
  methods: {
    _event () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'createDistributor',
        onOff: true
      })
    }
  }
}
</script>
