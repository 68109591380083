<template>
  <dialogs-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createApiClient.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createApiClient.title') "
  >
    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-min c-dialog__instruction--full-width">
        <div class="o-grid o-grid--center">
          <div class="o-grid__col u-4/4 u-text-center">
            <form class="c-form">
              <input-field
                id="create-api-client-username"
                v-model="apiClient.name"
                :focus-on-mount="false"
                :is-store-mode="false"
                :label="$t('dialogs.createApiClient.form.username')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @input="$_trim"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-client-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-client-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewApiClient() }"
          />
        </div>
      </div>
    </template>
  </dialogs-frame>
</template>
<script>
import { apiMethods } from '@/helper/apiHelper'
import { mapActions, mapGetters } from 'vuex'

import DialogsFrame from '@/components/common/DialogsFrame'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'

export default {
  name: 'CreateApiClientDialog',
  components: {
    DialogsFrame,
    ButtonBase,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      targetDialog: 'createApiClient',
      cannotClose: false,
      apiClient: apiMethods.getModel('apiClient')
    }
  },
  computed: {
    ...mapGetters({
      me: 'backendAPI/user/get'
    })
  },
  methods: {
    ...mapActions({
      createApiClient: 'backendAPI/apiClient/create'
    }),
    async createNewApiClient () {
      this.apiClient.group_id = this.me.group.id
      this.createApiClient(this.apiClient)
      this.close()
    },
    close () {
      this.apiClient = apiMethods.getModel('apiClient')
      this.mx_dialogs_closeDialog()
    },
    $_trim (input) {
      this.apiClient.username = input.replace(' ', '')
    }
  }
}
</script>
