<template>
  <div class="c-los__panel-item">
    <panel-container v-if="allowedToSplit && !isWasteObjectSet && !isWasteTrack">
      <button-base
        id="product-track-information-split"
        :disabled="getSelectedTracks.length > 1"
        :label="$t('editor.lineOfSight.track.split')"
        :on-click-action="() => { return splitSelectedTrack() }"
        classes="u-mr-size-0"
        style-min-width="180px"
      />
    </panel-container>
    <panel-container :title=" $t('editor.lineOfSight.track.selected.title') ">
      <div class="c-los__tracks">
        <table>
          <tr>
            <th>{{ $t('editor.lineOfSight.track.selected.name') }}</th>
            <th>{{ $t('editor.lineOfSight.track.selected.width') }}</th>
            <th>{{ $t('editor.lineOfSight.track.selected.length') }}</th>
            <th>{{ $t('editor.lineOfSight.track.selected.oversize') }}</th>
          </tr>
          <tr
            v-for="(track, key) in getSelectedTracks"
            :key="key"
          >
            <td
              v-for="(info, infoIndex) in track.getInformation()"
              :key="infoIndex"
            >
              {{ info }}
            </td>
          </tr>
        </table>
      </div>
    </panel-container>
    <panel-container
      v-if="!isWasteObjectSet && !isWasteTrack"
      :title=" $t('editor.lineOfSight.track.hillValley') "
    >
      <hill-valley-configuration
        v-if="hillValley !== null"
        :length="hillValley.length"
        :max-length="getObjectProduct.length"
        :name="hillValley.name"
        :unit="getObjectProduct.unit"
        @oversizeChangedByConfigurator="updateOversize"
      />

      <input-field
        id="track-oversize"
        :key="`to-${getOversize}`"
        v-model="oversize"
        :isStoreMode="false"
        :label="$t('editor.lineOfSight.track.oversize', {unit: $t(`backend.materials.units.${getObjectProduct.unit}`)})"
        :validate="(v) => {
          return mx_validate_validateNotNegativeNumber(v)
            && v <= getObjectProduct.length - getLongestTrackBySelected.length
        }"
        type="number"
        @change="setOversize"
      />

      <button-base
        id="product-track-information-set-oversize"
        :disabled="error"
        :label="$t('common.save')"
        :on-click-action="() => { return setTrackOversize() }"
        classes="u-mr-size-0"
        style-min-width="180px"
      />

      <div
        v-if="error"
        class="c-los__warning"
        v-html="$t('editor.lineOfSight.track.warning',
                   {
                     unit: $t(`backend.materials.units.${getObjectProduct.unit}`),
                     maxLength: mx_math_roundDecimal(getObjectProduct.length - getLongestTrackBySelected.length, 2)
                   })"
      />
    </panel-container>
    <panel-container>
      <div
        v-if="hillValley !== null"
        class="c-los__labeling"
      >
        <h3>{{ $t('editor.lineOfSight.track.totalLength') }}</h3>
        <h3>{{ totalLength }} {{ $t(`backend.materials.units.${getObjectProduct.unit}`) }}</h3>
      </div>
    </panel-container>
    <panel-container
      v-if="getSelectedTracks.length === 1"
      :title="$t('editor.lineOfSight.track.waste.title')"
    >
      <los-waste-management :track="getSelectedTracks[0]" />
    </panel-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import paperjs from 'paper'
import PaperLayer from '@/classes/paperLayer'
import { TurfTrack } from '@/classes/products'

import HillValleyConfiguration from '@/components/modules/lineOfSight/tools/HillValleyConfiguration'
import LosWasteManagement from '@/components/modules/lineOfSight/tools/LosWasteManagement'
import PanelContainer from '@/components/common/PanelContainer'
import InputField from '@/components/common/InputField'

import _ from 'lodash'

import MixinMath from '@/mixins/math'
import MixinClockwise from '@/mixins/clockwise'
import MixinValidate from '@/mixins/validate'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * Displays all information of the selected track
 * @displayName LineOfSight Panel - Product - Track Information
 */
export default {
  name: 'ProductTrackInformation',
  components: {
    ButtonBase,
    HillValleyConfiguration,
    PanelContainer,
    InputField,
    LosWasteManagement
  },
  mixins: [
    MixinMath,
    MixinClockwise,
    MixinValidate
  ],
  data: () => {
    return {
      error: false,
      oversize: 0
    }
  },
  computed: {
    ...mapGetters({
      getMeasure: 'project/measurement/getMeasure',
      getObjectProduct: 'lineOfSightNew/getObjectProduct',
      getSelectedTracks: 'lineOfSightNew/getSelectedTracks',
      getLongestTrackBySelected: 'lineOfSightNew/getLongestTrackBySelected',
      isWasteObjectSet: 'lineOfSightNew/isWasteObjectSet'
    }),
    hillValley () {
      if (this.getSelectedTracks.length === 1) {
        return this.getSelectedTracks[0]
      }
      return null
    },
    totalLength () {
      return (
        parseFloat(this.getSelectedTracks[0].length) +
        parseFloat(this.getSelectedTracks[0].oversize)
      ).toFixed(2)
    },
    allowedToSplit () {
      return Number(this.getSelectedTracks[0].length) >= Number(this.getObjectProduct.threshold) * 2
    },
    isWasteTrack () {
      let isWaste = false
      if (this.getSelectedTracks.length === 1) {
        isWaste = this.getSelectedTracks[0].usedWaste
      } else {
        isWaste = this.getSelectedTracks.some(track => track.usedWaste)
      }
      return isWaste
    },
    getOversize () {
      return (this.getSelectedTracks.length === 1) ? this.getSelectedTracks[0].oversize : 0
    }
  },
  watch: {
    getSelectedTracks: {
      handler: function (n, o) {
        this.$nextTick(() => {
          this.oversize = (this.getSelectedTracks.length === 1) ? n[0].oversize : 0
        })
      },
      deep: true
    }
  },
  mounted () {
    this.oversize = (this.getSelectedTracks.length === 1) ? this.getSelectedTracks[0].oversize : 0
  },
  methods: {
    ...mapActions({
      updateObjectProductTrackBySplit: 'lineOfSightNew/updateObjectProductTrackBySplit',
      updateObjectProductTracksByOversize: 'lineOfSightNew/updateObjectProductTracksByOversize'
    }),
    updateOversize (data) {
      this.oversize = data.oversize
    },
    setOversize (data) {
      this.error = data.error
    },
    setTrackOversize () {
      this.updateObjectProductTracksByOversize(this.oversize)
    },
    splitSelectedTrack () {
      const turfTrackToSplit = _.cloneDeep(this.getSelectedTracks[0])

      const dataToUpdate = this.$_divide_track_points(turfTrackToSplit.getPoints())

      const newTurfTrack = new TurfTrack('R' + Number(this.getObjectProduct.lastTrackId + 1) + '.1')
      newTurfTrack.setPoints(dataToUpdate.newTrackPoints)
      newTurfTrack.setWidth(turfTrackToSplit.getWidth())
      newTurfTrack.setLength(dataToUpdate.newTrackLength.toFixed(2))

      this.updateObjectProductTrackBySplit({
        turfTrack: {
          name: turfTrackToSplit.name,
          length: dataToUpdate.originalLength.toFixed(2),
          points: dataToUpdate.original
        },
        new: newTurfTrack
      })
    },
    $_get_new_track_length (value) {
      return (value / this.mx_math_replaceDecimalPointWithDot(this.getMeasure.fromPixels)) * this.mx_math_replaceDecimalPointWithDot(this.getMeasure.toUnit)
    },
    $_divide_track_points (points) {
      const mappedPoints = points.filter(p => p.type !== 'M' && p.type !== 'Z').map(p => {
        return new paperjs.Point(p.x, p.y)
      })

      // create path and add mapped points
      const pathToSplit = new paperjs.Path()
      mappedPoints.forEach(p => {
        pathToSplit.add(p)
      })

      const orgCenter = pathToSplit.bounds.clone().center

      pathToSplit.closed = true
      pathToSplit.rotate(-Number(this.getObjectProduct.angle))

      // create cut line
      const cut = new paperjs.Path()
      cut.add(pathToSplit.bounds.rightCenter)
      cut.add(pathToSplit.bounds.leftCenter)
      cut.closed = true

      // get intersections between path and cut
      const intersections = pathToSplit.getIntersections(cut)

      const targets = []
      intersections.forEach(location => {
        const target = pathToSplit.splitAt(location)
        if (target !== null) {
          targets.push(target)
        }
      })

      const t1 = targets[0].unite(cut)
      const t2 = targets[1].unite(cut)

      const t1Length = t1.bounds.height
      const t2Length = t2.bounds.height

      t1.rotate(Number(this.getObjectProduct.angle), orgCenter)
      t2.rotate(Number(this.getObjectProduct.angle), orgCenter)

      const o = PaperLayer.makePointsFromPaperSegments(t1.segments)
      const n = PaperLayer.makePointsFromPaperSegments(t2.segments)

      pathToSplit.remove()
      t1.remove()
      t2.remove()
      cut.remove()

      return {
        original: o,
        originalLength: this.mx_math_roundDecimal(this.$_get_new_track_length(t1Length)),
        newTrackPoints: n,
        newTrackLength: this.mx_math_roundDecimal(this.$_get_new_track_length(t2Length))
      }
    }
  }
}
</script>
