import { mapState, mapGetters, mapActions } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'
import config from '@/config'

/**
 * @displayName Object
 */
export default {
  name: 'ObjectMixin',
  data () {
    return {
      mx_object_lastMouseDown: null,
      mx_object_dragover: false,
      mx_object_nearestPointToMouse: null,
      mx_object_lastMouseDownPan: null
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.PROJECT_CLEAR_OBJECT_SELECTION, () => {
      this.setSelectionMode({ object: this.object, selectionMode: config.objects.modes.UNSELECTED })
    })
    this.$events.on(editorEventTypes.PRODUCT_STOP_DRAGGING, (evt) => {
      this.mx_object_dragover = false
    })
  },
  computed: {
    ...mapState({
      cutMode: state => state.events.mode.cut,
      losMode: state => state.events.mode.los,
      pan: state => state.editor.viewbox.pan,
      scale: state => state.editor.viewbox.scale
    }),
    ...mapGetters({
      isWasteObjectSet: 'lineOfSightNew/isWasteObjectSet',
      getWasteObject: 'lineOfSightNew/getWasteObject',
      isObjectSelectedById: 'lineOfSightNew/isObjectSelectedById',
      isUseFromObjectById: 'lineOfSightNew/isUseFromObjectById'
    })
  },
  methods: {
    ...mapActions({
      setObjectToWorkWith: 'lineOfSightNew/setObjectToWorkWith',
      setUseFromObject: 'lineOfSightNew/setUseFromObject'
    }),
    /**
     * Draw path vnode
     * @param isCutObject
     * @returns {VNode}
     * @public
     */
    mx_object_objPath (isCutObject = false) {
      const element = this.$createElement('path', {
        class: {
          'c-object c-object__path': true,
          'c-object__path--cut': !isCutObject && this.cutMode,
          'c-object__path--hasNoProduct': this.object.product === null,
          'c-object__path--active': (this.losModeActive) ? this.isObjectSelectedById(this.object.id) : this.isObjectSelected(this.object.id),
          'c-object__path--edit': this.object.selectionMode === config.objects.modes.EDIT && (this.isObjectSelected(this.object.id) || this.isObjectSelectedById(this.object.id)),
          'c-object__path--active__pattern-cut': this.isObjectSelected(this.object.id) && isCutObject,
          'c-object__pattern c-object__pattern--cut': isCutObject,
          'c-object__path--dragover': this.mx_object_dragover,
          'c-object__path--los-active': this.losModeActive,
          'c-object__path--notAllowedToSelect': !(this.object.product && this.object.product.tracks) && this.isWasteObjectSet
        },
        attrs: {
          d: this.object.d(),
          fill: 'url(#objectLinearGradientLawn)',
          mask: `url(#object-mask-${this.object.id})`
        },
        on: {}
      })

      if (this.object.selectionMode === config.objects.modes.SELECTED && this.object.product !== null && this.isObjectSelected(this.object.id)) {
        element.data.attrs['fill-opacity'] = 0.5
      }

      return element
    },
    /**
     * Draw circle vnode
     * @param isCutObject
     * @returns {VNode}
     * @public
     */
    mx_object_objCircle (isCutObject = false) {
      const data = this.object.getCircleProperties()

      const element = this.$createElement('ellipse', {
        class: {
          'c-object c-object__path--circle': true,
          'c-object__path--hasNoProduct': this.object.product === null,
          'c-object__path--active': (this.losModeActive) ? this.isObjectSelectedById(this.object.id) : this.isObjectSelected(this.object.id),
          'c-object__path--edit': this.object.selectionMode === config.objects.modes.EDIT && this.isObjectSelected(this.object.id),
          'c-object__path--active__pattern-cut': this.isObjectSelected(this.object.id) && isCutObject,
          'c-object__pattern c-object__pattern--cut': isCutObject,
          'c-object__path--circle--dragover': this.mx_object_dragover,
          'c-object__path--los-active': this.losModeActive,
          'c-object__path--notAllowedToSelect': !(this.object.product && this.object.product.tracks) && this.isWasteObjectSet
        },
        attrs: {
          cx: data.middle.x,
          cy: data.middle.y,
          rx: data.radius,
          ry: data.radius,
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth),
          fill: 'url(#objectLinearGradientLawn)',
          mask: `url(#object-mask-${this.object.id})`
        },
        on: {}
      })

      if (this.object.selectionMode === config.objects.modes.SELECTED && this.object.product !== null && this.isObjectSelected(this.object.id)) {
        element.data.attrs['fill-opacity'] = 0.5
      }

      return element
    },
    /**
     * Clear the last mouse down position and toggle bbox
     * @private
     */
    $_mx_object_resetLastMouseDown () {
      this.mx_object_lastMouseDown = null
      this.mx_object_nearestPointToMouse = null
      this.mx_object_lastMouseDownPan = null
      if ((!this.cutModeActive && !this.object.isCutOut) || (this.cutModeActive && this.object.isCutOut)) {
        this.toggleBBox()
      }
    },
    /**
     * Override the last mouse down position
     * @param {Object}position Object as {x: x, y: y}
     * @private
     */
    $_mx_object_overrideLastMouseDown (position) {
      this.mx_object_lastMouseDown = position
    },
    /**
     * Override the last pan
     * @param pan
     * @private
     */
    $_mx_object_overrideLastMouseDownPan (pan) {
      this.mx_object_lastMouseDownPan = pan
    },
    /**
     * Creates mousedown event for last mouse down position and resets the last mouse down pan event to current pan.
     * Necessary for calculating the correct object position while dragging and panning the state
     */
    mx_object_overrideLastPanAndMouse () {
      const tmpEvent = new MouseEvent('mousedown', {
        clientX: this.mx_object_lastMouseDown.x +
          ((this.pan.x - this.mx_object_lastMouseDownPan.x) / (0.5 / this.scale)),
        clientY: this.mx_object_lastMouseDown.y +
          ((this.pan.y - this.mx_object_lastMouseDownPan.y) / (0.5 / this.scale)),
        timeStamp: this.mx_object_lastMouseDown.timeStamp
      })
      this.$_mx_object_overrideLastMouseDown(tmpEvent)
      this.$_mx_object_overrideLastMouseDownPan({ x: this.pan.x, y: this.pan.y })
    },
    /**
     * Register additional event handler (like event manager events) on the concrete svg element itself
     * @requires @/mixins/create
     * @param element
     * @returns {*}
     * @public
     */
    mx_object_elementEvents (element) {
      if (this.isWasteObjectSet && this.getWasteObject && this.object.product && this.object.product.tracks) {
        element.data.on = {
          click: (evt) => {
            if (!this.isUseFromObjectById(this.object.id)) {
              this.setUseFromObject(this.object)
            }
          }
        }
      }

      if (this.losMode && this.object.product !== null && !this.isWasteObjectSet) {
        element.data.on = {
          click: (evt) => {
            this.$events.fire(editorEventTypes.OBJECT_SELECTED, {
              addToSelection: false,
              object: this.object.id,
              product: (this.object.product !== null) ? this.object.product.id : null
            })
            this.setObjectToWorkWith(this.object)
          }
        }
      }

      if (!this.losMode && !this.isWasteObjectSet) {
        element.data.on = {
          click: (evt) => {
            if (this.mx_object_lastMouseDown && evt.timeStamp - this.mx_object_lastMouseDown.timeStamp > 300) {
              this.mx_object_lastMouseDown = null
              this.mx_object_nearestPointToMouse = null
              this.mx_object_lastMouseDownPan = null
              return
            }
            if (this.ctrl !== 'move') return
            this.mx_create_reset()
            const addToSelection = (evt.shiftKey)
            this.$events.fire(editorEventTypes.OBJECT_SELECTED, {
              addToSelection: addToSelection,
              object: this.object.id,
              product: (this.object.product !== null) ? this.object.product.id : null
            })
          },
          mousedown: (evt) => {
            this.mx_object_lastMouseDown = evt
            this.mx_object_lastMouseDownPan = { x: this.pan.x, y: this.pan.y }
            const pointerCoordinates = this.mx_math_getCoordinatesFromEvent(evt)
            const np = this.object.nearestPointToPosition(
              { x: pointerCoordinates.x, y: pointerCoordinates.y })
            this.mx_object_nearestPointToMouse = { x: np.x, y: np.y }
            if ((this.isObjectSelected(this.object.id) && !this.cutModeActive && !this.object.isCutOut) ||
              (this.isObjectSelected(this.object.id) && this.cutModeActive && this.object.isCutOut)) {
              if (evt.which === 3) {
                const point = this.mx_math_getPointerCoordinatesForMetaMenu(evt)
                this.setMouseMenu({
                  ctrl: 'menu-object',
                  position: point,
                  circle: null,
                  object: this.object.id,
                  isCutOut: this.object.isCutOut
                })
              } else {
                this.dragObject = true
              }
            }
          },
          dblclick: () => {
            if (this.mx_object_lastMouseDown === null) {
              return
            }
            this.$_mx_object_resetLastMouseDown()
          },
          dragover: (evt) => {
            evt.preventDefault()
            this.mx_object_dragover = true
            this.$events.fire(editorEventTypes.PRODUCT_DRAG_OVER_OBJECT, this.object.id)
          },
          dragleave: (evt) => {
            this.mx_object_dragover = false
            this.$events.fire(editorEventTypes.PRODUCT_DRAG_OVER_OBJECT, null)
          }
        }
      }

      return element
    }
  }
}
