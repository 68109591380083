<template>
  <v-popover
    :disabled="tooltipDisabled"
    :placement="hasSubmenuSlot ? 'left' : 'bottom'"
    popover-inner-class="popover-inner popover-no-pointer-events"
  >
    <!-- Popover target (for the events and position) -->
    <button
      :id="id"
      :class="[componentClasses, classes]"
      :disabled="disabled"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <icon
        :classes="iconClasses"
        style-width="100%"
        style-height="24px"
      >
        <slot name="icon" />
      </icon>
      <!--
          @slot Submenu slot
      -->
      <slot name="submenu" />
    </button>

    <!-- Popover content -->
    <template #popover>
      <div class="c-tooltip">
        <div :class="headerClasses">
          <h4
            class="c-tooltip-label"
            v-html="label"
          />
          <p
            :class="categoryClasses"
            v-html="category"
          />
        </div>
        <div v-if="hasPopupSlot">
          <hr
            v-if="label || category"
            :class="lineClasses"
          >
          <!--
              @slot Popup slot
          -->
          <slot name="popup" />
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Icon from '@/components/common/Icon'
/**
 * The ButtonIcon Component.
 * @displayName Button Head Nav
 */

export default {
  name: 'ButtonHeadNav',
  components: { Icon },
  props: {
    /**
     * Category label displayed on top of the tooltip
     */
    category: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Additional CSS classes
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * Disables the button
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Icon CSS classes - mostly Font Awesome
     */
    iconClasses: {
      type: [String, Array],
      required: true
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Description label displayed on top of the tooltip
     */
    label: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Inline function for onClick action<br>
     * <b>Example:</b><br>
     * <i>() => {return someFunction}</i>
     * @values false, ()=>{return someFunction}
     */
    onClickAction: {
      type: [Function, Boolean],
      default: false,
      required: false
    },
    /**
     * Sets corrections in css correlated with theme
     */
    theme: {
      type: String,
      default: 'dark',
      required: false
    },
    /**
     * Disables the tooltip
     */
    tooltipDisabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Defines color theme
     * @values dark, light
     */
    styleColor: {
      type: String,
      default: 'dark',
      required: false
    }
  },
  computed: {
    /* ------------------------------------------
     *  Styles
     *------------------------------------------ */

    /**
     * Sets CSS classes of the whole component
     */
    componentClasses () {
      return [
        'c-nav-sub__item tooltip-target',

        this.styleColor === 'light' ? 'c-nav-sub__item--lighter' : ''
      ]
    },
    /**
     * Sets CSS classes of the category label
     */
    categoryClasses () {
      return [
        'c-tooltip-category',
        `c-tooltip-category--${this.theme}-theme`
      ]
    },
    /**
     * Sets CSS classes of the header
     */
    headerClasses () {
      return [
        'c-tooltip-header',
        this.hasPopupSlot ? '' : 'c-tooltip-header--no-slot'
      ]
    },
    /**
     * Sets CSS classes of the line
     */
    lineClasses () {
      return [
        'c-tooltip-line',
        `c-tooltip-line--${this.theme}-theme`
      ]
    },
    /**
     * Checks if default slot is empty
     */
    hasPopupSlot () {
      return !!this.$slots.popup
    },
    /**
     * Checks if button slot is empty
     */
    hasSubmenuSlot () {
      return !!this.$slots.submenu
    }
  },
  methods: {
    onClick () {
      if (this.onClickAction) {
        document.activeElement.blur()
        this.onClickAction()
      }
      /**
       * Triggers when the button is clicked
       *
       * @event click
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('click', { id: this.id })
    },
    onMouseEnter () {
      /**
       * Triggers when the cursor enters the button
       *
       * @event mouseenter
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('mouseenter', { id: this.id })
      this.mouseOver = true
    },
    onMouseLeave () {
      /**
       * Triggers when the cursor leaves the button
       *
       * @event mouseleave
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('mouseleave', { id: this.id })
      this.mouseOver = false
    }
  }
}
</script>
