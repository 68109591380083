<template>
  <div
    v-if="showMenu"
    class="c-nav-sub__group"
  >
    <button-head-nav
      id="sub-nav-button-circle"
      :category="$t('subNav.categories.editSurface')"
      :classes="['c-nav-sub__submenu-group-container']"
      :disabled="shouldBlockGui"
      :label="$t('subNav.editSurface.point')"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
      @mouseenter="showSubmenu.circle = true"
      @mouseleave="showSubmenu.circle = false"
    >
      <template #icon>
        <point-circle-icon />
      </template>
      <template #submenu>
        <transition name="c-nav-sub__submenu-group">
          <div
            v-if="showSubmenu.circle"
            class="c-nav-sub__submenu-group"
          >
            <button
              v-if="hasEnoughPoints"
              id="sub-nav-button-circle-delete"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="removePoint()"
            >
              <icon classes="fas fa-2x fa-trash-alt" />
              <span>{{ $t('editor.contextMenu.circle.delete') }}</span>
            </button>
            <button
              v-if="pointType !== 'C'"
              id="sub-nav-button-circle-curve"
              class="c-nav-sub__item  c-nav-sub__submenu-item"
              @click="transformTo('C')"
            >
              <icon classes="c-nav-sub__icon">
                <handles-curve-icon />
              </icon>
              <span>{{ $t('editor.contextMenu.circle.curve') }}</span>
            </button>
            <button
              v-if="pointType !== 'L'"
              id="sub-nav-button-circle-line"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="transformTo('L')"
            >
              <icon classes="c-nav-sub__icon">
                <handles-line-icon />
              </icon>
              <span>{{ $t('editor.contextMenu.circle.line') }}</span>
            </button>
            <button
              v-if="pointType !== 'Q'"
              id="sub-nav-button-circle-quadratic"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="transformTo('Q')"
            >
              <icon classes="c-nav-sub__icon">
                <handles-quadratic-icon />
              </icon>
              <span>{{ $t('editor.contextMenu.circle.quadratic') }}</span>
            </button>
          </div>
        </transition>
      </template>
    </button-head-nav>

    <button-head-nav
      v-if="hasHandles"
      id="sub-nav-button-handle"
      :category="$t('subNav.categories.editSurface')"
      :classes="['c-nav-sub__submenu-group-container']"
      :disabled="shouldBlockGui"
      :label="$t('subNav.editSurface.handle')"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
      @mouseenter="showSubmenu.handle = true"
      @mouseleave="showSubmenu.handle = false"
    >
      <template #icon>
        <point-handle-icon />
      </template>
      <template #submenu>
        <transition name="c-nav-sub__submenu-group">
          <div
            v-if="showSubmenu.handle"
            class="c-nav-sub__submenu-group"
          >
            <button
              id="sub-nav-button-handle-reset"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="resetHandles()"
            >
              <icon classes="fas fa-redo-alt" />
              <span>{{ $t('editor.contextMenu.handle.reset') }}</span>
            </button>
            <button
              id="sub-nav-button-handle-delete"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="deleteHandles()"
            >
              <icon classes="fas fa-2x fa-trash-alt" />
              <span>{{ $t('editor.contextMenu.handle.delete') }}</span>
            </button>
          </div>
        </transition>
      </template>
    </button-head-nav>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import config from '@/config'

import MixinOperations from '@/mixins/operations'
import MixinTrackState from '@/mixins/trackstate'
import MixinValidate from '@/mixins/validate'
import ButtonHeadNav from '@/components/common/ButtonHeadNav'
import Icon from '@/components/common/Icon'
import HandlesCurveIcon from '@/components/common/icons/handles/HandlesCurveIcon'
import HandlesLineIcon from '@/components/common/icons/handles/HandlesLineIcon'
import HandlesQuadraticIcon from '@/components/common/icons/handles/HandlesQuadraticIcon'
import PointHandleIcon from '@/components/common/icons/point/PointHandleIcon'
import PointCircleIcon from '@/components/common/icons/point/PointCircleIcon'

export default {
  name: 'CircleHandlePan',
  components: {
    PointCircleIcon,
    PointHandleIcon,
    HandlesQuadraticIcon,
    HandlesLineIcon,
    HandlesCurveIcon,
    Icon,
    ButtonHeadNav
  },
  mixins: [
    MixinOperations,
    MixinTrackState,
    MixinValidate
  ],
  data: () => {
    return {
      showSubmenu: {
        circle: false,
        handle: false
      }
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      cut: state => state.events.mode.cut,
      currentlyActiveModifier: state => state.events.currentlyActiveModifier,
      currentSelectedObjects: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      getCutObject: 'project/objects/getCutObject',
      getPointIndex: 'project/objects/getPointIndex',
      getChildByPoint: 'project/objects/getChildByPoint',
      getPointFromChild: 'project/objects/getPointFromChild',
      shouldBlockGui: 'events/shouldBlockGui',
      getTheme: 'gui/getTheme'
    }),
    workingObject () {
      return (this.currentSelectedObjects.length !== 0)
        ? (!this.cut)
          ? this.getObject(this.currentSelectedObjects[0])
          : this.getCutObject
        : null
    },
    workingPoint () {
      if (this.workingObject &&
                    this.workingObject.selectionMode === config.objects.modes.EDIT &&
                    this.currentlyActiveModifier) {
        let point = this.workingObject.getPoint(this.currentlyActiveModifier)
        let child = null

        if (point === null) {
          point = this.getPointFromChild(this.workingObject.id, this.currentlyActiveModifier)
          child = this.getChildByPoint(this.workingObject.id, point.id, this.workingObject.isCutOut)
        }
        return { point: point, child: child }
      } else return { point: null, child: null }
    },
    hasEnoughPoints () {
      if (this.workingPoint.child !== null) {
        return (this.workingPoint.point !== null)
      }
      return (this.workingPoint.point !== null && (this.workingObject.points.length - 2) > 3)
    },
    pointType () {
      return (this.workingPoint.point) ? this.workingPoint.point.type : null
    },
    hasHandles () {
      return (this.workingPoint.point)
        ? (Object.entries(this.workingPoint.point.handles).length !== 0 && this.workingPoint.point.handles.constructor === Object) : false
    },
    showMenu () {
      if (this.currentlyActiveModifier && this.workingObject) {
        return this.ctrl === 'move' && this.currentlyActiveModifier && this.workingObject.type !== 'OBJECT_CIRCLE'
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      resetPointHandle: 'project/resetPointHandle',
      transformPoint: 'project/transformObjectPoint'
    }),
    ...mapMutations({
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU
    }),
    transformTo (type) {
      this.hideMouseMenu()
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.transformPoint({
            circle: this.currentlyActiveModifier,
            object: this.workingObject.id,
            isCutOut: this.workingObject.isCutOut,
            type: type
          })
        },
        runSave: !this.workingObject.isCutOut
      })
    },
    removePoint () {
      this.hideMouseMenu()
      this.showSubmenu.circle = false
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.mx_validate_removingPointsWithValidation(
            this.workingObject,
            this.currentlyActiveModifier
          )
        },
        runSave: !this.workingObject.isCutOut
      })
    },
    resetHandles () {
      this.hideMouseMenu()
      this.showSubmenu.handle = false
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.resetPointHandle({
            circle: this.currentlyActiveModifier,
            object: this.workingObject.id,
            isCutOut: this.workingObject.isCutOut
          })
        },
        runSave: !this.workingObject.isCutOut
      })
    },
    deleteHandles () {
      this.hideMouseMenu()
      this.showSubmenu.handle = false
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.transformPoint({
            circle: this.currentlyActiveModifier,
            object: this.workingObject.id,
            isCutOut: this.workingObject.isCutOut,
            type: 'L'
          })
        },
        runSave: !this.workingObject.isCutOut
      })
    }
  }
}
</script>
