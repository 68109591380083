import MixinMath from '@/mixins/math'
import { Turf } from '@/classes/products'

export const isClockwise = MixinMath.methods.mx_math_isClockwise
export const roundDecimal = MixinMath.methods.mx_math_roundDecimal

export const updateObjectMove = (object, movement) => {
  object.points.map((point) => {
    updatePointMove(point, movement)
  })
  if (object.hasChildren()) {
    for (let i = 0; i < object.children.length; i++) {
      object.children[i].points.map((point) => {
        updatePointMove(point, movement)
      })
    }
  }
  if (object.product instanceof Turf) {
    if (typeof object.product.tracks !== 'undefined' && object.product.tracks !== null && object.product.tracks.length > 0) {
      object.product.tracks.forEach(track => {
        track.points.map((point) => {
          updatePointMove(point, movement)
        })
      })
    }
  }
}

export const updatePointMove = (point, movement) => {
  if (point.type !== 'Z') {
    point.x = roundDecimal(point.x + movement.x)
    point.y = roundDecimal(point.y + movement.y)

    if (point.type === 'C' || point.type === 'c') {
      point.handles.x1 += MixinMath.methods.mx_math_roundDecimal(movement.x)
      point.handles.y1 += MixinMath.methods.mx_math_roundDecimal(movement.y)
      point.handles.x2 += MixinMath.methods.mx_math_roundDecimal(movement.x)
      point.handles.y2 += MixinMath.methods.mx_math_roundDecimal(movement.y)
    }
    if (point.type === 'Q' || point.type === 'q') {
      point.handles.x1 += MixinMath.methods.mx_math_roundDecimal(movement.x)
      point.handles.y1 += MixinMath.methods.mx_math_roundDecimal(movement.y)
    }
  }
}

export const getPointIndex = (points, id) => {
  return points.findIndex(x => {
    return x.id === id
  })
}

export const findPoint = (points, pointID) => {
  return points.find(point => {
    if (point.id === pointID) {
      return point
    }
  })
}

export const cubicHandles = (point, nextPoint) => {
  return {
    x2: roundDecimal(point.x + ((nextPoint.x - point.x) / 4), 2),
    y2: roundDecimal(point.y + ((nextPoint.y - point.y) / 4), 2),
    x1: roundDecimal(nextPoint.x + ((point.x - nextPoint.x) / 4), 2),
    y1: roundDecimal(nextPoint.y + ((point.y - nextPoint.y) / 4), 2)
  }
}

export const quadraticHandles = (point, nextPoint) => {
  return {
    x1: roundDecimal(nextPoint.x + ((point.x - nextPoint.x) / 2), 2),
    y1: roundDecimal(nextPoint.y + ((point.y - nextPoint.y) / 2), 2),
    x2: undefined,
    y2: undefined
  }
}

export const getNextPoint = (points, id) => {
  const key = points.findIndex(x => {
    return x.id === id
  })
  const nextIndex = key - 1
  return (Object.prototype.hasOwnProperty.call(points, nextIndex) && points[nextIndex].type !== 'Z' && points[nextIndex].type !== 'M') ? points[nextIndex] : points[points.length - 2]
}

export const sortClockwise = (points) => {
  return points.reverse()
}
