<template>
  <div
    :class="{'c-frame--bottom': styleBottom}"
    class="c-frame"
  >
    <h4
      v-if="title"
      class="c-frame__header c-typo__label"
    >
      {{ title }}
    </h4>
    <div class="c-frame__content">
      <!--
          @slot Default slot for content
      -->
      <slot />
    </div>
  </div>
</template>

<script>

/**
 * The PanelContainer Component.
 * @displayName Panel Container
 */

export default {
  name: 'PanelContainer',
  props: {
    /**
     * Title label
     */
    title: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines if panel should be moved to bottom of a container (margin-top: auto;)
     */
    styleBottom: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
