<template>
  <svg
    id="canvas-products-anim"
    viewBox="0 0 200 200"
    width="150px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>products-anim</title>

    <rect
      id="products-anim-object-1"
      class="cls-1 anim"
      height="95.92"
      rx="2.99"
      width="95.92"
      x="20.98"
      y="82.98"
    />
    <g
      id="products-anim-object-2"
      class="cls-2 anim"
    >
      <rect
        class="products-light-green"
        x="20.77"
        y="83.54"
        width="95.92"
        height="95.92"
        rx="2.99"
      />
      <path
        class="products-green"
        d="M46.39,120.43c-3.86.84-9.53-11.38-9.53-11.38s5,4.53,7.5,5.7c.12-5,.55-6.5-1-14.75,2.84,5.17,4.75,8.59,5.29,15.06,1.61-2.36,4.45-4,7.67-5.6-7,9.62-9.46,10.93-9.46,10.93Z"
      />
      <path
        class="products-green"
        d="M65.62,166.55c-4.83,1.11-11.93-14.9-11.93-14.9s6.31,5.93,9.39,7.47c.15-6.49.68-8.52-1.22-19.32,3.55,6.77,6,11.25,6.62,19.73,2-3.1,5.57-5.2,9.61-7.34-8.7,12.59-11.86,14.32-11.86,14.32Z"
      />
      <path
        class="products-green"
        d="M91.71,130.55c4.51,1.11,11.16-14.9,11.16-14.9s-5.9,5.93-8.78,7.47c-.15-6.49-.65-8.52,1.14-19.32-3.32,6.77-5.57,11.25-6.2,19.73-1.88-3.1-5.21-5.2-9-7.34,8.15,12.59,11.1,14.32,11.1,14.32Z"
      />
    </g>

    <g id="products">
      <circle
        class="products-white"
        cx="144.5"
        cy="24.5"
        r="5.5"
      />
      <rect
        class="products-white"
        x="157"
        y="22"
        width="33"
        height="5"
        rx="2.5"
      />
      <circle
        class="products-white"
        cx="144.5"
        cy="52.5"
        r="5.5"
      />
      <rect
        class="products-white"
        x="157"
        y="50"
        width="33"
        height="5"
        rx="2.5"
      />
      <circle
        class="products-white"
        cx="144.5"
        cy="38.5"
        r="5.5"
      />
      <rect
        class="products-white"
        x="157"
        y="36"
        width="33"
        height="5"
        rx="2.5"
      />
    </g>

    <g
      id="product"
      class="anim"
    >
      <circle
        class="products-white"
        cx="144.5"
        cy="38.5"
        r="5.5"
      />
      <rect
        class="products-white"
        x="157"
        y="36"
        width="33"
        height="5"
        rx="2.5"
      />
    </g>

    <path
      id="cursor"
      class="anim"
      d="M55.65,36.34,63,31.58,39,23l8.58,24,4.76-7.35,5.15,5.15a2.34,2.34,0,0,0,3.31-3.31Z"
    />

  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

    .products-green {
        fill: var(--g);
    }

    .products-light-green {
        fill: var(--g);
        opacity: 0.5;
    }

    #cursor, .products-white {
        fill: #fff;
    }

    #products-anim-object-1 {
        fill: var(--b1);
        opacity: 0.7
    }

    #products-anim-object-2 {
        opacity: 0.001; // Mozilla fix (cannot be 0)
    }

    /* Animations */

    .anim {
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;

        &#cursor {
            animation-name: move-cursor;
            animation-timing-function: ease-in-out;
        }

        &#product {
            animation-name: move-product, make-invisible;
            animation-timing-function: ease-in-out;
        }

        &#products-anim-object-1 {
            animation-name: make-invisible;
        }

        &#products-anim-object-2 {
            animation-name: make-visible;
        }
    }

    @keyframes move-cursor {
        0% {
            transform: translate(0, 0);
        }
        33% {
            transform: translate(110px, 15px);
        }
        66% {
            transform: translate(20px, 90px);
        }
        100% {
            transform: translate(20px, 90px);
        }
    }

    @keyframes move-product {
        0% {
            transform: translate(0, 0);
        }
        33% {
            transform: translate(0, 0);
        }
        66% {
            transform: translate(-90px, 75px);
        }
        100% {
            transform: translate(-90px, 75px);
        }
    }

    @keyframes make-invisible {
        0% {
            opacity: 0.7;
        }
        66% {
            opacity: 0.7;
        }
        66.1% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        100% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
    }

    @keyframes make-visible {
        0% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        66% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        66.1% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
</style>
