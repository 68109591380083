<template>
  <dialog-frame
    :cannot-close="true"
    :subtitle=" $t('dialogs.projectNeedUpdate.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.projectNeedUpdate.title')"
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p v-html="$t('dialogs.projectNeedUpdate.text_1')" />
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="project-need-update-update"
            :label="$t('dialogs.projectNeedUpdate.doUpdate')"
            :on-click-action="() => { return doUpdateProject() }"
            style-color="gray"
          />
          <button-base
            id="project-need-update-load"
            :label="$t('dialogs.projectNeedUpdate.forward')"
            :on-click-action="() => { return doLoadProject() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Project Need Update
     */
export default {
  name: 'ProjectNeedUpdate',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'projectNeedUpdate'
    }
  },
  methods: {
    ...mapActions({
      doUpdateProject: 'backendAPI/project/doUpdateProjectProducts',
      doLoadProject: 'backendAPI/project/doLoadProjectWithoutUpdate'
    })
  }
}
</script>
