<template>
  <svg
    id="cut-rect-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M5.52,36.25a2,2,0,1,1,2-2A2,2,0,0,1,5.52,36.25Zm0-11a2,2,0,0,1-2-2v-5a2,2,0,0,1,4,0v5A2,2,0,0,1,5.52,25.25Zm0-14a2,2,0,0,1-2-2V5.14a2,2,0,0,1,4,0V9.25A2,2,0,0,1,5.52,11.25Z"
    />
    <path
      class="u-fill-w"
      d="M34.7,7.14a2,2,0,1,1,2-2A2,2,0,0,1,34.7,7.14Zm-11,0h-5a2,2,0,1,1,0-4h5a2,2,0,1,1,0,4Zm-14,0H5.6a2,2,0,1,1,0-4H9.7a2,2,0,1,1,0,4Z"
    />
    <path
      class="u-fill-w"
      d="M34.63,36.51a2,2,0,0,1-2-2V30.41a2,2,0,0,1,4,0v4.11A2,2,0,0,1,34.63,36.51Zm0-13.1a2,2,0,0,1-2-2v-5a2,2,0,0,1,4,0v5A2,2,0,0,1,34.63,23.41Zm0-16a2,2,0,1,1,2-2A2,2,0,0,1,34.63,7.41Z"
    />
    <path
      class="u-fill-w"
      d="M34.55,36.51h-4.1a2,2,0,1,1,0-4h4.1a2,2,0,1,1,0,4Zm-13.1,0h-5a2,2,0,1,1,0-4h5a2,2,0,1,1,0,4Zm-16,0a2,2,0,1,1,2-2A2,2,0,0,1,5.45,36.51Z"
    />
    <circle
      class="u-fill-b1"
      cx="5.7"
      cy="34.33"
      r="5"
    />
    <circle
      class="u-fill-b1"
      cx="34.45"
      cy="5.67"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'CutRectIcon'
}
</script>
