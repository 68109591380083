<template>
  <div>
    <div
      class="c-pdf-to-img"
      :class="{'u-flex u-ai-center u-jc-center': !documentLoaded}"
    >
      <div
        v-if="documentLoaded"
        class="c-pdf-to-img__inner"
      >
        <div
          v-for="(data, index) in pages"
          :key="index"
          class="c-pdf-to-img__item"
          :class="{'c-pdf-to-img__item--loading': !data || data && !data.rendered}"
        >
          <div
            v-if="data && data.rendered"
            class="c-pdf-to-img__cover"
            :class="{'c-pdf-to-img__cover--selected': (data && selected && data.page.pageNumber === selected.pageNumber)}"
            @click="selectPage(data.page)"
          />
          <div
            v-if="data && data.page"
            class="c-pdf-to-img__page-number"
          >
            {{ data.page.pageNumber }}
          </div>
          <img
            v-if="data && data.rendered"
            style="cursor: pointer;"
            :src="data.thumb"
          >
          <i
            v-else
            class="fas fa-spinner fa-pulse"
          />
        </div>
      </div>
      <div
        v-else
        class="u-flex u-ai-center u-jc-center"
      >
        <i
          class="fas fa-spinner fa-pulse"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import * as pdfjsLib from 'pdfjs-dist'
import pdfWorker from 'pdfjs-dist/build/pdf.worker.entry'

export default {
  name: 'ConvertPdfToImage',
  props: {
    file: {
      type: File,
      required: false,
      default: null
    },
    scalePreview: {
      type: Number,
      required: false,
      default: 0.5
    },
    scaleOutput: {
      type: Number,
      required: false,
      default: 1
    },
    rotation: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => {
    return {
      documentLoaded: false,
      pagesLoaded: false,
      numPages: null,
      pages: null,
      selected: null
    }
  },
  mounted () {
    this.initialize()
  },
  methods: {
    initialize () {
      pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorker
      this.readFile(this.file)
    },
    readFile (file) {
      const reader = new FileReader()
      reader.onload = () => this.doLoadDocument(reader)
      reader.readAsDataURL(file)
    },
    doLoadDocument (reader) {
      if (reader.error === null && reader.readyState === 2) {
        const pdfDocument = pdfjsLib.getDocument(reader.result)

        pdfDocument.promise.then(doc => {
          Vue.set(this.$data, 'numPages', doc.numPages)
          Vue.set(this.$data, 'documentLoaded', true)
          this.doLoadPages(doc)
        })
      }
    },
    doLoadPages (doc) {
      const predefinedPages = new Array(this.numPages)
      Vue.set(this.$data, 'pages', predefinedPages)

      for (let i = 1; i <= this.numPages; i++) {
        doc.getPage(i).then(page => {
          this.doLoadThumbnail(page)
        })
      }
    },
    doLoadThumbnail (page) {
      const viewport = page.getViewport({ scale: this.scalePreview, rotation: this.rotation })
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.height = viewport.height
      canvas.width = viewport.width
      const task = page.render({ canvasContext: context, viewport: viewport })
      task.promise.then(() => {
        Vue.set(this.pages, page.pageNumber - 1, {
          rendered: true,
          page: page,
          thumb: canvas.toDataURL('image/png', 90)
        })

        canvas.remove()
        page.cleanup()
      })
    },
    selectPage (page) {
      if (page.pageNumber === this.selected) {
        this.selected = null
      } else {
        this.selected = page
      }

      this.$emit('selectPage', this.selected)
    }
  }
}
</script>
