<template>
  <panel-container>
    <div class="c-los__labeling">
      <span>{{ $t('editor.lineOfSight.product.summary.tracks') }}</span>
      <span>{{ getObjectProduct.getUsedTrackParts() }}</span>
      <span>{{ $t('editor.lineOfSight.product.summary.usedWaste') }}</span>
      <span>{{ getObjectProduct.getUsedWasteTrackParts() }}</span>
      <span>{{ $t('editor.lineOfSight.product.summary.total') }}</span>
      <span>{{ getObjectProduct.getLinearLength() }} {{ $t(`backend.materials.units.${getObjectProduct.unit}`) }}</span>
      <span>{{ $t('editor.lineOfSight.product.summary.withOversize') }}</span>
      <span>{{ linearLengthOversize }} {{ $t(`backend.materials.units.${getObjectProduct.unit}`) }}</span>
    </div>

    <div class="c-los__labeling">
      <h3>{{ $t('editor.lineOfSight.product.summary.price') }}</h3>
      <h3>{{ getCosts }}</h3>
    </div>
  </panel-container>
</template>

<script>
import PanelContainer from '@/components/common/PanelContainer'
import { mapGetters } from 'vuex'

import MixinCosts from '@/mixins/costs'

/**
     * Displays current configuration costs
     * @displayName LineOfSight Panel - Product - Costs
     */
export default {
  name: 'ProductCosts',
  components: {
    PanelContainer
  },
  mixins: [
    MixinCosts
  ],
  computed: {
    ...mapGetters({
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    getCosts () {
      const costs = this.mx_costs_turf(this.getObjectProduct)

      return costs.total.formatted
    },
    linearLengthOversize () {
      return this.getObjectProduct.getLinearLengthWithOversize()
    },
    cuts () {
      const tracksSmallerThanMaxLength = this.getObjectProduct.tracks.filter(track => track.length < this.getObjectProduct.max.length)

      return tracksSmallerThanMaxLength.length
    }
  }
}
</script>
