<template>
  <div class="c-los__panel-item">
    <panel-container :title="$t('editor.lineOfSight.product.cut.title_2')">
      {{ cutDistanceModified }} {{ getObjectProduct.unit }}

      <div class="c-los__tracks">
        <table>
          <tr v-if="topTrack !== null">
            <td />
            <td>{{ $t('editor.lineOfSight.product.cut.table.track') }}</td>
            <td>{{ $t('editor.lineOfSight.product.cut.table.length') }}</td>
            <td>{{ $t('editor.lineOfSight.product.cut.table.oversize') }}</td>
            <td>{{ $t('editor.lineOfSight.product.cut.table.result') }}</td>
          </tr>
          <tr v-if="topTrack !== null">
            <td>
              <div class="c-los__track-marker c-los__track-marker--first" />
            </td>
            <td>{{ topTrack.name }}</td>
            <td>{{ topTrack.length }}</td>
            <td>{{ topTrack.oversize }}</td>
            <td>
              {{
                ((Number(topTrack.length) + Number(topTrack.oversize)) - cutDistanceModified).toFixed(2)
              }}
            </td>
          </tr>
          <tr v-if="bottomTrack !== null">
            <td>
              <div class="c-los__track-marker c-los__track-marker--second" />
            </td>
            <td>{{ bottomTrack.name }}</td>
            <td>{{ bottomTrack.length }}</td>
            <td>{{ bottomTrack.oversize }}</td>
            <td>
              {{
                ((Number(bottomTrack.length) + Number(bottomTrack.oversize)) +
                  cutDistanceModified).toFixed(2)
              }}
            </td>
          </tr>
        </table>
      </div>

      <button-base
        v-if="!hideCancel"
        id="product-cut-information-abort"
        :label="$t('common.abort')"
        :on-click-action="() => { return clearWorkingData() }"
        classes="u-mr-size-0"
        style-min-width="180px"
      />

      <div
        v-if="!isMovedCutAllowedToSet"
        class="c-los__warning"
        v-html="$t('editor.lineOfSight.product.cut.warning',
                   {unit: $t(`backend.materials.units.${getObjectProduct.unit}`), threshold: getObjectProduct.threshold, length: getObjectProduct.length})"
      />
    </panel-container>
    <panel-container :title="$t('editor.lineOfSight.product.cut.title_3')">
      <button-base
        id="product-cut-information-remove"
        :disabled="isPossibleToRemove"
        :label="$t('editor.lineOfSight.product.cut.remove')"
        :on-click-action="() => { return removeCutBetweenTracks() }"
        classes="u-mr-size-0"
        style-min-width="180px"
      />
    </panel-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'

import paperjs from 'paper'
import PaperLayer from '@/classes/paperLayer'

import PanelContainer from '@/components/common/PanelContainer'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * Information about the selected end-point of a track
 * - Remove cut, Move cut, Calculate updated tracks after cut moved
 * @displayName LineOfSight Panel - Product - Cut Information
 */
export default {
  name: 'ProductCutInformation',
  components: {
    ButtonBase,
    PanelContainer
  },
  data: () => {
    return {
      topTrack: null,
      bottomTrack: null,
      hideCancel: false,
      modifiedCutInformation: {
        distance: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      getObjectProduct: 'lineOfSightNew/getObjectProduct',
      getSelectedTrackCutLine: 'lineOfSightNew/getSelectedTrackCutLine'
    }),
    isPossibleToRemove () {
      if (this.topTrack !== null && this.bottomTrack !== null) {
        const top_length = Number(this.topTrack.length) + Number(this.topTrack.oversize)
        const bottom_length = Number(this.bottomTrack.length) + Number(this.bottomTrack.oversize)

        const totalTrackLength = parseFloat(Number(top_length) + Number(bottom_length)).toFixed(2)

        return (Number(totalTrackLength) > Number(this.getObjectProduct.length))
      }

      return true
    },
    isMovedCutAllowedToSet () {
      if (this.topTrack !== null && this.bottomTrack !== null) {
        const top_length = Number(this.topTrack.length) + Number(this.topTrack.oversize)
        const bottom_length = Number(this.bottomTrack.length) + Number(this.bottomTrack.oversize)

        const t1 = Number((top_length - this.modifiedCutInformation.distance).toFixed(2))
        const t2 = Number((bottom_length + this.modifiedCutInformation.distance).toFixed(2))

        return t1 <= Number(this.getObjectProduct.length) && t2 <= Number(this.getObjectProduct.length) && t1 >= this.getObjectProduct.threshold && t2 >= this.getObjectProduct.threshold
      }
      return false
    },
    cutDistanceModified () {
      return Number(this.modifiedCutInformation.distance)
    }
  },
  mounted () {
    const elementIDs = this.getSelectedTrackCutLine.id.split('|')
    this.getTracksByNameAndPosition(elementIDs)
    // this.$events.on(editorEventTypes.LINE_OF_SIGHT_CUT_CLEAR_WORKING_DATA, this.clearWorkingData)
    this.$events.on(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED, this.updateCutDistance)
    this.$events.on(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED_STOP, this.manipulateTracksByCut)
    this.$events.on(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED_START, () => {
      this.hideCancel = true
    })
  },
  beforeDestroy () {
    this.clearWorkingData()
    this.$events.off(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED)
    this.$events.off(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED_STOP)
    this.$events.off(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED_START)
  },
  methods: {
    ...mapActions({
      updateObjectProductTracks: 'lineOfSightNew/updateObjectProductTracks',
      setSelectedTrackCutLine: 'lineOfSightNew/setSelectedTrackCutLine',
      removeSelectedTrackCutLine: 'lineOfSightNew/removeSelectedTrackCutLine'
    }),
    /**
     * Get current tracks above and below selected cut
     * @param ids track ids
     * @public
     */
    getTracksByNameAndPosition (ids) {
      const cp = new paperjs.CompoundPath()

      this.getObjectProduct.tracks.filter(track => ids.includes(track.name)).forEach(t => {
        const p = new paperjs.Path(t.getPathData())
        p.name = t.name
        cp.addChild(p)
      })

      if (this.getObjectProduct.angle > 0) {
        cp.rotate(-Number(this.getObjectProduct.angle))
      }

      const child0 = cp.children[0]
      const child1 = cp.children[1]

      this.topTrack = (child0.bounds.y < child1.bounds.y) ? this.getObjectProduct.tracks.find(t => t.name === child0.name) : this.getObjectProduct.tracks.find(t => t.name === child1.name)
      this.bottomTrack = (child0.bounds.y > child1.bounds.y) ? this.getObjectProduct.tracks.find(t => t.name === child0.name) : this.getObjectProduct.tracks.find(t => t.name === child1.name)

      cp.removeChildren()
      cp.remove()
    },
    /**
     * Update cut distance on moving cut
     * @param data
     * @public
     */
    updateCutDistance (data) {
      this.modifiedCutInformation = data
    },
    /**
     * Clear working data
     * @private
     */
    clearWorkingData () {
      this.topTrack = null
      this.bottomTrack = null
      this.hideCancel = false
      this.setSelectedTrackCutLine(null)
    },
    /**
     * Gets called by event, manipulate the tracks after mouse up
     *
     * @public
     */
    manipulateTracksByCut () {
      if (this.isMovedCutAllowedToSet) {
        this.updateObjectProductTracks({
          points: {
            old: this.getSelectedTrackCutLine,
            new: this.modifiedCutInformation
          }
        })
        this.clearWorkingData()
      } else {
        this.hideCancel = false
      }
    },
    /**
     * Remove selected cut between two tracks
     * @public
     */
    removeCutBetweenTracks () {
      const data = {
        trackIdToUpdate: this.topTrack.name,
        trackIdToDelete: this.bottomTrack.name,
        trackLength: parseFloat(Number(this.topTrack.length) + Number(this.bottomTrack.length)).toFixed(2),
        trackPoints: this.$_get_new_points_data(this.topTrack.points, this.bottomTrack.points)
      }

      this.removeSelectedTrackCutLine(data)
      this.clearWorkingData()
    },
    /**
     * Returns new point set after path stop removed
     *
     * @param p1
     * @param p2
     * @returns {null|Array}
     */
    $_get_new_points_data (p1, p2) {
      const pointMap = (point) => {
        if (point.type !== 'Z') {
          return [point.x, point.y]
        }
      }
      const pointFilter = (point) => point

      // transform point objects to array of [[x,y],...]
      const _p1 = p1.map(pointMap).filter(pointFilter)
      const _p2 = p2.map(pointMap).filter(pointFilter)

      // create paths and unite them
      const a = new paperjs.Path(_p1)
      const b = new paperjs.Path(_p2)

      a.closed = true
      b.closed = true

      const result = a.unite(b)

      // create point objects of paper segments
      if (result.segments.length > 0) {
        return PaperLayer.makePointsFromPaperSegments(result.segments)
      }

      return null
    }
  }
}
</script>
