import { mapState } from 'vuex'
import { productTypes } from '@/helper/api/types'

import MixinAlgorithm from '@/mixins/algorithmIntersect'

/**
 * @displayName Line of sight
 */
export default {
  name: 'LineOfSightMixin',
  data: () => {
    return {}
  },
  mixins: [
    MixinAlgorithm
  ],
  computed: {
    ...mapState({
      losObject: state => state.lineOfSightNew.object,
      losAngle: state => state.lineOfSightNew.object.product.angle,
      losAlignment: state => state.lineOfSightNew.object.product.alignment,
      measure: state => state.project.measurement.measure
    })
  },
  methods: {
    /**
         * Calculate Subbase (Pad, Pedestals) amount with the turf algorithm
         * @param subbase
         * @returns {null|number}
         * @public
         */
    async mx_lineOfSight_calculateSubbaseAmount (subbase) {
      if (subbase.type !== productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
        const width = (subbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) ? subbase.overlay.pad_width : subbase.overlay.pedestal_width
        const length = (subbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) ? subbase.overlay.pad_length : subbase.overlay.pedestal_length

        const settings = {
          angles: [this.losAngle],
          perfectVisualResult: true,
          alignment: this.losAlignment,
          threshold: length,
          shift: 0,
          turfWidth: Number(this.$_convert_value_to_pixels(width)),
          turfLength: Number(this.$_convert_value_to_pixels(length))
        }

        const amount = await this.mx_algorithm_calculate_tracks(this.losObject, settings)

        return amount.get(this.losAngle).tracks.length
      }

      return null
    },
    $_convert_value_to_pixels (value) {
      return (value / this.measure.toUnit) * this.measure.fromPixels
    }
  }
}
