<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.deleteProject.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.deleteProject.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p>{{ $t('dialogs.deleteProject.info') }}</p>
        <span
          class="u-color-b1 u-mt-size-standard"
        >
          {{ dialogProps.projectData.name }}
        </span>
        <p
          class="u-mt-size-standard"
          v-html="$t('dialogs.deleteProject.warning')"
        />
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="delete-project-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
          />
          <button-base
            id="delete-project-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return doDeleteProject() }"
            style-color="gray"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * @displayName Dialog Delete Project
 */
export default {
  name: 'DeleteProject',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'deleteProject',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.deleteProject
    })
  },
  methods: {
    ...mapActions({
      deleteProject: 'backendAPI/project/delete',
      loadProjects: 'backend/loadProjects'
    }),
    ...mapMutations({}),
    close () {
      this.mx_dialogs_closeDialog()
    },
    async doDeleteProject () {
      await this.deleteProject(this.dialogProps.projectData.id)
      // await this.loadProjects()
      this.close()
    }
  }
}
</script>
