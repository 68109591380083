<template>
  <div class="c-los__panel-content">
    <panel-container :title="$t('editor.lineOfSight.infill.title')">
      <select-infills-substructures
        :button-text="$t('editor.lineOfSight.infill.add')"
        :disabled="error"
        :items="dropDownItems"
        :no-items="$t('editor.lineOfSight.infill.noInfills')"
        @addSelectedItem="addInfill"
        @changedSelectedItem="changedInfill"
      >
        <template #properties>
          <input-field
            v-if="infill !== null"
            id="override-amount"
            v-model="overrideWeightField"
            :disabled="infill === null"
            :label="$t('editor.lineOfSight.infill.weight',{unit: $settings.infillUnits.recommended_weight})
              + ( (infill !== null && infill.overlay.infill_recommended_weight != null)
                ? $t('editor.lineOfSight.infill.weight_default', {default: infill.overlay.infill_recommended_weight})
                : '' )"
            :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
            type="number"
            @input="registerInput"
          />
        </template>
      </select-infills-substructures>

      <draggable
        v-model="draggableElements"
        :disabled="isDraggable"
        class="c-los-card-container"
        handle=".c-los-card__handle-container"
        v-bind="{animation: 200}"
        @end="dragTransition = false"
        @start="dragTransition = true"
      >
        <transition-group
          :name="dragTransition ? 'c-los-card-container__drag' : ''"
          type="transition"
        >
          <infill-card
            v-for="(infill, index) in draggableElements"
            :key="'infill' +index+infill.id"
            :index="index"
            :infill="infill"
            @disableDrag="disableDrag"
          />
        </transition-group>
      </draggable>
    </panel-container>

    <infills-costs />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import draggable from 'vuedraggable'

import { Infill } from '@/classes/products'

import PanelContainer from '@/components/common/PanelContainer'
import SelectInfillsSubstructures from '@/components/modules/lineOfSight/tools/common/SelectInfillsSubstructures'

import InfillCard from '@/components/modules/lineOfSight/tools/infill/InfillCard'
import InputField from '@/components/common/InputField'

import MixinValidate from '@/mixins/validate'
import InfillsCosts from '@/components/modules/lineOfSight/tools/infill/InfillsCosts'
import MixinMath from '@/mixins/math'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

/**
 * Provides a panel to add infills for lawn products
 * @displayName LineOfSight Panel - Infills
 */
export default {
  name: 'InfillsPanel',
  components: {
    InfillsCosts,
    draggable,
    PanelContainer,
    SelectInfillsSubstructures,
    InfillCard,
    InputField
  },
  mixins: [
    MixinValidate,
    MixinMath,
    MixinProductInLanguage
  ],
  data: () => {
    return {
      isDraggable: false,
      infill: null,
      overrideWeight: 0,
      error: false,
      dragTransition: false
    }
  },
  computed: {
    ...mapState({
      infills: state => state.products.infills
    }),
    ...mapGetters({
      getProductInfills: 'lineOfSightNew/getProductInfills',
      getInfillById: 'products/getInfillById'
    }),
    draggableElements: {
      get () {
        return this.getProductInfills
      },
      set (value) {
        this.updateInfillsSorting(value)
      }
    },
    overrideWeightField: {
      get () {
        return this.overrideWeight
      },
      set (payload) {
        this.overrideWeight = payload.value
        this.error = payload.error
      }
    },
    dropDownItems () {
      return _.cloneDeep(this.infills).map(base => {
        base.original.name = this.mx_product_in_language(base.original, ['name'], this.$i18n.locale)
        return base.original
      })
    }
  },
  methods: {
    ...mapActions({
      addInfillToProduct: 'lineOfSightNew/addInfillToProduct',
      updateInfillsSorting: 'lineOfSightNew/updateInfillsSorting'
    }),
    /**
     * Handle drag component
     * Elements inside the component are sortable
     * @param {boolean} value
     * @public
     */
    disableDrag (value) {
      this.isDraggable = value
    },
    registerInput (payload) {
      this.error = payload.error
    },
    /**
     * Gets called on selected changed
     * @param queryData
     */
    changedInfill (queryData) {
      this.error = false
      this.overrideWeight = 0
      if (queryData.id !== null) {
        this.infill = this.getInfillById(queryData.id)
        this.$nextTick(() => {
          const weight = (this.infill.overlay.infill_recommended_weight !== null) ? this.infill.overlay.infill_recommended_weight : 0
          this.overrideWeight = weight
          this.error = !this.mx_validate_validatePositiveNumber(weight)
        })
      }
    },
    /**
     * Add selected infill to list
     * @public
     */
    addInfill () {
      if (this.infill !== null) {
        this.addInfillToProduct(new Infill(this.infill.original.id, this.infill.original.name, this.infill.versioning.version_nr, this.infill.versioning.id, this.mx_math_roundDecimal(this.overrideWeight, 3)))
      }
    }
  }
}
</script>
