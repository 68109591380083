function evtHandlerPlugin (Vue) {
  if (evtHandlerPlugin.installed) return

  const events = new Vue({
    methods: {
      /**
       * @param {string} evt
       * @param  {...*} args
       */
      emit (evt, ...args) {
        this.$emit(evt, ...args)
      },
      /**
       * @param {string} evt
       * @param  {...*} args
       */
      fire (evt, ...args) {
        this.emit(evt, ...args)
      },
      /**
       * @param {string} evt
       * @param  {function} callback
       */
      on (evt, callback) {
        this.$on(evt, callback)
      },
      /**
       * @param {string} evt
       * @param  {function} callback
       */
      listen (evt, callback) {
        this.on(evt, callback)
      },
      /**
       * @param {string} evt
       * @param  {function} callback
       */
      once (evt, callback) {
        this.$once(evt, callback)
      },
      /**
       * @param {string} evt
       * @param  {function} callback
       */
      off (evt, callback) {
        this.$off(evt, callback)
      },
      /**
       * @param {string} evt
       * @param  {function} callback
       */
      remove (evt, callback) {
        this.off(evt, callback)
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$events', {
    get () {
      return events
    }
  })

  Vue.mixin({
    beforeCreate () {
      if (typeof this.$options.events !== 'object') return
      var eventMap = {}

      for (var key in this.$options.events) {
        eventMap[key] = this.$options.events[key].bind(this)
      }

      this.$once('hook:beforeMount', () => {
        for (var key in eventMap) {
          events.$on(key, eventMap[key])
        }
      })

      this.$once('hook:beforeDestroy', () => {
        for (var key in eventMap) {
          events.$off(key, eventMap[key])
        }
        eventMap = null
      })
    }
  })
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(evtHandlerPlugin)
}

export default evtHandlerPlugin
