<template>
  <div class="c-checkout__summary">
    <div class="u-pt-size-standard u-mb-size-20">
      <h4 class="c-typo__h4">
        {{ $t('dialogs.overview.sum.header') }}
      </h4>
    </div>
    <div class="o-grid o-grid--center c-checkout__content-full-width c-checkout__summary-partial u-ml-size-0">
      <div class="o-grid__col u-4/8 u-pl-size-0">
        <p>Σ {{ $t('dialogs.overview.sum.sum_products') }}</p>
        <p>Σ {{ $t('dialogs.overview.sum.sum_tools') }}</p>
        <p>Σ {{ $t('dialogs.overview.sum.sum_additional') }}</p>
      </div>
      <div class="o-grid__col u-4/8">
        <div class="c-checkout__content-right">
          <p>{{ $world.currencyConvert(summary.products_sum,true) }} </p>
          <p>{{ $world.currencyConvert(summary.tools_sum,true) }} </p>
          <p>{{ $world.currencyConvert(summary.additional,true) }} </p>
        </div>
      </div>
    </div>
    <div class="o-grid o-grid--center c-checkout__content-full-width c-checkout__summary-total u-ml-size-0">
      <div class="o-grid__col u-2/8 u-pl-size-0">
        <h3 class="c-typo__h3 u-color-b1">
          {{ $t('dialogs.overview.sum.sum_total') }}
        </h3>
      </div>
      <div class="o-grid__col u-6/8">
        <div class="c-checkout__content-right">
          <h2 class="c-typo__h2 u-color-b1">
            {{ $world.currencyConvert(summary.total_sum,true) }}
          </h2>
        </div>
      </div>
    </div>
    <div class="o-grid__col u-8/8 u-text-right c-checkout__summary-export">
      <button-base
        id="checkout-summary-export-pdf"
        :label="$t('dialogs.overview.export.costs')"
        :on-click-action="() => { return mx_export_pdf() }"
        classes="u-mr-size-0"
        icon-classes="far fa-file-pdf"
        style-min-height="50px"
        style-size="l"
      />
      <button-base
        id="checkout-summary-export-pdf-no-price"
        :label="$t('dialogs.overview.export.overview')"
        :on-click-action="() => { return mx_export_pdf(false) }"
        classes="u-mr-size-0"
        icon-classes="far fa-file-pdf"
        style-min-height="50px"
        style-size="l"
      />
      <button-base
        id="checkout-summary-export-pdf-a1"
        :label="$t('dialogs.overview.export.big')"
        :on-click-action="() => { return mx_export_pdf_turf_a1() }"
        classes="u-mr-size-0"
        icon-classes="far fa-file-pdf"
        style-min-height="50px"
        style-size="l"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import config from '@/config'
import MixinExport from '@/mixins/export'
import ButtonBase from '@/components/common/ButtonBase'

export default {
  name: 'Summary',
  components: {
    ButtonBase
  },
  mixins: [
    MixinExport
  ],
  props: {
    summary: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      projectTools: state => state.project.tools,
      projectConsumables: state => state.project.consumables
    }),
    currency () {
      return config.currency
    }
  }
}
</script>
