import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes, roles } from '@/helper/apiHelper'

/**
 * Default state for: Project Store
 *
 * @returns {{groups: []}}
 */
const defaultState = () => {
  return {
    groups: [],
    settings: null
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all groups
     *
     * @param state
     * @returns {[]}
     */
  getAll (state) {
    return state.groups
  },
  /**
     * Has groups
     *
     * @param state
     * @returns {boolean}
     */
  hasGroups (state) {
    return (state.groups.length > 0)
  },
  /**
   * Get all settings for my group
   *
   * @param state
   * @returns {Object|null}
   */
  getSettings (state) {
    return state.settings
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_GROUP_STATE)
  },
  /**
     * Set group list
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.group, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.GROUP_SET_GROUPS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a group
     * Roles: Manufacture
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{group: Object, fileCallback: CallableFunction}} data
     * @returns {Promise<void>}
     */
  async create ({ rootState, state, dispatch, commit }, data) {
    const group = data.group
    const createdGroup = await apiMethods.request('post', routes.group, group, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.GROUP_ADD_GROUP, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })

    if (createdGroup !== null) {
      const response = await data.fileCallback(routes.group + `/${createdGroup.id}/add_logo`, rootState.backendAPI.login.token, dispatch)
      if (response !== null && response.postMediaResponse !== null) {
        commit(types.GROUP_UPDATE_GROUP, response.postMediaResponse)
      }
    }
  },
  /**
     * Delete a group
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} groupId
     * @returns {Promise<void>}
     */
  async delete ({ rootState, dispatch, commit }, groupId) {
    await apiMethods.request('delete', routes.group + `/${groupId}`, null, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.GROUP_DELETE_GROUP, groupId)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get full group information
     * Roles: Admin, Manufacture, Distributor, Employee
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param groupId
     * @returns {Promise<null|Object>}
     */
  async read ({ rootState, dispatch, commit }, groupId) {
    return await apiMethods.request('get', routes.group + `/${groupId}`, null, rootState.backendAPI.login.token)
      .then(response => {
        const group = response.data.data
        if (rootState.backendAPI.user.me.role === roles.manufacture &&
          rootState.backendAPI.user.me.group.id === group.id) {
          if (group.theme === null || !Object.prototype.hasOwnProperty.call(group.theme, 'theme')) {
            group.theme = {
              theme: 'dark'
            }
          }
        }
        return group
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  },
  /**
     * Update a group
     * Roles: Manufacture, Distributor
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{group: Object, fileCallback: CallableFunction}} data
     * @returns {Promise<boolean>}
     */
  async update ({ rootState, state, dispatch, commit }, data) {
    const group = data.group
    await data.fileCallback(routes.group + `/${group.id}/add_logo`, rootState.backendAPI.login.token, dispatch)

    const groupModel = {
      name: group.name,
      address: {
        name: group.address.name,
        street: group.address.street,
        city: group.address.city,
        zip: group.address.zip,
        state: group.address.state,
        country: group.address.country,
        website: group.address.website
      },
      measurement: group.measurement,
      currency_sign: group.currency_sign,
      currency_short: group.currency_short
    }

    if (rootState.backendAPI.user.me.role === roles.manufacture &&
      rootState.backendAPI.user.me.group.id === group.id) {
      groupModel.theme = {
        theme: group.theme.theme
      }
    }
    return await apiMethods.request('patch', routes.group + `/${group.id}`, groupModel,
      rootState.backendAPI.login.token).then(response => {
      commit(types.GROUP_UPDATE_GROUP, response.data.data)
      return true
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
      return false
    })
  },
  /**
   * Read group settings
   * Roles: Manufacture, Distributor, Employee
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param groupId
   * @returns {Promise<null|Object>}
   */
  async readSettings ({ rootState, dispatch, commit }, groupId) {
    return await apiMethods.request('get', routes.group + `/${groupId}/config`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.GROUP_SET_SETTINGS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  }
}

const mutations = {
  [types.RESET_GROUP_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.GROUP_SET_GROUPS] (state, groups) {
    state.groups = groups
  },
  [types.GROUP_ADD_GROUP] (state, group) {
    state.groups.push(group)
  },
  [types.GROUP_DELETE_GROUP] (state, groupId) {
    state.groups = state.groups.filter(group => group.id !== groupId)
  },
  [types.GROUP_UPDATE_GROUP] (state, group) {
    const g = state.groups.find(g => g.id === group.id)

    if (g !== undefined) {
      apiMethods.updateStoreObjectProperties(g, group)
    }
  },
  [types.GROUP_SET_SETTINGS] (state, settings) {
    state.settings = settings
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
