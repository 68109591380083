<template>
  <div class="c-los-card">
    <div class="c-los-card__handle-container">
      <i class="fa fa-align-justify c-los-card__handle" />
    </div>
    <div class="c-los-card__content">
      <button-icon
        id="los-infill-subbase-delete"
        classes="c-los-card__close c-los-card__close--absolute"
        icon-classes="far fa-times-circle"
        @click="$emit('deleteItem')"
      />

      <slot name="content" />

      <button-icon
        v-if="!editMode && editable"
        id="los-infill-subbase-edit"
        classes="c-los-card__edit"
        icon-classes="fas fa-pen"
        @click="toggleEditMode('editItem')"
      />

      <div
        v-else-if="editable"
        class="c-los-card__button-item-container"
      >
        <button-icon
          id="los-infill-subbase-save"
          classes="c-los-card__button-item"
          icon-classes="fas fa-check"
          @click="toggleEditMode('saveItem')"
        />
        <button-icon
          id="los-infill-subbase-cancel"
          classes="c-los-card__close c-los-card__button-item"
          icon-classes="fas fa-times"
          @click="toggleEditMode('cancelItem')"
        />
      </div>
    </div>
  </div>
</template>

<script>

import ButtonIcon from '@/components/common/ButtonIcon'

/**
     * Provides a default item card
     * Use: InfillCard.vue, SubbaseCard.vue
     * @displayName LineOfSight Panel - Item Card
     */
export default {
  name: 'LosInfillSubbaseCard',
  components: {
    ButtonIcon
  },
  props: {
    index: {
      type: Number,
      required: true
    },
    editable: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => {
    return {
      editMode: false
    }
  },
  methods: {
    toggleEditMode (evtType) {
      this.editMode = !this.editMode
      this.$emit(evtType)
    }
  }
}
</script>
