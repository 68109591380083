<template>
  <div class="c-dialog__instruction c-dialog__instruction--no-center">
    <slot
      :formData="formData"
      :formFieldTypes="formFieldTypes"
      :files="files"
    />
  </div>
</template>

<script>
/**
 * Distributor based !!!
 *
 * This product update form helper is only for distributor based data
 */
import { mapState, mapActions } from 'vuex'
import { apiMethods, routes } from '@/helper/apiHelper'

import MixinFile from '@/mixins/file'
/**
 * @displayName Materials Form Component
 */
export default {
  name: 'MaterialsFormHelper',
  mixins: [
    MixinFile
  ],
  props: {
    item: {
      type: Object,
      required: false,
      default: null
    },
    itemType: {
      type: String,
      required: true
    },
    isUpdateForm: {
      type: Boolean,
      required: false,
      default: false
    },
    update: {
      type: Boolean,
      required: false,
      default: false
    },
    useStore: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => {
    return {
      formData: null,
      formFieldTypes: null,
      files: {
        file_image: null,
        file_media_images: null,
        file_media_documents: null
      }
    }
  },
  computed: {
    ...mapState({
      token: state => state.backendAPI.login.token
    })
  },
  watch: {
    update: {
      handler (n, o) {
        if (n === true) {
          this.doSubmit()
        }
      }
    },
    /* watcher for 'files'-property which is added through a slot to the form;
    * if it's updated, the properties are also file-mixin are updated */
    files: {
      handler (n, o) {
        this.mx_file_image = n.file_image
        this.mx_file_media_images = n.file_media_images
        this.mx_file_media_documents = n.file_media_documents
      },
      deep: true
    }
  },
  mounted () {
    this.compileItem()
  },
  methods: {
    ...mapActions({
      updateSpecialData: 'backendAPI/product/subProductHandler/updateSpecialProduct',
      createSpecialData: 'backendAPI/product/subProductHandler/createSpecialProduct'
    }),
    async compileItem () {
      const model = await apiMethods.getServerProductModel(routes[this.itemType], this.token)
      if (this.item === null && !this.isUpdateForm) {
        this.formData = model
      } else {
        this.formData = this.item
        /* TODO: when the server price.each for locations will be fixed
        it can be merged with getServerProductModel() and send with compileOutgoingProduct() */
        this.formFieldTypes = model
      }
    },
    async doSubmit () {
      if (this.isUpdateForm) {
        await this.updateSpecialData({
          update: this.formData,
          type: this.itemType,
          fileCallback: this.mx_file_doMediaRequests
        })
      } else {
        await this.createSpecialData({
          create: apiMethods.compileOutgoingProduct(this.formData),
          type: this.itemType,
          fileCallback: this.mx_file_doMediaRequests
        })
      }
      this.formData = null
      this.mx_file_clearData()
      this.$emit('close')
    }
  }
}
</script>
