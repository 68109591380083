<template>
  <div
    :class="toolbarClass"
    class="c-toolbar"
  >
    <div
      :class="{'c-toolbar__head--closed': open !== true, 'c-toolbar__head--hidden': shouldBlockGui}"
      class="c-toolbar__head"
      @click="toggleToolbar"
    >
      <icon classes="fas fa-angle-double-left" />
    </div>
    <div
      id="toolbar-activate-move"
      v-tooltip.right="{
        content: $t('editor.toolbar.select'),
      }"
      :class="{'c-toolbar__item--active':ctrl === 'move'}"
      class="c-toolbar__item"
      @click="activateTool('move')"
    >
      <icon classes="fas fa-2x fa-mouse-pointer" />
    </div>
    <div
      id="toolbar-activate-draw"
      v-tooltip.right="{
        content: $t('editor.toolbar.draw'),
      }"
      :class="{'c-toolbar__item--active':ctrl === 'draw'}"
      class="c-toolbar__item"
      @click="activateTool('draw')"
    >
      <icon
        classes="c-toolbar__icon"
        style-height="22px"
        style-width="22px"
      >
        <draw-icon />
      </icon>
    </div>

    <!-- Draw default shapes -->

    <div
      id="toolbar-activate-draw-default"
      v-tooltip="{
        content: $t('editor.toolbar.drawDefault.all'),
        placement: 'right'
      }"
      :class="{'c-toolbar__item--active':ctrl === 'drawDefault' && !cutDefaultActive}"
      class="c-toolbar__item c-toolbar__group-container"
      @click="openSubmenu('drawDefault')"
    >
      <icon
        classes="c-toolbar__icon"
        style-height="22px"
        style-width="22px"
      >
        <draw-all-icon v-if="groups.drawDefault.tool === null" />
        <draw-circle-icon v-if="groups.drawDefault.tool === 'circle'" />
        <draw-rect-icon v-if="groups.drawDefault.tool === 'rect'" />
        <draw-tri-icon v-if="groups.drawDefault.tool === 'tri'" />
      </icon>
    </div>

    <!-- submenu drawDefault -->

    <transition name="c-toolbar__group">
      <div
        v-if="groups.drawDefault.show"
        class="c-toolbar__group"
      >
        <div
          v-tooltip="{
            content: $t('editor.toolbar.drawDefault.rect'),
            placement: 'bottom-start'
          }"
          class="c-toolbar__item"
          @click="activateTool('drawDefault','rect')"
        >
          <icon
            classes="c-toolbar__icon"
            style-height="22px"
            style-width="22px"
          >
            <draw-rect-icon />
          </icon>
        </div>
        <!--<div @click="activateTool('drawDefault','tri')"
           class="c-toolbar__item"
           v-tooltip="{
              content: $t('editor.toolbar.drawDefault.tri'),
              placement: 'bottom-start'
          }">
          <icon classes="c-toolbar__icon" style-height="22px" style-width="22px">
              <draw-tri-icon/>
          </icon>
        </div>-->
        <div
          v-tooltip="{
            content: $t('editor.toolbar.drawDefault.circle'),
            placement: 'bottom-start'
          }"
          class="c-toolbar__item"
          @click="activateTool('drawDefault','circle')"
        >
          <icon
            classes="c-toolbar__icon"
            style-height="22px"
            style-width="22px"
          >
            <draw-circle-icon />
          </icon>
        </div>
      </div>
    </transition>

    <!-- Draw cutting shapes -->

    <div
      id="toolbar-activate-cut-default"
      v-tooltip="{
        content: $t('editor.toolbar.cutDefault.all'),
        placement: 'right'
      }"
      :class="{'c-toolbar__item--active':ctrl === 'drawDefault' && cutDefaultActive}"
      class="c-toolbar__item c-toolbar__group-container"
      @click="openCutSubmenu()"
    >
      <icon
        classes="c-toolbar__icon"
        style-height="22px"
        style-width="22px"
      >
        <cut-all-icon v-if="groups.cutDefault.tool === null" />
        <cut-circle-icon v-if="groups.cutDefault.tool === 'circle'" />
        <cut-rect-icon v-if="groups.cutDefault.tool === 'rect'" />
        <cut-tri-icon v-if="groups.cutDefault.tool === 'tri'" />
        <cut-draw-icon v-if="groups.cutDefault.tool === 'polygon'" />
      </icon>
    </div>

    <!-- submenu cutDefault -->

    <transition name="c-toolbar__group">
      <div
        v-if="groups.cutDefault.show"
        class="c-toolbar__group"
      >
        <div
          v-tooltip="{
            content: $t('editor.toolbar.cutDefault.polygon'),
            placement: 'bottom-start'
          }"
          class="c-toolbar__item"
          @click="activateTool('cutDefault','polygon')"
        >
          <icon
            classes="c-toolbar__icon"
            style-height="22px"
            style-width="22px"
          >
            <cut-draw-icon />
          </icon>
        </div>
        <div
          v-tooltip="{
            content: $t('editor.toolbar.cutDefault.rect'),
            placement: 'bottom-start'
          }"
          class="c-toolbar__item"
          @click="activateTool('cutDefault','rect')"
        >
          <icon
            classes="c-toolbar__icon"
            style-height="22px"
            style-width="22px"
          >
            <cut-rect-icon />
          </icon>
        </div>
        <!--<div @click="activateTool('cutDefault','tri')"
           class="c-toolbar__item"
           v-tooltip="{
               content: $t('editor.toolbar.cutDefault.tri'),
               placement: 'bottom-start'
           }">
            <icon classes="c-toolbar__icon" style-height="22px" style-width="22px">
                <cut-tri-icon />
            </icon>
          </div>-->
        <div
          v-tooltip="{
            content: $t('editor.toolbar.cutDefault.circle'),
            placement: 'bottom-start'
          }"
          class="c-toolbar__item"
          @click="activateTool('cutDefault','circle')"
        >
          <icon
            classes="c-toolbar__icon"
            style-height="22px"
            style-width="22px"
          >
            <cut-circle-icon />
          </icon>
        </div>
      </div>
    </transition>

    <div
      id="toolbar-activate-ruler"
      v-tooltip.right="{
        content: $t('editor.toolbar.measurement'),
      }"
      :class="{'c-toolbar__item--active':ctrl === 'ruler'}"
      class="c-toolbar__item"
      @click="activateTool('ruler')"
    >
      <icon classes="fas fa-ruler" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'
import devlog from '@/helper/devlog'

import MixinOperations from '@/mixins/operations'

import Icon from '@/components/common/Icon'

import DrawIcon from '@/components/common/icons/toolbar/DrawIcon'

import DrawAllIcon from '@/components/common/icons/toolbar/draw-default/DrawAllIcon'
import DrawCircleIcon from '@/components/common/icons/toolbar/draw-default/DrawCircleIcon'
import DrawRectIcon from '@/components/common/icons/toolbar/draw-default/DrawRectIcon'
import DrawTriIcon from '@/components/common/icons/toolbar/draw-default/DrawTriIcon'

import CutAllIcon from '@/components/common/icons/toolbar/cut-default/CutAllIcon'
import CutCircleIcon from '@/components/common/icons/toolbar/cut-default/CutCircleIcon'
import CutRectIcon from '@/components/common/icons/toolbar/cut-default/CutRectIcon'
import CutTriIcon from '@/components/common/icons/toolbar/cut-default/CutTriIcon'
import CutDrawIcon from '@/components/common/icons/toolbar/cut-default/CutDrawIcon'

export default {
  name: 'Toolbar',
  components: {
    Icon,
    DrawIcon,
    DrawAllIcon,
    DrawCircleIcon,
    DrawRectIcon,
    DrawTriIcon,
    CutAllIcon,
    CutCircleIcon,
    CutRectIcon,
    CutTriIcon,
    CutDrawIcon
  },
  mixins: [
    MixinOperations
  ],
  data () {
    return {
      open: true,
      groups: {
        drawDefault: {
          show: false,
          tool: null
        },
        cutDefault: {
          show: false,
          tool: null
        }
      },
      cutDefaultActive: false,
      locked: false
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      target: state => state.events.target
    }),
    ...mapGetters({
      shouldBlockGui: 'events/shouldBlockGui'
    }),
    toolbarClass: function () {
      return {
        'c-toolbar--closed': !this.open || this.shouldBlockGui,
        'c-toolbar--inactive-cut': this.mx_operations_selectedObjectsCount !== 1,
        'c-toolbar--inactive-all': this.shouldBlockGui
      }
    }
  },
  mounted () {
    document.addEventListener('mousedown', this.documentClick)
    this.$events.on(editorEventTypes.TOOLBAR_RESET_SUBMENU_CONTAINERS, this.resetSubmenuContainers)
  },
  destroyed () {
    document.removeEventListener('mousedown', this.documentClick)
  },
  methods: {
    ...mapActions({
      clearObjectSelection: 'events/clearObjectSelection'
    }),

    /* ------------------------------------------
     *  Tools activator
     *------------------------------------------ */

    activateTool (mode, param) {
      if (this.target === null && !this.shouldBlockGui) {
        switch (mode) {
          case 'move':
            this.changeCtrl('move')
            break
          case 'draw':
            this.changeCtrl('draw')
            break
          case 'drawDefault':
            this.changeDrawMode(false, param)
            this.groups.drawDefault.tool = param
            break
          case 'cutDefault':
            if (this.mx_operations_selectedObjectsCount === 1) {
              this.changeDrawMode(true, param)
              this.groups.cutDefault.tool = param
              this.$events.fire('CUT_DRAW_TOOL', this.groups.cutDefault.tool)
            }
            break
          case 'ruler':
            this.changeCtrl('ruler')
            this.$events.fire(editorEventTypes.RULER_CREATE)
            break
          default:
            devlog.log("There's no such tool:", mode)
        }
      }
    },

    /* ------------------------------------------
     *  Helpers
     *------------------------------------------ */
    changeDrawMode (isCut, param) {
      if (!isCut) {
        this.clearObjectSelection()
      }
      this.$events.fire(editorEventTypes.DRAW_DEFAULT_OBJECT, { cut: isCut, type: param })
      this.cutDefaultActive = isCut
      this.openSubmenu('')
    },
    openSubmenu (name) { // opens only the submenu with fitting name
      for (const prop in this.groups) { // all other will be closed
        if (Object.prototype.hasOwnProperty.call(this.groups, prop)) {
          this.groups[prop].show = (this.groups[prop].show) ? false : (prop === name)
        }
      }
    },
    openCutSubmenu () {
      if (this.mx_operations_selectedObjectsCount === 1) {
        this.openSubmenu('cutDefault')
      }
    },
    changeCtrl (ctrl) {
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, ctrl)
      this.clearObjectSelection()
    },
    toggleToolbar () {
      this.open = !this.open
    },
    documentClick (evt) {
      const elements = [...document.getElementsByClassName('c-toolbar__group'),
        ...document.getElementsByClassName('c-toolbar__group-container')]
      let target = null
      let close = true // by default try to close all submenus

      if (evt && evt.target) {
        target = evt.target
      }
      for (const element in elements) { // check if event target is not equal to one of submenus, a child of one
        if (Object.prototype.hasOwnProperty.call(elements, element)) { // or an activator (container)
          if (
            (element &&
              elements[element] !== target &&
              !elements[element].contains(target))
          ) {
            close = (close !== false) // as long as no element matches target, 'close' will stay to be true
          } else {
            close = false
          }
        }
      }
      if (close) {
        this.openSubmenu('')
      }
    },
    resetSubmenuContainers () {
      for (const element in this.groups) {
        if (Object.prototype.hasOwnProperty.call(this.groups, element)) {
          this.groups[element].tool = null
        }
      }
    }
  }
}
</script>
