import Vue from 'vue'
import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Pad Store
 *
 * @returns {{pads: [], specialToUpdate: null, specialPads: []}}
 */
const defaultState = () => {
  return {
    specialPads: [],
    pads: [],
    specialToUpdate: null,
    meta: null
  }
}

const state = defaultState()

const getters = {
  getMeta (state) {
    return state.meta
  },
  /**
     * Has pads
     *
     * @param state
     * @returns {boolean}
     */
  hasPads (state) {
    return !_.isEmpty(state.pads)
  },
  /**
     * Get pads
     *
     * @param state
     * @returns {[]}
     */
  getPads (state) {
    return state.pads
  },
  /**
     * Has special pads
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialPads (state) {
    return !_.isEmpty(state.specialPads)
  },
  /**
     * Get special pads
     *
     * @param state
     * @returns {[]}
     */
  getSpecialPads (state) {
    return state.specialPads
  },
  /**
     * Has product to update
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialToUpdate (state) {
    return state.specialToUpdate !== null
  },
  /**
     * Get special pad for update
     *
     * @param state
     * @returns {{}|null}
     */
  getSpecialForUpdate: (state) => {
    return (state.specialToUpdate !== null) ? _.cloneDeep(state.specialToUpdate) : null
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_PAD_STATE)
  },
  /**
     * Reset PIM state
     *
     * @param dispatch
     * @param commit
     */
  resetPIMState ({ dispatch, commit }) {
    commit(types.RESET_PAD_PIM_STATE)
  },
  /**
     * Set special product to update
     *
     * @param commit
     * @param specialPad
     */
  setSpecialToUpdate ({ commit }, specialPad) {
    commit(types.PRODUCT_PAD_SET_SPECIAL_PAD_TO_UPDATE, specialPad)
  },
  /**
     * Get all pads for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.location + `/${rootState.backendAPI.product.locationId}/pads`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PAD_SET_PADS, response.data.data)
        commit(types.PRODUCT_PAD_SET_META, response.data.meta)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update location pad
     *
     * @param dispatch
     * @param pad
     */
  updateLocation ({ dispatch }, pad) {
    pad.relate_to_latest = true
    dispatch('update', [pad])
  },
  /**
     * Update all pads for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param state
     * @param pads
     * @returns {Promise<void>}
     */
  async update ({ rootState, dispatch, commit, state }, pads) {
    await apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/pads`, { update: pads }, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PAD_SET_PADS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get all special pads for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listSpecial ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.pad, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PAD_SET_SPECIAL_PADS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a special pad for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialPad
     * @returns {Promise<boolean>}
     */
  async createSpecial ({ rootState, dispatch, commit }, specialPad) {
    if (!Object.prototype.hasOwnProperty.call(specialPad.data, 'group_id')) {
      specialPad.data.group_id = rootState.backendAPI.user.me.group.id
    }

    const createdPad = await apiMethods.request('post', routes.pad, specialPad.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PAD_ADD_SPECIAL_PAD, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return true
      })

    if (createdPad !== null) {
      const response = await specialPad.fileCallback(routes.pad + `/${createdPad.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_PAD_UPDATE_SPECIAL_PAD, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_PAD_UPDATE_SPECIAL_PAD, response.postMediaResponse)
        }
      }
    }
  },
  /**
     * Update a special pad for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialPad
     * @returns {Promise<void>}
     */
  async updateSpecial ({ rootState, state, dispatch, commit }, specialPad) {
    const updatedPad = await apiMethods.request('patch', routes.pad + `/${state.specialToUpdate.id}`, specialPad.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_PAD_UPDATE_SPECIAL_PAD, response.data.data)
        commit(types.PRODUCT_PAD_SET_SPECIAL_PAD_TO_UPDATE, null)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })

    if (updatedPad !== null) {
      const response = await specialPad.fileCallback(routes.pad + `/${updatedPad.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_PAD_UPDATE_SPECIAL_PAD, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_PAD_UPDATE_SPECIAL_PAD, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Delete a special pad for distributor, manufacture
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {[Number]} padIds
   * @returns {Promise<void>}
   */
  async deleteSpecial ({ rootState, dispatch, commit }, padIds) {
    await apiMethods.request('delete', routes.pad, { ids: padIds }, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.PRODUCT_PAD_DELETE_SPECIAL_PAD, padIds)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_PAD_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.RESET_PAD_PIM_STATE] (state) {
    state.pads = []
  },
  [types.PRODUCT_PAD_SET_PADS] (state, pads) {
    state.pads = pads
  },
  [types.PRODUCT_PAD_SET_SPECIAL_PADS] (state, specialPads) {
    state.specialPads = specialPads
  },
  [types.PRODUCT_PAD_ADD_SPECIAL_PAD] (state, specialPad) {
    state.specialPads.push(specialPad)
  },
  [types.PRODUCT_PAD_UPDATE_SPECIAL_PAD] (state, specialPad) {
    const index = state.specialPads.findIndex(i => i.id === specialPad.id)

    if (index !== -1) {
      Vue.set(state.specialPads, index, specialPad)
    }
  },
  [types.PRODUCT_PAD_SET_SPECIAL_PAD_TO_UPDATE] (state, specialPad) {
    state.specialToUpdate = specialPad
  },
  [types.PRODUCT_PAD_DELETE_SPECIAL_PAD] (state, padIds) {
    state.specialPads = state.specialPads.filter(pad => {
      return pad.id !== padIds.filter(padId => padId === pad.id)[0]
    })
  },
  [types.PRODUCT_PAD_SET_META] (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
