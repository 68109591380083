import { mapMutations } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

/**
 * @displayName Subnav
 */
export default {
  name: 'SubnavMixin',
  methods: {
    ...mapMutations({
      /** @private **/
      $_mx_subnav_toggleSubNavDialog: 'navigation/' + mutationTypes.SUBNAV_DIALOGS_TOGGLE,
      /** @private **/
      $_mx_subnav_subNavDialogSubscreen: 'navigation/' + mutationTypes.SUBNAV_DIALOGS_SUBSCREEN,
      /** @private **/
      $_mx_subnav_setCoverState: 'editor/' + mutationTypes.SET_EDITOR_COVER
    }),
    /**
     * Closes the sub navigation dialog
     * @public
     */
    mx_subnav_closeDialog () {
      this.$_mx_subnav_toggleSubNavDialog({
        dialog: this.targetSubNavDialog,
        onOff: false,
        cannotClose: false,
        hideTitle: false
      })
      this.$_mx_subnav_setCoverState(false)
    },
    /**
     * Set the sub navigation sub screen
     * @param val
     * @public
     */
    mx_subnav_setSubscreen (val) {
      this.$_mx_subnav_subNavDialogSubscreen({
        dialog: this.targetSubNavDialog,
        val: val
      })
    }
  }
}
