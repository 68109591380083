<template>
  <div class="c-input-checkbox__container">
    <label
      :id="id + '-label'"
      :class="[ componentClasses, classes]"
      :for="id"
      v-html="label"
    />

    <input
      :id="id"
      :checked="value"
      :disabled="disabled"
      class="c-input-checkbox__input"
      type="checkbox"
      @change="updateInput({val: $event.target.checked, el: 'input'})"
    >
  </div>
</template>

<script>

/**
 * The InputCheckbox Component.
 * @displayName Input Checkbox
 */

export default {
  name: 'InputCheckbox',
  props: {
    /**
     * Additional CSS classes
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * Disables the whole component
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Defines if the payload will be emitted or just the value. If payload, then it is possible to use custom v-model and validate
     */
    isStoreMode: {
      type: Boolean,
      default: true,
      required: false
    },
    /**
     * Description label displayed near the component
     */
    label: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines component style (1 - rounded slider, 2 - quadratic point)
     * @values 1, 2
     */
    styleComponent: {
      type: [Number, String],
      default: 1,
      required: false
    },
    /**
     * @model
     * Current item (bind via v-model)
     */
    value: {
      type: null,
      required: false,
      default: null
    }
  },
  computed: {
    componentClasses () {
      return [
        'c-typo__label',
        Number(this.styleComponent) === 1 ? 'c-input-checkbox-style-1' : '',
        Number(this.styleComponent) === 2 ? 'c-input-checkbox-style-2' : '',
        this.value && Number(this.styleComponent) === 1 ? 'c-input-checkbox-style-1--active' : '',
        this.value && Number(this.styleComponent) === 2 ? 'c-input-checkbox-style-2--active' : '',
        this.label !== '' && Number(this.styleComponent) === 2 ? 'c-input-checkbox-style-2--labeled' : '',
        Number(this.styleComponent) === 1 && this.disabled ? 'c-input-checkbox-style-1--disabled' : '',
        Number(this.styleComponent) === 2 && this.disabled ? 'c-input-checkbox-style-2--disabled' : ''
      ]
    }
  },
  watch: {
    // for changes from outside - still with validation
    value (val) {
      this.updateInput({ val: val, el: 'watcher' })
    }
  },
  beforeMount () {
    this.registerInput()
  },
  methods: {
    registerInput () {
      /**
       * Triggers on 'beforeMount' to register the component in 'validate' mixin
       *
       * @event register
       * @property {{id: string, value: boolean, error: boolean}} payload - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       * @property {boolean} value - Current value
       * @property {boolean} error - always false (it's always correct in mx\_validate\_errors; only for registering changes)
       */
      this.$emit('register', { id: this.id, value: this.value, error: false })
    },
    updateInput (payload) {
      // prevent from updating two times (because of the watcher)
      if (payload.el !== 'watcher') {
        if (!this.isStoreMode) {
          /**
           * Additional emit of input changes - it reacts only on internal changes and ignores those which comes from outside (watcher)<br>
           * Used mostly for registering changes in validate mixin<br><br>
           * Triggers when the value changes - made for custom v-model
           * which gives a possibility to check error and decide if the value should be saved in store
           *
           * @event change
           * @property {{id: string, value: boolean, error: boolean}} payload - Emitted payload
           * @property {string} id - ID of this component (also used in html and tests)
           * @property {boolean} value - Current value
           * @property {boolean} error - always false
           */
          this.$emit('change', { id: this.id, value: this.value, error: false })
        }
        this.returnValue(payload.val)
      }
    },
    returnValue (val) {
      if (this.isStoreMode) {
        /**
         * Triggers when the value changes - made for custom v-model
         * which gives a possibility to check error and decide if the value should be saved in store
         *
         * @event input - store mode
         * @property {{id: string, value: boolean, error: boolean}} value - Emitted payload
         * @property {string} id - ID of this component (also used in html and tests)
         * @property {boolean} value - Current value
         * @property {boolean} error - always false
         */
        this.$emit('input', { id: this.id, value: val, error: false })
      } else {
        /**
         * Triggers when the value changes and on 'mounted' - made for standard v-model
         *
         * @event input
         * @property {*} value - Emitted value
         */
        this.$emit('input', val)
      }
    }
  }
}
</script>
