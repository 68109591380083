<template>
  <svg
    id="operations-add-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M35.69,13.37H26.54V4.25A3.25,3.25,0,0,0,23.29,1h-19A3.26,3.26,0,0,0,1,4.25v19a3.25,3.25,0,0,0,3.25,3.25H13.4v9.14a3.24,3.24,0,0,0,3.24,3.25h19a3.26,3.26,0,0,0,3.25-3.25v-19A3.26,3.26,0,0,0,35.69,13.37Zm-2.5,14.34H27.76v5.44a1.7,1.7,0,0,1-3.4,0V27.71H18.93a1.67,1.67,0,0,1-1.61-1.17,1.5,1.5,0,0,1-.09-.52,1.7,1.7,0,0,1,1.7-1.71h5.43V18.88a1.7,1.7,0,0,1,1.7-1.69,1.51,1.51,0,0,1,.48.07,1.69,1.69,0,0,1,1.22,1.62v5.43h5.43a1.7,1.7,0,0,1,0,3.4Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OperationsAddIcon'
}
</script>
