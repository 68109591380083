<template>
  <los-infill-subbase-card
    :editable="subbase.type === productTypes.PRODUCT_OTHER_SUBBASE_TYPE"
    :index="index"
    @cancelItem="cancelSubbase"
    @deleteItem="removeFromProduct"
    @editItem="editSubbase"
    @saveItem="saveSubbase"
  >
    <template #content>
      <p>{{ name }}</p>
      <hr class="u-mb-size-standard u-mt-size-standard">

      <div v-if="!isEditable">
        <p>{{ $t('editor.lineOfSight.subbase.thickness',{unit: $settings.subbaseUnits.thickness}) }}: {{ subbase.thickness }}</p>
      </div>

      <div v-else>
        <input-field
          id="subbase-card-thickness"
          v-model="inputThickness"
          :label="$t('editor.lineOfSight.subbase.thickness',{unit: $settings.subbaseUnits.thickness})"
          :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
        />
      </div>
    </template>
  </los-infill-subbase-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { Subbase } from '@/classes/products'
import { productTypes } from '@/helper/api/types'

import InputField from '@/components/common/InputField'
import LosInfillSubbaseCard from '@/components/modules/lineOfSight/tools/common/LosInfillSubbaseCard'

import MixinValidate from '@/mixins/validate'
import MixinMath from '@/mixins/math'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

/**
 * Displays a subbase item
 * @displayName LineOfSight Panel - Subbase Card
 */
export default {
  name: 'SubbaseCard',
  components: {
    InputField,
    LosInfillSubbaseCard
  },
  mixins: [
    MixinValidate,
    MixinMath,
    MixinProductInLanguage
  ],
  props: {
    index: {
      type: Number,
      required: true
    },
    subbase: {
      type: Subbase,
      required: true
    }
  },
  data: () => {
    return {
      tmpThickness: null,
      productTypes: productTypes
    }
  },
  computed: {
    ...mapGetters({
      getSubbaseByIdType: 'products/getSubbaseByIdType'
    }),
    isEditable () {
      return this.tmpThickness !== null && this.subbase.type === productTypes.PRODUCT_OTHER_SUBBASE_TYPE
    },
    inputThickness: {
      get () {
        return this.tmpThickness
      },
      set (payload) {
        if (!payload.error) {
          this.tmpThickness = payload.value
        }
      }
    },
    name () {
      const subbase = this.getSubbaseByIdType({ id: this.subbase.id, type: this.subbase.type })
      return this.mx_product_in_language(subbase, ['name'], this.$i18n.locale)
    }
  },
  methods: {
    ...mapActions({
      removeSubbaseFromProduct: 'lineOfSightNew/removeSubbaseFromProduct',
      updateSubbaseProperties: 'lineOfSightNew/updateSubbaseProperties'
    }),
    removeFromProduct () {
      this.removeSubbaseFromProduct(this.index)
    },
    editSubbase () {
      this.tmpThickness = this.subbase.thickness
      this.$emit('disableDrag', true)
    },
    saveSubbase () {
      this.updateSubbaseProperties({
        index: this.index,
        props: {
          thickness: this.mx_math_roundDecimal(this.tmpThickness, 3)
        }
      })
      this.cancelSubbase()
    },
    cancelSubbase () {
      this.tmpThickness = null
      this.$emit('disableDrag', false)
    }
  }
}
</script>
