<template>
  <svg
    id="template-add-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <path
      class="u-fill-b1"
      d="M35.49,30H30.06v5.43a1.7,1.7,0,0,1-3.4,0V30H21.23a1.69,1.69,0,0,1-1.61-1.17,1.77,1.77,0,0,1-.09-.52,1.7,1.7,0,0,1,1.7-1.71h5.43V21.16a1.7,1.7,0,0,1,1.71-1.69,1.49,1.49,0,0,1,.47.07,1.69,1.69,0,0,1,1.22,1.62v5.43h5.43a1.7,1.7,0,0,1,0,3.4Z"
    />
    <path
      class="u-fill-w"
      d="M34.1,33v1.45h-1v1a4.9,4.9,0,0,1-.11,1H35.1a1,1,0,0,0,1-1V33a4.08,4.08,0,0,1-.61,0Zm1-23.08H12.25V7.73A4.12,4.12,0,0,0,8.08,3.55,4,4,0,0,0,3.9,7.73V31.26a4.93,4.93,0,0,0,5.18,5.18h14.7a4.31,4.31,0,0,1-.12-1v-1c-6.46,0-13.68,0-14.58,0A2.91,2.91,0,0,1,6,31.34a2,2,0,0,1,2.16-2.17,2,2,0,0,1,2.1,2.09c0,1.18,2,1.11,2,0V11.91H34.1V23.59h1.39a4.08,4.08,0,0,1,.61,0V10.91A1,1,0,0,0,35.1,9.91Z"
    />
    <g>
      <path
        class="u-fill-w"
        d="M17.46,18.82H16v-2.5a.75.75,0,0,1,.75-.75h2.5v1.5H17.46Z"
      />
      <rect
        class="u-fill-w"
        x="21.38"
        y="15.57"
        width="3.62"
        height="1.5"
      />
      <path
        class="u-fill-w"
        d="M30.42,16.32V17a3.81,3.81,0,0,0-.66-.27,4.59,4.59,0,0,0-1.39-.22,4.29,4.29,0,0,0-1.2.16V15.57h2.5A.75.75,0,0,1,30.42,16.32Z"
      />
      <path
        class="u-fill-w"
        d="M23.66,22.29v1.3H22.44V23a.75.75,0,0,1,.75-.75Z"
      />
      <path
        class="u-fill-w"
        d="M17.1,30.51h-.39a.75.75,0,0,1-.75-.75v-2.5h.69a4.42,4.42,0,0,0-.12,1,4.69,4.69,0,0,0,.23,1.42A4.28,4.28,0,0,0,17.1,30.51Z"
      />
      <rect
        class="u-fill-w"
        x="15.96"
        y="21.12"
        width="1.5"
        height="3.84"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TemplateAddIcon'
}
</script>
