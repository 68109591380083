<template>
  <div class="c-checkout__products-container">
    <div
      v-for="(cost, index) in costs"
      :key="`additionalCosts-${cost.id}`"
      class="c-checkout__product"
    >
      <div class="o-grid o-grid--center c-checkout__content-full-width">
        <div class="o-grid__col u-8/8 c-checkout__product-line">
          <div class="o-grid o-grid--center c-checkout__content-full-width">
            <div class="o-grid__col u-4/8">
              <div class="o-grid">
                <div class="o-grid__col u-8/8">
                  <textarea-field
                    :id="`cost-description-${cost.id}`"
                    v-model="costs[index].description"
                    :is-store-mode="false"
                    :label="$t('modules.additionalCosts.form.label.description')"
                    :placeholder="$t('modules.additionalCosts.form.label.example')"
                    :required="true"
                    :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                    style-textarea-width="250px"
                    type="text"
                    @change="mx_validate_updateErrorsArray"
                    @register="mx_validate_registerInputsInErrorsArray"
                  />
                </div>
                <div class="o-grid__col u-8/8">
                  <input-field
                    :id="`cost-price-${cost.id}`"
                    v-model="costs[index].price"
                    :is-store-mode="false"
                    :label="$t('modules.additionalCosts.form.label.price')"
                    :placeholder="$t('modules.additionalCosts.form.label.price')"
                    :required="true"
                    :suffix="$world.getCurrencySymbol()"
                    :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                    style-input-width="250px"
                    type="number"
                    @change="mx_validate_updateErrorsArray"
                    @register="mx_validate_registerInputsInErrorsArray"
                  />
                </div>
              </div>
            </div>
            <div class="o-grid__col u-4/8">
              <button-base
                :id="`cost-remove-${cost.id}`"
                :label="$t('modules.additionalCosts.buttons.removeCosts')"
                :on-click-action="() => { return removeCost(cost.id) }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <button-base
      id="add-cost"
      classes="u-ml-size-0"
      :label="$t('modules.additionalCosts.buttons.addAdditionalCosts')"
      :on-click-action="() => { return addCosts() }"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'

import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'

import MixinValidate from '@/mixins/validate'
import TextareaField from '@/components/common/TextareaField'

const additionalCosts = (id, description = '', price = 0) => {
  return {
    id: id,
    description: description,
    price: price
  }
}

export default {
  name: 'AdditionalCosts',
  components: {
    TextareaField,
    InputField,
    ButtonBase
  },
  mixins: [
    MixinValidate
  ],
  data: () => {
    return {
      costs: []
    }
  },
  computed: {
    ...mapGetters({
      getProjectAdditionCosts: 'project/getAdditionalCosts'
    })
  },
  watch: {
    costs: {
      handler: function (n, o) {
        const costs = this.costs.filter(c => Number(c.price) > 0 && c.description !== '')
        this.$emit('update', { costs: costs, update: uuidv4() })

        this.addAdditionalCosts(_.cloneDeep(costs))
      },
      deep: true
    }
  },
  mounted () {
    this.getProjectAdditionCosts.forEach(cost => {
      this.costs.push(additionalCosts(uuidv4(), cost.description, cost.price))
    })
  },
  methods: {
    ...mapActions({
      addAdditionalCosts: 'project/addAdditionalCostsToProject'
    }),
    addCosts () {
      this.costs.push(additionalCosts(uuidv4()))
    },
    removeCost (id) {
      const costIndex = this.costs.findIndex(cost => cost.id === id)

      if (costIndex !== -1) {
        this.costs.splice(costIndex, 1)
      }
    }
  }
}
</script>
