<template>
  <svg
    id="point-circle-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M20,36.46A16.46,16.46,0,1,1,36.46,20,16.47,16.47,0,0,1,20,36.46ZM20,8.54A11.46,11.46,0,1,0,31.46,20,11.47,11.47,0,0,0,20,8.54Z"
    />

  </svg>
</template>

<script>
export default {
  name: 'PointCircleIcon'
}
</script>
