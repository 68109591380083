<template>
  <g
    v-if="active && selectionMode !== config.objects.modes.EDIT"
    :id="'areaCalcGroup-' + id"
    class="no-pointer-events"
  >
    <text v-if="showCalculatedArea">
      <tspan
        :font-size="mx_text_fontSizeArea"
        :stroke-width="mx_text_strokeWidth"
        :x="areaCenter.x"
        :y="areaCenter.y + mx_text_fontSizeArea/3"
        fill="black"
        paint-order="stroke"
        stroke="white"
        text-anchor="middle"
      >
        {{ areaOutput }}

        <tspan>
          {{ $settings.areaUnit }}
        </tspan>
      </tspan>
    </text>
  </g>
</template>
<script>
import config from '@/config'

import MixinMath from '@/mixins/math'
import MixinPoints from '@/mixins/points'
import MixinText from '@/mixins/text'

import { mapActions, mapGetters, mapState } from 'vuex'

/**
     * Shows the object area
     * @displayName Object area
     */
export default {
  mixins: [MixinMath, MixinPoints, MixinText],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    selectionMode: {
      type: [Number, String],
      required: true
    },
    active: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: false,
      default: 'PATH'
    }
  },
  data: () => {
    return {
      config: config
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    }),
    ...mapGetters({
      getObject: 'project/objects/getById'
    }),
    showCalculatedArea () {
      return this.mx_points_filteredPoints.length > 2 || this.type === config.objects.types.CIRCLE
    },
    connectedObject () {
      return this.getObject(this.id)
    },
    areaCenter () {
      switch (this.connectedObject.type) {
        case config.objects.types.PATH:
          return this.mx_math_getCenter(this.mx_points_filteredPoints)
        case config.objects.types.CIRCLE:
          return this.connectedObject.getCircleCenter()
        default:
          return this.mx_math_getCenter(this.mx_points_filteredPoints)
      }
    },
    areaOutput () {
      const area = this.connectedObject.area

      return this.$units.convert(this.mx_math_roundDecimal(area, 2))

      // TODO: Refactor for transform unit
      // if (this.measure.transformUnit.id !== this.measure.unit.id) {
      //     out += ' / ' + this.$units.convert(this.mx_math_roundDecimal(this.convertArea(area), 2)) + ' ' + this.measure.transformUnit.square_svg
      // }
      // return out
    },
    metricSystem () {
      return (this.measure.unit.label === 'm' || this.measure.unit.label === 'cm')
    }
  },
  methods: {
    ...mapActions({
      updateObjectArea: 'project/objects/updateObjectArea'
    }),
    convertArea (val) {
      return Math.pow((Math.sqrt(val) * this.measure.unit.inches) / this.measure.transformUnit.inches, 2)
    }
  }
}
</script>
