<template>
  <backend>
    <template #dialogs>
      <update-group-dialog
        v-if="isDialogVisible('updateGroup')"
        @group-updated="getGroup"
      />
    </template>

    <template #content>
      <div
        v-if="group"
        class="c-backend-settings"
      >
        <div class="o-grid o-grid--center u-mt-size-20">
          <div class="o-grid__col u-10/12">
            <button-base
              id="company-update-company"
              :label="$t('backend.profile.edit')"
              :on-click-action="() => { return updateGroup() }"
              classes="u-mt-size-40 u-ml-size-0"
              style-size="s"
            />
          </div>
        </div>

        <div class="o-grid o-grid--center c-backend-settings u-mb-size-40">
          <div class="o-grid__col u-10/12">
            <div class="o-grid">
              <div class="o-grid__col u-6/12 u-4/12@m u-3/12@l u-mt-size-30 u-pr-size-standard">
                <h3 class="c-typo__h3">
                  {{ $t(`backend.groupSettings.address.title`) }}
                </h3>
                <table>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.name`) }}</th>
                    <td v-if="group.address && group.address.name">
                      <b>{{ group.address.name }}</b>
                    </td>
                  </tr>

                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.website`) }}</th>
                    <td v-if="group.address && group.address.website">
                      <a
                        class="u-color-b1"
                        :href="group.address.website"
                        target="_blank"
                      >{{ group.address.website }}</a>
                    </td>
                  </tr>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.street`) }}</th>
                    <td v-if="group.address && group.address.street">
                      {{ group.address.street }}
                    </td>
                  </tr>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.city`) }}</th>
                    <td v-if="group.address && group.address.city">
                      {{ group.address.city }}
                    </td>
                  </tr>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.state`) }}</th>
                    <td v-if="group.address && group.address.state">
                      {{ group.address.state }}
                    </td>
                  </tr>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.zip`) }}</th>
                    <td v-if="group.address && group.address.zip">
                      {{ group.address.zip }}
                    </td>
                  </tr>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.address.country`) }}</th>
                    <td v-if="group.address && group.address.country">
                      {{ group.address.country }}
                    </td>
                  </tr>
                </table>
              </div>

              <div class="o-grid__col u-6/12 u-4/12@m u-3/12@l u-mt-size-30 u-pr-size-standard">
                <h3 class="c-typo__h3">
                  {{ $t(`backend.groupSettings.units.title`) }}
                </h3>
                <table>
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.units.measurement`) }}</th>
                    <td v-if="group.measurement">
                      {{ group.measurement }}
                    </td>
                  </tr>
                </table>
              </div>

              <div class="o-grid__col u-6/12 u-4/12@m u-3/12@l u-mt-size-30 u-pr-size-standard">
                <h3 class="c-typo__h3">
                  {{ $t(`backend.groupSettings.appearance.title`) }}
                </h3>
                <div class="c-backend-settings__avatar-container-outer u-mt-size-30">
                  <div class="c-backend-settings__avatar-container-inner">
                    <img
                      v-if="group.logo && group.logo.thumb"
                      class="c-backend-settings__avatar"
                      :src="group.logo.thumb"
                    >
                  </div>
                </div>
                <table class="u-mt-size-20">
                  <tr class="u-align-top">
                    <th>{{ $t(`backend.groupSettings.appearance.theme`) }}</th>
                    <td v-if="group.theme && group.theme.theme">
                      {{ group.theme.theme }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import config from '@/config'
import { mapActions, mapGetters } from 'vuex'

import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'
import UpdateGroupDialog from '@/components/backend/groups/dialogs/UpdateGroup'
import ButtonBase from '@/components/common/ButtonBase'

/**
* @displayName Group Settings Component
*/
export default {
  name: 'GroupSettings',
  components: {
    ButtonBase,
    Backend,
    UpdateGroupDialog
  },
  mixins: [
    MixinDialogHandler
  ],
  data: () => {
    return {
      group: null,
      config: config
    }
  },
  computed: {
    ...mapGetters({
      getUserGroup: 'backendAPI/user/getGroup',
      isDialogVisible: 'dialogs/isDialogVisible'
    })
  },
  mounted () {
    this.getGroup()
  },
  methods: {
    ...mapActions({
      readGroup: 'backendAPI/group/read',
      updateMe: 'backendAPI/user/me'
    }),
    async getGroup () {
      this.group = await this.readGroup(parseInt(this.getUserGroup.id))
      this.updateMe()
    },
    async updateGroup () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'updateGroup',
        onOff: true,
        dialogProps: { groupData: this.group }
      })
    }
  }
}
</script>
