import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Statistics Store
 *
 * @returns {{groupStats: []}}
 */
const defaultState = () => {
  return {
    groupStats: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all groups
     *
     * @param state
     * @returns {[]}
     */
  getAllGroupStats (state) {
    return state.groupStats
  },
  /**
     * Has group statistics
     *
     * @param state
     * @returns {boolean}
     */
  hasGroupStats (state) {
    return state.groupStats.length > 0
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_STATS_STATE)
  },
  /**
     * Set group list
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listGroupStats ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.stats + '/groups', null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.STATS_SET_GROUP_STATS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_STATS_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.STATS_SET_GROUP_STATS] (state, groupStats) {
    state.groupStats = groupStats
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
