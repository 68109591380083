<template>
  <g>
    <path
      v-if="anchor !== null"
      :d="anchor.pathData"
      :stroke="pathStroke.anchorStroke"
      :stroke-width="pathStroke.anchorStrokeWidth"
    />
    <path
      v-if="temporaryTracks.pathA !== null"
      :d="temporaryTracks.pathA"
      fill="#64CFFD"
    />
    <path
      v-if="temporaryTracks.pathB !== null"
      :d="temporaryTracks.pathB"
      fill="#AED45B"
    />
    <path
      v-if="temporaryLine !== null"
      :d="temporaryLine.pathData"
      :stroke="pathStroke.temporaryStroke"
      :stroke-dasharray="pathStroke.temporaryStrokeDash"
      :stroke-width="pathStroke.temporaryStrokeWidth"
    />
    <nearest-paths
      v-if="temporaryLine !== null"
      :data="[{path:temporaryLine.pathData, id:'temporary'}]"
      @click="close"
      @hover="lineHover"
    />
    <vnodes :vnodes="modifier" />
  </g>
</template>

<script>
import { mapState } from 'vuex'
import paperjs from 'paper'
import * as editorEventTypes from '@/events/editor-event-types'

import NearestPaths from '@/components//editor/tools/NearestPaths'

import MixinMath from '@/mixins/math'

/**
     * Helper to calculate the new tracks size and to display the cut line movement
     * @displayName Object tools - LineOfSight move cut
     */
export default {
  name: 'LosCutMovePoint',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    },
    NearestPaths
  },
  mixins: [
    MixinMath
  ],
  props: {
    line: {
      type: [Object, Array],
      required: true,
      default: null
    },
    position: {
      type: [Object, Array],
      required: false,
      default: null
    },
    angle: {
      type: Number,
      required: true
    },
    measure: {
      type: [Array, Object],
      required: true
    }
  },
  data: () => {
    return {
      anchor: null,
      handle: null,
      handleActivated: false,
      temporaryLine: null,
      temporaryTracks: {
        a: null,
        b: null,
        pathA: null,
        pathB: null
      },
      lineCenter: null,
      currentDelta: 0,
      hovered: false,
      lineHovered: false,
      selected: false
    }
  },
  computed: {
    ...mapState({
      commonSvgSettings: state => state.common.svg
    }),
    modifier () {
      if (this.handle !== null) {
        return this.$createElement('circle', {
          class: {
            'c-object c-object__circle': true,
            'c-object__circle--active': true,
            'c-object__circle--hover-alt': this.hovered,
            'c-object__circle--selected-alt': this.selected
          },
          attrs: {
            cx: this.handle.x,
            cy: this.handle.y,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          },
          on: {
            mousedown: e => {
              this.activateHandle()
              this.selected = true
              window.addEventListener('mouseup', this.windowMouseUp)
            },
            mouseup: e => {
              this.selected = false
            },
            mouseenter: e => {
              this.hovered = true
            },
            mouseleave: e => {
              this.hovered = false
            }
          }
        })
      }
      return null
    },
    pathStroke () {
      let anchorStroke = '#327EFF'
      let anchorStrokeWidth = this.mx_math_handleElementsOnScale(2)
      let temporaryStroke = anchorStroke
      let temporaryStrokeWidth = anchorStrokeWidth
      const temporaryStrokeDash = this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashLength) +
        ',' + this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashSpace)

      if (this.hovered || this.selected) {
        anchorStroke = '#00F6FF'
        temporaryStroke = anchorStroke
        anchorStrokeWidth = this.mx_math_handleElementsOnScale(3)
        temporaryStrokeWidth = anchorStrokeWidth
      }

      if (this.lineHovered) {
        temporaryStroke = '#00F6FF'
        temporaryStrokeWidth = this.mx_math_handleElementsOnScale(3)
      }

      return {
        anchorStroke: anchorStroke,
        anchorStrokeWidth: anchorStrokeWidth,
        temporaryStroke: temporaryStroke,
        temporaryStrokeWidth: temporaryStrokeWidth,
        temporaryStrokeDash: temporaryStrokeDash
      }
    }
  },
  watch: {
    line: {
      handler: function (n, o) {
        if (n.path !== o.path) {
          this.initializeComponent()
        }
      },
      deep: true
    }

  },
  mounted () {
    this.initializeComponent()
  },
  beforeDestroy () {
    if (this.anchor !== null && this.temporaryLine !== null) {
      this.anchor.remove()
      this.temporaryLine.remove()
      this.temporaryTracks.a.remove()
      this.temporaryTracks.b.remove()
    }

    if (this.handle !== null) {
      this.handle = null
    }
  },
  methods: {
    initializeComponent () {
      this.currentDelta = 0
      this.anchor = new paperjs.Path(this.line.anchor)
      this.anchor.closed = true
      this.temporaryLine = new paperjs.Path(this.line.path)
      this.temporaryLine.closed = false

      this.$_initialize_temporary_tracks(this.line.trackA.path, this.line.trackB.path)

      const handlePoint = this.$_get_handle_point(this.anchor, this.temporaryLine)

      if (handlePoint !== null) {
        this.lineCenter = this.temporaryLine.bounds.clone().center
        this.handle = {
          x: handlePoint.x,
          y: handlePoint.y
        }
        this.$events.fire(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED, { distance: 0 })
      }
    },
    activateHandle () {
      this.handleActivated = true
      window.addEventListener('mousemove', this.moveModifier)
      window.addEventListener('mouseup', this.deactivateHandle)
      this.$events.fire(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED_START)
    },
    moveModifier (evt) {
      // get event point and nearest point to anchor line
      const point = this.mx_math_getCoordinatesFromEvent(evt)
      const newAnchorPoint = this.anchor.getNearestPoint(point)

      // get delta from new point to old point
      const delta = new paperjs.Point(newAnchorPoint.x - this.handle.x, newAnchorPoint.y - this.handle.y)

      this.handle = newAnchorPoint

      // move temporary cut by delta
      this.temporaryLine.translate(delta)

      // distance moved
      const distance = this.$_get_moved_distance(this.temporaryLine.bounds.clone().center)

      this.$_change_temporary_tracks(distance.px)

      // collect data for LoS-Panel to submit
      const temporarySegments = this.temporaryLine.segments
      const dataToSubmit = {
        distance: Number(distance.value),
        p1: { x: temporarySegments[0].point.x, y: temporarySegments[0].point.y },
        p2: { x: temporarySegments[1].point.x, y: temporarySegments[1].point.y }
      }

      this.$events.fire(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED, dataToSubmit)
    },
    deactivateHandle () {
      this.handleActivated = false
      window.removeEventListener('mousemove', this.moveModifier)
      window.removeEventListener('mouseup', this.deactivateHandle)
      this.$events.fire(editorEventTypes.LINE_OF_SIGHT_CUT_MOVED_STOP)
    },
    close () {
      this.$events.fire(editorEventTypes.LINE_OF_SIGHT_CUT_CLEAR_WORKING_DATA)
    },
    lineHover (payload) {
      this.lineHovered = payload.selected !== null
    },
    $_change_temporary_tracks (distance) {
      const center = this.temporaryTracks.a.bounds.clone().bottomCenter
      const top = this.temporaryTracks.a.clone()
      const bottom = this.temporaryTracks.b.clone()
      const line = this.temporaryLine.clone()

      top.rotate(-this.angle, center)
      bottom.rotate(-this.angle, center)
      line.rotate(-this.angle, center)

      const deltaY = line.bounds.topCenter.y - bottom.bounds.topCenter.y

      top.bounds.height = top.bounds.height - distance
      bottom.bounds.height = bottom.bounds.height + distance
      bottom.translate(new paperjs.Point(0, deltaY))

      top.rotate(this.angle, center)
      bottom.rotate(this.angle, center)

      this.temporaryTracks.pathA = top.pathData
      this.temporaryTracks.pathB = bottom.pathData

      top.remove()
      bottom.remove()
      line.remove()
    },
    $_get_handle_point (anchor, line) {
      const intersection = {
        distance: null,
        point: null
      }

      line.segments.forEach((segment, index) => {
        const nearest = anchor.getNearestPoint(segment.point)
        const distance = this.mx_math_getDistance(segment.point, nearest)

        if (intersection.point === null && nearest instanceof paperjs.Point) {
          intersection.distance = distance
          intersection.point = nearest
        } else {
          if (intersection.distance > distance && nearest instanceof paperjs.Point) {
            intersection.distance = distance
            intersection.point = nearest
          }
        }
      })

      return intersection.point
    },
    $_get_value_by_measurement (value) {
      return parseFloat(((value / this.measure.fromPixels) * this.measure.toUnit).toFixed(2))
    },
    $_get_moved_distance (point) {
      const radians = (-Number(this.angle) * Math.PI) / 180

      // rotate moved point around center to get positive/negative direction
      const v1 = {
        x: Math.cos(radians) * (point.x - this.lineCenter.x) - Math.sin(radians) * (point.y - this.lineCenter.y) + this.lineCenter.x,
        y: Math.sin(radians) * (point.x - this.lineCenter.x) + Math.cos(radians) * (point.y - this.lineCenter.y) + this.lineCenter.y
      }

      const x = v1.x - this.lineCenter.x
      const y = v1.y - this.lineCenter.y

      // d = direction
      const d = Math.atan2(y, x)

      const distance = this.mx_math_getDistance(this.temporaryLine.bounds.center, this.lineCenter)
      const value = this.$_get_value_by_measurement(distance)

      return {
        value: (d > 0) ? value * -1 : value,
        px: (d > 0) ? distance * -1 : distance
      }
    },
    $_initialize_temporary_tracks (pathData1, pathData2) {
      const path1 = new paperjs.Path(pathData1)
      const path2 = new paperjs.Path(pathData2)

      if (path1.clone({ insert: false }).rotate(-this.angle, path1.bounds.bottomCenter).bounds.y < path2.clone({ insert: false }).rotate(-this.angle, path1.bounds.bottomCenter).bounds.y) {
        this.temporaryTracks.a = path1
        this.temporaryTracks.b = path2
      } else {
        this.temporaryTracks.a = path2
        this.temporaryTracks.b = path1
      }

      this.temporaryTracks.a.closed = true
      this.temporaryTracks.b.closed = true

      this.temporaryTracks.pathA = this.temporaryTracks.a.pathData
      this.temporaryTracks.pathB = this.temporaryTracks.b.pathData
    },
    windowMouseUp () {
      this.selected = false
      window.removeEventListener('mouseup', this.windowMouseUp)
    }
  }
}
</script>
