<template>
  <div
    v-if="ctrl === 'move' && mx_operations_selectedObjectsCount === 1 && !isCutOut"
    class="c-nav-sub__group c-nav-sub__group--no-margin"
  >
    <button-head-nav
      id="sub-nav-button-duplicate"
      :category="$t('subNav.categories.object')"
      :disabled="shouldBlockGui"
      :label="$t('subNav.object.duplicate')"
      :on-click-action="()=>{return $events.fire(editorEventTypes.OBJECT_DUPLICATE)}"
      :theme="getTheme"
      icon-classes="fas fa-2x fa-clone"
      style-color="light"
    />
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'

import MixinOperations from '@/mixins/operations'
import ButtonHeadNav from '@/components/common/ButtonHeadNav'

export default {
  name: 'DuplicatePan',
  components: {
    ButtonHeadNav
  },
  mixins: [
    MixinOperations
  ],
  data: () => {
    return {
      editorEventTypes: editorEventTypes
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      isCutOut: state => state.project.objects.cutOut
    }),
    ...mapGetters({
      shouldBlockGui: 'events/shouldBlockGui',
      getTheme: 'gui/getTheme'
    })
  }
}
</script>
