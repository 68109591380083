<template>
  <div
    id="drawing-surface"
    ref="surfaceContainer"
    :class="{'mouse-control--draw':(ctrl === 'draw')}"
    class="mouse-control"
  >
    <draw-input
      v-if="(ctrl === 'draw' && currentPath !== null && currentPath.hasPoints(0)) || (ctrl === 'drawDefault' && showDrawInput)"
      ref="drawKeyboardInput"
      @changedDrawLengthByKeyboard="setKeyboardInput"
    />
    <svg
      id="svg-editor"
      ref="editorContainer"
      :viewBox="`${x}, ${y}, ${w}, ${h}`"
      height="100%"
      width="100%"
    >
      <common-svg-modifier />
      <underlay />
      <grid />
      <svg-object
        v-for="item in objects"
        :key="item.id"
        :object="item"
      >
        <template #angles>
          <svg-angles
            :id="item.id"
            :active="isObjectSelected(item.id)"
            :line="null"
            :points="item.getPoints()"
          />
        </template>
        <template #lengths>
          <svg-lengths
            :id="item.id"
            :slot="'lengths'"
            :active="isObjectSelected(item.id)"
            :line="null"
            :points="item.getPoints()"
          />
        </template>
        <template #area-calc>
          <svg-area-calc
            :id="item.id"
            :active="isObjectSelected(item.id)"
            :points="item.getPoints()"
            :selectionMode="item.selectionMode"
            :type="item.type"
          />
        </template>
        <template #product-title>
          <svg-product
            :id="item.id"
            :active="isObjectSelected(item.id)"
            :points="item.getPoints()"
            :product="item.product"
            :selectionMode="item.selectionMode"
            :type="item.type"
          />
        </template>
      </svg-object>
      <svg-object-cut
        v-if="objectCut !== null"
        :object="objectCut"
      >
        <template #angles>
          <svg-angles
            :id="objectCut.id"
            #angles
            :active="isObjectSelected(objectCut.id)"
            :line="null"
            :points="objectCut.getPoints()"
          />
        </template>
        <template #lengths>
          <svg-lengths
            :id="objectCut.id"
            :active="isObjectSelected(objectCut.id)"
            :line="null"
            :points="objectCut.getPoints()"
          />
        </template>
      </svg-object-cut>
      <svg-path
        v-if="currentPath !== null && currentPath.hasPoints(0)"
        id="-999"
        :line="pseudoPosition"
        :points="currentPath.getPoints()"
      >
        <template #angles>
          <svg-angles
            :id="'pseudoAngles'"
            :drawing="true"
            :line="pseudoPosition"
            :points="currentPath.getPoints()"
          />
        </template>
        <template #lengths>
          <svg-lengths
            :id="'pseudoLengths'"
            :drawing="true"
            :line="pseudoPosition"
            :points="currentPath.getPoints()"
          />
        </template>
      </svg-path>
      <svg-colliding-snap-points />
      <global-bounding-box />
      <cut-out-bounding-box />
      <reference-line />
      <ruler />
      <default-object
        @defaultObjectHasPoints="toggleDrawInput"
        @defaultObjectResetDrawInput="resetDrawInput"
      />
    </svg>
    <object-menu />
    <circle-menu />
    <handle-menu />
    <div
      v-if="ctrl === 'draw' || showPressEnterInfoText"
      class="c-draw-input c-draw-input--info"
    >
      <div class="c-draw-input__info">
        <span>{{ $t('editor.drawInput.info') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import config from '@/config'
import * as editorEventTypes from '@/events/editor-event-types'

import svgPath from '@/components/svg/Path'
import svgObject from '@/components/svg/Object'
import svgObjectCut from '@/components/svg/ObjectCut'
import svgAngles from '@/components/svg/object/Angles'
import svgAreaCalc from '@/components/svg/object/AreaCalc'
import svgLengths from '@/components/svg/object/Lengths'
import svgProduct from '@/components/svg/object/Product'
import Grid from '@/components/editor/Grid'
import Underlay from '@/components/svg/Underlay'
import CommonSvgModifier from '@/components/svg/CommonSvgModifier'
import svgCollidingSnapPoints from '@/components/svg/CollidingSnapPoints'
import referenceLine from '@/components/editor/tools/ReferenceLine'
import ruler from '@/components/editor/tools/Ruler'
import defaultObject from '@/components/editor/tools/DefaultObject'
import GlobalBoundingBox from '@/components/svg/object/GlobalBBox'
import CutOutBoundingBox from '@/components/svg/object/CutOutBBox'

import DrawInput from '@/components/modules/keyboard/DrawInput'

import CircleMenu from '@/components/editor/menu/Circle'
import HandleMenu from '@/components/editor/menu/Handle'
import ObjectMenu from '@/components/editor/menu/Object'

import MixinEventHandler from '@/mixins/eventHandler'
import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinMath from '@/mixins/math'
import MixinDraw from '@/mixins/draw'
import MixinViewboxPan from '@/mixins/viewboxPan'
import MixinEventManager from '@/events/manager'

/**
     * Drawing area
     * @displayName Surface
     */
export default {
  name: 'Surface',
  components: {
    svgPath,
    svgObject,
    svgObjectCut,
    svgAngles,
    CommonSvgModifier,
    CircleMenu,
    HandleMenu,
    ObjectMenu,
    svgAreaCalc,
    svgLengths,
    svgProduct,
    svgCollidingSnapPoints,
    referenceLine,
    ruler,
    Grid,
    Underlay,
    defaultObject,
    GlobalBoundingBox,
    CutOutBoundingBox,
    DrawInput
  },
  mixins: [
    MixinEventHandler,
    MixinDialogHandler,
    MixinMath,
    MixinDraw,
    MixinViewboxPan,
    MixinEventManager
  ],
  data () {
    return {
      editorRef: null,
      surfaceRect: null,
      path: null,
      showDrawInput: false,
      showPressEnterInfoText: false
    }
  },
  computed: {
    ...mapState({
      currentPath: state => state.pseudo.path,
      mouse: state => state.pseudo.mouse,
      keyboardInput: state => state.pseudo.keyboardInput,
      ctrl: state => state.events.ctrl,
      menu: state => state.editor.menuCircle,
      lastInternalAction: state => state.editor.lastInternalAction,
      selectedObjects: state => state.events.currentSelectedObjects,
      eventsMode: state => state.events.mode,
      objectTranslate: state => state.project.object.translate
    }),
    ...mapGetters({
      x: 'editor/viewbox/x',
      y: 'editor/viewbox/y',
      w: 'editor/viewbox/w',
      h: 'editor/viewbox/h',
      center: 'editor/viewbox/center',
      objects: 'project/objects/getAll',
      objectCut: 'project/objects/getCutObject',
      isObjectSelected: 'events/isObjectSelected',
      editor: 'editor/get',
      getMeasure: 'project/measurement/getMeasure'
    }),
    showLengthsAngles () {
      return this.viewboxScale > config.objects.SHOW_LENGTHS_ANGLES_SCALE
    },
    pseudoPosition () {
      const lastPoint = this.currentPath.getPoints().slice(-1).pop()

      return (this.keyboardInput > 0)
        ? this.mx_math_getPointInDistance(lastPoint, this.mouse, this.keyboardInput, this.getMeasure)
        : this.mouse
    }
  },
  mounted () {
    const surfaceRef = this.$refs.surfaceContainer
    this.surfaceRect = surfaceRef.getBoundingClientRect()

    this.$events.fire(editorEventTypes.VIEWBOX_UPDATED, this.surfaceRect)
    this.setViewBoxProperties({
      x: 0,
      y: 0,
      w: surfaceRef.clientWidth,
      h: surfaceRef.clientHeight,
      ox: (this.surfaceRect.x !== undefined) ? this.surfaceRect.x : this.surfaceRect.left,
      oy: (this.surfaceRect.y !== undefined) ? this.surfaceRect.y : this.surfaceRect.top
    })

    window.addEventListener('resize', this.updateView)

    this.setEditor(this.$refs.editorContainer.attributes.id.value)

    this.$events.on(editorEventTypes.VIEWBOX_MOVE_BY_MOUSE, this.moveViewBoxByMouse)
    this.$events.on(editorEventTypes.PROJECT_CLEAR_OBJECT_SELECTION, this.switchBBox)
    this.$events.on(editorEventTypes.VIEWBOX_MOVE_BY_KEY, this.moveViewBoxByKey)
    this.$events.on('CUT_DRAW_TOOL', this.showPressEnterInfo)
    // this.$events.on('move-project-objects', this.moveSelectedObjects)

    this.editor.addEventListener('mouseleave', this.handleEditorLeave)
    this.editor.addEventListener('dragleave', (evt) => {
      this.$events.fire(editorEventTypes.PRODUCT_DRAG_LEAVE_OBJECT, evt)
    })
    this.editor.addEventListener('dragenter', (evt) => {
      this.$events.fire(editorEventTypes.PRODUCT_DRAG_ENTER_OBJECT, evt)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.updateView)
    this.editor.removeEventListener('mouseleave', this.handleEditorLeave)
    this.editor.removeEventListener('dragleave', (evt) => {
      this.$events.fire(editorEventTypes.PRODUCT_DRAG_LEAVE_OBJECT, evt)
    })
    this.editor.removeEventListener('dragenter', (evt) => {
      this.$events.fire(editorEventTypes.PRODUCT_DRAG_ENTER_OBJECT, evt)
    })
    this.$events.off('CUT_DRAW_TOOL', this.showPressEnterInfo)
    this.mx_manager_remove_all_events()
  },
  destroyed () {
    this.setEditor(null)
  },
  methods: {
    ...mapActions({
      setViewBoxProperties: 'editor/viewbox/properties',
      panViewBox: 'editor/viewbox/pan',
      clearObjectSelection: 'events/clearObjectSelection',
      setKeyboardInput: 'pseudo/setKeyboardInput'
    }),
    ...mapMutations({
      setEditor: 'editor/' + mutationTypes.SET_EDITOR,
      setEditorOffset: 'editor/' + mutationTypes.SET_EDITOR_OFFSET,
      moveViewBox: 'editor/' + mutationTypes.MOVE_EDITOR_VIEW_BOX,
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU,
      setLastInternalAction: 'editor/' + mutationTypes.SET_LAST_INTERNAL_ACTION,
      moveViewBoxByKey: 'editor/viewbox/' + mutationTypes.SET_VIEWBOX_PAN_ARROW_KEYS,
      calculateTranslate: 'project/' + mutationTypes.TRANSLATE_COORDINATES_FOR_OBJECTS
    }),
    showPressEnterInfo (tool) {
      this.showPressEnterInfoText = (tool && tool === 'polygon')
    },
    toggleDrawInput (value) {
      this.showDrawInput = value
    },
    resetDrawInput () {
      if (this.$refs && Object.hasOwnProperty.call(this.$refs, 'drawKeyboardInput') && typeof this.$refs.drawKeyboardInput !== 'undefined') {
        this.$refs.drawKeyboardInput.resetInput()
      }
    },
    /**
     * Get called by event, Update the drawing area
     * @param evt
     * @public
     */
    updateView (evt) {
      const surfaceRef = this.$refs.surfaceContainer
      this.surfaceRect = surfaceRef.getBoundingClientRect()
      this.$events.fire(editorEventTypes.VIEWBOX_UPDATED, this.surfaceRect)
      this.setViewBoxProperties({
        x: 0,
        y: 0,
        w: surfaceRef.clientWidth,
        h: surfaceRef.clientHeight,
        ox: (this.surfaceRect.x !== undefined) ? this.surfaceRect.x : this.surfaceRect.left,
        oy: (this.surfaceRect.y !== undefined) ? this.surfaceRect.y : this.surfaceRect.top
      })
    },
    /**
     * Get called by event, Updates the x,y movement of the drawing area
     * @param evt
     * @public
     */
    moveViewBoxByMouse (evt) {
      this.panViewBox({
        x: evt.movementX,
        y: evt.movementY
      })
    },
    /**
     * Get called by event, Updates the x,y movement of an object
     * @param evt
     * @public
     */
    moveSelectedObjects (evt) {
      this.hideMouseMenu()
      this.calculateTranslate({
        x: evt.movementX,
        y: evt.movementY
      })
    },
    /**
     * Switch bounding box mode, show and hide
     * @public
     */
    switchBBox () {
      if (!this.eventsMode.cut) {
        if (!this.lastInternalAction) {
          this.setLastInternalAction(null)
        }
        this.clearObjectSelection()
      }
    },
    /**
     * Get called by event
     * If mouse leave editor on dragging object then stop drag
     */
    handleEditorLeave () {
      if (this.objectTranslate.x !== 0 && this.objectTranslate.y !== 0) {
        this.$events.fire(editorEventTypes.OBJECT_STOP_DRAGGING, this.selectedObjects)
      }
    }
  }
}
</script>

<style lang="scss">
    #drawing-surface {
        width: 100%;
        height: 100%;
        position: relative;
    }
</style>
