import * as types from '@/vuex/mutation-types'
import devlog from '@/helper/devlog'

import { copyInitialRootState } from '@/vuex/store'
import PouchDBFind from 'pouchdb-find'

import _ from 'lodash'

const PouchDB = process.env.NODE_ENV === 'test'
  ? require('pouchdb-memory')
  : require('pouchdb-browser').default

// pouch db not used yet
PouchDB.plugin(PouchDBFind)
// const DB_NAME = 'ProjectPlannerDB'
// const db = new PouchDB(DB_NAME, { size: 50 })

let historyStates = []
let currentState = null

let beginTransactionState = null

let projectInitialState = null

/**
 * Private function to make a copy of a state with relevant properties
 * @param obj
 * @returns {*}
 */
const makeStateCopy = (obj) => {
  const t = _.cloneDeep(obj)
  delete t.events.ctrl
  t.events.mode.pan = false /* TODO: unit test for this */
  delete t.project.translate
  delete t.project.backendMeta
  delete t.backendAPI
  delete t.backend
  delete t.project.measurement.editor
  delete t.lineOfSight
  delete t.pseudo
  delete t.dialogs
  delete t.common
  delete t.navigation
  delete t.products
  delete t.gui
  return t
}

/**
 * Private function for creating a state string for tracking changes after project creation or load
 * @param rootGetters
 * @param rootState
 * @returns {string}
 */
const makeChangesStateString = (rootState, rootGetters) => {
  const projectStateCopy = _.cloneDeep(rootState.project)
  delete projectStateCopy.measurement.editor
  const surfaceUrl = rootGetters['files/getUnderlayDataUrl']

  return JSON.stringify({
    underlay: surfaceUrl,
    project: projectStateCopy
  })
}

/**
 * Cleans the project state from properties that should not be compared when checking state equality
 * @param {String}stateString
 */
const cleanProjectCompareData = (stateString) => {
  const comparedData = JSON.parse(stateString)
  if (comparedData !== null) {
    const project = comparedData.project
    project.objects.objects.forEach(obj => {
      delete obj.selectionMode
    })
  }
  return JSON.stringify(comparedData)
}

/**
 * Restores relevant vuex states in different modules from given state
 * @param commit
 * @param payload
 */
const restoreState = (commit, payload) => {
  const state = payload.state
  const restoreCompleteProject = payload.restoreCompleteProject
  const withFiles = payload.withFiles
  commit('pseudo/' + types.SET_FROM_HISTORY, state.pseudo, { root: true })
  commit('events/' + types.SET_FROM_HISTORY, state.events, { root: true })
  // restore objects and layer or complete project
  if (restoreCompleteProject) {
    commit('project/' + types.SET_FROM_HISTORY, state.project, { root: true })
  } else {
    commit('project/objects/' + types.SET_FROM_HISTORY, state.project.objects,
      { root: true })
    commit('project/layers/' + types.SET_FROM_HISTORY, state.project.layers,
      { root: true })
  }
  // restore file module
  if (withFiles) {
    commit('files/' + types.SET_FROM_HISTORY, state.files, { root: true })
  }
  // reset translate
  commit('project/' + types.SET_TRANSLATE_COORDINATES_FOR_OBJECTS,
    { x: 0, y: 0 }, { root: true })
  // reset collision points
  commit('project/' + types.SET_COLLISION_POINTS, [], { root: true })
}

const state = {
  pointer: 0,
  max: 21
}

const actions = {
  /**
     * Check if project state and/or underlay has changes
     * @param state
     * @param getters
     * @param rootState
     * @param rootGetters
     * @returns {boolean}
     */
  projectHasChanges ({ state, getters, rootState, rootGetters }) {
    return cleanProjectCompareData(projectInitialState) !== cleanProjectCompareData(makeChangesStateString(rootState, rootGetters))
  },
  /**
     * Inits the db
     * @param dispatch
     * @returns {Promise<void>}
     */
  /* async initHistory({dispatch}) {
        // debug: destroy db
        await db.destroy()
        db = new PouchDB(DB_NAME, {size: 50})
        //dispatch('loadHistory')
    }, */
  /**
     * Load history items from db
     * @param commit
     * @returns {Promise<void>}
     */
  /* async loadHistory({commit}) {
        await db.createIndex({
            index: {fields: ['type']}
        })
        let items = await db.find({
            selector: {
                type: 'history'
            }
        })
        commit(types.SET_HISTORY_ITEMS, items.docs)
    }, */
  /**
     * Insert a new history item (and clear follow up)
     * @param commit
     * @param dispatch
     * @param stateItems
     * @returns {Promise<void>}
     */
  /* async insertHistoryItem({commit, dispatch}, stateItems) {
        if (state.pointer !== state.historyItems.length) {
            // remove old states
        }
        await db.put({
            _id: 'history_' + (new Date()).getTime(),
            type: 'history',
            stateItems: stateItems
        })
        await dispatch('loadHistory')
    }, */
  /**
     * Step back one entry
     * @param commit
     * @param dispatch
     * @param state
     * @param rootState
     * @returns {Promise<void>}
     */
  async stepBack ({ commit, dispatch, state, rootState }) {
    if (state.pointer > 0) {
      commit(types.SET_POINTER, state.pointer - 1)
      const prevState = historyStates[state.pointer]
      restoreState(commit, { state: prevState })
    }
    devlog.info('\n⬅ Step back made. Pointer: ' + state.pointer)
  },

  /**
     * Step forward one entry
     * @param commit
     * @param dispatch
     * @param state
     * @param rootState
     * @returns {Promise<void>}
     */
  async stepForward ({ commit, dispatch, state, rootState }) {
    if (state.pointer < historyStates.length - 1) {
      commit(types.SET_POINTER, state.pointer + 1)
      const nextState = historyStates[state.pointer]
      restoreState(commit, { state: nextState })
    } else if (state.pointer === historyStates.length - 1) {
      commit(types.SET_POINTER, state.pointer + 1)
      const nextState = currentState
      restoreState(commit, { state: nextState })
    }
    devlog.info('\n⮕ Step forward made. Pointer: ' + state.pointer)
  },

  /**
     * Tracks the current state, runs commands in given function and stores the new state
     * @param commit
     * @param rootState
     * @param commands
     */
  trackState ({ commit, rootState }, commands) {
    const historyState = makeStateCopy(rootState)
    commands()
    const currentState = makeStateCopy(rootState)
    if (JSON.stringify(historyState) !== JSON.stringify(currentState)) {
      devlog.info('%c\n⬤ %cTrack state transaction', 'color: blue; background-color: #222', '')
      commit(types.STORE_HISTORY_AND_CURRENT_STATE,
        { historyState: historyState, currentState: currentState })
    }
  },

  /**
     * Tracks the current state if payload.condition, if not, it only runs commands without saving
     * @param commit
     * @param rootState
     * @param payload
     * @param payload.commands - commands that should be done before trackstate
     * @param payload.runSave - condition for trackstate; if true, than it will be made
     */
  trackStateWithCondition ({ commit, rootState }, payload) {
    const historyState = makeStateCopy(rootState)
    payload.commands()
    devlog.info('%c\n⬤ %cConditioned track state transaction - commands before: done', 'color: lightblue; background-color: #222', '')
    if (payload.runSave) {
      const currentState = makeStateCopy(rootState)
      if (JSON.stringify(historyState) !== JSON.stringify(currentState)) {
        devlog.info('%c⬤ %cConditioned track state transaction - done', 'color: lightblue; background-color: #222', '')
        commit(types.STORE_HISTORY_AND_CURRENT_STATE,
          { historyState: historyState, currentState: currentState })
      }
    }
  },

  /**
     * Starts the tracking of changes, should end with "endStateTransaction"
     * @param rootState
     */
  beginStateTransaction ({ rootState }) {
    if (beginTransactionState === null && !rootState.events.mode.cut) {
      beginTransactionState = makeStateCopy(rootState)
      devlog.info('%c\n⬤ %cBegin state transaction', 'color: yellow; background-color: #222', '')
    }
  },

  /**
     * Ends the tracking of changes started with "endStateTransaction"
     * @param rootState
     * @param commit
     */
  endStateTransaction ({ rootState, commit }) {
    if (beginTransactionState && !rootState.events.mode.cut) {
      const endTransactionState = makeStateCopy(rootState)
      if (JSON.stringify(beginTransactionState) !==
                JSON.stringify(endTransactionState)) {
        devlog.info('%c⬤ %cEnd state transaction', 'color: green; background-color: #222', '')
        commit(types.STORE_HISTORY_AND_CURRENT_STATE, {
          historyState: beginTransactionState,
          currentState: endTransactionState
        })
      }
      beginTransactionState = null
    }
  },

  /**
     * Restores the last state before start of beginSTateTransaction. Used for breaking state recording; typically by Escape while drawing
     * @param commit
     * @param stateObj
     */
  breakStateTransaction ({ state, states }) {
    devlog.info('%c⬤ %cBreak state transaction', 'color: red; background-color: #222', '')
    beginTransactionState = null
  },

  /**
     * Restores a given state object and clears history states, resets pointer. Used for loading projects
     * @param commit
     * @param stateObj
     */
  resetWithState ({ commit }, stateObj) {
    restoreState(commit, { state: stateObj, withFiles: true, restoreCompleteProject: true })
    commit('events/' + types.RESET_STATE, null, { root: true })
    commit('pseudo/' + types.RESET_STATE, null, { root: true })
    commit('navigation/' + types.RESET_STATE, null, { root: true })
    historyStates = []
    currentState = state
    commit(types.SET_POINTER, 0)
  },

  /**
     * Resets the complete state to the initial root state
     * @param dispatch
     */
  resetToInitialState ({ dispatch }) {
    dispatch('resetWithState', copyInitialRootState())
  },
  /**
     * Stores the projects states that a relevant for tracking a change in the project, stored after creation or loading a project
     * @param commit
     * @param rootState
     * @param rootGetters
     */
  setProjectInitialState ({ rootState, rootGetters }) {
    projectInitialState = makeChangesStateString(rootState, rootGetters)
  }
}

const mutations = {
  /* [types.SET_HISTORY_ITEMS](state, items) {
        state.historyItems = items
        state.pointer = items.length
        console.log(JSON.parse(JSON.stringify(state.historyItems)))
        console.log('new state pointer ', state.pointer)
    }, */
  [types.SET_POINTER] (state, position) {
    state.pointer = position
  },
  [types.STORE_HISTORY_AND_CURRENT_STATE] (state, states) {
    if (historyStates.length && state.pointer < historyStates.length) {
      const del = historyStates.length - state.pointer
      devlog.info('Removing history states: From ' + state.pointer + ' ' + del + ' entries')
      historyStates.splice(state.pointer, del)
    }

    historyStates.push(states.historyState)
    currentState = states.currentState

    if (historyStates.length + 1 > state.max) {
      historyStates.splice(0, 1)
    } else {
      state.pointer = state.pointer + 1
    }

    devlog.info('History entry set. Length: ' + historyStates.length + ' pointer: ' + state.pointer)
  }

}

const getters = {
  redoPossible (state) {
    return state.pointer < historyStates.length
  },
  undoPossible (state) {
    return state.pointer > 0
  },
  currentStateCopy (state, getters, rootState) {
    return makeStateCopy(rootState)
  },
  projectInitialState (state) {
    return projectInitialState
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
