var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-los__panel-item"},[(_vm.allowedToSplit && !_vm.isWasteObjectSet && !_vm.isWasteTrack)?_c('panel-container',[_c('button-base',{attrs:{"id":"product-track-information-split","disabled":_vm.getSelectedTracks.length > 1,"label":_vm.$t('editor.lineOfSight.track.split'),"on-click-action":() => { return _vm.splitSelectedTrack() },"classes":"u-mr-size-0","style-min-width":"180px"}})],1):_vm._e(),_c('panel-container',{attrs:{"title":_vm.$t('editor.lineOfSight.track.selected.title')}},[_c('div',{staticClass:"c-los__tracks"},[_c('table',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('editor.lineOfSight.track.selected.name')))]),_c('th',[_vm._v(_vm._s(_vm.$t('editor.lineOfSight.track.selected.width')))]),_c('th',[_vm._v(_vm._s(_vm.$t('editor.lineOfSight.track.selected.length')))]),_c('th',[_vm._v(_vm._s(_vm.$t('editor.lineOfSight.track.selected.oversize')))])]),_vm._l((_vm.getSelectedTracks),function(track,key){return _c('tr',{key:key},_vm._l((track.getInformation()),function(info,infoIndex){return _c('td',{key:infoIndex},[_vm._v(" "+_vm._s(info)+" ")])}),0)})],2)])]),(!_vm.isWasteObjectSet && !_vm.isWasteTrack)?_c('panel-container',{attrs:{"title":_vm.$t('editor.lineOfSight.track.hillValley')}},[(_vm.hillValley !== null)?_c('hill-valley-configuration',{attrs:{"length":_vm.hillValley.length,"max-length":_vm.getObjectProduct.length,"name":_vm.hillValley.name,"unit":_vm.getObjectProduct.unit},on:{"oversizeChangedByConfigurator":_vm.updateOversize}}):_vm._e(),_c('input-field',{key:`to-${_vm.getOversize}`,attrs:{"id":"track-oversize","isStoreMode":false,"label":_vm.$t('editor.lineOfSight.track.oversize', {unit: _vm.$t(`backend.materials.units.${_vm.getObjectProduct.unit}`)}),"validate":(v) => {
        return _vm.mx_validate_validateNotNegativeNumber(v)
          && v <= _vm.getObjectProduct.length - _vm.getLongestTrackBySelected.length
      },"type":"number"},on:{"change":_vm.setOversize},model:{value:(_vm.oversize),callback:function ($$v) {_vm.oversize=$$v},expression:"oversize"}}),_c('button-base',{attrs:{"id":"product-track-information-set-oversize","disabled":_vm.error,"label":_vm.$t('common.save'),"on-click-action":() => { return _vm.setTrackOversize() },"classes":"u-mr-size-0","style-min-width":"180px"}}),(_vm.error)?_c('div',{staticClass:"c-los__warning",domProps:{"innerHTML":_vm._s(_vm.$t('editor.lineOfSight.track.warning',
                 {
                   unit: _vm.$t(`backend.materials.units.${_vm.getObjectProduct.unit}`),
                   maxLength: _vm.mx_math_roundDecimal(_vm.getObjectProduct.length - _vm.getLongestTrackBySelected.length, 2)
                 }))}}):_vm._e()],1):_vm._e(),_c('panel-container',[(_vm.hillValley !== null)?_c('div',{staticClass:"c-los__labeling"},[_c('h3',[_vm._v(_vm._s(_vm.$t('editor.lineOfSight.track.totalLength')))]),_c('h3',[_vm._v(_vm._s(_vm.totalLength)+" "+_vm._s(_vm.$t(`backend.materials.units.${_vm.getObjectProduct.unit}`)))])]):_vm._e()]),(_vm.getSelectedTracks.length === 1)?_c('panel-container',{attrs:{"title":_vm.$t('editor.lineOfSight.track.waste.title')}},[_c('los-waste-management',{attrs:{"track":_vm.getSelectedTracks[0]}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }