<template>
  <div
    :id="id"
    :class="[label ? 'c-textarea-field__container--labeled' : '', 'c-textarea-field__container', classes]"
  >
    <label
      v-if="label"
      :for="id + '-textarea-field'"
      class="c-typo__label u-mt-size-5"
      v-html="required ? label + ' *': label"
    />
    <div
      :class="containerComputedClasses"
      :style="`width: ${styleTextareaWidth}`"
    >
      <textarea
        :id="id + '-textarea-field'"
        ref="textarea"
        :disabled="disabled"
        :maxlength="maxlength"
        :placeholder="placeholder"
        :readonly="isReadOnly"
        :rows="rows"
        :value="tempValue"
        class="c-textarea-field"
        @focusin="setFocusHelper(true)"
        @focusout="setFocusHelper(false)"
        @input="updateInput({val: $event.target.value, el: 'textarea'})"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import MixinValidate from '@/mixins/validate'
import * as mutationTypes from '@/vuex/mutation-types'

/**
 * The TextareaField Component.
 * @displayName Textarea Field
 */

export default {
  name: 'TextareaField',
  mixins: [
    MixinValidate
  ],
  props: {
    /**
     * Additional CSS classes for the whole component
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * Disables the whole component
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Defines if this field should be focused while mounted
     */
    focusOnMount: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Defines if the payload will be emitted or just the value. If payload, then it is possible to use custom v-model and validate
     */
    isStoreMode: {
      type: Boolean,
      default: true,
      required: false
    },
    /**
     * Description label displayed near the component
     */
    label: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines the maximum length of an input string
     */
    maxlength: {
      type: Number,
      default: null,
      required: false
    },
    /**
     * Placeholder for the textarea field
     */
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines if the textarea is 'read-only'
     */
    isReadOnly: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Informs with a '*' after the label that this field is required in a form
     * (it doesn't perform any check!)
     */
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Defines a number of rows in textarea
     */
    rows: {
      type: Number,
      default: 4,
      required: false
    },
    /**
     * Defines the width of the textarea field
     */
    styleTextareaWidth: {
      type: String,
      default: '90px',
      required: false
    },
    /**
     * Additional CSS classes for the textarea field
     */
    textareaClasses: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * Inline function for validating an textarea value<br>
     * <b>Example:</b><br>
     * <i>(v) => {return mx\_validate\_validatePositiveNumber(v)}</i>
     * @values false, (v)=>{validation}
     */
    validate: {
      type: [Function, Boolean],
      default: false,
      required: false
    },
    /**
     * @model
     * Current item (bind via v-model)
     */
    value: {
      required: false,
      type: null,
      default: null
    }
  },
  data: () => {
    return {
      // internal value which is always shown despite this, if it's correct or not
      tempValue: 0,
      focus: false,
      error: false,
      errorOnRegister: false
    }
  },
  computed: {
    containerComputedClasses () {
      return [
        'c-textarea-field-container-inner',
        'c-textarea-field-container-inner--small',
        this.focus ? 'c-textarea-field-container-inner--focus' : '',
        !this.error ? '' : 'c-textarea-field-container-inner--error',
        this.textareaClasses
      ]
    }
  },
  watch: {
    // for changes from outside - still with validation
    value (val) {
      this.updateInput({ val: val, el: 'watcher' })
    },
    disabled (val) {
      this.validateInput()
    }
  },
  mounted () {
    this.tempValue = this.value
    this.registerInput()
    if (this.focusOnMount) {
      this.setFocus()
    }
  },
  beforeDestroy () {
    this.setInputFocus(false)
  },
  methods: {
    ...mapMutations({
      setInputFocus: 'events/' + mutationTypes.SET_INPUT_FOCUS
    }),
    registerInput () {
      if ({}.toString.call(this.validate) === '[object Function]') {
        this.errorOnRegister = !this.validate(this.tempValue)
      }
      /**
       * Triggers on 'beforeMount' to register the component in 'validate' mixin
       *
       * @event register
       * @property {{id: string, value: *, error: boolean}} payload - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       * @property {*} value - Current value
       * @property {boolean} error - Error if current value doesn't pass the validation
       */
      this.$emit('register', { id: this.id, value: this.value, error: this.errorOnRegister })
    },
    updateInput (payload) {
      this.tempValue = payload.val

      // prevent from updating two times (because of the watcher)
      this.validateInput()
      if (payload.el !== 'watcher') {
        this.returnValue(payload.val)
      }
    },
    validateInput () {
      if (this.validate !== false) {
        this.error = !this.validate(this.tempValue)
        if (!this.isStoreMode) {
          /**
           * Additional emit of input changes - it reacts only on internal changes and ignores those which comes from outside (watcher)<br>
           * Used mostly for registering changes in validate mixin<br><br>
           * Triggers when the value changes - made for custom v-model
           * which gives a possibility to check error and decide if the value should be saved in store
           *
           * @event change
           * @property {{id: string, value: *, error: boolean}} payload - Emitted payload
           * @property {string} id - ID of this component (also used in html and tests)
           * @property {*} value - Current value
           * @property {boolean} error - Error if current value doesn't pass the validation
           */
          this.$emit('change', { id: this.id, value: this.value, error: this.error })
        }
      }
    },
    returnValue (val) {
      if (this.isStoreMode) {
        /**
         * Triggers when the value changes - made for custom v-model
         * which gives a possibility to check error and decide if the value should be saved in store
         *
         * @event input - store mode
         * @property {{id: string, value: *, error: boolean}} value - Emitted payload
         * @property {string} id - ID of this component (also used in html and tests)
         * @property {*} value - Current value
         * @property {boolean} error - Error if current value doesn't pass the validation
         */
        this.$emit('input', { id: this.id, value: val, error: this.error })
      } else {
        /**
         * Triggers when the value changes and on 'mounted' - made for standard v-model
         *
         * @event input
         * @property {*} value - Emitted value
         */
        this.$emit('input', val)
      }
    },
    setFocusHelper (val) {
      this.setInputFocus(val)
      this.focus = val
    },
    setFocus () {
      this.$nextTick(
        () => {
          this.$refs.textarea.focus()
        }
      )
    }
  }
}
</script>
