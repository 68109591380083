import * as types from './../mutation-types'
import { withHistoryMutation } from '../history-helper'

const initState = () => {
  return {
    sidenav: null,
    dialogs: {
      visible: {
        measurementSettings: false
      },
      subscreen: {
        measurementSettings: 1
      }
    }
  }
}

const state = () => initState()

const getters = {
  dialogVisible (state) {
    for (const dialog in state.dialogs.visible) {
      if (Object.prototype.hasOwnProperty.call(state.dialogs.visible, dialog)) {
        if (state.dialogs.visible[dialog] === true) {
          return true
        }
      }
    }
    return false
  }
}

const actions = {
  async closeProject ({ dispatch, commit }, route) {
    switch (route) {
      case 'Login': {
        dispatch('history/resetToInitialState', null, { root: true })
        dispatch('project/resetState', null, { root: true })
        await dispatch('backendAPI/logout', null, { root: true })
        break
      }
      default:
        dispatch('history/resetToInitialState', null, { root: true })
        dispatch('project/resetState', null, { root: true })
        break
    }
  }
}

const mutations = withHistoryMutation({
  [types.SUBNAV_DIALOGS_TOGGLE] (state, payload) {
    state.dialogs.visible[payload.dialog] = payload.onOff
    if (Object.prototype.hasOwnProperty.call(state.dialogs.subscreen, payload.dialog)) {
      state.dialogs.subscreen[payload.dialog] = 1
    }
  },
  [types.SUBNAV_DIALOGS_SUBSCREEN] (state, payload) {
    state.dialogs.subscreen[payload.dialog] = payload.val
  },
  [types.HEADNAV_SET_SIDENAV] (state, sidenav) {
    state.sidenav = sidenav
  }
}, initState)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
