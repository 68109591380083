<template>
  <backend>
    <template #dialogs>
      <create-turf />
      <update-turf />
      <create-pad />
      <update-pad />
      <create-pedestal />
      <update-pedestal />
      <create-other />
      <update-other />
      <create-infill />
      <update-infill />
      <create-seamtape />
      <update-seamtape />
      <create-adhesive />
      <update-adhesive />
      <delete-material :product-type="deleteDialogType" />
      <import-material-dialog v-if="isDialogVisible('importMaterial')" />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-60">
        <div class="o-grid__col u-10/12">
          <materials-cat-menu
            :current-cat="cat"
            :current-sub="sub"
          />
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-30">
        <div class="o-grid__col u-10/12">
          <!-- Subbases -->
          <div
            v-if="cat === 'products'"
            id="location-materials-products"
          >
            <Turf
              v-if="sub === 'turf'"
              @delete-material="setDeleteDialogType"
            />
            <Epdm
              v-if="sub === 'epdm'"
              @delete-material="setDeleteDialogType"
            />
          </div>

          <!-- Subbases -->
          <div
            v-if="cat === 'subbases'"
            id="location-materials-subbases"
          >
            <Others
              v-if="sub === 'others'"
              @delete-material="setDeleteDialogType"
            />
            <Pedestals
              v-if="sub === 'pedestals'"
              @delete-material="setDeleteDialogType"
            />
            <Pads
              v-if="sub === 'pads'"
              @delete-material="setDeleteDialogType"
            />
          </div>

          <!-- Infills -->
          <div
            v-if="cat === 'infills'"
            id="location-materials-infills"
          >
            <Infills @delete-material="setDeleteDialogType" />
          </div>

          <!-- Consumables -->
          <div
            v-if="cat === 'consumables'"
            id="location-materials-consumables"
          >
            <Seamtape
              v-if="sub === 'seamtape'"
              @delete-material="setDeleteDialogType"
            />
            <Adhesives
              v-if="sub === 'adhesives'"
              @delete-material="setDeleteDialogType"
            />
          </div>
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import { mapGetters } from 'vuex'
import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'
import MaterialsCatMenu from '@/components/backend/materials/partials/MaterialsCatMenu'

import ImportMaterialDialog from '@/components/editor/dialogs/ImportMaterial'

import Turf from '@/components/backend/materials/partials/products/Turf'
import CreateTurf from '@/components/backend/materials/partials/products/dialogs/CreateTurf'
import UpdateTurf from '@/components/backend/materials/partials/products/dialogs/UpdateTurf'

import Epdm from '@/components/backend/materials/partials/products/EPDM'

import Pads from '@/components/backend/materials/partials/subbases/Pads'
import CreatePad from '@/components/backend/materials/partials/subbases/dialogs/CreatePad'
import UpdatePad from '@/components/backend/materials/partials/subbases/dialogs/UpdatePad'

import Pedestals from '@/components/backend/materials/partials/subbases/Pedestals'
import CreatePedestal from '@/components/backend/materials/partials/subbases/dialogs/CreatePedestal'
import UpdatePedestal from '@/components/backend/materials/partials/subbases/dialogs/UpdatePedestal'

import Others from '@/components/backend/materials/partials/subbases/Subbases'
import CreateOther from '@/components/backend/materials/partials/subbases/dialogs/CreateSubbase'
import UpdateOther from '@/components/backend/materials/partials/subbases/dialogs/UpdateSubbase'

import Infills from '@/components/backend/materials/partials/infills/Infills'
import CreateInfill from '@/components/backend/materials/partials/infills/dialogs/CreateInfill'
import UpdateInfill from '@/components/backend/materials/partials/infills/dialogs/UpdateInfill'

import Seamtape from '@/components/backend/materials/partials/consumables/Seamtape'
import CreateSeamtape from '@/components/backend/materials/partials/consumables/dialogs/CreateSeamtape'
import UpdateSeamtape from '@/components/backend/materials/partials/consumables/dialogs/UpdateSeamtape'

import Adhesives from '@/components/backend/materials/partials/consumables/Adhesives'
import CreateAdhesive from '@/components/backend/materials/partials/consumables/dialogs/CreateAdhesive'
import UpdateAdhesive from '@/components/backend/materials/partials/consumables/dialogs/UpdateAdhesive'

import DeleteMaterial from '@/components/backend/materials/partials/DeleteMaterial'
/**
 * @displayName Materials Component
 */
export default {
  name: 'Materials',
  components: {
    MaterialsCatMenu,
    Backend,

    Turf,
    CreateTurf,
    UpdateTurf,

    Epdm,

    Pads,
    CreatePad,
    UpdatePad,

    Pedestals,
    CreatePedestal,
    UpdatePedestal,

    Others,
    CreateOther,
    UpdateOther,

    Infills,
    CreateInfill,
    UpdateInfill,

    Seamtape,
    CreateSeamtape,
    UpdateSeamtape,

    Adhesives,
    CreateAdhesive,
    UpdateAdhesive,

    DeleteMaterial,

    ImportMaterialDialog
  },
  mixins: [MixinDialogHandler],
  props: {
    cat: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      deleteDialogType: 'Pad'
    }
  },
  computed: {
    ...mapGetters({
      isDialogVisible: 'dialogs/isDialogVisible'
    })
  },
  methods: {
    setDeleteDialogType (type) {
      this.deleteDialogType = type
    }
  }
}
</script>
