<template>
  <svg
    id="draw-all-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        class="u-fill-w"
        d="M4.49,26.51a2,2,0,0,1-2-2v-20a2,2,0,1,1,4,0v20A2,2,0,0,1,4.49,26.51Z"
      />
      <path
        class="u-fill-w"
        d="M24.46,26.51a2,2,0,0,1-2-2v-20a2,2,0,0,1,4,0v20A2,2,0,0,1,24.46,26.51Z"
      />
      <path
        class="u-fill-w"
        d="M24.46,6.55h-20a2,2,0,0,1,0-4h20a2,2,0,0,1,0,4Z"
      />
      <path
        class="u-fill-w"
        d="M24.46,26.51h-20a2,2,0,1,1,0-4h20a2,2,0,0,1,0,4Z"
      />
    </g>
    <path
      class="u-fill-w"
      d="M39.73,26.46a13.31,13.31,0,0,1-26,4h4.53A9.1,9.1,0,1,0,30.44,18.29V13.78A13.22,13.22,0,0,1,39.73,26.46Z"
    />
    <circle
      class="u-fill-b1"
      cx="4.49"
      cy="24.51"
      r="4.26"
    />
    <circle
      class="u-fill-b1"
      cx="24.46"
      cy="4.55"
      r="4.26"
    />
    <circle
      class="u-fill-b1"
      cx="34.16"
      cy="33.99"
      r="4.26"
    />
  </svg>
</template>

<script>
export default {
  name: 'DrawAllIcon'
}
</script>
