import Paper from 'paper'
import config from '@/config'
import MixinMath from '@/mixins/math'

const paperScope = new Paper.PaperScope().setup(new Paper.Size(1, 1))

const OffsetUtils = {
  offsetPath: function (path, offset, enforeArcs = true) {
    const outerPath = new paperScope.Path({ insert: false })
    const epsilon = paperScope.Numerical.GEOMETRIC_EPSILON

    for (let i = 0; i < path.curves.length; i++) {
      const curve = path.curves[i]
      if (curve.hasLength(epsilon)) {
        const segments = this.getOffsetSegments(curve, offset)
        const start = segments[0]
        if (outerPath.isEmpty()) {
          outerPath.addSegments(segments)
        } else {
          const lastCurve = outerPath.lastCurve
          if (!lastCurve.point2.isClose(start.point, epsilon)) {
            if (enforeArcs || lastCurve.getTangentAtTime(1).dot(start.point.subtract(curve.point1)) >= 0) {
              this.addRoundJoin(outerPath, start.point, curve.point1, Math.abs(offset))
            } else {
              // Connect points with a line
              outerPath.lineTo(start.point)
            }
          }
          outerPath.lastSegment.handleOut = start.handleOut
          outerPath.addSegments(segments.slice(1))
        }
      }
    }
    if (path.isClosed()) {
      if (!outerPath.lastSegment.point.isClose(outerPath.firstSegment.point, epsilon) && (enforeArcs ||
        outerPath.lastCurve.getTangentAtTime(1).dot(outerPath.firstSegment.point.subtract(path.firstSegment.point)) >= 0)) {
        this.addRoundJoin(outerPath, outerPath.firstSegment.point, path.firstSegment.point, Math.abs(offset))
      }
      outerPath.closePath()
    }
    return outerPath
  },

  /**
   * Creates an offset for the specified curve and returns the segments of
   * that offset path.
   *
   * @param {Curve} curve the curve to be offset
   * @param {Number} offset the offset distance
   * @returns {Segment[]} an array of segments describing the offset path
   */
  getOffsetSegments: function (curve, offset) {
    if (curve.isStraight()) {
      const n = curve.getNormalAtTime(0.5).multiply(offset)
      const p1 = curve.point1.add(n)
      const p2 = curve.point2.add(n)
      return [new paperScope.Segment(p1), new paperScope.Segment(p2)]
    } else {
      const curves = this.splitCurveForOffsetting(curve)
      const segments = []
      for (let i = 0, l = curves.length; i < l; i++) {
        const offsetCurves = this.getOffsetCurves(curves[i], offset, 0)
        let prevSegment
        for (let j = 0, m = offsetCurves.length; j < m; j++) {
          const curve = offsetCurves[j]
          const segment = curve.segment1
          if (prevSegment) {
            prevSegment.handleOut = segment.handleOut
          } else {
            segments.push(segment)
          }
          segments.push(prevSegment = curve.segment2)
        }
      }
      return segments
    }
  },

  /**
   * Approach for Curve Offsetting based on:
   *   "A New Shape Control and Classification for Cubic Bézier Curves"
   *   Shi-Nine Yang and Ming-Liang Huang
   */
  offsetCurve_middle: function (curve, offset) {
    const v = curve.getValues()
    const p1 = curve.point1.add(paperScope.Curve.getNormal(v, 0).multiply(offset))
    const p2 = curve.point2.add(paperScope.Curve.getNormal(v, 1).multiply(offset))
    const pt = paperScope.Curve.getPoint(v, 0.5).add(
      paperScope.Curve.getNormal(v, 0.5).multiply(offset))
    const t1 = paperScope.Curve.getTangent(v, 0)
    const t2 = paperScope.Curve.getTangent(v, 1)
    const div = t1.cross(t2) * 3 / 4
    const d = pt.multiply(2).subtract(p1.add(p2))
    const a = d.cross(t2) / div
    const b = d.cross(t1) / div
    return new paperScope.Curve(p1, t1.multiply(a), t2.multiply(-b), p2)
  },

  offsetCurve_average: function (curve, offset) {
    let v = curve.getValues()
    const p1 = curve.point1.add(paperScope.Curve.getNormal(v, 0).multiply(offset))
    const p2 = curve.point2.add(paperScope.Curve.getNormal(v, 1).multiply(offset))
    const t = this.getAverageTangentTime(v)
    const u = 1 - t
    const pt = paperScope.Curve.getPoint(v, t).add(
      paperScope.Curve.getNormal(v, t).multiply(offset))
    const t1 = paperScope.Curve.getTangent(v, 0)
    const t2 = paperScope.Curve.getTangent(v, 1)
    const div = t1.cross(t2) * 3 * t * u

    v = pt.subtract(
      p1.multiply(u * u * (1 + 2 * t)).add(
        p2.multiply(t * t * (3 - 2 * t))))
    const a = v.cross(t2) / (div * u)
    const b = v.cross(t1) / (div * t)
    return new paperScope.Curve(p1, t1.multiply(a), t2.multiply(-b), p2)
  },

  /**
   * This algorithm simply scales the curve so its end points are at the
   * calculated offsets of the original end points.
   */
  offsetCurve_simple: function (crv, dist) {
    // calculate end points of offset curve
    const p1 = crv.point1.add(crv.getNormalAtTime(0).multiply(dist))
    const p4 = crv.point2.add(crv.getNormalAtTime(1).multiply(dist))
    // get scale ratio
    const pointDist = crv.point1.getDistance(crv.point2)
    // TODO: Handle cases when pointDist == 0
    let f = p1.getDistance(p4) / pointDist
    if (crv.point2.subtract(crv.point1).dot(p4.subtract(p1)) < 0) {
      f = -f // probably more correct than connecting with line
    }
    // Scale handles and generate offset curve
    return new paperScope.Curve(p1, crv.handle1.multiply(f), crv.handle2.multiply(f), p4)
  },

  getOffsetCurves: function (curve, offset, method) {
    const errorThreshold = 0.1
    const radius = Math.abs(offset)
    const offsetMethod = this['offsetCurve_' + (method || 'middle')]
    const that = this

    function offsetCurce (curve, curves, recursion) {
      const offsetCurve = offsetMethod.call(that, curve, offset)
      const cv = curve.getValues()
      const ov = offsetCurve.getValues()
      const count = 16
      let error = 0
      for (let i = 1; i < count; i++) {
        const t = i / count
        const p = paperScope.Curve.getPoint(cv, t)
        const n = paperScope.Curve.getNormal(cv, t)
        const roots = paperScope.Curve.getCurveLineIntersections(ov, p.x, p.y, n.x, n.y)
        let dist = 2 * radius
        for (let j = 0, l = roots.length; j < l; j++) {
          const d = paperScope.Curve.getPoint(ov, roots[j]).getDistance(p)
          if (d < dist) { dist = d }
        }
        const err = Math.abs(radius - dist)
        if (err > error) { error = err }
      }
      if (error > errorThreshold && recursion++ < 8) {
        const curve2 = curve.divideAtTime(that.getAverageTangentTime(cv))
        offsetCurce(curve, curves, recursion)
        offsetCurce(curve2, curves, recursion)
      } else {
        curves.push(offsetCurve)
      }
      return curves
    }

    return offsetCurce(curve, [], 0)
  },

  /**
   * Split curve into sections that can then be treated individually by an
   * offset algorithm.
   */
  splitCurveForOffsetting: function (curve) {
    const curves = [curve.clone()] // Clone so path is not modified.
    // const that = this
    if (curve.isStraight()) { return curves }

    function splitAtRoots (index, roots) {
      for (let i = 0, prevT, l = roots && roots.length; i < l; i++) {
        const t = roots[i]
        const curve = curves[index].divideAtTime(
          // Renormalize curve-time for multiple roots:
          i ? (t - prevT) / (1 - prevT) : t)
        prevT = t
        if (curve) { curves.splice(++index, 0, curve) }
      }
    }

    // Recursively splits the specified curve if the angle between the two
    // handles is too large (we use 60° as a threshold).
    // function splitLargeAngles (index, recursion) {
    //   const curve = curves[index]
    //   const v = curve.getValues()
    //   const n1 = paperScope.Curve.getNormal(v, 0)
    //   const n2 = paperScope.Curve.getNormal(v, 1).negate()
    //   const cos = n1.dot(n2)
    //   if (cos > -0.5 && ++recursion < 4) {
    //     curves.splice(index + 1, 0,
    //       curve.divideAtTime(that.getAverageTangentTime(v)))
    //     splitLargeAngles(index + 1, recursion)
    //     splitLargeAngles(index, recursion)
    //   }
    // }

    // Split curves at cusps and inflection points.
    const info = curve.classify()
    if (info.roots && info.type !== 'loop') {
      splitAtRoots(0, info.roots)
    }

    // Split sub-curves at peaks.
    for (let i = curves.length - 1; i >= 0; i--) {
      splitAtRoots(i, paperScope.Curve.getPeaks(curves[i].getValues()))
    }

    // Split sub-curves with too large angle between handles.
    // for (let i = curves.length - 1; i >= 0; i--) {
    // splitLargeAngles(i, 0);
    // }

    return curves
  },

  /**
   * Returns the first curve-time where the curve has its tangent in the same
   * direction as the average of the tangents at its beginning and end.
   */
  getAverageTangentTime: function (v) {
    const tan = paperScope.Curve.getTangent(v, 0).add(paperScope.Curve.getTangent(v, 1))
    const tx = tan.x
    const ty = tan.y
    const abs = Math.abs
    const flip = abs(ty) < abs(tx)
    const s = flip ? ty / tx : tx / ty
    const ia = flip ? 1 : 0 // the abscissa index
    const io = ia ^ 1 // the ordinate index
    const a0 = v[ia]
    const o0 = v[io]
    const a1 = v[ia + 2]
    const o1 = v[io + 2]
    const a2 = v[ia + 4]
    const o2 = v[io + 4]
    const a3 = v[ia + 6]
    const o3 = v[io + 6]
    const aA = -a0 + 3 * a1 - 3 * a2 + a3
    const aB = 3 * a0 - 6 * a1 + 3 * a2
    const aC = -3 * a0 + 3 * a1
    const oA = -o0 + 3 * o1 - 3 * o2 + o3
    const oB = 3 * o0 - 6 * o1 + 3 * o2
    const oC = -3 * o0 + 3 * o1
    const roots = []
    const epsilon = paperScope.Numerical.CURVETIME_EPSILON
    const count = paperScope.Numerical.solveQuadratic(
      3 * (aA - s * oA),
      2 * (aB - s * oB),
      aC - s * oC, roots,
      epsilon, 1 - epsilon)
    // Fall back to 0.5, so we always have a place to split...
    return count > 0 ? roots[0] : 0.5
  },

  addRoundJoin: function (path, dest, center, radius) {
    // return path.lineTo(dest);
    const middle = path.lastSegment.point.add(dest).divide(2)
    const through = center.add(middle.subtract(center).normalize(radius))
    path.arcTo(through, dest)
  }
}

const createObjectCollar = (object, offset, measure) => {
  const path = $_get_paper_object(object)
  const bounds = path.bounds.clone()

  path.translate(new paperScope.Point((0 - bounds.x) * 2, (0 - bounds.y) * 2))

  const result = OffsetUtils.offsetPath(path, offset)

  const subtractResult = result.subtract(path)
  subtractResult.curves.forEach(curve => {
    const lengths = MixinMath.methods.mx_math_calculateLengthsFromPoints(curve.points, measure)
    const removeCurve = lengths.some(length => {
      return length.cl <= 0.01
    })
    if (removeCurve) {
      curve.remove()
    }
  })

  subtractResult.translate(new paperScope.Point((bounds.x * 2), (bounds.y * 2)))

  return (subtractResult.area > 0) ? subtractResult : null
}

const $_get_paper_object = (object) => {
  if (object.type === config.objects.types.CIRCLE) {
    const props = object.getCircleProperties()
    return new paperScope.CompoundPath(new Paper.Path.Circle({
      center: [props.middle.x, props.middle.y],
      radius: props.radius
    }))
  } else {
    return new paperScope.CompoundPath(object.d(true))
  }
}

export { createObjectCollar, OffsetUtils }
