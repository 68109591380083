<template>
  <svg
    id="order-up-total-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path
      class="u-fill-w"
      d="M8.74,23.05A2.45,2.45,0,0,1,7,22.32a2.5,2.5,0,0,1,0-3.54L20.3,5.45,33.43,18.58a2.5,2.5,0,1,1-3.53,3.54l-9.6-9.6-9.79,9.8A2.49,2.49,0,0,1,8.74,23.05Z"
    />
    <path
      class="u-fill-w"
      d="M8.74,34.45A2.5,2.5,0,0,1,7,30.18L20.3,16.85,33.43,30a2.5,2.5,0,0,1-3.53,3.54l-9.6-9.6-9.79,9.8A2.53,2.53,0,0,1,8.74,34.45Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderUpTotalIcon'
}
</script>
