<template>
  <div>
    <div class="c-los__manipulate-track">
      <canvas id="hill-valley-configurator" />
    </div>
    <div class="c-los__labeling">
      <span>H: {{ summary.h }} {{ $t(`backend.materials.units.${unit}`) }}</span>
      <span>L: {{ summary.l }} {{ $t(`backend.materials.units.${unit}`) }}</span>

      <button-icon
        id="hill-valley-reset"
        classes="c-los__reset"
        icon-classes="fas fa-undo"
        style-color="dark"
        style-font-size="l"
        @click="reset"
      >
        <template #popup>
          {{ $t('common.reset') }}
        </template>
      </button-icon>
    </div>
  </div>
</template>

<script>
import Paper from 'paper'

import MixinMath from '@/mixins/math'
import ButtonIcon from '@/components/common/ButtonIcon'

/**
     * Configure a selected track
     * - Add oversize
     * @displayName LineOfSight Panel - Product - Hill and valley
     */
export default {
  name: 'HillValleyConfiguration',
  components: { ButtonIcon },
  mixins: [
    MixinMath
  ],
  props: {
    length: {
      type: [Number, String],
      required: true
    },
    maxLength: {
      type: [Number, String],
      required: true
    },
    unit: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data: () => {
    return {
      configurator: null,
      summary: {
        h: 0,
        l: 0
      },
      changes: 0
    }
  },
  watch: {
    name () {
      this.reset()
    }
  },
  mounted () {
    this.configurator = new Paper.PaperScope().setup('hill-valley-configurator')
    this.$_draw_coordinate_system()
  },
  methods: {
    $_draw_coordinate_system () {
      const bbox = this.configurator.view.bounds

      // Add base line
      const baseLine = new this.configurator.Path.Line(
        new Paper.Point(bbox.left, (bbox.bottom / 2)),
        new Paper.Point(bbox.right, (bbox.bottom / 2))
      )

      baseLine.strokeColor = '#4D4D4D'
      baseLine.dashArray = [5, 5]
      baseLine.name = 'BaseLine'

      // Add vertical right line
      const rightLine = new this.configurator.Path.Line(
        new this.configurator.Point(bbox.right, bbox.top + 5),
        new this.configurator.Point(bbox.right, bbox.bottom - 5)
      )

      rightLine.name = 'RightLine'

      // Add label left
      const labelLeft = new this.configurator.PointText(new this.configurator.Point(2, baseLine.bounds.center.y + 15))
      labelLeft.name = 'LabelLeft'
      labelLeft.content = '0'
      labelLeft.fillColor = '#B2B2B2'
      labelLeft.fontSize = 12

      // Add label right
      const labelRight = new this.configurator.PointText(new this.configurator.Point(0, baseLine.bounds.center.y + 15))
      labelRight.name = 'LabelRight'
      labelRight.content = parseFloat(this.length).toFixed(2)
      labelRight.fillColor = '#B2B2B2'
      labelRight.fontSize = 12

      labelRight.translate(new this.configurator.Point((baseLine.bounds.right - labelRight.bounds.width - 2), 0))

      // Add curve handle
      const curveHandle = new this.configurator.Path.Circle(baseLine.bounds.center, 5)
      curveHandle.name = 'CurveHandle'
      curveHandle.fillColor = '#B2B2B2'

      // Add curve line
      this.$_curveToHandle(baseLine, curveHandle)

      // Bring handle to front
      curveHandle.bringToFront()

      const maxDragPosition = {
        top: 5,
        bottom: bbox.bottom - 5
      }

      // Add mouse drag event
      curveHandle.onMouseDrag = (evt) => {
        let newPositionY = curveHandle.position.y + evt.delta.y

        if (newPositionY - 0.5 <= maxDragPosition.top) {
          newPositionY = maxDragPosition.top
        }

        if (newPositionY - 0.5 >= maxDragPosition.bottom) {
          newPositionY = maxDragPosition.bottom
        }

        if (maxDragPosition.top <= newPositionY && maxDragPosition.bottom >= newPositionY) {
          curveHandle.position.y = newPositionY
          this.$_updateCurveToHandle(curveHandle.position)
          curveHandle.bringToFront()
        }
      }

      // Add mouse up event
      curveHandle.onMouseUp = (evt) => {
        this.$emit('oversizeChangedByConfigurator', { oversize: Number(this.changes) })
      }
    },
    $_updateCurveToHandle (point) {
      const baseLine = this.configurator.project.getItem({ class: 'Path', name: 'BaseLine' })
      const curveToHandle = this.configurator.project.getItem({ class: 'Path', name: 'CurveToHandle' })
      curveToHandle.removeSegments()

      curveToHandle.add(new this.configurator.Point(baseLine.bounds.left, baseLine.bounds.center.y))
      curveToHandle.curveTo(point, new this.configurator.Point(baseLine.bounds.right, baseLine.bounds.center.y))
      this.$_calculateCurveLengthHeight()
    },
    $_calculateCurveLengthHeight () {
      const baseLine = this.configurator.project.getItem({ class: 'Path', name: 'BaseLine' })
      const rightLine = this.configurator.project.getItem({ class: 'Path', name: 'RightLine' })
      const curveHandle = this.configurator.project.getItem({ class: 'Path', name: 'CurveHandle' })

      // calculate arc length, max radius, unit equals px (arc_length = pi * r)
      const max_radius = (Number(this.maxLength) - Number(this.length)) / Math.PI
      const verticalLength = Number(rightLine.length) / 2

      const vertical = verticalLength / max_radius
      // const horizontal = baseLine.length / Number(this.length)

      // calculation
      let x, y
      x = y = 0

      const curveHandleToBaseLinePath = new this.configurator.Path.Line(curveHandle.position, baseLine.bounds.center)

      y = curveHandleToBaseLinePath.length / vertical

      const arcLength = (y * Math.PI)
      x = parseFloat(arcLength).toFixed(2)

      this.summary = {
        h: parseFloat(y).toFixed(2),
        l: x
      }

      curveHandleToBaseLinePath.remove()

      this.changes = x
    },
    $_curveToHandle (baseLine, curveHandle) {
      const curveToHandle = new this.configurator.Path()
      curveToHandle.add(new this.configurator.Point(baseLine.bounds.left, baseLine.bounds.center.y))
      curveToHandle.curveTo(curveHandle.position, new this.configurator.Point(baseLine.bounds.right, baseLine.bounds.center.y))
      curveToHandle.name = 'CurveToHandle'
      curveToHandle.strokeColor = '#B2B2B2'
      curveToHandle.bringToFront()
    },
    reset () {
      const baseLine = this.configurator.project.getItem({ class: 'Path', name: 'BaseLine' })
      const curveHandle = this.configurator.project.getItem({ class: 'Path', name: 'CurveHandle' })
      curveHandle.position = baseLine.bounds.center
      this.$_updateCurveToHandle(curveHandle.position)
      this.$_calculateCurveLengthHeight()
      this.$emit('oversizeChangedByConfigurator', { oversize: Number(this.changes) })
    }
  }
}
</script>
