<template>
  <div>
    <tabs
      :classes="['c-tabs--full-width', 'c-tabs__dialog', 'u-mt-size-20']"
      :current-tab="tab"
      :tabs="tabLabels"
      @tabClicked="changeTab"
    />
  </div>
</template>

<script>
import Tabs from '@/components/common/Tabs'
import config from '@/config'
import { mapState } from 'vuex'

export default {
  components: {
    Tabs
  },
  props: {
    locales: {
      type: Array,
      required: false,
      default: () => Object.values(config.locales)
    }
  },
  computed: {
    ...mapState({
      tab: state => state.dialogs.tab
    }),
    tabLabels () {
      const labels = []

      this.locales.forEach((locale) => {
        labels.push(this.$i18n.messages[this.$i18n.locale].translations.tabs[locale])
      })

      return labels
    }
  },
  methods: {
    changeTab (index) {
      this.$emit('tab-changed', index)
    }
  }
}
</script>
