<template>
  <div>
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: 20,
        perPageDropdown: [10, 20, 50],
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: texts.pageLabel, // for 'pages' mode
        allLabel: texts.allLabel,
      }"
      :row-style-class="rowStyleClass"
      :rows="distributors"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'firstname', type: 'asc'}, {field: 'lastname', type: 'asc'}]
      }"
      theme="melos"
    >
      <template #emptystate>
        <div
          class="u-m-size-40 c-backend__nothing-to-show"
        >
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open" />
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template
        #table-row="props"
      >
        <!-- Buttons -->
        <span
          v-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <button-base
            :id="`distributor-list-resend-email-${props.row.originalIndex}`"
            :disabled="props.row.verified"
            :on-click-action="() => { return resend(props.row.email) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fas fa-paper-plane"
          >
            <template #popup>
              <p v-html="props.row.verified ? $t('backend.distributors.verified') : $t('backend.distributors.resend')" />
            </template>
          </button-base>

          <button-base
            :id="`distributor-list-lock-unlock-${props.row.originalIndex}`"
            :on-click-action="() => { return enable({userId: props.row.id, enabled: !props.row.enabled}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            @mouseenter="hoveredRow = props.index"
            @mouseleave="hoveredRow = -1"
          >
            <template #popup>
              <p v-html="props.row.enabled ? $t('backend.distributors.lock') : $t('backend.distributors.unlock')" />
            </template>
            <template #content>
              <icon
                v-if="props.row.enabled && hoveredRow !== props.index"
                classes="fas fa-lock-open"
              />
              <icon
                v-if="!props.row.enabled && hoveredRow !== props.index"
                classes="fas fa-lock"
              />
              <icon
                v-if="hoveredRow === props.index"
                classes="fas fa-unlock"
              />
            </template>
          </button-base>

          <!-- <button-base
            :id="`distributor-list-downgrade-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'downgradeDistributor', distributorData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fas fa-exchange-alt"
          >
            <template #popup>
              <p v-html="$t('backend.distributors.downgrade')" />
            </template>
          </button-base>-->

          <button-base
            :id="`distributor-list-delete-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'deleteDistributor', distributorData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>
        <span v-if="props.column.field ==='salutation'">
          {{ mx_languages_getCurrentSalutation(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'

import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinLanguages from '@/mixins/languages'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

/**
     * @displayName Distributor List Component
     */
export default {
  name: 'DistributorList',
  components: {
    Icon,
    ButtonBase,
    VueGoodTable
  },
  mixins: [MixinDialogHandler, MixinLanguages],
  data () {
    return {
      columns: [
        {
          field: 'settings',
          sortable: false,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.salutation,
          field: 'salutation',
          thClass: 'vgt-no-min-width'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.firstName,
          field: 'firstname'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.lastName,
          field: 'lastname'

        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.email,
          field: 'email'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.phone,
          field: 'phone'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.group,
          field: 'group.name'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.createdAt,
          field: 'created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd/MM/yy HH:mm:ss',
          thClass: 'u-text-left',
          tdClass: 'u-text-left vgt-min-width'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.distributors.tableLabel.lastChange,
          field: 'updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd HH:mm:ss',
          dateOutputFormat: 'dd/MM/yy HH:mm:ss',
          thClass: 'u-text-left',
          tdClass: 'u-text-left vgt-min-width'
        }
      ],
      hoveredRow: -1
    }
  },
  computed: {
    ...mapGetters({
      distributors: 'backendAPI/distributor/getAll'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    }
  },
  methods: {
    ...mapActions({
      resendInvitation: 'backendAPI/user/resendInvitation',
      enable: 'backendAPI/user/enable'
    }),
    rowStyleClass (row) {
      return row.enabled ? '' : 'disabled'
    },
    openDialog (payload) {
      this.$_mx_dialogHandler_openDialog(
        { dialog: payload.type, onOff: true, dialogProps: { distributorData: payload.distributorData } })
    },
    async resend (email) {
      await this.resendInvitation(email).then(response => {
        if (response) {
          this.$toasted.show(this.$t('toast.resendInvitation.success'), { duration: 7000, type: 'success' })
        } else {
          this.$toasted.show(this.$t('toast.resendInvitation.fail'), { duration: 7000, type: 'error' })
        }
      })
    }
  }
}
</script>
