<template>
  <svg
    id="canvas-fragment-anim"
    viewBox="0 0 200 200"
    width="150px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>fragment-anim</title>
    <path
      id="part-3"
      class="anim fragment-anim-object-3"
      d="M178.85,85.92v89.94a3,3,0,0,1-3,3H85.92a3,3,0,0,1-3-3v-60h31a3,3,0,0,0,3-3v-30h59A3,3,0,0,1,178.85,85.92Z"
    />
    <path
      id="part-2"
      class="anim fragment-anim-object-3"
      d="M116.9,82.93v30a3,3,0,0,1-3,3h-31v-30a3,3,0,0,1,3-3Z"
    />
    <path
      id="part-1"
      class="anim fragment-anim-object-3"
      d="M116.9,23v60h-31a3,3,0,0,0-3,3v30H24a3,3,0,0,1-3-3V23a3,3,0,0,1,3-3h89.94A3,3,0,0,1,116.9,23Z"
    />

    <rect
      id="fragment-anim-object-1"
      class="anim"
      height="95.92"
      rx="2.99"
      width="95.92"
      x="20.98"
      y="19.98"
    />
    <rect
      id="fragment-anim-object-2"
      class="anim"
      height="95.92"
      rx="2.99"
      width="95.92"
      x="82.93"
      y="82.93"
    />
    <g
      id="icon"
      class="anim"
    >
      <path
        id="icon-3"
        d="M157.91,55.32V47.78h8.18a2.68,2.68,0,0,0,2.68-2.69V36.91h7.55A2.67,2.67,0,0,1,179,39.59V55.32A2.67,2.67,0,0,1,176.32,58H160.59A2.68,2.68,0,0,1,157.91,55.32Z"
      />
      <path
        id="icon-2"
        d="M165.4,33.58v8.18a2.66,2.66,0,0,1-2.66,2.67H154.6V36.24a2.66,2.66,0,0,1,2.66-2.66Z"
      />
      <path
        id="icon-1"
        d="M162.09,22.68v7.54h-8.18a2.68,2.68,0,0,0-2.68,2.69v8.18h-7.55A2.67,2.67,0,0,1,141,38.41V22.68A2.67,2.67,0,0,1,143.68,20h15.73A2.68,2.68,0,0,1,162.09,22.68Z"
      />
    </g>
    <path
      id="cursor"
      class="anim"
      d="M55.65,149.34,63,144.58,39,136l8.58,24,4.76-7.35,5.15,5.15a2.34,2.34,0,0,0,3.31-3.31Z"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

    #fragment-anim-object-1, #fragment-anim-object-2 {
        fill: var(--b1);
        opacity: 0.7;
    }

    .fragment-anim-object-3 {
        fill: var(--b1);
        opacity: 0.001; // Mozilla fix (cannot be 0)
    }

    #cursor {
        fill: #fff;
    }

    #icon {
        fill: var(--w);
    }

    /* Animations */

    .anim {
        animation-duration: 5s;
        animation-fill-mode: forwards;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        animation-delay: 0.5s;

        &#cursor {
            animation-name: move-cursor;
            animation-timing-function: ease-in-out;
        }

        &#fragment-anim-object-1 {
            animation-name: select-fragment-anim-object-1, make-invisible;
        }

        &#fragment-anim-object-2 {
            animation-name: select-fragment-anim-object-2, make-invisible;
        }

        &.fragment-anim-object-3 {
            animation-name: make-visible;

            &#part-1 {
                animation-name: move-part-1, make-visible
            }

            &#part-3 {
                animation-name: move-part-3, make-visible
            }
        }

        &#icon {
            animation-name: click-button;
        }
    }

    @keyframes move-cursor {
        0% {
            transform: translate(0, 0);
        }
        20% {
            transform: translate(80px, -10px);
        }
        25% {
            transform: translate(80px, -10px);
        }
        40% {
            transform: translate(20px, -80px);
        }
        45% {
            transform: translate(20px, -80px);
        }
        60% {
            transform: translate(120px, -90px);
        }
        100% {
            transform: translate(120px, -90px);
        }
    }

    @keyframes select-fragment-anim-object-1 {
        0% {
            fill: var(--b1);
        }
        40% {
            fill: var(--b1);
        }
        42% {
            fill: var(--o);
        }
        100% {
            fill: var(--o);
        }
    }

    @keyframes select-fragment-anim-object-2 {
        0% {
            fill: var(--b1);
        }
        20% {
            fill: var(--b1);
        }
        22% {
            fill: var(--o);
        }
        100% {
            fill: var(--o);
        }
    }

    @keyframes click-button {
        0% {
            fill: var(--w);
        }
        60% {
            fill: var(--w);
        }
        60.1% {
            fill: var(--b1);
        }
        100% {
            fill: var(--b1);
        }
    }

    @keyframes make-invisible {
        0% {
            opacity: 0.7;
        }
        60% {
            opacity: 0.7;
        }
        60.1% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        100% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
    }

    @keyframes make-visible {
        0% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        60% {
            opacity: 0.001; // Mozilla fix (cannot be 0)
        }
        60.1% {
            opacity: 0.7;
        }
        100% {
            opacity: 0.7;
        }
    }

    @keyframes move-part-1 {
        0% {
            transform: translate(0, 0);
        }
        70% {
            transform: translate(0, 0);
        }
        80% {
            transform: translate(-8px, -8px);
        }
        100% {
            transform: translate(-8px, -8px);
        }
    }

    @keyframes move-part-3 {
        0% {
            transform: translate(0, 0);
        }
        70% {
            transform: translate(0, 0);
        }
        80% {
            transform: translate(8px, 8px);
        }
        100% {
            transform: translate(8px, 8px);
        }
    }
</style>
