<template>
  <div
    v-if="mx_operations_selectedObjectsCount === 2 && !isCutOut"
    class="c-nav-sub__group"
  >
    <button-head-nav
      id="sub-nav-button-operation-add"
      :category="$t('subNav.categories.operations')"
      :disabled="!unite"
      :label="$t('subNav.operations.add')"
      :on-click-action="() => {mx_operations_booleanOperation('unite')}"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
    >
      <template #icon>
        <operations-add-icon />
      </template>
      <template #popup>
        <add-animation />
      </template>
    </button-head-nav>

    <button-head-nav
      id="sub-nav-button-operation-subtract"
      :category="$t('subNav.categories.operations')"
      :disabled="!subtract"
      :label="$t('subNav.operations.subtract')"
      :on-click-action="() => {mx_operations_booleanOperation('subtract')}"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
    >
      <template #icon>
        <operations-subtract-icon />
      </template>
      <template #popup>
        <subtract-animation />
      </template>
    </button-head-nav>

    <button-head-nav
      id="sub-nav-button-operation-exclude"
      :category="$t('subNav.categories.operations')"
      :disabled="!exclude"
      :label="$t('subNav.operations.exclude')"
      :on-click-action="() => {mx_operations_booleanOperation('exclude')}"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
    >
      <template #icon>
        <operations-exclude-icon />
      </template>
      <template #popup>
        <exclude-animation />
      </template>
    </button-head-nav>

    <button-head-nav
      id="sub-nav-button-operation-fragment"
      :category="$t('subNav.categories.operations')"
      :disabled="!fragment"
      :label="$t('subNav.operations.fragment')"
      :on-click-action="() => {mx_operations_booleanOperation('fragment')}"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      style-color="light"
    >
      <template #icon>
        <operations-fragment-icon />
      </template>
      <template #popup>
        <fragment-animation />
      </template>
    </button-head-nav>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

import MixinOperations from '@/mixins/operations'

import AddAnimation from '@/components/subnav/tooltips/AddAnim'
import SubtractAnimation from '@/components/subnav/tooltips/SubtractAnim'
import ExcludeAnimation from '@/components/subnav/tooltips/ExcludeAnim'
import FragmentAnimation from '@/components/subnav/tooltips/FragmentAnim'
import ButtonHeadNav from '@/components/common/ButtonHeadNav'
import OperationsAddIcon from '@/components/common/icons/operations/OperationsAddIcon'
import OperationsSubtractIcon from '@/components/common/icons/operations/OperationsSubtractIcon'
import OperationsExcludeIcon from '@/components/common/icons/operations/OperationsExcludeIcon'
import OperationsFragmentIcon from '@/components/common/icons/operations/OperationsFragmentIcon'

export default {
  name: 'OperationsPan',
  components: {
    OperationsFragmentIcon,
    OperationsExcludeIcon,
    OperationsSubtractIcon,
    OperationsAddIcon,
    ButtonHeadNav,
    AddAnimation,
    SubtractAnimation,
    ExcludeAnimation,
    FragmentAnimation
  },
  mixins: [
    MixinOperations
  ],
  computed: {
    ...mapState({
      unite: state => state.events.booleanOperations.unite,
      subtract: state => state.events.booleanOperations.subtract,
      exclude: state => state.events.booleanOperations.exclude,
      fragment: state => state.events.booleanOperations.fragment,
      isCutOut: state => state.project.objects.cutOut
    }),
    ...mapGetters({
      getTheme: 'gui/getTheme'
    })
  }
}
</script>
