<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :style-header-height="'90px'"
    :target-dialog="targetDialog"
    :title="`${$t('dialogs.overview.title')}: ${$t('dialogs.overview.projectNo',{number: projectMeta.id})}`"
    :style-content-size-fit="true"
    style-dialog-width="1280px"
    style-dialog-height="670px"
  >
    <template #header>
      <tabs
        :current-tab="page"
        :tabs="tabLabelNames"
        @tabClicked="pagination"
      />
    </template>

    <template #content>
      <div
        v-if="allObjectUpToDate"
        class="c-checkout"
      >
        <div class="o-grid o-grid--center c-checkout__content-container">
          <div
            :class="[ page === 3 ? 'u-8/8' : 'u-8/12' ]"
            class="o-grid__col c-checkout__content-container"
          >
            <screen1-products
              v-if="page === 0"
              :objects="objectsWithProducts"
              :summary="checkoutSummary"
              @close-checkout="close"
              @pagination-checkout="pagination"
            />

            <screen2-tool
              v-if="page === 1"
              :objects="objectsWithProducts"
              :tools="productTools"
              :summary="checkoutSummary"
              @close-checkout="close"
              @pagination-checkout="pagination"
              @checkoutUpdateSeamtape="updateSummarySeamtape"
              @checkoutUpdateAdhesive="updateSummaryAdhesive"
            />
            <checkout-additional
              v-if="page === 2"
              @close-checkout="close"
              @pagination-checkout="pagination"
              @additionalCostsUpdate="updateSummaryAdditionalCosts"
            />
          </div>

          <div
            v-if="page !== 3"
            class="o-grid__col u-4/12  u-pr-size-standard c-checkout__summary-container"
          >
            <checkout-summary :summary="summary" />
          </div>
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import Screen1Products from '@/components/editor/checkout/screens/CheckoutProducts'
import Screen2Tool from '@/components/editor/checkout/screens/CheckoutTool'
import CheckoutAdditional from '@/components/editor/checkout/screens/CheckoutAdditional'

import DialogFrame from '@/components/common/DialogsFrame'
import Tabs from '@/components/common/Tabs'
import CheckoutSummary from '@/components/editor/checkout/CheckoutSummary'

import MixinDialog from '@/mixins/dialogs'
import MixinAlgorithmIntersect from '@/mixins/algorithmIntersect'
import MixinAlgorithmHelper from '@/mixins/algorithm_helper'
import MixinTrackState from '@/mixins/trackstate'
import MixinCosts from '@/mixins/costs'

const ADDITIONAL_COSTS_KEY = 'additionalCosts'

export default {
  name: 'Checkout',
  components: {
    Screen1Products,
    Screen2Tool,
    CheckoutAdditional,
    DialogFrame,
    Tabs,
    CheckoutSummary
  },
  mixins: [
    MixinDialog,
    MixinAlgorithmIntersect,
    MixinAlgorithmHelper,
    MixinTrackState,
    MixinCosts
  ],
  data: () => {
    return {
      targetDialog: 'checkout',
      cannotClose: false,
      allObjectUpToDate: false,
      checkoutSummary: null,
      updateByCosts: true,
      summaryData: []
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      page: state => state.dialogs.subscreen.checkout,
      projectMeta: state => state.project.backendMeta
    }),
    ...mapGetters({
      getObjectById: 'project/objects/getById',
      objectsWithProducts: 'project/objects/objectsWithProducts',
      productTools: 'products/tools',
      getConsumable: 'products/getConsumableById',
      getSeamtapes: 'products/getSeamtapes',
      getAdhesives: 'products/getAdhesives',
      getProjectAdditionCosts: 'project/getAdditionalCosts'
    }),
    summary () {
      const summary = {
        products_sum: 0,
        additional: 0,
        tools_sum: 0,
        total_sum: 0
      }

      if (this.checkoutSummary && this.updateByCosts) {
        this.checkoutSummary.forEach((sum, index) => {
          if (index !== ADDITIONAL_COSTS_KEY) {
            summary.products_sum += Number(sum.areaPrice)
            if (sum.seamtape && Object.prototype.hasOwnProperty.call(sum.seamtape, 'value')) {
              summary.tools_sum += Number(sum.seamtape.value)
            }
            if (sum.adhesive && Object.prototype.hasOwnProperty.call(sum.adhesive, 'value')) {
              summary.tools_sum += Number(sum.adhesive.value)
            }
          } else {
            sum.forEach(cost => {
              if (Number(cost.price) > 0) {
                summary.additional += Number(cost.price)
              }
            })
          }
        })

        summary.total_sum = Number((Number(summary.products_sum) + Number(summary.tools_sum) + Number(summary.additional)).toFixed(2))
      }

      return summary
    },
    tabLabelNames () {
      return [
        this.$i18n.messages[this.$i18n.locale].dialogs.overview.screen_1.header,
        this.$i18n.messages[this.$i18n.locale].dialogs.overview.screen_2.header,
        this.$i18n.messages[this.$i18n.locale].dialogs.overview.additionalCosts.header
      ]
    }
  },
  mounted: function () {
    this.checkoutCheckObjects()
  },
  beforeDestroy () {
    this.checkoutSummary = null
    this.summaryData = []
  },
  methods: {
    ...mapActions({
      addResultToObject: 'project/objects/addLawnCalculationForObject'
    }),
    updateSummaryAdditionalCosts (data) {
      this.checkoutSummary.set(ADDITIONAL_COSTS_KEY, data.costs)
      this.updateByCosts = data.update
      this.summaryData = Array.from(this.checkoutSummary.values())
    },
    updateSummarySeamtape (objectId) {
      if (this.checkoutSummary && this.checkoutSummary.has(objectId)) {
        const data = this.checkoutSummary.get(objectId)
        const obj = this.getObjectById(objectId)

        if (obj.product.seamtape) {
          if (data.seamtape === null || (data.seamtape && data.seamtape.tapeId !== obj.product.seamtape.id)) {
            const seamtapeID = obj.product.seamtape.id
            const seamtape = this.getSeamtapes.find(tape => tape.original.id === seamtapeID)

            if (typeof seamtape !== 'undefined') {
              data.seamtape = this.mx_costs_seamtape(seamtape, obj)
            }
          }
        } else {
          data.seamtape = null
        }
        this.checkoutSummary.set(objectId, data)
        this.summaryData = Array.from(this.checkoutSummary.values())
      }
    },
    updateSummaryAdhesive (objectId) {
      if (this.checkoutSummary && this.checkoutSummary.has(objectId)) {
        const data = this.checkoutSummary.get(objectId)
        const obj = this.getObjectById(objectId)
        if (obj.product.adhesive) {
          if (data.adhesive === null || (data.adhesive && data.adhesive.adhesiveId !== obj.product.adhesive.id)) {
            const adhesiveID = obj.product.adhesive.id
            const adhesive = this.getAdhesives.find(glue => glue.original.id === adhesiveID)

            if (typeof adhesive !== 'undefined') {
              data.adhesive = this.mx_costs_adhesive(adhesive, obj.area)
            }
          }
        } else {
          data.adhesive = null
        }
        this.checkoutSummary.set(objectId, data)
        this.summaryData = Array.from(this.checkoutSummary.values())
      }
    },
    calculateSummary () {
      const summary = new Map()

      this.objectsWithProducts.forEach(object => {
        if (object.product !== null) {
          const turfCosts = this.mx_costs_turf(object.product)
          const subbasesCosts = this.mx_costs_total_subbases(object.product.subbases, object.area)
          const infillsCosts = this.mx_costs_total_infills(object.product.infills, object.area)
          let seamtapeCosts = null
          let adhesiveCosts = null

          if (typeof object.product.seamtape !== 'undefined' && object.product.seamtape !== null) {
            const seamtapeID = object.product.seamtape.id
            const seamtape = this.getSeamtapes.find(tape => tape.original.id === seamtapeID)

            if (typeof seamtape !== 'undefined') {
              seamtapeCosts = this.mx_costs_seamtape(seamtape, object)
            }
          }

          if (typeof object.product.adhesive !== 'undefined' && object.product.adhesive !== null) {
            const adhesiveID = object.product.adhesive.id
            const adhesive = this.getAdhesives.find(glue => glue.original.id === adhesiveID)

            if (typeof adhesive !== 'undefined') {
              adhesiveCosts = this.mx_costs_adhesive(adhesive, object.area)
            }
          }

          const areaPrice = Number(turfCosts.total.value) + Number(subbasesCosts.total.value) + Number(infillsCosts.total.value)

          summary.set(object.id, {
            object: object,
            turf: turfCosts,
            subbases: subbasesCosts,
            infills: infillsCosts,
            seamtape: seamtapeCosts,
            adhesive: adhesiveCosts,
            areaPrice: areaPrice
          })
        }
      })
      summary.set(ADDITIONAL_COSTS_KEY, this.getProjectAdditionCosts)
      this.checkoutSummary = summary
      this.summaryData = Array.from(this.checkoutSummary.values())
    },
    checkoutCheckObjects () {
      this.mx_trackstate_trackStateWithCondition({
        commands: async () => {
          for (const object of this.objectsWithProducts) {
            if (object.productNeedUpdate) {
              const calculationProperties = {
                angles: [Number(object.product.angle)],
                perfectVisualResult: object.product.perfectVisualResult,
                alignment: object.product.alignment,
                threshold: this.$_mx_algorithm_helper_convert_to_pixels(object.product.threshold),
                shift: this.$_mx_algorithm_helper_convert_to_pixels(object.product.shift),
                turfWidth: this.$_mx_algorithm_helper_convert_to_pixels(object.product.width),
                turfLength: this.$_mx_algorithm_helper_convert_to_pixels(object.product.length)
              }

              const results = await this.mx_algorithm_calculate_tracks(object, calculationProperties)

              this.mx_algorithm_helper_calculate_result(object, results.get(Number(object.product.angle)))
            }
          }
          this.allObjectUpToDate = true
          this.calculateSummary()
        },
        runSave: true
      })
    },
    close () {
      this.mx_dialogs_closeDialog()
    },
    pagination (payload) {
      switch (payload) {
        case 'next':
          this.mx_dialogs_setSubscreen(this.page + 1)
          break
        case 'back':
          this.mx_dialogs_setSubscreen(this.page - 1)
          break
        case 'order':
          this.close()
          break
        default:
          this.mx_dialogs_setSubscreen(payload)
      }
    }
  }
}
</script>
