<template>
  <dialog-frame
    :targetDialog="targetDialog"
    :title=" $t('dialogs.import.title') "
    :subtitle=" $t('dialogs.import.subtitle') "
  >
    <template #content>
      <import-file />
    </template>
  </dialog-frame>
</template>

<script>
import MixinDialog from '@/mixins/dialogs'
import DialogFrame from '@/components/common/DialogsFrame'
import ImportFile from '@/components/import/ImportFile'

export default {
  name: 'ImportDialog',
  components: {
    DialogFrame,
    ImportFile
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'importFile'
    }
  }
}
</script>
