/**
 * Handles collision events
 * @displayName Draw collision
 */
export default {
  name: 'DrawCollisionMixin',
  methods: {
    /**
         * Handle mouse move events like displaying collision points for snapped drawing
         * @requires @/mixins/math
         * @requires @/mixins/collision
         * @param {Event} evt
         * @private
         */
    $_mx_drawCollision_handleMouseMove (evt) {
      if (this.ctrl !== this.activeCtrl) return false
      if (evt) {
        this.$data.$_mx_drawCollision_lastMousePosition = this.mx_math_getCoordinatesFromEvent(evt)
      }
      if (this.snapKeyPressed && this.$data.$_mx_drawCollision_lastMousePosition) {
        this.mx_collision_setCollisionPoints(this.mx_collision_testCollidingPoints(this.$data.$_mx_drawCollision_lastMousePosition))
      }
    }
  },
  watch: {
    /**
         * @requires @/mixins/collision
         * @param val
         */
    snapKeyPressed (val) {
      if (val === false) {
        this.mx_collision_setCollisionPoints([])
      } else {
        this.$_mx_drawCollision_handleMouseMove()
      }
    },
    /**
         * @param val
         */
    editor (val) {
      if (val !== null) {
        if (window.PointerEvent) {
          this.editor.addEventListener('pointermove', this.$_mx_drawCollision_handleMouseMove)
        } else {
          this.editor.addEventListener('touchmove', this.$_mx_drawCollision_handleMouseMove)
          this.editor.addEventListener('mousemove', this.$_mx_drawCollision_handleMouseMove)
        }
      }
    }
  },
  beforeDestroy () {
    if (this.editor) {
      this.editor.removeEventListener('pointermove', this.$_mx_drawCollision_handleMouseMove)
      this.editor.removeEventListener('touchmove', this.$_mx_drawCollision_handleMouseMove)
      this.editor.removeEventListener('mousemove', this.$_mx_drawCollision_handleMouseMove)
    }
  },
  data () {
    return {
      /** @private **/
      $_mx_drawCollision_lastMousePosition: null
    }
  }
}
