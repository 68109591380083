<template>
  <i
    :class="iconClasses"
    :style="iconStyle"
  >
    <!--
      @slot Default slot
    -->
    <slot name="default" />
  </i>
</template>

<script>
export default {
  name: 'Icon',

  props: {
    /**
     * Icon CSS classes - mostly Font Awesome
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * Defines the icon width (only custom icon)
     */
    styleWidth: {
      type: [Number, String],
      required: false,
      default: '20px'
    },
    /**
     * Defines the icon height (only custom icon)
     */
    styleHeight: {
      type: [Number, String],
      required: false,
      default: '20px'
    }
  },

  computed: {
    /**
     * Sets CSS classes of the whole component
     */
    iconClasses () {
      const propClasses = typeof this.classes === 'string' ? this.classes.split(' ') : this.classes
      const localClasses = [
        'c-icon',

        this.hasDefaultSlot ? 'c-icon--custom-icon' : ''
      ]

      return [...propClasses, ...localClasses]
    },
    /**
     * Sets CSS styles of the whole component
     */
    iconStyle () {
      let inlineStyle = ''

      inlineStyle += this.hasDefaultSlot ? `width: ${this.styleWidth};` : ''
      inlineStyle += this.hasDefaultSlot ? `height: ${this.styleHeight};` : ''

      return inlineStyle
    },
    /**
     * Checks if default slot is empty
     */
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  }
}
</script>
