<template>
  <svg
    id="handles-line-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M34.36,35.66a1.44,1.44,0,0,1-1-.44L5,7.08A1.5,1.5,0,1,1,7.1,5L35.42,33.1a1.5,1.5,0,0,1,0,2.12A1.53,1.53,0,0,1,34.36,35.66Z"
    />
    <circle
      class="u-fill-w"
      cx="32.87"
      cy="32.67"
      r="4"
    />
    <circle
      class="u-fill-w"
      cx="7.54"
      cy="7.57"
      r="4"
    />
  </svg>
</template>

<script>
export default {
  name: 'HandlesLineIcon'
}
</script>
