import Paper from 'paper'
import { Point } from '@/classes/objects'
import config from '@/config'

import MixinMath from '@/mixins/math'

class PaperLayer {
  /**
     * Gets paper.js segments and returns array of internal point objects
     * @param {Array} segments
     * @param {Boolean} clockwise
     * @returns {Array}
     */
  static makePointsFromPaperSegments (segments, clockwise) {
    const transformedPoints = []
    const max = segments.length - 1
    segments.forEach((segment, index) => {
      if (index === 0 && clockwise) {
        transformedPoints.push(new Point(MixinMath.methods.mx_math_roundDecimal(segment.point.x), MixinMath.methods.mx_math_roundDecimal(segment.point.y), 'M'))
      } else if (index === max && !clockwise) {
        transformedPoints.unshift(new Point(MixinMath.methods.mx_math_roundDecimal(segment.point.x), MixinMath.methods.mx_math_roundDecimal(segment.point.y), 'M'))
      }

      const p = new Point(MixinMath.methods.mx_math_roundDecimal(segment.point.x), MixinMath.methods.mx_math_roundDecimal(segment.point.y), 'L')

      if (segment.handleIn.x || segment.handleIn.y) {
        p.setType('C')

        p.setHandles({
          x2: MixinMath.methods.mx_math_roundDecimal(segment.point.x + segment.handleIn.x),
          y2: MixinMath.methods.mx_math_roundDecimal(segment.point.y + segment.handleIn.y),
          x1: MixinMath.methods.mx_math_roundDecimal(segment.previous.point.x + segment.previous.handleOut.x),
          y1: MixinMath.methods.mx_math_roundDecimal(segment.previous.point.y + segment.previous.handleOut.y)
        })
      }
      transformedPoints.push(p)
    })
    transformedPoints.push(new Point(0, 0, 'Z'))

    return transformedPoints
  }

  // OPERATIONS

  /**
     * Subtract and return single or multiple Objects
     * @param {Path} objA
     * @param {Path} objB
     * @returns {{points: Array, inside: Boolean}|[{points: Array, inside: Boolean}]}
     */
  static subtractObjects (objA, objB) {
    const a = this._getCompoundPath(objA)
    const b = this._getCompoundPath(objB)

    const c = b.subtract(a)

    if (c.isEmpty()) {
      return null
    }

    return this._transformPoints(c)
  }

  /**
     * Unite and return single or multiple Objects
     * @param {Path} objA
     * @param {Path} objB
     * @returns {{points: Array, inside: Boolean}|[{points: Array, inside: Boolean}]}
     */
  static uniteObjects (objA, objB) {
    const a = this._getCompoundPath(objA)
    const b = this._getCompoundPath(objB)

    const c = b.unite(a)

    return this._transformPoints(c)
  }

  /**
     * Divide (Fragment) and return single or multiple Objects
     * @param {Path} objA
     * @param {Path} objB
     * @returns {{points: Array, inside: Boolean}|[{points: Array, inside: Boolean}]}
     */
  static divideObjects (objA, objB) {
    const a = this._getCompoundPath(objA)
    const b = this._getCompoundPath(objB)

    // clone objects to operate with
    const _a = a.clone()
    const _b = b.clone()

    // divide
    const c = _a.divide(b)
    const d = _b.divide(a)

    // divde result for each operation
    const fragments = {
      a: {
        clockwise: c.getItems({ clockwise: true }),
        counterClockwise: c.getItems({ clockwise: false })
      },
      b: {
        clockwise: d.getItems({ clockwise: true }),
        counterClockwise: d.getItems({ clockwise: false })
      },
      counterClockwise: []
    }

    const equal_clockwise = PaperLayer.fkt_divide_equal_elements(fragments.a.clockwise, fragments.b.clockwise, true)
    const equal_counter_clockwise = PaperLayer.fkt_divide_equal_elements(fragments.a.counterClockwise, fragments.b.counterClockwise)

    fragments.b.clockwise = equal_clockwise.b
    fragments.a.counterClockwise = equal_counter_clockwise.a
    fragments.b.counterClockwise = equal_counter_clockwise.b
    fragments.counterClockwise = equal_counter_clockwise.equal

    const result_a = PaperLayer.fkt_divide_combine(fragments.a.clockwise, fragments.a.counterClockwise)
    const result_b = PaperLayer.fkt_divide_combine(fragments.b.clockwise, fragments.b.counterClockwise)

    fragments.a.clockwise = (result_a !== null) ? result_a : fragments.a.clockwise
    fragments.b.clockwise = (result_b !== null) ? result_b : fragments.b.clockwise

    let result = []
    const elements = fragments.a.clockwise.concat(fragments.b.clockwise)

    if (fragments.counterClockwise.length > 0) {
      elements.forEach(el => {
        fragments.counterClockwise.forEach(cut => {
          cut.clockwise = true

          const _cut = new Paper.CompoundPath(cut.pathData)
          const _el = (el instanceof Paper.CompoundPath) ? el.clone() : new Paper.CompoundPath(el.pathData)

          if (!_el.compare(_cut)) {
            result.push(new Paper.CompoundPath(_el.subtract(_cut).pathData))
          } else {
            result.push(new Paper.CompoundPath(el.pathData))
          }
        })
      })
    } else {
      result = elements
    }

    const check = {
      paper: [],
      transformed: []
    }

    result.forEach((res) => {
      if (check.paper.length > 0) {
        let isEqual = false
        check.paper.forEach(item => {
          if (item.compare(res)) {
            isEqual = true
          }
        })
        if (!isEqual) {
          check.paper.push(res)
          check.transformed.push(this._transformPoints(res))
        }
      } else {
        check.paper.push(res)
        check.transformed.push(this._transformPoints(res))
      }
    })

    return {
      a: null,
      b: null,
      c: check.transformed
    }
  }

  /**
     * Check for equality of (counter) clockwise elements
     * @param a
     * @param b
     * @param isClockwise
     * @returns {{equal: [], a: null, b: null}}
     */
  static fkt_divide_equal_elements (a, b, isClockwise = false) {
    const result = {
      a: null,
      b: null,
      equal: []
    }

    a.forEach((el_a, index_a) => {
      const cpA = new Paper.CompoundPath(el_a.pathData)
      b.forEach((el_b, index_b) => {
        const cpB = new Paper.CompoundPath(el_b.pathData)
        if (cpA.compare(cpB)) {
          if (!isClockwise) {
            result.equal.push(el_a)
            a.splice(index_a, 1)
          }
          b.splice(index_b, 1)
        }
      })
    })

    result.a = a
    result.b = b

    return result
  }

    /**
     * Combine counter clockwise elements with clockwise elements
     * @param clockwise
     * @param counterClockwise
     * @returns {null|[]}
     */
    static fkt_divide_combine = (clockwise, counterClockwise) => {
      if (counterClockwise.length > 0) {
        // const result = []

        counterClockwise.forEach((item) => {
          item.clockwise = true
          const cutItem = new Paper.CompoundPath(item.pathData)
          clockwise.forEach((obj, index) => {
            const objToCut = new Paper.CompoundPath(obj.pathData)
            clockwise[index] = objToCut.subtract(cutItem)
          })
        })

        return clockwise
      }

      return null
    }

    /**
     *
     * @param obj
     * @param props
     * @returns {{children: null, id: *, points: Array}|{children: *, id: *, points: *}}
     */
    static rotateObject (obj, props) {
      if (obj.type !== config.objects.types.CIRCLE) {
        const a = this._getCompoundPath(obj)

        a.rotate(props.deg, new Paper.Point({
          x: props.cx,
          y: props.cy
        }))

        const result = PaperLayer._transformPoints(a)
        const key = Object.keys(result).shift()
        const outcome = result[key]

        if (result.points) {
          return {
            id: obj.id,
            points: result.points,
            children: null,
            center: a.bounds.center
          }
        } else {
          const clockwise_object = outcome.filter(obj => !obj.inside)
          const counterclockwise_objects = outcome.filter(obj => obj.inside)

          const object_properties = clockwise_object.shift()

          return {
            id: obj.id,
            points: object_properties.points,
            children: counterclockwise_objects
          }
        }
      } else {
        const data = obj.getCircleProperties()
        const circle = new Paper.Path.Circle({
          center: [data.middle.x, data.middle.y],
          radius: data.radius
        })

        circle.rotate(props.deg, new Paper.Point({
          x: props.cx,
          y: props.cy
        }))

        const centerX = circle.bounds.centerX
        const centerY = circle.bounds.centerY

        return {
          centerX: centerX,
          centerY: centerY,
          radius: {
            x: circle.bounds.right,
            y: circle.bounds.top + data.radius
          }
        }
      }
    }

    /**
     * Checks if object is valid (no overlapping, crossing, islands if objects has children)
     * @param object
     * @param addPoint
     */
    static isValidObject (object, addPoint) {
      const cPath = object instanceof Paper.Path ? object : PaperLayer._getCompoundPath(object)
      if (addPoint) {
        cPath.add(new Paper.Point(addPoint.x, addPoint.y))
      }
      // let noIntersections = onlyCrossings ? cPath.getCrossings().length === 0 : cPath.getIntersections().length === 0 && cPath.getCrossings().length === 0
      const noIntersections = cPath.getCrossings().length === 0
      if (!noIntersections) return false
      // check children holes...
      let childrenValid = true
      if (cPath.hasChildren()) {
        const innerChildren = Object.assign([], cPath.children)
        const mainObject = innerChildren.shift()
        childrenValid = innerChildren.filter(item => item.unite(mainObject).isEmpty()).length === 0 && innerChildren.filter(item => item.clockwise).length === 0
      }
      return noIntersections && childrenValid
    }

    /**
     * Return a paper.js path from points
     * @param points
     * @returns {paper.Path}
     */
    static makePaperPathFromPoints (points) {
      return new Paper.Path(points)
    }

    /**
     * Return the paper.js compound path form internal object
     * @param obj
     * @returns {paper.CompoundPath}
     * @private
     */
    static _getCompoundPath (obj) {
      if (obj.type === config.objects.types.PATH) {
        return new Paper.CompoundPath(obj.d())
      } else if (obj.type === config.objects.types.CIRCLE) {
        const data = obj.getCircleProperties()
        const circle = new Paper.Path.Circle({
          center: [data.middle.x, data.middle.y],
          radius: data.radius
        })
        const circlePath = circle.toPath()

        return new Paper.CompoundPath(circlePath.pathData)
      }
    }

    /**
     * Private divide helper
     * @param {{Paper.Path}|{Paper.CompoundPath}} obj
     * @param {{Paper.Path}|{Paper.CompoundPath}} fragment
     * @returns {{Paper.Path}|{Paper.CompoundPath}}
     * @private
     */
    static _fragment (obj, fragment) {
      return obj.subtract(fragment)
    }

    /**
     * Is child inside object
     * @param {{Paper.Path}|{Paper.CompoundPath}} child
     * @param {{Paper.Path}|{Paper.CompoundPath}} obj
     * @returns {Boolean}
     * @private
     */
    static _isInside (child, obj) {
      const c = new Paper.CompoundPath(child.pathData)
      const o = new Paper.CompoundPath(obj.pathData)
      const intersect = c.intersect(o)

      return (child.isInside(obj.bounds) && intersect.segments.length > 0)
    }

    /**
     * Transform given objects to own objects
     * @param {{Paper.Path}|{Paper.CompoundPath}} c
     * @returns {{points: Array, inside: Boolean}|[{points: Array, inside: Boolean}]}
     * @private
     */
    static _transformPoints (c) {
      if (c.children && c.children.length === 1) {
        c = new Paper.Path(c.pathData)
      }

      if (c instanceof Paper.CompoundPath) {
        const transformedPoints = {}
        const clockwise_objects = c.children.filter(child => child.clockwise)
        const counterclockwise_objects = c.children.filter(child => !child.clockwise)

        clockwise_objects.forEach(child => {
          if (child.closed && child.segments.length > 2) {
            if (!Object.prototype.hasOwnProperty.call(transformedPoints, child.id)) {
              transformedPoints[child.id] = []
            }
            transformedPoints[child.id].push({
              points: this.makePointsFromPaperSegments(child.segments, child.clockwise),
              inside: false
            })
          }
        })

        counterclockwise_objects.forEach(child => {
          if (child.closed && child.segments.length > 2) {
            const objInside = clockwise_objects.filter(obj => child.isInside(obj.bounds))

            if (objInside.length) {
              transformedPoints[objInside[0].id].push({
                points: this.makePointsFromPaperSegments(child.segments, child.clockwise),
                inside: true
              })
            }
          }
        })

        return transformedPoints
      }
      if (c instanceof Paper.Path) {
        const transformedPoints = this.makePointsFromPaperSegments(c.segments)
        return { points: transformedPoints, inside: false }
      }
    }
}

export default PaperLayer
