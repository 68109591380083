/**
 * @displayName Clockwise
 */
export default {
  name: 'ClockwiseMixin',
  methods: {
    /**
     * Return points in reverse order if not clockwise
     * @requires @/mixins/math
     * @param points
     * @returns {*}
     * @public
     */
    mx_clockwise_clockwise (points) {
      let _points = points.filter((item, index) => item.type !== 'M' && item.type !== 'Z')

      if (!this.mx_math_isClockwise(_points)) {
        _points = _points.reverse()

        _points.unshift(points[0])
        _points.push(points[points.length - 1])

        return _points
      }

      return points
    }
  }
}
