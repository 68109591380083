import {
  /* Path, */
  PathArray
  /* PointArray,
  Polygon,
  Polyline,
  Number as SVGNumber,
  extend,
  parser */
} from '@svgdotjs/svg.js'

import '@svgdotjs/svg.topoly.js'

function svgjsPlugin (Vue) {
  if (svgjsPlugin.installed) return

  const svgjs = new Vue({
    methods: {
      /**
             * @param {string} d
             */
      PathToPoly (d) {
        return new PathArray(d).toPoly('1%')
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$svg', {
    get () {
      return svgjs
    }
  })

  Vue.mixin({})
  Vue.svg = Vue.prototype.$svg
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(svgjsPlugin)
}

export default svgjsPlugin
