<template>
  <div class="c-layout c-backend u-bgcolor-s4">
    <slot name="dialogs" />

    <page-header :main-nav="true" />

    <div class="c-layout__sidebar u-bgcolor-s3">
      <side-nav />
    </div>

    <div class="c-layout__content c-layout__content--sidebar">
      <slot name="content" />
    </div>
  </div>
</template>

<script>

import PageHeader from './partials/Header'
import SideNav from './partials/SideNav'

export default {
  name: 'Backend',
  components: {
    PageHeader,
    SideNav
  }
}
</script>
