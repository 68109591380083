<template>
  <div
    :id="id"
    ref="dropDown"
    :class="[label ? 'c-dropdown--labeled' : '', 'c-dropdown', classes]"
  >
    <label
      v-if="label"
      :for="id + '-current'"
      class="c-typo__label"
      v-html="required ? label + ' *': label"
    />
    <div
      :style="`width: ${styleCurrentItemWidth}; min-width: ${styleCurrentItemWidth}`"
      class="c-dropdown-container"
    >
      <!-- Current item -->
      <div
        :id="id + '-current'"
        :class="currentItemClasses"
        @click="setOpenClose(true)"
      >
        <!-- Item -->
        <span
          v-if="currentItem && (!listVisible || !hasSearch)"
          :class="disabled === true ? 'c-dropdown-disabled' : ''"
        >
          {{ currentLabel }}
        </span>

        <!-- Search field -->
        <input
          v-else-if="hasSearch && currentItem && listVisible"
          ref="input"
          v-model="search"
          :class="currentItemInputClasses"
          :disabled="disabled"
          :placeholder="currentLabel"
          type="text"
          @focusin="setInputFocus(true)"
          @focusout="setInputFocus(false)"
        >

        <!-- Error - instead of current item or search field -->
        <span
          v-else-if="error.type === 'NO_ITEM'"
          class="c-dropdown-error"
        >{{ error.label }}</span>

        <button
          :id="id + '-opener'"
          :class="currentItemOpenerClasses"
          @click="switchSubmenu"
        />
      </div>

      <!-- Drop down list -->
      <transition name="c-dropdown">
        <div
          v-if="listVisible"
          :id="id + '-group'"
          :class="listGroupClasses"
          :style="`max-height: ${styleListHeight}px`"
        >
          <!-- Filtered items -->
          <div
            v-for="(item, index) in filteredItems"
            :id="id + '-item-' + index"
            :key="index"
            :class="listItemClasses(index)"
            @mousedown="onSelect(item)"
          >
            {{ labelKey !== '' ? item[labelKey] : item }}
          </div>

          <!-- Nothing found - label -->
          <div
            v-if="error.type === 'NOTHING_FOUND'"
            :id="id + '-nothing-found'"
            :class="listItemClasses()"
          >
            {{ error.label }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

/**
 * The DropDown Component.
 * @displayName Drop Down
 */
export default {
  name: 'DropDown',
  props: {
    /**
     * Additional CSS classes
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * How many characters are allowed in the current item before they will be trimmed and changed to '...'
     */
    currentItemAllowedSigns: {
      type: Number,
      required: false,
      default: 0
    },
    /**
     * Disables the whole component
     */
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Array of possible options to choose
     */
    items: {
      type: Array,
      required: true
    },
    /**
     * Description label displayed near the component
     */
    label: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Name of a property containing the label. If empty, the item itself will be displayed
     */
    labelKey: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Activates the search field
     */
    hasSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Description showed while the component doesn't contain any items
     */
    noItemLabel: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Informs with a '*' after the label that this field is required in a form
     * (it doesn't perform any check!)
     */
    required: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Aligns the current item to the left side
     */
    styleCurrentItemLeftAlign: {
      default: true,
      required: false,
      type: Boolean
    },
    /**
     * Defines the length of the component (without the label)
     */
    styleCurrentItemWidth: {
      type: String,
      default: '100%',
      required: false
    },
    /**
     * Defines color theme
     * @values dark, darker
     */
    styleColor: {
      type: String,
      default: 'darker',
      required: false
    },
    /**
     * Defines list height (px)
     */
    styleListHeight: {
      type: Number,
      required: false,
      default: 250
    },
    /**
     * Defines if the list is opened at the top or bottom (default: top)
     */
    styleUp: {
      default: true,
      required: false,
      type: Boolean
    },
    /**
     * @model
     * Current item (bind via v-model)
     */
    value: {
      required: false,
      type: [String, Number, Array, Object],
      default: null
    },
    /**
     * Name of a property containing the value. If empty, the item itself will be displayed
     */
    valueKey: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      listVisible: false,
      currentItem: null,
      search: '',
      highlighted: -1
    }
  },

  computed: {
    /**
     * Current value
     */
    currentValue () {
      return (this.currentItem) ? this.getValueRegardingKey(this.currentItem) : null
    },
    /**
     * Current value label (trimmed if 'currentItemAllowedSigns' is set)
     */
    currentLabel () {
      const label = this.getLabelRegardingKey(this.currentItem)

      return (this.currentItemAllowedSigns !== 0 && label.length > this.currentItemAllowedSigns)
        ? label.substring(0, this.currentItemAllowedSigns) + '...'
        : label
    },
    /**
     * Filters items during the search
     */
    filteredItems () {
      return this.items.filter(item => {
        return this.getLabelRegardingKey(item).toLowerCase()
          .includes(this.search.toString().toLowerCase())
      })
    },
    /**
     * Sets the error message for 'no item' error
     */
    noItemWarning () {
      return this.noItemLabel === ''
        ? this.$i18n.messages[this.$i18n.locale].components.dropdown.noItemLabel
        : this.noItemLabel
    },
    /**
     * Sets a local error and the message if there's no item available or nothing was found during the search
     */
    error () {
      let type = ''
      let label = ''

      if (!this.currentItem) {
        type = 'NO_ITEM'
        label = this.noItemWarning
      } else if (this.filteredItems.length <= 0) {
        type = 'NOTHING_FOUND'
        label = this.$i18n.messages[this.$i18n.locale].components.dropdown.nothingFound
      }
      return { type: type, label: label }
    },

    /* ------------------------------------------
     *  Styles
     *------------------------------------------ */

    /**
     * Sets CSS classes of the current item
     */
    currentItemClasses () {
      return [
        'c-dropdown-current',

        this.styleCurrentItemLeftAlign ? 'c-dropdown-current--left' : '',
        this.styleColor === 'darker' ? 'c-dropdown-current--dark' : '',

        this.listVisible ? '' : 'u-bdradius-big',
        this.listVisible && this.styleUp ? 'u-bdradiusb-big' : '',
        this.listVisible && !this.styleUp ? 'u-bdradiust-big' : '',
        this.listVisible && this.styleColor === 'darker' ? 'c-dropdown-current--dark--active' : ''
      ]
    },
    /**
     * Sets CSS classes of the search input
     */
    currentItemInputClasses () {
      return [
        this.listVisible && !this.styleUp ? 'u-bdradiustl-big' : '',
        this.listVisible && this.styleUp ? 'u-bdradiusbl-big' : ''
      ]
    },
    /**
     * Sets CSS classes for a opener button
     */
    currentItemOpenerClasses () {
      return [
        'c-dropdown-opener u-bdradiustr-big u-bdradiusbr-big',
        this.styleUp ? 'c-dropdown-opener--up' : '',

        this.listVisible && !this.styleUp ? 'c-dropdown-opener--active' : '',
        this.listVisible && this.styleUp ? 'c-dropdown-opener--up--active' : ''
      ]
    },
    /**
     * Sets CSS classes of an item list
     */
    listGroupClasses () {
      return [
        'c-dropdown-list-group',
        this.styleUp ? 'c-dropdown-list-group--up u-bdradiust-big' : 'u-bdradiusb-big'
      ]
    },
    /**
     * Sets CSS classes of a listed item
     */
    listItemClasses: function () {
      return function (id = -1) {
        const vm = this
        return [
          'c-dropdown-list-item',
          vm.highlighted === id ? 'c-dropdown-list-item--selected' : '',
          vm.styleColor === 'darker' ? 'c-dropdown-list-item--dark' : '',
          vm.styleUp ? 'c-dropdown-list-item--up' : '',
          vm.error.type === 'NOTHING_FOUND' ? 'c-dropdown-list-item--no-item' : ''
        ]
      }
    }
  },
  mounted: function () {
    const type = typeof this.value

    // sets the current value for v-model according to its type and valueKey property
    if (this.value) {
      switch (type) {
        case 'object':
          this.currentItem = this.value
          break

        case 'number':
        case 'string':
          this.valueKey !== '' // if there's a valueKey, it has to be a part of an object, otherwise the value itself is a key
            ? this.currentItem = this.items.filter(item => item[this.valueKey] === this.value)[0]
            : this.currentItem = this.items.filter(item => item === this.value)[0]
          if (this.currentItem === undefined) this.$devLog.log('value (type: string) could not be found in items array')
          break

        default:
          this.$devLog.log('Not acceptable type passed as DropDown value: ', type)
      }
    } else {
      // there's no current value, so just take the first item from the list
      this.currentItem = this.items[0]
    }
    this.$emit('input', this.currentValue)
    /**
     * Triggers on 'mounted' to register the component in 'validate' mixin
     *
     * @event register
     * @property {{id: string, value: *, error: boolean}} payload - Emitted payload
     * @property {string} id - ID of this component (also used in html and tests)
     * @property {*} value - Current value
     * @property {boolean} error - Error if current value is null
     */
    this.$emit('register', { id: this.id, value: this.value, error: this.currentItem == null })
    this.$nextTick(() => {
      document.addEventListener('mousedown', this.documentClick)
      this.$el.addEventListener('keydown', this.keyPressed)
    })
  },
  beforeDestroy () {
    this.setInputFocus(false)
    document.removeEventListener('mousedown', this.documentClick)
    this.$el.removeEventListener('keydown', this.keyPressed)
  },
  methods: {
    ...mapMutations({
      setInputFocus: 'events/' + mutationTypes.SET_INPUT_FOCUS
    }),
    /**
     * Sets an item as the selected
     *
     * @param {*} item The selected item
     */
    onSelect (item) {
      this.currentItem = item
      /**
       * Triggers when the value changes - made for custom v-model
       * which gives a possibility to check error and decide if the value should be saved in store
       *
       * @event change
       * @property {{id: string, value: *, error: boolean}} payload - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       * @property {*} value - Current value
       * @property {boolean} error - Error if current value is null
       */
      this.$emit('change', { id: this.id, value: this.currentValue, error: this.currentItem == null })
      /**
       * Triggers when the value changes and on 'mounted' - made for standard v-model
       *
       * @event input
       * @property {*} value - Emitted value
       */
      this.$emit('input', this.currentValue)
      this.setOpenClose(false)
    },
    /**
     * Opens or closes the list
     *
     * @param {boolean} val - true if the list should be opened
     */
    setOpenClose (val) {
      if (!this.disabled) {
        this.listVisible = this.value ? val : false

        if (this.listVisible === true) {
          this.setFocus()
          this.search = ''
        }
        this.setInputFocus(val)
      }
    },

    /* ------------------------------------------
     *  Helpers
     *------------------------------------------ */

    /**
     * Opens and closes the list
     */
    switchSubmenu (e) {
      e.preventDefault()
      e.stopPropagation()
      this.setOpenClose(!this.listVisible)
    },
    /**
     * Returns the label of an item
     *
     * @param {boolean} item
     */
    getLabelRegardingKey (item) {
      return (this.labelKey !== '') ? item[this.labelKey].toString() : item.toString()
    },
    /**
     * Returns the value of an item
     *
     * @param {boolean} item
     */
    getValueRegardingKey (item) {
      return (this.valueKey !== '') ? item[this.valueKey] : item
    },
    /**
     * Sets the focus in input / search field
     */
    setFocus () {
      this.$nextTick(
        () => {
          if (this.hasSearch && !this.disabled) { this.$refs.input.focus() }
        }
      )
    },
    /**
     * Manages keyboard events
     *
     * @param {KeyboardEvent} e
     */
    keyPressed (e) {
      if (this.listVisible) {
        switch (e.key) {
          case 'Up': // IE/Edge specific value
          case 'ArrowUp':
            this.highlighted--
            break
          case 'Down': // IE/Edge specific value
          case 'ArrowDown':
            this.highlighted++
            break
          case 'Esc': // IE/Edge specific value
          case 'Escape':
            e.preventDefault()
            e.stopPropagation()
            this.setOpenClose(false)
            break
          case 'Enter':
            if (this.filteredItems.length) {
              this.onSelect(this.filteredItems[this.highlighted])
            }
            break
        }

        if (this.filteredItems.length) {
          if (this.highlighted < 0) {
            this.highlighted = this.filteredItems.length - 1
          } else if (this.highlighted >= this.filteredItems.length) {
            this.highlighted = 0
          }
        }
      }
    },
    /**
     * Closes the list if anything outside of the component was clicked
     */
    documentClick (e) {
      const element = this.$refs.dropDown
      let target = null

      if (e && e.target) {
        target = e.target
      }
      if (element && element !== target && !element.contains(target)) {
        this.setOpenClose(false)
      }
    }
  }
}
</script>
