<template>
  <div
    class="c-los__labeling u-mb-size-standard"
  >
    <div
      v-if="!isWasteObjectSet"
      class="c-los__info"
    >
      <!-- Track is not a source and doesn't use any waste from another one -->
      <div
        v-if="!track.usedWaste && !track.sourceTargetFor.length > 0"
        class="c-los__info"
      >
        <p>{{ $t('editor.lineOfSight.track.waste.info') }}</p>
        <div class="c-los__component-area  u-mt-size-standard">
          <button-base
            id="waste-management-select-area"
            :label="$t('editor.lineOfSight.track.waste.selectArea')"
            :on-click-action="() => { return setUseFromWaste() }"
            classes="u-mr-size-0 u-mb-size-0"
            style-min-width="180px"
          />
        </div>
      </div>

      <!-- Track is a source -->
      <div
        v-if="track.sourceTargetFor.length > 0"
        class="c-los__labeling u-mt-size-0"
      >
        <span>
          {{ $t('editor.lineOfSight.track.waste.wasteUsedBy') }}
        </span>
        <span>
          <ul>
            <li
              v-for="(source, key) in track.sourceTargetFor"
              :key="`sourceFor-${key}`"
            >
              {{ objectName(source.object, $t('editor.lineOfSight.track.waste.areaShort')) }} - {{ source.track }}
            </li>
          </ul>
        </span>
      </div>
      <!-- Track uses waste from another one -->
      <div
        v-if="track.usedWaste"
        class="c-los__labeling u-mt-size-0"
      >
        <span>
          {{ $t('editor.lineOfSight.track.waste.usesWasteFrom') }}
        </span>
        <span>
          {{ $t('editor.lineOfSight.track.waste.isRestOf', {
            object: objectName(track.wasteData.object),
            track: track.wasteData.track
          }) }}
        </span>
        <div class="c-los__component-area u-mt-size-standard">
          <button-base
            id="waste-management-remove-waste-data"
            :label="$t('editor.lineOfSight.track.waste.removeConnection')"
            :on-click-action="() => { return removeWasteData(track) }"
            classes="u-mr-size-0 u-mb-size-0"
            style-min-width="180px"
          />
        </div>
      </div>
    </div>
    <!-- Save / Cancel choice of waste -->
    <div
      v-if="isWasteObjectSet"
      class="c-los__info"
    >
      <p>{{ $t('editor.lineOfSight.track.waste.selectingInfo') }}</p>

      <div class="c-los__labeling">
        <button-base
          id="waste-management-select-area-cancel"
          :label="$t('common.cancel')"
          :on-click-action="() => { return clearWasteData() }"
          classes="u-mh-size-0 u-mb-size-0"
          style-min-width="110px"
          style-color="gray"
        />
        <button-base
          id="waste-management-select-area-confirm"
          :disabled="wasteInformation.use.object === null || wasteInformation.use.track === null"
          :label="$t('common.save')"
          :on-click-action="() => { return saveWasteData() }"
          classes="u-mh-size-0 u-mb-size-0"
          style-min-width="110px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '@/config'
import ButtonBase from '@/components/common/ButtonBase'

export default {
  name: 'WasteManagement',
  components: { ButtonBase },
  props: {
    track: {
      type: Object,
      default: null
    }
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters({
      getObject: 'lineOfSightNew/getObject',
      getWasteData: 'lineOfSightNew/getWasteData',
      getUseFromWaste: 'lineOfSightNew/getUseFromWaste',
      isWasteObjectSet: 'lineOfSightNew/isWasteObjectSet'
    }),
    wasteInformation () {
      return {
        for: {
          object: (this.getWasteData.object) ? this.getWasteData.object.id : null,
          track: (this.getWasteData.track) ? this.getWasteData.track.name : null
        },
        use: {
          object: (this.getUseFromWaste.object) ? this.getUseFromWaste.object.id : null,
          track: (this.getUseFromWaste.track) ? this.getUseFromWaste.track.name : null
        }
      }
    }
  },
  methods: {
    ...mapActions({
      setWasteData: 'lineOfSightNew/setWasteData',
      saveWasteData: 'lineOfSightNew/saveWasteData',
      removeWasteData: 'lineOfSightNew/removeWasteData',
      clearWasteData: 'lineOfSightNew/clearWasteData'
    }),
    setUseFromWaste () {
      this.setWasteData({ object: this.getObject, track: this.track })
    },
    objectName (object, extra = '') {
      if (object) {
        return object.replace(config.objects.ID, extra)
      }
      return ''
    }
  }
}
</script>
