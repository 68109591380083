<template>
  <dialog-frame
    :targetDialog="targetDialog"
    :title=" $t('dialogs.collar.title') "
    :subtitle=" $t('dialogs.collar.subtitle') "
  >
    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-min c-dialog__instruction--full-width">
        <input-field
          id="collar-offset"
          v-model="offset"
          :isStoreMode="false"
          :label="$t('dialogs.collar.distance')"
          :suffix="$settings.lengthUnit"
          :validate="(v) => {return mx_validate_validatePositiveNumber(v) && offset >= parseFloat(v) && parseFloat(v) >= 1 }"
          style-input-width="120px"
          type="number"
          @change="setOffset"
        />
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="abort-measurements-no"
            :label="$t('common.cancel')"
            :on-click-action="() => { mx_dialogs_closeDialog() }"
            style-color="gray"
          />
          <button-base
            id="abort-measurements-no"
            :label="$t('dialogs.collar.button')"
            :on-click-action="() => { create() }"
            style-color="blue"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import { createObjectCollar } from '@/helper/objectCollar'
import PaperLayer from '@/classes/paperLayer'

import MixinDialog from '@/mixins/dialogs'
import MixinMath from '@/mixins/math'
import MixinTrackState from '@/mixins/trackstate'
import MixinValidate from '@/mixins/validate'

import DialogFrame from '@/components/common/DialogsFrame'
import ButtonBase from '@/components/common/ButtonBase'
import InputField from '@/components/common/InputField'

export default {
  name: 'CollarDialog',
  components: {
    ButtonBase,
    DialogFrame,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinMath,
    MixinValidate,
    MixinTrackState
  ],
  data: () => {
    return {
      targetDialog: 'collar',
      offset: 1,
      error: {
        offset: false
      }
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      currentSelectedObjects: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      getObject: 'project/objects/getById'
    })
  },
  methods: {
    ...mapActions({
      operationActions: 'project/operationActions',
      clearObjectSelection: 'events/clearObjectSelection'
    }),
    setOffset (data) {
      this.error.offset = data.error
    },
    create () {
      const object = this.getObject(this.currentSelectedObjects[0])
      const offset = this.mx_math_getPixelByLength(this.offset, this.measure)
      const collarResult = createObjectCollar(object, offset, this.measure)

      if (collarResult !== null) {
        const transformedPoints = PaperLayer._transformPoints(collarResult)

        if (typeof transformedPoints !== 'undefined') {
          for (const key in transformedPoints) {
            if (Object.hasOwnProperty.call(transformedPoints, key)) {
              const objProps = transformedPoints[key].filter(p => !p.inside)
              const childProps = transformedPoints[key].filter(p => p.inside)

              if (childProps.length > 0) {
                this.mx_trackstate_trackState(() => {
                  this.operationActions({
                    points: objProps[0].points,
                    children: childProps,
                    action: 'create-new-object-from-points',
                    cutObjectMode: false
                  })
                })
              } else {
                this.$toasted.show((this.$t('toast.collar.cantCreateCollar').toString()), { duration: 7000, type: 'error' })
              }
            }
          }
        }
      } else {
        this.$toasted.show((this.$t('toast.collar.cantCreateCollar').toString()), { duration: 7000, type: 'error' })
      }

      this.clearObjectSelection()
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
