import * as types from '@/vuex/mutation-types'
import editorModule from '@/vuex/modules/editor'

import config from '@/config'
import { withHistoryMutation } from '../../history-helper'

import MixinMath from '@/mixins/math'

/* const replaceDecimalPointWithDot = (value) => {
  const string = value.toString()
  const n = string.replace(',', '.')

  return parseFloat(n)
} */

const state = {
  measure: {
    fromPixels: 100,
    toUnit: 3,
    unit: config.units[0],
    transformUnit: config.units[0]
  },
  reference: {
    start: null,
    end: null
  },
  stored: {
    references: [],
    toUnit: []
  },
  ruler: {
    start: null,
    end: null
  }
}

const getters = {
  getGridMeasurements (state) {
    return { fromPixels: state.measure.fromPixels, toUnit: state.measure.toUnit }
  },
  getMeasure (state) {
    return state.measure
  }
}

const actions = {

  updateFromPixels ({ state, dispatch, commit }) {
    if (state.stored.references.length && state.stored.toUnit.length) {
      let distancesSum = 0
      let toUnitSum = 0

      state.stored.references.forEach(
        storedRef => {
          distancesSum += MixinMath.methods.mx_math_getDistance(storedRef.start, storedRef.end)
        }
      )
      state.stored.toUnit.forEach(
        storedToUnit => {
          toUnitSum += parseFloat(storedToUnit)
        }
      )
      const finalDistance = MixinMath.methods.mx_math_roundDecimal(distancesSum / state.stored.references.length, 0)
      const finalToUnit = MixinMath.methods.mx_math_roundDecimal(toUnitSum / state.stored.toUnit.length, 2)

      commit(types.SET_MEASURE_PROPERTY, { property: 'fromPixels', value: finalDistance })
      commit(types.SET_MEASURE_PROPERTY, { property: 'toUnit', value: finalToUnit })
      commit(types.RESET_REF_POINTS)
      commit(types.RESET_STORED_REF_POINTS_AND_TO_UNIT)
      dispatch('project/objects/updateAllObjectsAreas', {}, { root: true })
    }
  },
  updateMeasurements ({ state, dispatch, commit }, payload) {
    commit(types.SET_MEASURE_PROPERTY, { property: payload.property, value: payload.value })
    dispatch('project/objects/updateAllObjectsAreas', {}, { root: true })
  }
}

const mutations = withHistoryMutation({
  [types.UPDATE_REF_POINT_START] (state, point) {
    state.reference.start.setXY(point.x, point.y)
  },
  [types.UPDATE_REF_POINT_END] (state, point) {
    state.reference.end.setXY(point.x, point.y)
  },
  [types.SET_REF_POINT_START] (state, point) {
    state.reference.start = point
  },
  [types.SET_REF_POINT_END] (state, point) {
    state.reference.end = point
  },
  [types.RESET_REF_POINTS] (state) {
    state.reference.start = null
    state.reference.end = null
  },
  [types.STORE_TO_UNIT] (state, payload) {
    state.stored.toUnit.push(payload)
  },
  [types.STORE_REF_POINTS] (state) {
    state.stored.references.push(Object.assign({}, state.reference))
  },
  [types.RESET_STORED_REF_POINTS_AND_TO_UNIT] (state) {
    state.stored.references = []
    state.stored.toUnit = []
  },
  [types.SET_MEASURE_PROPERTY] (state, payload) {
    if (Object.prototype.hasOwnProperty.call(state.measure, payload.property)) {
      state.measure[payload.property] = payload.value
    }
  },
  [types.UPDATE_RULER_POINT_START] (state, point) {
    state.ruler.start.x = point.x
    state.ruler.start.y = point.y
  },
  [types.UPDATE_RULER_POINT_END] (state, point) {
    state.ruler.end.x = point.x
    state.ruler.end.y = point.y
  },
  [types.SET_RULER_POINT_START] (state, point) {
    state.ruler.start = point
  },
  [types.SET_RULER_POINT_END] (state, point) {
    state.ruler.end = point
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    editor: editorModule
  }
}
