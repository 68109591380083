<template>
  <v-popover
    :disabled="tooltipDisabled"
    :placement="tooltipPlacement"
    popover-inner-class="popover-inner popover-no-pointer-events"
  >
    <!-- Popover target (for the events and position) -->
    <button
      :id="id"
      :class="[buttonClasses, classes]"
      :disabled="disabled"
      :style="buttonStyle"
      @click="onClick"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
    >
      <icon
        v-if="iconClasses"
        :classes="iconClasses"
        style-width="18px"
        style-height="18px"
      />
      <span v-html="label" />
      <!--
          @slot Content slot
      -->
      <slot name="content" />
    </button>
    <template #popover>
      <div class="c-tooltip">
        <div :class="headerClasses">
          <h4
            class="c-tooltip-label"
            v-html="tooltipLabel"
          />
          <p
            :class="categoryClasses"
            v-html="tooltipCategory"
          />
        </div>
        <div v-if="hasPopupSlot">
          <hr
            v-if="tooltipLabel || tooltipCategory"
            :class="lineClasses"
          >
          <!--
              @slot Popup slot
          -->
          <slot name="popup" />
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Icon from '@/components/common/Icon'
export default {
  name: 'ButtonBase',
  components: { Icon },
  props: {
    /**
     * Additional CSS classes
     */
    classes: {
      type: null,
      default: null,
      required: false
    },
    /**
     * Disables the button
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Icon CSS classes - mostly Font Awesome
     */
    iconClasses: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Description label placed inside of the button
     */
    label: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Inline function for onClick action<br>
     * <b>Example:</b><br>
     * <i>() => {return someFunction}</i>
     * @values false, ()=>{return someFunction}
     */
    onClickAction: {
      type: [Function, Boolean],
      default: false,
      required: false
    },
    /**
     * Sets corrections in css correlated with theme
     */
    theme: {
      type: String,
      default: 'dark',
      required: false
    },
    /**
     * Defines color theme
     * @values blue, green, gray
     */
    styleColor: {
      type: String,
      default: 'blue',
      required: false
    },
    /**
     * Defines button min width
     */
    styleMinWidth: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines button min height
     */
    styleMinHeight: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines button size
     * @values xs, s, m, l, xl
     */
    styleSize: {
      type: String,
      default: 'xs',
      required: false
    },
    /**
     * Category label displayed on top of the tooltip
     */
    tooltipCategory: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Disables the tooltip
     */
    tooltipDisabled: {
      type: Boolean,
      default: true,
      required: false
    },
    /**
     * Description label displayed on top of the tooltip
     */
    tooltipLabel: {
      type: String,
      default: '',
      required: false
    },
    /**
     * Defines tooltip placement
     * @values auto, auto-start, auto-end, top, top-start, top-end, right, right-start, right-end, bottom, bottom-start, bottom-end, left, left-start, left-end
     */
    tooltipPlacement: {
      type: String,
      default: 'auto',
      required: false
    },
    /**
     * Defines button type (submit; reset)
     */
    type: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    /* ------------------------------------------
     *  Styles
     *------------------------------------------ */

    /**
     * Sets CSS classes of the whole component
     */
    buttonClasses () {
      return [
        'c-button-base',
        /* size */
        this.styleSize === 'xs' ? 'c-button-base--size-xs' : '',
        this.styleSize === 's' ? 'c-button-base--size-s' : '',
        this.styleSize === 'm' ? 'c-button-base--size-m' : '',
        this.styleSize === 'l' ? 'c-button-base--size-l' : '',
        this.styleSize === 'xl' ? 'c-button-base--size-xl' : '',

        /* color */
        this.styleColor === 'blue' ? 'c-button-base--color-blue' : '',
        this.styleColor === 'green' ? 'c-button-base--color-green' : '',
        this.styleColor === 'gray' ? 'c-button-base--color-gray' : '',

        /* only icon */
        this.label === '' ? 'c-button-base--icon-only' : ''
      ]
    },
    /**
     * Sets CSS styles of the whole component
     */
    buttonStyle () {
      let inlineStyle = ''

      inlineStyle += this.styleMinWidth !== '' ? `min-width: ${this.styleMinWidth};` : ''
      inlineStyle += this.styleMinHeight !== '' ? `min-height: ${this.styleMinHeight};` : ''

      return inlineStyle
    },
    /**
     * Sets CSS classes of the category label
     */
    categoryClasses () {
      return [
        'c-tooltip-category',
        `c-tooltip-category--${this.theme}-theme`
      ]
    },
    /**
     * Sets CSS classes of the header
     */
    headerClasses () {
      return [
        'c-tooltip-header',
        this.hasPopupSlot ? '' : 'c-tooltip-header--no-slot'
      ]
    },
    /**
     * Sets CSS classes of the line
     */
    lineClasses () {
      return [
        'c-tooltip-line',
        `c-tooltip-line--${this.theme}-theme`
      ]
    },
    /**
     * Checks if popup slot is empty
     */
    hasPopupSlot () {
      return !!this.$slots.popup
    }
  },
  methods: {
    onClick () {
      if (this.onClickAction) {
        this.onClickAction()
      }
      /**
       * Triggers when the button is clicked
       *
       * @event click
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('click', { id: this.id })
    },
    onMouseEnter () {
      /**
       * Triggers when the cursor enters the button
       *
       * @event mouseenter
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('mouseenter', { id: this.id })
      this.mouseOver = true
    },
    onMouseLeave () {
      /**
       * Triggers when the cursor leaves the button
       *
       * @event mouseleave
       * @property {{id: string}} value - Emitted payload
       * @property {string} id - ID of this component (also used in html and tests)
       */
      this.$emit('mouseleave', { id: this.id })
      this.mouseOver = false
    }
  }
}
</script>
