function performancePlugin (Vue) {
  if (performancePlugin.installed) return

  const performanceHelper = new Vue({
    data: () => {
      return {
        map: new Map(),
        t0: 0,
        t1: 0
      }
    },
    methods: {
      start (key) {
        this.map.set(key, { t0: performance.now(), t1: 0 })
      },
      stop (key) {
        this.end(key)
      },
      end (key) {
        if (this.map.has(key)) {
          const map = this.map.get(key)
          map.t1 = performance.now()
          this.map.set(key, map)
        }
      },
      get_needed_milliseconds (key) {
        if (this.map.has(key)) {
          const map = this.map.get(key)
          this.map.delete(key)

          return Number(map.t1) - Number(map.t0)
        }

        return 0
      },
      get_time_needed (key) {
        if (this.map.has(key)) {
          const map = this.map.get(key)

          this.map.delete(key)

          const milliseconds = Number(map.t1) - Number(map.t0)
          const pad = (n, z = 2) => ('00' + n).slice(-z)
          const hh = pad(milliseconds / 3.6e6 | 0)
          const mm = pad((milliseconds % 3.6e6) / 6e4 | 0)
          const ss = pad((milliseconds % 6e4) / 1000 | 0)
          const mmm = pad(milliseconds % 1000, 3)

          return `${hh}:${mm}:${ss}.${mmm}`
        }

        return ''
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$performance', {
    get () {
      return performanceHelper
    }
  })

  Vue.mixin({})
  Vue.currency = Vue.prototype.$performance
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(performancePlugin)
}

export default performancePlugin
