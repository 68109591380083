<template>
  <transition name="c-dialog">
    <div
      v-if="isVisible"
      :style="`z-index: ${styleDialogZIndex};`"
      class="c-dialog"
    >
      <div
        :style="containerStyle"
        class="c-dialog__container"
      >
        <div
          :style="`height: ${styleHeaderHeight}; min-height: ${styleHeaderHeight};`"
          class="c-dialog__header-container u-bdradiust-big"
        >
          <div
            v-if="number"
            class="c-dialog__step"
          >
            <div>{{ number }}</div>
          </div>
          <div class="c-dialog__header">
            <div class="c-dialog__title">
              {{ title }}
            </div>
            <div class="c-dialog__subtitle">
              {{ subtitle }}
            </div>

            <!--
                @slot Slot for additional header content
            -->
            <slot name="header" />
          </div>

          <button-icon
            id="dialog-frame-close"
            :disabled="cannotClose"
            classes="c-dialog__close"
            icon-classes="fas fa-2x fa-times"
            style-color="darker"
            style-font-size="l"
            @click="close"
          />
        </div>
        <div
          :class="innerClasses"
        >
          <div
            class="c-dialog__content"
            :style="contentStyle"
          >
            <!--
                @slot Slot for content
            -->
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from 'vuex'

import MixinDialog from '@/mixins/dialogs'
import ButtonIcon from '@/components/common/ButtonIcon'

/**
 * The DialogsFrame Component.
 * @displayName Dialogs Frame
 */

export default {
  name: 'DialogsFrame',
  components: { ButtonIcon },
  mixins: [
    MixinDialog
  ],
  props: {
    /**
     * Dialog name from store (modules/dialogs.js).
     * Used to save information about currently opened dialogs and their additional data ('dialogProps')
     */
    targetDialog: {
      type: String,
      required: true
    },
    /**
     * Numeration for header (for showing steps in some process)
     */
    number: {
      type: Number,
      required: false,
      default: 0
    },
    /**
     * Title label
     */
    title: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Subtitle label
     */
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines optional whole dialog width<br>
     * <b>Example:</b><br>
     * <i>:style-dialog-width="'1280px'"</i>
     */
    styleDialogWidth: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Defines optional whole dialog height<br>
     * <b>Example:</b><br>
     * <i>:style-dialog-height="'670px'"</i>
     */
    styleDialogHeight: {
      type: String,
      required: false,
      default: null
    },
    /**
     * Defines header height
     */
    styleHeaderHeight: {
      type: String,
      required: false,
      default: '70px'
    },
    /**
     * Defines whole dialog z-index
     */
    styleDialogZIndex: {
      type: Number,
      required: false,
      default: 1000
    },
    /**
     * Defines if the content will be rendered with padding
     */
    styleContentNoFrame: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Defines if the content has 100% width and height (prevents from scrolling)
     */
    styleContentSizeFit: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Defines if the scrollbar should have flipped colors
     */
    styleContentFlipScrollbarColor: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Blocks closing
     */
    cannotClose: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      visible: state => state.dialogs.visible
    }),
    isVisible () {
      return this.visible[this.targetDialog]
    },
    containerStyle () {
      let inlineStyle = ''
      inlineStyle += this.styleDialogWidth !== null ? `width: ${this.styleDialogWidth};` : ''
      inlineStyle += this.styleDialogHeight !== null ? `height: ${this.styleDialogHeight};` : ''

      return inlineStyle
    },
    innerClasses () {
      return [
        'c-dialog__inner',
        this.styleContentNoFrame ? '' : 'c-dialog__inner--padding',
        this.styleContentFlipScrollbarColor ? 'c-dialog__inner--scrollbar-default' : 'c-dialog__inner--scrollbar-flipped'
      ]
    },
    contentStyle () {
      let inlineStyle = ''
      inlineStyle += this.styleContentSizeFit ? 'width: 100%; height: 100%' : ''

      return inlineStyle
    }
  },
  watch: {
    isVisible: {
      handler (n, o) {
        if (n === true) {
          /**
           * Triggers when the dialog frame is opened
           */
          this.$emit('dialog-opened')
        }
      }
    }
  },
  beforeMount () {
    document.addEventListener('keydown', this.keyPressed)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.keyPressed)
  },
  methods: {
    close () {
      /**
       * Triggers when the dialog frame is closed
       */
      this.$emit('abort-button-pressed')
      this.mx_dialogs_setTab(0)
      this.mx_dialogs_closeDialog()
    },
    /**
     * Manages keyboard events
     *
     * @param {KeyboardEvent} e
     */
    keyPressed (e) {
      if (this.isVisible) {
        switch (e.key) {
          case 'Esc': // IE/Edge specific value
          case 'Escape':
            if (!this.cannotClose) {
              this.close()
            }
            break
        }
      }
    }
  }
}
</script>
