<template>
  <div class="c-los__panel-content">
    <div
      v-if="getSelectedTrackCutLine === null && getSelectedTracks.length === 0"
      class="c-los__panel-item"
    >
      <los-calculation-by-angles @calculationByAngles="handleEvents" />
      <los-product-alignment @changeProductAlignment="handleEvents" />
      <los-product-angle @changeProductAngle="handleEvents" />
      <los-product-shift @changeProductShift="handleEvents" />
      <los-product-properties @changeProductProperties="handleEvents" />
      <los-product-costs />
    </div>
    <los-track-information v-else-if="getSelectedTracks.length > 0 && getSelectedTrackCutLine === null" />
    <los-track-cut v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import LosCalculationByAngles from '@/components/modules/lineOfSight/tools/LosCalculationByAngles'
import LosProductAlignment from '@/components/modules/lineOfSight/tools/LosProductAlignment'
import LosProductAngle from '@/components/modules/lineOfSight/tools/LosProductAngle'
import LosProductShift from '@/components/modules/lineOfSight/tools/LosProductShift'
import LosProductProperties from '@/components/modules/lineOfSight/tools/LosProductProperties'
import LosTrackInformation from '@/components/modules/lineOfSight/tools/LosTrackInformation'
import LosProductCosts from '@/components/modules/lineOfSight/tools/LosProductCosts'

import LosTrackCut from '@/components/modules/lineOfSight/tools/LosTrackCut'

export default {
  name: 'LineOfSightProductOptions',
  components: {
    LosCalculationByAngles,
    LosProductAlignment,
    LosProductAngle,
    LosProductShift,
    LosProductProperties,
    LosTrackCut,
    LosTrackInformation,
    LosProductCosts
  },
  computed: {
    ...mapGetters({
      getSelectedTrackCutLine: 'lineOfSightNew/getSelectedTrackCutLine',
      getSelectedTracks: 'lineOfSightNew/getSelectedTracks'
    })
  },
  mounted () {
    this.$events.on('LINE_OF_SIGHT_PRODUCT_PROPERTIES_CHANGED', this.handleEvents)
  },
  beforeDestroy () {
    this.$events.off('LINE_OF_SIGHT_PRODUCT_PROPERTIES_CHANGED', this.handleEvents)
  },
  methods: {
    ...mapActions({
      updateObjectProduct: 'lineOfSightNew/updateObjectProduct'
    }),
    handleEvents ({ action, data }) {
      switch (action) {
        case 'calculationByAngles':
          this.$emit('productPropertiesChanged', { angles: data })
          break
        case 'changeProductAngleBySlider':
          this.updateObjectProduct(data)
          this.$emit('productPropertiesChanged')
          break
        default:
          this.updateObjectProduct(data)
          this.$emit('productPropertiesChanged')
      }
    }
  }
}
</script>
