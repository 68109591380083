<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.updateProfile.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.updateProfile.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <div class="o-grid o-grid--center">
          <div class="o-grid__col u-4/12 u-text-center">
            <!-- Logo -->
          </div>
          <div class="o-grid__col u-8/12 u-text-center">
            <form
              v-if="user !== null"
              class="c-form"
            >
              <drop-down
                id="update-user-salutation"
                v-model="user.salutation"
                :items="mx_languages_getSalutations"
                :label="$t('dialogs.updateProfile.salutation')"
                :required="true"
                :style-up="false"
                classes="u-mt-size-standard"
                label-key="label"
                style-current-item-width="240px"
                value-key="value"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-user-name"
                v-model="user.firstname"
                :focus-on-mount="true"
                :is-store-mode="false"
                :label="$t('dialogs.updateProfile.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-user-surname"
                v-model="user.lastname"
                :is-store-mode="false"
                :label="$t('dialogs.updateProfile.surname')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-user-address-phone"
                v-model="user.phone"
                :is-store-mode="false"
                :label="$t('dialogs.updateProfile.phone')"
                :required="true"
                :validate="(v) => {return mx_validate_validatePhone(v)}"
                placeholder="+49 1234 567 890"
                style-input-width="240px"
                type="tel"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="update-profile-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="update-profile-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return update() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import config from '@/config'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinLanguages from '@/mixins/languages'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Update Profile
     */
export default {
  name: 'UpdateProfile',
  components: {
    ButtonBase,
    DropDown,
    DialogFrame,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinLanguages
  ],
  data: () => {
    return {
      targetDialog: 'updateProfile',
      cannotClose: false,
      user: null,
      config: config
    }
  },
  computed: {
    ...mapState({
      visible: state => state.dialogs.visible
    }),
    ...mapGetters({
      me: 'backendAPI/user/get'
    }),
    isVisible () {
      return this.visible[this.targetDialog]
    }
  },
  watch: {
    isVisible: {
      handler (n, o) {
        if (n === true) {
          this.user = _.cloneDeep(this.me)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateMe: 'backendAPI/user/updateMe'
    }),

    async update () {
      this.user.userId = this.user.id
      const isUpdated = await this.updateMe(this.user)
      if (isUpdated) {
        this.user = null
      }
      this.close()
    },
    close () {
      this.user = null
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
