<template>
  <g
    v-if="object"
    :id="'group-' + object.id"
    :transform="transform"
    class="c-object c-object__group"
    :class="{'c-object__group--active':isObjectSelected(object.id)}"
  >
    <g :id="object.id">
      <vnodes :vnodes="objElement" />
    </g>
    <g v-if="object.selectionMode !== objectModes.LOS && !isUseFromObjectById(object.id)">
      <vnodes :vnodes="mx_create_modifier_modifier" />
      <g
        v-if="object.hasChildren"
        :id="`masks-modifier-group-${object.id}`"
      >
        <vnodes :vnodes="mx_create_modifier_childModifier" />
      </g>
      <template v-if="object.selectionMode === objectModes.EDIT && showLengthsAngles">
        <slot name="angles" />
        <slot name="lengths" />
      </template>
      <slot
        v-if="!isRotating"
        name="area-calc"
      />
      <slot
        v-if="!isRotating"
        name="product-title"
      />
      <object-cut-menu v-if="object.isCutOut && !mx_create_draggedModifier && !isRotating" />
      <tool-add-nearest-point-to-path
        v-if="object.selectionMode === objectModes.EDIT && objectModifiers"
        :disable="disableAddPointToPath"
        :path="object.path()"
        :pointsToCheck="pointsToCheckAgainst"
        @addNewPoint="addNewPointToPath"
        @locationChanged="markLocationToAddPoint"
      />
    </g>
    <los-tracks
      v-if="losModeActive && object.product !== null"
      :id="object.id"
      :waste="isUseFromObjectById(object.id)"
    />
  </g>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'
import config from '@/config'
import { Path, Point } from '@/classes/objects'

import LosTracks from '@/components/modules/lineOfSight/svg/LosTracks'

import ObjectCutMenu from '@/components/editor/menu/ObjectCut'
import ToolAddNearestPointToPath from '@/components/svg/object/tools/AddNearestPointToPath'

import MixinCreate from '@/mixins/create'
import MixinMath from '@/mixins/math'
import MixinCollision from '@/mixins/collision'
import MixinObject from '@/mixins/object'
import MixinTrackState from '@/mixins/trackstate'

import PaperLayer from '@/classes/paperLayer'

/**
 * Displays drawn areas
 * @displayName Object
 */
export default {
  name: 'SvgObject',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    },
    LosTracks,
    ObjectCutMenu,
    ToolAddNearestPointToPath
  },
  mixins: [
    MixinCreate,
    MixinMath,
    MixinCollision,
    MixinObject,
    MixinTrackState
  ],
  props: {
    object: {
      type: [Array, Object],
      required: true
    }
  },
  data: () => {
    return {
      dragObject: false,
      lastPointCoordinates: null,
      objectTypes: config.objects.types,
      objectModes: config.objects.modes,
      isRotating: false,
      bBox: null,
      addNearestPointTo: null,
      objectModifiers: null,
      showLengthsAngles: false
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      lastInternalAction: state => state.editor.lastInternalAction,
      translateObjects: state => state.project.object.translate,
      rotateObject: state => state.project.object.rotate,
      commonSvgSettings: state => state.common.svg,
      cutModeActive: state => state.events.mode.cut,
      panModeActive: state => state.events.mode.pan,
      losModeActive: state => state.events.mode.los,
      gridSnap: state => state.editor.grid.snap,
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      pan: state => state.editor.viewbox.pan,
      scale: state => state.editor.viewbox.scale
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      isObjectSelected: 'events/isObjectSelected',
      getPointFromObject: 'project/objects/getPointFromObject',
      getPointFromChild: 'project/objects/getPointFromChild',
      getChildByPoint: 'project/objects/getChildByPoint',
      editor: 'editor/get',
      viewboxW: 'editor/viewbox/w',
      viewboxH: 'editor/viewbox/h',
      isUseFromObjectById: 'lineOfSightNew/isUseFromObjectById'
    }),
    /**
     * Handles the object svg-path
     * @returns {*}
     */
    objElement () {
      switch (this.object.type) {
        case this.objectTypes.PATH:
          return this.mx_object_elementEvents(this.mx_object_objPath())
        case this.objectTypes.CIRCLE:
          return this.mx_object_elementEvents(this.mx_object_objCircle())
      }
      return null
    },
    /**
     * transform element
     * - translate, rotate
     * @returns {string}
     */
    transform () {
      return this.isObjectSelected(this.object.id) && this.checkCutMode
        ? `translate(${this.translateObjects.x} ${this.translateObjects.y}) rotate(${this.rotateObject.deg} ${this.rotateObject.cx} ${this.rotateObject.cy})`
        : ''
    },
    /**
     * Is cut mode active
     * @returns {*|boolean|boolean}
     */
    checkCutMode () {
      return (this.cutModeActive && this.object.isCutOut) || (!this.cutModeActive && !this.object.isCutOut)
    },
    disableAddPointToPath () {
      return (this.object.type === config.objects.types.CIRCLE || this.mx_create_draggedModifier !== false ||
        this.mx_create_draggedModifierHandle !== false || this.dragObject !== false)
    },
    pointsToCheckAgainst () {
      const points = []
      const circles = this.objectModifiers

      for (let i = 0; i < circles.length; i++) {
        const point = {
          x: circles[i].getAttribute('cx'),
          y: circles[i].getAttribute('cy')
        }
        points.push(point)
      }

      return (points.length > 0) ? points : null
    }
  },
  watch: {
    'object.selectionMode' (val) {
      if (val === this.objectModes.SELECTED) {
        this.$nextTick(() => {
          this.mx_create_activeModifier = undefined
        })
      }
    },
    snapKeyPressed () {
      this.$_handleMoveBoxCollision()
    },
    panModeActive (val, oldVal) {
      if (oldVal === true && val === false && this.mx_object_lastMouseDown) {
        this.mx_object_overrideLastPanAndMouse()
      }
    },
    scale () {
      this.$_setShowLengthsAngles()
    },
    object: {
      handler: function (n, o) {
        this.$nextTick(() => {
          this.initModifiers()
          // this.objectModifiers = this.$el.querySelectorAll('.c-object__circle')
        })
      },
      deep: true
    }
  },
  mounted () {
    if (window.PointerEvent) {
      this.editor.addEventListener('pointermove', this.handleMouseMove)
      this.editor.addEventListener('pointerup', this.handleMouseUp)
    } else {
      this.editor.addEventListener('touchmove', this.handleMouseMove)
      this.editor.addEventListener('mousemove', this.handleMouseMove)
      this.editor.addEventListener('mouseup', this.handleMouseUp)
    }
    this.$events.on(editorEventTypes.OBJECT_STOP_DRAGGING, this.stopDragging)

    this.$events.on(editorEventTypes.OBJECT_SELECTED, () => {
      this.setSelectionMode({ object: this.object, selectionMode: this.objectModes.SELECTED })
    })
    this.$events.on(editorEventTypes.OBJECT_MODIFIER_DRAG, (evtData) => {
      this.lastPointCoordinates = { x: evtData.x, y: evtData.y }
    })
    this.$events.on(editorEventTypes.OBJECT_HANDLE_MODIFIER_DRAG, (evtData) => {
      this.lastPointCoordinates = { x: evtData.x, y: evtData.y }
    })
    this.$events.on(editorEventTypes.OBJECT_ROTATE, (data) => {
      this.isRotating = (data !== null)
    })

    this.$_setShowLengthsAngles()
    this.initModifiers()
  },
  beforeDestroy () {
    this.editor.removeEventListener('touchmove', this.handleMouseMove)
    this.editor.removeEventListener('mousemove', this.handleMouseMove)
    this.editor.removeEventListener('mouseup', this.handleMouseUp)
  },
  methods: {
    ...mapActions({
      updateHandle: 'project/objects/updateObjectHandle',
      updatePoint: 'project/objects/updateObjectPoint',
      scaleObjectProp: 'project/scaleObject',
      updateObjectMoveBySnap: 'project/snapMultipleObjects',
      setMouseMenu: 'events/openContextMenu',
      stepBack: 'history/stepBack',
      setSelectionMode: 'project/objects/setSelectionMode',
      addNearestPointToObject: 'project/objects/addNearestPointToObject'
    }),
    ...mapMutations({
      setCurrentMovingPoint: 'events/' + mutationTypes.EVT_SET_CURRENT_MOVING_POINT,
      setLastInternalAction: 'editor/' + mutationTypes.SET_LAST_INTERNAL_ACTION,
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU,
      addCutObjectToSelection: 'events/' + mutationTypes.EVT_ADD_CUT_OBJECT_TO_SELECT
    }),
    /**
     * init all modifiers
     * @public
     */
    initModifiers () {
      this.objectModifiers = this.$el.querySelectorAll('.c-object__circle')
    },
    /**
     * Handles drag flag
     * @public
     */
    stopDragging () {
      this.dragObject = false
    },
    /**
     * Handles bounding box
     * - show, hide
     * @public
     */
    toggleBBox () {
      this.setSelectionMode({
        object: this.object,
        selectionMode: this.object.selectionMode === this.objectModes.SELECTED
          ? this.objectModes.EDIT
          : this.objectModes.SELECTED,
        isCutObject: this.object.isCutOut
      })
    },
    /**
     * Provides mouse up events
     * @param event Event
     * @public
     */
    handleMouseUp (event) {
      if (this.snapKeyPressed) {
        if (this.mx_create_draggedModifier /* && this.object.getPoint(this.activeModifier) !== null */) {
          this.$_handleMouseUpModifierSnap(event)
        } else if (this.mx_create_draggedModifierHandle /* && this.object.getPoint(this.activeModifier) !== null */) {
          this.$_handleMouseUpModifierHandleSnap(event)
        } else if (this.dragObject === true) {
          this.$_handleMouseUpObjectSnap()
        }
      }
      // modifications after mouse up (and snap)
      if (this.mx_create_draggedModifier) {
        if (!PaperLayer.isValidObject(this.object)) {
          this.mx_trackstate_breakStateTransaction()
          this.$_updatePoint(this.lastPointCoordinates)
          this.$toasted.show(this.$t('toast.objects.invalidShape'), { duration: 7000, type: 'error' })
        }
        this.setCurrentMovingPoint(null)
        this.mx_create_dragModifier({ index: null, drag: false })
      } else if (this.mx_create_draggedModifierHandle) {
        if (!PaperLayer.isValidObject(this.object)) {
          this.mx_trackstate_breakStateTransaction()
          this.$_updateHandle(this.lastPointCoordinates)
          this.$toasted.show(this.$t('toast.objects.invalidShape'), { duration: 7000, type: 'error' })
        }
        this.setCurrentMovingPoint(null)
        this.mx_create_dragModifierHandle({ index: null, point: null, drag: false })
      } else if (this.dragObject) {
        if (this.mx_object_lastMouseDown !== null) {
          if (this.gridSnap) {
            this.$_handleMouseUpObjectSnap()
          }
          this.$events.fire(editorEventTypes.OBJECT_STOP_DRAGGING, this.currentSelectedObjects)
        }
      }
    },
    /**
     * Provides mouse move events
     * @param event Event
     * @public
     */
    handleMouseMove (event) {
      if (this.ctrl !== 'move') return false
      if (!this.dragObject) {
        this.$_handleMoveBoxCollision()
      }
      if (this.mx_create_draggedModifier) {
        this.$_handleMoveDragModifier(event)
      } else if (this.mx_create_draggedModifierHandle) {
        this.$_handleMoveDragModifierHandle(event)
      } else if (this.dragObject) {
        if (!this.panModeActive) {
          this.$_handleMoveDragObject(event)
        } else {
          this.$_handleMoveDragObjectWithPanning(event)
        }
        this.$_handleMoveBoxCollision()
      }
    },
    /**
     * Update bounding box
     * @param {object} newBBox {width: Number, height: Number}
     * @public
     */
    updateBBox (newBBox) {
      this.bBox = {
        width: newBBox.width,
        height: newBBox.height
      }
    },
    /**
     * Adds a new point to the current object
     * @param {object} result {nearestPoint: {x: Number, y: Number}}
     * @public
     */
    addNewPointToPath (result) {
      const point = new Point(result.nearestPoint.x, result.nearestPoint.y)
      this.mx_trackstate_trackState(() => {
        this.addNearestPointToObject({
          objectId: this.object.id,
          point: point,
          nearest: this.addNearestPointTo,
          isCutOut: this.object.isCutOut
        })
      })
    },
    /**
     * Shows the nearest location point on path outlines
     * @param {object|null} location {point2: {x: Number, y: Number}}
     * @public
     */
    markLocationToAddPoint (location) {
      if (location !== null) {
        const vertex = this.$el.querySelector(`circle[cx="${location.point2.x}"][cy="${location.point2.y}"]`)

        this.addNearestPointTo = vertex.getAttribute('data-id')
        this.mx_create_hoverModifier(this.addNearestPointTo)
      } else {
        this.mx_create_hoverModifier(null)
        this.mx_create_activeModifier = undefined
      }
    },
    /**
     * Checks if showLengthsAngles lengths should be displayed. Requires object to be mounted!
     **/
    $_setShowLengthsAngles () {
      const bBox = document.getElementById(this.object.id).getBBox()
      if (bBox !== null) {
        const widthPercent = bBox.width / this.viewboxW
        const heightPercent = bBox.height / this.viewboxH

        this.showLengthsAngles = (widthPercent > config.objects.SHOW_LENGTHS_ANGLES_SCALE ||
          heightPercent > config.objects.SHOW_LENGTHS_ANGLES_SCALE)
      } else {
        this.showLengthsAngles = false
      }
    },
    /**
     * Calculate and send dragging object position for translation
     * @param {Object} event Event
     * @private
     */
    $_handleMoveDragObject (event) {
      this.$events.fire(editorEventTypes.OBJECT_START_DRAGGING, {
        x: event.clientX - this.mx_object_lastMouseDown.clientX,
        y: event.clientY - this.mx_object_lastMouseDown.clientY
      })
    },
    /**
     * Calculate and send dragging object position for translation with observing the relative panning of the stage
     * @param {Object} event Event
     * @private
     */
    $_handleMoveDragObjectWithPanning (event) {
      this.$events.fire(editorEventTypes.OBJECT_START_DRAGGING, {
        x: (event.clientX - this.mx_object_lastMouseDown.clientX) -
          ((this.pan.x - this.mx_object_lastMouseDownPan.x) / (0.5 / this.scale)),
        y: (event.clientY - this.mx_object_lastMouseDown.clientY) -
          ((this.pan.y - this.mx_object_lastMouseDownPan.y) / (0.5 / this.scale))
      })
    },
    /**
     * Update the handle after move
     * @param newHandlePoint
     * @private
     */
    $_updateHandle (newHandlePoint) {
      this.updateHandle({
        object: this.object.id,
        point: this.mx_create_activeModifier,
        isCutOut: this.object.isCutOut,
        handle: this.mx_create_activeModifierHandle,
        movement: newHandlePoint
      })
    },
    /**
     * Update the point after move
     * @param newPoint
     * @private
     */
    $_updatePoint (newPoint) {
      this.updatePoint({
        object: this.object.id,
        pid: this.mx_create_activeModifier,
        isCutOut: this.object.isCutOut,
        nPoint: newPoint
      })
    },
    /**
     * Check the bounding box collision between different objects, update points or clear points if any set and no collision detected
     * @private
     */
    $_handleMoveBoxCollision () {
      if (this.dragObject === true) {
        this.mx_collision_setOrUpdateCollidingPoints(this.gridSnap, this.snapKeyPressed,
          this.mx_object_nearestPointToMouse)
      }
    },
    /**
     * Handle the dragging of a modifier
     * @param event
     * @returns {boolean}
     * @private
     */
    $_handleMoveDragModifier (event) {
      const ePoint = this.mx_math_getCoordinatesFromEvent(event)
      const workingElement = (!this.mx_create_isActiveModifierChild)
        ? this.getPointFromObject(this.object.id, this.mx_create_activeModifier, this.object.isCutOut)
        : this.getPointFromChild(this.object.id, this.mx_create_activeModifier, this.object.isCutOut)
      const child = (this.mx_create_isActiveModifierChild) ? this.getChildByPoint(this.object.id,
        this.mx_create_activeModifier) : null
      if (!workingElement) return false
      this.setCurrentMovingPoint(this.mx_create_activeModifier)
      let newPoint = { x: ePoint.x, y: ePoint.y }
      if (event.shiftKey) {
        this.setLastInternalAction({ action: 'ortho', id: this.object.id })
        const pointBefore = (!this.mx_create_isActiveModifierChild)
          ? this.object.getPointBefore(this.mx_create_activeModifier)
          : child.getPointBefore(this.mx_create_activeModifier)
        newPoint = this.mx_math_ortho(newPoint, pointBefore)
      } else {
        this.setLastInternalAction(null)
      }
      this.hideMouseMenu()

      if (this.gridSnap && !this.snapKeyPressed) {
        newPoint = this.mx_collision_closestGridPoint(newPoint)
      }
      this.$_updatePoint(newPoint)
      if (this.snapKeyPressed && this.object instanceof Path) {
        this.mx_collision_setCollisionPoints(this.mx_collision_testCollidingPoints(newPoint))
      }
    },
    /**
     * Handle the dragging of a modifier handle like curve handles
     * @param event
     * @private
     */
    $_handleMoveDragModifierHandle (event) {
      const ePoint = this.mx_math_getCoordinatesFromEvent(event)
      this.setCurrentMovingPoint(this.mx_create_activeModifier)
      const newHandlePoint = { x: ePoint.x, y: ePoint.y }
      this.$_updateHandle(newHandlePoint)
      if (this.snapKeyPressed && this.object instanceof Path) {
        this.mx_collision_setCollisionPoints(this.mx_collision_testCollidingHandlePoints(newHandlePoint))
      }
    },
    /**
     * Handle the mouse up after moving a modifier and pressing the snap key
     * @private
     */
    $_handleMouseUpModifierSnap (event) {
      const ePoint = this.mx_math_getCoordinatesFromEvent(event)
      const newPoint = { x: ePoint.x, y: ePoint.y }
      const cp = this.mx_collision_testCollidingPoints(newPoint)
      if (cp.length) {
        this.$_updatePoint({ x: cp[0].p1.x, y: cp[0].p1.y })
        this.mx_collision_setCollisionPoints([])
      } else if (this.gridSnap) {
        this.$_updatePoint(this.mx_collision_closestGridPoint(newPoint))
      }
    },
    /**
     * Handle the mouse up after moving a handle (like from a curve) and pressing the snap key
     * @private
     */
    $_handleMouseUpModifierHandleSnap (event) {
      const ePoint = this.mx_math_getCoordinatesFromEvent(event)
      let newPoint = { x: ePoint.x, y: ePoint.y }
      const cp = this.mx_collision_testCollidingHandlePoints(newPoint)
      if (cp.length) {
        newPoint = (cp[0].handle === 1) ? {
          x: cp[0].p1.handles.x1,
          y: cp[0].p1.handles.y1
        } : { x: cp[0].p1.handles.x2, y: cp[0].p1.handles.y2 }
        this.$_updateHandle(newPoint)
        this.mx_collision_setCollisionPoints([])
      }
    },
    /**
     * Handle the object snap if dragging object to snap point
     * @private
     */
    $_handleMouseUpObjectSnap () {
      if (this.mx_collision_collisionPoints.length) {
        const pointTuple = this.mx_collision_getCollisionPointTupleWithSmallestDistance(
          this.mx_collision_collisionPoints)
        const movement = { x: pointTuple.p1.x - pointTuple.p2.x, y: pointTuple.p1.y - pointTuple.p2.y }
        const props = []
        if (this.cutModeActive) {
          // update only this cut object
          props.push({
            object: this.object.id,
            isCutOut: this.object.isCutOut,
            movement: movement
          })
        } else {
          // update all selected objects
          this.currentSelectedObjects.forEach(objectId => {
            const object = objectId === this.object.id ? this.object : this.getObject(objectId)
            props.push({
              object: object.id,
              isCutOut: object.isCutOut,
              movement: movement
            })
          })
        }
        this.updateObjectMoveBySnap(props)
        this.mx_collision_setCollisionPoints([])
      }
    }
  }
}
</script>
