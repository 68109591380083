<template>
  <div class="c-layout__header u-bgcolor-s5">
    <div
      v-if="!editorNav"
      class="c-layout__header-logo"
      :class="`c-layout__header-logo--${getTheme}-theme`"
    />
    <div
      v-else
      :class="`c-layout__header-logo-icon c-layout__header-logo-icon--${getTheme}-theme`"
    />
    <sub-nav v-if="editorNav" />
    <head-nav v-if="mainNav" />
  </div>
</template>

<script>
import HeadNav from './HeadNav'
import SubNav from '@/components/editor/SubNav'
import { mapGetters } from 'vuex'

export default {
  name: 'PageHeader',
  components: {
    HeadNav,
    SubNav
  },
  props: {
    // this allows using the `value` prop for a different purpose
    mainNav: {
      required: false,
      type: Boolean,
      default: false
    },
    editorNav: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      getTheme: 'gui/getTheme'
    })
  }
}
</script>
