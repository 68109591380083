<template>
  <div
    v-if="objectsHaveProducts"
    class="c-nav-sub__group"
  >
    <button-head-nav
      v-if="currentSelectedProducts.length === 1 && currentSelectedObjects.length === 1"
      id="sub-nav-button-product-edit"
      :category="$t('subNav.categories.product')"
      :label="$t('subNav.products.edit')"
      :on-click-action="()=>{return toggleLosMode()}"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon c-nav-sub__icon--edit-product"
      style-color="light"
    >
      <template #icon>
        <los-eye-blue-icon />
      </template>
    </button-head-nav>
    <button-head-nav
      id="sub-nav-button-product-remove"
      :category="$t('subNav.categories.product')"
      :disabled="shouldBlockGui"
      :label="$t('subNav.products.removeProduct')"
      :on-click-action="()=>{return removeProductFromObjects()}"
      :theme="getTheme"
      icon-classes="far fa-times-circle"
      style-color="light"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import MixinOperations from '@/mixins/operations'
import ButtonHeadNav from '@/components/common/ButtonHeadNav'
import LosEyeBlueIcon from '@/components/common/icons/los/LosEyeBlueIcon'

export default {
  name: 'ProductsPan',
  components: {
    LosEyeBlueIcon,
    ButtonHeadNav
  },
  mixins: [
    MixinOperations
  ],
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      isCutOut: state => state.project.objects.cutOut,
      currentSelectedProducts: state => state.events.currentSelectedProducts,
      currentSelectedObjects: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      getLosObject: 'lineOfSightNew/getObject',
      getObject: 'project/objects/getById',
      shouldBlockGui: 'events/shouldBlockGui',
      getTheme: 'gui/getTheme'
    }),
    objectsHaveProducts () {
      return this.currentSelectedProducts.length > 0 && this.ctrl === 'move' && !this.isCutOut
    }
  },
  methods: {
    ...mapMutations({
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU
    }),
    ...mapActions({
      removeProductFromSelectedObjects: 'project/objects/removeProductFromSelectedObjects',
      toggleLineOfSightMode: 'events/toggleLineOfSightMode',
      setObjectToWorkWith: 'lineOfSightNew/setObjectToWorkWith'
    }),
    toggleLosMode () {
      const object = this.getObject(this.currentSelectedObjects[0])
      this.toggleLineOfSightMode()
      this.setObjectToWorkWith(object)
      this.hideMouseMenu()
    },
    removeProductFromObjects () {
      this.mx_trackstate_trackState(() => {
        this.removeProductFromSelectedObjects({ objects: this.currentSelectedObjects, product: null })
      })
      this.hideMouseMenu()
    }
  }
}
</script>
