<template>
  <div>
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: 20,
        perPageDropdown: [10, 20, 50],
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: texts.pageLabel, // for 'pages' mode
        allLabel: texts.allLabel,
      }"
      :rows="locations"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'name', type: 'asc'}]
      }"
      theme="melos"
    >
      <template #emptystate>
        <div
          class="u-m-size-40 c-backend__nothing-to-show"
        >
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open" />
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template #table-row="props">
        <span v-if="props.column.field ==='name'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.street'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.city'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.zip'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.state'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span v-if="props.column.field ==='address.country'">
          {{ props.formattedRow[props.column.field] }}
        </span>

        <span
          v-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <router-link
            :id="`location-list-set-materials-${props.row.originalIndex}`"
            :to="{ name: 'Location-products', params: { id: props.row.id, cat: 'products', sub: 'turf'}}"
            class="c-button-base c-button-base--color-blue vgt-button"
            tag="button"
          >
            <icon classes="fas fa-2x fa-cogs" />
            {{ $t('backend.locations.setMaterials') }}
          </router-link>
          <button-base
            :id="`location-list-update-location-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'updateLocation', locationData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-edit"
          >
            <template #popup>
              {{ $t('common.edit') }}
            </template>
          </button-base>
          <button-base
            :id="`location-list-delete-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'deleteLocation', locationData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

/**
     * @displayName Location List Component
     */
export default {
  name: 'LocationList',
  components: {
    Icon,
    ButtonBase,
    VueGoodTable
  },
  mixins: [MixinDialogHandler],
  data () {
    return {
      columns: [
        {
          field: 'settings',
          sortable: false,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.locations.tableLabel.name,
          field: 'name'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.locations.tableLabel.address.street,
          field: 'address.street'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.locations.tableLabel.address.city,
          field: 'address.city'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.locations.tableLabel.address.zip,
          field: 'address.zip'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.locations.tableLabel.address.state,
          field: 'address.state'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.locations.tableLabel.address.country,
          field: 'address.country'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locations: 'backendAPI/location/getAll'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    }
  },
  methods: {
    openDialog (payload) {
      this.$_mx_dialogHandler_openDialog(
        { dialog: payload.type, onOff: true, dialogProps: { locationData: payload.locationData } })
    }
  }
}
</script>
