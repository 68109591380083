import * as types from '@/vuex/mutation-types'
import Vue from 'vue'

import Paper from 'paper'
import paperHelper from '@/helper/paperHelper'

const prepare_tracks = (tracks) => {
  const dataObjects = []

  tracks.forEach(track => {
    dataObjects.push({ name: track.name, path: track.getPathData(), oversize: track.oversize, length: track.length })
  })

  return dataObjects
}

const prepare_movables_by_tracks = (tracks, settings) => {
  const cp = new Paper.CompoundPath()
  const children = paperHelper.remap_tracks(tracks)

  cp.addChildren(children)

  const intersections = cp.getIntersections()
  const lineStorage = []
  const endsStorage = []

  intersections.forEach(intersection => {
    // angle to check against
    let angle = (Number(settings.turfAngle) > 180) ? Number(settings.turfAngle) - 180 : Number(settings.turfAngle)
    angle = Math.abs(Number(angle.toFixed(2)))
    // intersecting path
    const intersectionA = intersection.path
    const intersectionB = intersection.intersection.path

    // get unique intersection points
    const uniquePoints = paperHelper.get_unique_points(intersection.curve.points)

    // hit test
    const hitTest = paperHelper.points_hit_tracks(intersectionA, intersectionB, uniquePoints)

    if (hitTest) {
      // get angle between points (pointA -> pointB and pointB -> pointA)
      const angleA = paperHelper.get_angle_by_points(uniquePoints[0], uniquePoints[1])
      const angleB = paperHelper.get_angle_by_points(uniquePoints[1], uniquePoints[0])

      // get original tracks
      const trackA = paperHelper.get_track_by_name(tracks, intersectionA.name)
      const trackB = paperHelper.get_track_by_name(tracks, intersectionB.name)

      // is path end movable
      const movableA = Number(trackA.length) < Number(settings.turfLength)
      const movableB = Number(trackB.length) < Number(settings.turfLength)

      if ((angle === angleA || angle === angleB) && (movableA || movableB)) {
        const newTrackEnd = {
          p1: uniquePoints[0],
          p2: uniquePoints[1]
        }

        if (paperHelper.is_unique_track_end(lineStorage, newTrackEnd)) {
          lineStorage.push(newTrackEnd)

          const anchor = paperHelper.create_track_end_anchor(intersectionA, intersectionB, settings.turfAngle)

          endsStorage.push({
            id: `${intersectionA.name}|${intersectionB.name}`,
            p1: newTrackEnd.p1,
            p2: newTrackEnd.p2,
            anchor: anchor,
            path: `M${newTrackEnd.p1.x},${newTrackEnd.p1.y}L${newTrackEnd.p2.x},${newTrackEnd.p2.y}Z`,
            trackA: {
              path: trackA.path,
              name: trackA.name
            },
            trackB: {
              path: trackB.path,
              name: trackB.name
            },
            type: 'movableLine'
          })
        }
      }
    }
  })

  return endsStorage
}

const state = {
  tracks: [],
  movables: [],
  external: {
    turfLength: 0,
    turfAngle: 0
  },
  renderKey: 0
}

const getters = {
  getRenderKey: (state) => {
    return (state.tracks.length + state.movables.length) * Math.random()
  },
  getDrawableTracks: (state) => {
    return state.tracks
  },
  getDrawableMovables: (state) => {
    return state.movables
  }
}

const actions = {
  updateExternalProperties ({ rootState, commit }) {
    commit(types.LOS_DRAWABLE_ELEMENTS_UPDATE_EXTERNAL_PROPERTIES, {
      turfLength: rootState.lineOfSight.product.length,
      turfAngle: rootState.lineOfSight.product.angle
    })
  },
  setDrawableElements ({ dispatch, commit }, elements) {
    dispatch('updateExternalProperties')
    commit(types.LOS_SET_DRAWABLE_ELEMENTS, elements)
  },
  setDrawableTracks ({ state, dispatch, commit }, tracks) {
    dispatch('updateExternalProperties')

    const tracksData = prepare_tracks(tracks)
    commit(types.LOS_SET_DRAWABLE_ELEMENTS, { tracks: tracksData })

    const movableData = prepare_movables_by_tracks(tracksData, state.external)
    dispatch('setDrawableMovables', movableData)
  },
  setDrawableMovables ({ dispatch, commit }, movables) {
    commit(types.LOS_SET_DRAWABLE_ELEMENTS, { movables: movables })
    dispatch('lineOfSight/movableLines/set', movables, { root: true })
  },
  clearDrawableData ({ commit }) {
    commit(types.LOS_CLEAR_DRAWABLE_DATA)
  }
}

const mutations = {
  [types.LOS_SET_DRAWABLE_ELEMENTS] (state, elements) {
    for (const prop in elements) {
      if (Object.prototype.hasOwnProperty.call(state, prop)) {
        Vue.set(state, prop, elements[prop])
      }
    }
  },
  [types.LOS_DRAWABLE_ELEMENTS_UPDATE_EXTERNAL_PROPERTIES] (state, settings) {
    for (const prop in settings) {
      if (Object.prototype.hasOwnProperty.call(state.external, prop)) {
        Vue.set(state.external, prop, settings[prop])
      }
    }
  },
  [types.LOS_CLEAR_DRAWABLE_DATA] (state) {
    state.tracks = []
    state.movables = []
    state.external = {
      turfLength: 0,
      turfAngle: 0
    }
    state.renderKey = 0
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
