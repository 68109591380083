import { Point/*, Path */ } from '@/classes/objects'
import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Tools - Default Object Pattern
 * @displayName Triangle Object (not added)
 */
export default {
  name: 'ToolsTriangleMixin',
  mounted () {
    this.$events.on(editorEventTypes.DRAW_DEFAULT_FINISH_TRIANGLE, this.$_mx_tools_triangle_finishTriangle)
  },
  methods: {
    /**
         * Create triangle
         * @returns {VNode}
         * @public
         */
    mx_tools_trianlge_triangle () {
      let topPoint = {
        x: 0,
        y: 0
      }
      let circles = this.defaultObject.points.map((point, index, points) => {
        if (point.type !== 'M' && index <= points.length - 1) {
          this.pseudoPoints.push({ x: point.x, y: point.y })
          topPoint = {
            x: point.x,
            y: point.y
          }
          return this.defaultCircle(point)
        }
      })

      circles = circles.filter(item => {
        if (item !== undefined) {
          return item
        }
      })

      topPoint.x = topPoint.x + ((this.mouse.x - topPoint.x) / 2)
      topPoint.y = topPoint.y - (topPoint.y - (this.mouse.y - topPoint.y)) / 2

      circles.push(this.defaultCircle(this.mouse))
      circles.push(this.defaultCircle(topPoint))
      this.pseudoPoints.push(this.mouse)
      this.pseudoPoints.push(topPoint)

      return this.$createElement('g', {
        class: {
          'c-object c-object__group': true
        }
      }, [this.defaultPattern(this.pseudoPoints), circles])
    },
    /**
         * Finish the predefined triangle
         * @private
         */
    $_mx_tools_triangle_finishTriangle () {
      const startPoint = this.defaultObject.points[1]
      const p2 = new Point(startPoint.x + ((this.mouse.x - startPoint.x) / 2), startPoint.y - (startPoint.y - (this.mouse.y - startPoint.y)) / 2)

      this.defaultObject.points.splice(2, 0, p2)
      this.defaultObject.points.push(new Point(0, 0, 'Z'))

      if (!this.cut) {
        this.finishPredefinedObject()
      } else {
        this.preparePredefinedObject()
      }
    }
  }
}
