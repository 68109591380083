import Paper from 'paper'
import config from '@/config'

const paperScope = new Paper.PaperScope().setup(new Paper.Size(1, 1))

const get_needed_length = (obj, tracks, angle, alignment, measure) => {
  let object = null

  if (obj.type === config.objects.types.CIRCLE) {
    const props = obj.getCircleProperties()
    object = new paperScope.CompoundPath(new Paper.Path.Circle({
      center: [props.middle.x, props.middle.y],
      radius: props.radius
    }))
  } else {
    object = new paperScope.CompoundPath(obj.d())
  }

  object.name = obj.type

  const rotationCenter = object.bounds.center

  const allSideLengths = []

  object.rotate(-angle, rotationCenter)

  if (!alignment) {
    object.rotate(180, rotationCenter)
  }

  tracks.forEach(trackObject => {
    const track = new paperScope.CompoundPath(trackObject.getPathData())

    track.rotate(-angle, rotationCenter)

    if (!alignment) {
      track.rotate(180, rotationCenter)
    }

    // Points to check
    const p1 = new paperScope.Point(track.bounds.topRight)
    const p2 = new paperScope.Point(track.bounds.bottomRight)
    const p3 = new paperScope.Point(track.bounds.bottomLeft)

    if (!$_objectOutlineHitTest(object.curves, p1, p2)) {
      Array.prototype.push.apply(allSideLengths, $_checkPointsCrossingsWithObject(object, p1, p2, measure))
    }
    if (!$_objectOutlineHitTest(object.curves, p2, p3)) {
      Array.prototype.push.apply(allSideLengths, $_checkPointsCrossingsWithObject(object, p2, p3, measure))
    }

    track.remove()
  })

  object.remove()

  const reducer = (accumulator, currentValue) => Number(accumulator) + Number(currentValue)

  if (allSideLengths.length > 0) {
    const roundedSideLengths = allSideLengths.map(l => Math.ceil(l))
    return roundedSideLengths.reduce(reducer)
  }

  return 0
}

const $_getLengthBetweenPointsInUnit = (p1, p2, measure) => {
  const line = new paperScope.Path.Line(p1, p2)
  const length = $_formatPxToUnit(line.length, measure)
  line.remove()

  return length
}

const $_checkPointsCrossingsWithObject = (object, p1, p2, measure) => {
  const line = new paperScope.Path.Line(p1, p2)
  const intersections = object.getCrossings(line)

  const lengths = []
  const workingData = intersections.map(i => i.point)

  workingData.sort((a, b) => {
    return (a.angle > b.angle) ? 1 : -1
  })

  if (object.name !== config.objects.types.CIRCLE) {
    workingData.unshift(p1)
    workingData.push(p2)
  }
  for (let i = 0; i < workingData.length - 1; i++) {
    const testLine = new paperScope.Path.Line(workingData[i], workingData[i + 1])
    if (object.contains(testLine.bounds.center) && !$_objectOutlineHitTest(object.curves, testLine.bounds.center, testLine.bounds.center)) {
      const length = $_getLengthBetweenPointsInUnit(workingData[i], workingData[i + 1], measure)
      lengths.push((length < 0.5) ? 0 : length)
    }
    testLine.remove()
  }

  line.remove()
  return lengths
}

const $_objectOutlineHitTest = (curves, p1, p2) => {
  let hit = false
  curves.forEach((curve, index) => {
    const testPath = new paperScope.Path.Line(curve.point1, curve.point2)

    const nearestP1 = testPath.getNearestPoint(p1)
    const nearestP2 = testPath.getNearestPoint(p2)

    const distance1 = p1.getDistance(nearestP1)
    const distance2 = p2.getDistance(nearestP2)

    const isNearestP1 = (distance1 > -0.1) && (distance1 < 0.1)
    const isNearestP2 = (distance2 > -0.1) && (distance2 < 0.1)

    if ((testPath.contains(p1) && testPath.contains(p2)) || (isNearestP1 && isNearestP2)) {
      hit = true
    }

    // const nearestP1 = testPath.getNearestPoint(p1)
    // const nearestP2 = testPath.getNearestPoint(p2)
    //
    // const distance1 = p1.getDistance(nearestP1)
    // const distance2 = p2.getDistance(nearestP2)
    //
    // const isNearestP1 = (distance1 > -0.1) && (distance1 < 0.1)
    // const isNearestP2 = (distance2 > -0.1) && (distance2 < 0.1)
    //
    // if (isNearestP1 && isNearestP2) {
    //   hit = true
    // }

    testPath.remove()
  })

  return hit
}

const $_formatPxToUnit = (value, measure) => {
  return ((value / measure.fromPixels) * measure.toUnit)
}

export { get_needed_length }
