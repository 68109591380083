import config from '@/config'
import PaperLayer from '@/classes/paperLayer'

import _ from 'lodash'
import Paper from 'paper'
import axios from 'axios'

export default {
  name: 'algorithmServer',
  data: () => {
    return {
      rpc: {
        jsonrpc: '2.0',
        method: 'roll_out',
        id: 1,
        params: {
          to_roll: {
            plain: {
              points: [],
              children: []
            },
            roll: {},
            details: {},
            angles: []
          }
        }
      }
    }
  },
  methods: {
    /**
     * Calculate tracks by given object and properties
     * @param object
     * @param properties
     * @returns {Promise<void>}
     */
    async mx_algorithmServer_calculate (object, properties) {
      let jsonrpc = _.cloneDeep(this.rpc)
      jsonrpc = this.$_mx_algorithmServer_prepareObject(jsonrpc, object)

      jsonrpc = this.$_mx_algorithmServer_prepareProperties(jsonrpc, properties)

      jsonrpc.id = 1
      jsonrpc.params.to_roll.angles = properties.angles

      return await this.$_mx_algorithmServer_request(jsonrpc)
    },
    async $_mx_algorithmServer_request (jsonRPC) {
      return await axios({
        method: 'POST',
        url: config.algorithm.url,
        data: jsonRPC,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        return response.data.result
      }).catch(error => {
        console.log('jsonrpc error', error)
      })
    },
    /**
     * Returns modified jsonRPC, add object points
     * @param jsonRPC
     * @param object
     * @returns {*}
     */
    $_mx_algorithmServer_prepareObject (jsonRPC, object) {
      const pointsMapFunc = (points) => {
        return points.filter(point => point.type.toUpperCase() !== 'Z').map(point => {
          return { x: point.x, y: point.y, type: point.type, handles: point.handles }
        })
      }

      let points = object.getPoints()
      const children = []

      if (object.type === config.objects.types.CIRCLE) {
        points = this.$_mx_algorithmServer_convertCircle(object.getCircleProperties())
      }

      const mappedPoints = pointsMapFunc(points)

      if (object.hasChildren()) {
        object.children.forEach(child => {
          let points = child.getPoints()

          if (child.type === config.objects.types.CIRCLE) {
            points = this.$_mx_algorithmServer_convertCircle(object.getCircleProperties())
          }

          children.push({ points: pointsMapFunc(points) })
        })
      }

      jsonRPC.params.to_roll.plain.points = mappedPoints
      jsonRPC.params.to_roll.plain.children = children

      return jsonRPC
    },
    /**
     * Returns modified jsonRPC, add calculations properties
     * @param jsonRPC
     * @param properties
     * @returns {*}
     */
    $_mx_algorithmServer_prepareProperties (jsonRPC, properties) {
      jsonRPC.params.to_roll.roll = {
        width: properties.turfWidth,
        length: properties.turfLength
      }

      jsonRPC.params.to_roll.details = {
        impact_lr: properties.alignment,
        impact_ud: true,
        perfect: properties.perfectVisualResult,
        shift: properties.shift,
        threshold: properties.threshold
      }

      return jsonRPC
    },
    /**
     * Returns points from circle object
     * @param circleProperties
     * @returns {Array}
     */
    $_mx_algorithmServer_convertCircle (circleProperties) {
      const cp = new Paper.Path.Circle({
        center: [circleProperties.middle.x, circleProperties.middle.y],
        radius: circleProperties.radius
      })
      const points = PaperLayer.makePointsFromPaperSegments(cp.segments)
      cp.removeChildren()
      cp.remove()

      return points
    }
  }
}
