import { Point } from '@/classes/objects'
import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Tools - Default Object Pattern
 * @displayName Circle Object
 */
export default {
  name: 'ToolsCircleMixin',
  mounted () {
    this.$events.on(editorEventTypes.DRAW_DEFAULT_FINISH_CIRCLE, this.$_mx_tools_circle_finishCircle)
  },
  methods: {
    /**
         * Creates circle
         * @returns {null|VNode}
         * @public
         */
    mx_tools_circle_circle (keyboardInput) {
      this.pseudoPoints = []

      let circles = this.defaultObject.points.map((point, index, points) => {
        if (point.type !== 'M' && index <= points.length - 1) {
          this.pseudoPoints.push(point)
          return this.defaultCircle(point)
        }
      })

      circles = circles.filter(item => {
        if (item !== undefined) {
          return item
        }
      })

      let nextPoint = this.mouse
      if (circles.length > 0) {
        const lines = circles.map((circle, index) => {
          const circleData = circle.data.attrs
          if (keyboardInput > 0) {
            nextPoint = this.mx_math_getPointInDistance({
              x: circleData.cx,
              y: circleData.cy
            },
            this.mouse, keyboardInput, this.measure)
          }
          return this.defaultLine({
            x1: circleData.cx,
            y1: circleData.cy,
            x2: nextPoint.x,
            y2: nextPoint.y
          })
        })

        this.pseudoPoints.push(nextPoint)
        circles.push(this.defaultCircle(nextPoint))

        return this.$createElement('g', {
          class: {
            'c-object c-object__group': true
          }
        }, [this.defaultPattern(this.pseudoPoints, 'ellipse'), lines, circles])
      }

      return null
    },
    /**
         * Finish the circle
         * @private
         */
    $_mx_tools_circle_finishCircle () {
      this.defaultObject.points.push(new Point(0, 0, 'Z'))

      if (!this.cut) {
        this.finishPredefinedObject()
      } else {
        this.preparePredefinedObject()
      }
    }
  }
}
