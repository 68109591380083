import config from '@/config'

function unitsPlugin (Vue) {
  if (unitsPlugin.installed) return

  const unitsjs = new Vue({
    data: () => {
      return {
        key: ''
      }
    },
    methods: {
      init (value) {
        this.key = value
      },
      convert (value) {
        const _config = config.unit[this.key]
        return new Intl.NumberFormat(_config ?? 'en-EN', {}).format(value)
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$units', {
    get () {
      return unitsjs
    }
  })

  Vue.mixin({})
  Vue.units = Vue.prototype.$units
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(unitsPlugin)
}

export default unitsPlugin
