import { mapMutations, mapGetters, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

/**
 * @displayName Dialogs
 */
export default {
  name: 'DialogsMixin',
  computed: {
    ...mapState({
      mx_dialog_currentTab: state => state.dialogs.tab
    }),
    ...mapGetters({
      mx_dialogs_getDialogProps: 'dialogs/getDialogProps'
    })
  },
  methods: {
    ...mapMutations({
      /** @private **/
      $_mx_dialogs_toggleDialog: 'dialogs/' + mutationTypes.DIALOGS_TOGGLE,
      /** @private **/
      $_mx_dialogs_dialogSubscreen: 'dialogs/' + mutationTypes.DIALOGS_SUBSCREEN,
      /** @private **/
      $_mx_dialogs_dialogTab: 'dialogs/' + mutationTypes.DIALOGS_TAB,
      /** @private **/
      $_mx_dialogs_resetCurrentRefPoints: 'project/measurement/' + mutationTypes.RESET_REF_POINTS,
      /** @private **/
      $_mx_dialogs_resetStoredRefPointsAndToUnit: 'project/measurement/' + mutationTypes.RESET_STORED_REF_POINTS_AND_TO_UNIT
    }),
    /**
         * Close the dialog window
         * @public
         */
    mx_dialogs_closeDialog (override = null) {
      const targetDialog = (override !== null) ? override : this.targetDialog

      this.$_mx_dialogs_toggleDialog({
        dialog: targetDialog,
        onOff: false,
        dialogProps: null
      })
    },
    /**
         * Set a dialogs sub screen
         * @param val
         * @public
         */
    mx_dialogs_setSubscreen (val) {
      this.$_mx_dialogs_dialogSubscreen({
        dialog: this.targetDialog,
        val: val
      })
    },
    /**
         * Set a dialogs tab
         * @param val
         * @public
         */
    mx_dialogs_setTab (val) {
      this.$_mx_dialogs_dialogTab({
        val: val
      })
    },
    /**
         * Resets all reference points collected during measurement process
         * Current scale and unit settings stay untouched
         * @public
         */
    mx_dialogs_resetMeasurements () {
      this.$_mx_dialogs_resetCurrentRefPoints()
      this.$_mx_dialogs_resetStoredRefPointsAndToUnit()
    }
  }
}
