// import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, subProductRoutes, specialSubProductRoutes, routes } from '@/helper/apiHelper'

/**
 * Default state for: Sub-Product Handler Store
 *
 * @returns {{}}
 */
const defaultState = () => {
  return {}
}

const state = defaultState()

const getters = {}

const actions = {
  /**
   * Reset state
   *
   * @param dispatch
   * @param commit
   */
  resetState ({ dispatch, commit }) {
    commit(types.RESET_SUBPRODUCT_STATE)
  },
  /**
   * Get all sub-products for location
   * - Location Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async list ({ rootState, dispatch, commit }) {
    for (const [, product] of Object.entries(subProductRoutes)) {
      apiMethods.request('get', routes.location + `/${rootState.backendAPI.product.locationId}/${product.route}`, null, rootState.backendAPI.login.token)
        .then(response => {
          commit(`backendAPI/product/${product.module}/${product.mutation}`, response.data.data, { root: true })
          commit(`backendAPI/product/${product.module}/PRODUCT_${product.module.toUpperCase()}_SET_META`, response.data.meta, { root: true })
        }).catch(error => {
          dispatch('backendAPI/error', error, { root: true })
        })
    }
  },
  /**
   * Update a sub-product for location
   * - Location Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param data
   * @returns {Promise<void>}
   */
  async update ({ rootState, dispatch, commit }, data) {
    const routeData = subProductRoutes[data.type]
    return apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/${routeData.route}`, { update: data.update }, rootState.backendAPI.login.token)
      .then(response => {
        commit(`backendAPI/product/${routeData.module}/${routeData.mutation}`, response.data.data, { root: true })
        commit(`backendAPI/product/${routeData.module}/PRODUCT_${routeData.module.toUpperCase()}_SET_META`, response.data.meta, { root: true })
        return true
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  /**
   * Get all special sub-products for location
   * - Distributor Based
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @returns {Promise<void>}
   */
  async listSpecial ({ rootState, dispatch, commit }) {
    const role = rootState.backendAPI.user.me.role

    for (const [, product] of Object.entries(specialSubProductRoutes)) {
      if (product.allowed.includes(role)) {
        await apiMethods.request('get', product.route, null, rootState.backendAPI.login.token)
          .then(response => {
            commit(`backendAPI/product/${product.module}/${product.mutations.list}`, response.data.data, { root: true })
            commit(`backendAPI/product/${product.module}/PRODUCT_${product.module.toUpperCase()}_SET_META`, response.data.meta, { root: true })
          }).catch(error => {
            dispatch('backendAPI/error', error, { root: true })
          })
      }
    }
  },
  /**
   * Create special product
   *
   * @param dispatch
   * @param data
   * @returns {Promise<void>}
   */
  async createSpecialProduct ({ dispatch }, data) {
    const routeData = subProductRoutes[data.type]
    await dispatch(`backendAPI/product/${routeData.module}/createSpecial`, { data: data.create, fileCallback: data.fileCallback }, { root: true })
  },
  /**
   * Update special product
   *
   * @param dispatch
   * @param data
   * @returns {Promise<void>}
   */
  async updateSpecialProduct ({ dispatch }, data) {
    const routeData = subProductRoutes[data.type]
    await dispatch(`backendAPI/product/${routeData.module}/updateSpecial`, { data: data.update, fileCallback: data.fileCallback }, { root: true })
  },
  /**
   * Delete special product
   *
   * @param dispatch
   * @param data
   * @returns {Promise<void>}
   */
  async deleteSpecialProduct ({ dispatch }, data) {
    const routeData = subProductRoutes[data.type]
    await dispatch(`backendAPI/product/${routeData.module}/deleteSpecial`, data.delete, { root: true })
  },
  /**
   * Cancel
   *
   * @param dispatch
   * @param type
   */
  cancelSpecial ({ dispatch }, type) {
    const routeData = subProductRoutes[type]
    dispatch(`backendAPI/product/${routeData.module}/setSpecialToUpdate`, null, { root: true })
  }
}

const mutations = {
  [types.RESET_SUBPRODUCT_STATE] (state) {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
