<template>
  <dialog-frame
    :cannot-close="cannotClose"
    style-header-height="unset"
    :subtitle=" $t('dialogs.createAdhesive.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createAdhesive.title') "
    @abort-button-pressed="close"
  >
    <template #header>
      <product-translation-tabs
        @tab-changed="mx_dialogs_setTab"
      />
    </template>

    <template #content>
      <materials-form-helper
        v-slot="{formData, files}"
        :update="update"
        item-type="adhesive"
        @close="close"
      >
        <form
          v-if="formData"
          class="c-form"
        >
          <div
            v-show="mx_dialog_currentTab === 0"
            class="o-grid o-grid--center c-dialog__instruction--no-wrap"
          >
            <div class="c-dialog__instruction-col">
              <div class="u-flex u-fd-row">
                <file-upload
                  id="create-pedestal-logo"
                  :accept="'image/png, image/jpeg'"
                  :label-default-message="$t('dialogs.createAdhesive.imageFrame.dropPreview')"
                  :file-max-size="config.files.single.backend"
                  style-drop-area-height="180px"
                  style-drop-area-width="180px"
                  @change="(e) => {
                    mx_validate_updateErrorsArray(e)
                    files.file_image = e.value
                  }"
                  @register="mx_validate_registerInputsInErrorsArray"
                />
                <file-upload
                  id="create-pedestal-media-images"
                  :accept="'image/png, image/jpeg'"
                  :label-default-message="$t('dialogs.createAdhesive.imageFrame.dropImages')"
                  :file-max-size="config.files.single.backend"
                  :multiple="true"
                  classes="u-pl-size-20"
                  style-drop-area-height="180px"
                  style-drop-area-width="180px"
                  @change="(e) => {
                    mx_validate_updateErrorsArray(e)
                    files.file_media_images = e.value
                  }"
                  @register="mx_validate_registerInputsInErrorsArray"
                />
                <file-upload
                  id="create-pedestal-media-documents"
                  :accept="'application/pdf'"
                  :label-default-message="$t('dialogs.createAdhesive.imageFrame.dropDocuments')"
                  :file-max-size="config.files.single.backend"
                  :multiple="true"
                  classes="u-pl-size-20"
                  style-drop-area-height="180px"
                  style-drop-area-width="180px"
                  @change="(e) => {
                    mx_validate_updateErrorsArray(e)
                    files.file_media_documents = e.value
                  }"
                  @register="mx_validate_registerInputsInErrorsArray"
                />
              </div>
            </div>
            <div class="c-dialog__instruction-col u-text-right">
              <input-field
                id="create-adhesive-id"
                v-model="formData.pim_id._value"
                :focus-on-mount="true"
                :is-store-mode="false"
                :label="$t('dialogs.createAdhesive.pim_id')"
                :required="true"
                :validate="(v) => {return mx_validate_validateId(v)}"
                style-input-width="240px"
                :type="formData.pim_id._type"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-adhesive-name"
                v-model="formData.name._value"
                :is-store-mode="false"
                :label="$t('dialogs.createAdhesive.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                :type="formData.name._type"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <textarea-field
                id="create-adhesive-description"
                v-model="formData.description._value"
                :is-store-mode="false"
                :label="$t('dialogs.createAdhesive.description')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                classes="u-mb-size-30"
                style-textarea-width="240px"
                :type="formData.description._type"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-adhesive-size"
                v-model="formData.adhesive_size._value"
                :is-store-mode="false"
                :label="$t('dialogs.createAdhesive.size', { unit: $t(`backend.materials.units.${adhesiveSettings.adhesive_size.short}`) })"
                :required="true"
                :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
                classes="c-backend__double-field-value"
                style-input-width="240px"
                :type="formData.adhesive_size._type"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-adhesive-coverage"
                v-model="formData.adhesive_coverage._value"
                :is-store-mode="false"
                :label="$t('dialogs.createAdhesive.coverage', { unit: $t(`backend.materials.units.${adhesiveSettings.adhesive_coverage.short}`) })"
                :required="true"
                :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
                classes="c-backend__double-field-value"
                style-input-width="240px"
                :type="formData.adhesive_coverage._type"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </div>
          </div>
          <product-translation-fields
            v-for="item in languagesForTranslationFields"
            :key="`fields-${item[1]}`"
            :form-data="formData"
            id-prefix="create-adhesive"
            :lang="item[1]"
            i18nKey="dialogs.createAdhesive"
            :current-tab="mx_dialog_currentTab"
            :tab-key="item[0]"
            :is-new="true"
            @change-field="mx_validate_updateErrorsArray"
            @register-field="mx_validate_registerInputsInErrorsArray"
          />
        </form>
      </materials-form-helper>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-adhesive-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-adhesive-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            @click="update = true"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import config from '@/config'
import { mapGetters } from 'vuex'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import MaterialsFormHelper from '@/components/backend/materials/partials/MaterialsFormHelper'
import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'
import FileUpload from '@/components/common/FileUpload'
import TextareaField from '@/components/common/TextareaField'
import ProductTranslationTabs from '@/modules/translation/ProductTranslationTabs'
import ProductTranslationFields from '@/modules/translation/ProductTranslationFields'
import MixinLanguageFieldHelper from '@/modules/translation/services/languageFieldHelper'

/**
 * @displayName Dialog Materials Create Adhesive
 */
export default {
  name: 'CreateAdhesive',
  components: {
    TextareaField,
    FileUpload,
    ButtonBase,
    MaterialsFormHelper,
    DialogFrame,
    InputField,
    ProductTranslationTabs,
    ProductTranslationFields
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinLanguageFieldHelper
  ],
  data: () => {
    return {
      config: config,
      targetDialog: 'createAdhesive',
      cannotClose: false,
      update: false
    }
  },
  computed: {
    ...mapGetters({
      getSettings: 'backendAPI/group/getSettings'
    }),
    adhesiveSettings () {
      return this.getSettings.units['App\\Adhesive']
    }
  },
  methods: {
    close () {
      this.update = false
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
