import { mapMutations, mapState, mapGetters, mapActions } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import paper from 'paper'

import PaperLayer from '@/classes/paperLayer'

import MixinTrackState from '@/mixins/trackstate'

/**
 * Boolean operations
 * - Subtract: remove object1 from object2 -> object2
 * - Exclude: remove object1 from object2 -> object1, modified object2
 * - Fragment: object1 on object2 -> multiple objects
 * - Unite: object1 on object2 -> new object
 * @displayName SVG boolean operations
 */
export default {
  name: 'OperationsMixin',
  mixins: [MixinTrackState],
  data: () => {
    return {
      $_mx_operations_paper: null,
      $_mx_operations_cut_object_mode: false
    }
  },
  created () {
    this.$data.$_mx_operations_paper = new paper.PaperScope().setup(1, 1)
  },
  computed: {
    ...mapGetters({
      mx_operations_selectedObjectsCount: 'events/selectedObjectsCount',
      /** @private **/
      $_mx_operations_getObject: 'project/objects/getById'
    }),
    ...mapState({
      /** @private **/
      $_mx_operations_selectedObjects: state => state.events.currentSelectedObjects
    })
  },
  methods: {
    ...mapMutations({
      /** @private **/
      $_mx_operations_setEventMode: 'events/' + mutationTypes.EVT_UPDATE_EDITOR_MODE
    }),
    ...mapActions({
      /** @private **/
      $_mx_operations_removeObject: 'project/removeObject',
      /** @private **/
      $_mx_operations_operationActions: 'project/operationActions',
      /** @private **/
      $_mx_operations_removeObjectCut: 'project/removeObjectCut',
      /** @private **/
      $_mx_operations_clearObjectSelection: 'events/clearObjectSelection'
    }),
    /**
     * Entry point for a logical operation e.g. "subtract" or "unite"
     * @requires @/mixins/trackstate
     * @param {string} operation Options: subtract, exclude, unite, fragment
     * @public
     */
    mx_operations_booleanOperation (operation) {
      if (this.$_mx_operations_selectedObjects.length === 2) {
        this.mx_trackstate_trackState(() => {
          this.$_mx_operations_cut_object_mode = false
          let a = this.$_mx_operations_getObject(this.$_mx_operations_selectedObjects[0])
          let b = this.$_mx_operations_getObject(this.$_mx_operations_selectedObjects[1])
          const sortedObjects = this.mx_operations_sortByOrder(a, b)

          a = sortedObjects.first
          b = sortedObjects.second

          switch (operation) {
            case 'subtract':
              this.$_mx_operations_subtract(a, b)
              break
            case 'exclude':
              this.$_mx_operations_exclude(a, b)
              break
            case 'unite':
              this.$_mx_operations_unite(a, b)
              break
            case 'fragment':
              this.$_mx_operations_fragment(a, b)
              break
          }
        })
      }
    },
    /**
     * Entry point for logical operations on predefined objects
     * @param object
     * @public
     */
    mx_operations_predefinedBooleanOperation (object) {
      if (this.$_mx_operations_selectedObjects.length >= 1 && this.$_mx_operations_selectedObjects.length <= 2) {
        this.$_mx_operations_cut_object_mode = true
        const a = object
        const b = this.$_mx_operations_getObject(this.$_mx_operations_selectedObjects[0])
        this.$_mx_operations_subtract(a, b)
      }
    },
    /**
     * The result operation after logical operation
     * @param obj
     * @param result
     * @private
     */
    $_mx_operations_resultOperation (obj, result) {
      let usedObjectId = false
      if (result.points && !result.inside) {
        this.$_mx_operations_operationActions({
          id: obj.id,
          points: result.points,
          action: 'override-object-points',
          cutObjectMode: this.$_mx_operations_cut_object_mode
        })
      } else {
        for (const key in result) {
          const outcome = result[key]

          const clockwise_object = outcome.filter(obj => !obj.inside)
          const counterclockwise_objects = outcome.filter(obj => obj.inside)

          const object_properties = clockwise_object.shift()
          if (!usedObjectId) {
            if (counterclockwise_objects.length > 0) {
              this.$_mx_operations_operationActions({
                id: obj.id,
                points: object_properties.points,
                children: counterclockwise_objects,
                action: 'override-object-add-children',
                cutObjectMode: this.$_mx_operations_cut_object_mode
              })
            } else {
              this.$_mx_operations_operationActions({
                id: obj.id,
                points: object_properties.points,
                action: 'override-object-points',
                cutObjectMode: this.$_mx_operations_cut_object_mode
              })
            }
            usedObjectId = true
          } else {
            this.$_mx_operations_operationActions({
              points: object_properties.points,
              children: counterclockwise_objects,
              action: 'create-new-object-from-points',
              cutObjectMode: this.$_mx_operations_cut_object_mode
            })
          }
        }
      }

      this.$_mx_operations_clearObjectSelection()
    },
    /**
     * Subtract two objects
     * @param a
     * @param b
     * @private
     */
    $_mx_operations_subtract (a, b) {
      const res = PaperLayer.subtractObjects(a, b)
      if (!a.isCutOut) {
        this.$_mx_operations_removeObject([a.id])
      } else {
        this.$_mx_operations_removeObjectCut()
        this.$_mx_operations_setEventMode({ cut: false })
      }

      if (res === null) {
        this.$_mx_operations_removeObject([b.id])
        this.$_mx_operations_clearObjectSelection()
        return
      }

      this.$_mx_operations_resultOperation(b, res)
    },
    /**
     * Exclude two objects
     * @param a
     * @param b
     * @private
     */
    $_mx_operations_exclude (a, b) {
      const res = PaperLayer.subtractObjects(a, b)
      if (res === null) {
        this.$_mx_operations_removeObject([b.id])
        this.$_mx_operations_clearObjectSelection()
        return
      }
      this.$_mx_operations_resultOperation(b, res)
    },
    /**
     * Unite two objects
     * @param a
     * @param b
     * @private
     */
    $_mx_operations_unite (a, b) {
      const res = PaperLayer.uniteObjects(a, b)

      if (res !== null) {
        this.$_mx_operations_removeObject([a.id])
        this.$_mx_operations_resultOperation(b, res)
        this.$_mx_operations_clearObjectSelection()
      }
    },
    /**
     * Make fragment from two objects
     * @param a
     * @param b
     * @private
     */
    $_mx_operations_fragment (a, b) {
      const res = PaperLayer.divideObjects(a, b)

      if (res !== null) {
        if (res.a !== null && Object.keys(res.a).length > 0) {
          this.$_mx_operations_resultOperation(a, res.a)
        } else {
          this.$_mx_operations_removeObject([a.id])
        }

        if (res.b !== null && Object.keys(res.b).length > 0) {
          this.$_mx_operations_resultOperation(b, res.b)
        } else {
          this.$_mx_operations_removeObject([b.id])
        }

        for (const key in res.c) {
          const outcome = res.c[key]
          let clockwise_object = null
          let counterclockwise_objects = null

          if (Object.prototype.hasOwnProperty.call(outcome, 'inside')) {
            clockwise_object = outcome
          } else {
            const keys = Object.keys(outcome)
            if (keys.length === 1) {
              const newOutcome = outcome[keys[0]]
              clockwise_object = newOutcome.filter(obj => !obj.inside)
              clockwise_object = clockwise_object.shift()
              counterclockwise_objects = newOutcome.filter(obj => obj.inside)
            }
          }

          if (clockwise_object !== null) {
            this.$_mx_operations_operationActions({
              points: clockwise_object.points,
              children: counterclockwise_objects,
              action: 'create-new-object-from-points',
              cutObjectMode: this.$_mx_operations_cut_object_mode
            })
          }
        }
      }

      this.$_mx_operations_clearObjectSelection()
    },

    /* ------------------------------------------
     *  Helpers
     *------------------------------------------ */

    /**
     * Order objects by their orderBy layer property
     * @param obj1
     * @param obj2
     * @returns {{first: *, second: *}}
     * @public
     */
    mx_operations_sortByOrder (obj1, obj2) {
      const sortedTable = [obj1, obj2]

      sortedTable.sort((a, b) => {
        return b.orderBy.layerOrder - a.orderBy.layerOrder
      })
      return { first: sortedTable[0], second: sortedTable[1] }
    }
  }
}
