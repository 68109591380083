import * as types from '@/vuex/mutation-types'
import { Path, PathChild, Point } from '@/classes/objects'
import { Turf } from '@/classes/products'
import * as funcObj from '../../../vuex/modules/helper/func_objects'
import { updatePointMove } from '@/vuex/modules/helper/func_objects'

import MixinMath from '@/mixins/math'
import MixinPoints from '@/mixins/points'

import config from '@/config'
import { withHistoryMutation } from '../../history-helper'
import Vue from 'vue'
import svgjsPlugin from '@/plugin/svgjs'
import PaperLayer from '@/classes/paperLayer'
import _ from 'lodash'

if (!Vue.svg) {
  Vue.use(svgjsPlugin)
}

const findObject = (state, id) => {
  if (id === 'object-predefined') {
    return state.cutOut
  }
  return state.objects.find(obj => {
    if (obj.id === id) {
      return obj
    }
  })
}

const updatePointIDs = (object, points) => {
  points.map((point, index, points) => {
    const _id = object.lastPointId + 1
    point.setID(`${object.id.replace(config.objects.ID, '')}-${_id}`)
    object.lastPointId = _id
  })

  return points
}

const updateDuplicatePoints = (object, points) => {
  const duplicatedPoints = []
  points.forEach((point, index) => {
    const _id = object.lastPointId + 1
    const _point = new Point(funcObj.roundDecimal(point.x + config.objects.DUPLICATE_OFFSET), funcObj.roundDecimal(point.y + config.objects.DUPLICATE_OFFSET),
      point.type)

    _point.setID(`${object.id.replace(config.objects.ID, '')}-${_id}`)

    switch (_point.type) {
      case 'C':
        _point.handles = {
          x1: funcObj.roundDecimal(point.handles.x1 + config.objects.DUPLICATE_OFFSET),
          y1: funcObj.roundDecimal(point.handles.y1 + config.objects.DUPLICATE_OFFSET),
          x2: funcObj.roundDecimal(point.handles.x2 + config.objects.DUPLICATE_OFFSET),
          y2: funcObj.roundDecimal(point.handles.y2 + config.objects.DUPLICATE_OFFSET)
        }

        break
      case 'Q':
        _point.handles = {
          x1: funcObj.roundDecimal(point.handles.x1 + config.objects.DUPLICATE_OFFSET),
          y1: funcObj.roundDecimal(point.handles.y1 + config.objects.DUPLICATE_OFFSET),
          x2: undefined,
          y2: undefined
        }
        break
    }

    duplicatedPoints.push(_point)
    object.lastPointId = _id
  })

  return duplicatedPoints
}

const updateMovePoint = (points) => {
  const lastPoint = points[points.length - 2]
  points[0].x = lastPoint.x
  points[0].y = lastPoint.y

  return points
}

const replaceObject = (state, object, id) => {
  if (object.isCutOut) {
    Vue.set(state, 'cutOut', object)
  } else {
    const index = state.objects.findIndex(obj => {
      return obj.id === id
    })
    Vue.set(state.objects, index, object)
  }
}

const removeObject = (state, id) => {
  const index = state.objects.findIndex((obj) => {
    return (obj.id === id)
  })
  state.objects.splice(index, 1)
}

const findChildByPoint = (obj, pointID) => {
  return obj.children.find(child => {
    return child.points.find(point => {
      return (point.id === pointID)
    })
  })
}

const objectsToPoly = (obj) => {
  const polyObject = getPolygon(obj)
  const polyChildren = []

  if (obj.hasChildren()) {
    obj.children.forEach(child => {
      const polyChild = getPolygon(child)
      polyChildren.push(MixinPoints.methods.mx_points_convertToPolyPoints(polyChild))
    })
  }

  return {
    obj: MixinPoints.methods.mx_points_convertToPolyPoints(polyObject),
    children: (polyChildren.length > 0) ? polyChildren : null
  }
}

const getPolygon = (obj) => {
  switch (obj.type) {
    case config.objects.types.PATH:
      return Vue.svg.PathToPoly(obj.d(true))
    case config.objects.types.CIRCLE:
      return Vue.svg.PathToPoly(obj.getCircleD())
    default:
      return Vue.svg.PathToPoly(obj.d(true))
  }
}

const updateObjectOrder = (state, payload) => {
  const object = findObject(state, payload.obj)
  object.orderBy = {
    layer: payload.layer,
    layerOrder: payload.order,
    position: payload.position
  }
}

/* const removePoint = (object, index) => {
  object.points.splice(index, 1)
  const newPoints = object.points

  if (index === object.getPoints().length - 1) {
    newPoints[0].x = funcObj.roundDecimal(newPoints[index - 1].x, 2)
    newPoints[0].y = funcObj.roundDecimal(newPoints[index - 1].y, 2)
  }
  return newPoints
} */

const makeDuplicateFromObject = (state, payload) => {
  const _id = (payload.idToDuplicate instanceof Array) ? payload.idToDuplicate[payload.idToDuplicate.length - 1] : payload.idToDuplicate
  const clone = findObject(state, _id)
  const object = new Path(payload.id)

  object.type = clone.type
  object.product = _.cloneDeep(clone.product)

  object.points = updateDuplicatePoints(object, clone.points)
  object.productNeedUpdate = false
  if (clone.hasChildren()) {
    clone.children.forEach(child => {
      const _child = new PathChild(`${object.id}-${object.lastChildId}`, [])

      _child.points = updateDuplicatePoints(_child, child.points)

      object.children.push(_child)
    })
  }

  if (object.product instanceof Turf) {
    if (object.product.tracks !== null && object.product.tracks.length > 0) {
      object.product.tracks.forEach(track => {
        track.points.map((point) => {
          updatePointMove(point, { x: config.objects.DUPLICATE_OFFSET, y: config.objects.DUPLICATE_OFFSET })
        })
      })
    }
  }

  return object
}

const state = {
  objects: [],
  cutOut: null
}

const getters = {
  getAll (state) {
    const objects = Object.assign([], state.objects)
    return objects.sort((a, b) => a.orderBy.layerOrder - b.orderBy.layerOrder)
  },
  getById: (state) => (key) => {
    return findObject(state, key)
  },
  getCutObject: (state) => {
    return state.cutOut
  },
  /**
     * Returns point from given object or -1 if not found
     * @param state
     * @returns {function( object, circle ): *} pointIndex
     */
  getPointIndex: (state) => (object, circle) => {
    return funcObj.getPointIndex(object.points, circle)
  },
  getPointFromObject: (state) => (objID, pointID, isCutOut) => {
    const obj = (!isCutOut) ? findObject(state, objID) : state.cutOut
    return funcObj.findPoint(obj.points, pointID)
  },
  getPointFromChild: (state) => (objID, pointID, isCutOut) => {
    const obj = (!isCutOut) ? findObject(state, objID) : state.cutOut
    const child = findChildByPoint(obj, pointID)

    return funcObj.findPoint(child.points, pointID)
  },
  getChildByPoint: (state) => (objID, pointID, isCutOut) => {
    const obj = (!isCutOut) ? findObject(state, objID) : state.cutOut

    return findChildByPoint(obj, pointID)
  },
  hasProduct: (state) => (key) => {
    key = Array.isArray(key) ? key[0] : key
    const object = findObject(state, key)

    return (object && object.product !== null)
  },
  objectsWithProducts: (state) => {
    return state.objects.filter(object => object.product !== null)
  }
}

const actions = {
  addObjectToProject ({ dispatch, commit }, object) {
    let points = object.points.filter((item, index) => item.type !== 'M' && item.type !== 'Z')
    const isClockwise = funcObj.isClockwise(points)

    points = (!isClockwise) ? funcObj.sortClockwise(points) : object.points

    if (!isClockwise) {
      points.unshift(object.points[0])
      points.push(object.points[object.points.length - 1])
    }

    commit(types.ADD_OBJECT, { object: object, points: points })
    dispatch('project/layers/addNewObjectToLayer', object.id, { root: true })
    dispatch('project/objects/updateObjectArea', { id: object.id }, { root: true })
  },
  addObjectToCutOut ({ commit }, object) {
    commit(types.UPDATE_OBJECT_CUT, object)
  },
  updateObjectsByTranslate ({ dispatch, commit }, props) {
    const data = JSON.parse(JSON.stringify(props))
    commit(types.UPDATE_OBJECTS_BY_TRANSLATE, data)
    dispatch('project/resetTranslate', null, { root: true })
  },
  updateObjectsByScale ({ state, dispatch, commit }, props) {
    props.elements.forEach(el => {
      commit(types.SCALE_OBJECT, {
        id: el.id,
        scale: props.scale,
        bounds: el.bounds,
        origBbox: props.origBbox,
        direction: props.direction,
        cut: props.cut
      })
      if (!props.cut) {
        dispatch('project/objects/updateObjectArea', { id: el.id }, { root: true })
        commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, el.id)
      }
    })
  },
  updateObjectsByRotation ({ state, commit }, data) {
    data.ids.forEach(id => {
      const obj = (!data.cut) ? findObject(state, id) : state.cutOut
      if (obj) {
        const result = PaperLayer.rotateObject(obj, data.props)
        result.object = obj
        result.rotation = data.props.deg

        if (obj.type !== config.objects.types.CIRCLE) {
          commit(types.ROTATE_OBJECT_DEFAULT, result)
        } else {
          commit(types.ROTATE_OBJECT_CIRCLE, result)
        }
      }
    })
  },
  updateObjectBySnap ({ commit }, props) {
    commit(types.UPDATE_OBJECT_POINTS_BY_SNAP, props)
    // commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.object)
  },
  updateObjectPoint ({ commit, dispatch }, props) {
    commit(types.UPDATE_OBJECT_POINT, props)
    dispatch('project/objects/updateObjectArea', { id: props.object }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.object)
  },
  updateObjectHandle ({ commit, dispatch }, props) {
    commit(types.UPDATE_OBJECT_HANDLE, props)
    dispatch('project/objects/updateObjectArea', { id: props.object }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.object)
  },
  transformPointTo ({ commit, dispatch }, props) {
    switch (props.type) {
      case 'L':
      case 'l':
        commit(types.TRANSFORM_OBJECT_POINT_TO_LINE, props)
        break
      case 'C':
      case 'c':
        commit(types.TRANSFORM_OBJECT_POINT_TO_CUBIC, props)
        break
      case 'Q':
      case 'q':
        commit(types.TRANSFORM_OBJECT_POINT_TO_QUADRATIC, props)
        break
    }
    dispatch('project/objects/updateObjectArea', { id: props.object }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.object)
  },
  resetHandleForPoint ({ commit, dispatch }, props) {
    commit(types.RESET_OBJECT_POINT_HANDLE, props)
    dispatch('project/objects/updateObjectArea', { id: props.object }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.object)
  },
  overrideObjectPoints ({ commit, dispatch }, props) {
    commit(types.OVERRIDE_ALL_OBJECT_POINTS, props)
    dispatch('project/objects/updateObjectArea', { id: props.id }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.id)
  },
  createObjectFromPoints ({ commit, dispatch }, props) {
    commit(types.CREATE_NEW_OBJECT_FROM_POINTS, props)
    dispatch('project/objects/updateObjectArea', { id: props.id }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.id)
  },
  overrideObjectPointsAddChildren ({ commit, dispatch }, props) {
    commit(types.OVERRIDE_ALL_OBJECT_POINTS, props)
    dispatch('project/objects/updateObjectArea', { id: props.id }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.id)
  },
  remove ({ dispatch, commit }, objIDs) {
    commit(types.REMOVE_OBJECTS, objIDs)
    dispatch('removeWasteConnections', objIDs)
  },
  removeWasteConnections ({ commit }, ids) {
    commit(types.OBJECT_CHECK_WASTE_CONNECTIONS_AFTER_DELETE_OBJECT, ids)
  },
  removeValidatedObjectPoints ({ commit, dispatch }, props) {
    commit(types.REMOVE_VALIDATED_OBJECT_POINTS, props)
    dispatch('events/resetCurrentActiveModifier', {}, { root: true })
    dispatch('project/objects/updateObjectArea', { id: props.objectId }, { root: true })
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, props.objectId)
  },
  removeCut ({ commit }) {
    commit(types.REMOVE_OBJECT_CUT)
  },
  duplicate ({ state, dispatch, commit }, payload) {
    const object = makeDuplicateFromObject(state, payload)
    commit(types.DUPLICATE_OBJECT, object)
    dispatch('project/layers/addNewObjectToLayer', payload.id, { root: true })
    dispatch('project/objects/updateObjectArea', { id: payload.id }, { root: true })
  },
  initLayer ({ commit }, payload) {
    commit(types.INIT_OBJECT_LAYER, payload)
  },
  updateObjectOrder ({ commit }, payload) {
    commit(types.UPDATE_OBJECT_ORDER, payload)
  },
  setSelectionMode ({ rootState, state, commit }, payload) {
    let cutOut = false
    if (payload.isCutObject !== undefined) {
      cutOut = payload.isCutObject
    }

    if (!(payload.object instanceof Path)) {
      payload.object = findObject(state, payload.object)
    }

    (rootState.events.currentSelectedObjects.length > 1 && !cutOut) ? commit(types.SET_SELECTION_MODE, {
      object: payload.object,
      selectionMode: config.objects.modes.SELECTED
    }) : commit(types.SET_SELECTION_MODE, payload)
  },
  updateObjectArea ({ rootState, state, commit, dispatch }, payload) {
    const id = (typeof payload.id === 'string') ? payload.id : config.objects.ID + payload.id
    const object = findObject(state, id)

    if (object !== undefined) {
      const _omPoly = objectsToPoly(object)
      let objectArea = MixinMath.methods.mx_math_calculateArea(_omPoly.obj)

      if (_omPoly !== null) {
        if (_omPoly.children !== null) {
          let childrenArea = 0.0
          _omPoly.children.forEach(child => {
            childrenArea += MixinMath.methods.mx_math_calculateArea(child)
          })
          objectArea -= childrenArea
        }

        const fv = Math.pow((Math.sqrt(objectArea) /
                    MixinMath.methods.mx_math_replaceDecimalPointWithDot(rootState.project.measurement.measure.fromPixels)) *
                    MixinMath.methods.mx_math_replaceDecimalPointWithDot(rootState.project.measurement.measure.toUnit), 2)
        commit(types.SET_OBJECT_AREA, { object: object, area: fv })
      }
    }
  },
  updateAllObjectsAreas ({ state, dispatch }) {
    for (const object of state.objects) {
      dispatch('project/objects/updateObjectArea', object, { root: true })
      dispatch('project/objects/productNeedUpdate', object.id, { root: true })
    }
  },
  addProductToObject ({ rootState, state, dispatch, commit }, payload) {
    commit(types.ADD_PRODUCT_TO_OBJECT, payload)
    if (!rootState.dialogs.showedOnce.firstProductAdded) {
      const selectedObject = findObject(state, payload.object)
      const selectedProductID = selectedObject.product.id
      dispatch('events/addObjectToSelection', {
        addToSelection: false,
        object: payload.object,
        product: selectedProductID
      }, { root: true })
      commit(types.SET_SELECTION_MODE, { object: selectedObject, selectionMode: config.objects.modes.SELECTED })
      dispatch('dialogs/dialogToggle', { dialog: 'firstProductAdded', onOff: true }, { root: true })
    }
  },
  removeProductFromObject ({ dispatch, commit }, payload) {
    commit(types.REMOVE_PRODUCT_FROM_OBJECT, payload)
    dispatch('removeWasteConnections', payload)
  },
  removeProductFromSelectedObjects ({ state, dispatch, commit }, payload) {
    if (payload.objects) {
      const objects = state.objects.filter(object => {
        if (payload.objects.includes(object.id)) {
          if ((object.product && object.product.id === payload.product) || (object.product && payload.product === null)) {
            dispatch('events/removeProductSelection', {
              product: object.product.id
            }, { root: true })
            return object
          }
        }
      })

      commit(types.REMOVE_PRODUCT_FROM_SELECTED_OBJECTS, objects)
      dispatch('removeWasteConnections', objects.map(obj => { return obj.id }))
    }
  },
  addLawnCalculationForObject ({ state, commit }, payload) {
    const calculation = {
      object: payload.calculation.object.pathData,
      turf_tracks: [],
      turf_data: payload.calculation.turf_data
    }

    payload.calculation.turf_tracks.children.forEach((track) => {
      calculation.turf_tracks.push({
        path: track.pathData,
        name: track.name
      })
    })

    commit(types.ADD_LAWN_CALCULATION_TO_OBJECT, {
      id: payload.id,
      calculation: JSON.parse(JSON.stringify(calculation))
    })
  },
  clearSelectionMode ({ state, commit }, payload) {
    const objects = state.objects.filter(object => object.selectionMode !== config.objects.modes.SELECTED)

    commit(types.CLEAR_OBJECT_SELECTION_MODE, { objects: objects, mode: payload })
  },
  productNeedUpdate ({ state, commit }, id) {
    commit(types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED, id)
  },
  addNearestPointToObject ({ state, commit }, payload) {
    let obj = (!payload.isCutOut) ? findObject(state, payload.objectId) : state.cutOut
    if (obj) {
      const childObj = findChildByPoint(obj, payload.nearest)
      obj = (childObj !== undefined) ? childObj : obj

      commit(types.ADD_NEW_OBJECT_POINT, {
        object: obj,
        point: payload.point,
        nearest: payload.nearest
      })
    }
  },
  updateObjectByLineOfSight ({ state, commit }, data) {
    commit(types.OBJECT_UPDATE_PRODUCT, data)
  },
  removeSourceTrackFromTrackByLineOfSight ({ commit }, data) {
    commit(types.OBJECT_TRACK_REMOVE_SOURCE_TARGET_LOS, data)
  },
  updateObjectProductProperties ({ state, commit }, data) {
    commit(types.OBJECT_UPDATE_PRODUCT_PROPERTIES, data)
  }
}

const mutations = withHistoryMutation({
  [types.ADD_OBJECT] (state, props) {
    props.points = updateMovePoint(props.points)
    props.object.points = props.points
    state.objects.push(props.object)
  },
  [types.ADD_NEW_OBJECT_POINT] (state, data) {
    const object = data.object
    const point = data.point
    const _id = object.lastPointId + 1

    object.lastPointId = _id
    point.setID(`${object.id.replace(config.objects.ID, '')}-${_id}`)
    object.addPointAfter(point, data.nearest)
  },
  [types.DUPLICATE_OBJECT] (state, object) {
    state.objects.push(object)
  },
  [types.SCALE_OBJECT] (state, props) {
    const obj = (!props.cut) ? findObject(state, props.id) : state.cutOut
    if (obj) {
      obj.scale(props.scale, props.bounds, props.origBbox, props.direction)
    }
  },
  [types.ROTATE_OBJECT_DEFAULT] (state, result) {
    const object = result.object

    object.lastPointId = 0
    object.points = updateMovePoint(updatePointIDs(object, result.points))
    object.lastChildId = 0
    object.children = []

    if (object.product && typeof object.product.tracks !== 'undefined') {
      const deg = object.product.angle + result.rotation
      object.product.angle = (deg <= 360) ? deg : (deg - 360)
    }

    object.productNeedUpdate = true

    if (result.children && result.children.length > 0) {
      object.children = result.children.map((child) => {
        const childPath = new PathChild(`${object.id}-${object.lastChildId}`, [])
        const _points = updatePointIDs(childPath, child.points)

        let points = _points.filter((item, index) => item.type !== 'M' && item.type !== 'Z')
        const isClockwise = funcObj.isClockwise(_points)

        points = (isClockwise) ? funcObj.sortClockwise(points) : _points

        if (isClockwise) {
          points.unshift(child.points[0])
          points.push(child.points[child.points.length - 1])
        }

        childPath.points = points
        object.lastChildId += 1

        return childPath
      })
    }
  },
  [types.ROTATE_OBJECT_CIRCLE] (state, result) {
    const object = result.object

    object.points[1].x = result.centerX
    object.points[1].y = result.centerY
    object.points[0].x = result.radius.x
    object.points[0].y = result.radius.y
    object.points[2].x = result.radius.x
    object.points[2].y = result.radius.y

    object.productNeedUpdate = true
  },
  [types.RESET_OBJECT_POINT_HANDLE] (state, payload) {
    let object = (payload.isCutOut) ? state.cutOut : findObject(state, payload.object)
    let point = funcObj.findPoint(object.points, payload.circle)

    if (point === undefined) {
      object = findChildByPoint(object, payload.circle)
      point = funcObj.findPoint(object.points, payload.circle)
    }

    if (point !== undefined) {
      const nextPoint = funcObj.getNextPoint(object.points, payload.circle)

      switch (point.type) {
        case 'C':
        case 'c': {
          const cubic = funcObj.cubicHandles(point, nextPoint)

          point.handles = {
            x2: funcObj.roundDecimal(cubic.x2, 2),
            y2: funcObj.roundDecimal(cubic.y2, 2),
            x1: funcObj.roundDecimal(cubic.x1, 2),
            y1: funcObj.roundDecimal(cubic.y1, 2)
          }
          break
        }
        case 'Q':
        case 'q': {
          const quadratic = funcObj.quadraticHandles(point, nextPoint)

          point.handles = {
            x1: funcObj.roundDecimal(quadratic.x1, 2),
            y1: funcObj.roundDecimal(quadratic.y1, 2),
            x2: undefined,
            y2: undefined
          }
          break
        }
      }
    }
  },
  [types.OVERRIDE_ALL_OBJECT_POINTS] (state, props) {
    const object = findObject(state, props.id)
    object.lastPointId = 0
    object.points = updateMovePoint(updatePointIDs(object, props.points))
    object.lastChildId = 0
    object.children = []

    if (!props.cutObjectMode) {
      object.product = null
    }

    if (object.type === config.objects.types.CIRCLE) {
      object.type = config.objects.types.PATH
    }

    if (props.children && props.children.length > 0) {
      object.children = props.children.map((child) => {
        const childPath = new PathChild(`${object.id}-${object.lastChildId}`, [])
        const _points = updatePointIDs(childPath, child.points)

        let points = _points.filter((item, index) => item.type !== 'M' && item.type !== 'Z')
        const isClockwise = funcObj.isClockwise(_points)

        points = (isClockwise) ? funcObj.sortClockwise(points) : _points

        if (isClockwise) {
          points.unshift(child.points[0])
          points.push(child.points[child.points.length - 1])
        }

        childPath.points = points
        object.lastChildId += 1

        return childPath
      })
    }
  },
  [types.CREATE_NEW_OBJECT_FROM_POINTS] (state, props) {
    const object = new Path(props.id)

    object.points = updateMovePoint(updatePointIDs(object, props.points))

    if (props.children && props.children.length > 0) {
      object.children = props.children.map((child) => {
        const childPath = new PathChild(`${object.id}-${object.lastChildId}`, [])
        const _points = updatePointIDs(childPath, child.points)

        let points = _points.filter((item, index) => item.type !== 'M' && item.type !== 'Z')
        const isClockwise = funcObj.isClockwise(_points)

        points = (isClockwise) ? funcObj.sortClockwise(points) : _points

        if (isClockwise) {
          points.unshift(child.points[0])
          points.push(child.points[child.points.length - 1])
        }

        childPath.points = points
        object.lastChildId += 1

        return childPath
      })
    }

    state.objects.push(object)
  },
  [types.REMOVE_OBJECTS] (state, ids) {
    if (ids instanceof Array) {
      ids.forEach(item => {
        removeObject(state, item)
      })
    } else {
      removeObject(state, ids)
    }
  },
  [types.OBJECT_CHECK_WASTE_CONNECTIONS_AFTER_DELETE_OBJECT] (state, ids) {
    const checkIds = (ids instanceof Array) ? ids : [ids]
    const objects = state.objects.filter(object => object.product && object.product.tracks)

    objects.forEach(obj => {
      obj.product.tracks.map(track => {
        if (track.usedWaste && track.wasteData) {
          if (checkIds.includes(track.wasteData.object)) {
            track.usedWaste = false
            track.wasteData = null
            return track
          }
        }
        if (track.sourceTargetFor.length > 0) {
          const remove = []
          track.sourceTargetFor.forEach((target, index) => {
            if (checkIds.includes(target.object)) {
              remove.push(index)
            }
          })
          for (let i = remove.length - 1; i >= 0; i--) {
            track.sourceTargetFor.splice(remove[i], 1)
          }
        }
      })
    })
    // checkIds.forEach(id => {
    //
    // })
  },
  [types.REMOVE_VALIDATED_OBJECT_POINTS] (state, payload) {
    replaceObject(state, payload.newObject, payload.objectId)
  },
  [types.UPDATE_OBJECTS_BY_TRANSLATE] (state, props) {
    const objectIDs = props.objectIDs
    if (objectIDs.includes(config.OBJECT_CUT_ID) && state.cutOut !== null) {
      funcObj.updateObjectMove(state.cutOut, props.translate)
    } else if (!objectIDs.includes(config.OBJECT_CUT_ID)) {
      for (const objIndex in objectIDs) {
        const obj = (objectIDs[objIndex] !== config.OBJECT_CUT_ID) ? findObject(state, objectIDs[objIndex]) : state.cutOut
        funcObj.updateObjectMove(obj, props.translate)
      }
    }
  },
  [types.UPDATE_OBJECT_POINT] (state, props) {
    let object = (!props.isCutOut) ? findObject(state, props.object) : state.cutOut
    let point = funcObj.findPoint(object.points, props.pid)

    if (point === undefined) {
      object = findChildByPoint(object, props.pid)
      point = funcObj.findPoint(object.points, props.pid)
    }

    point.x = funcObj.roundDecimal(props.nPoint.x, 2)
    point.y = funcObj.roundDecimal(props.nPoint.y, 2)

    const key = funcObj.getPointIndex(object.points, point.id)

    if (key === object.getPoints().length - 2) {
      object.points[0].x = funcObj.roundDecimal(props.nPoint.x, 2)
      object.points[0].y = funcObj.roundDecimal(props.nPoint.y, 2)
    }
  },
  [types.UPDATE_OBJECT_HANDLE] (state, props) {
    let object = (!props.isCutOut) ? findObject(state, props.object) : state.cutOut
    let point = funcObj.findPoint(object.points, props.point)
    if (point === undefined && !props.point.includes('predefined')) {
      object = findChildByPoint(object, props.point)
      point = funcObj.findPoint(object.points, props.point)
    }

    if (point !== undefined) {
      switch (props.handle) {
        case 1:
          point.handles.x1 = funcObj.roundDecimal(props.movement.x, 2)
          point.handles.y1 = funcObj.roundDecimal(props.movement.y, 2)
          if (point.type === 'Q') {
            point.handles.x2 = undefined
            point.handles.y2 = undefined
          }
          break
        case 2:
          point.handles.x2 = funcObj.roundDecimal(props.movement.x, 2)
          point.handles.y2 = funcObj.roundDecimal(props.movement.y, 2)
          break
      }
    }
  },
  [types.UPDATE_OBJECT_POINTS_BY_SNAP] (state, props) {
    let obj = findObject(state, props.object)
    if (!obj) {
      obj = state.cutOut.id === props.object ? state.cutOut : null
    }
    if (obj) {
      funcObj.updateObjectMove(obj, props.movement)
    }
  },
  [types.TRANSFORM_OBJECT_POINT_TO_LINE] (state, props) {
    let object = (!props.isCutOut) ? findObject(state, props.object) : state.cutOut
    let point = funcObj.findPoint(object.points, props.circle)

    if (point === undefined) {
      object = findChildByPoint(object, props.circle)
      point = funcObj.findPoint(object.points, props.circle)
    }

    if (point !== undefined) {
      point.type = props.type
      point.handles = {}
    }
  },
  [types.TRANSFORM_OBJECT_POINT_TO_QUADRATIC] (state, props) {
    let object = (!props.isCutOut) ? findObject(state, props.object) : state.cutOut
    let point = funcObj.findPoint(object.points, props.circle)

    if (point === undefined) {
      object = findChildByPoint(object, props.circle)
      point = funcObj.findPoint(object.points, props.circle)
    }

    if (point !== undefined) {
      point.type = props.type
      const nextPoint = funcObj.getNextPoint(object.points, props.circle)

      const quadratic = funcObj.quadraticHandles(point, nextPoint)

      point.handles = {
        x1: funcObj.roundDecimal(quadratic.x1, 2),
        y1: funcObj.roundDecimal(quadratic.y1, 2),
        x2: undefined,
        y2: undefined
      }
    }
  },
  [types.TRANSFORM_OBJECT_POINT_TO_CUBIC] (state, props) {
    let object = (!props.isCutOut) ? findObject(state, props.object) : state.cutOut
    let point = funcObj.findPoint(object.points, props.circle)

    if (point === undefined) {
      object = findChildByPoint(object, props.circle)
      point = funcObj.findPoint(object.points, props.circle)
    }

    if (point !== undefined) {
      point.type = props.type

      const nextPoint = funcObj.getNextPoint(object.points, props.circle)

      const cubic = funcObj.cubicHandles(point, nextPoint)

      point.handles = {
        x2: funcObj.roundDecimal(cubic.x2, 2),
        y2: funcObj.roundDecimal(cubic.y2, 2),
        x1: funcObj.roundDecimal(cubic.x1, 2),
        y1: funcObj.roundDecimal(cubic.y1, 2)
      }
    }
  },
  [types.INIT_OBJECT_LAYER] (state, payload) {
    updateObjectOrder(state, payload)
  },
  [types.UPDATE_OBJECT_ORDER] (state, payload) {
    updateObjectOrder(state, payload)
  },
  [types.UPDATE_OBJECT_CUT] (state, object) {
    object.points = updateMovePoint(object.points)
    state.cutOut = object
  },
  [types.REMOVE_OBJECT_CUT] (state) {
    state.cutOut = null
  },
  [types.SET_SELECTION_MODE] (state, payload) {
    payload.object.selectionMode = payload.selectionMode
  },
  [types.SET_OBJECT_AREA] (state, payload) {
    payload.object.area = payload.area
  },
  [types.ADD_PRODUCT_TO_OBJECT] (state, payload) {
    const object = findObject(state, payload.object)
    object.productNeedUpdate = true
    object.product = payload.product
  },
  [types.REMOVE_PRODUCT_FROM_OBJECT] (state, payload) {
    payload = Array.isArray(payload) ? payload[0] : payload
    const object = findObject(state, payload)

    object.product = null
    object.productNeedUpdate = true
  },
  [types.REMOVE_PRODUCT_FROM_SELECTED_OBJECTS] (state, objects) {
    objects.forEach(obj => {
      obj.product = null
      // obj.settings.product.lineOfSight = 0
      // obj.settings.product.fit = '1'
      obj.productNeedUpdate = true
    })
  },
  [types.ADD_LAWN_CALCULATION_TO_OBJECT] (state, payload) {
    const object = findObject(state, payload.id)
    object.calculation = payload.calculation

    object.productNeedUpdate = false
  },
  [types.CLEAR_OBJECT_SELECTION_MODE] (state, payload) {
    if (payload.objects.length > 0) {
      payload.objects.forEach(object => {
        object.selectionMode = payload.mode
      })
    }
  },
  [types.PRODUCT_NEED_UPDATE_OBJECT_MODIFIED] (state, payload) {
    const object = findObject(state, payload)
    if (object !== undefined) {
      object.productNeedUpdate = true
    }
  },
  [types.OBJECT_UPDATE_PRODUCT] (state, data) {
    const object = findObject(state, data.object)
    object.productNeedUpdate = false
    object.product = data.product
  },
  [types.OBJECT_UPDATE_PRODUCT_PROPERTIES] (state, data) {
    const object = findObject(state, data.object)
    object.productNeedUpdate = false
    for (const key in data.product) {
      if (Object.prototype.hasOwnProperty.call(object.product, key)) {
        Vue.set(object.product, key, data.product[key])
      }
    }
  },
  [types.OBJECT_TRACK_REMOVE_SOURCE_TARGET_LOS] (state, data) {
    const object = state.objects.find(obj => {
      if (obj.id === data.wasteData.object) {
        return obj
      }
    })

    if (typeof object !== 'undefined') {
      const track = object.product.tracks.find(track => {
        if (track.sourceTargetFor && track.sourceTargetFor.some(target => {
          return target.object === data.waste.object && target.track === data.waste.track
        })) {
          return track
        }
      })

      if (typeof track !== 'undefined') {
        const index = track.sourceTargetFor.findIndex(target => {
          return target.object === data.waste.object && target.track === data.waste.track
        })

        if (index !== -1) {
          track.sourceTargetFor.splice(index, 1)
        }
      }
    }
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
