<script>
import SvgObject from '@/components/svg/Object'

/**
     * Provides a default object to cut out from the current selected object
     * Extends: Object.vue
     * @displayName Object Cut
     */
export default {
  name: 'SvgObjectCut',
  extends: SvgObject,
  computed: {
    objElement () {
      switch (this.object.type) {
        case this.objectTypes.PATH:
          return this.mx_object_elementEvents(this.mx_object_objPath(true))
        case this.objectTypes.CIRCLE:
          return this.mx_object_elementEvents(this.mx_object_objCircle(true))
      }
      return null
    }
  }
}
</script>
