<template>
  <nav class="c-nav-side">
    <div v-if="anyRole">
      <router-link
        id="side-nav-profile"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/profile"
      >
        <icon classes="fas fa-2x fa-user-circle" />
        <span>{{ $t('sideNav.profile') }}</span>
      </router-link>
    </div>

    <hr>

    <div v-if="manufactureDistributor">
      <router-link
        id="side-nav-materials"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/materials"
      >
        <icon classes="fas fa-2x fa-folder-plus" />
        <span>{{ distributor ? $t('sideNav.customMaterials') : $t('sideNav.materials') }}</span>
      </router-link>

      <hr>
    </div>

    <div v-if="manufacture">
      <router-link
        id="side-nav-group-settings"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/company-settings"
      >
        <icon classes="fas fa-2x fa-cog" />
        <span>{{ $t('sideNav.companySettings') }}</span>
      </router-link>
    </div>

    <div v-if="adminManufacture">
      <router-link
        id="side-nav-groups"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/groups"
      >
        <icon classes="fas fa-2x fa-boxes" />
        <span>{{ $t('sideNav.groups') }}</span>
      </router-link>
    </div>

    <div v-if="admin">
      <router-link
        id="side-nav-user-management"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/user-management"
      >
        <icon classes="fas fa-2x fa-users" />
        <span>{{ $t('sideNav.userManagement') }}</span>
      </router-link>
    </div>

    <div v-if="manufacture">
      <router-link
        id="side-nav-distributors"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/distributors"
      >
        <icon classes="fas fa-2x fa-users" />
        <span>{{ $t('sideNav.distributors') }}</span>
      </router-link>
    </div>

    <div v-if="adminManufacture">
      <hr>

      <router-link
        id="side-nav-stats"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/stats"
      >
        <icon classes="fas fa-2x fa-chart-pie" />
        <span>{{ $t('sideNav.stats') }}</span>
      </router-link>
    </div>

    <div v-if="distributor">
      <router-link
        id="side-nav-locations"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/locations"
      >
        <icon classes="fas fa-2x fa-map-marker-alt" />

        <span>
          {{ $t('sideNav.locations') }}
          <span v-if="$router.currentRoute.name === 'Location-products'">
            <icon classes="fas fa-chevron-right" />
            {{ $t('sideNav.setMaterials') }}
          </span>
        </span>
      </router-link>

      <router-link
        id="side-nav-employees"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/employees"
      >
        <icon classes="fas fa-2x fa-users" />
        <span>{{ $t('sideNav.employees') }}</span>
      </router-link>

      <router-link
        id="side-nav-api-clients"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/api-clients"
      >
        <icon classes="fab fa-2x fa-keycdn" />
        <span>{{ $t('sideNav.apiUsers') }}</span>
      </router-link>
    </div>

    <div v-if="distributorEmployee">
      <router-link
        id="side-nav-clients"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/clients"
      >
        <icon classes="fas fa-2x fa-users" />
        <span>{{ $t('sideNav.clients') }}</span>
      </router-link>

      <router-link
        id="side-nav-projects"
        active-class="c-nav-side__item--active"
        class="c-nav-side__item"
        tag="button"
        to="/backend/projects"
      >
        <icon classes="fas fa-2x fa-copy" />
        <span>{{ $t('sideNav.projects') }}</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { roles } from '@/helper/apiHelper'
import Icon from '@/components/common/Icon'

export default {
  name: 'SideNav',
  components: { Icon },
  data: () => {
    return {
      roles: roles,
      onlyInDev: JSON.parse(process.env.VUE_APP_ONLY_IN_DEV_VISIBLE)
    }
  },
  computed: {
    ...mapGetters({
      getUserRole: 'backendAPI/user/getRole'
    }),
    anyRole () {
      return this.getUserRole === roles.admin || this.getUserRole === roles.manufacture || this.getUserRole === roles.distributor || this.getUserRole === roles.employee
    },
    admin () {
      return this.getUserRole === roles.admin
    },
    adminManufacture () {
      return this.getUserRole === roles.admin || this.getUserRole === roles.manufacture
    },
    manufacture () {
      return this.getUserRole === roles.manufacture
    },
    manufactureDistributor () {
      return this.getUserRole === roles.manufacture || this.getUserRole === roles.distributor
    },
    distributor () {
      return this.getUserRole === roles.distributor
    },
    distributorEmployee () {
      return this.getUserRole === roles.distributor || this.getUserRole === roles.employee
    }
  },
  methods: {
    ...mapActions({
      setProjectInitialState: 'history/setProjectInitialState'
    }),
    openEditor () {
      this.setProjectInitialState()
      this.$router.push({ name: 'Melos-Editor' })
    }
  }
}
</script>
