import { mapState, mapGetters, mapActions } from 'vuex'
import _ from 'lodash'

import MixinPdf from '@/mixins/export/pdf'
import MixinSVG from '@/mixins/export/svg'
import MixinLanguages from '@/mixins/languages'

/**
 * Helper that provides multiply export methods
 * @displayName Export
 */
export default {
  name: 'ExportMixin',
  mixins: [
    MixinPdf,
    MixinSVG,
    MixinLanguages
  ],
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      contact: state => state.backendAPI.user.me,
      projectMeta: state => state.project.backendMeta,
      products: state => state.products.products
    }),
    ...mapGetters({
      getAreasWithProducts: 'project/objects/objectsWithProducts',
      getProjectAdditionalCosts: 'project/getAdditionalCosts'
    })
  },
  methods: {
    ...mapActions({
      exportPdfTurfOverview: 'backendAPI/export/exportPdfTurfOverview',
      exportPdfTurfInstallationPlan: 'backendAPI/export/exportPdfTurfInstallationPlan'
    }),
    /**
         * Export PDF Overview
         * @param {boolean} costs Export pdf overview with costs
         * @public
         */
    mx_export_pdf (costs = true) {
      this.$performance.start('pdf')
      const language = {
        areaPrefix: this.$i18n.t('pdf.areaPrefix'),
        trackNamePrefix: this.$i18n.t('pdf.trackNamePrefix')
      }

      const project = {
        id: this.projectMeta.id,
        name: this.projectMeta.name,
        executionAddress: _.cloneDeep(this.projectMeta.address),
        date: new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000,
        additionalCosts: this.$_mx_project_additional_costs()
      }

      const contact = {
        name: `${this.mx_languages_getCurrentSalutation(this.contact.salutation)} ${this.contact.firstname} ${this.contact.lastname}`,
        email: this.contact.email,
        phone: this.contact.phone,
        locationName: this.projectMeta.location.name,
        locationAddress: _.cloneDeep(this.projectMeta.location.address)
      }

      contact.locationAddress.name = contact.locationName

      const client = {
        name: `${this.mx_languages_getCurrentSalutation(this.projectMeta.client.salutation)} ${this.projectMeta.client.firstname} ${this.projectMeta.client.lastname}`,
        email: this.projectMeta.client.email,
        address: _.cloneDeep(this.projectMeta.client.address)
      }

      const areaResult = this.mx_pdf_get_turf_project_details(this.getAreasWithProducts, this.measure, this.products, language)
      const projectCosts = areaResult.total.prices.project

      projectCosts.value = projectCosts.value + project.additionalCosts.total.value
      projectCosts.formatted = this.$world.currencyConvert(projectCosts.value, true)

      areaResult.total.prices.project = projectCosts

      this.$performance.stop('pdf')
      this.$devLog.log('export get pdf data', this.$performance.get_time_needed('pdf'))

      this.exportPdfTurfOverview({
        project: project,
        contact: contact,
        client: client,
        area: areaResult,
        withCosts: costs,
        measurements: this.$settings.getAllSettings
      })
    },
    /**
         * Export pdf installation plan a1
         * @public
         */
    mx_export_pdf_turf_a1 () {
      this.$performance.start('pdfa1')
      const language = {
        areaPrefix: this.$i18n.t('pdf.areaPrefix'),
        trackNamePrefix: this.$i18n.t('pdf.trackNamePrefix')
      }

      const project = {
        id: this.projectMeta.id,
        name: this.projectMeta.name,
        executionAddress: _.cloneDeep(this.projectMeta.address),
        date: new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      }

      const contact = {
        name: `${this.mx_languages_getCurrentSalutation(this.contact.salutation)} ${this.contact.firstname} ${this.contact.lastname}`,
        locationName: this.projectMeta.location.name,
        locationAddress: _.cloneDeep(this.projectMeta.location.address)
      }

      contact.locationAddress.name = contact.locationName

      const client = {
        address: _.cloneDeep(this.projectMeta.client.address)
      }

      const resultArea = this.mx_pdf_get_turf_project_a1(this.getAreasWithProducts, this.measure, this.products, language)

      this.$performance.stop('pdfa1')
      this.$devLog.log('export get pdf data', this.$performance.get_time_needed('pdfa1'))

      this.exportPdfTurfInstallationPlan({
        project: project,
        contact: contact,
        client: client,
        areasTable: resultArea.areasTable,
        plan: resultArea.plan,
        measurements: this.$settings.getAllSettings
      })
    },
    /**
     * Calls the different export functions
     * @param {string} type String - Available types: svg
     * @public
     */
    mx_export_project_as (type = 'svg') {
      switch (type) {
        case 'svg':
          this.mx_svg_project()
      }
    },
    $_mx_project_additional_costs () {
      let total = 0
      const costs = []

      this.getProjectAdditionalCosts.forEach(cost => {
        const _clone = _.cloneDeep(cost)
        _clone.formatted = this.$world.currencyConvert(Number(cost.price), true)
        total += Number(cost.price)

        costs.push(_clone)
      })

      return {
        costs: costs,
        total: {
          value: total,
          formatted: this.$world.currencyConvert(total, true)
        }
      }
    }
  }
}
