function copyToClipboardPlugin (Vue) {
  if (copyToClipboardPlugin.installed) return

  const copyToClipboard = new Vue({
    methods: {
      copy (value) {
        const input = document.createElement('input')
        document.body.appendChild(input)
        input.setAttribute('id', 'dummyInput')
        document.getElementById('dummyInput').value = value
        input.select()
        input.setSelectionRange(0, 99999)
        document.execCommand('copy')
        document.body.removeChild(input)
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$copyToClipboard', {
    get () {
      return copyToClipboard
    }
  })

  Vue.mixin({})
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(copyToClipboardPlugin)
}

export default copyToClipboardPlugin
