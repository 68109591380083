<template>
  <panel-container :title=" $t('editor.lineOfSight.product.alignment.title') ">
    <input-checkbox
      id="checkbox-c-align"
      v-model="align"
      :is-store-mode="false"
      :label="$t('editor.lineOfSight.product.alignment.rightLeft')"
      classes="u-mt-size-0 u-mb-size-standard u-mt-size-standard"
    />
    <input-checkbox
      id="checkbox-c-invert"
      v-model="invert"
      :is-store-mode="false"
      :label="$t('editor.lineOfSight.product.alignment.invert')"
      classes="u-mt-size-0 u-mb-size-standard u-mt-size-standard"
      @change="invertYarn"
    />
  </panel-container>
</template>

<script>
import { mapGetters } from 'vuex'

import MixinMath from '@/mixins/math'

import PanelContainer from '@/components/common/PanelContainer'
import InputCheckbox from '@/components/common/InputCheckbox'
import _ from 'lodash'

/**
     * Configure the side where the calculation starts
     * @displayName LineOfSight Panel - Product - Alignment
     */
export default {
  name: 'LineOfSightProductAlignment',
  components: {
    InputCheckbox,
    PanelContainer
  },
  mixins: [
    MixinMath
  ],
  data: () => {
    return {
      invert: false
    }
  },
  computed: {
    ...mapGetters({
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    align: {
      get () {
        return this.getObjectProduct.alignment
      },
      set (value) {
        this.$emit('changeProductAlignment', { action: 'changeProductAlignment', data: { alignment: value } })
      }
    }
  },
  methods: {
    invertYarn () {
      this.$emit('changeProductAlignment', {
        action: 'changeProductAlignment',
        data: { alignment: !this.align, angle: this.shiftAngle(_.clone(this.getObjectProduct.angle)) }
      })
    },
    shiftAngle (angle) {
      const shiftAngle = (Number(angle) >= 180) ? Number(angle) - 180 : Number(angle) + 180

      return this.mx_math_roundDecimal(shiftAngle)
    }
  }
}
</script>
