<template>
  <g
    v-if="active && selectionMode !== config.objects.modes.EDIT"
    :id="'productGroup'"
    class="no-pointer-events"
  >
    <text v-if="product !== null && getProductById(product.id) !== undefined">
      <tspan
        v-if="product !== null"
        :font-size="mx_text_fontSizeArea"
        :x="position.x"
        :y="position.y"
        :stroke-width="mx_text_strokeWidth"
        stroke="white"
        fill="black"
        text-anchor="middle"
        paint-order="stroke"
      >
        {{ productName }}
      </tspan>
    </text>
  </g>
</template>
<script>
import config from '@/config'

import MixinMath from '@/mixins/math'
import MixinPoints from '@/mixins/points'
import MixinText from '@/mixins/text'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

import { mapGetters, mapState } from 'vuex'

/**
     * Shows the name from the added product
     * @displayName Object product name
     */
export default {
  mixins: [MixinMath, MixinPoints, MixinText, MixinProductInLanguage],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    selectionMode: {
      type: [Number, String],
      required: true
    },
    product: {
      type: [Array, Object, null],
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      required: false,
      default: 'PATH'
    }
  },
  data: () => {
    return {
      config: config
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      getProductById: 'products/getProductById'
    }),
    productName () {
      const product = this.getProductById(this.product.id)
      return (product) ? this.mx_product_in_language(product.original, ['name']) : ''
    },
    position () {
      let areaCenter = { x: 0, y: 0 }

      switch (this.type) {
        case config.objects.types.PATH:
          areaCenter = this.mx_math_getCenter(this.mx_points_filteredPoints)
          break
        case config.objects.types.CIRCLE: {
          const object = this.getObject(this.id)
          const circleCenter = object.getCircleCenter()

          areaCenter = {
            x: circleCenter.x,
            y: circleCenter.y
          }

          break
        }
        default:
          areaCenter = this.mx_math_getCenter(this.mx_points_filteredPoints)
      }

      areaCenter.y += this.mx_math_handleElementsOnScale(30)

      return areaCenter
    }
  }
}
</script>
