<template>
  <svg
    id="draw-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      class="u-fill-w"
      cx="6.05"
      cy="5.86"
      r="5"
    />
    <circle
      class="u-fill-w"
      cx="5.97"
      cy="34.14"
      r="5"
    />
    <circle
      class="u-fill-w"
      cx="33.57"
      cy="5.86"
      r="5"
    />
    <circle
      class="u-fill-w"
      cx="33.49"
      cy="34.14"
      r="5"
    />
    <path
      class="u-fill-w"
      d="M33.65,7.86H6a2,2,0,1,1,0-4H33.65a2,2,0,0,1,0,4Z"
    />
    <path
      class="u-fill-w"
      d="M33.92,36.14H6.24a2,2,0,0,1,0-4H33.92a2,2,0,0,1,0,4Z"
    />
    <path
      class="u-fill-w"
      d="M6,36.14a2,2,0,0,1-2-2V6.46a2,2,0,0,1,4,0V34.14A2,2,0,0,1,6,36.14Z"
    />
    <path
      class="u-fill-w"
      d="M33.65,36.14A2,2,0,0,1,32,35.26l-9.4-13.84a2,2,0,0,1,3.31-2.25L35.3,33a2,2,0,0,1-.53,2.78A1.93,1.93,0,0,1,33.65,36.14Z"
    />
    <circle
      class="u-fill-b1"
      cx="24.56"
      cy="20"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'DrawIcon'
}
</script>
