<template>
  <div class="u-flex u-fd-row u-ai-center">
    <button-icon
      :id="`export-csv-${byType}`"
      classes="u-pl-size-5 u-pr-size-5"
      icon-classes="fas fa-file-import"
      style-font-size="xl"
      @click="() => { openDialog() }"
    >
      <template #popup>
        {{ $t('backend.materials.import', {type: $t(`backend.materials.menu.byType.${byType}`)}) }}
      </template>
    </button-icon>

    <button-icon
      :id="`export-csv-${byType}`"
      classes="u-pl-size-5 u-pr-size-5"
      icon-classes="fas fa-file-download"
      style-font-size="xl"
      @click="() => { getMaterialCsv(byType) }"
    >
      <template #popup>
        {{ $t('backend.materials.export', {type: $t(`backend.materials.menu.byType.${byType}`)}) }}
      </template>
    </button-icon>

    <button-icon
      :id="`export-import-csv-help-${byType}`"
      classes="u-pl-size-5 u-pr-size-5"
      icon-classes="fas fa-question-circle"
      style-font-size="xl"
      @click="() => { openHelp() }"
    >
      <template #popup>
        {{ $t('backend.materials.help') }}
      </template>
    </button-icon>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '@/config'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonIcon from '@/components/common/ButtonIcon'

export default {
  name: 'MaterialCSV',
  components: {
    ButtonIcon
  },
  mixins: [MixinDialogHandler],
  props: {
    byType: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      currentLanguage: 'language/get'
    })
  },
  methods: {
    ...mapActions({
      getMaterialCsv: 'backendAPI/csv/getByType'
    }),
    openDialog () {
      this.$_mx_dialogHandler_openDialog(
        { dialog: 'importMaterial', onOff: true, dialogProps: { materialType: this.byType } })
    },
    openHelp () {
      window.open(`${config.frontend.baseUrl}downloads/${config.downloads.csvImport[this.currentLanguage].path}`, '_blank')
    }
  }
}
</script>
