<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createAdmin.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createAdmin.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <drop-down
            id="create-admin-salutation"
            v-model="admin.salutation"
            :items="mx_languages_getSalutations"
            :label="$t('dialogs.createAdmin.salutation')"
            :required="true"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="label"
            style-current-item-width="240px"
            value-key="value"
          />
          <input-field
            id="create-admin-first-name"
            v-model="admin.firstname"
            :focus-on-mount="true"
            :is-store-mode="false"
            :label="$t('dialogs.createAdmin.firstName')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-admin-last-name"
            v-model="admin.lastname"
            :is-store-mode="false"
            :label="$t('dialogs.createAdmin.lastName')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-admin-email"
            v-model="admin.email"
            :is-store-mode="false"
            :label="$t('dialogs.createAdmin.email')"
            :required="true"
            :validate="(v) => {return mx_validate_validateEmail(v)}"
            placeholder="email@company.com"
            style-input-width="240px"
            type="email"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-admin-phone"
            v-model="admin.phone"
            :is-store-mode="false"
            :label="$t('dialogs.createAdmin.phone')"
            :required="true"
            :validate="(v) => {return mx_validate_validatePhone(v)}"
            placeholder="+49 1234 567 890"
            style-input-width="240px"
            type="tel"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-admin-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-admin-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewAdmin() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import config from '@/config'

import { apiMethods } from '@/helper/apiHelper'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinLanguages from '@/mixins/languages'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Create Admin
     */
export default {
  name: 'CreateAdmin',
  components: {
    ButtonBase,
    DialogFrame,
    DropDown,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinLanguages
  ],
  data: () => {
    return {
      admin: apiMethods.getModel('admin'),
      targetDialog: 'createAdmin',
      cannotClose: false,
      config: config
    }
  },
  computed: {
    ...mapGetters({
      getGroups: 'backendAPI/group/getAll'
    })
  },
  methods: {
    ...mapActions({
      createAdmin: 'backendAPI/admin/create'
    }),
    async createNewAdmin () {
      await this.createAdmin(this.admin)
      this.close()
    },
    close () {
      this.admin = apiMethods.getModel('admin')
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
