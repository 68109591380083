<template>
  <backend>
    <template #dialogs />

    <template #content>
      <div class="o-grid o-grid--center u-mt-size-80 u-mt-size-20">
        <div class="o-grid__col u-mt-size-50 u-10/12">
          <admin-group-stats-list v-if="isAdmin" />
          <manufacture-group-stats-list v-else />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>

import Backend from '@/views/Backend'

import ManufactureGroupStatsList from '@/components/backend/stats/group/ManufactureGroupStatsList'
import AdminGroupStatsList from '@/components/backend/stats/group/AdminGroupStatsList'

import MixinDialogHandler from '@/mixins/dialogHandler'
import { mapGetters } from 'vuex'
import { roles } from '@/helper/apiHelper'

/**
     * @displayName GroupStats Component
     */
export default {
  name: 'GroupStats',
  components: {
    Backend,
    ManufactureGroupStatsList,
    AdminGroupStatsList
  },
  mixins: [MixinDialogHandler],
  computed: {
    ...mapGetters({
      getUserRole: 'backendAPI/user/getRole'
    }),
    isAdmin () {
      return this.getUserRole === roles.admin
    }
  }
}
</script>
