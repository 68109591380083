import * as types from './mutation-types'
import Vue from 'vue'

const withHistoryMutation = (mutations, initState) => {
  mutations[types.SET_FROM_HISTORY] = (state, stateObj) => {
    for (const prop in stateObj) {
      if (Object.prototype.hasOwnProperty.call(state, prop)) {
        Vue.set(state, prop, stateObj[prop])
      }
    }
  }
  mutations[types.RESET_STATE] = (state) => {
    if (typeof initState === 'function') {
      Object.assign(state, initState())
    }
  }
  return mutations
}

export { withHistoryMutation }
