<template>
  <backend>
    <template #dialogs>
      <create-employee-dialog />
      <upgrade-employee-dialog />
      <delete-employee-dialog />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div
          v-if="!isManufacture"
          class="o-grid__col u-10/12"
        >
          <button-base
            id="employees-create-employee"
            :label="$t('backend.employees.newEmployee')"
            :on-click-action="() => { return _event() }"
            classes="u-mt-size-40 u-ml-size-0"
            icon-classes="fa fa-plus"
            style-size="s"
          />
        </div>
      </div>
      <div :class="[isManufacture ? 'u-mt-size-110' : 'u-mt-size-20', 'o-grid o-grid--center'] ">
        <div class="o-grid__col u-10/12">
          <employee-list />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import { roles } from '@/helper/apiHelper'
import { mapGetters } from 'vuex'
import Backend from '@/views/Backend'

import CreateEmployeeDialog from '@/components/backend/employees/dialogs/CreateEmployee'
import UpgradeEmployeeDialog from '@/components/backend/employees/dialogs/UpgradeEmployee'
import DeleteEmployeeDialog from '@/components/backend/employees/dialogs/DeleteEmployee'
import EmployeeList from '@/components/backend/employees/EmployeeList'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Employees Component
     */
export default {
  name: 'Employees',
  components: {
    ButtonBase,
    Backend,
    CreateEmployeeDialog,
    UpgradeEmployeeDialog,
    DeleteEmployeeDialog,
    EmployeeList
  },
  mixins: [MixinDialogHandler],
  data () {
    return {
      roles: roles
    }
  },
  computed: {
    ...mapGetters({
      getMyRole: 'backendAPI/user/getRole'
    }),
    isManufacture () {
      return this.getMyRole === roles.manufacture
    }
  },
  methods: {
    _event () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'createEmployee',
        onOff: true
      })
    }
  }
}
</script>
