import _ from 'lodash'
import PaperLayer from '@/classes/paperLayer'
import MixinTrackState from '@/mixins/trackstate'
import { mapActions, mapGetters } from 'vuex'

/**
 * @displayName Validate
 */
export default {
  name: 'ValidateMixin',
  data () {
    return {
      mx_validate_errors: {},
      mx_validate_isConfirmBlocked: true
    }
  },
  mixins: [
    MixinTrackState
  ],
  computed: {
    ...mapGetters({
      getObject: 'project/objects/getById'
    })
  },
  methods: {
    ...mapActions({
      removeValidatedObjectPoints: 'project/objects/removeValidatedObjectPoints'
    }),

    /* ------------------------------------------
     *  Validate forms
     *------------------------------------------ */

    /**
     * Validates array of errors and returns true as lon as there's at least one error
     * @param payload
     * @param {string} payload.id - field id which will be set in error array
     * @param {boolean} payload.error - error value from field
     * @returns {boolean}
     * @public
     */
    mx_validate_updateErrorsArray (payload) {
      this.mx_validate_errors[payload.id] = payload.error

      let atLeastOneError = false

      if (Object.keys(this.mx_validate_errors).length !== 0) {
        for (const error in this.mx_validate_errors) {
          if (this.mx_validate_errors[error]) {
            atLeastOneError = true
          }
        }
      }

      this.mx_validate_isConfirmBlocked = atLeastOneError
    },

    /**
     * Set errors for all fields at the beginning as true
     * @param payload
     * @param {string} payload.id - field id which will be set in error array
     * @param {*} payload.val - field value
     * @param {boolean} payload.error - error value from validation
     * @public
     */
    mx_validate_registerInputsInErrorsArray (payload) {
      this.mx_validate_errors[payload.id] = payload.error
      this.mx_validate_isConfirmBlocked = true
    },

    /**
     * Validate given string and returns true if it's not empty (white spaces are not accepted)
     * @param {string} val
     * @return {boolean} result
     * @public
     */
    mx_validate_validateNotEmpty (val) {
      const _val = String(val)
      if (val) {
        return _val.trim() !== ''
      }
      return false
    },

    /**
     * Validate given string and returns true if it's not empty (white spaces are not accepted)
     * @param {*} val
     * @return {boolean} result
     * @public
     */
    mx_validate_validateEmpty (val) {
      return (val === null) ||
                (val === undefined) ||
                (val === '')
    },

    /**
     * Validate given value and return true if it is a correct Id (also not empty)
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validateId (val) {
      return (/^([a-zA-Z0-9_-])+$/.test(val)) &&
        val !== ''
    },

    /**
     * Validate given value and return true if it is a correct email (also not empty)
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validateEmail (val) {
      return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(val)) &&
                val !== ''
    },

    /**
     * Validate given value and return true if it is a correct URL address
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validateURL (val) {
      return (/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(val)) &&
        val !== ''
    },

    /**
     * Validate given value and return true if it is a correct phone number (also not empty)
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validatePhone (val) {
      return (/^\+{1}[0-9\- \(\)]{6,}$/.test(val)) &&
                val !== ''
    },

    /**
     * Validate given value and return true if no sign matches any of not allowed signs
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validateStandardNotAllowed (val) {
      return (/^[^\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]+$/.test(val)) && // not arabic
      // eslint-disable-next-line no-misleading-character-class
                (/^[^\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29\ud840-\ud868\udc00-\udfff\ud869\udc00-\uded6\udf00-\udfff\ud86a-\ud86c\udc00-\udfff\ud86d\udc00-\udf34\udf40-\udfff\ud86e\udc00-\udc1d]+$/.test(val)) && // not chinese
                (/^[^!?]+$/.test(val)) && // not ! or ?
                val !== ''
    },

    /* ------------------------------------------
     *  Validate numbers
     *------------------------------------------ */

    /**
     * Validate given value and return true if it is a number greater than 0
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validatePositiveNumber (val) {
      return this.$_mx_validate_validateNumber(val) && Number.parseFloat(val) > 0
    },

    /**
     * Validate given value and return true if it is a number greater or equal 0
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    mx_validate_validateNotNegativeNumber (val) {
      return this.$_mx_validate_validateNumber(val) && Number.parseFloat(val) >= 0
    },

    /**
     * Validate given value and return true if it is a number (also not empty)
     * @param {string} val
     * @returns {boolean} result
     * @public
     */
    $_mx_validate_validateNumber (val) {
      return (/^[-+]?\d+([\.][0-9])?\d*$/.test(val)) &&
                !Number.isNaN(Number.parseFloat(val)) &&
                val !== ''
    },

    /* ------------------------------------------
     *  Validate paths
     *------------------------------------------ */

    /**
     * Validate the possibility of removing a point from object
     * @param {string|BasePath} object
     * @param {string} pointId
     * @public
     */
    mx_validate_removingPointsWithValidation (object, pointId) {
      object = (typeof object === 'string') ? this.getObject(object) : object
      // copy of an object to carry out tests before even touching the original
      const objectCopy = _.cloneDeep(object)
      let pointIndex = objectCopy.getPointIndex(pointId)
      let child = null

      // if it's a child element
      if (pointIndex === -1) {
        child = objectCopy.findChildByPoint(pointId)
        pointIndex = child.getPointIndex(pointId)
      }

      // point found
      if (pointIndex !== -1) {
        (child === null)
          ? objectCopy.removePoint(pointIndex)
          : child.removePoint(pointIndex)

        const isValid = PaperLayer.isValidObject(objectCopy)

        if (!isValid) {
          if (child !== null) {
            objectCopy.removeChildFromObject(objectCopy, child.id)
            this.removeValidatedObjectPoints({ newObject: objectCopy, objectId: object.id })
          } else {
            this.mx_trackstate_breakStateTransaction()
            this.$toasted.show(this.$t('toast.objects.cantDeletePoint'), { duration: 7000, type: 'error' })
          }
        } else {
          if (child !== null && child.getPoints().length - 2 < 3) {
            objectCopy.removeChildFromObject(objectCopy, child.id)
          }
          this.removeValidatedObjectPoints({ newObject: objectCopy, objectId: object.id })
        }
      }
    },

    /**
     * Validate the possibility of closing a given Path
     * @param {Point[]} points
     * @param {Point} endPoint
     * @returns {boolean} result
     * @public
     */
    mx_validate_validatePathClosing (points, endPoint) {
      const tmpPaperPath = PaperLayer.makePaperPathFromPoints(points)

      endPoint = (endPoint) ? { x: endPoint.x, y: endPoint.y } : undefined

      if (points.length >= 2 && !PaperLayer.isValidObject(tmpPaperPath, endPoint)) {
        this.$toasted.show(this.$t('toast.objects.invalidShape'), { duration: 7000, type: 'error' })
        return false
      }
      return true
    }
  }
}
