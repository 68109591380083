import Vue from 'vue'
import Vuex from 'vuex'
import globalStoreWatch from './global-store-watch'

import _ from 'lodash'

/**
 * START: Import Store Modules
 */
import common from '@/vuex/modules/common'
import project from '@/vuex/modules/project'
import editor from '@/vuex/modules/editor'
import events from '@/vuex/modules/events'
import dialogs from '@/vuex/modules/dialogs'
import files from '@/vuex/modules/files'
import pseudo from '@/vuex/modules/pseudo'
import navigation from '@/vuex/modules/navigation'
import history from '@/vuex/modules/history'
import products from '@/vuex/modules/products'
import backendAPI from '@/vuex/modules/backendAPI'
import lineOfSight from '@/vuex/modules/lineOfSight'
import lineOfSightNew from '@/vuex/modules/lineOfSightNew'
import gui from '@/vuex/modules/gui'
import template from '@/vuex/modules/template'
import language from '@/vuex/modules/language'

Vue.use(Vuex)

/**
 * END: Import Store Modules
 */

const storeDefinition = {
  modules: {
    backendAPI,
    common,
    project,
    editor,
    events,
    dialogs,
    files,
    pseudo,
    navigation,
    history,
    products,
    lineOfSight,
    lineOfSightNew,
    gui,
    template,
    language
  },
  strict: true
}

const store = new Vuex.Store(storeDefinition)

const initialRootState = _.cloneDeep(store.state)

const copyInitialRootState = () => {
  return _.cloneDeep(initialRootState)
}

// GLOBAL STORE WATCHERS ---------------------------------------------------------------------------------------------------------------------------------------

store.watch((state) => {
  globalStoreWatch(state, store)
})

export { storeDefinition, copyInitialRootState }
export default store
