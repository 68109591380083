import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import { Path, Point } from '@/classes/objects'
import MixinMath from '@/mixins/math'
import MixinCollision from '@/mixins/collision'
import MixinDrawCollision from '@/mixins/drawCollision'
import MixinTrackState from '@/mixins/trackstate'
import MixinValidate from '@/mixins/validate'
import PaperLayer from '@/classes/paperLayer'

import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Draw methods
 * @displayName Draw
 */
export default {
  name: 'DrawMixin',
  data: () => {
    return {
      activeCtrl: 'draw'
    }
  },
  mixins: [
    MixinMath,
    MixinCollision,
    MixinTrackState,
    MixinDrawCollision,
    MixinValidate
  ],
  mounted () {
    this.$events.on(editorEventTypes.DRAW_ADD_POINT_TO_PATH, this.$_mx_draw_addPointToPath)
    this.$events.on(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, this.$_mx_draw_updateMouse)
    this.$events.on(editorEventTypes.DRAW_ESCAPE_PRESSED, this.$_mx_draw_resetOrRemoveLastPoint)
    this.$events.on(editorEventTypes.DRAW_ENTER_PRESSED, this.$_mx_draw_checkToFinishPath)
  },
  computed: {
    ...mapState({
      mx_draw_keyboardInput: state => state.pseudo.keyboardInput,
      mx_draw_mouse: state => state.pseudo.mouse,
      /** @private **/
      $_mx_draw_currentPath: state => state.pseudo.path,
      /** @private **/
      $_mx_draw_lastObjectId: state => state.project.lastObjectId,
      /** @private **/
      $_mx_draw_gridSnap: state => state.editor.grid.snap
    }),
    ...mapGetters({
      getMeasure: 'project/measurement/getMeasure'
    })
  },
  methods: {
    ...mapActions({
      /** @private **/
      $_mx_draw_addNewPointToPseudoPath: 'pseudo/addNewPointToPseudoPath',
      /** @private **/
      $_mx_draw_addObjectToProject: 'project/addObjectToProject',
      /** @private **/
      $_mx_draw_stepBack: 'history/stepBack',
      $_mx_draw_setKeyboardInput: 'pseudo/setKeyboardInput'
    }),
    ...mapMutations({
      /** @private **/
      $_mx_draw_setPseudoPath: 'pseudo/' + mutationTypes.SET_PSEUDO_PATH,
      /** @private **/
      $_mx_draw_removeLastPseudoPoint: 'pseudo/' + mutationTypes.REMOVE_LAST_PSEUDO_POINT,
      /** @private **/
      $_mx_draw_setPseudoMouse: 'pseudo/' + mutationTypes.UPDATE_PSEUDO_MOUSE_COORDINATES,
      /** @private **/
      $_mx_draw_updateLastObjectId: 'project/' + mutationTypes.UPDATE_PROJECT_LAST_OBJECT_ID
    }),
    /**
         * One step back or break drawing (on escape)
         * @private
         */
    $_mx_draw_resetOrRemoveLastPoint () {
      if (this.$_mx_draw_currentPath) {
        if (this.$_mx_draw_currentPath.getPoints().length > 1) {
          this.$_mx_draw_removeLastPseudoPoint()
        } else {
          this.mx_draw_reset()
        }
      } else {
        this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
      }
    },
    /**
         * Check if the path can be finished
         * @private
         */
    $_mx_draw_checkToFinishPath () {
      if (this.$_mx_draw_currentPath !== null) {
        const ePoint = this.$_mx_draw_currentPath.points[0]
        const pointType = (this.$_mx_draw_currentPath.hasPoints(0)) ? 'L' : 'M'
        const pseudoPoint = new Point(ePoint.x, ePoint.y, pointType)
        const tmpPaperPath = PaperLayer.makePaperPathFromPoints(this.$_mx_draw_currentPath.points)

        if (this.$_mx_draw_currentPath.getPoints().length - 2 >= 1 && PaperLayer.isValidObject(tmpPaperPath, {
          x: pseudoPoint.x,
          y: pseudoPoint.y
        })) {
          this.$_mx_draw_finishPath({ keyCode: 13 })
        } else {
          this.$toasted.show(this.$t('toast.objects.invalidShape'), { duration: 7000, type: 'error' })
        }
      }
    },
    /**
         * Prepare the event point
         * @requires @/mixins/collision
         * @param evt
         * @returns {*|{x: number, y: number}}
         * @private
         */
    $_mx_draw_prepareEPoint (evt) {
      let ePoint = this.mx_math_getCoordinatesFromEvent(evt)
      if (evt.shiftKey) {
        ePoint = this.mx_math_ortho(ePoint)
      }
      if (this.$_mx_draw_gridSnap && !this.snapKeyPressed) {
        ePoint = this.mx_collision_closestGridPoint(ePoint)
      }
      if (this.snapKeyPressed) {
        const t = this.mx_collision_testCollidingPoints(ePoint)
        if (t.length) {
          ePoint = { x: t[0].p1.x, y: t[0].p1.y }
        } else if (this.$_mx_draw_gridSnap) {
          ePoint = this.mx_collision_closestGridPoint(ePoint)
        }
        this.mx_collision_setCollisionPoints(t)
      }
      return ePoint
    },
    /**
         * Update the mouse position by given event
         * @param evt
         * @private
         */
    $_mx_draw_updateMouse (evt) {
      const ePoint = this.$_mx_draw_prepareEPoint(evt)
      this.$_mx_draw_setPseudoMouse({ x2: ePoint.x, y2: ePoint.y })
    },
    /**
         * Add a point to the path by given event
         * @param evt
         * @returns {boolean}
         * @private
         */
    $_mx_draw_addPointToPath (evt) {
      let isNewPath = false
      if (this.$_mx_draw_currentPath === null) {
        const newId = this.$_mx_draw_lastObjectId + 1
        this.$_mx_draw_setPseudoPath(new Path(newId))
        this.$_mx_draw_updateLastObjectId(newId)
        this.$events.fire(editorEventTypes.DRAW_START)
        isNewPath = true
      }

      let ePoint = null
      if (this.mx_draw_keyboardInput > 0 && !isNewPath) {
        const lastPoint = this.$_mx_draw_currentPath.points.slice(-1).pop()
        const result = this.mx_math_getPointInDistance(lastPoint, this.mouse, this.mx_draw_keyboardInput, this.getMeasure)
        ePoint = {
          x: result.x2,
          y: result.y2
        }
        this.$_mx_draw_setKeyboardInput(0)
        if (Object.hasOwnProperty.call(this.$refs, 'drawKeyboardInput')) {
          this.$refs.drawKeyboardInput.resetInput()
        }
      } else {
        ePoint = this.$_mx_draw_prepareEPoint(evt)
      }

      const pointType = (this.$_mx_draw_currentPath.hasPoints(0)) ? 'L' : 'M'
      const pseudoPoint = new Point(ePoint.x, ePoint.y, pointType)

      if (!this.mx_validate_validatePathClosing(this.$_mx_draw_currentPath.points, pseudoPoint)) {
        return false
      }

      const hitTest = this.$_mx_draw_currentPath.hitTest(pseudoPoint)
      if (hitTest.hit) {
        this.$_mx_draw_finishPath({ keyCode: 13 })
      } else {
        this.$_mx_draw_setPseudoMouse({ x1: ePoint.x, y1: ePoint.y })
        this.$_mx_draw_addNewPointToPseudoPath(pseudoPoint)
      }
    },
    /**
         * Finish the path by event
         * @param evt
         * @private
         */
    $_mx_draw_finishPath (evt) {
      if (evt.keyCode === 13) {
        const firstPoint = this.$_mx_draw_currentPath.getPointByIndex(0)
        this.$_mx_draw_addNewPointToPseudoPath(new Point(firstPoint.x, firstPoint.y, 'L'))
        this.$_mx_draw_addNewPointToPseudoPath(new Point(0, 0, 'Z'))
        this.$_mx_draw_addObjectToProject(this.$_mx_draw_currentPath)
        this.mx_draw_reset()
      }
    },
    /**
         * Reset mouse and Path and finish or break history transaction
         * @public
         */
    mx_draw_reset () {
      const shouldBeDeleted = (this.$_mx_draw_currentPath.getPoints().length < 2)
      this.$_mx_draw_setPseudoMouse({ x1: 0, y1: 0, x2: 0, y2: 0 })
      this.$_mx_draw_setPseudoPath(null)
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
      shouldBeDeleted ? this.mx_trackstate_breakStateTransaction() : this.mx_trackstate_endStateTransaction()
    }
  },
  watch: {
    $_mx_draw_currentPath (val, oldVal) {
      if (val && val.points.length === 0 && oldVal && oldVal.points.length > 0) {
        this.mx_draw_reset()
      }
    }
  }
}
