<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.requestError.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.requestError.title') "
    :style-dialog-z-index="2000"
  >
    <template #header />

    <template #content>
      <div
        v-if="error instanceof Object"
        class="c-dialog__instruction c-dialog__instruction"
      >
        <h3>{{ error.status }}: {{ error.statusText }}</h3><br>
        <p v-if="error.data && error.data.message ">
          {{ error.data.message }}
        </p>
        <template v-if="error.data && error.data.errors">
          <div v-if="Array.isArray(error.data.errors)">
            <p
              v-for="(issue, index) in error.data.errors"
              :key="index"
            >
              {{ issue }}
            </p>
          </div>
          <div v-else-if="typeof error.data.errors === 'object'">
            <div
              v-for="(err, key) in error.data.errors"
              :key="key"
              class="u-mt-size-20"
            >
              <div v-if="typeof err === 'object' && key.includes('LINE')">
                <span class="c-typo__h5">{{ $t('errors.materials.row') }} {{ Number.parseInt(key.toString().substring(5)) + 2 }}</span>
                <ul class="u-ml-size-30 u-mt-size-5">
                  <li
                    v-for="(field, fKey) in err"
                    :key="fKey"
                  >
                    <span
                      class="u-color-b1"
                    >{{ fKey }}: </span>
                    <span v-if="Array.isArray(field)">
                      <template
                        v-for="(item, iKey) in field"
                      >
                        <span
                          :key="iKey"
                        >{{ item }}</span>
                      </template>
                    </span>
                  </li>
                </ul>
              </div>
              <div v-else>
                {{ err }}
              </div>
            </div>
          </div>
          <div v-else>
            <p>{{ error.data.errors }}</p>
          </div>
        </template>
      </div>
      <div
        v-else
        class="c-dialog__instruction c-dialog__instruction"
      >
        <p>{{ error }}</p>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child c-dialog__confirm-child--flex-center">
          <button-base
            id="request-error-dialog-ok"
            :label="$t('common.ok')"
            :on-click-action="() => { return close() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * The RequestError Component.
 * @displayName Request Error
 */

export default {
  name: 'RequestError',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'requestError',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      error: state => state.backendAPI.error
    })
  },
  methods: {
    close () {
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
