<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.updateClient.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.updateClient.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form
          v-if="client !== null"
          class="c-form"
        >
          <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
            <!--<div class="o-grid__col u-2/12 u-text-center">
                            Logo
                        </div>-->
            <div class="c-dialog__instruction-col u-text-center u-pl-size-30">
              <drop-down
                id="update-client-salutation"
                v-model="client.salutation"
                :items="mx_languages_getSalutations"
                :label="$t('dialogs.createClient.salutation')"
                :required="true"
                :style-up="false"
                classes="u-mt-size-standard"
                label-key="label"
                style-current-item-width="240px"
                value-key="value"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-name"
                v-model="client.firstname"
                :focus-on-mount="true"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-surname"
                v-model="client.lastname"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.surname')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-email"
                v-model="client.email"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.email')"
                :validate="(v) => {return mx_validate_validateEmail(v) || mx_validate_validateEmpty(v)}"
                placeholder="email@company.com"
                style-input-width="240px"
                type="email"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-address-phone"
                v-model="client.address.phone"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.address.phone')"
                :validate="(v) => {return mx_validate_validatePhone(v) || mx_validate_validateEmpty(v)}"
                placeholder="+49 1234 567 890"
                style-input-width="240px"
                type="tel"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />

              <drop-down
                v-if="isDistributor"
                id="create-distributor-group"
                v-model="client.group.id"
                :disabled="true"
                :items="getLocations"
                :label="$t('dialogs.createClient.location')"
                :required="true"
                :style-up="true"
                classes="u-mt-size-30"
                label-key="name"
                style-current-item-width="240px"
                value-key="id"
              />
            </div>
            <div class="c-dialog__instruction-col u-text-center">
              <input-field
                id="update-client-address-name"
                v-model="client.address.name"
                :is-store-mode="false"
                :label="$t('dialogs.createClient.address.name')"
                :validate="(v) => {return true}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-address-street"
                v-model="client.address.street"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.address.street')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-address-city"
                v-model="client.address.city"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.address.city')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-address-zip"
                v-model="client.address.zip"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.address.zip')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-address-state"
                v-model="client.address.state"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.address.state')"
                :validate="(v) => {return true}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="update-client-address-country"
                v-model="client.address.country"
                :is-store-mode="false"
                :label="$t('dialogs.updateClient.address.country')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="update-client-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="update-client-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return update() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import config from '@/config'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinLanguages from '@/mixins/languages'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'
import { roles } from '@/helper/apiHelper'

/**
 * @displayName Dialog Update Client
 */
export default {
  name: 'UpdateClient',
  components: {
    ButtonBase,
    DropDown,
    DialogFrame,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinLanguages
  ],
  data: () => {
    return {
      targetDialog: 'updateClient',
      cannotClose: false,
      client: null,
      config: config
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.updateClient,
      visible: state => state.dialogs.visible
    }),
    ...mapGetters({
      getMyRole: 'backendAPI/user/getRole',
      getLocations: 'backendAPI/location/getAll'
    }),
    isDistributor () {
      return this.getMyRole === roles.distributor
    },
    isVisible () {
      return this.visible[this.targetDialog]
    }
  },
  watch: {
    isVisible: {
      handler (n, o) {
        if (n === true) {
          this.editClient(this.dialogProps.clientData.id)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      readClient: 'backendAPI/client/read',
      updateClient: 'backendAPI/client/update'
    }),
    async editClient (clientId) {
      this.client = await this.readClient(clientId)
    },
    async update () {
      const isUpdated = await this.updateClient(this.client)
      if (isUpdated) {
        this.$events.fire('ASYNCLIST_CLIENT_UPDATED')
        this.client = null
      }
      this.close()
    },
    close () {
      this.client = null
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
