<template>
  <div
    :id="id"
    :class="classes"
    class="c-file-upload"
  >
    <input
      :id="id + 'file-upload-input'"
      :accept="accept"
      :multiple="multiple"
      class="c-file-upload__input--hide"
      type="file"
      @change="processFile"
    >
    <label
      ref="inputLabel"
      class="c-button-base c-button-base--color-blue c-button-base--size-xs u-ml-size-0"
      :for="id + 'file-upload-input'"
    >
      {{ getButtonText }}
    </label>

    <div
      :id="id + '-drop-area'"
      :class="dropContainerClasses"
      :style="`width: ${styleDropAreaWidth}; height: ${styleDropAreaHeight}`"
      @dragover="handleDragOver($event)"
      @drop="processFile($event)"
      @click="$refs.inputLabel.click()"
    >
      <!-- Files-->
      <div
        v-if="hasSomethingToDisplay"
        :style="dropInnerContainerStyle"
        class="c-file-upload__drop-area-inner"
      >
        <div
          v-for="(file, index) in localFiles"
          v-show="!Object.hasOwnProperty.call(file,'toDelete') || !file.toDelete"
          :id="id + '-file-' + index"
          :key="index"
          :style="fileStyle(file)"
          class="c-file-upload__file"
        >
          <button-icon
            v-if="multiple"
            :id="id + '-drop-area-delete-' + index"
            classes="c-file-upload__file-delete"
            icon-classes="fas fa-times"
            style-color="darker"
            style-font-size="xl"
            @click="$_setToDelete(index)"
            @click.native="(e) => e.stopPropagation()"
          />
          <icon
            v-if="!file.isImage"
            classes="fas fa-3x fa-file u-color-s2"
            style-width="24px"
            style-height="24px"
          />
          <div
            v-if="file.sizeHumanReadable || file.format"
            class="c-file-upload__file-info"
          >
            <span
              v-if="file.errors && file.errors.length"
              class="u-color-o"
            >
              <v-popover
                popover-base-class="tooltip popover tooltip-above-dialog"
                popover-inner-class="popover-inner popover-no-pointer-events"
              >
                <icon
                  classes="fas fa-2x fa-exclamation-triangle"
                  style-width="24px"
                  style-height="24px"
                />
                <!-- Popover content -->
                <template #popover>
                  <div class="c-tooltip">
                    <ul>
                      <li
                        v-for="(error, eIndex) in file.errors"
                        :key="eIndex"
                        class="u-ml-size-20"
                      >{{ error }}</li>
                    </ul>
                  </div>
                </template>
              </v-popover>
            </span>
            <template v-else>
              <span v-if="file.name">{{ file.name }}</span>
              <span v-if="file.sizeHumanReadable">{{ file.sizeHumanReadable }}</span>
              <span v-if="file.type">{{ file.type }}</span>
            </template>
          </div>
        </div>
      </div>
      <!-- Total max file size warning -->
      <span
        v-if="fileTotalMaxSize && totalSize > fileTotalMaxSize"
        class="c-file-upload__warning-total-size u-color-o"
      >
        <v-popover
          popover-base-class="tooltip popover tooltip-above-dialog"
          popover-inner-class="popover-inner popover-no-pointer-events"
        >
          <icon
            classes="fas fa-2x fa-exclamation-triangle"
            style-width="24px"
            style-height="24px"
          />
          <!-- Popover content -->
          <template #popover>
            <div class="c-tooltip">
              {{ getTexts.fileTotalMaxSize }}
            </div>
          </template>
        </v-popover>
      </span>
      <div
        v-if="!hasSomethingToDisplay"
        class="c-file-upload__drop-area-description"
      >
        <p
          class="c-typo__h3"
          v-html="getDefaultText.main"
        />
        <p
          v-html="getDefaultText.instructions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import ButtonIcon from '@/components/common/ButtonIcon'
import Icon from '@/components/common/Icon'

/**
 * The FileUpload Component.
 * @displayName File Upload
 */

export default {
  name: 'FileUpload',
  components: {
    Icon,
    ButtonIcon
  },
  props: {
    /**
     * String list of file types which the field accepts
     * e.g. image/png,image/jpeg,...
     * complete list of standard media types: http://www.iana.org/assignments/media-types/media-types.xhtml
     * @values any_file_type, audio/*, video/*, image/*, media_type
     */
    accept: {
      type: String,
      required: false,
      default: 'image/*'
    },
    /**
     * Additional CSS classes
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Current existing image if there's one already (takes path / URL)
     * <b>Awaited structure of every incoming file</b>
     * <pre>{
     *    id - {number} file id; it can be faked
     *                  and doesn't have to be unique
     *                  if later not needed
     *    name - {string} file name to show
     *    size - {number} size in Bytes
     *    type - {string} file type; e.g. 'image/jpeg'
     *    url - {string} URL or blob
     *    conversions: - {object} (optional)
     *       {
     *          thumb - {string} URL or blob
     *       }
     *  }</pre>
     */
    current: {
      type: [Array, Object],
      required: false,
      default: null
    },
    /**
     * Defines file size limit (in MB)
     * @values null, number
     */
    fileMaxSize: {
      type: Number,
      required: false,
      default: 1
    },
    /**
     * Defines summary file size limit (in MB)
     * @values null, number
     */
    fileTotalMaxSize: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * Defines image max height
     */
    imageMaxHeight: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * Defines image min height
     */
    imageMinHeight: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * Defines image max width
     */
    imageMaxWidth: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * Defines image min width
     */
    imageMinWidth: {
      type: Number,
      required: false,
      default: null
    },
    /**
     * Defines the drop area text
     */
    labelDefaultMessage: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines file size limit text
     */
    labelFileMaxSize: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines total file size limit text
     */
    labelFileTotalMaxSize: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines image max height text
     */
    labelImageMaxHeight: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines image min height text
     */
    labelImageMinHeight: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines image max width text
     */
    labelImageMaxWidth: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines image min width text
     */
    labelImageMinWidth: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines the drop area width
     */
    styleDropAreaWidth: {
      type: String,
      required: false,
      default: '100%'
    },
    /**
     * Defines the drop area height
     */
    styleDropAreaHeight: {
      type: String,
      required: false,
      default: '100%'
    },
    /**
     * Defines if only one or many file should be able to upload
     */
    multiple: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => {
    return {
      localFiles: [],
      totalSize: 0
    }
  },
  computed: {
    hasLocalFiles () {
      return !_.isEmpty(this.localFiles)
    },
    /**
     * Informs about the number of images to display
     * @returns {number} 0 while nothing to show
     */
    hasSomethingToDisplay () {
      const toDisplay = this.localFiles.filter((file) => {
        return (Object.hasOwnProperty.call(file, 'toDelete')) ? !file.toDelete : true
      })
      return toDisplay.length
    },
    dropContainerClasses () {
      return [
        'c-file-upload__drop-area',
        this.hasSomethingToDisplay ? 'c-file-upload__drop-area--file-loaded' : ''
      ]
    },
    dropInnerContainerStyle () {
      let inlineStyle = ''

      inlineStyle += !this.multiple ? 'width: 100%' : ''

      return inlineStyle
    },
    fileStyle: function () {
      return (file) => {
        let inlineStyle = ''

        inlineStyle += this.multiple
          ? `width: calc(${this.styleDropAreaHeight} - 36px);`
          : `width: calc(${this.styleDropAreaWidth});`
        inlineStyle += `min-width: calc(${this.styleDropAreaHeight} - ${this.hasSomethingToDisplay > 1 ? '36px' : '24px'});`
        inlineStyle += `height: calc(${this.styleDropAreaHeight} - ${this.hasSomethingToDisplay > 1 ? '36px' : '24px'});`
        inlineStyle += file.isImage ? `background: url(${file.path})` : ''

        return inlineStyle
      }
    },
    getTexts () {
      const textsPath = 'components.fileUpload'

      const chooseFile = this.$i18n.t(`${textsPath}.addImage.chooseFile`)
      const change = this.$i18n.t(`${textsPath}.addImage.change`)
      const chooseFiles = this.$i18n.t(`${textsPath}.addImage.chooseFiles`)
      const defaultMessage = this.$i18n.t(`${textsPath}.dropArea.dropFile`)
      const fileMaxSize = this.$i18n.t(`${textsPath}.file.fileMaxSize`, { value: this.fileMaxSize })
      const fileTotalMaxSize = this.$i18n.t(`${textsPath}.file.fileTotalMaxSize`, { value: this.fileTotalMaxSize })
      const imageMaxHeight = this.$i18n.t(`${textsPath}.file.imageMaxHeight`, { value: this.imageMaxHeight })
      const imageMinHeight = this.$i18n.t(`${textsPath}.file.imageMinHeight`, { value: this.imageMinHeight })
      const imageMaxWidth = this.$i18n.t(`${textsPath}.file.imageMaxWidth`, { value: this.imageMaxWidth })
      const imageMinWidth = this.$i18n.t(`${textsPath}.file.imageMinWidth`, { value: this.imageMinWidth })

      return {
        chooseFile,
        change,
        chooseFiles,
        defaultMessage: this.labelDefaultMessage ? this.labelDefaultMessage : defaultMessage,
        fileMaxSize: this.labelFileMaxSize ? this.labelFileMaxSize : fileMaxSize,
        fileTotalMaxSize: this.labelFileTotalMaxSize ? this.fileTotalMaxSize : fileTotalMaxSize,
        imageMaxHeight: this.labelImageMaxHeight ? this.labelImageMaxHeight : imageMaxHeight,
        imageMinHeight: this.labelImageMinHeight ? this.labelImageMinHeight : imageMinHeight,
        imageMaxWidth: this.labelImageMaxWidth ? this.labelImageMaxWidth : imageMaxWidth,
        imageMinWidth: this.labelImageMinWidth ? this.labelImageMinWidth : imageMinWidth
      }
    },
    getButtonText () {
      if (this.multiple) {
        return this.getTexts.chooseFiles
      } else {
        return this.hasSomethingToDisplay ? this.getTexts.change : this.getTexts.chooseFile
      }
    },
    getDefaultText () {
      let main = ''
      let instructions = ''

      main += this.getTexts.defaultMessage
      instructions += this.fileMaxSize ? this.getTexts.fileMaxSize + '<br>' : ''
      instructions += this.fileTotalMaxSize ? this.getTexts.fileTotalMaxSize + '<br>' : ''
      instructions += this.imageMinWidth ? this.getTexts.imageMinWidth + '<br>' : ''
      instructions += this.imageMaxWidth ? this.getTexts.imageMaxWidth + '<br>' : ''
      instructions += this.imageMinHeight ? this.getTexts.imageMinHeight + '<br>' : ''
      instructions += this.imageMaxHeight ? this.getTexts.imageMaxHeight + '<br>' : ''

      return { main, instructions }
    }
  },
  watch: {
    localFiles: {
      handler (val) {
        this.totalSize = val.map(file => file.size).reduce((elem, acc) => {
          const tmpTS = Number.parseFloat(acc) + Number.parseFloat(elem)
          return this.$_getCeilDecimal(tmpTS)
        }, 0)
        /**
         * Triggers on change in localFiles (add, delete)
         *
         * @event change
         * @property {{id: string, value: {}, error: boolean}} payload - Emitted payload
         * @property {string} id - ID of this component (also used in html and tests)
         * @property {{ newFiles: File[], toDelete: number[]}} value - object containing new files and those to delete
         * @property {boolean} error - true if anyone from files reports an error
         */
        this.$emit('change', this.$_prepareOutput())
      },
      deep: true
    }
  },
  mounted () {
    this.loadCurrentFiles()
    /**
     * Triggers on 'mounted' to register the component in 'validate' mixin
     *
     * @event register
     * @property {{id: string, value: {}, error: boolean}} payload - Emitted payload
     * @property {string} id - ID of this component (also used in html and tests)
     * @property {{ newFiles: File[], toDelete: number[]}} value - object containing new files and those to delete
     * @property {boolean} error - always false
     */
    this.$emit('register', this.$_prepareOutput())
  },
  destroyed () {
    this.localFiles = []
  },
  methods: {
    loadCurrentFiles () {
      if (!_.isEmpty(this.current)) {
        // Array
        if (_.isArray(this.current)) {
          for (const [i, file] of this.current.entries()) {
            if (typeof file === 'object') {
              this.$_setLocalFilesFromCurrent(file)
            } else {
              this.$devLog.error(`wrong type of data in "current" on position: ${i}`)
            }
          }
          // String
        } else if (typeof this.current === 'object') {
          this.$_setLocalFilesFromCurrent(this.current)
          // Any other
        } else {
          this.$devLog.error('wrong type of data in "current"')
        }
      }
    },
    handleDragOver (evt) {
      evt.stopPropagation()
      evt.preventDefault()

      evt.dataTransfer.dropEffect = 'copy'
    },
    async processFile (evt) {
      evt.stopPropagation()
      evt.preventDefault()

      let fileList = null
      // selected files from explorer/finder
      if (evt.target.files !== undefined && evt.target.files.length > 0) {
        fileList = evt.target.files
      }
      // drag'n'drop-ed files
      if (evt.dataTransfer !== undefined && evt.dataTransfer.files.length > 0) {
        fileList = evt.dataTransfer.files
      }
      if (fileList !== null && fileList.length) {
        if (this.multiple) {
          for (const file of fileList) {
            await this.$_setLocalFilesFromDisc(file)
          }
        } else {
          // if only one file is accepted, the possibly existing current file (from server)
          // will be saved and later set as 'to delete'
          const firstFile = this.localFiles.length ? this.localFiles[0] : null
          this.localFiles = []
          if (firstFile) {
            this.localFiles.push(firstFile)
            this.$_setToDelete(0)
          }
          await this.$_setLocalFilesFromDisc(fileList[0])
        }
      }
    },

    /** ------------------------------------------
     *  Helpers
     *------------------------------------------ */

    /**
     * Prepares local files array from data added locally
     */
    async $_setLocalFilesFromDisc (file) {
      const path = URL.createObjectURL(file)
      const name = this.$_getFileName(file.name)
      const sizeHumanReadable = this.$_getFileSize(file.size, true)
      const size = this.$_getFileSize(file.size, false)
      const type = this.$_getFileType(file.type)
      const errors = await this.$_validateFile(file)
      const isImage = this.$_isImage(file)
      this.localFiles.push({
        file: file,
        errors,
        isImage,
        path,
        name,
        sizeHumanReadable,
        size,
        type
      })
    },
    /**
     * Prepares local files array from current files (incoming from server)
     */
    $_setLocalFilesFromCurrent (file) {
      const path = this.$_getFilePath(file)
      const name = file.name
      const sizeHumanReadable = this.$_getFileSize(file.size, true)
      const size = this.$_getFileSize(file.size, false)
      const type = this.$_getFileType(file.type)
      const isImage = this.$_isImage(file)
      this.localFiles.unshift({
        id: file.id,
        toDelete: false,
        isImage,
        path,
        name,
        sizeHumanReadable,
        size,
        type
      })
    },
    /**
     * Get file name from name with extension
     */
    $_getFileName (file) {
      return file.substring(0, file.lastIndexOf('.'))
    },
    /**
     * Get file path
     */
    $_getFilePath (file) {
      let path = ''

      if (Object.hasOwnProperty.call(file, 'conversions')) {
        if (Object.hasOwnProperty.call(file, 'thumb')) {
          path = file.conversions.thumb
        }
      }
      if (path === '' && Object.hasOwnProperty.call(file, 'url')) {
        path = file.url
      }

      return path
    },
    /**
     * Get size with unit suffix
     *
     * @return {String}
     */
    $_getFileSize (size, humanReadable = false) {
      if (humanReadable) {
        if (size < 1024) {
          return size + ' B'
        } else if (size >= 1024 && size < 1048576) {
          return this.$_getCeilDecimal(size / 1024) + ' KB'
        } else if (size >= 1048576) {
          return this.$_getCeilDecimal(size / 1048576) + ' MB'
        }
      } else {
        return this.$_getCeilDecimal(size / 1048576).toString()
      }
    },
    /**
     * Get ceil of a given number rounded to a decimal place
     *
     * @return {Number}
     */
    $_getCeilDecimal (value, decimals = 1) {
      return Number((String(value).indexOf('e') === -1 ? Math.ceil(Number(String(value) + 'e' + decimals)) : Math.round(value)) + 'e-' + decimals)
    },
    /**
     * Get file format from format (sample: image/png)
     *
     * @return {String}
     */
    $_getFileType (type) {
      return type.substring(type.lastIndexOf('/') + 1, type.length).toUpperCase()
    },
    /**
     * Returns true if type of the file matches 'image'-regex
     *
     * @return {Boolean}
     */
    $_isImage (file) {
      return (/.*(gif|jpe?g|bmp|png)$/i).test(file.type)
    },
    /**
     * Validates a file according to all input rules
     *
     * @return {Promise<Array>}
     */
    $_validateFile (file) {
      return new Promise((resolve, reject) => {
        let errors = []
        const isImage = this.$_isImage(file)
        if (isImage) {
          if (this.imageMaxHeight || this.imageMinHeight || this.imageMaxWidth || this.imageMinWidth) {
            const image = new Image()
            const objectUrl = URL.createObjectURL(file)
            image.src = objectUrl

            image.onload = () => {
              errors = this.$_generateErrors(file, image)
              URL.revokeObjectURL(objectUrl)
              resolve(errors)
            }
          } else {
            errors = this.$_generateErrors(file)
            resolve(errors)
          }
        } else {
          errors = this.$_generateErrors(file)
          resolve(errors)
        }
      })
    },
    /**
     * Helper function for validating
     * Checks all possible conditions and builds an array with errors
     *
     * @return {Array}
     */
    $_generateErrors (file, image = null) {
      const errors = []
      if (image && this.imageMaxHeight && (image.height > this.imageMaxHeight)) {
        errors.push(this.getTexts.imageMaxHeight)
      }
      if (image && this.imageMinHeight && (image.height < this.imageMinHeight)) {
        errors.push(this.getTexts.imageMinHeight)
      }
      if (image && this.imageMaxWidth && (image.height > this.imageMaxWidth)) {
        errors.push(this.getTexts.imageMaxWidth)
      }
      if (image && this.imageMinWidth && (image.height < this.imageMinWidth)) {
        errors.push(this.getTexts.imageMinWidth)
      }
      if (this.fileMaxSize && ((file.size / 1024 / 1024) > this.fileMaxSize)) {
        errors.push(this.getTexts.fileMaxSize)
      }
      return errors
    },
    /**
     * Sets an object to delete if it's coming from current files (server)
     * or removing it if it's a local file
     */
    $_setToDelete (index) {
      const file = this.localFiles[index]
      if (Object.hasOwnProperty.call(file, 'id')) {
        this.localFiles[index].toDelete = true
      } else {
        this.localFiles.splice(index, 1)
      }
    },
    /**
     * Prepares an object with two arrays:
     * - new files
     * - indexes of files to delete
     *
     * @return {{ id: string, value: { newFiles: File[], toDelete: number[]}, error: boolean }}
     */
    $_prepareOutput () {
      const newFiles = this.localFiles
        .filter(file => Object.hasOwnProperty.call(file, 'file'))
        .map(file => file.file)
      const toDelete = this.localFiles
        .filter(file => Object.hasOwnProperty.call(file, 'toDelete') && file.toDelete)
        .map(file => file.id)
      const errorsArray = this.localFiles
        .filter(file => Object.hasOwnProperty.call(file, 'errors') && !_.isEmpty(file.errors))
        .map(file => file.errors)
      if (this.fileTotalMaxSize && this.totalSize > this.fileTotalMaxSize) {
        errorsArray.push(this.getTexts.fileTotalMaxSize)
      }
      const error = !_.isEmpty(errorsArray)

      return {
        id: this.id,
        value: {
          newFiles,
          toDelete
        },
        error
      }
    }
  }
}
</script>
