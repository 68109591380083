const defaultState = () => {
  return {
    themes: {
      dark: {
        b0: '202, 227, 254',
        b1: '100, 207, 255',
        b2: '74, 166, 255',
        b3: '50, 126, 255',
        g0: '226, 248, 179',
        g1: '174, 212, 91',
        g2: '155, 185, 55',
        g3: '111, 151, 24',
        g: '120, 190, 32',
        o: '255, 151, 0',
        b: '0, 246, 255',
        w: '255, 255, 255',
        w2: '236, 236, 236',
        s1: '178, 178, 178',
        s2: '128, 128, 128',
        s3: '77, 77, 77',
        s4: '51, 51, 51',
        s5: '39, 39, 39',
        s6: '0, 0, 0'
      },
      light: {
        b0: '120, 166, 217',
        b1: '51, 143, 234',
        b2: '50, 117, 181',
        b3: '21, 81, 185',
        g0: '120, 166, 217',
        g1: '51, 143, 234',
        g2: '50, 117, 181',
        g3: '21, 81, 185',
        g: '120, 190, 32',
        o: '255, 84, 0',
        b: '0, 246, 255',
        w: '79, 79, 79',
        w2: '236, 236, 236',
        s1: '79, 79, 79',
        s2: '159, 159, 159',
        s3: '213, 213, 213',
        s4: '187, 187, 187',
        s5: '236, 236, 236',
        s6: '255, 255, 255'
      }
    }
  }
}

const state = defaultState()

const getters = {
  /**
   * Get current theme
   *
   * @param state
   * @param getters
   * @param rootState
   * @returns {string}
   */
  getTheme (state, getters, rootState) {
    const group = rootState.backendAPI.user.me.group
    if (group !== null && group.theme != null &&
      Object.prototype.hasOwnProperty.call(group.theme, 'theme')) {
      return group.theme.theme
    }
    return 'dark'
  }
}

const actions = {
  /**
   * Sets a theme
   *
   * @param state
   * @param {string} theme
   */
  setTheme ({ state }, theme = 'dark') {
    const formattedColors = {}

    for (const [name, color] of Object.entries(state.themes[theme])) {
      if (Object.prototype.hasOwnProperty.call(state.themes[theme], name)) {
        const colorArray = color.split(',')
        const colorR = colorArray[0].trim()
        const colorG = colorArray[1].trim()
        const colorB = colorArray[2].trim()

        formattedColors[`--${name}`] = `rgb(${colorR}, ${colorG}, ${colorB})`
        formattedColors[`--${name}-r`] = colorR
        formattedColors[`--${name}-g`] = colorG
        formattedColors[`--${name}-b`] = colorB
      }
    }

    const root = document.documentElement

    for (const [name, value] of Object.entries(formattedColors)) {
      root.style.setProperty(name, value)
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
