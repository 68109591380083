import Paper from 'paper'

const paperHelper = {
  prepare_tracks (tracks) {
    const dataObjects = []

    tracks.forEach(track => {
      dataObjects.push({ name: track.name, path: track.getPathData(), oversize: track.oversize, length: track.length })
    })

    return dataObjects
  },
  prepare_movables_by_tracks (tracks, settings) {
    const cp = new Paper.CompoundPath()
    const children = paperHelper.remap_tracks(tracks)

    cp.addChildren(children)

    const intersections = cp.getIntersections()
    const lineStorage = []
    const endsStorage = []

    intersections.forEach(intersection => {
      // angle to check against
      let angle = (Number(settings.angle) > 180) ? Number(settings.angle) - 180 : Number(settings.angle)
      angle = Math.abs(Number(angle.toFixed(2)))
      // intersecting path
      const intersectionA = intersection.path
      const intersectionB = intersection.intersection.path

      // get unique intersection points
      const uniquePoints = paperHelper.get_unique_points(intersection.curve.points)

      // hit test
      const hitTest = paperHelper.points_hit_tracks(intersectionA, intersectionB, uniquePoints)

      if (hitTest) {
        // get angle between points (pointA -> pointB and pointB -> pointA)
        const angleA = paperHelper.get_angle_by_points(uniquePoints[0], uniquePoints[1])
        const angleB = paperHelper.get_angle_by_points(uniquePoints[1], uniquePoints[0])

        // get original tracks
        const trackA = paperHelper.get_track_by_name(tracks, intersectionA.name)
        const trackB = paperHelper.get_track_by_name(tracks, intersectionB.name)

        // is path end movable
        const movableA = Number(trackA.length) < Number(settings.length)
        const movableB = Number(trackB.length) < Number(settings.length)

        if ((angle === angleA || angle === angleB) && (movableA || movableB)) {
          const newTrackEnd = {
            p1: uniquePoints[0],
            p2: uniquePoints[1]
          }

          if (paperHelper.is_unique_track_end(lineStorage, newTrackEnd)) {
            lineStorage.push(newTrackEnd)

            const anchor = paperHelper.create_track_end_anchor(intersectionA, intersectionB, settings.angle)

            endsStorage.push({
              id: `${intersectionA.name}|${intersectionB.name}`,
              p1: newTrackEnd.p1,
              p2: newTrackEnd.p2,
              anchor: anchor,
              path: `M${newTrackEnd.p1.x},${newTrackEnd.p1.y}L${newTrackEnd.p2.x},${newTrackEnd.p2.y}Z`,
              trackA: {
                path: trackA.path,
                name: trackA.name
              },
              trackB: {
                path: trackB.path,
                name: trackB.name
              },
              type: 'trackCutLine'
            })
          }
        }
      }
    })

    return endsStorage
  },
  remap_tracks (tracks) {
    return tracks.map(track => {
      const p = new Paper.Path(track.path)
      p.name = track.name

      return p
    })
  },
  get_unique_points (points) {
    return points.map(p => {
      return { x: p.x, y: p.y }
    }).map(JSON.stringify).reverse()
      .filter((item, index, points) => points.indexOf(item, index + 1) === -1)
      .reverse().map(JSON.parse)
  },
  get_angle_by_points (v1, v2, offset = 0) {
    const srX = (v2.x - v1.x)
    const srY = (v2.y - v1.y)

    const angle = (Math.atan2(srY, srX) * 180 / Math.PI) - offset

    return Math.abs(Number(angle.toFixed(2)))
  },
  get_track_by_name (tracks, name) {
    return tracks.find(track => track.name === name)
  },
  points_hit_tracks (t1, t2, points) {
    return t1.hitTest(new Paper.Point(points[0])) !== null &&
            t1.hitTest(new Paper.Point(points[1])) !== null &&
            t2.hitTest(new Paper.Point(points[0])) !== null &&
            t2.hitTest(new Paper.Point(points[1])) !== null
  },
  is_unique_track_end (trackEnds, trackEnd) {
    const tE = trackEnds.map(JSON.stringify).reverse()
    const t = JSON.stringify(trackEnd)

    return tE.indexOf(t) === -1
  },
  /**
     *
     * @param {paper.Path | paper.CompoundPath} t1
     * @param {paper.Path | paper.CompoundPath} t2
     * @param {Number} angle
     * @returns {string}
     */
  create_track_end_anchor (t1, t2, angle) {
    const el = t1.unite(t2)
    el.rotate(-angle)

    const anchor = new Paper.Path.Line({
      from: el.bounds.topLeft,
      to: el.bounds.bottomLeft
    })
    anchor.rotate(angle, el.bounds.center)

    return anchor.pathData
  },
  /**
     *
     * @param {paper.Path | paper.CompoundPath} a
     * @param {paper.Path | paper.CompoundPath} b
     */
  is_inside (a, b) {
    const intersect = a.intersect(b)
    const isValid = (a.isInside(b.bounds) && intersect.segments.length > 0)

    intersect.remove()

    return isValid
  }
}

export default paperHelper
