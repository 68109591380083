import worldCurrencySymbolsList from '@/worldCurrencySymbolsList'
import currency from 'currency.js'

function worldPlugin (Vue) {
  if (worldPlugin.installed) return

  const world = new Vue({
    data: () => {
      return {
        currency: null,
        currencyConfig: worldCurrencySymbolsList.currencyConfig.default
      }
    },
    methods: {
      initCurrency (code) {
        this.currency = worldCurrencySymbolsList.currency.find(el => el.code === code)

        const config = worldCurrencySymbolsList.currencyConfig[this.currency.code]
        this.currencyConfig = config ?? worldCurrencySymbolsList.currencyConfig.default
        this.currencyConfig.symbol = `${this.currency.symbol} `
      },
      currencyConvert (value, symbol = false) {
        return currency(value, this.currencyConfig).format(symbol)
      },
      getCurrencyList () {
        return worldCurrencySymbolsList.currency
      },
      getCurrencySymbol () {
        return this.currency.symbol
      },
      getCurrencySymbolByCode (code) {
        const obj = worldCurrencySymbolsList.currency.find(el => el.code === code)
        return obj?.symbol ?? ''
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$world', {
    get () {
      return world
    }
  })

  Vue.world = Vue.prototype.$world
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(worldPlugin)
}

export default worldPlugin
