import * as types from '@/vuex/mutation-types'
import config from '@/config'

const getValueByAspectRatio = (state, value) => {
  return (value / state.aspectRatio) / state.scale
}

/**
 * Calculates and returns mouse x and y values for current scale and the new scale value when zooming.
 * The current panning values are subtracted so that the x and y panning can be increased or decreased by the returned x and y values.
 * @see SET_VIEWBOX_ZOOM_IN, SET_VIEWBOX_ZOOM_OUT mutations
 * @param state
 * @param coordinate
 * @param newScale
 */
const calculateAndSetPanning = (state, coordinate = null, newScale) => {
  if (coordinate !== null) {
    const dxa = ((coordinate.event.clientX - state.center.x - state.offset.x) / state.scale) - state.pan.x
    const dya = ((coordinate.event.clientY - state.center.y - state.offset.y) / state.scale) - state.pan.y
    const dxb = ((coordinate.event.clientX - state.center.x - state.offset.x) / newScale) - state.pan.x
    const dyb = ((coordinate.event.clientY - state.center.y - state.offset.y) / newScale) - state.pan.y
    state.pan.x += (dxb - dxa) / 2
    state.pan.y += (dyb - dya) / 2
  }
  state.scale = newScale
}

const state = {
  x: 0,
  y: 0,
  w: 0,
  h: 0,
  aspectRatio: 1,
  panAmount: 50,
  pan: {
    x: 0,
    y: 0
  },
  lastPanDelta: {
    x: 0,
    y: 0
  },
  scale: 1.0,
  scaleAmount: 0.1,
  transformMatrix: {
    zf1: 1.0,
    b: 0,
    c: 0,
    zf2: 1.0,
    panX: 0,
    panY: 0
  },
  center: {
    x: 0,
    y: 0
  },
  offset: {
    x: 0,
    y: 0
  }
}

const getters = {
  x (state) {
    return ((-1 * state.pan.x / 0.5)) + (state.center.x - ((state.w / 2) / state.scale))
  },
  y (state) {
    return ((-1 * state.pan.y / 0.5)) + (state.center.y - ((state.h / 2) / state.scale))
  },
  w (state) {
    return state.w / state.scale
  },
  h (state) {
    return state.h / state.scale
  },
  center () {
    return state.center
  },
  pan () {
    return state.pan
  },
  scale (state) {
    return `scale(${state.scale})`
  },
  mouseX (state) {
    return ((state.x - state.pan.x) / state.scale)
  },
  mouseY (state) {
    return ((state.y - state.pan.y) / state.scale)
  },
  matrix (state) {
    return `matrix(${Object.values(state.transformMatrix).join(' ')})`
  }
}

const actions = {
  properties ({ dispatch, commit }, props) {
    commit(types.SET_VIEWBOX_X, props.x)
    commit(types.SET_VIEWBOX_Y, props.y)
    commit(types.SET_VIEWBOX_W, props.w)
    commit(types.SET_VIEWBOX_H, props.h)
    const ar = props.w / props.h
    commit(types.SET_VIEWBOX_ASPECT_RATIO, ar)
    const cx = props.w / 2
    const cy = props.h / 2
    commit(types.SET_VIEWBOX_CX, cx)
    commit(types.SET_VIEWBOX_CY, cy)
    commit(types.SET_VIEWBOX_OX, props.ox)
    commit(types.SET_VIEWBOX_OY, props.oy)
  },
  origin ({ commit }) {
    commit(types.SET_VIEWBOX_ORIGIN)
  },
  pan ({ dispatch, commit }, props) {
    commit(types.SET_VIEWBOX_PAN_X, props.x)
    commit(types.SET_VIEWBOX_PAN_Y, props.y)
  },
  move_pan_x ({ commit }, payload) {
    commit(types.SET_VIEWBOX_PAN_X, payload)
  },
  move_pan_y ({ commit }, payload) {
    commit(types.SET_VIEWBOX_PAN_Y, payload)
  }
}

const mutations = {
  [types.SET_VIEWBOX_X] (state, x) {
    state.x = x
  },
  [types.SET_VIEWBOX_Y] (state, y) {
    state.y = y
  },
  [types.SET_VIEWBOX_W] (state, w) {
    state.w = w
  },
  [types.SET_VIEWBOX_H] (state, h) {
    state.h = h
  },
  [types.SET_VIEWBOX_ASPECT_RATIO] (state, ar) {
    state.aspectRatio = ar
  },
  [types.SET_VIEWBOX_CX] (state, cx) {
    state.center.x = cx
  },
  [types.SET_VIEWBOX_CY] (state, cy) {
    state.center.y = cy
  },
  [types.SET_VIEWBOX_OX] (state, ox) {
    state.offset.x = ox
  },
  [types.SET_VIEWBOX_OY] (state, oy) {
    state.offset.y = oy
  },
  [types.SET_VIEWBOX_PAN] (state, pan) {
    const amount = (pan === null) ? { x: state.panAmount, y: state.panAmount } : pan
    state.pan.x += getValueByAspectRatio(state, amount.x)
    state.pan.y += getValueByAspectRatio(state, amount.y)
  },
  [types.SET_VIEWBOX_PAN_ARROW_KEYS] (state, direction) {
    const kC = config.arrowsEnum
    switch (direction) {
      case kC.ARROW_UP:
        state.pan.y += getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_RIGHT:
        state.pan.x -= getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_DOWN:
        state.pan.y -= getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_LEFT:
        state.pan.x += getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_UP_RIGHT:
        state.pan.x -= getValueByAspectRatio(state, state.panAmount)
        state.pan.y += getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_UP_LEFT:
        state.pan.x += getValueByAspectRatio(state, state.panAmount)
        state.pan.y += getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_DOWN_RIGHT:
        state.pan.x -= getValueByAspectRatio(state, state.panAmount)
        state.pan.y -= getValueByAspectRatio(state, state.panAmount)
        break
      case kC.ARROW_DOWN_LEFT:
        state.pan.x += getValueByAspectRatio(state, state.panAmount)
        state.pan.y -= getValueByAspectRatio(state, state.panAmount)
        break
    }
  },
  [types.SET_VIEWBOX_PAN_X] (state, px) {
    state.pan.x += getValueByAspectRatio(state, px)
  },
  [types.SET_VIEWBOX_PAN_Y] (state, py) {
    state.pan.y += getValueByAspectRatio(state, py)
  },
  [types.SET_VIEWBOX_ORIGIN] (state) {
    state.pan.x = 0
    state.pan.y = 0
  },
  [types.SET_VIEWBOX_ZOOM] (state, zf) {
    state.scale = zf
  },
  [types.SET_VIEWBOX_ZOOM_RESET] (state) {
    state.scale = 1.0
    state.center.x = (state.w / 2)
    state.center.y = (state.h / 2)
  },
  [types.SET_VIEWBOX_ZOOM_IN] (state, coordinate = null) {
    const newScale = (parseFloat(state.scale) + parseFloat(state.scaleAmount)).toFixed(1)
    calculateAndSetPanning(state, coordinate, newScale)
  },
  [types.SET_VIEWBOX_ZOOM_OUT] (state, coordinate = null) {
    const newScale = (parseFloat(state.scale) - parseFloat(state.scaleAmount)).toFixed(1)
    if (newScale > 0) {
      calculateAndSetPanning(state, coordinate, newScale)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
