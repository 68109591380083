<template>
  <div
    v-if="ctrl === 'move' && mx_operations_selectedObjectsCount > 0 && !isCutOut"
    class="c-nav-sub__group"
  >
    <button-head-nav
      id="sub-nav-button-delete"
      :category="$t('subNav.categories.object')"
      :disabled="shouldBlockGui"
      :label="$t('subNav.object.delete')"
      :on-click-action="()=>{return deleteObject()}"
      :theme="getTheme"
      icon-classes="fas fa-2x fa-trash-alt"
      style-color="light"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'
import * as mutationTypes from '@/vuex/mutation-types'

import MixinOperations from '@/mixins/operations'
import MixinTrackState from '@/mixins/trackstate'

import ButtonHeadNav from '@/components/common/ButtonHeadNav'

export default {
  name: 'DeletePan',
  components: {
    ButtonHeadNav
  },
  mixins: [
    MixinOperations,
    MixinTrackState
  ],
  data: () => {
    return {
      editorEventTypes: editorEventTypes
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      isCutOut: state => state.project.objects.cutOut,
      currentSelectedProducts: state => state.events.currentSelectedProducts,
      currentSelectedObjects: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      shouldBlockGui: 'events/shouldBlockGui',
      getTheme: 'gui/getTheme'
    })
  },
  methods: {
    ...mapActions({
      removeProductFromSelectedObjects: 'project/objects/removeProductFromSelectedObjects'
    }),
    ...mapMutations({
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU
    }),
    deleteObject () {
      this.hideMouseMenu()
      this.$events.fire(editorEventTypes.PROJECT_REMOVE_SELECTED_OBJECTS)
    }
  }
}
</script>
