/**
 * START: Editor Mutations
 */
export const SET_EDITOR = 'SET_EDITOR'
export const SET_EDITOR_OFFSET = 'SET_EDITOR_OFFSET'
export const SET_EDITOR_VIEW_BOX = 'SET_EDITOR_VIEW_BOX'
export const MOVE_EDITOR_VIEW_BOX = 'MOVE_EDITOR_VIEW_BOX'
export const SET_EDITOR_DEFAULT_VIEWBOX_PROPERTIES = 'SET_EDITOR_DEFAULT_VIEWBOX_PROPERTIES'
export const SET_EDITOR_SETTINGS = 'SET_EDITOR_SETTINGS'
export const SET_EDITOR_SCALE = 'SET_EDITOR_SCALE'
export const EDITOR_SCALE_IN = 'EDITOR_SCALE_IN'
export const EDITOR_SCALE_OUT = 'EDITOR_SCALE_OUT'
export const UPDATE_EDITOR_OBJECT_SELECT_PROPERTIES = 'UPDATE_EDITOR_OBJECT_SELECT_PROPERTIES'
export const CLEAR_EDITOR_OBJECT_SELECTION = 'CLEAR_EDITOR_OBJECT_SELECTION'
export const ADD_DOWN_KEY = 'ADD_DOWN_KEY'
export const REMOVE_DOWN_KEY = 'REMOVE_DOWN_KEY'
export const SET_LAST_INTERNAL_ACTION = 'SET_LAST_INTERNAL_ACTION'
export const EDITOR_MOVE_VIEWBOX_BY_KEYPRESS = 'EDITOR_MOVE_VIEWBOX_BY_KEYPRESS'
export const SET_EDITOR_COVER = 'SET_EDITOR_COVER'
export const EDITOR_NAVIGATOR = 'EDITOR_NAVIGATOR'
/**
 * END: Editor Mutations
 */

/**
 * START: ViewBox
 */
export const SET_VIEWBOX_X = 'SET_VIEWBOX_X'
export const SET_VIEWBOX_Y = 'SET_VIEWBOX_Y'
export const SET_VIEWBOX_W = 'SET_VIEWBOX_W'
export const SET_VIEWBOX_H = 'SET_VIEWBOX_H'
export const SET_VIEWBOX_ASPECT_RATIO = 'SET_VIEWBOX_ASPECT_RATIO'
export const SET_VIEWBOX_CX = 'SET_VIEWBOX_CX'
export const SET_VIEWBOX_CY = 'SET_VIEWBOX_CY'
export const SET_VIEWBOX_OX = 'SET_VIEWBOX_OX'
export const SET_VIEWBOX_OY = 'SET_VIEWBOX_OY'
export const SET_VIEWBOX_PAN = 'SET_VIEWBOX_PAN'
export const SET_VIEWBOX_PAN_ARROW_KEYS = 'SET_VIEWBOX_PAN_ARROW_KEYS'
export const SET_VIEWBOX_PAN_X = 'SET_VIEWBOX_PAN_X'
export const SET_VIEWBOX_PAN_Y = 'SET_VIEWBOX_PAN_Y'
export const SET_VIEWBOX_ZOOM = 'SET_VIEWBOX_ZOOM'
export const SET_VIEWBOX_ZOOM_RESET = 'SET_VIEWBOX_ZOOM_RESET'
export const SET_VIEWBOX_ZOOM_IN = 'SET_VIEWBOX_ZOOM_IN'
export const SET_VIEWBOX_ZOOM_OUT = 'SET_VIEWBOX_ZOOM_OUT'
export const SET_VIEWBOX_ORIGIN = 'SET_VIEWBOX_ORIGIN'
/**
 * END: ViewBox
 */

/**
 * START: Event Handler
 */
export const EVT_TARGET = 'EVT_TARGET'
export const EVT_ACTION = 'EVT_ACTION'
export const EVT_CTRL = 'EVT_CTRL'
export const EVT_OPEN_CONTEXT_MENU = 'EVT_OPEN_CONTEXT_MENU'
export const EVT_CLOSE_CONTEXT_MENU = 'EVT_CLOSE_CONTEXT_MENU'
export const EVT_ADD_OBJECT_SELECT = 'EVT_ADD_OBJECT_SELECT'
export const EVT_REMOVE_OBJECT_SELECT = 'EVT_REMOVE_OBJECT_SELECT'
export const EVT_CLEAR_OBJECT_SELECTION = 'EVT_CLEAR_OBJECT_SELECTION'
export const EVT_SET_CURRENT_MOVING_POINT = 'EVT_SET_CURRENT_MOVING_POINT'
export const EVT_SET_CURRENT_ACTIVE_MODIFIER = 'EVT_SET_CURRENT_ACTIVE_MODIFIER'
export const EVT_RESET_CURRENT_ACTIVE_MODIFIER = 'EVT_RESET_CURRENT_ACTIVE_MODIFIER'
export const EVT_UPDATE_BOOLEAN_OPERATIONS = 'EVT_UPDATE_BOOLEAN_OPERATIONS'
export const EVT_UPDATE_EDITOR_MODE = 'EVT_UPDATE_EDITOR_MODE'
export const EVT_ADD_CUT_OBJECT_TO_SELECT = 'EVT_ADD_CUT_OBJECT_TO_SELECT'
export const EVT_REMOVE_PRODUCT_FROM_SELECTION = 'EVT_REMOVE_PRODUCT_FROM_SELECTION'
export const SET_INPUT_FOCUS = 'SET_INPUT_FOCUS'
/**
 * END: Event Handler
 */

/**
 * START: Dialogs Mutations
 */
export const DIALOGS_TOGGLE = 'DIALOGS_TOGGLE'
export const DIALOGS_SUBSCREEN = 'DIALOGS_SUBSCREEN'
export const DIALOGS_TAB = 'DIALOGS_TAB'
/**
 * END: Dialogs Mutations
 */

/**
 * START: Nav Mutations
 */
export const HEADNAV_SET_SIDENAV = 'HEADNAV_SET_SIDENAV'
export const SUBNAV_DIALOGS_TOGGLE = 'SUBNAV_DIALOGS_TOGGLE'
export const SUBNAV_DIALOGS_SUBSCREEN = 'SUBNAV_DIALOGS_SUBSCREEN'
/**
 * END: Nav Mutations
 */

/**
 * START: File Mutations
 */
export const FILES_STORE_SURFACE_UNDERLAY = 'FILES_STORE_SURFACE_UNDERLAY'
export const FILES_STORE_RAW_DATA_SURFACE_UNDERLAY = 'FILES_STORE_RAW_DATA_SURFACE_UNDERLAY'
export const FILES_TOGGLE_SURFACE_UNDERLAY = 'FILES_TOGGLE_SURFACE_UNDERLAY'
export const FILES_DELETE_SURFACE_UNDERLAY = 'FILES_DELETE_SURFACE_UNDERLAY'
export const FILES_SET_UNDERLAY_STATE = 'FILES_SET_UNDERLAY_STATE'
/**
 * END: File Mutations
 */

/**
 * START: Grid Mutations
 */
export const TOGGLE_GRID_SHOW = 'TOGGLE_GRID_SHOW'
export const TOGGLE_GRID_SNAP = 'TOGGLE_GRID_SNAP'
export const SET_GRID_RASTER = 'SET_GRID_RASTER'
export const SET_GRID_RASTER_SLIDER = 'SET_GRID_RASTER_SLIDER'
export const SET_GRID_RASTER_TEXT = 'SET_GRID_RASTER_TEXT'
/**
 * END: Grid Mutations
 */

/**
 * START: Project Mutations
 */
export const TRANSLATE_COORDINATES_FOR_OBJECTS = 'TRANSLATE_COORDINATES_FOR_OBJECTS'
export const SET_TRANSLATE_COORDINATES_FOR_OBJECTS = 'SET_TRANSLATE_COORDINATES_FOR_OBJECTS'
export const SET_ROTATION_FOR_OBJECTS = 'SET_ROTATION_FOR_OBJECTS'
export const SET_COLLISION_POINTS = 'SET_COLLISION_POINTS'
export const UPDATE_PROJECT_OBJECT_BY_SNAP = 'UPDATE_PROJECT_OBJECT_BY_SNAP'
export const UPDATE_PROJECT_LAST_OBJECT_ID = 'UPDATE_PROJECT_LAST_OBJECT_ID'
export const PROJECT_SET_BACKEND_META = 'PROJECT_SET_BACKEND_META'
export const PROJECT_TOOL_ADD = 'PROJECT_TOOL_ADD'
export const PROJECT_TOOL_REMOVE = 'PROJECT_TOOL_REMOVE'
export const PROJECT_CONSUMABLE_OBJECT_UPDATE = 'PROJECT_CONSUMABLE_OBJECT_UPDATE'
export const PROJECT_CONSUMABLE_ADD = 'PROJECT_CONSUMABLE_ADD'
export const PROJECT_CONSUMABLE_REMOVE = 'PROJECT_CONSUMABLE_REMOVE'
export const PROJECT_ADD_ADDITIONAL_COSTS = 'PROJECT_ADD_ADDITIONAL_COSTS'
/**
 * END: Project Mutations
 */

/**
 * START: Project Objects Mutations
 */
export const ADD_OBJECT = 'ADD_OBJECT'
export const ADD_NEW_OBJECT_POINT = 'ADD_NEW_OBJECT_POINT'
export const SCALE_OBJECT = 'SCALE_OBJECT'
export const ROTATE_OBJECT_DEFAULT = 'ROTATE_OBJECT_DEFAULT'
export const ROTATE_OBJECT_CIRCLE = 'ROTATE_OBJECT_CIRCLE'
export const RESET_OBJECT_POINT_HANDLE = 'RESET_OBJECT_POINT_HANDLE'
export const OVERRIDE_ALL_OBJECT_POINTS = 'OVERRIDE_ALL_OBJECT_POINTS'
export const CREATE_NEW_OBJECT_FROM_POINTS = 'CREATE_NEW_OBJECT_FROM_POINTS'
export const REMOVE_OBJECTS = 'REMOVE_OBJECTS'
export const REMOVE_VALIDATED_OBJECT_POINTS = 'REMOVE_VALIDATED_OBJECT_POINTS'
export const UPDATE_OBJECTS_BY_TRANSLATE = 'UPDATE_OBJECTS_BY_TRANSLATE'
export const UPDATE_OBJECT_LAST_ID = 'UPDATE_OBJECT_LAST_ID'
export const UPDATE_OBJECT_POINT = 'UPDATE_OBJECT_POINT'
export const UPDATE_OBJECT_POINTS_BY_SNAP = 'UPDATE_OBJECT_POINTS_BY_SNAP'
export const UPDATE_OBJECT_HANDLE = 'UPDATE_OBJECT_HANDLE'
export const TRANSFORM_OBJECT_POINT_TO_LINE = 'TRANSFORM_OBJECT_POINT_TO_LINE'
export const TRANSFORM_OBJECT_POINT_TO_CUBIC = 'TRANSFORM_OBJECT_POINT_TO_CUBIC'
export const TRANSFORM_OBJECT_POINT_TO_QUADRATIC = 'TRANSFORM_OBJECT_POINT_TO_QUADRATIC'
export const DUPLICATE_OBJECT = 'DUPLICATE_OBJECT'
export const INIT_OBJECT_LAYER = 'INIT_OBJECT_LAYER'
export const UPDATE_OBJECT_ORDER = 'UPDATE_OBJECT_ORDER'
export const UPDATE_OBJECT_CUT = 'UPDATE_OBJECT_CUT'
export const REMOVE_OBJECT_CUT = 'REMOVE_OBJECT_CUT'
export const SET_SELECTION_MODE = 'SET_SELECTION_MODE'
export const SET_OBJECT_AREA = 'SET_OBJECT_AREA'
export const ADD_PRODUCT_TO_OBJECT = 'ADD_PRODUCT_TO_OBJECT'
export const REMOVE_PRODUCT_FROM_OBJECT = 'REMOVE_PRODUCT_FROM_OBJECT'
export const REMOVE_PRODUCT_FROM_SELECTED_OBJECTS = 'EVT_REMOVE_PRODUCT_FROM_SELECTED_OBJECTS'
export const ADD_LAWN_CALCULATION_TO_OBJECT = 'ADD_LAWN_CALCULATION_TO_OBJECT'
export const CLEAR_OBJECT_SELECTION_MODE = 'CLEAR_OBJECT_SELECTION_MODE'
export const PRODUCT_NEED_UPDATE_OBJECT_MODIFIED = 'PRODUCT_NEED_UPDATE_OBJECT_MODIFIED'
export const OBJECT_UPDATE_PRODUCT = 'OBJECT_UPDATE_PRODUCT'
export const OBJECT_UPDATE_PRODUCT_PROPERTIES = 'OBJECT_UPDATE_PRODUCT_PROPERTIES'
export const OBJECT_TRACK_REMOVE_SOURCE_TARGET_LOS = 'OBJECT_TRACK_REMOVE_SOURCE_TARGET_LOS'
export const OBJECT_CHECK_WASTE_CONNECTIONS_AFTER_DELETE_OBJECT = 'OBJECT_CHECK_WASTE_CONNECTIONS_AFTER_DELETE_OBJECT'
/**
 * END: Project Objects Mutations
 */

/**
 * START: Project Masks Mutations for objects
 */
export const ADD_MASK = 'ADD_MASK'
export const ADD_NEW_MASK_POINT = 'ADD_NEW_MASK_POINT'
export const SCALE_MASKS = 'SCALE_MASKS'
export const CREATE_NEW_MASK_FROM_POINTS = 'CREATE_NEW_MASK_FROM_POINTS'
export const UPDATE_MASKS_BY_TRANSLATE = 'UPDATE_MASKS_BY_TRANSLATE'
export const UPDATE_MASK_POINT = 'UPDATE_MASK_POINT'
export const UPDATE_MASK_HANDLE = 'UPDATE_MASK_HANDLE'
export const REMOVE_MASKS_BY_PARENT = 'REMOVE_MASKS_BY_PARENT'
export const REMOVE_MASK_POINT = 'REMOVE_MASK_POINT'
export const RESET_MASK_POINT_HANDLE = 'RESET_MASK_POINT_HANDLE'
export const TRANSFORM_MASK_POINT_TO_LINE = 'TRANSFORM_MASK_POINT_TO_LINE'
export const TRANSFORM_MASK_POINT_TO_CUBIC = 'TRANSFORM_MASK_POINT_TO_CUBIC'
export const TRANSFORM_MASK_POINT_TO_QUADRATIC = 'TRANSFORM_MASK_POINT_TO_QUADRATIC'
/**
 * END: Project Masks Mutations for objects
 */

/**
 * START: Boolean Operations Mutations on Project
 */
export const OVERRIDE_PROJECT_OBJECT_POINTS = 'OVERRIDE_PROJECT_OBJECT_POINTS'
export const ADD_PROJECT_OBJECT_MASK = 'ADD_PROJECT_OBJECT_MASK'
/**
 * END: Boolean Operations Mutations on Project
 */

/**
 * START: Pseudo Elements Mutations
 */
export const SET_PSEUDO_PATH = 'SET_PSEUDO_PATH'
export const ADD_PSEUDO_POINT_TO_PATH = 'ADD_PSEUDO_POINT_TO_PATH'
export const REMOVE_LAST_PSEUDO_POINT = 'REMOVE_LAST_PSEUDO_POINT'
export const UPDATE_LAST_PSEUDO_POINT_ID = 'UPDATE_LAST_PSEUDO_POINT_ID'
export const UPDATE_PSEUDO_MOUSE_COORDINATES = 'UPDATE_PSEUDO_MOUSE_COORDINATES'
export const SET_PSEUDO_KEYBOARD_INPUT = 'SET_PSEUDO_KEYBOARD_INPUT'
/**
 * END: Pseudo Elements Mutations
 */

/**
 * START: Measurement Mutations
 */
export const SET_REF_POINT_START = 'SET_REF_POINT_START'
export const SET_REF_POINT_END = 'SET_REF_POINT_END'
export const UPDATE_REF_POINT_START = 'UPDATE_REF_POINT_START'
export const UPDATE_REF_POINT_END = 'UPDATE_REF_POINT_END'
export const RESET_REF_POINTS = 'RESET_REF_POINTS'
export const STORE_REF_POINTS = 'STORE_REF_POINTS'
export const STORE_TO_UNIT = 'STORE_TO_UNIT'
export const RESET_STORED_REF_POINTS_AND_TO_UNIT = 'RESET_STORED_REF_POINTS_AND_TO_UNIT'
export const SET_MEASURE_PROPERTY = 'SET_MEASURE_PROPERTY'
export const SET_RULER_POINT_START = 'SET_RULER_POINT_START'
export const SET_RULER_POINT_END = 'SET_RULER_POINT_END'
export const UPDATE_RULER_POINT_START = 'UPDATE_RULER_POINT_START'
export const UPDATE_RULER_POINT_END = 'UPDATE_RULER_POINT_END'
/**
 * END: Measurement Mutations
 */

/**
 * START: Layer Mutations
 */
export const ADD_LAYER = 'ADD_LAYER'
export const REMOVE_LAYER = 'REMOVE_LAYER'
export const UPDATE_LAST_LAYER_ID = 'UPDATE_LAST_LAYER_ID'
export const UPDATE_ALL_LAYERS = 'UPDATE_ALL_LAYERS'
export const UPDATE_LAYER = 'UPDATE_LAYER'
/**
 * END: Layer Mutations
 */

/**
 * START: History
 */
/* export const SET_HISTORY_ITEMS = 'SET_HISTORY_ITEMS'
export const STORE_STATE = 'STORE_STATE'
export const RESTORE_STATE = 'RESTORE_STATE' */
export const SET_POINTER = 'SET_POINTER'
export const SET_FROM_HISTORY = 'SET_STATES'
export const STORE_HISTORY_AND_CURRENT_STATE = 'STORE_HISTORY_AND_CURRENT_STATE'
export const RESET_STATE = 'RESET_STATE'
export const HISTORY_SET_PROJECT_INITIAL_STATE = 'HISTORY_SET_PROJECT_INITIAL_STATE'
/**
 * END: History
 */
/**
 * START: Backend
 */
export const BACKEND_SET_ERROR = 'BACKEND_SET_ERROR'
export const BACKEND_SET_LOGIN = 'BACKEND_SET_LOGIN'
export const BACKEND_SET_PROJECTS = 'BACKEND_SET_PROJECTS'
/**
 * END: Backend
 */
/**
 * START: Line of Sight
 */
export const LOS_SET_MEASUREMENT_SETTINGS = 'LOS_SET_MEASUREMENT_SETTINGS'
export const LOS_SET_DATA_TO_WORK_WITH = 'LOS_SET_DATA_TO_WORK_WITH'
export const LOS_CHANGE_MODE = 'LOS_CHANGE_MODE'
export const LOS_CLEAR_DATA_TO_WORK_WITH = 'LOS_CLEAR_DATA_TO_WORK_WITH'
export const LOS_ADD_TURF_TRACK_TO_SELECTION = 'LOS_ADD_TURF_TRACK_TO_SELECTION'
export const LOS_REMOVE_TURF_TRACK_FROM_SELECTION = 'LOS_REMOVE_TURF_TRACK_FROM_SELECTION'
export const LOS_CLEAR_TURF_TRACK_SELECTION = 'LOS_CLEAR_TURF_TRACK_SELECTION'
export const LOS_CHANGE_TURF_ANGLE = 'LOS_CHANGE_TURF_ANGLE'
export const LOS_CHANGE_TURF_SHIFT = 'LOS_CHANGE_TURF_SHIFT'
export const LOS_CHANGE_TURF_WIDTH = 'LOS_CHANGE_TURF_WIDTH'
export const LOS_CHANGE_TURF_LENGTH = 'LOS_CHANGE_TURF_LENGTH'
export const LOS_CHANGE_TURF_OVERSIZE = 'LOS_CHANGE_TURF_OVERSIZE'
export const LOS_CHANGE_TURF_RESULT = 'LOS_CHANGE_TURF_RESULT'
export const LOS_UPDATE_SPLIT_TURF_TRACK = 'LOS_UPDATE_SPLIT_TURF_TRACK'
export const LOS_IS_SELECTED_OUTLINE = 'LOS_IS_SELECTED_OUTLINE'
export const LOS_CHANGE_PRODUCT_ALIGNMENT = 'LOS_CHANGE_PRODUCT_ALIGNMENT'
export const LOS_CHANGE_TEMPORARY_TURF_SETTINGS = 'LOS_CHANGE_TEMPORARY_TURF_SETTINGS'
export const LOS_UPDATE_TRACKS_BY_REMOVING_PATH_STOP = 'LOS_UPDATE_TRACKS_BY_REMOVING_PATH_STOP'
export const LOS_UPDATE_TRACKS_BY_MOVED_PATH_STOP = 'LOS_UPDATE_TRACKS_BY_MOVED_PATH_STOP'
export const LOS_SET_MOVABLES = 'LOS_SET_MOVABLES'
export const LOS_ADD_MOVABLE_SELECTED = 'LOS_ADD_MOVABLE_SELECTED'
export const LOS_REMOVE_MOVABLE_SELECTED = 'LOS_REMOVE_MOVABLE_SELECTED'
export const LOS_ADD_MOVABLE_HOVERED = 'LOS_ADD_MOVABLE_HOVERED'
export const LOS_REMOVE_MOVABLE_HOVERED = 'LOS_REMOVE_MOVABLE_HOVERED'
export const LOS_SET_OUTSIDES = 'LOS_SET_OUTSIDES'
export const LOS_CLEAR_OUTSIDES_DATA = 'LOS_CLEAR_OUTSIDES_DATA'
export const LOS_SET_DRAWABLE_ELEMENTS = 'LOS_SET_DRAWABLE_ELEMENTS'
export const LOS_DRAWABLE_ELEMENTS_UPDATE_EXTERNAL_PROPERTIES = 'LOS_DRAWABLE_ELEMENTS_UPDATE_EXTERNAL_PROPERTIES'
export const LOS_CLEAR_DRAWABLE_DATA = 'LOS_CLEAR_DRAWABLE_DATA'
export const LOS_CLEAR_MOVABLE_DATA = 'LOS_CLEAR_MOVABLE_DATA'

export const LOS_UPDATE_SUBBASE_INFILL_PROPERTIES = 'LOS_UPDATE_SUBBASE_INFILL_PROPERTIES'
/**
 * END: Line of Sight
 */

/**
 * START: Line of Sight NEW
 */
export const LOS_CLEAR_STATE = 'LOS_CLEAR_STATE'
export const LOS_SET_OBJECT_TO_WORK_WITH = 'LOS_SET_OBJECT_TO_WORK_WITH'
export const LOS_SET_MEASURE = 'LOS_SET_MEASURE'
export const LOS_SET_OBJECT_LINES = 'LOS_SET_OBJECT_LINES'
export const LOS_UPDATE_OBJECT_PRODUCT_PROPERTIES = 'LOS_UPDATE_OBJECT_PRODUCT_PROPERTIES'
export const LOS_SET_TRACK_CUT_LINES = 'LOS_SET_TRACK_CUT_LINES'
export const LOS_SET_TRACK_CUT_LINE_SELECTED = 'LOS_SET_TRACK_CUT_LINE_SELECTED'
export const LOS_REMOVE_TRACK_CUT_LINE = 'LOS_REMOVE_TRACK_CUT_LINE'
export const LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_MOVED = 'LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_MOVED'
export const LOS_SET_TRACK_SELECTED = 'LOS_SET_TRACK_SELECTED'
export const LOS_REMOVE_TRACK_SELECTED = 'LOS_REMOVE_TRACK_SELECTED'
export const LOS_CLEAR_TRACKS_SELECTED = 'LOS_CLEAR_TRACKS_SELECTED'
export const LOS_UPDATE_OBJECT_PRODUCT_TRACK_BY_SPLIT = 'LOS_UPDATE_OBJECT_PRODUCT_TRACK_BY_SPLIT'
export const LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_OVERSIZE = 'LOS_UPDATE_OBJECT_PRODUCT_TRACKS_BY_OVERSIZE'

// SUBSTRUCTURES
export const LOS_ADD_SUBBASE_TO_PRODUCT = 'LOS_ADD_SUBBASE_TO_PRODUCT'
export const LOS_CACHE_SUBBASE_AMOUNT = 'LOS_CACHE_SUBBASE_AMOUNT'
export const LOS_OVERRIDE_SUBSTRUCTURES_BY_SORTING = 'LOS_OVERRIDE_SUBSTRUCTURES_BY_SORTING'
export const LOS_REMOVE_SUBBASE_FROM_PRODUCT = 'LOS_REMOVE_SUBBASE_FROM_PRODUCT'
export const LOS_UPDATE_SUBBASES_AFTER_CHANGES = 'LOS_UPDATE_SUBBASES_AFTER_CHANGES'
// INFILLS
export const LOS_ADD_INFILL_TO_PRODUCT = 'LOS_ADD_INFILL_TO_PRODUCT'
export const LOS_OVERRIDE_INFILLS_BY_SORTING = 'LOS_OVERRIDE_INFILLS_BY_SORTING'
export const LOS_REMOVE_INFILL_FROM_PRODUCT = 'LOS_REMOVE_INFILL_FROM_PRODUCT'

// Waste
export const LOS_SET_WASTE_OBJECT = 'LOS_SET_WASTE_OBJECT'
export const LOS_SET_WASTE_TRACK = 'LOS_SET_WASTE_TRACK'
export const LOS_SET_USE_WASTE_FROM_OBJECT = 'LOS_SET_USE_WASTE_FROM_OBJECT'
export const LOS_SET_USE_WASTE_FROM_OBJECT_TRACK = 'LOS_SET_USE_WASTE_FROM_OBJECT_TRACK'
export const LOS_SAVE_USED_WASTE = 'LOS_SAVE_USED_WASTE'
export const LOS_REMOVE_WASTE_FROM_TRACK = 'LOS_REMOVE_WASTE_FROM_TRACK'
export const LOS_CLEAR_WASTE = 'LOS_CLEAR_WASTE'
/**
 * END: Line of Sight NEW
 */

/**
 * START: Waste management
 */
export const WASTE_MANAGEMENT_CANCEL = 'WASTE_MANAGEMENT_CANCEL'
export const WASTE_MANAGEMENT_SET_ACTIVE = 'WASTE_MANAGEMENT_SET_ACTIVE'
export const WASTE_MANAGEMENT_SET_FOR = 'WASTE_MANAGEMENT_SET_FOR'
export const WASTE_MANAGEMENT_SET_AREA = 'WASTE_MANAGEMENT_SET_AREA'
export const WASTE_MANAGEMENT_SET_TRACK = 'WASTE_MANAGEMENT_SET_TRACK'
/**
 * END: Waste management
 */

/**
 * START: Template
 */
export const TEMPLATES_SET = 'TEMPLATES_SET'
export const TEMPLATES_SET_DATA_MAP = 'TEMPLATES_SET_DATA_MAP'
export const TEMPLATES_ADD = 'TEMPLATES_ADD'
export const TEMPLATES_UPDATE = 'TEMPLATES_UPDATE'
export const TEMPLATES_DELETE = 'TEMPLATES_DELETE'
export const TEMPLATES_CLEAR = 'TEMPLATES_CLEAR'
/**
 * END: Template
 */

/**
 * START: LANGUAGE
 */
export const LANGUAGE_SET = 'LANGUAGE_SET'
/**
 * END: LANGUAGE
 */
