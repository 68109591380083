import * as types from '@/vuex/mutation-types'
import viewboxModule from '@/vuex/modules/submodules/viewbox'
import gridModule from '@/vuex/modules/submodules/grid'
import { withHistoryMutation } from '../history-helper'
import Vue from 'vue'

const state = {
  editor: null,
  lastInternalAction: null,
  coverActive: false,
  navigator: {
    is_chrome: false,
    is_explorer: false,
    is_firefox: false,
    is_safari: false,
    is_opera: false
  }
}

const getters = {
  get (state) {
    if (state.editor !== null) {
      return document.getElementById(state.editor)
    }
    return null
  }
}

const actions = {
  browser ({ dispatch, commit }, payload) {
    commit(types.EDITOR_NAVIGATOR, payload)
  }
}

const mutations = withHistoryMutation({
  [types.SET_EDITOR] (state, editor) {
    state.editor = editor
  },
  [types.SET_LAST_INTERNAL_ACTION] (state, action) {
    state.lastInternalAction = action
  },
  [types.SET_EDITOR_COVER] (state, val) {
    state.coverActive = val
  },
  [types.EDITOR_NAVIGATOR] (state, payload) {
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(state.navigator, key)) {
        Vue.set(state.navigator, key, payload[key])
      }
    }

    if ((state.navigator.is_chrome) && (state.navigator.is_safari)) {
      state.navigator.is_safari = false
    }
    if ((state.navigator.is_chrome) && (state.navigator.is_opera)) {
      state.navigator.is_chrome = false
    }
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    viewbox: viewboxModule,
    grid: gridModule
  }
}
