<template>
  <div
    :id="`cSidebarProduct-${product.original.id}`"
    :class="{'c-sidebar__product--selected':isProductSelected(product.original.id),
             'c-sidebar__product--disabled':ctrl !== 'move' || shouldBlockGui}"
    :draggable="ctrl === 'move' && !shouldBlockGui"
    class="c-sidebar__product"
    @mouseleave="sendTooltipInfos(null)"
    @mouseover="sendTooltipInfos(product)"
    @dragend="dragEnd"
    @dragstart="dragStart"
  >
    <span v-if="product.original.assets">
      <div
        v-if="product.original.assets.image !== null"
        :style="`background-image: url(${product.original.assets.image.conversions.thumb})`"
        class="c-sidebar__product-image"
      />
    </span>
    <div class="c-sidebar__product-description">
      <p v-html="mx_product_in_language(product.original, ['name'])" />
      <p
        class="c-sidebar__product-link"
        @click="openProductPage(product)"
      >
        {{ $t('editor.sidebar.products.productPage') }}
      </p>
    </div>
    <span
      v-if="isProductSelected(product.original.id)"
      class="c-sidebar__product-remove"
      @click="remove(product.original.id)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Turf } from '@/classes/products'
import * as editorEventTypes from '@/events/editor-event-types'

import MixinMath from '@/mixins/math'
import MixinTrackState from '@/mixins/trackstate'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

export default {
  name: 'Product',
  mixins: [
    MixinMath,
    MixinTrackState,
    MixinProductInLanguage
  ],
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      dragging: false,
      dropObject: null,
      eventLeave: null,
      eventEnter: null
    }
  },
  computed: {
    ...mapState({
      currentSelectedObjects: state => state.events.currentSelectedObjects,
      ctrl: state => state.events.ctrl
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      isProductSelected: 'events/isProductSelected',
      shouldBlockGui: 'events/shouldBlockGui'
    })
  },
  mounted () {
    this.$events.on(editorEventTypes.PRODUCT_DRAG_OVER_OBJECT, (payload) => {
      this.dropObject = payload
    })
    this.$events.on(editorEventTypes.PRODUCT_DRAG_LEAVE_OBJECT, (payload) => {
      this.eventLeave = payload
    })
    this.$events.on(editorEventTypes.PRODUCT_DRAG_ENTER_OBJECT, (payload) => {
      this.eventEnter = payload
    })
  },
  methods: {
    ...mapActions({
      addProductToObject: 'project/objects/addProductToObject',
      clearObjectSelection: 'events/clearObjectSelection',
      removeProductFromSelectedObjects: 'project/objects/removeProductFromSelectedObjects',
      removeProductFromSelection: 'events/removeProductSelection'
    }),
    sendTooltipInfos (payload) {
      this.$emit('sidebar-product-infos', payload)
    },
    openProductPage (payload) {
      if (this.ctrl === 'move' && !this.shouldBlockGui) {
        this.$events.fire(editorEventTypes.PRODUCT_SHOW_PAGE, payload)
      }
    },
    dragStart (evt) {
      const image = evt.target
      evt.dataTransfer.setData('text', 'dragging product')
      evt.dataTransfer.setDragImage(image, 0, 0)
      evt.dataTransfer.effectAllowed = 'move'
      this.$emit('sidebar-product-infos', null)
      this.$emit('stop-product-tooltip', null)
    },
    dragEnd (evt) {
      let point = null
      if (this.eventLeave !== null) {
        point = this.mx_math_getCoordinatesFromEvent(this.eventLeave)
      } else if (this.eventEnter !== null) {
        point = this.mx_math_getCoordinatesFromEvent(this.eventEnter)
      } else if (this.dropObject !== null) {
        point = this.mx_math_getCoordinatesFromEvent(evt)
      }

      if (this.dropObject !== 'surface' && this.dropObject !== null) {
        const obj = this.getObject(this.dropObject)

        if (obj && obj.isPointInsidePath(point)) {
          this.mx_trackstate_trackState(() => {
            this.clearObjectSelection()
            const product = new Turf(this.product.original.id,
              this.product.overlay.price_each,
              this.product.overlay.roll_width,
              this.product.overlay.roll_length,
              this.$settings.turfUnits.width,
              this.product.versioning.version_nr,
              this.product.versioning.id
            )
            product.setThreshold(this.product.overlay.roll_threshold)
            product.setMaxValues(this.product.overlay.roll_width, this.product.overlay.roll_length)

            this.addProductToObject({
              object: this.dropObject,
              product: product
            })
          })
        }
      }

      this.eventLeave = null
      this.eventEnter = null
      this.dropObject = null
      this.$events.fire(editorEventTypes.PRODUCT_STOP_DRAGGING)
    },
    remove (id) {
      if (this.ctrl === 'move' && !this.shouldBlockGui) {
        this.mx_trackstate_trackState(() => {
          this.removeProductFromSelectedObjects({
            objects: this.currentSelectedObjects,
            product: id
          })
          this.removeProductFromSelection({
            product: id
          })
        })
      }
    }
  }
}
</script>
