<template>
  <svg
    id="point-handle-icon"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
  >
    <defs>
      <linearGradient
        id="menu-handles-gradient"
        x1="13.03"
        y1="40.56"
        x2="13.03"
        y2="7.72"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.17"
          stop-color="#fff"
          stop-opacity="0"
        />
        <stop
          offset="0.81"
          stop-color="#fff"
        />
      </linearGradient>
    </defs>
    <path
      class="cls-1"
      d="M13.93,40.56c-4.87-7-3.53-12-2.35-16.5S13.8,15.62,9.66,9.38l2.51-1.66c4.87,7.37,3.51,12.54,2.31,17.1-1.16,4.39-2.16,8.19,1.91,14Z"
    />
    <circle
      class="u-fill-w"
      cx="10.91"
      cy="8.55"
      r="4.73"
    />
    <circle
      class="u-fill-b1"
      cx="29.01"
      cy="31.5"
      r="4.73"
    />
    <path
      class="u-fill-b1"
      d="M26.35,29.9a1.51,1.51,0,0,1-1.19-.58l-.88-1.15a1.5,1.5,0,0,1,2.38-1.83l.88,1.15a1.5,1.5,0,0,1-.28,2.1A1.47,1.47,0,0,1,26.35,29.9Zm-3.93-5.11a1.49,1.49,0,0,1-1.19-.59L19.4,21.83A1.5,1.5,0,1,1,21.78,20l1.83,2.38a1.5,1.5,0,0,1-.28,2.1A1.47,1.47,0,0,1,22.42,24.79Zm-4.88-6.34a1.49,1.49,0,0,1-1.19-.59l-1.83-2.37a1.5,1.5,0,1,1,2.38-1.83L18.73,16a1.5,1.5,0,0,1-.27,2.1A1.52,1.52,0,0,1,17.54,18.45Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'PointHandleIcon'
}
</script>

<style scoped>
.cls-1 {
  fill: url(#menu-handles-gradient);
}
</style>
