import * as types from '../mutation-types'
import Vue from 'vue'
import { withHistoryMutation } from '../history-helper'

import config from '@/config'

const initState = () => {
  return {
    path: null,
    mouse: { x1: 0, y1: 0, x2: 0, y2: 0 },
    keyboardInput: 0
  }
}

const state = () => initState()

const getters = {}

const actions = {
  addNewPointToPseudoPath (ctx, point) {
    const _id = ctx.state.path.lastPointId + 1
    point.setID(`${ctx.state.path.id.replace(config.objects.ID, '')}-${_id}`)
    ctx.commit(types.UPDATE_LAST_PSEUDO_POINT_ID, _id)
    ctx.commit(types.ADD_PSEUDO_POINT_TO_PATH, point)
  },
  setKeyboardInput ({ commit }, input) {
    commit(types.SET_PSEUDO_KEYBOARD_INPUT, input)
  }
}

const mutations = withHistoryMutation({
  [types.SET_PSEUDO_KEYBOARD_INPUT] (state, input) {
    state.keyboardInput = input
  },
  [types.SET_PSEUDO_PATH] (state, path) {
    state.path = path
  },
  [types.UPDATE_LAST_PSEUDO_POINT_ID] (state, _id) {
    state.path.lastPointId = _id
  },
  [types.REMOVE_LAST_PSEUDO_POINT] (state) {
    const _lastPoint = state.path.removeLastPoint()
    state.mouse.x1 = _lastPoint.x
    state.mouse.y1 = _lastPoint.y
  },
  [types.ADD_PSEUDO_POINT_TO_PATH] (state, point) {
    state.path.addPoint(point)
  },
  [types.UPDATE_PSEUDO_MOUSE_COORDINATES] (state, coordinates) {
    for (const coord in coordinates) {
      if (Object.prototype.hasOwnProperty.call(state.mouse, coord)) {
        Vue.set(state.mouse, coord, coordinates[coord])
      }
    }
  }
}, initState)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
