import config from '@/config'
import SVG from 'svg.js'
import Paper from 'paper'

import { Adhesive, Infill, Seamtape, Subbase, Turf, TurfTrack } from '@/classes/products'
import MixinMath from '@/mixins/math'

/**
 * Class producing paths from Points
 * @class PathFactory
 */
class PathFactory {
  /**
     * Creates string path from array of internal points
     * @param {Point[]} points
     * @returns {string}
     */
  static makeInternalPathFromPoints (points) {
    let d = ''

    for (const point in points) {
      const _p = points[point]

      switch (_p.type) {
        case 'M':
        case 'L':
          d += `${_p.type} ${_p.x} ${_p.y} `
          break
        case 'C':
          d += `${_p.type} ${_p.handles.x1} ${_p.handles.y1} ${_p.handles.x2} ${_p.handles.y2} ${_p.x} ${_p.y} `
          break
        case 'Q':
          d += `${_p.type} ${_p.handles.x1} ${_p.handles.y1} ${_p.x} ${_p.y} `
          break
        case 'Z':
          d += 'Z'
          break
      }
    }

    return d
  }

    /**
     * Return reverse points
     * @param {Point[]} points
     * @returns {Point[]} reversed points
     */
    static sortClockwise = (points) => {
      return points.reverse()
    }

    /**
     * Creates new instance from given class name
     * @param {string} className
     * @returns {Path|PathChild|Point} new class
     */
    static createFromClassName = (className) => {
      switch (className) {
        case ('Path'):
          return new Path()
        case ('PathChild'):
          return new PathChild()
        case ('Point'):
          return new Point()
        case ('Turf'):
          return new Turf()
        case ('TurfTrack'):
          return new TurfTrack()
        case ('Subbase'):
          return new Subbase()
        case ('Infill'):
          return new Infill()
        case ('Seamtape'):
          return new Seamtape()
        case ('Adhesive'):
          return new Adhesive()
      }
    }

    /**
     * Creates "full" objects form given json objects
     * @param {JSON} objects
     * @returns {object[]}
     */
    static createFromJsonObjects = (objects) => {
      const res = []
      if (objects !== null && objects !== undefined) {
        objects.forEach(obj => {
          const objInstance = PathFactory.createFromClassName(obj.className)
          for (const property in obj) {
            if (property === 'children' || property === 'points' || property === 'tracks') {
              objInstance[property] = PathFactory.createFromJsonObjects(obj[property])
            } else if (property === 'product') {
              if (obj[property] !== null) {
                const productInstance = PathFactory.createFromClassName(obj[property].className)
                for (const productProperty in obj[property]) {
                  productInstance[productProperty] = obj[property][productProperty]
                }
                objInstance[property] = productInstance
                objInstance[property].tracks = PathFactory.createFromJsonObjects(obj[property].tracks)
                objInstance[property].subbases = PathFactory.createFromJsonObjects(obj[property].subbases)
                objInstance[property].infills = PathFactory.createFromJsonObjects(obj[property].infills)
              }
            } else {
              objInstance[property] = obj[property]
            }
          }
          res.push(objInstance)
        })
        return res
      }
    }
}

/**
 * Basic Point class
 * Visually it's represented by interactive path nodes
 * @class Point
 */
class Point {
  constructor (x, y, type = 'L', id) {
    this.id = id ? null : id
    this.x = parseFloat(x)
    this.y = parseFloat(y)
    this.type = type
    this.handles = {}
    this.className = 'Point'
  }

  /**
     * Set the id by number
     * @param {String} id
     */
  setID (id) {
    this.id = `point-${id}`
  }

  /**
     * Set x y position
     * @param {number} x
     * @param {number} y
     */
  setXY (x, y) {
    this.x = x
    this.y = y
  }

  /**
     * Get this as json object
     * @returns {{x: number, y: number}}
     */
  getPoint () {
    return {
      x: this.x,
      y: this.y
    }
  }

  /**
     * Set the point type (more: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d)
     * @param {string} type
     */
  setType (type) {
    this.type = type
  }

  /**
     * Set the handles
     * @param {number} handles.x2
     * @param {number} handles.y2
     * @param {number} handles.x1
     * @param {number} handles.y1
     */
  setHandles (handles) {
    this.handles = handles
  }
}

/**
 * Basic Path class
 * It contains a collection of Points as well as Child elements (also Paths)
 * @class BasePath
 */
class BasePath {
  constructor (id, tolerance = 10, points = [], children = []) {
    this.id = id
    this.name = null
    this.tolerance = tolerance
    this.points = points
    this.children = children
  }

  getName () {
    return (this.name) ? this.name : this.id.replace(config.objects.ID, '')
  }

  /**
     * Gets the nearest object point to a given position (e.g. mouse)
     * @param {number} position.x
     * @param {number} position.y
     * @returns {Point}
     */
  nearestPointToPosition (position) {
    let min = null; let point = null
    this._relevantPoints().forEach(rP => {
      const d = MixinMath.methods.mx_math_getDistance(rP, position)
      if (d < min || min === null) {
        min = d
        point = rP
      }
    })
    return point
  }

  /**
     * Get points that collide with given points
     * @param {Point[]} points
     * @param translate
     * @param {number} translate.x
     * @param {number} translate.y
     * @param {number} tolerance
     * @returns {Point[]}
     */
  collisionPoints (points, translate, tolerance) {
    let res = []
    for (let i = 0; i < points.length; i++) {
      res = res.concat(this.collisionPoint(points[i], tolerance, translate, true))
    }
    return res
  }

  /**
     * Check if points of object collide with given point
     * @param {Point} p1
     * @param {number} tolerance
     * @param translate
     * @param {number} translate.x
     * @param {number} translate.y
     * @param {boolean} notReverse
     * @returns {{p1: Point, p2: Point}[]} pointsArray
     */
  collisionPoint (p1, tolerance, translate, notReverse) {
    let rp = this._relevantPoints()
    this.children.map(child => { rp = rp.concat(child._relevantPoints()) })
    return rp
      .filter(p2 => this._collisionTest(
        p1,
        p2,
        translate,
        tolerance || this.tolerance))
      .map(p2 => {
        return notReverse ? { p1: p1, p2: p2 } : { p1: p2, p2: p1 }
      })
  }

  /**
     * Check if handle points of object collide with given point
     * @param {Point} p1
     * @param {number} tolerance
     * @param translate
     * @param {number} translate.x
     * @param {number} translate.y
     * @returns {{p1: Point, p2:Point, handle: Point}[]}
     */
  collisionHandlePoint (p1, tolerance, translate) {
    const res = []
    this._relevantHandlePoints().forEach(p2 => {
      if (p2.type === 'C') {
        const p21 = { x: p2.handles.x1, y: p2.handles.y1 }
        if (this._collisionTest(p1, p21, translate, tolerance || this.tolerance)) {
          res.push({ p1: p2, p2: p1, handle: 1 })
        }
        const p22 = { x: p2.handles.x2, y: p2.handles.y2 }
        if (this._collisionTest(p1, p22, translate, tolerance || this.tolerance)) {
          res.push({ p1: p2, p2: p1, handle: 2 })
        }
      }
      if (p2.type === 'Q') {
        const p21 = { x: p2.handles.x1, y: p2.handles.y1 }
        if (this._collisionTest(p1, p21, translate, tolerance || this.tolerance)) {
          res.push({ p1: p2, p2: p1, handle: 1 })
        }
      }
    })

    return res
  }

  /**
     * Check if this object has children
     * @returns {boolean}
     */
  hasChildren () {
    return this.children.length > 0
  }

  /**
     * Get a single point of object by id
     * @param {string} pointId
     * @returns {null|Point}
     */
  getPoint (pointId) {
    const point = this.points.find(point => {
      if (point.id === pointId) {
        return point
      }
    })
    if (point !== undefined) {
      return point
    }
    return null
  }

  /**
     * Return an index of a given point
     * @param {string} pointId
     * @returns {null|number}
     */
  getPointIndex (pointId) {
    const index = this.points.findIndex(point => {
      return point.id === pointId
    })

    if (index !== undefined) {
      return index
    }
    return null
  }

  /**
     * Get the points
     * @returns {Point[]}
     */
  getPoints () {
    return this.points
  }

  /**
     * Get Points of children
     *
     * @returns {Point[][]|*[]}
     */
  getChildrenPoints () {
    if (this.hasChildren()) {
      return this.children.map(child => {
        return child.getPoints()
      })
    }

    return []
  }

  /**
     * Get all child paths
     *
     * @returns {*[]}
     */
  getChildrenPaths () {
    if (this.hasChildren()) {
      return this.children.map(child => {
        return child.d(true)
      })
    }

    return []
  }

  /**
     * Returns a point before a certain point id
     * @param {string} pointId
     * @returns {Point}
     */
  getPointBefore (pointId) {
    const key = this.points.findIndex(point => {
      return point.id === pointId
    })
    let keyBefore = key - 1
    keyBefore = (keyBefore !== 0 && keyBefore !== this.points.length - 1) ? keyBefore : this.points.length - 2

    return this.points[keyBefore]
  }

  /**
     * Add a point after a certain point
     * @param {Point} point
     * @param {string} pointId
     */
  addPointAfter (point, pointId) {
    const key = this.points.findIndex(point => {
      return point.id === pointId
    })
    this.points.splice(key, 0, point)
  }

  /**
     * Remove a certain point
     * @param {number} index
     * @return {Point[]} newPoints
     */
  removePoint (index) {
    this.points.splice(index, 1)
    const newPoints = this.points

    if (index === this.points.length - 1) {
      newPoints[0].x = MixinMath.methods.mx_math_roundDecimal(newPoints[index - 1].x, 2)
      newPoints[0].y = MixinMath.methods.mx_math_roundDecimal(newPoints[index - 1].y, 2)
    }
    return newPoints
  }

  /**
     * Relevant points - without M and Z points
     * @returns {Point[]}
     * @private
     */
  _relevantPoints () {
    return this.points.filter(item => item.type !== 'M' && item.type !== 'Z')
  }

  /**
     * Relevant points - without M, Z and L points
     * @returns {Point[]}
     * @private
     */
  _relevantHandlePoints () {
    return this.points.filter(item => item.type !== 'M' && item.type !== 'Z' && item.type !== 'L')
  }

  /**
     * Test a single collision
     * @param {Point} p1
     * @param {Point} p2
     * @param translate
     * @param {number} translate.x
     * @param {number} translate.y
     * @param {number} tolerance
     * @returns {boolean}
     * @private
     */
  _collisionTest (p1, p2, translate, tolerance) {
    return (p1.x - tolerance < p2.x + translate.x && p2.x + translate.x < p1.x + tolerance) &&
            (p1.y - tolerance < p2.y + translate.y && p2.y + translate.y < p1.y + tolerance)
  }

  /**
     * Returns child element by given point
     * @param {string} pointId
     * @returns {PathChild}
     */
  findChildByPoint (pointId) {
    return this.children.find(child => {
      return child.points.find(point => {
        return (point.id === pointId)
      })
    })
  }

  /**
     * Removes a certain child element from object
     * @param {BasePath} obj
     * @param {string} id
     */
  removeChildFromObject (obj, id) {
    const index = obj.children.findIndex(cld => {
      return (cld.id === id)
    })
    obj.children.splice(index, 1)
  }
}

/**
 * Standard Child class which represents a child element of other larger objects
 * Basically it's just a hole in parent object
 * @class PathChild
 * @extends BasePath
 */
class PathChild extends BasePath {
  constructor (id, points) {
    super(id, 10, points)
    this.lastPointId = 0
    this.className = 'PathChild'
  }

  /**
     * String representation of path
     * @returns {string}
     */
  d () {
    return PathFactory.makeInternalPathFromPoints(this.points)
  }

  /**
     * Return amount of points
     * @returns {number}
     */
  getPointsCount () {
    return this.points.length
  }
}

/**
 * Standard Path class
 * @class Path
 * @extends BasePath
 */

class Path extends BasePath {
  constructor (id, tolerance = 10, points = [], children = [], lastPointId = null, selectionMode = config.objects.modes.UNSELECTED, area = null) {
    super(config.objects.ID + id, tolerance, points, children)
    this.lastPointId = 0
    this.lastChildId = 0
    this.className = 'Path'
    this.type = config.objects.types.PATH
    this.isCutOut = false
    this.area = 0
    this.orderBy = {
      layer: 0,
      layerOrder: 0,
      position: 0
    }
    this.selectionMode = selectionMode
    this.product = null
    this.productNeedUpdate = true
  }

  // TODO: REMOVE price() !!!
  /**
     * Returns price of a product
     * @return {null|number}
     */
  price () {
    if (this.product !== null && !this.productNeedUpdate) {
      return Number(parseFloat(this.product.attributes.price.each * this.product.attributes.roll.length * this.calculation.turf_data.used).toFixed(2))
      // return Number(parseFloat(this.product.attributes.price.each * this.calculation.turf_data.edge_length)).toFixed(2)
    }

    return null
  }

  /**
     * Returns rest (waste) area of used rolls for a product
     * @return {null|number}
     */
  blend () {
    if (this.product !== null && !this.productNeedUpdate) {
      const rolls_area = (this.product.attributes.roll.width * this.calculation.turf_data.edge_length)

      return Number((rolls_area - this.area).toFixed(2))
    }

    return null
  }

  /**
     * Checks if the point is inside of a path
     * @param {Point} point
     * @return {boolean}
     */
  isPointInsidePath (point) {
    let cp = null
    const p = new Paper.Point(point.x, point.y)
    switch (this.type) {
      case config.objects.types.PATH:
        cp = new Paper.CompoundPath(this.d())
        break
      case config.objects.types.CIRCLE: {
        const props = this.getCircleProperties()
        cp = new Paper.CompoundPath(new Paper.Path.Circle({
          center: [props.middle.x, props.middle.y],
          radius: props.radius
        }))
        break
      }
    }

    if (cp !== null) {
      return p.isInside(cp.bounds)
    }

    return false
  }

  /**
     * Returns string representation of path or circle
     * @return {string}
     */
  path () {
    let d = ''
    switch (this.type) {
      case config.objects.types.PATH:
        d = this.d()
        break
      case config.objects.types.CIRCLE:
        d = this.getCircleD()
        break
    }

    return d
  }

  /**
     * String representation of path
     * @param {boolean} withoutChildren
     * @param {string[]} childId
     * @returns {string}
     */
  d (withoutChildren = false, childId = null) {
    let childrenPath = ''
    if (this.hasChildren() && !withoutChildren) {
      this.children.forEach((child) => {
        if (childId === null) {
          childrenPath += child.d()
        } else if (child.id !== `object-${childId[1]}-${childId[2]}`) {
          childrenPath += child.d()
        }
      })
    }

    return PathFactory.makeInternalPathFromPoints(this.points) + childrenPath
  }

  /**
     * Get the circle center
     * @returns {Point[]}
     */
  getCircleCenter () {
    const points = this.points.filter(point => point.type !== 'M' && point.type !== 'Z')

    return points[0]
  }

  /**
     * Get the circle properties
     * @returns {{middle: Point, radius: (number)}}
     */
  getCircleProperties () {
    const points = this.points.filter(point => point.type !== 'M' && point.type !== 'Z')

    return {
      middle: points[0],
      radius: (points !== null && points !== undefined) ? MixinMath.methods.mx_math_roundDecimal(
        MixinMath.methods.mx_math_getDistance(points[0], points[1])) : 0
    }
  }

  /**
     * Get the string representation of the circle
     * @returns {string}
     */
  getCircleD () {
    const data = this.getCircleProperties()

    return `M ${data.middle.x} ${data.middle.y} m-${data.radius} 0 a${data.radius},${data.radius} 0 1 0 ${data.radius *
        2} 0 a${data.radius} ${data.radius} 0 1 0 -${data.radius * 2} 0`
  }

  /**
     * Add a point to array of points
     * @param {Point} point
     */
  addPoint (point) {
    this.points.push(point)
  }

  /**
     * Remove the last point and returns coordinates of actual last point
     * @returns {Point}
     */
  removeLastPoint () {
    this.points.pop()
    return this.points[this.points.length - 1]
  }

  /**
     * Check if this has at least a certain amount of points (or more)
     * @param {number} smallerThan
     * @returns {boolean}
     */
  hasPoints (smallerThan = 1) {
    return this.points.length > smallerThan
  }

  /**
     * Get a point by index number
     * @param {number} index
     * @returns {Point}
     */
  getPointByIndex (index) {
    return this.points[index]
  }

  /**
     * Update a point
     * @param {number} index
     * @param {Point} point
     */
  updatePoint (index, point) {
    this.points[index] = point
  }

  /**
     * Test collision
     * @param {Point} point
     * @param translate
     * @param {number} translate.x
     * @param {number} translate.y
     * @returns {{hit: boolean, point: *}|{hit: boolean, point: null}}
     */
  hitTest (point, translate = { x: 0, y: 0 }) {
    if (this.hasPoints()) {
      if (this._collisionTest(point, this.points[0], translate, this.tolerance)) {
        return { point: this.points[1], hit: true }
      }
    }
    return { point: null, hit: false }
  }

  /**
     * Scale the object
     * @param {object} distance
     * @param {object} objBbox
     * @param {object} Bbox
     * @param {string} direction Possible directions are 'tr', 'br', 'bl' and 'tl', where
     * @param {boolean} proportional
     * 'tr' - top right,
     * 'br' - bottom right,
     * and so on ...
     */
  scale (distance, objBbox, Bbox, direction, proportional = true) {
    let scaleX, scaleY

    // Object position x,y
    let nx = objBbox.x; let ny = objBbox.y

    // Differences of the bounding-box to the object-bounding-box
    const diffX = Bbox.x - objBbox.x
    const diffY = Bbox.y - objBbox.y

    // Scale factor of the bounding-box
    scaleX = Math.abs(distance.x / Bbox.width)
    scaleY = Math.abs(distance.y / Bbox.height)
    const distanceX = distance.x
    const distanceY = distance.y

    if (proportional) {
      scaleX = scaleY = Math.abs(distanceX / Math.max(Bbox.width, Bbox.height))
    }

    // Check which edge of the bounding-box was clicked and resize the element(s) according to this
    switch (direction) {
      // Top-Middle
      case 'tm':
        scaleY = Math.abs(distance.y / Bbox.height)

        scaleX = 1
        scaleY = (distanceY > 0 ? 1 - scaleY : 1 + scaleY)

        ny -= (Bbox.height + diffY) * (scaleY - 1)
        break
        // Bottom-Middle
      case 'bm':
        scaleY = Math.abs(distance.y / Bbox.height)
        scaleX = 1
        scaleY = (distanceY < 0 ? 1 - scaleY : 1 + scaleY)
        break
        // Left-Middle
      case 'lm':
        scaleX = Math.abs(distance.x / Bbox.width)
        scaleX = (distanceX > 0 ? 1 - scaleX : 1 + scaleX)
        scaleY = 1

        nx -= (Bbox.width + diffX) * (scaleX - 1)
        break
      case 'rm':
        scaleX = Math.abs(distance.x / Bbox.width)
        scaleX = (distanceX < 0 ? 1 - scaleX : 1 + scaleX)
        scaleY = 1
        break
        // Bottom-Right
      case 'br':
        if (!proportional) {
          scaleX = (distanceX < 0 ? 1 - scaleX : 1 + scaleX)
          scaleY = (distanceY < 0 ? 1 - scaleY : 1 + scaleY)
        } else {
          scaleX = scaleY = (distanceX < 0 ? 1 - scaleX : 1 + scaleX)
        }

        nx -= diffX * (scaleX - 1)
        ny -= diffY * (scaleY - 1)
        break
        // Top-Right
      case 'tr':
        if (!proportional) {
          scaleX = (distanceX < 0 ? 1 - scaleX : 1 + scaleX)
          scaleY = (distanceY > 0 ? 1 - scaleY : 1 + scaleY)
        } else {
          scaleX = scaleY = (distanceX < 0 ? 1 - scaleX : 1 + scaleX)
        }

        nx -= diffX * (scaleX - 1)
        ny -= (Bbox.height + diffY) * (scaleY - 1)
        break
        // Bottom-Left
      case 'bl':
        if (!proportional) {
          scaleX = (distanceX > 0 ? 1 - scaleX : 1 + scaleX)
          scaleY = (distanceY < 0 ? 1 - scaleY : 1 + scaleY)
        } else {
          scaleX = scaleY = (distanceX > 0 ? 1 - scaleX : 1 + scaleX)
        }

        nx -= (Bbox.width + diffX) * (scaleX - 1)
        ny -= diffY * (scaleY - 1)
        break
        // Top-Left
      case 'tl':
        if (!proportional) {
          scaleX = (distanceX > 0 ? 1 - scaleX : 1 + scaleX)
          scaleY = (distanceY > 0 ? 1 - scaleY : 1 + scaleY)
        } else {
          scaleX = scaleY = (distanceX > 0 ? 1 - scaleX : 1 + scaleX)
        }

        nx -= (Bbox.width + diffX) * (scaleX - 1)
        ny -= (Bbox.height + diffY) * (scaleY - 1)
        break
    }

    // Calculate new object width and height
    const nw = objBbox.width * scaleX; const nh = objBbox.height * scaleY

    if (nw > 0 && nh > 0) {
      this._scaleObject(nw, nh, nx, ny)
    }
  }

  // PRIVATE ---------------------------------------------------------------------------------------------------------

  /**
     * Scale object
     * @param {number} nw
     * @param {number} nh
     * @param {number} nx
     * @param {number} ny
     * @private
     */
  _scaleObject (nw, nh, nx, ny) {
    let pa = new SVG.PathArray(this.path()).size(nw, nh).move(nx, ny)
    pa = pa.value

    const objectPoints = pa.slice(0, this.points.length)

    if (this.type === config.objects.types.CIRCLE) {
      const mP = objectPoints[0]
      const rP = objectPoints[2]

      this._updateCirclePointsByScale(mP, rP)
    } else {
      objectPoints.forEach((point, index) => {
        const origPoint = this.points[index]
        this._updatePointsByScale(origPoint, point)
      })

      if (this.children && this.children.length > 0) {
        let lastLength = objectPoints.length
        this.children.forEach((child) => {
          const childPoints = pa.slice(lastLength, child.getPointsCount() + lastLength)
          childPoints.forEach((point, index) => {
            const origPoint = child.points[index]
            this._updatePointsByScale(origPoint, point)
          })
          lastLength += child.getPointsCount()
        })
      }
    }
  }

  /**
     * Update circle points after scaling
     * @param {array} mP middle point
     * @param {array} rP radius point
     * @private
     */
  _updateCirclePointsByScale (mP, rP) {
    this.points.map((point, index) => {
      if (index === 1) {
        point.x = MixinMath.methods.mx_math_roundDecimal(mP[1])
        point.y = MixinMath.methods.mx_math_roundDecimal(mP[2])
      }
      if (index === 0 || index === 2) {
        point.x = MixinMath.methods.mx_math_roundDecimal(rP[6])
        point.y = MixinMath.methods.mx_math_roundDecimal(rP[7])
      }
    })
  }

  /**
     * Update the points by scale from a given original point
     * @param {Point} origPoint
     * @param {Point} point
     * @private
     */
  _updatePointsByScale (origPoint, point) {
    switch (origPoint.type) {
      case ('Q'):
        origPoint.x = MixinMath.methods.mx_math_roundDecimal(point[3])
        origPoint.y = MixinMath.methods.mx_math_roundDecimal(point[4])
        origPoint.handles = {
          x1: MixinMath.methods.mx_math_roundDecimal(point[1]),
          y1: MixinMath.methods.mx_math_roundDecimal(point[2]),
          x2: undefined,
          y2: undefined
        }
        break
      case ('C'):
        origPoint.x = MixinMath.methods.mx_math_roundDecimal(point[5])
        origPoint.y = MixinMath.methods.mx_math_roundDecimal(point[6])
        origPoint.handles = {
          x1: MixinMath.methods.mx_math_roundDecimal(point[1]),
          y1: MixinMath.methods.mx_math_roundDecimal(point[2]),
          x2: MixinMath.methods.mx_math_roundDecimal(point[3]),
          y2: MixinMath.methods.mx_math_roundDecimal(point[4])
        }
        break
      default:
        origPoint.x = MixinMath.methods.mx_math_roundDecimal(point[1])
        origPoint.y = MixinMath.methods.mx_math_roundDecimal(point[2])
        break
    }
  }
}

/**
 * Reference line point class
 * @class RefPoint
 */
class RefPoint {
  constructor (id, x, y) {
    this.id = `ref-point-${id}`
    this.x = parseFloat(x)
    this.y = parseFloat(y)
  }

  /**
     * Set position
     * @param {number} x
     * @param {number} y
     */
  setXY (x, y) {
    this.x = x
    this.y = y
  }
}

/**
 * Ruler line point class
 * @class RulerPoint
 */
class RulerPoint {
  constructor (id, x, y) {
    this.id = `ruler-point-${id}`
    this.x = parseFloat(x)
    this.y = parseFloat(y)
  }
}

export { PathChild, Point, Path, RefPoint, RulerPoint, PathFactory }
