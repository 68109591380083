import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'
import i18n from '@/i18n/language'

/**
 * Default state for: User Store
 *
 * @returns {{me: {firstname: null, role: null, updated_at: null, created_at: null, id: null, email: null, lastname: null, group: null}}}
 */
const defaultState = () => {
  return {
    me: {
      id: null,
      role: null,
      salutation: null,
      firstname: null,
      lastname: null,
      email: null,
      phone: null,
      group: null,
      created_at: null,
      updated_at: null
    }
  }
}

const state = defaultState()

const getters = {
  /**
     * Is user data set
     *
     * @returns {boolean}
     */
  hasStateSet (state) {
    return (state.me.id !== null && state.me.role !== null && state.me.group instanceof Object)
  },
  /**
     * Get user
     *
     * @param state
     * @returns {{firstname: null, role: null, updated_at: null, created_at: null, id: null, email: null, lastname: null, group: null}}
     */
  get (state) {
    return state.me
  },
  /**
     * Get user role
     *
     * @param state
     * @returns {String}
     */
  getRole (state) {
    return state.me.role
  },
  /**
     * Get user group
     *
     * @param state
     * @returns {Object}
     */
  getGroup (state) {
    return state.me.group
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_USER_STATE)
  },
  /**
     * Set current logged-in user
     * Roles: all
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async me ({ rootState, dispatch, commit }) {
    const data = await apiMethods.request('get', routes.user + '/me', null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.GLOBAL_USER_SET_ME, response.data.data)
        dispatch('language/init', null, { root: true })
        if (response.data.data.group !== null) {
          dispatch('backendAPI/group/readSettings', response.data.data.group.id, { root: true })
        }
        return {
          role: response.data.data.role,
          errors: false
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return {
          errors: true
        }
      })

    return data.errors
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param state
     * @param {{userId: Number,firstname: String, lastname: String}}data
     * @returns {Promise<boolean>}
     */
  async updateMe ({ rootState, dispatch, commit, state }, data) {
    return await apiMethods.request('patch', routes.user + `/std/${data.userId}`, {
      salutation: data.salutation,
      firstname: data.firstname,
      lastname: data.lastname,
      phone: data.phone
    }, rootState.backendAPI.login.token)
      .then(response => {
        if (state.me.id === response.data.data.id) {
          commit(types.GLOBAL_USER_SET_ME, response.data.data)
        }

        return true
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} userId
     * @returns {Promise<boolean|Object>}
     */
  async read ({ rootState, dispatch, commit }, userId) {
    return await apiMethods.request('get', routes.user + `/std/${userId}`)
      .then(response => {
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  },
  /**
     *
     * Roles: Manufacture, Distributor
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {String} user_email
     * @returns {Promise<>}
     */
  async resendInvitation ({ rootState, dispatch, commit }, user_email) {
    return await apiMethods.request('post', routes.user + '/resend_email', {
      email: user_email
    }, rootState.backendAPI.login.token)
      .then(response => {
        return response.data.resendInvitation
      })
      .catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  /**
     *
     * Roles: Any
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {String} user_email
     * @returns {Promise<boolean|Object>}
     */
  async resetPassword ({ rootState, dispatch, commit }, user_email) {
    return await apiMethods.request('post', routes.user + '/password/request_reset_mail', {
      email: user_email
    }, rootState.backendAPI.login.token)
      .then(response => {
        return { error: false, message: i18n.t('login.success') }
      })
      .catch(error => {
        if (error.response.status === 422) {
          return { error: true, message: i18n.t('errors.login.toShortTime') }
        } else {
          commit(types.COMMON_SET_ERROR, error)
          return false
        }
      })
  },
  /**
     *
     * Roles: Manufacture, Distributor
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{userId: Number, enabled: Boolean}}data
     * @returns {Promise<boolean>}
     */
  async enable ({ rootState, dispatch, commit }, data) {
    return await apiMethods.request('patch', routes.user + `/${data.userId}/enable`, {
      enabled: data.enabled
    }, rootState.backendAPI.login.token)
      .then(response => {
        dispatch('backendAPI/employee/update', response.data.data, { root: true })
        dispatch('backendAPI/distributor/update', response.data.data, { root: true })
        dispatch('backendAPI/manufacture/update', response.data.data, { root: true })
        dispatch('backendAPI/admin/update', response.data.data, { root: true })
        return true
      })
      .catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  /**
     *
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} user_id
     * @returns {Promise<boolean>}
     */
  async flagAsDistributor ({ rootState, dispatch, commit }, user_id) {
    return await apiMethods.request('patch', routes.user + `/${user_id}/flag_as_distributor`, null,
      rootState.backendAPI.login.token)
      .then(response => {
        dispatch('backendAPI/employee/delete', response.data.data.id, { root: true })
        return true
      })
      .catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  /**
     *
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} user_id
     * @returns {Promise<boolean>}
     */
  async flagAsEmployee ({ rootState, dispatch, commit }, user_id) {
    return await apiMethods.request('patch', routes.user + `/${user_id}/flag_as_employee`, null,
      rootState.backendAPI.login.token)
      .then(response => {
        dispatch('backendAPI/distributor/delete', response.data.data.id, { root: true })
        return true
      })
      .catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  /**
     *
     * Roles: Manufacture, Distributor
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} user_id
     * @returns {Promise<boolean>}
     */
  async delete ({ rootState, dispatch, commit }, user_id) {
    return await apiMethods.request('delete', routes.user + `/std/${user_id}`, null,
      rootState.backendAPI.login.token)
      .then(response => {
        dispatch('backendAPI/employee/delete', response.data.data.id, { root: true })
        dispatch('backendAPI/distributor/delete', response.data.data.id, { root: true })
        dispatch('backendAPI/manufacture/delete', response.data.data.id, { root: true })
        dispatch('backendAPI/admin/delete', response.data.data.id, { root: true })
        return true
      })
      .catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return false
      })
  },
  clearUserMe ({ commit }) {
    commit(types.GLOBAL_USER_SET_ME, null)
  }
}

const mutations = {
  [types.RESET_USER_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.GLOBAL_USER_SET_ME] (state, me) {
    apiMethods.updateStoreObjectProperties(state.me, me)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
