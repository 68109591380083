import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

import MixinModifier from '@/mixins/create/modifier'
import MixinElements from '@/mixins/create/elements'
import MixinMath from '@/mixins/math'
import MixinTrackState from '@/mixins/trackstate'

/**
 * Creates the drawn elements
 * @displayName Create
 */
export default {
  name: 'CreateMixin',
  data: () => {
    return {
      mx_create_modifierHover: null,
      mx_create_activeModifier: undefined,
      mx_create_activeModifierHandle: null,
      mx_create_isActiveModifierChild: false,
      mx_create_draggedModifier: false,
      mx_create_draggedModifierHandle: false,
      /** @private **/
      $_mx_create_dblclic: false
    }
  },
  mixins: [
    MixinElements, MixinModifier,
    MixinMath, MixinTrackState
  ],
  mounted () {
    this.$events.on(editorEventTypes.OBJECT_MODIFIER_OPEN_MENU, this.$_mx_create_openModifierMenu)
    this.$events.on(editorEventTypes.OBJECT_MODIFIER_DRAG, this.mx_create_dragModifier)
    this.$events.on(editorEventTypes.OBJECT_HANDLE_MODIFIER_DRAG, this.mx_create_dragModifierHandle)
    this.$events.on(editorEventTypes.OBJECT_ESCAPE_PRESSED, this.mx_create_hide_menu)
  },
  computed: {
    ...mapState({
      /** @private **/
      $_mx_create_selectedElement: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      mx_create_isObjectSelected: 'events/isObjectSelected',
      mx_create_isPointMoving: 'events/isPointMoving'
    })
  },
  methods: {
    ...mapActions({
      /** @private **/
      $_mx_create_setMouseMenu: 'events/openContextMenu'
    }),
    ...mapMutations({
      /** @private **/
      $_mx_create_hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU,
      /** @private **/
      $_mx_create_setActiveModifier: 'events/' + mutationTypes.EVT_SET_CURRENT_ACTIVE_MODIFIER,
      /** @private **/
      $_mx_create_resetActiveModifier: 'events/' + mutationTypes.EVT_RESET_CURRENT_ACTIVE_MODIFIER,
      /** @private **/
      $_mx_create_addNewObjectPoint: 'project/objects/' + mutationTypes.ADD_NEW_OBJECT_POINT
    }),
    /**
         * Create object handle circles
         * @requires @/mixins/create/elements
         * @param points
         * @param child
         * @returns {*}
         * @public
         */
    mx_create_createCirclesFromPoints (points, child = false) {
      let _circles = points.map((point, index, points) => {
        if (point.type !== 'M' && point.type !== 'Z' && index <= points.length - 1) {
          const circle = this.mx_create_elements_circle(point.id, point.x, point.y, child)

          /**
                     * Quadratic
                     */
          if (point.type === 'Q' || point.type === 'q') {
            const qHandle = this.mx_create_elements_quadratic(point.id, point.handles, child)

            return this.mx_create_elements_group([
              circle,
              qHandle
            ])
          }

          /**
                     * Cubic
                     */
          if (point.type === 'C' || point.type === 'c') {
            const cHandle = this.mx_create_elements_cubic(point.id, point.handles, child)

            return this.mx_create_elements_group([
              circle,
              cHandle[0],
              cHandle[1]
            ])
          }

          /**
                     * Default Circle
                     */
          return circle
        }
      })

      _circles = _circles.filter(item => {
        if (item !== undefined) {
          return item
        }
      })

      return _circles
    },
    /**
         * Create lines between circles
         * @requires @/mixins/create/elements
         * @param circles
         * @param child
         * @returns {*}
         * @public
         */
    mx_create_createLinesFromCircles (circles, child = false) {
      let _lines = circles.map((circle, index, _circles) => {
        let _c = (circle.tag === 'g') ? circle.children : circle
        let _n = (index === 0) ? _circles[_circles.length - 1] : _circles[index - 1]

        _n = (_n.tag === 'g') ? _n.children[0].data : _n.data

        const _keys = { a: _n.key, b: _c.key }

        if (circle.tag === 'g') {
          let _d = null
          const _point = _c[0].data.attrs
          _keys.b = _c[0].key

          /**
                     * Quadratic
                     */
          if (_c.length === 2) {
            const _handle = _c[1].data.attrs
            _d = `M ${_n.attrs.cx} ${_n.attrs.cy} Q ${_handle.cx} ${_handle.cy} ${_point.cx} ${_point.cy}`

            const qPath = this.mx_create_elements_modifierPath(_keys.b, _keys, _d, child)
            const line1 = this.mx_create_elements_handleLine(_keys.b, {
              x1: _handle.cx,
              y1: _handle.cy,
              x2: _n.attrs.cx,
              y2: _n.attrs.cy
            })
            const line2 = this.mx_create_elements_handleLine(_keys.b, {
              x1: _handle.cx,
              y1: _handle.cy,
              x2: _point.cx,
              y2: _point.cy
            })

            return [
              qPath,
              line1,
              line2
            ]
          }
          /**
                     * Cubic
                     */
          if (_c.length === 3) {
            const _handle1 = _c[1].data.attrs
            const _handle2 = _c[2].data.attrs
            _d = `M ${_n.attrs.cx} ${_n.attrs.cy} C ${_handle1.cx} ${_handle1.cy} ${_handle2.cx} ${_handle2.cy} ${_point.cx} ${_point.cy}`

            const cPath = this.mx_create_elements_modifierPath(_keys.b, _keys, _d, child)
            const line1 = this.mx_create_elements_handleLine(_keys.b, {
              x1: _handle1.cx,
              y1: _handle1.cy,
              x2: _n.attrs.cx,
              y2: _n.attrs.cy
            })
            const line2 = this.mx_create_elements_handleLine(_keys.b, {
              x1: _handle2.cx,
              y1: _handle2.cy,
              x2: _point.cx,
              y2: _point.cy
            })

            return [
              cPath,
              line1,
              line2
            ]
          }

          return undefined
        }
        /**
                 * Default Line
                 */
        _c = _c.data.attrs
        _n = _n.attrs
        return this.mx_create_elements_line(_keys.b, _keys, { x1: _n.cx, y1: _n.cy, x2: _c.cx, y2: _c.cy }, child)
      })

      _lines = _lines.filter(item => {
        if (item !== undefined) {
          return item
        }
      })

      return _lines
    },
    /**
         * Open the modifier menu with options
         * @param attrs
         * @private
         */
    $_mx_create_openModifierMenu (attrs) {
      if (!this.$data.$_mx_create_dblclic && this.mx_create_activeModifier !== attrs.id) {
        this.mx_create_selectModifier({ id: attrs.id, keys: attrs.keys })
      }
      if (!this.$data.$_mx_create_dblclic && (this.mx_create_activeModifier === attrs.id || this.mx_create_modifierHover === attrs.id)) {
        const point = this.mx_math_getPointerCoordinatesForMetaMenu(attrs.evt)
        this.$_mx_create_setMouseMenu({
          ctrl: attrs.ctrl,
          position: point,
          circle: attrs.id,
          object: this.object.id,
          isCutOut: this.object.isCutOut
        })
      }
    },
    /**
         * Select the modifier handle
         * @param attrs
         * @public
         */
    mx_create_selectModifier (attrs) {
      if (this.mx_create_isObjectSelected(this.object.id)) {
        if (this.mx_create_activeModifier !== attrs.id) {
          this.$_mx_create_hideMouseMenu()
          this.mx_create_activeModifier = attrs.id
          this.$_mx_create_setActiveModifier(attrs.id)
          this.mx_create_isActiveModifierChild = attrs.child
          this.mx_create_modifierHover = null
        }
      }
    },
    /**
         * Set the hover modifier
         * @param id
         * @public
         */
    mx_create_hoverModifier (id) {
      if (this.mx_create_isObjectSelected(this.object.id) && this.mx_create_activeModifier !== id) {
        this.mx_create_modifierHover = id
      }
    },
    /**
         * Handle when modifier is dragged
         * @param attrs
         * @public
         */
    mx_create_dragModifier (attrs) {
      if (attrs.drag === true) {
        this.mx_trackstate_beginStateTransaction()
      }
      if (this.mx_create_modifierHover) {
        this.mx_create_selectModifier({ id: attrs.id, child: attrs.child })
      }
      if (this.mx_create_activeModifier === attrs.id || !attrs.drag) {
        this.mx_create_draggedModifier = attrs.drag
      }
      if (attrs.drag === false) {
        this.mx_trackstate_endStateTransaction()
      }
    },
    /**
         * Handle when dragging a handle
         * @param attrs
         * @public
         */
    mx_create_dragModifierHandle (attrs) {
      if (attrs.drag === true) {
        this.mx_trackstate_beginStateTransaction()
      }
      if (this.mx_create_activeModifier === attrs.id || !attrs.drag) {
        this.$_mx_create_hideMouseMenu()
        this.mx_create_draggedModifierHandle = attrs.drag
        this.mx_create_activeModifierHandle = attrs.handleId
      }
      if (attrs.drag === false) {
        this.mx_trackstate_endStateTransaction()
      }
    },
    /**
         * Reset the object flags
         * @public
         */
    mx_create_reset () {
      this.$data.$_mx_create_dblclic = false
      this.mx_create_modifierHover = null
      this.mx_create_activeModifier = undefined
      this.mx_create_isActiveModifierChild = false
      this.mx_create_activeModifierHandle = null
      this.mx_create_draggedModifier = false
      this.mx_create_draggedModifierHandle = false
      this.$_mx_create_hideMouseMenu()
      this.$_mx_create_resetActiveModifier()
    },
    /**
         * Hide mouse menu
         * @public
         */
    mx_create_hide_menu () {
      this.$_mx_create_hideMouseMenu()
    }
  },
  watch: {
    $_mx_create_selectedElement: {
      handler: function (newObj, oldObj) {
        if (!newObj.includes(this.object.id)) {
          this.mx_create_reset()
        }
      },
      deep: true
    }
  }
}
