<template>
  <div>
    <material-update-helper
      v-slot="{formData}"
      :is-confirm-blocked="mx_validate_isConfirmBlocked"
      :items="getPedestals"
      :render-helper="renderHelper"
      item-type="pedestal"
    >
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage: 20,
          perPageDropdown: [10, 20, 50],
          nextLabel: texts.nextLabel,
          prevLabel: texts.prevLabel,
          rowsPerPageLabel: texts.rowsPerPageLabel,
          ofLabel: texts.ofLabel,
          pageLabel: texts.pageLabel, // for 'pages' mode
          allLabel: texts.allLabel,
        }"
        :rows="formData"
        :search-options="{
          enabled: true,
          skipDiacritics: true,
          placeholder: texts.searchLabel
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: [{field: 'original.name', type: 'asc'}]
        }"
        theme="melos"
      >
        <template #emptystate>
          <div
            class="u-m-size-40 c-backend__nothing-to-show"
          >
            <div class="c-typo__h3 c-backend__nothing-to-show">
              <icon classes="far fa-2x fa-folder-open" />
              <span>{{ $t('common.nothingToShow') }}</span>
            </div>
          </div>
        </template>

        <template #table-row="props">
          <span v-if="props.column.field ==='productNeedUpdate' && formData[props.row.originalIndex].original.hasOwnProperty('update_needed')">
            <button-base
              :id="`location-materials-pedestals-update-${props.row.originalIndex}`"
              :on-click-action="() => { return updateLocationPedestal(formData[props.row.originalIndex].overlay) }"
              :tooltip-disabled="false"
              classes="vgt-button vgt-button-start u-bgcolor-o"
              icon-classes="fa fa-sync"
            >
              <template #popup>
                <p v-html="$t('backend.materials.updateNeeded')" />
              </template>
            </button-base>
          </span>

          <span v-if="props.column.field ==='overlay.active'">
            <input-checkbox
              :id="`location-materials-pedestals-checked-${props.row.originalIndex}`"
              :key="`location-materials-pedestals-checked-${props.row.originalIndex}`"
              v-model="formData[props.row.originalIndex].overlay.active"
              :is-store-mode="false"
              style-component="2"
              @change="mx_validate_updateErrorsArray"
              @input="forceRerender"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </span>

          <span v-if="props.column.field ==='original.pim_id'">
            {{ props.formattedRow[props.column.field] }}
          </span>

          <span v-if="props.column.field ==='original.name'">
            {{ mx_product_in_language(props.row.original, ['name']) }}
          </span>

          <span v-if="props.column.field ==='overlay.price_each'">
            <input-field
              :id="`location-materials-pedestals-price-${props.row.originalIndex}`"
              :key="`location-materials-pedestals-price-${props.row.originalIndex}`"
              v-model="formData[props.row.originalIndex].overlay.price_each"
              :disabled="Boolean(formData[props.row.originalIndex].overlay.active) !== true"
              :input-classes="'c-input-field--table'"
              :is-store-mode="false"
              :validate="Boolean(formData[props.row.originalIndex].overlay.active) === true ?
                (v) => {return mx_validate_validateNotNegativeNumber(v)}
                :(v) => {return mx_validate_validateNotNegativeNumber(v) || mx_validate_validateEmpty(v)}"
              type="text"
              @change="mx_validate_updateErrorsArray"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </span>

          <span v-if="props.column.field ==='original.pedestal_width'">
            {{ props.formattedRow[props.column.field] }} {{ $t(`backend.materials.units.${pedestalSettings.pedestal_width.short}`) }}
          </span>

          <span v-if="props.column.field ==='overlay.pedestal_width'">
            <input-field
              :id="`location-materials-pedestals-width-${props.row.originalIndex}`"
              :key="`location-materials-pedestals-width-${props.row.originalIndex}`"
              v-model="formData[props.row.originalIndex].overlay.pedestal_width"
              :disabled="Boolean(formData[props.row.originalIndex].overlay.active) !== true"
              :input-classes="'c-input-field--table'"
              :is-store-mode="false"
              :validate="(v) => {return mx_validate_validatePositiveNumber(v) || mx_validate_validateEmpty(v)}"
              type="text"
              @change="mx_validate_updateErrorsArray"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </span>

          <span v-if="props.column.field ==='original.pedestal_length'">
            {{ props.formattedRow[props.column.field] }} {{ $t(`backend.materials.units.${pedestalSettings.pedestal_length.short}`) }}
          </span>

          <span v-if="props.column.field ==='overlay.pedestal_length'">
            <input-field
              :id="`location-materials-pedestals-length-${props.row.originalIndex}`"
              :key="`location-materials-pedestals-length-${props.row.originalIndex}`"
              v-model="formData[props.row.originalIndex].overlay.pedestal_length"
              :disabled="Boolean(formData[props.row.originalIndex].overlay.active) !== true"
              :input-classes="'c-input-field--table'"
              :is-store-mode="false"
              :validate="(v) => {return mx_validate_validatePositiveNumber(v) || mx_validate_validateEmpty(v)}"
              type="text"
              @change="mx_validate_updateErrorsArray"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </span>

          <span v-if="props.column.field ==='original.pedestal_thickness'">
            {{ props.formattedRow[props.column.field] }} {{ $t(`backend.materials.units.${pedestalSettings.pedestal_thickness.short}`) }}
          </span>

          <span v-if="props.column.field ==='overlay.pedestal_thickness'">
            <input-field
              :id="`location-materials-pedestals-thickness-${props.row.originalIndex}`"
              :key="`location-materials-pedestals-thickness-${props.row.originalIndex}`"
              v-model="formData[props.row.originalIndex].overlay.pedestal_thickness"
              :disabled="Boolean(formData[props.row.originalIndex].overlay.active) !== true"
              :input-classes="'c-input-field--table'"
              :is-store-mode="false"
              :validate="(v) => {return mx_validate_validatePositiveNumber(v) || mx_validate_validateEmpty(v)}"
              type="text"
              @change="mx_validate_updateErrorsArray"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </span>
        </template>
      </vue-good-table>
    </material-update-helper>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import { productTypes } from '@/helper/apiHelper'
import MixinValidate from '@/mixins/validate'

import MaterialUpdateHelper from '@/components/backend/locations/materials/partials/MaterialUpdateHelper'
import InputField from '@/components/common/InputField'
import InputCheckbox from '@/components/common/InputCheckbox'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

/**
 * @displayName Location Material Pedestals List
 */
export default {
  name: 'LocationsPedestals',
  components: {
    Icon,
    ButtonBase,
    InputCheckbox,
    InputField,
    MaterialUpdateHelper,
    VueGoodTable
  },
  mixins: [
    MixinValidate,
    MixinProductInLanguage
  ],
  data () {
    return {
      renderHelper: true,
      productTypes: productTypes
    }
  },
  computed: {
    ...mapGetters({
      getPedestals: 'backendAPI/product/pedestal/getPedestals',
      getMeta: 'backendAPI/product/pedestal/getMeta',
      getSettings: 'backendAPI/group/getSettings'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    },
    pedestalSettings () {
      return this.getSettings.units['App\\LocationPedestal']
    },
    columns () {
      return [
        {
          field: 'productNeedUpdate',
          sortable: false,
          thClass: 'vgt-no-min-width vgt-no-padding',
          tdClass: 'vgt-button-col-start'
        },
        {
          field: 'overlay.active',
          sortable: false,
          thClass: 'vgt-no-min-width'
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.id'),
          field: 'original.pim_id'
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.name'),
          field: 'original.name'
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.price', { currency: this.$world.getCurrencySymbol(), unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.price_each.short}`) }),
          sortable: false,
          field: 'overlay.price_each'
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.width', { unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.pedestal_width.short}`) }),
          field: 'original.pedestal_width',
          tdClass: 'u-text-right',
          sortable: false
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.widthCustom', { unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.pedestal_width.short}`) }),
          sortable: false,
          field: 'overlay.pedestal_width'
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.length', { unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.pedestal_length.short}`) }),
          field: 'original.pedestal_length',
          tdClass: 'u-text-right',
          sortable: false
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.lengthCustom', { unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.pedestal_length.short}`) }),
          sortable: false,
          field: 'overlay.pedestal_length'
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.thickness', { unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.pedestal_thickness.short}`) }),
          field: 'original.pedestal_thickness',
          tdClass: 'u-text-right',
          sortable: false
        },
        {
          label: this.$i18n.t('backend.locations.pedestalsTableLabel.thicknessCustom', { unit: this.$i18n.t(`backend.materials.units.${this.pedestalSettings.pedestal_thickness.short}`) }),
          sortable: false,
          field: 'overlay.pedestal_thickness'
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      updateLocationPedestal: 'backendAPI/product/pedestal/updateLocation'
    }),
    forceRerender () {
      // Remove component from the DOM
      this.renderHelper = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderHelper = true
      })
    }
  }
}
</script>
