<template>
  <div class="c-checkout__content-container">
    <div class="u-pt-size-standard u-mb-size-20">
      <h4 class="c-typo__h4">
        {{ $t('dialogs.overview.screen_2.header') }}
      </h4>
    </div>
    <div class="c-checkout__products-container">
      <div
        v-for="(object, index) in objects"
        :key="index"
        class="c-checkout__product"
      >
        <div class="o-grid o-grid--center c-checkout__content-full-width">
          <div class="o-grid__col u-8/8 c-checkout__product-line">
            <div class="o-grid o-grid--center c-checkout__content-full-width">
              <div class="o-grid__col u-1/8">
                <div
                  v-if="getProductThumb(object)"
                  :style="`background-image: url(${getProductThumb(object)})`"
                  class="c-checkout__product-image"
                />
              </div>
              <div class="o-grid__col u-7/8">
                <div class="o-grid u-mb-size-10">
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.area') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-3/8">
                    <p class="c-typo__text">
                      {{ index + 1 }}
                    </p>
                  </div>
                  <div class="o-grid__col u-3/8">
                    <p class="c-typo__text">
                      ~ <span v-html="areaRound(object.area)" />
                    </p>
                  </div>
                </div>
                <div class="o-grid u-mb-size-10">
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.product') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-3/8">
                    <p class="c-typo__text">
                      {{ getTitle(object) }}
                    </p>
                  </div>
                  <div class="o-grid__col u-3/8">
                    <p class="c-typo__text">
                      ~ {{ turfArea(getProductArea(object.id)) }} {{ $settings.areaUnit }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="hasSeamtapes"
                  class="o-grid u-mb-size-10"
                >
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_2.seamtape') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-3/8">
                    <drop-down
                      :id="'checkout-seamtapes-' + index"
                      v-model="productSeamTape[index]"
                      :items="localSeamTapes[index]"
                      :required="true"
                      :style-up="true"
                      classes="u-mt-size-half-standard"
                      label-key="name"
                      style-current-item-width="240px"
                      :current-item-allowed-signs="15"
                      @input="changeSeamTape(index)"
                    />
                  </div>
                  <div class="o-grid__col u-3/8">
                    <div class="o-grid">
                      <div class="o-grid__col u-2/4">
                        <p class="c-typo__text">
                          {{ getSeamtapeAmount(object.id) }}
                        </p>
                      </div>
                      <div class="o-grid__col u-2/4 u-text-right">
                        <p class="c-typo__text">
                          {{ getSeamtapePrice(object.id) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="hasAdhesives"
                  class="o-grid u-mb-size-10"
                >
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_2.adhesive') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-3/8">
                    <drop-down
                      :id="'checkout-adhesives-' + index"
                      v-model="productAdhesive[index]"
                      :items="localAdhesives[index]"
                      :required="true"
                      :style-up="true"
                      classes="u-mt-size-half-standard"
                      label-key="name"
                      style-current-item-width="240px"
                      :current-item-allowed-signs="15"
                      @input="changeAdhesive(index)"
                    />
                  </div>
                  <div class="o-grid__col u-3/8">
                    <div class="o-grid">
                      <div class="o-grid__col u-2/4">
                        <p class="c-typo__text">
                          {{ getAdhesiveAmount(object.id) }}
                        </p>
                      </div>
                      <div class="o-grid__col u-2/4 u-text-right">
                        <p class="c-typo__text">
                          {{ getAdhesivePrice(object.id) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="o-grid">
                  <div class="o-grid__col u-4/4 u-text-right">
                    <h5 class="c-typo__h5">
                      {{ getConsumablePrice(object.id) }}
                    </h5>
                  </div>
                </div>

                <!-- Infos -->
                <!--                <div
                  class="o-grid"
                >
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_2.infos.title') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-6/8">
                    <div class="o-grid o-grid&#45;&#45;center">
                      <div class="o-grid__col u-8/12">
                        <p class="c-typo__text u-color-o">
                          {{ $t('dialogs.overview.screen_2.infos.seamTapeNotCorrect') }}
                        </p>
                      </div>
                      <div class="o-grid__col u-4/12" />
                    </div>
                  </div>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { Adhesive, Seamtape } from '@/classes/products'
import config from '@/config'

import MixinMath from '@/mixins/math'
import MixinCosts from '@/mixins/costs'

import DropDown from '@/components/common/DropDown'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

export default {
  name: 'CheckoutTools',
  components: { DropDown },
  mixins: [
    MixinMath,
    MixinCosts,
    MixinProductInLanguage
  ],
  props: {
    objects: {
      type: Array,
      required: false,
      default: () => []
    },
    tools: {
      type: Array,
      required: false,
      default: () => []
    },
    summary: {
      type: [Map, Object],
      required: false,
      default: () => new Map()
    }
  },
  data () {
    return {
      localSeamTapes: [],
      productSeamTape: [],
      localAdhesives: [],
      productAdhesive: []
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      projectTools: state => state.project.tools,
      projectConsumables: state => state.project.consumables,
      products: state => state.products.products
    }),
    ...mapGetters({
      toolIsActive: 'project/toolIsActive',
      consumableIsActive: 'project/consumableIsActive',
      hasSeamtapes: 'products/hasSeamtapes',
      getSeamtapes: 'products/getSeamtapes',
      hasAdhesives: 'products/hasAdhesives',
      getAdhesives: 'products/getAdhesives'
    }),
    currency () {
      return config.currency
    }
  },
  created () {
    for (let index = 0; index < this.objects.length; index++) {
      const object = this.objects[index]

      this.localSeamTapes[index] = []
      this.localSeamTapes[index][0] = new Seamtape(0, '--', 0)

      for (let s = 0; s < this.getSeamtapes.length; s++) {
        const name = this.mx_product_in_language(this.getSeamtapes[s].original, ['name'], this.$i18n.locale)

        this.localSeamTapes[index][s + 1] = new Seamtape(this.getSeamtapes[s].original.id,
          name,
          this.getSeamtapes[s].versioning.version_nr, this.getSeamtapes[s].versioning.id)
      }

      if (object.product.seamtape === null) {
        this.productSeamTape[index] = this.localSeamTapes[index][0]
      } else {
        const seamID = object.product.seamtape.id
        this.productSeamTape[index] = this.localSeamTapes[index].filter(tape => tape.id === seamID)[0]
      }

      this.localAdhesives[index] = []
      this.localAdhesives[index][0] = new Adhesive(0, '--', 0)
      for (let a = 0; a < this.getAdhesives.length; a++) {
        const name = this.mx_product_in_language(this.getAdhesives[a].original, ['name'], this.$i18n.locale)
        this.localAdhesives[index][a + 1] = new Adhesive(this.getAdhesives[a].original.id,
          name,
          this.getAdhesives[a].versioning.version_nr, this.getAdhesives[a].versioning.id)
      }

      if (object.product.adhesive === null) {
        this.productAdhesive[index] = this.localAdhesives[index][0]
      } else {
        const glueID = object.product.adhesive.id
        this.productAdhesive[index] = this.localAdhesives[index].filter(glue => glue.id === glueID)[0]
      }
    }
  },
  methods: {
    ...mapActions({
      updateProjectTools: 'project/updateProjectTools',
      updateProjectConsumables: 'project/updateProjectConsumables',
      addProjectToConsumables: 'project/addProjectToConsumables',
      updateObjectProductProperties: 'project/objects/updateObjectProductProperties'
    }),
    getProductArea (id) {
      if (this.summary.has(id)) {
        return this.summary.get(id).turf.total.area
      }

      return 0
    },
    getSeamtapeAmount (id) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        if (data.seamtape) {
          return data.seamtape.rolls
        }
      }

      return 0
    },
    getSeamtapePrice (id) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        if (data.seamtape) {
          return this.$world.currencyConvert(data.seamtape.value, true)
        }
      }

      return this.$world.currencyConvert(0, true)
    },
    getAdhesiveAmount (id) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        if (data.adhesive) {
          return data.adhesive.buckets
        }
      }

      return 0
    },
    getAdhesivePrice (id) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        if (data.adhesive) {
          return this.$world.currencyConvert(data.adhesive.value, true)
        }
      }

      return this.$world.currencyConvert(0, true)
    },
    areaRound (area) {
      let out = this.$units.convert(this.mx_math_roundDecimal(area, 2)) + ' ' + this.$settings.areaUnit
      if (this.measure.transformUnit.id !== this.measure.unit.id) {
        out += ' / ' + this.$units.convert(this.mx_math_roundDecimal(this.convertArea(area), 1)) + ' ' + this.measure.transformUnit.square
      }
      return out
    },
    convertArea (val) {
      return this.$units.convert(Math.pow((Math.sqrt(val) * this.measure.unit.inches) / this.measure.transformUnit.inches, 2))
    },
    turfArea (area) {
      return this.$units.convert(this.mx_math_roundDecimal(area, 2))
    },
    getTitle (object) {
      const product = this.products.find(p => (p.original.id === object.product.id))

      return this.mx_product_in_language(product, ['name'])
    },
    getProductThumb (object) {
      const product = this.products.find(p => (p.original.id === object.product.id))
      if (product && product.original.assets.image && product.original.assets.image !== null) {
        return product.original.assets.image.conversions.thumb
      }

      return null
    },
    // seamTapeCalc (object, seamtape) {
    //   return this.mx_costs_seamtape(seamtape, object)
    // },
    // glueCalc (object, adhesive) {
    //   const objArea = object.area
    //   return this.mx_costs_adhesive(adhesive, objArea)
    // },
    pagination (payload) {
      switch (payload) {
        case 'back':
          this.$emit('pagination-checkout', 'back')
          break
        case 'next':
          this.$emit('pagination-checkout', 'next')
          break
        case 'option':
          break
        default:
          this.$emit('pagination-checkout', payload)
          break
      }
    },
    getActiveSeamTape (index) {
      const object = this.objects[index]
      if (typeof object.product.seamtape !== 'undefined' && object.product.seamtape !== null) {
        return object.product.seamtape.id
      } else {
        return 0
      }
    },
    getActiveAdhesive (index) {
      const object = this.objects[index]
      if (typeof object.product.adhesive !== 'undefined' && object.product.adhesive !== null) {
        return object.product.adhesive.id
      } else {
        return 0
      }
    },
    getConsumablePrice (id) {
      let price = 0
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        if (data.seamtape) {
          price += data.seamtape.value
        }
        if (data.adhesive) {
          price += data.adhesive.value
        }
      }
      return this.$world.currencyConvert(price, true)
    },
    changeSeamTape (index) {
      const tape = (this.productSeamTape[index].name === '--') ? null : this.productSeamTape[index]
      this.updateObjectProductProperties({ object: this.objects[index].id, product: { seamtape: tape } })
      this.$emit('checkoutUpdateSeamtape', this.objects[index].id)
    },
    changeAdhesive (index) {
      const glue = (this.productAdhesive[index].name === '--') ? null : this.productAdhesive[index]
      this.updateObjectProductProperties({ object: this.objects[index].id, product: { adhesive: glue } })
      this.$emit('checkoutUpdateAdhesive', this.objects[index].id)
    }
  }
}
</script>
