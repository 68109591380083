import { mapMutations } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Insert dialogs events into the application
 * @displayName Dialogs handler
 */
export default {
  name: 'DialogsHandlerMixin',
  mounted () {
    this.$events.on('dialog-add-surface-plan', () => {
      this.$_mx_dialogHandler_openDialog({ dialog: 'addSurfaceUnderlay', onOff: true })
    })
    this.$events.on('dialog-first-product-added', () => {
      this.$_mx_dialogHandler_openDialog({ dialog: 'firstProductAdded', onOff: true })
    })
    this.$events.on('create-project-dialog', () => {
      this.$_mx_dialogHandler_openDialog({ dialog: 'createProject', onOff: true })
    })
    this.$events.on('dialog-checkout', () => {
      this.$_mx_dialogHandler_openDialog({ dialog: 'checkout', onOff: true })
    })
    this.$events.on('dialog-edit-product', () => {
      this.$_mx_dialogHandler_openDialog({ dialog: 'editProduct', onOff: true })
    })
    this.$events.on(editorEventTypes.PRODUCT_SHOW_PAGE, () => {
      this.$_mx_dialogHandler_openDialog({ dialog: 'productPage', onOff: true })
    })
    this.$events.on('sub-nav-dialog-measurement-settings', () => {
      this.$_mx_dialogHandler_openSubNavDialog({ dialog: 'measurementSettings', onOff: true })
    })
  },
  methods: {
    ...mapMutations({
      $_mx_dialogHandler_openDialog: 'dialogs/' + mutationTypes.DIALOGS_TOGGLE,
      /** @private **/
      $_mx_dialogHandler_openSubNavDialog: 'navigation/' + mutationTypes.SUBNAV_DIALOGS_TOGGLE
    })
  }
}
