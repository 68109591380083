import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Distributor Store
 *
 * @returns {{distributors: []}}
 */
const defaultState = () => {
  return {
    distributors: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get all distributors
     *
     * @returns {[]}
     */
  getAll (state) {
    return state.distributors
  },
  /**
     * Has distributors
     *
     * @param state
     * @returns {boolean}
     */
  hasDistributors (state) {
    return (state.distributors.length > 0)
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_DISTRIBUTOR_STATE)
  },
  /**
     * Update a distributor
     *
     * @param commit
     * @param {Object} distributor
     */
  update ({ commit }, distributor) {
    commit(types.DISTRIBUTOR_UPDATE_DISTRIBUTOR, distributor)
  },
  /**
     * Delete a distributor
     *
     * @param commit
     * @param {Object} distributor
     */
  delete ({ commit }, distributor) {
    commit(types.DISTRIBUTOR_DELETE_DISTRIBUTOR, distributor)
  },
  /**
     * Set distributor list
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.distributor, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.DISTRIBUTOR_SET_DISTRIBUTORS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a distributor
     * Roles: Manufacture
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} distributor
     * @returns {Promise<void>}
     */
  async create ({ rootState, dispatch, commit }, distributor) {
    await apiMethods.request('post', routes.distributor, distributor, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.DISTRIBUTOR_ADD_DISTRIBUTOR, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_DISTRIBUTOR_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.DISTRIBUTOR_SET_DISTRIBUTORS] (state, distributors) {
    state.distributors = distributors.map(distributor => {
      distributor.created_at = apiMethods.convertDate(distributor.created_at)
      distributor.updated_at = apiMethods.convertDate(distributor.updated_at)

      return distributor
    })
  },
  [types.DISTRIBUTOR_ADD_DISTRIBUTOR] (state, distributor) {
    distributor.created_at = apiMethods.convertDate(distributor.created_at)
    distributor.updated_at = apiMethods.convertDate(distributor.updated_at)
    state.distributors.push(distributor)
  },
  [types.DISTRIBUTOR_DELETE_DISTRIBUTOR] (state, distributorId) {
    state.distributors = state.distributors.filter(distributor => distributor.id !== distributorId)
  },
  [types.DISTRIBUTOR_UPDATE_DISTRIBUTOR] (state, distributor) {
    const d = state.distributors.find(d => d.id === distributor.id)

    if (d !== undefined) {
      distributor.created_at = apiMethods.convertDate(distributor.created_at)
      distributor.updated_at = apiMethods.convertDate(distributor.updated_at)
      apiMethods.updateStoreObjectProperties(d, distributor)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
