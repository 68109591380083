<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createProject.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createProject.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <input-field
            id="create-project-name"
            v-model="project.name"
            :focus-on-mount="true"
            :is-store-mode="false"
            :label="$t('dialogs.createProject.name')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <drop-down
            id="create-project-client"
            v-model="project.client_id"
            :current-item-allowed-signs="15"
            :items="getClients"
            :label="$t('dialogs.createProject.client')"
            :no-item-label="$t('dialogs.createProject.noClient')"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="label"
            style-current-item-width="240px"
            value-key="id"
            @change="setPlaceOfExecution"
            @register="mx_validate_registerInputsInErrorsArray"
          />

          <span class="c-typo__h5 u-float-right u-mt-size-20 u-mb-size-10">{{ $t('dialogs.createProject.placeOfExecution') }}</span>

          <input-field
            id="create-project-address-street"
            v-model="project.address.street"
            :is-store-mode="false"
            :label="$t('dialogs.createProject.address.street')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            classes="u-mt-size-40"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-project-address-city"
            v-model="project.address.city"
            :is-store-mode="false"
            :label="$t('dialogs.createProject.address.city')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-project-address-zip"
            v-model="project.address.zip"
            :is-store-mode="false"
            :label="$t('dialogs.createProject.address.zip')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-project-address-state"
            v-model="project.address.state"
            :is-store-mode="false"
            :label="$t('dialogs.createProject.address.state')"
            :validate="(v) => {return true}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="create-project-address-country"
            v-model="project.address.country"
            :is-store-mode="false"
            :label="$t('dialogs.createProject.address.country')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
        </form>

        <div class="o-grid o-grid--right c-dialog__instruction--no-wrap u-mt-size-40">
          <div class="c-dialog__instruction-col">
            <label
              :class="{'c-form__radio--active':(!uploadUnderlay)}"
              class="c-form__radio"
              for="uploadUnderlay1"
            >
              {{ $t('dialogs.createProject.underlayUpload.no') }}
            </label>
            <input
              id="uploadUnderlay1"
              v-model="uploadUnderlay"
              :value="false"
              class="c-form__radio-input"
              name="radio"
              type="radio"
            >
          </div>
          <div class="c-dialog__instruction-col">
            <label
              :class="{'c-form__radio--active':(uploadUnderlay)}"
              class="c-form__radio"
              for="uploadUnderlay2"
            >
              {{ $t('dialogs.createProject.underlayUpload.yes') }}
            </label>
            <input
              id="uploadUnderlay2"
              v-model="uploadUnderlay"
              :value="true"
              class="c-form__radio-input"
              name="radio"
              type="radio"
            >
          </div>
        </div>
        <p class="u-pt-size-standard u-text-right">
          <small>{{ $t('dialogs.createProject.underlayUpload.info') }}</small>
        </p>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-project-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-project-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewProject() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters, mapState } from 'vuex'
import { apiMethods } from '@/helper/apiHelper'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Create Project
     */
export default {
  name: 'CreateProject',
  components: {
    ButtonBase,
    DropDown,
    InputField,
    DialogFrame
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      project: apiMethods.getModel('project'),
      targetDialog: 'createProject',
      cannotClose: false,
      uploadUnderlay: false
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.createProject,
      projectMeta: state => state.project.backendMeta
    }),
    ...mapGetters({
      getClients: 'backendAPI/client/getAll',
      getLocations: 'backendAPI/location/getAll'
    }),
    isNewEmptyProject () {
      return this.dialogProps && this.dialogProps.resetState
    }
  },
  methods: {
    ...mapActions({
      createProject: 'backendAPI/project/create',
      resetToInitialState: 'history/resetToInitialState'
    }),
    setPlaceOfExecution (payload) {
      const client = this.getClients.find(client => client.id === payload.value)
      const address = this.project.address

      if (client.address) {
        const cAddress = client.address

        address.street = cAddress.street
        address.city = cAddress.city
        address.zip = cAddress.zip
        address.state = cAddress.state
        address.country = cAddress.country
      }
      this.mx_validate_updateErrorsArray(payload)
    },
    async createNewProject () {
      if (this.isNewEmptyProject) {
        this.resetToInitialState()
        await this.createProject(this.project)
        const tempUploadUnderlay = _.clone(this.uploadUnderlay)
        this.close()
        await this.$router.push({ name: 'Melos-Editor', query: { uploadUnderlay: tempUploadUnderlay } })
      } else {
        this.$devLog.log('TODO: Empty not saved project') // TODO: Empty not saved project
        this.close()
      }
    },
    close () {
      this.project = apiMethods.getModel('project')
      this.uploadUnderlay = false
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
