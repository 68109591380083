import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'
import _ from 'lodash'

/**
 * Default state for: Client Store
 *
 * @returns {{clients: []}}
 */
const defaultState = () => {
  return {
    clients: []
  }
}

const state = defaultState()

const getters = {
  /**
     * Get clients
     *
     * @param state
     * @returns {[]}
     */
  getAll (state) {
    return _.sortBy(state.clients, ['firstname'])
  },
  /**
     * Get client by ID
     *
     * @param state
     * @returns {Object|null}
     */
  getById: (state) => (id) => {
    if (state.clients.length > 0) {
      const client = state.clients.find(client => client.id === id)
      client.label = client.address.name
        ? client.firstname + ' ' + client.lastname + ', ' + client.address.name
        : client.firstname + ' ' + client.lastname
      return (client !== undefined) ? client : null
    }

    return null
  },
  /**
     * Has clients
     *
     * @param state
     * @returns {boolean}
     */
  hasClients (state) {
    return (state.clients.length > 0)
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_CLIENT_STATE)
  },
  /**
     * Set client list
     * Roles: Distributor, Employee
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<boolean>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.client, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.CLIENT_SET_CLIENTS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listShort ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', `${routes.client}/short`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.CLIENT_SET_CLIENTS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Get full client information
     * Roles: Distributor, Employee
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param clientId
     * @returns {Promise<void>}
     */
  async read ({ rootState, dispatch, commit }, clientId) {
    return await apiMethods.request('get', routes.client + `/${clientId}`, null, rootState.backendAPI.login.token)
      .then(response => {
        const client = response.data.data
        client.label = client.address.name
          ? client.address.name
          : client.firstname + ' ' + client.lastname
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  },
  /**
     * Create a client
     * Roles: Distributor, Employee
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param client
     * @returns {Promise<void>}
     */
  async create ({ rootState, dispatch, commit }, client) {
    if (client.group_id === null) {
      client.group_id = rootState.backendAPI.user.me.group.id
    }

    await apiMethods.request('post', routes.client, client, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.CLIENT_ADD_CLIENT, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update a client
     * Roles: Distributor, Employee
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} client
     * @returns {Promise<boolean>}
     */
  async update ({ rootState, dispatch, commit }, client) {
    return await apiMethods.request('patch', routes.client + `/${client.id}`, {
      salutation: client.salutation,
      firstname: client.firstname,
      lastname: client.lastname,
      email: client.email,
      groupId: client.group.id,
      address: {
        name: client.address.name,
        phone: client.address.phone,
        street: client.address.street,
        city: client.address.city,
        zip: client.address.zip,
        state: client.address.state,
        country: client.address.country
      }
    }, rootState.backendAPI.login.token).then(response => {
      commit(types.CLIENT_UPDATE_CLIENT, response.data.data)
      return true
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
      return false
    })
  },
  /**
     * Delete a client
     * Roles: Distributor, Employee
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} clientId
     * @returns {Promise<void>}
     */
  async delete ({ rootState, dispatch, commit }, clientId) {
    await apiMethods.request('delete', routes.client + `/${clientId}`, null, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.CLIENT_DELETE_CLIENT, clientId)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_CLIENT_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.CLIENT_SET_CLIENTS] (state, clients) {
    state.clients = clients
  },
  [types.CLIENT_ADD_CLIENT] (state, client) {
    state.clients.push(client)
  },
  [types.CLIENT_DELETE_CLIENT] (state, clientId) {
    state.clients = state.clients.filter(client => client.id !== clientId)
  },
  [types.CLIENT_UPDATE_CLIENT] (state, client) {
    const c = state.clients.find(c => c.id === client.id)

    if (c !== undefined) {
      apiMethods.updateStoreObjectProperties(c, client)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
