import { mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Provides the nearest collision point
 * @displayName Collision
 */
export default {
  name: 'CollisionMixin',
  data () {
    return {
      tolerance: 10,
      snapKeyPressed: false
    }
  },
  computed: {
    ...mapState({
      /** @private **/
      $_mx_collision_translate: state => state.project.object.translate,
      /** @private **/
      $_mx_collision_raster: state => state.editor.grid.raster,
      /** @private **/
      $_mx_collision_measure: state => state.project.measurement.measure,
      $_mx_collision_selectedObjects: state => state.events.currentSelectedObjects,
      mx_collision_collisionPoints: state => state.project.collisionPoints
    }),
    ...mapGetters({
      /** @private **/
      $_mx_collision_objects: 'project/objects/getAll'
    })
  },
  beforeMount () {
    this.$events.on(editorEventTypes.OBJECT_SNAP_ON, () => {
      this.snapKeyPressed = true
    })
    this.$events.on(editorEventTypes.OBJECT_SNAP_OFF, () => {
      this.snapKeyPressed = false
    })
  },
  methods: {
    ...mapMutations({
      mx_collision_setCollisionPoints: 'project/' + mutationTypes.SET_COLLISION_POINTS
    }),
    /**
         * Get the object of mixin parent component
         * @private
         */
    $_mx_collision_getSelfObject () {
      if (this.object) {
        return this.object
      } else if (this.defaultObject) {
        return this.defaultObject
      } else return null
    },
    /**
         * Returns the correct id
         * @private
         */
    $_mx_collision_getSelfObjectId () {
      if (this.object) {
        return this.object.id
      } else if (this.defaultObject) {
        return 'predefined'
      } else return '99999'
    },
    /**
         * Main function to display colliding points between objects
         * @param {Boolean} gridSnap Snap to grid activated
         * @param {Boolean} snapKey If snap key is active
         * @param {Object} nPtM Nearest point to mouse position as {x: x, y: y}
         * @public
         */
    mx_collision_setOrUpdateCollidingPoints (gridSnap = false, snapKey = false, nPtM = null) {
      let res = []
      // check grid snap movement collision points
      if (gridSnap) {
        const tP = { x: this.$_mx_collision_translate.x + nPtM.x, y: this.$_mx_collision_translate.y + nPtM.y }
        const gP = this.mx_collision_closestGridPoint(tP)
        res.push({ p1: gP, p2: nPtM })
      }
      // check snap key object collision points, override possible snap movement grid point
      if (snapKey) {
        const boxColluding = this.$_mx_collision_testBoxColliding()
        if (boxColluding.length) {
          const possiblePoints = this.$_mx_collision_testCollidingPointsInObjects(boxColluding)
          if (possiblePoints.length) {
            res = possiblePoints
          }
        }
      }
      // set and display points or clear any older ones
      if (res.length) {
        this.mx_collision_setCollisionPoints(res)
      } else {
        if (this.mx_collision_collisionPoints.length) {
          this.mx_collision_setCollisionPoints([])
        }
      }
    },
    /**
         * Points collision with all objects
         * @param point
         * @returns {Array}
         * @public
         */
    mx_collision_testCollidingPoints (point) {
      let cps = []
      this.$_mx_collision_objects.filter(item => item.id !== this.$_mx_collision_getSelfObjectId()).forEach(item => {
        cps = cps.concat(item.collisionPoint(point, this.tolerance, this.$_mx_collision_translate))
        item.children.forEach(child => {
          cps = cps.concat(child.collisionPoint(point, this.tolerance, this.$_mx_collision_translate))
        })
      })
      return cps
    },
    /**
         * Handle collision with all objects
         * @param point
         * @returns {Array}
         * @public
         */
    mx_collision_testCollidingHandlePoints (point) {
      let cps = []
      this.$_mx_collision_objects.filter(item => item.id !== this.$_mx_collision_getSelfObjectId()).forEach(item => {
        cps = cps.concat(item.collisionHandlePoint(point, this.tolerance, this.$_mx_collision_translate))
        item.children.forEach(child => {
          cps = cps.concat(child.collisionHandlePoint(point, this.tolerance, this.$_mx_collision_translate))
        })
      })
      return cps
    },
    /**
         * Points in object to object collision
         * @param res
         * @private
         */
    $_mx_collision_testCollidingPointsInObjects (res) {
      let cps = []
      res.forEach(id => {
        const obj = this.getObject(id)
        cps = cps.concat(this.$_mx_collision_getSelfObject().collisionPoints(obj._relevantPoints(), this.$_mx_collision_translate, this.tolerance))
        obj.children.forEach(child => {
          cps = cps.concat(this.$_mx_collision_getSelfObject().collisionPoints(child.getPoints(), this.$_mx_collision_translate, this.tolerance))
        })
      })
      return cps
    },
    /**
         * Test bounding boxes colliding
         * @returns {Array}
         * @private
         */
    $_mx_collision_testBoxColliding () {
      return this.$_mx_collision_objects.filter(
        item => item.id !== this.$_mx_collision_getSelfObjectId() && !this.$_mx_collision_selectedObjects.includes(item.id) && this.$_mx_collision_hitTestBbox(
          document.getElementById(item.id).getBBox(),
          document.getElementById(this.$_mx_collision_getSelfObjectId()).getBBox(),
          this.tolerance
        )
      ).map(item => item.id)
    },
    /**
         * Get the collision point tuple with the smallest distance from an array of collision points
         * @requires @/mixins/math
         * @param {Array}collisionPoints
         * @param {Boolean}withTranslation
         * @public
         */
    mx_collision_getCollisionPointTupleWithSmallestDistance (collisionPoints, withTranslation = true) {
      const distances = collisionPoints.map(pointTuple => {
        return this.mx_math_getDistance(pointTuple.p1, {
          x: pointTuple.p2.x + (withTranslation ? this.$_mx_collision_translate.x : 0),
          y: pointTuple.p2.y + (withTranslation ? this.$_mx_collision_translate.y : 0)
        })
      })
      const min = Math.min(...distances)
      return collisionPoints[distances.findIndex(item => item === min)]
    },
    /**
         * Test collision of two bounding boxes
         * @param bboxA
         * @param bboxB
         * @param tolerance
         * @returns {boolean}
         * @private
         */
    $_mx_collision_hitTestBbox (bboxA, bboxB, tolerance) {
      if (bboxA.x > bboxB.x + bboxB.width + this.$_mx_collision_translate.x + tolerance) return false
      if (bboxA.x + bboxA.width < bboxB.x + this.$_mx_collision_translate.x - tolerance) return false
      if (bboxA.y > bboxB.y + bboxB.height + this.$_mx_collision_translate.y + tolerance) return false
      if (bboxA.y + bboxA.height < bboxB.y + this.$_mx_collision_translate.y - tolerance) return false
      return true
    },
    /**
         * @param mousePosition
         * @returns {{x: number, y: number}}
         * @public
         */
    mx_collision_closestGridPoint (mousePosition) {
      const scaledRaster = (parseFloat(this.$_mx_collision_raster) / this.mx_math_replaceDecimalPointWithDot(this.$_mx_collision_measure.toUnit)) *
                this.mx_math_replaceDecimalPointWithDot(this.$_mx_collision_measure.fromPixels)

      return {
        x: Math.round(mousePosition.x / scaledRaster) * scaledRaster,
        y: Math.round(mousePosition.y / scaledRaster) * scaledRaster
      }
    }
  }
}
