<template>
  <div class="c-backend-cat-menu o-grid">
    <div class="c-backend-cat-menu__menu o-grid__col">
      <router-link
        v-for="category in menu.categories"
        :id="category"
        :key="category"
        :class="[currentCat === category ? 'c-backend-cat-menu__menu-item--active': '', 'c-backend-cat-menu__menu-item']"
        :to="`/backend/materials/${category}/${firstSub(category)}`"
        tag="button"
      >
        {{ $t(`backend.materials.menu.${category}.default`) }}
      </router-link>
    </div>

    <div class="c-backend-cat-menu__menu c-backend-cat-menu__menu-left o-grid__col u-8/12 u-mt-size-80">
      <router-link
        v-for="subCategory in menu.subcategories"
        :id="subCategory"
        :key="subCategory"
        :class="[currentSub === subCategory ? 'c-backend-cat-menu__menu-item--active': '',
                 subCategory === 'epdm' ? 'c-backend-cat-menu__menu-item--uppercase' : '',
                 'c-backend-cat-menu__menu-item c-backend-cat-menu__menu-item--sub']"
        :to="`/backend/materials/${currentCat}/${subCategory}`"
        tag="button"
      >
        {{ $t(`backend.materials.menu.${currentCat}.${subCategory}`) }}
      </router-link>
    </div>
  </div>
</template>

<script>
import config from '@/config'
// import ButtonIcon from '@/components/common/ButtonIcon'
import { mapGetters, mapState } from 'vuex'

/**
     * @displayName Materials Category Menu Component
     */
export default {
  name: 'MaterialsCatMenu',
  components: { /* ButtonIcon */ },
  props: {
    currentCat: {
      type: String,
      required: true
    },
    currentSub: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      config: config
    }
  },
  computed: {
    ...mapState({
      locations: store => store.backendAPI.location.locations
    }),
    ...mapGetters({
      getUserRole: 'backendAPI/user/getRole'
    }),
    menu () {
      const categories = Object.keys(this.config.materials[this.getUserRole])
      const subcategories = this.config.materials[this.getUserRole][this.currentCat]
      return { categories: categories, subcategories: subcategories }
    }
  },
  methods: {
    firstSub (cat) {
      const sub = this.config.materials[this.getUserRole][cat][0]
      return sub !== undefined ? sub : ''
    },
    _copy () {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'copyMaterials',
        onOff: true
      })
    }
  }
}
</script>
