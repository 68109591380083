<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.updateLocation.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.updateLocation.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form
          v-if="location !== null"
          class="c-form"
        >
          <input-field
            id="update-location-name"
            v-model="location.name"
            :focus-on-mount="true"
            :is-store-mode="false"
            :label="$t('dialogs.updateLocation.name')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-location-address-street"
            v-model="location.address.street"
            :is-store-mode="false"
            :label="$t('dialogs.updateLocation.address.street')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-location-address-city"
            v-model="location.address.city"
            :is-store-mode="false"
            :label="$t('dialogs.updateLocation.address.city')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-location-address-zip"
            v-model="location.address.zip"
            :is-store-mode="false"
            :label="$t('dialogs.updateLocation.address.zip')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-location-address-state"
            v-model="location.address.state"
            :is-store-mode="false"
            :label="$t('dialogs.updateLocation.address.state')"
            :validate="(v) => {return true}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-location-address-country"
            v-model="location.address.country"
            :is-store-mode="false"
            :label="$t('dialogs.updateLocation.address.country')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <drop-down
            id="create-location-currency"
            v-model="location.currency_short"
            :items="$world.getCurrencyList()"
            :label="$t('dialogs.updateLocation.currency')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="name"
            style-current-item-width="240px"
            value-key="code"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
        </form>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="update-location-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="update-location-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return update() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { apiMethods } from '@/helper/apiHelper'
import _ from 'lodash'
import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import DropDown from '@/components/common/DropDown'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Update Location
     */
export default {
  name: 'UpdateLocation',
  components: {
    ButtonBase,
    DialogFrame,
    InputField,
    DropDown
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      targetDialog: 'updateLocation',
      cannotClose: false,
      location: null
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.updateLocation,
      visible: state => state.dialogs.visible
    }),
    isVisible () {
      return this.visible[this.targetDialog]
    }
  },
  watch: {
    isVisible: {
      handler (n, o) {
        if (n === true) {
          this.location = _.cloneDeep(this.dialogProps.locationData)
          if (!this.location.address) {
            this.location.address = apiMethods.getModel('location').address
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      readLocations: 'backendAPI/location/read',
      updateLocation: 'backendAPI/location/update'
    }),
    async update () {
      const isUpdated = await this.updateLocation(this.location)
      if (isUpdated) {
        this.location = null
      }
      this.close()
    },
    close () {
      this.location = null
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
