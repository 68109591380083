<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.updateProject.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.updateProject.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <input-field
            id="update-project-name"
            v-model="project.name"
            :focus-on-mount="true"
            :is-store-mode="false"
            :label="$t('dialogs.updateProject.name')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <drop-down
            id="update-project-client"
            v-model="project.client_id"
            :current-item-allowed-signs="15"
            :disabled="true"
            :items="getClients"
            :label="$t('dialogs.updateProject.client')"
            :style-up="false"
            classes="u-mt-size-standard"
            label-key="label"
            style-current-item-width="240px"
            value-key="id"
            @change="setPlaceOfExecution"
            @register="mx_validate_registerInputsInErrorsArray"
          />

          <span class="c-typo__h5 u-float-right u-mt-size-20 u-mb-size-10">{{ $t('dialogs.updateProject.placeOfExecution') }}</span>

          <input-field
            id="update-project-address-street"
            v-model="project.address.street"
            :is-store-mode="false"
            :label="$t('dialogs.updateProject.address.street')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            classes="u-mt-size-40"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-project-address-city"
            v-model="project.address.city"
            :is-store-mode="false"
            :label="$t('dialogs.updateProject.address.city')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-project-address-zip"
            v-model="project.address.zip"
            :is-store-mode="false"
            :label="$t('dialogs.updateProject.address.zip')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-project-address-state"
            v-model="project.address.state"
            :is-store-mode="false"
            :label="$t('dialogs.updateProject.address.state')"
            :validate="(v) => {return true}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
          <input-field
            id="update-project-address-country"
            v-model="project.address.country"
            :is-store-mode="false"
            :label="$t('dialogs.updateProject.address.country')"
            :required="true"
            :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
            style-input-width="240px"
            type="text"
            @change="mx_validate_updateErrorsArray"
            @register="mx_validate_registerInputsInErrorsArray"
          />
        </form>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="update-project-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="update-project-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return doUpdateProject() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import _ from 'lodash'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'

import DialogFrame from '@/components/common/DialogsFrame'
import DropDown from '@/components/common/DropDown'
import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Update Project
     */
export default {
  name: 'UpdateProject',
  components: {
    ButtonBase,
    InputField,
    DropDown,
    DialogFrame
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      targetDialog: 'updateProject',
      cannotClose: false,
      project: null
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.updateProject,
      visible: state => state.dialogs.visible
    }),
    ...mapGetters({
      getClients: 'backendAPI/client/getAll',
      getLocations: 'backendAPI/location/getAll'
    }),
    isVisible () {
      return this.visible[this.targetDialog]
    }
  },
  watch: {
    isVisible: {
      handler (n, o) {
        if (n === true) {
          this.project = _.cloneDeep(this.dialogProps.projectData)
        }
      }
    }
  },
  methods: {
    ...mapActions({
      updateProject: 'backendAPI/project/update'
    }),
    async doUpdateProject () {
      await this.updateProject(this.project)
      this.close()
    },
    setPlaceOfExecution (payload) {
      const client = this.getClients.filter(client => client.id === payload.value)[0]
      const address = this.project.address

      if (client.address) {
        const cAddress = client.address

        address.street = cAddress.street
        address.city = cAddress.city
        address.zip = cAddress.zip
        address.state = cAddress.state
        address.country = cAddress.country
      }
      this.mx_validate_updateErrorsArray(payload)
    },
    close () {
      this.project = null
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
