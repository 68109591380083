<template>
  <g>
    <vnodes :vnodes="ruler" />
    <text
      v-for="(edge,index) in length"
      :key="'length-text-' + index"
      :font-size="mx_text_fontSizeLength"
      :stroke-width="mx_text_strokeWidth"
      :x="edge.center.x + mx_text_lengthSpacePointX"
      :y="edge.center.y + mx_text_lengthSpacePointY"
      fill="black"
      paint-order="stroke"
      stroke="white"
      text-anchor="middle"
    >
      {{ edge.cl }} {{ $settings.rulerLengthUnit }}
    </text>
  </g>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

import { RulerPoint } from '@/classes/objects'
import MixinMath from '@/mixins/math'
import MixinText from '@/mixins/text'
import MixinDrawCollision from '@/mixins/drawCollision'
import MixinCollision from '@/mixins/collision'

export default {
  name: 'Ruler',
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  mixins: [
    MixinMath,
    MixinText,
    MixinDrawCollision,
    MixinCollision
  ],
  data: () => {
    return {
      clicks: 0,
      rulerPoint: {
        hover: null,
        drag: false,
        selected: null
      },
      activeCtrl: 'ruler'
    }
  },
  computed: {
    ...mapState({
      rulerPointStart: state => state.project.measurement.ruler.start,
      rulerPointEnd: state => state.project.measurement.ruler.end,
      mouse: state => state.pseudo.mouse,
      commonSvgSettings: state => state.common.svg,
      measure: state => state.project.measurement.measure,
      ctrl: state => state.events.ctrl
    }),
    length () {
      if (this.rulerPointStart !== null) {
        const _nextPoint = (this.rulerPointEnd !== null) ? this.rulerPointEnd : {
          x: this.mouse.x2,
          y: this.mouse.y2
        }
        const l = this.mx_math_calculateLengthsFromPoints([this.rulerPointStart, _nextPoint], this.measure)

        if (this.line) {
          l.pop()
        }
        return l
      }
      return null
    },
    ruler () {
      if (this.rulerPointStart !== null) {
        const _nextPoint = (this.rulerPointEnd !== null) ? this.rulerPointEnd : {
          x: this.mouse.x2,
          y: this.mouse.y2
        }

        const _start = {
          class: {
            'c-object c-object__circle c-object__circle--active': true,
            'c-object__circle--selected': this.rulerPoint.selected === 'circle-ruler-start',
            'c-object__circle--hover': this.rulerPoint.hover === 'circle-ruler-start'
          },
          attrs: {
            id: 'circle-ruler-start',
            cx: this.rulerPointStart.x,
            cy: this.rulerPointStart.y,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          }
        }

        const _end = {
          class: {
            'c-object c-object__circle c-object__circle--active': true,
            'c-object__circle--selected': this.rulerPoint.selected === 'circle-ruler-end',
            'c-object__circle--hover': this.rulerPoint.hover === 'circle-ruler-end'
          },
          attrs: {
            id: 'circle-ruler-end',
            cx: _nextPoint.x,
            cy: _nextPoint.y,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          }
        }

        if (this.rulerPointStart !== null && this.rulerPointEnd !== null) {
          _start.on = {
            mouseenter: e => {
              this.rulerPoint.hover = 'circle-ruler-start'
            },
            mouseleave: e => {
              this.rulerPoint.hover = null
            },
            mousedown: e => {
              this.rulerPoint.drag = true
              this.rulerPoint.selected = 'circle-ruler-start'
              this.setTarget('moveRulerPointStart')
            },
            mouseup: e => {
              this.rulerPoint.drag = false
              this.rulerPoint.selected = null
              this.setTarget(null)
            }
          }

          _end.on = {
            mouseenter: e => {
              this.rulerPoint.hover = 'circle-ruler-end'
            },
            mouseleave: e => {
              this.rulerPoint.hover = null
            },
            mousedown: e => {
              this.rulerPoint.drag = true
              this.rulerPoint.selected = 'circle-ruler-end'
              this.setTarget('moveRulerPointEnd')
            },
            mouseup: e => {
              this.rulerPoint.drag = false
              this.rulerPoint.selected = null
              this.setTarget(null)
            }
          }
        }

        const _line = this.$createElement('line', {
          class: {
            'c-object c-object__line': true
          },
          attrs: {
            id: 'line-ruler',
            x1: this.rulerPointStart.x,
            y1: this.rulerPointStart.y,
            x2: _nextPoint.x,
            y2: _nextPoint.y,
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
          }
        })

        return this.$createElement('g', {
          attrs: {
            id: 'project-RulerLine'
          }
        }, [
          _line,
          this.$createElement('circle', _start),
          this.$createElement('circle', _end)
        ])
      }

      return null
    }
  },
  watch: {
    clicks (n, o) {
      if (n > 1) {
        this.clicks = 0
        this.setPseudoMouseRuler({ x1: 0, y1: 0, x2: 0, y2: 0 })
        this.resetRuler()
      }
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.RULER_ADD_POINT, this.addPointToRuler)
    this.$events.on(editorEventTypes.RULER_UPDATE_MOUSE, this.updateMouseRuler)
    this.$events.on(editorEventTypes.RULER_MODIFY_START_POINT, this.$_updateRulerPointStart)
    this.$events.on(editorEventTypes.RULER_MODIFY_END_POINT, this.$_updateRulerPointEnd)
    this.$events.on(editorEventTypes.RULER_CREATE, this.resetRuler)
    this.$events.on(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, (e) => {
      if (e !== 'ruler') {
        this.resetRuler()
      }
    })
  },
  methods: {
    ...mapMutations({
      setRulerPointStart: 'project/measurement/' + mutationTypes.SET_RULER_POINT_START,
      setRulerPointEnd: 'project/measurement/' + mutationTypes.SET_RULER_POINT_END,
      updateRulerPointStart: 'project/measurement/' + mutationTypes.UPDATE_RULER_POINT_START,
      updateRulerPointEnd: 'project/measurement/' + mutationTypes.UPDATE_RULER_POINT_END,
      setPseudoMouseRuler: 'pseudo/' + mutationTypes.UPDATE_PSEUDO_MOUSE_COORDINATES,
      setTarget: 'events/' + mutationTypes.EVT_TARGET
    }),
    updateMouseRuler (evt) {
      const _evtPoint = this.$_prepareRulerPoint(evt)
      this.setPseudoMouseRuler({ x2: _evtPoint.x, y2: _evtPoint.y })
    },
    $_prepareRulerPoint (evt) {
      let _rulerPoint = this.mx_math_getCoordinatesFromEvent(evt)

      if (evt.shiftKey) {
        _rulerPoint = this.mx_math_ortho(_rulerPoint, this.rulerPointStart)
      }

      if (this.snapKeyPressed && this.mx_collision_collisionPoints.length) {
        const cpT = this.mx_collision_getCollisionPointTupleWithSmallestDistance(this.mx_collision_collisionPoints)
        _rulerPoint = { x: cpT.p1.x, y: cpT.p1.y }
      }

      return _rulerPoint
    },
    addPointToRuler (evt) {
      const evtPoint = this.$_prepareRulerPoint(evt)
      const _id = (this.clicks > 0) ? 'end' : 'start'
      const rulerPoint = new RulerPoint(_id, evtPoint.x, evtPoint.y)

      if (_id === 'start') {
        this.setRulerPointStart(rulerPoint)
      } else {
        this.setRulerPointEnd(rulerPoint)
      }

      this.clicks += 1
    },
    $_updateRulerPointStart (evt) {
      if (this.rulerPoint.drag && this.rulerPoint.selected === 'circle-ruler-start') {
        const _p = this.$_prepareRulerPoint(evt)
        this.updateRulerPointStart(_p)
      }
    },
    $_updateRulerPointEnd (evt) {
      if (this.rulerPoint.drag && this.rulerPoint.selected === 'circle-ruler-end') {
        const _p = this.$_prepareRulerPoint(evt)
        this.updateRulerPointEnd(_p)
      }
    },
    resetRuler () {
      this.clicks = 0
      this.setRulerPointStart(null)
      this.setRulerPointEnd(null)
      this.setTarget(null)
    }
  }
}
</script>
