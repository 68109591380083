import * as types from '@/vuex/backend-api-mutation-types'
// Import product submodules for api calls
import SubProductHandlerModule from '@/vuex/modules/backendAPI/submodules/SubProductHandler'
import turfModule from '@/vuex/modules/backendAPI/submodules/turf'
import infillModule from '@/vuex/modules/backendAPI/submodules/infill'
import subbaseModule from '@/vuex/modules/backendAPI/submodules/subbase'
import padModule from '@/vuex/modules/backendAPI/submodules/pad'
import pedestalModule from '@/vuex/modules/backendAPI/submodules/pedestal'
import seamtapeModule from '@/vuex/modules/backendAPI/submodules/seamtape'
import adhesiveModule from '@/vuex/modules/backendAPI/submodules/adhesive'

/**
 * Default state for: Product Store
 *
 * @returns {{ locationId: null }}
 */
const defaultState = () => {
  return {
    locationId: null
  }
}

const state = defaultState()

const getters = {
  /**
   * Location Id
   *
   * @param state
   * @returns {number}
   */
  getLocationId (state) {
    return state.locationId
  }
}

const actions = {
  /**
   * Reset state
   *
   * @param dispatch
   * @param commit
   */
  resetState ({ dispatch, commit }) {
    dispatch('backendAPI/product/turf/resetState', null, { root: true })
    dispatch('backendAPI/product/infill/resetState', null, { root: true })
    dispatch('backendAPI/product/subbase/resetState', null, { root: true })
    dispatch('backendAPI/product/pad/resetState', null, { root: true })
    dispatch('backendAPI/product/pedestal/resetState', null, { root: true })
    dispatch('backendAPI/product/seamtape/resetState', null, { root: true })
    dispatch('backendAPI/product/adhesive/resetState', null, { root: true })
  },
  /**
   * Reset PIM state
   *
   * @param dispatch
   * @param commit
   */
  resetPIMState ({ dispatch, commit }) {
    dispatch('backendAPI/product/turf/resetPIMState', null, { root: true })
    dispatch('backendAPI/product/infill/resetPIMState', null, { root: true })
    dispatch('backendAPI/product/subbase/resetPIMState', null, { root: true })
    dispatch('backendAPI/product/pad/resetPIMState', null, { root: true })
    dispatch('backendAPI/product/pedestal/resetPIMState', null, { root: true })
    dispatch('backendAPI/product/seamtape/resetPIMState', null, { root: true })
    dispatch('backendAPI/product/adhesive/resetPIMState', null, { root: true })
  },
  /**
   * Set current location id to edit products
   *
   * @param dispatch
   * @param commit
   * @param locationId
   * @returns {Promise<void>}
   */
  async setLocationId ({ dispatch, commit }, locationId) {
    dispatch('resetPIMState')
    commit(types.PRODUCT_SET_LOCATION_ID, locationId)

    if (locationId !== null) {
      dispatch('backendAPI/product/subProductHandler/list', null, { root: true })
    }
  }
}

const mutations = {
  [types.PRODUCT_SET_LOCATION_ID] (state, locationId) {
    state.locationId = locationId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    turf: turfModule,
    infill: infillModule,
    subbase: subbaseModule,
    pad: padModule,
    pedestal: pedestalModule,
    seamtape: seamtapeModule,
    adhesive: adhesiveModule,
    subProductHandler: SubProductHandlerModule
  }
}
