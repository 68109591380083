<template>
  <div class="c-checkout__content-container">
    <div class="u-pt-size-standard u-mb-size-20">
      <h4 class="c-typo__h4">
        {{ $t('dialogs.overview.screen_1.header') }}
      </h4>
    </div>
    <div class="c-checkout__products-container">
      <div
        v-for="(object, index) in objects"
        :key="index"
        class="c-checkout__product"
      >
        <div class="o-grid o-grid--center c-checkout__content-full-width">
          <div class="o-grid__col u-8/8 c-checkout__product-line">
            <div class="o-grid o-grid--center c-checkout__content-full-width">
              <div class="o-grid__col u-1/8">
                <div
                  v-if="getProductThumb(object)"
                  :style="`background-image: url(${getProductThumb(object)})`"
                  class="c-checkout__product-image"
                />
              </div>
              <div class="o-grid__col u-7/8">
                <div class="o-grid">
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.area') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      {{ object.getName() }}
                    </p>
                  </div>
                  <div class="o-grid__col u-4/8">
                    <p class="c-typo__text">
                      ~ <span v-html="areaRound(object.area)" />
                    </p>
                  </div>
                </div>
                <div class="o-grid">
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.product') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      {{ getTitle(object) }}
                    </p>
                  </div>
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      ~ {{ turfArea(getProductArea(object.id)) }} {{ $settings.areaUnit }}
                    </p>
                  </div>
                  <div class="o-grid__col u-2/8 u-text-right">
                    <p class="c-typo__text">
                      {{ getProductPrice(object.id) }}
                    </p>
                  </div>
                </div>
                <div
                  v-if="object.product.subbases.length"
                  class="o-grid"
                >
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.subbases') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-6/8">
                    <div
                      v-for="(subbase, subbaseIndex) in object.product.subbases"
                      :key="subbaseIndex"
                      class="o-grid o-grid--center"
                    >
                      <div class="o-grid__col u-4/12">
                        <p class="c-typo__text">
                          {{ getNameByObject(subbase, 'subbase') }}
                        </p>
                      </div>
                      <div class="o-grid__col u-4/12">
                        <p class="c-typo__text">
                          {{ getSubbaseVolmount(object.id, subbase) }}
                        </p>
                      </div>
                      <div class="o-grid__col u-4/12 u-text-right">
                        <p class="c-typo__text">
                          {{ getSubbasePrice(object.id, subbase) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="object.product.infills.length"
                  class="o-grid"
                >
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.infills') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-6/8">
                    <div
                      v-for="(infill, infillIndex) in object.product.infills"
                      :key="infillIndex"
                      class="o-grid o-grid--center"
                    >
                      <div class="o-grid__col u-4/12">
                        <p class="c-typo__text">
                          {{ getNameByObject(infill, 'infill') }}
                        </p>
                      </div>
                      <div class="o-grid__col u-4/12">
                        <p class="c-typo__text">
                          {{ getInfillPrice(object.id,infill).packages }}
                        </p>
                      </div>
                      <div class="o-grid__col u-4/12 u-text-right">
                        <p class="c-typo__text">
                          {{ getInfillPrice(object.id, infill).formatted }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="o-grid">
                  <div class="o-grid__col u-4/4 u-text-right">
                    <h5 class="c-typo__h5">
                      {{ getAreaPrice(object.id) }}
                    </h5>
                  </div>
                </div>
                <div
                  v-if="object.product.tracks.length
                    && hasWasteConnection(object.product.tracks).anyConnection"
                  class="o-grid"
                >
                  <div class="o-grid__col u-2/8">
                    <p class="c-typo__text">
                      <strong>{{ $t('dialogs.overview.screen_1.infos.title') }}</strong>
                    </p>
                  </div>
                  <div class="o-grid__col u-6/8">
                    <div class="o-grid o-grid--center">
                      <!-- Rest is used by -->
                      <template v-if="hasWasteConnection(object.product.tracks).sourceTargetFor">
                        <div class="o-grid__col u-8/12">
                          <p class="c-typo__text u-color-o">
                            {{ $t('dialogs.overview.screen_1.infos.wasteUsedBy') }}
                          </p>
                        </div>
                        <div class="o-grid__col u-4/12">
                          <div
                            v-for="(track, trackIndex) in object.product.tracks"
                            :key="trackIndex"
                          >
                            <div class="o-grid__col u-12/12 u-text-right">
                              <p
                                v-for="(source, key) in track.sourceTargetFor"
                                :key="`sourceFor-${key}`"
                                class="c-typo__text u-color-o"
                              >
                                {{ getObjectName(source.object, $t('editor.lineOfSight.track.waste.areaShort')) }} - {{ source.track }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                      <!-- Uses rests from -->
                      <template v-if="hasWasteConnection(object.product.tracks).wasteData">
                        <div class="o-grid__col u-8/12">
                          <p class="c-typo__text u-color-o">
                            {{ $t('dialogs.overview.screen_1.infos.usesWasteFrom') }}
                          </p>
                        </div>
                        <div class="o-grid__col u-4/12">
                          <div
                            v-for="(track, trackIndex) in object.product.tracks"
                            :key="trackIndex"
                          >
                            <div class="o-grid__col u-12/12 u-text-right">
                              <p
                                v-if="track.wasteData"
                                class="c-typo__text u-color-o"
                              >
                                {{ $t('dialogs.overview.screen_1.infos.isRestOf', {
                                  object: getObjectName(track.wasteData.object),
                                  track: track.wasteData.track
                                }) }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { productTypes } from '@/helper/api/types'

import MixinMath from '@/mixins/math'
import MixinCosts from '@/mixins/costs'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'
import config from '@/config'

export default {
  name: 'CheckoutProducts',
  mixins: [
    MixinMath,
    MixinCosts,
    MixinProductInLanguage
  ],
  props: {
    objects: {
      type: Array,
      required: false,
      default: () => []
    },
    summary: {
      type: [Map, Object],
      required: false,
      default: () => new Map()
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      products: state => state.products.products
    }),
    ...mapGetters({
      getInfillById: 'products/getInfillById',
      getSubbaseByIdType: 'products/getSubbaseByIdType'
    })
  },
  methods: {
    getTitle (object) {
      const product = this.products.find(p => (p.original.id === object.product.id))
      return this.mx_product_in_language(product.original, ['name'])
    },
    getNameByObject (object, type) {
      let orgObject = null

      switch (type) {
        case 'subbase':
          orgObject = this.getSubbaseByIdType({ id: object.id, type: object.type })
          break
        case 'infill':
          orgObject = this.getInfillById(object.id)
          break
      }
      return (orgObject) ? this.mx_product_in_language(orgObject, ['name'], this.$i18n.locale) : 'unknown'
    },
    getProductThumb (object) {
      const product = this.products.find(p => (p.original.id === object.product.id))
      if (product && product.original.assets.image && product.original.assets.image !== null) {
        return product.original.assets.image.conversions.thumb
      }

      return null
    },
    areaRound (area) {
      let out = this.$units.convert(this.mx_math_roundDecimal(area, 2)) + ' ' + this.$settings.areaUnit
      if (this.measure.transformUnit.id !== this.measure.unit.id) {
        out += ' / ' + this.$units.convert(this.mx_math_roundDecimal(this.convertArea(area), 1)) + ' ' + this.measure.transformUnit.square
      }
      return out
    },
    convertArea (val) {
      return this.$units.convert(Math.pow((Math.sqrt(val) * this.measure.unit.inches) / this.measure.transformUnit.inches, 2))
    },
    turfArea (area) {
      return this.$units.convert(this.mx_math_roundDecimal(area, 2))
    },
    pagination (payload) {
      switch (payload) {
        case 'back':
          break
        case 'next':
          this.$emit('pagination-checkout', 'next')
          break
        case 'option':
          break
        default:
          this.$emit('pagination-checkout', payload)
          break
      }
    },
    getAreaPrice (id) {
      let price = 0
      if (this.summary.has(id)) {
        price = this.summary.get(id).areaPrice
      }

      return this.$world.currencyConvert(price, true)
    },
    getProductPrice (id) {
      let price = 0
      if (this.summary.has(id)) {
        price = this.summary.get(id).turf.total.value
      }
      return this.$world.currencyConvert(price, true)
    },
    getProductArea (id) {
      if (this.summary.has(id)) {
        return this.summary.get(id).turf.total.area
      }

      return 0
    },
    getInfillPrice (id, infill) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        const _infill = data.infills.each.find(i => i.uuid === infill.uuid, data)
        if (typeof _infill !== 'undefined') {
          return {
            packages: _infill.packages,
            formatted: _infill.formatted
          }
        }
      }
      return {
        packages: 0,
        formatted: this.$world.currencyConvert(0, true)
      }
    },
    getSubbasePrice (id, subbase) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)

        if (subbase.type === productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
          const other = data.subbases.others.each.find(i => i.uuid === subbase.uuid)
          if (typeof other !== 'undefined') {
            return other.formatted
          }
        }
        if (subbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) {
          const pad = data.subbases.pads.each.find(i => i.uuid === subbase.uuid)
          if (typeof pad !== 'undefined') {
            return pad.formatted
          }
        }
        if (subbase.type === productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE) {
          const pedestal = data.subbases.pedestals.each.find(i => i.uuid === subbase.uuid)
          if (typeof pedestal !== 'undefined') {
            return pedestal.formatted
          }
        }
      }

      return this.$world.currencyConvert(0, true)
    },
    getSubbaseVolmount (id, subbase) {
      if (this.summary.has(id)) {
        const data = this.summary.get(id)
        if (subbase.type === productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
          const other = data.subbases.others.each.find(i => i.uuid === subbase.uuid)
          if (typeof other !== 'undefined') {
            return `${other.volume} ${this.$settings.subbaseUnits.volume}`
          }
        }
        if (subbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) {
          const pad = data.subbases.pads.each.find(i => i.uuid === subbase.uuid)
          if (typeof pad !== 'undefined') {
            return `${pad.amount} ${this.$settings.subbaseUnits.padShort}`
          }
        }
        if (subbase.type === productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE) {
          const pedestal = data.subbases.pedestals.each.find(i => i.uuid === subbase.uuid)
          if (typeof pedestal !== 'undefined') {
            return `${pedestal.amount} ${this.$settings.subbaseUnits.pedestalShort}`
          }
        }
      }
      return 0 + ' pc'
    },
    getObjectName (object, extra = '') {
      if (object) {
        return object.replace(config.objects.ID, extra)
      }
      return ''
    },
    hasWasteConnection (tracks) {
      const wasteConnections = {
        anyConnection: null,
        sourceTargetFor: null,
        wasteData: null
      }
      for (const track of tracks) {
        // TODO: Shouldn't it be: Object.prototype.hasOwnProperty.call ?
        if (Object.prototype.hasOwnProperty.bind(track, 'sourceTargetFor') &&
          track.sourceTargetFor.length) {
          wasteConnections.sourceTargetFor = true
          wasteConnections.anyConnection = true
        }
        if (Object.prototype.hasOwnProperty.bind(track, 'wasteData') &&
          track.wasteData !== null) {
          wasteConnections.wasteData = true
          wasteConnections.anyConnection = true
        }
      }
      return wasteConnections
    }
  }
}
</script>
