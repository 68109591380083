<template>
  <div
    v-if="isVisible"
    class="c-nav-sub-dialog"
  >
    <div class="c-nav-sub-dialog__container">
      <div
        v-if="!hideTitle"
        class="c-nav-sub-dialog__header"
      >
        <div class="c-nav-sub-dialog__title">
          {{ title }}
        </div>
        <div class="c-nav-sub-dialog__subtitle">
          {{ subtitle }}
        </div>
      </div>
      <div class="c-nav-sub-dialog__inner">
        <!--
            @slot Default slot for content
        -->
        <slot />
        <button-base
          id="sub-nav-dialog-frame-abort"
          :classes="{'u-hidden': hideClose}"
          :disabled="cannotClose"
          :label="$t('common.abort') "
          :on-click-action="() => { return close() }"
          style-color="gray"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import MixinSubNav from '@/mixins/subnav'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * The SubNavDialogsFrame Component.
 * @displayName Sub Nav Dialogs Frame
 */

export default {
  name: 'SubNavDialogsFrame',
  components: { ButtonBase },
  mixins: [
    MixinSubNav
  ],
  props: {
    /**
     * Dialog name from store (modules/navigation.js).
     * Used to save information about currently opened dialogs and their additional data ('dialogProps')
     */
    targetSubNavDialog: {
      type: String,
      required: true
    },
    /**
     * Title label
     */
    title: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Subtitle label
     */
    subtitle: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Blocks closing
     */
    cannotClose: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Hides the 'abort' button
     */
    hideClose: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Hides the title
     */
    hideTitle: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      visible: state => state.navigation.dialogs.visible
    }),
    isVisible () {
      return this.visible[this.targetSubNavDialog]
    }
  },
  watch: {
    isVisible: {
      handler (n, o) {
        if (n === true) {
          /**
           * Triggers when the dialog frame is opened
           */
          this.$emit('dialog-opened')
        }
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.keyPressed)
  },
  destroyed () {
    document.removeEventListener('keydown', this.keyPressed)
  },
  methods: {
    close () {
      /**
       * Triggers when the dialog frame is closed
       */
      this.$emit('abort-button-pressed')
      this.mx_subnav_closeDialog()
    },
    /**
     * Manages keyboard events
     *
     * @param {KeyboardEvent} e
     */
    keyPressed (e) {
      if (this.isVisible) {
        switch (e.key) {
          case 'Esc': // IE/Edge specific value
          case 'Escape':
            if (!this.cannotClose) {
              this.close()
            }
            break
        }
      }
    }
  }
}
</script>
