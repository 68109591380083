import config from '@/config'
import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Creates elements as VUE vnode
 * use: create.js, modifier.js
 * @displayName Create elements
 */
export default {
  name: 'CreateElementMixin',
  methods: {
    /**
         * Create svg group "g"
         * @param elements
         * @returns {VNode}
         * @public
         */
    mx_create_elements_group (elements) {
      return this.$createElement('g', {
        class: {
          'c-object c-object__modifier__group': true
        }
      }, elements)
    },
    /**
         * Create svg element "line"
         * @requires @/mixins/math
         * @requires @/mixins/create
         * @param id
         * @param keys
         * @param coords
         * @param child
         * @returns {VNode}
         * @public
         */
    mx_create_elements_line (id, keys, coords, child) {
      const obj = {
        class: {
          'c-object c-object__line c-object--calc': true,
          'c-object__line--selected': (this.mx_create_activeModifier === id),
          'c-object__line--hover': (this.mx_create_modifierHover === id) && this.object.selectionMode === config.objects.modes.EDIT,
          'c-object__line--dragover': this.mx_object_dragover
        },
        attrs: {
          id: 'line-' + id,
          x1: coords.x1,
          y1: coords.y1,
          x2: coords.x2,
          y2: coords.y2,
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
        }
      }
      // if (this.object.selectionMode !== config.objects.modes.SELECTED) {
      //     obj.on = {
      //         click: e => this.mx_create_selectModifier({id: id, keys: keys, event: e, child: child}),
      //         mouseenter: e => this.mx_create_hoverModifier(id),
      //         mouseleave: e => this.mx_create_hoverModifier(null)
      //     }
      // }
      return this.$createElement('line', obj)
    },
    /**
         * Create the svg element "circle"
         * @requires @/mixins/create
         * @param id
         * @param cx
         * @param cy
         * @param child
         * @returns {VNode}
         * @public
         */
    mx_create_elements_circle (id, cx, cy, child) {
      const createdCircle = this.$createElement('circle', {
        class: {
          'c-object c-object__circle': true,
          'c-object__circle--selected': (this.mx_create_activeModifier === id),
          'c-object__circle--hover': (this.mx_create_modifierHover === id),
          'c-object__circle--moving': this.mx_create_isPointMoving(id),
          'c-object__circle--active': this.mx_create_isObjectSelected(this.object.id),
          'c-object__circle--hidden': this.object.selectionMode === config.objects.modes.SELECTED || this.object.selectionMode === config.objects.modes.LOS
        },
        attrs: {
          id: 'circle-' + id,
          cx: cx,
          cy: cy,
          r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth),
          'data-id': id
        },
        on: {
          click: e => this.mx_create_selectModifier({ id: id, keys: null }),
          mouseenter: e => this.mx_create_hoverModifier(id),
          mouseleave: e => this.mx_create_hoverModifier(null),
          mousedown: e => this.$events.fire(editorEventTypes.OBJECT_MODIFIER_MOUSEDOWN, {
            x: cx,
            y: cy,
            evt: e,
            id: id,
            ctrl: 'menu-mouse-circle',
            handle: false,
            handleId: null,
            drag: true,
            child: child
          })
        },
        key: id
      })

      if (this.object.type === config.objects.types.CIRCLE) {
        createdCircle.data.on.mousedown = e => this.$events.fire(editorEventTypes.OBJECT_MODIFIER_MOUSEDOWN, {
          x: cx,
          y: cy,
          evt: e,
          id: id,
          ctrl: null,
          handle: false,
          handleId: null,
          drag: true,
          child: child
        })
      }

      return createdCircle
    },
    /**
         * Create svg "path"
         * @requires @/mixins/create
         * @param id
         * @param keys
         * @param d
         * @param child
         * @param fill
         * @returns {VNode}
         * @public
         */
    mx_create_elements_modifierPath (id, keys, d, child, fill = 'none') {
      const obj = {
        class: {
          'c-object c-object__line c-object--calc': true,
          'c-object__line--selected': (this.mx_create_activeModifier === id),
          'c-object__line--hover': (this.mx_create_modifierHover === id),
          'c-object__line--hidden': this.object.selectionMode === config.objects.modes.SELECTED,
          'c-object__line--dragover': this.mx_object_dragover
        },
        attrs: {
          fill: fill,
          d: d,
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
        }
      }
      if (this.object.selectionMode === config.objects.modes.EDIT) {
        obj.on = {
          click: e => this.mx_create_selectModifier({ id: id, keys: keys, event: e, child: child }),
          mouseenter: e => this.mx_create_hoverModifier(id),
          mouseleave: e => this.mx_create_hoverModifier(null)
        }
      }
      return this.$createElement('path', obj)
    },
    /**
         * Create cubic handles
         * @requires @/mixins/math
         * @requires @/mixins/create
         * @param id
         * @param point
         * @param child
         * @returns {*[]}
         * @public
         */
    mx_create_elements_cubic (id, point, child) {
      return [
        this.$createElement('circle', {
          class: {
            'c-object c-object__circle c-object__circle--handle': true,
            'c-object__circle--selected': (this.mx_create_activeModifier === id),
            'c-object__circle--moving': this.mx_create_isPointMoving(id),
            'c-object__circle--edit': this.object.selectionMode === config.objects.modes.EDIT,
            'c-object__circle--hover': (this.mx_create_modifierHover === id)
          },
          attrs: {
            cx: point.x1,
            cy: point.y1,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          },
          on: {
            mousedown: e => {
              if (this.mx_create_activeModifier !== id) {
                this.mx_create_selectModifier({ id: id, keys: null })
              }
              if (this.mx_create_activeModifier === undefined) {
                this.mx_create_activeModifier = id
              }
              this.$events.fire(editorEventTypes.OBJECT_HANDLE_MODIFIER_MOUSEDOWN, {
                x: point.x1,
                y: point.y1,
                evt: e,
                id: id,
                ctrl: 'menu-mouse-handle',
                handle: true,
                handleId: 1,
                drag: true,
                child: child
              })
            },
            mouseenter: e => this.mx_create_hoverModifier(id),
            mouseleave: e => this.mx_create_hoverModifier(null)
          }
        }),
        this.$createElement('circle', {
          class: {
            'c-object c-object__circle c-object__circle--handle': true,
            'c-object__circle--selected': (this.mx_create_activeModifier === id),
            'c-object__circle--moving': this.mx_create_isPointMoving(id),
            'c-object__circle--edit': this.object.selectionMode === config.objects.modes.EDIT,
            'c-object__circle--hover': (this.mx_create_modifierHover === id)
          },
          attrs: {
            cx: point.x2,
            cy: point.y2,
            r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
            'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
          },
          on: {
            mousedown: e => {
              if (this.mx_create_activeModifier !== id) {
                this.mx_create_selectModifier({ id: id, keys: null })
              }
              if (this.mx_create_activeModifier === undefined) {
                this.mx_create_activeModifier = id
              }
              this.$events.fire(editorEventTypes.OBJECT_HANDLE_MODIFIER_MOUSEDOWN, {
                x: point.x2,
                y: point.y2,
                evt: e,
                id: id,
                ctrl: 'menu-mouse-handle',
                handle: true,
                handleId: 2,
                drag: true
              })
            },
            mouseenter: e => this.mx_create_hoverModifier(id),
            mouseleave: e => this.mx_create_hoverModifier(null)
          }
        })
      ]
    },
    /**
         * Creates quadratic handles
         * @param id
         * @param point
         * @param child
         * @returns {VNode}
         * @public
         */
    mx_create_elements_quadratic (id, point, child) {
      return this.$createElement('circle', {
        class: {
          'c-object c-object__circle c-object__circle--handle': true,
          'c-object__circle--selected': (this.mx_create_activeModifier === id),
          'c-object__circle--moving': this.mx_create_isPointMoving(id),
          'c-object__circle--edit': this.object.selectionMode === config.objects.modes.EDIT,
          'c-object__circle--hover': (this.mx_create_modifierHover === id)
        },
        attrs: {
          cx: point.x1,
          cy: point.y1,
          r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth)
        },
        on: {
          mousedown: e => {
            if (this.mx_create_activeModifier !== id) {
              this.mx_create_selectModifier({ id: id, keys: null })
            }
            if (this.mx_create_activeModifier === undefined) {
              this.mx_create_activeModifier = id
            }
            this.$events.fire(editorEventTypes.OBJECT_HANDLE_MODIFIER_MOUSEDOWN, {
              x: point.x1,
              y: point.y1,
              evt: e,
              id: id,
              ctrl: 'menu-mouse-handle',
              handle: true,
              handleId: 1,
              drag: true,
              child: child
            })
          },
          mouseenter: e => this.mx_create_hoverModifier(id),
          mouseleave: e => this.mx_create_hoverModifier(null)
        }
      })
    },
    /**
         * Create the handle line
         * @param id
         * @param coords
         * @returns {VNode}
         * @public
         */
    mx_create_elements_handleLine (id, coords) {
      return this.$createElement('line', {
        class: {
          'c-object c-object__line c-object__line--handle': true,
          // 'c-object__circle--selected': (this.mx_create_activeModifier === id),
          'c-object__circle--edit': this.object.selectionMode === config.objects.modes.EDIT
        },
        attrs: {
          'stroke-dasharray': this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashLength) +
                        ',' + this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashSpace),
          x1: coords.x1,
          y1: coords.y1,
          x2: coords.x2,
          y2: coords.y2,
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
        }
      })
    },
    /**
         * Create a radius line
         * @requires @/mixins/create
         * @param x1
         * @param y1
         * @param x2
         * @param y2
         * @returns {VNode}
         * @public
         */
    mx_create_elements_radiusLine (x1, y1, x2, y2) {
      return this.$createElement('line', {
        class: {
          'c-object c-object__line c-object--calc': true,
          'c-object__radius-line--hidden': this.object.selectionMode === config.objects.modes.SELECTED || !this.mx_create_isObjectSelected(this.object.id)
        },
        attrs: {
          x1: x1,
          y1: y1,
          x2: x2,
          y2: y2,
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth),
          'stroke-dasharray': this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashLength) +
                        ',' + this.mx_math_handleElementsOnScale(this.commonSvgSettings.line.dashSpace)
        }
      })
    },
    /**
         * Returns the center of the circle
         * @param id
         * @param cx
         * @param cy
         * @returns {VNode}
         * @public
         */
    mx_create_elements_centerCircle (id, cx, cy) {
      return this.$createElement('circle', {
        class: {
          'c-object c-object__circle c-object__center-circle': true,
          'c-object__circle--active': this.mx_create_isObjectSelected(this.object.id),
          'c-object__circle--hidden': this.object.selectionMode === config.objects.modes.SELECTED
        },
        attrs: {
          id: 'circle-' + id,
          cx: cx,
          cy: cy,
          r: this.mx_math_handleElementsOnScale(this.commonSvgSettings.circleRadius),
          'stroke-width': this.mx_math_handleElementsOnScale(this.commonSvgSettings.circle.strokeWidth),
          'data-id': id
        }
      })
    }
  }
}
