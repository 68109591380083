import Vue from 'vue'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

const defaultState = () => {
  return {
    clients: [],
    apiKey: null
  }
}

const state = defaultState()

const getters = {
  get: (state) => {
    return state.clients
  },
  getApiKey: (state) => {
    return state.apiKey
  }
}

const actions = {
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.apiUser, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.APICLIENT_SET_CLIENT, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async create ({ rootState, dispatch, commit }, client) {
    await apiMethods.request('post', routes.apiUser, client, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.APICLIENT_ADD_CLIENT, response.data.data)
        commit(types.APICLIENT_SET_API_KEY, response.data.data.current_token)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async update ({ rootState, dispatch, commit }, client) {
    await apiMethods.request('patch', routes.apiUser + '/' + client.id, client, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.APICLIENT_UPDATE_CLIENT, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async delete ({ rootState, dispatch, commit }, client) {
    await apiMethods.request('delete', routes.apiUser + '/' + client.id, null, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.APICLIENT_REMOVE_CLIENT, client.id)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async refreshApiKey ({ rootState, state, dispatch, commit }, clientId) {
    const client = state.clients.find(c => c.id === clientId)

    if (typeof client !== 'undefined') {
      await apiMethods.request('patch', routes.apiUser + '/' + client.id + '/token', null, rootState.backendAPI.login.token)
        .then(response => {
          commit(types.APICLIENT_UPDATE_CLIENT, response.data.data)
          commit(types.APICLIENT_SET_API_KEY, response.data.data.current_token)
        }).catch(error => {
          dispatch('backendAPI/error', error, { root: true })
        })
    }
  },
  clearApiKey ({ commit }) {
    commit(types.APICLIENT_SET_API_KEY, null)
  }
}

const mutations = {
  [types.APICLIENT_SET_CLIENT] (state, client) {
    Vue.set(state, 'clients', client)
  },
  [types.APICLIENT_ADD_CLIENT] (state, client) {
    state.clients.push(client)
  },
  [types.APICLIENT_UPDATE_CLIENT] (state, client) {
    const index = state.clients.findIndex(c => c.id === client.id)

    if (index !== -1) {
      Vue.set(state.clients, index, client)
    }
  },
  [types.APICLIENT_REMOVE_CLIENT] (state, clientId) {
    const index = state.clients.findIndex(c => c.id === clientId)
    if (index !== -1) {
      state.clients.splice(index, 1)
    }
  },
  [types.APICLIENT_SET_API_KEY] (state, apiKey) {
    state.apiKey = apiKey
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
