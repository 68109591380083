<template>
  <dialog-frame
    :style-content-no-frame="true"
    :subtitle=" $t('dialogs.helpPage.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.helpPage.title') "
    style-dialog-height="670px"
    style-dialog-width="1280px"
  >
    <template #header />

    <template #content>
      <div class="c-help-page">
        <h3 class="c-typo__h3 u-mb-size-standard">
          {{ $t('dialogs.helpPage.keyboard_title') }}
        </h3>
        <div
          class="c-help-page__infos"
          v-html="$t('dialogs.helpPage.keyboard_info')"
        />

        <div class="c-help-page__table-wrapper u-mt-size-60">
          <table>
            <tr>
              <th />
              <td class="c-help-page__header-icons">
                <icon classes="fab fa-windows" /> & <icon class="fab fa-apple" />
              </td>
              <td class="c-help-page__header-icons">
                <icon classes="fab fa-apple" />
              </td>
            </tr>
            <tr
              v-for="(shortcuts, configKey) in configFile"
              :key="configKey"
            >
              <th>
                <div v-html="translation(configKey, 'name')" />
                <span v-html="translation(configKey, 'description')" />
              </th>
              <td>
                <span
                  v-for="(shortcut, scIndex) in shortcuts.win"
                  :key="scIndex"
                >
                  <template v-if="shortcut.special">
                    <span
                      v-for="(special, spIndex) in shortcut.special"
                      :key="spIndex"
                      class="c-help-page__key c-help-page__key-special"
                      :class="`c-help-page__key-special--${getTheme}-theme`"
                      v-html="special"
                    />
                  </template>
                  <span
                    v-if="shortcut.key"
                    class="c-help-page__key"
                  >
                    {{ shortcut.key }}
                  </span>
                  <template v-if="shortcut.icons">
                    <span
                      v-for="(icon, iIndex) in shortcut.icons"
                      :key="iIndex"
                      class="c-help-page__key"
                    >
                      <icon :classes="icon" />
                    </span>
                  </template>
                </span>
              </td>
              <td>
                <span
                  v-for="(shortcut, scMIndex) in shortcuts.mac"
                  :key="scMIndex"
                >
                  <template v-if="shortcut.special">
                    <span
                      v-for="(special, spIndex) in shortcut.special"
                      :key="spIndex"
                      class="c-help-page__key c-help-page__key-special"
                      :class="`c-help-page__key-special--${getTheme}-theme`"
                    >
                      {{ special }}
                    </span>
                  </template>
                  <span
                    v-if="shortcut.key"
                    class="c-help-page__key"
                  >
                    {{ shortcut.key }}
                  </span>
                  <template v-if="shortcut.icons">
                    <span
                      v-for="(icon, iIndex) in shortcut.icons"
                      :key="iIndex"
                      class="c-help-page__key"
                    >
                      <icon :classes="icon" />
                    </span>
                  </template>
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import config from '@/config'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import Icon from '@/components/common/Icon'
import { mapGetters } from 'vuex'

export default {
  name: 'HelpPage',
  components: {
    Icon,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'helpPage'
    }
  },
  computed: {
    configFile () {
      return config.keyboard
    },
    ...mapGetters({
      getTheme: 'gui/getTheme'
    }),
    refreshKey () {
      return this.$i18n.locale
    }
  },
  methods: {
    translation (configKey, field) {
      return this.$i18n.messages[this.$i18n.locale].dialogs.helpPage.keyboard[configKey][field]
    }
  }
}
</script>
