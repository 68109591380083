<template>
  <panel-container :title="$t('editor.lineOfSight.product.special.title')">
    <p class="c-los__info">
      {{ $t('editor.lineOfSight.product.special.info') }}
    </p>
    <button-base
      id="product-special-calculation-360"
      :label="$t('editor.lineOfSight.product.special.cheapestThreeSixty')"
      :on-click-action="() => { return cheapestThreeSixty() }"
      classes="u-mr-size-0"
      style-min-width="180px"
    />
    <button-base
      id="product-special-calculation-edges"
      :label="$t('editor.lineOfSight.product.special.cheapestObjectEdge')"
      :on-click-action="() => { return cheapestObjectEdge() }"
      classes="u-mr-size-0 u-mt-size-0"
      style-min-width="180px"
    />
  </panel-container>
</template>

<script>
import { mapGetters } from 'vuex'
import PanelContainer from '@/components/common/PanelContainer'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * Displays two buttons which triggers the line of sight calculation
 * - Cheapest angle of 360 degree
 * - Cheapest angle of object edge-angles
 * @displayName LineOfSight Panel - Product - Calculation by angle
 */
export default {
  name: 'LineOfSightCalculationByAngle',
  components: {
    ButtonBase,
    PanelContainer
  },
  computed: {
    ...mapGetters({
      getObjectLines: 'lineOfSightNew/getObjectLines'
    })
  },
  methods: {
    /**
     * Trigger Calculate with angles from 0 - 359 degree
     * @public
     */
    cheapestThreeSixty () {
      const angles = [...Array(360).keys()]
      this.$emit('calculationByAngles', { action: 'calculationByAngles', data: angles })
    },
    /**
     * Trigger Calculate with angles of object edges
     * @public
     */
    cheapestObjectEdge () {
      const angles = this.getObjectLines.map(line => {
        return Number(line.angle)
      })
      angles.sort(function (a, b) {
        return a - b
      })

      this.$emit('calculationByAngles', { action: 'calculationByAngles', data: angles })
    }
  }
}
</script>
