import * as editorEventTypes from '@/events/editor-event-types'

export default {
  name: 'EventManager',
  methods: {
    mx_manager_remove_all_events () {
      const current_events = this.$events._events

      for (const key in editorEventTypes) {
        if (current_events[key] !== undefined && current_events[key] !== null) {
          current_events[key].forEach(fkt => {
            this.$events.off(key, fkt)
          })
        }
      }
    }
  }
}
