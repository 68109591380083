<template>
  <div class="c-los__panel-content">
    <panel-container :title="$t('editor.lineOfSight.subbase.title')">
      <select-infills-substructures
        :button-text="$t('editor.lineOfSight.subbase.add')"
        :disabled="error"
        :items="dropDownItems"
        :no-items="$t('editor.lineOfSight.subbase.noSubbases')"
        @addSelectedItem="addSubbase"
        @changedSelectedItem="changedSubbase"
      >
        <template #properties>
          <input-field
            v-if="subbase !== null && productTypes.PRODUCT_OTHER_SUBBASE_TYPE === subbase.type"
            id="override-depth"
            v-model="overrideDepthField"
            :disabled="subbase === null"
            :label="$t('editor.lineOfSight.subbase.thickness',{unit: $settings.subbaseUnits.thickness})
              + ( (subbase !== null && subbase.overlay.subbase_thickness)
                ? $t('editor.lineOfSight.subbase.thickness_default', {default: subbase.overlay.subbase_thickness})
                : '' )"
            :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
            type="number"
            @register="registerInput"
          />
        </template>
      </select-infills-substructures>

      <draggable
        v-model="draggableElements"
        :disabled="isDraggable"
        class="c-los-card-container"
        handle=".c-los-card__handle-container"
        v-bind="{animation: 200}"
        @end="dragTransition = false"
        @start="dragTransition = true"
      >
        <transition-group
          :name="dragTransition ? 'c-los-card-container__drag' : ''"
          type="transition"
        >
          <subbase-card
            v-for="(subbase, index) in draggableElements"
            :key="'subbase' +index+subbase.id"
            :index="index"
            :subbase="subbase"
            @disableDrag="disableDrag"
          />
        </transition-group>
      </draggable>
    </panel-container>

    <substructures-costs />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'

import { Subbase } from '@/classes/products'
import { productTypes } from '@/helper/api/types'

import PanelContainer from '@/components/common/PanelContainer'
import SelectInfillsSubstructures from '@/components/modules/lineOfSight/tools/common/SelectInfillsSubstructures'

import SubbaseCard from '@/components/modules/lineOfSight/tools/subbase/SubbaseCard'
import InputField from '@/components/common/InputField'
import SubstructuresCosts from '@/components/modules/lineOfSight/tools/subbase/SubstructuresCosts'

import MixinValidate from '@/mixins/validate'
import MixinMath from '@/mixins/math'
import MixinLineOfSight from '@/mixins/lineOfSight'
import MixinAlgorithmServer from '@/mixins/algorithmServer'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

const convert_value_to_pixels = (measure, value) => {
  return (value / measure.toUnit) * measure.fromPixels
}

/**
 * Provides a panel to add subbases for lawn products
 * Subbases: Other, Pad, Pedestal
 * @displayName LineOfSight Panel - Subbases
 */
export default {
  name: 'SubstructuresPanel',
  components: {
    SubstructuresCosts,
    draggable,
    PanelContainer,
    SubbaseCard,
    SelectInfillsSubstructures,
    InputField
  },
  mixins: [
    MixinValidate,
    MixinMath,
    MixinLineOfSight,
    MixinAlgorithmServer,
    MixinProductInLanguage
  ],
  data: () => {
    return {
      isDraggable: false,
      subbase: null,
      overrideDepth: 0,
      error: false,
      dragTransition: false,
      productTypes: productTypes
    }
  },
  computed: {
    ...mapGetters({
      getMeasure: 'project/measurement/getMeasure',
      getAllSubbases: 'products/getAllSubbases',
      getProductSubstructures: 'lineOfSightNew/getProductSubstructures',
      getSubbaseByIdType: 'products/getSubbaseByIdType',
      getSubbaseAmountByIdType: 'lineOfSightNew/getSubbaseAmountByIdType'
    }),
    draggableElements: {
      get () {
        return this.getProductSubstructures
      },
      set (value) {
        this.updateSubstructuresSorting(value)
      }
    },
    overrideDepthField: {
      get () {
        return this.overrideDepth
      },
      set (payload) {
        this.overrideDepth = payload.value
        this.error = payload.error
      }
    },
    dropDownItems () {
      return _.cloneDeep(this.getAllSubbases).map(base => {
        base.original.type = base.type
        base.original.name = this.mx_product_in_language(base.original, ['name'], this.$i18n.locale)
        return base.original
      })
    }
  },
  methods: {
    ...mapActions({
      addSubbaseToProduct: 'lineOfSightNew/addSubbaseToProduct',
      updateSubstructuresSorting: 'lineOfSightNew/updateSubstructuresSorting'
    }),
    /**
     * Handle drag component
     * Elements inside the component are sortable
     * @param {boolean} value
     * @public
     */
    disableDrag (value) {
      this.isDraggable = value
    },
    registerInput (payload) {
      this.error = payload.error
    },
    /**
     * Gets called on selected changed
     * @param queryData
     */
    changedSubbase (queryData) {
      this.error = false
      this.overrideDepth = 0
      if (queryData.id !== null) {
        this.subbase = this.getSubbaseByIdType(queryData)
        this.$nextTick(() => {
          if (productTypes.PRODUCT_OTHER_SUBBASE_TYPE === this.subbase.type) {
            const thickness = (this.subbase.overlay.subbase_thickness !== null) ? this.subbase.overlay.subbase_thickness : 0
            this.overrideDepth = thickness
            this.error = !this.mx_validate_validatePositiveNumber(thickness)
          }
        })
      }
    },
    /**
     * Add selected subbase to list
     * @public
     */
    async addSubbase () {
      if (this.subbase !== null) {
        let thickness = 0

        switch (this.subbase.type) {
          case productTypes.PRODUCT_OTHER_SUBBASE_TYPE:
            thickness = this.mx_math_roundDecimal(this.overrideDepth, 3)
            break
          case productTypes.PRODUCT_PAD_SUBBASE_TYPE:
            thickness = this.subbase.overlay.pad_thickness
            break
          case productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE:
            thickness = this.subbase.overlay.pedestal_thickness
            break
        }

        let amount = this.getSubbaseAmountByIdType({ id: this.subbase.original.id, type: this.subbase.type })

        if (amount === null) {
          if (this.subbase.type !== productTypes.PRODUCT_OTHER_SUBBASE_TYPE) {
            const width = (this.subbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) ? this.subbase.overlay.pad_width : this.subbase.overlay.pedestal_width
            const length = (this.subbase.type === productTypes.PRODUCT_PAD_SUBBASE_TYPE) ? this.subbase.overlay.pad_length : this.subbase.overlay.pedestal_length
            const properties = {
              angles: [this.losAngle],
              perfectVisualResult: true,
              alignment: this.losAlignment,
              threshold: length,
              shift: 0,
              turfWidth: Number(convert_value_to_pixels(this.getMeasure, width)),
              turfLength: Number(convert_value_to_pixels(this.getMeasure, length))
            }

            const result = await this.mx_algorithmServer_calculate(this.losObject, properties)
            if (result) {
              amount = result.rolls_used
            }
          }
        }

        const subbaseToAdd = new Subbase(this.subbase.original.id, this.subbase.original.name, this.subbase.type, this.subbase.versioning.version_nr, this.subbase.versioning.id, thickness)
        subbaseToAdd.amount = amount

        this.addSubbaseToProduct(subbaseToAdd)
      }
    }
  }
}
</script>
