import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex/store'
import evtHandlerPlugin from './plugin/events'
import paperPlugin from './plugin/paper'
import svgjsPlugin from './plugin/svgjs'
import currencyPlugin from './plugin/currency'
import worldPlugin from './plugin/world'
import unitsPlugin from './plugin/units'
import performancePlugin from './plugin/performance'
import settingsPlugin from '@/plugin/project/settings'
import copyToClipboardPlugin from './plugin/copyToClipboard'
import devLogPlugin from './plugin/devLog'
import i18n from './i18n/language'
import './axios-interceptor'
import Toasted from 'vue-toasted'
import VTooltip from 'v-tooltip'

Vue.use(settingsPlugin, { i18n: i18n })
Vue.use(evtHandlerPlugin)
Vue.use(paperPlugin)
Vue.use(copyToClipboardPlugin)
Vue.use(svgjsPlugin)
Vue.use(currencyPlugin)
Vue.use(worldPlugin)
Vue.use(unitsPlugin)
Vue.use(performancePlugin)
Vue.use(devLogPlugin)
Vue.use(VTooltip, {
  defaultDelay: { show: 500, hide: 0 },
  defaultOffset: 12,
  popover: {
    defaultDelay: { show: 500, hide: 0 },
    defaultOffset: 12,
    defaultTrigger: 'hover focus'
  }
})

Vue.use(Toasted, {
  position: 'bottom-center',
  duration: 5000,
  keepOnHover: true,
  singleton: true,
  iconPack: 'fontawesome'
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  mounted () {
    // this.$store.dispatch('history/initHistory')
  },
  render: h => h(App)
}).$mount('#app')
