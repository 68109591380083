<template>
  <svg
    id="order-down-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >

    <path
      class="u-fill-w"
      d="M19.87,29.3,6.74,16.17a2.5,2.5,0,1,1,3.53-3.53l9.6,9.59,9.79-9.79A2.5,2.5,0,0,1,33.19,16Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderDownIcon'
}
</script>
