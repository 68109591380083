<template>
  <div
    :id="id"
    :class="[label ? 'c-input-field-slide__container--labeled' : '', 'c-input-field-slide__container', classes]"
    :style="`width: ${styleContainerWidth}`"
  >
    <label
      v-if="label"
      :for="id + '-input-field'"
      class="c-typo__label"
      v-html="label"
    />

    <div
      :class="[label && !styleWidth ? 'c-input-field-slide__container--labeled-container' : '']"
      :style="`width: ${styleWidth}`"
    >
      <input
        :id="id + '-input-field'"
        :class="inputClasses"
        :disabled="disabled"
        :value="tempValue"
        step="any"
        type="number"
        @focusin="setInputFocus(true)"
        @focusout="setInputFocus(false)"
        @input="updateInput({val: $event.target.value, el: 'input'})"
      >

      <div
        v-if="suffix !== ''"
        :id="id + '-input-field-suffix'"
        class="c-input-field__suffix"
      >
        {{ suffix }}
      </div>

      <div class="c-form__slide-input-container">
        <!--
            Emits an input event to the parent component. (triggered on input)
            @event input
            @property {{val: number, el: 'slider'}} payload - Emitted payload
        -->
        <!--
            Emits a mousedown event to the parent component. (triggered on mousedown)
            @event mousedown
        -->
        <!--
            Emits a mouseup event to the parent component. (triggered on mouseup)
            @event mouseup
        -->
        <input
          :id="id + '-input-slider'"
          :disabled="disabled"
          :max="max"
          :min="min"
          :step="step"
          :value="tempValue"
          class="c-form__slide-input"
          type="range"
          @input="updateInput({val: $event.target.value, el: 'slider'})"
          @mousedown="$emit('mousedown')"
          @mouseup="$emit('mouseup',$event.target.value)"
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

/**
 * The InputSlider Component.
 * @displayName Input Slider
 */

export default {
  name: 'InputSlider',
  props: {
    /**
     * ID of this component (also used in html and tests)
     */
    id: {
      type: String,
      required: true
    },
    /**
     * Additional CSS classes for the whole component
     */
    classes: {
      type: [String, Array],
      default: '',
      required: false
    },
    /**
     * Disables the whole component
     */
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    /**
     * Description label displayed near the component
     */
    label: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Min value of the slider
     */
    min: {
      type: [Number, String],
      default: 0,
      required: false
    },
    /**
     * Max value of the slider
     */
    max: {
      type: [Number, String],
      default: 100,
      required: false
    },
    /**
     * Step value of the slider
     */
    step: {
      type: [Number, String],
      required: false,
      default: 1
    },
    /**
     * Suffix displayed in the back of the input field
     */
    suffix: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Defines the width of the container (with label)
     */
    styleContainerWidth: {
      type: String,
      default: '100%',
      required: false
    },
    /**
     * Defines the width of the input field
     */
    styleWidth: {
      type: String,
      default: '',
      required: false
    },
    /**
     * @model
     * Current item (bind via v-model)
     */
    value: {
      type: [Number, String],
      required: false,
      default: 0
    }
  },
  data () {
    return {
      error: false,
      // internal value which is always shown despite this, if it's correct or not
      tempValue: 0
    }
  },
  computed: {
    inputClasses () {
      return [
        'c-input-field',
        'c-input-field--100percent',
        this.error ? 'c-input-field--error' : '',
        this.suffix ? 'c-input-field--suffixed' : ''
      ]
    },
    stepPrecision () {
      return this.step.toString().split('.')[1].length
    }
  },
  watch: {
    // for changes from outside - still with validation
    value (val) {
      this.updateInput({ val: parseFloat(val), el: 'watcher' })
    },
    max (val) {
      if (val < this.tempValue) {
        this.updateInput({ val: parseFloat(val), el: 'watcher' })
      }
    },
    min (val) {
      if (val > this.tempValue) {
        this.updateInput({ val: parseFloat(val), el: 'watcher' })
      }
    }
  },
  mounted () {
    this.tempValue = this.value
  },
  methods: {
    ...mapMutations({
      setInputFocus: 'events/' + mutationTypes.SET_INPUT_FOCUS
    }),
    updateInput (payload) {
      const parsed = parseFloat(payload.val)
      this.tempValue = parsed

      this.error = !(
        this.$_validate_validateNumber(payload.val) &&
        this.min <= parsed &&
        parsed <= this.max &&
        this.$_isStepConform(parsed)
      )

      // prevent from updating two times because of the watcher
      if (payload.el !== 'watcher') {
        /**
         * Triggers when the value changes - made for custom v-model
         * which gives a possibility to check error and decide if the value should be saved in store
         *
         * @event input
         * @property {{id: string, value: number, error: boolean, el: string}} value - Emitted payload
         * @property {string} id - ID of this component (also used in html and tests)
         * @property {number} value - Current value
         * @property {boolean} error - Error if current value is null
         * @property {string} el - element which caused emit ('watcher', 'input', 'slider')
         */
        this.$emit('input', {
          id: this.id,
          value: parsed,
          error: this.error,
          el: payload.el
        })
      }
    },
    $_isStepConform (val) {
      const parsedStep = parseFloat(this.step)

      if (this.step && !isNaN(parsedStep)) {
        if (Number.isInteger(parsedStep)) {
          return val % this.step === 0
        } else {
          const multiplier = 1 / Number(this.step)
          const roundedMod = ((val * multiplier).toFixed(this.stepPrecision) % (parsedStep * multiplier))
          return roundedMod === 0
        }
      }
      return true
    },
    $_validate_validateNumber (val) {
      return (/^[-+]?\d+([\.][0-9])?\d*$/.test(val)) &&
        !Number.isNaN(Number.parseFloat(val)) &&
        val !== ''
    }
  }

}
</script>
