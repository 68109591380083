import config from '@/config'

export default {
  name: 'LanguageMixin',
  computed: {
    mx_languages_getAvailableLanguages () {
      return this.$i18n.availableLocales.map(el => {
        return {
          label: this.$t(`languages.${el}`),
          value: el
        }
      })
    },
    mx_languages_getSalutations () {
      return config.salutation.map(el => {
        return {
          label: this.$t(`common.salutations.${el.id}`),
          value: el.label
        }
      })
    }
  },
  methods: {
    mx_languages_getCurrentSalutation (key) {
      if (key === null) return ''
      const el = config.salutation.find(el => el.label === key)
      return this.$t(`common.salutations.${el.id}`)
    }
  }
}
