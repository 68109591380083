import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'
import downloadjs from 'downloadjs'

/**
 * Default state for: Files Store
 *
 * @returns {{}}
 */
const defaultState = () => {
  return {
  }
}

const state = defaultState()

const getters = {}

const actions = {
  async exportPdfTurfOverview ({ rootState, dispatch }, data) {
    await apiMethods.request('post', routes.pdf + '/turf/overview', data, rootState.backendAPI.login.token)
      .then(response => {
        const current_datetime = new Date()
        const formatted_date = current_datetime.getFullYear() + '_' + (current_datetime.getMonth() + 1) + '_' + current_datetime.getDate()
        const base64 = response.data

        downloadjs('data:application/octet-stream;base64,' + base64, formatted_date + '_SYNLawn_overview.pdf', 'application/octet-stream')
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  },
  async exportPdfTurfInstallationPlan ({ rootState, dispatch }, data) {
    await apiMethods.request('post', routes.pdf + '/turf/install', data, rootState.backendAPI.login.token)
      .then(response => {
        const current_datetime = new Date()
        const formatted_date = current_datetime.getFullYear() + '_' + (current_datetime.getMonth() + 1) + '_' + current_datetime.getDate()
        const base64 = response.data

        downloadjs('data:application/octet-stream;base64,' + base64, formatted_date + '_SYNLawn_installation_plan.pdf', 'application/octet-stream')
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  }
}

const mutations = {
  [types.RESET_EXPORT_STATE] (state) {
    Object.assign(state, defaultState())
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
