<template>
  <div
    :class="{'c-scale-panel--hidden': (shouldBlockGui)}"
    class="c-scale-panel"
  >
    <!-- Main panel area and buttons -->

    <div
      ref="scalePanelMainpanel"
      class="c-scale-panel__mainpanel-group"
    >
      <!-- Minus Button -->

      <div
        id="scale-panel-scale-out"
        class="c-scale-panel__mainpanel-item c-scale-panel__mainpanel-item-minus"
        @mousedown="scaleOut()"
        @mouseleave="removeScaleEvent()"
        @mouseup="removeScaleEvent()"
      >
        <icon classes="fas fa-2x fa-minus" />
      </div>

      <!-- Scale Button -->

      <div
        id="scale-panel-scale-list-opener"
        :class="[submenuScaleVisible
                   ? 'c-scale-panel__mainpanel-item--active c-scale-panel__mainpanel-item-opener--up--active'
                   : '' ,
                 'c-scale-panel__mainpanel-item c-scale-panel__mainpanel-item-choose c-scale-panel__mainpanel-item-opener c-scale-panel__mainpanel-item-opener--up']"
        @click="toggleSubmenuScaleVisibility"
      >
        <!-- Scale Submenu Panel -->

        <transition name="c-dropdown">
          <div
            v-if="submenuScaleVisible"
            class="c-dropdown-list-group c-dropdown-list-group--up"
          >
            <div
              class="c-dropdown-list-item c-dropdown-list-item--up c-dropdown-list-item--bold"
              @mousedown="resetScale"
            >
              {{ $t('editor.scalePanel.reset') }}
            </div>
            <div
              v-for="(submenuScale,index) in submenuScales"
              :key="'submenuScale' + index"
              class="c-dropdown-list-item c-dropdown-list-item--up"
              @mousedown="setScale(submenuScale)"
            >
              {{ textFromScale( submenuScale ) }}
            </div>
          </div>
        </transition>

        {{ textFromScale(viewboxScale) }}
      </div>

      <!-- Plus Button -->

      <div
        id="scale-panel--scale-in"
        class="c-scale-panel__mainpanel-item c-scale-panel__mainpanel-item-plus"
        @mousedown="scaleIn()"
        @mouseleave="removeScaleEvent()"
        @mouseup="removeScaleEvent()"
      >
        <icon classes="fas fa-2x fa-plus" />
      </div>

      <div class="c-scale-panel__mainpanel-item c-scale-panel__mainpanel-item-grid">
        <div
          id="scale-panel-grid-opener"
          :class="[submenuGridVisible
                     ? 'c-scale-panel__mainpanel-item--active c-scale-panel__mainpanel-item-grid--active c-scale-panel__mainpanel-item-opener--up--active'
                     : ''
                   , 'c-scale-panel__mainpanel-item c-scale-panel__mainpanel-item-grid c-scale-panel__grid-submenu-button c-scale-panel__mainpanel-item-opener c-scale-panel__mainpanel-item-opener--up']"
          @click="toggleSubmenuGridVisibility"
        >
          <icon
            style-width="22px"
            style-height="22px"
          >
            <grid-icon />
          </icon>
          {{ scaleText }}
        </div>

        <!-- Grid Submenu Panel -->

        <transition name="c-dropdown">
          <div
            v-if="submenuGridVisible"
            class="c-scale-panel__grid-submenu-panel u-bdradiustr-small u-bdradiustl-small"
          >
            <!-- Measurement -->

            <label class="c-typo__label u-color-s1">
              {{ $t('editor.scalePanel.measurement') }}
            </label>

            <div class="c-scale-panel__grid-submenu-panel-inner">
              <button-base
                id="scale-panel-open-settings"
                :disabled="shouldBlockGui"
                :label="$t('dialogs.underlay.rescale')"
                :on-click-action="() => { return openMeasurementSettings() }"
                classes="u-ml-size-0"
                style-size="xs"
              />

              <!--<input-field :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
                                         id="scale-panel-to-unit" v-model="toUnit"/>-->
            </div>

            <hr class="u-mv-size-standard">

            <!-- Grid -->

            <label class="c-typo__label u-color-s1">
              {{ $t('editor.scalePanel.gridRaster') }}
            </label>

            <input
              v-model="rasterGrid"
              :max="maxRange"
              :min="minRange"
              :step="gridSteps"
              class="c-form__slide-input"
              type="range"
            >

            <input-checkbox
              id="checkbox-show-grid"
              v-model="showGrid"
              :classes="'u-mv-size-0'"
              :is-store-mode="false"
              :label="$t('editor.scalePanel.showGrid')"
            />

            <input-checkbox
              id="checkbox-snap-grid"
              v-model="snapGrid"
              :is-store-mode="false"
              :label="$t('editor.scalePanel.snap')"
            />
          </div>
        </transition>
      </div>
    </div>

    <!-- Ruler Bar  -->

    <svg
      :viewBox="x * viewboxScale + ',' + 0 + ',' + linesViewBoxWidth + ',' + 5"
      :width="linesViewBoxWidth"
      class="c-scale-panel__lines"
      height="100%"
    >
      <defs>
        <pattern
          id="scalePanelPattern"
          :width="scaledRaster * 2"
          height="5px"
          patternUnits="userSpaceOnUse"
        >
          <rect
            :width="scaledRaster"
            height="5px"
            x="0"
          />
          <rect
            :width="scaledRaster"
            :x="scaledRaster"
            height="5px"
          />
        </pattern>
      </defs>
      <rect
        :fill="'url(#scalePanelPattern)'"
        :width="linesViewBoxWidth"
        :x="x * viewboxScale"
        height="100%"
      />
    </svg>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

import MixinMath from '@/mixins/math'
import MixinValidate from '@/mixins/validate'

import InputCheckbox from '@/components/common/InputCheckbox'
// import InputField from '@/components/common/InputField'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'
import GridIcon from '@/components/common/icons/GridIcon'

export default {
  name: 'ScalePanel',
  components: {
    GridIcon,
    Icon,
    ButtonBase,
    /* InputField, */
    InputCheckbox
  },
  mixins: [
    MixinMath,
    MixinValidate
  ],
  data: () => {
    return {
      submenuScaleVisible: false,
      submenuGridVisible: false,
      chosenScale: false,
      linesViewBoxWidth: 0
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure,
      viewboxScale: state => state.editor.viewbox.scale,
      gridRaster: state => state.editor.grid.raster,
      gridShow: state => state.editor.grid.show,
      gridSnap: state => state.editor.grid.snap,

      gridSteps: state => state.common.grid.steps,
      minRange: state => state.common.grid.min,
      maxRange: state => state.common.grid.max,

      submenuScales: state => state.common.submenuScales
    }),
    ...mapGetters({
      x: 'editor/viewbox/x',
      y: 'editor/viewbox/y',
      w: 'editor/viewbox/w',
      h: 'editor/viewbox/h',
      shouldBlockGui: 'events/shouldBlockGui'
    }),
    showGrid: {
      get () {
        return this.gridShow
      },
      set (val) {
        this.toggleGridShow()
      }
    },
    snapGrid: {
      get () {
        return this.gridSnap
      },
      set (value) {
        this.toggleGridSnap()
      }
    },
    rasterGrid: {
      get () {
        return this.gridRaster
      },
      set (value) {
        this.setGridRaster(value)
      }
    },
    scaleText: function () {
      return this.$units.convert(this.rasterGrid) + ' x ' + this.$units.convert(this.rasterGrid) + ' ' + this.$settings.lengthUnit
      /* let outMeasure,
        rescale

      rescale = this.gridRaster / this.mx_math_replaceDecimalPointWithDot(this.measure.fromPixels)
      outMeasure = rescale * this.mx_math_replaceDecimalPointWithDot(this.measure.toUnit)

      return this.round(outMeasure) + ' x ' + this.round(outMeasure) + ' ' + this.measure.unit.label */
    },
    scaledRaster: function () {
      return (parseFloat(this.gridRaster) /
        this.mx_math_replaceDecimalPointWithDot(this.measure.toUnit)) *
        this.mx_math_replaceDecimalPointWithDot(this.measure.fromPixels) *
        this.viewboxScale
    },
    minGrid: function () {
      return (this.minRange / this.mx_math_replaceDecimalPointWithDot(this.measure.toUnit)) * this.mx_math_replaceDecimalPointWithDot(this.measure.fromPixels)
    },
    maxGrid: function () {
      return (this.maxRange / this.mx_math_replaceDecimalPointWithDot(this.measure.toUnit)) * this.mx_math_replaceDecimalPointWithDot(this.measure.fromPixels)
    }
  },
  mounted () {
    const mainpanel = this.$refs.scalePanelMainpanel
    this.linesViewBoxWidth = mainpanel.clientWidth
    document.addEventListener('mousedown', this.documentClick)
  },
  destroyed: function () {
    document.removeEventListener('mousedown', this.documentClick)
  },
  methods: {
    ...mapMutations({
      toggleGridShow: 'editor/grid/' + mutationTypes.TOGGLE_GRID_SHOW,
      toggleGridSnap: 'editor/grid/' + mutationTypes.TOGGLE_GRID_SNAP,
      setGridRaster: 'editor/grid/' + mutationTypes.SET_GRID_RASTER,
      setViewScale: 'editor/viewbox/' + mutationTypes.SET_VIEWBOX_ZOOM,
      resetViewScale: 'editor/viewbox/' + mutationTypes.SET_VIEWBOX_ZOOM_RESET,
      setMeasureProperty: 'project/measurement/' + mutationTypes.SET_MEASURE_PROPERTY
    }),
    ...mapActions({
      updateMeasurements: 'project/measurement/updateMeasurements'
    }),

    /* ------------------------------------------
            *  Fire events
            *------------------------------------------ */

    scaleIn () {
      this.$events.fire(editorEventTypes.EDITOR_SCALE_IN)
      clearInterval(this.scaleTimeout)
      this.scaleTimeout = setInterval(() => {
        this.scaleIn()
      }, 500)
    },
    scaleOut () {
      this.$events.fire(editorEventTypes.EDITOR_SCALE_OUT)
      clearInterval(this.scaleTimeout)
      this.scaleTimeout = setInterval(() => {
        this.scaleOut()
      }, 500)
    },
    removeScaleEvent () {
      if (this.scaleTimeout !== null) {
        clearInterval(this.scaleTimeout)
        this.scaleTimeout = null
      }
    },
    openMeasurementSettings () {
      this.toggleSubmenuGridVisibility()
      this.$events.fire('sub-nav-dialog-measurement-settings')
    },
    documentClick (evt) {
      const element = this.$refs.scalePanelMainpanel
      let target = null

      if (evt && evt.target) {
        target = evt.target
      }
      if (
        (element &&
                        element !== target &&
                        !element.contains(target))
      ) {
        this.submenuScaleVisible = false
        this.submenuGridVisible = false
      }
    },

    /* ------------------------------------------
            *  Switches, setters, etc.
            *------------------------------------------ */

    textFromScale (scale) {
      return this.round(scale * 100) + '%'
    },
    round (val) {
      return Math.round(val * 10) / 10
    },
    toggleSubmenuScaleVisibility () {
      if (this.submenuGridVisible) {
        this.toggleSubmenuGridVisibility()
      }
      this.submenuScaleVisible = !this.submenuScaleVisible
    },
    toggleSubmenuGridVisibility () {
      if (this.submenuScaleVisible) {
        this.toggleSubmenuScaleVisibility()
      }
      this.submenuGridVisible = !this.submenuGridVisible
    },
    setScale (scale) {
      this.setViewScale(scale)
    },
    resetScale () {
      this.resetViewScale()
    }
  }
}
</script>
