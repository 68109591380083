<template>
  <g
    :id="'anglesGroup-' + id"
    class="no-pointer-events"
  >
    <text
      v-for="(angle,index) in getAngles"
      :key="'angle-text-' + index"
      :font-size="mx_text_fontSizeAngle"
      :stroke-width="mx_text_strokeWidth"
      :x="angle.x+mx_text_angleSpacePointX"
      :y="angle.y+mx_text_angleSpacePointY"
      fill="black"
      paint-order="stroke"
      stroke="white"
      text-anchor="middle"
    >
      {{ $units.convert(angle.a) }}°
    </text>
  </g>
</template>

<script>
import config from '@/config'

import MixinMath from '@/mixins/math'
import MixinPoints from '@/mixins/points'
import MixinText from '@/mixins/text'

import { mapGetters } from 'vuex'

/**
     * Shows the object angles
     * @displayName Object angles
     */
export default {
  name: 'SvgAngles',
  components: {
    /* Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    } */
  },
  mixins: [MixinMath, MixinPoints, MixinText],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    line: {
      type: [Object, Array],
      required: false,
      default: null
    },
    active: {
      type: Boolean,
      default: true
    },
    drawing: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      showRects: false,
      paddingLeftRight: 10,
      paddingTopBottom: 8,
      radius: 5,
      opacity: 0.6,
      rects: []
    }
  },
  computed: {
    ...mapGetters({
      getObject: 'project/objects/getById'
    }),
    getAngles () {
      const obj = this.getObject(this.id)
      if (!this.active || (obj !== undefined && obj.type === config.objects.types.CIRCLE)) return []
      return this.mx_math_calculateAngles(this.mx_points_filteredPoints, this.mx_math_isClockwise(this.mx_points_filteredPoints))
    }
  },
  mounted () {
    if (this.$refs.angletext !== undefined) {
      this.showRects = true
    }
  }
}
</script>
