<template>
  <g>
    <filter
      id="eyeOutline"
      color-interpolation-filters="linearRGB"
      filterUnits="objectBoundingBox"
      height="120%"
      primitiveUnits="userSpaceOnUse"
      width="120%"
      x="-10%"
      y="-10%"
    >
      <feColorMatrix
        in="SourceAlpha"
        result="colormatrix"
        type="matrix"
        values="1 255 255 255 0
                                                    255 1 255 255 0
                                                    255 255 1 255 0
                                                    0 0 0 500 -20"
      />
      <feMorphology
        in="colormatrix"
        operator="dilate"
        radius="10 10"
        result="morphology"
      />
      <feGaussianBlur
        edgeMode="none"
        in="morphology"
        result="blur"
        stdDeviation="5 5"
      />
      <feBlend
        in="SourceGraphic"
        in2="blur"
        mode="multiply"
        result="blend"
      />
    </filter>
    <pattern
      id="cutDefaultPattern"
      class="c-object__default-pattern"
      patternUnits="userSpaceOnUse"
      :height="mx_math_handleElementsOnScale(commonPatternRect.height)"
      :patternTransform="`rotate(${commonPatternRect.rotation})`"
      :width="mx_math_handleElementsOnScale(commonPatternRect.width)"
    >
      <rect
        :height="mx_math_handleElementsOnScale(commonPatternRect.rect1.height) + 'px'"
        :width="mx_math_handleElementsOnScale(commonPatternRect.rect1.width) + 'px'"
        :x="commonPatternRect.rect1.x"
        :y="commonPatternRect.rect1.y"
      />
      <rect
        :height="mx_math_handleElementsOnScale(commonPatternRect.rect2.height) + 'px'"
        :width="mx_math_handleElementsOnScale(commonPatternRect.rect2.width) + 'px'"
        :x="mx_math_handleElementsOnScale(commonPatternRect.rect2.x)"
        :y="commonPatternRect.rect2.y"
      />
    </pattern>
    <pattern
      id="objectPatternLawn"
      width="1"
      height="1"
      patternContentUnits="objectBoundingBox"
    >
      <image
        :xlink:href="require(`@/assets/img/lawn/lawn_tex_400x400.svg`)"
        height="1"
        width="1"
        x="0"
        y="0"
      />
    </pattern>
    <linearGradient
      id="objectLinearGradientLawn"
      x1="50%"
      y1="0%"
      x2="50%"
      y2="100%"
    >
      <stop
        offset="0%"
        stop-color="#AED45B"
      />
      <stop
        offset="100%"
        stop-color="#79943F"
      />
    </linearGradient>
  </g>
</template>

<script>
import { mapState } from 'vuex'
import MixinMath from '@/mixins/math'

export default {
  name: 'CommonSvgModifier',
  mixins: [
    MixinMath
  ],
  computed: {
    ...mapState({
      commonPatternRect: state => state.common.svg.pattern.cut
    })
  }
}
</script>
