<template>
  <div class="c-dialog__instruction--full-width">
    <div class="c-dialog__instruction c-dialog__instruction--no-center">
      <div class=" c-dialog__instruction--full-width">
        <file-upload
          v-if="(state === states.OLD || state === states.UPLOAD || state === states.DELETE) && !fileRefresh"
          id="surface-underlay-upload"
          :accept="'image/png, image/jpeg, application/pdf'"
          :current="underlayImage"
          :fileMaxSize="config.files.single.underlay"
          style-drop-area-height="250px"
          :label-default-message="$t('dialogs.underlay.info')"
          @change="fileChanged"
        />
        <convert-pdf-to-image
          v-if="state === states.CONVERT"
          :file="file"
          @selectPage="setSelectedPage"
        />
        <div
          v-if="state === states.PREVIEW"
          class="c-pdf-to-img-preview"
        >
          <img
            v-if="previewImage"
            :src="previewImage"
          >
          <div v-else>
            <i
              class="fas fa-spinner fa-pulse"
            />
          </div>
        </div>
        <input-slider
          v-if="state === states.CONVERT"
          id="pdf-page-angle"
          v-model="angle"
          classes="u-mt-size-standard"
          style-width="300px"
          :label="$t('dialogs.underlay.rotate')"
          :max="270"
          :min="0"
          :step="90"
          suffix="°"
        />
      </div>
    </div>
    <div class="c-dialog__confirm">
      <div :class="footerClasses">
        <!-- Switch visibility -->
        <div
          v-if="state === states.OLD && underlayImage"
          class="u-pl-size-20"
        >
          <input-checkbox
            id="surface-underlay-show-switch"
            v-model="togglePlan"
            classes="u-mt-size-0"
            :is-store-mode="false"
            :label="$t('dialogs.underlay.showPlan')"
          />
        </div>
        <!-- Delete -->
        <button-base
          v-if="state === states.OLD && underlayImage"
          id="surface-underlay-delete"
          :label="$t('dialogs.underlay.deletePlan')"
          :on-click-action="() => { return deletePrepare() }"
          style-size="xs"
        />
        <div
          v-if="state === states.DELETE"
          class="u-pl-size-20"
        >
          {{ $t('dialogs.underlay.deleteText') }}
        </div>
        <button-base
          v-if="state === states.DELETE"
          id="surface-underlay-delete"
          :label="$t('common.no')"
          :on-click-action="() => { return deleteAbort() }"
          style-size="xs"
        />
        <button-base
          v-if="state === states.DELETE"
          id="surface-underlay-delete"
          :label="$t('common.yes')"
          :on-click-action="() => { return deleteConfirm() }"
          style-color="gray"
          style-size="xs"
        />
        <!-- Rescale -->
        <button-base
          v-if="state === states.OLD && underlayImage"
          id="surface-underlay-rescale"
          :label="$t('dialogs.underlay.rescale')"
          :on-click-action="() => { return openMeasurementSettings() }"
          classes="c-button-base--margin-auto-left"
          style-size="xs"
        />
        <!-- Upload -->
        <button-base
          v-if="state === states.UPLOAD"
          id="surface-underlay-upload"
          :label="handleSaveButtonText"
          :disabled="(file === null) || error"
          :on-click-action="() => { return handleNextStep() }"
          style-size="xs"
        />
        <!-- Convert (if from PDF) -->
        <button-base
          v-if="state === states.CONVERT"
          id="surface-underlay-useAsFile"
          :label="$t('dialogs.underlay.useConverted')"
          :disabled="(selectedPage === null) || error"
          :on-click-action="() => { return useAsFile() }"
          style-size="xs"
        />
        <!-- Preview of chosen page (if from PDF) -->
        <button-base
          v-if="state === states.PREVIEW"
          id="surface-underlay-save"
          :label="$t('dialogs.underlay.saveUnderlay')"
          :on-click-action="() => { return storeFile() }"
          style-size="xs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import config from '@/config'
import * as editorEventTypes from '@/events/editor-event-types'

import ConvertPdfToImage from '@/components/modules/pdf/convertToImage/ConvertPdfToImage'

import FileUpload from '@/components/common/FileUpload'
import ButtonBase from '@/components/common/ButtonBase'
import InputSlider from '@/components/common/InputSlider'
import InputCheckbox from '@/components/common/InputCheckbox'

const states = {
  OLD: 'old',
  DELETE: 'delete',
  UPLOAD: 'upload',
  CONVERT: 'convert',
  PREVIEW: 'preview'
}

export default {
  name: 'UnderlayUpload',
  components: {
    InputCheckbox,
    FileUpload,
    ConvertPdfToImage,
    ButtonBase,
    InputSlider
  },
  data: () => {
    return {
      states,
      config,
      state: states.OLD,
      pageAngle: 0,
      scaleOutput: 1,
      fileType: null,
      file: null,
      error: false,
      selectedPage: null,
      previewImage: null,
      fileRefresh: false
    }
  },
  computed: {
    ...mapGetters({
      underlayImage: 'files/getPseudoServerUnderlay'
    }),
    ...mapState({
      underlayVisible: state => state.files.show.underlay
    }),
    angle: {
      get () {
        return this.pageAngle
      },
      set (payload) {
        this.error = payload.error
        if (!payload.error) {
          this.pageAngle = payload.value
        }
      }
    },
    footerClasses () {
      return [
        'c-dialog__confirm-child',
        'u-ai-center',
        this.state === states.UPLOAD || this.state === states.CONVERT || this.state === states.PREVIEW ? 'c-dialog__confirm-child--flex-end' : ''
      ]
    },
    handleSaveButtonText () {
      const type = (this.file) ? this.file.type : ''

      switch (type) {
        case 'application/pdf':
          return this.$t('dialogs.underlay.convertUnderlay')
        default:
          return this.$t('dialogs.underlay.saveUnderlay')
      }
    },
    togglePlan: {
      get () {
        return this.underlayVisible
      },
      set () {
        this.$events.fire(editorEventTypes.UNDERLAY_TOGGLE)
      }
    }
  },
  watch: {
    underlayImage: {
      handler (val) {
        this.fileRefresh = true
        this.$nextTick(() => {
          this.fileRefresh = false
        })
      },
      deep: true
    }
  },
  methods: {
    deleteAbort () {
      this.state = states.OLD
    },
    deleteConfirm () {
      this.$events.fire(editorEventTypes.UNDERLAY_DELETE)
      this.state = states.OLD
    },
    deletePrepare () {
      this.state = states.DELETE
    },
    fileChanged (file) {
      if (file && file.value && file.value.newFiles && file.value.newFiles.length > 0) {
        this.file = file.value.newFiles[0]
        this.fileType = this.file.type
        this.error = file.error
        this.state = states.UPLOAD
      } else {
        this.file = null
        this.fileType = null
        this.error = false
      }
    },
    handleNextStep () {
      switch (this.fileType) {
        case 'application/pdf':
          this.state = states.CONVERT
          break
        default:
          this.$emit('store', this.file)
          this.state = states.OLD
      }
    },
    openMeasurementSettings () {
      this.$emit('open-measurement-settings')
    },
    setSelectedPage (page) {
      this.selectedPage = page
    },
    storeFile () {
      this.$emit('store', this.previewImage)
      this.selectedPage = null
      this.pageAngle = 0
      this.state = states.OLD
    },
    updateSlider (angle) {
      this.angle = angle
    },
    useAsFile () {
      const viewport = this.selectedPage.getViewport({ scale: this.scaleOutput, rotation: Number(this.pageAngle) })
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      canvas.height = viewport.height
      canvas.width = viewport.width

      const task = this.selectedPage.render({ canvasContext: context, viewport: viewport })
      task.promise.then(() => {
        this.previewImage = canvas.toDataURL('image/png', 90)
        canvas.remove()
        this.selectedPage.cleanup()
      })
      this.state = states.PREVIEW
    }
  }
}
</script>
