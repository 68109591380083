<template>
  <div>
    <vue-good-table
      :columns="columns"
      :rows="getClients"
      :pagination-options="{
        enabled: true,
        perPage: 20,
        perPageDropdown: [10, 20, 50],
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: texts.pageLabel, // for 'pages' mode
        allLabel: texts.allLabel,
      }"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'name', type: 'asc'}]
      }"
      theme="melos"
    >
      <template
        #table-row="props"
      >
        <span
          v-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <button-base
            :id="`api-client-edit-${props.row.originalIndex}`"
            :on-click-action="() => { return editApiClient(props.row)}"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fas fa-user-edit"
          >
            <template #popup>
              {{ $t('common.edit') }}
            </template>
          </button-base>
          <button-base
            :id="`api-client-refresh-key-${props.row.originalIndex}`"
            :on-click-action="() => { return createNewApiKey(props.row.id)}"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fas fa-sync"
          >
            <template #popup>
              {{ $t('backend.apiClients.refreshKey') }}
            </template>
          </button-base>
          <button-base
            :id="`api-client-delete-${props.row.originalIndex}`"
            :on-click-action="() => { return deleteApiClient(props.row)}"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>
        <span v-else-if="props.column.field === 'last_used_at'">
          {{ (props.formattedRow[props.column.field]) ? props.formattedRow[props.column.field] : '' }}
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'

import ButtonBase from '@/components/common/ButtonBase'

import MixinDialogHandler from '@/mixins/dialogHandler'

export default {
  name: 'ApiClientList',
  components: {
    VueGoodTable,
    ButtonBase
  },
  mixins: [
    MixinDialogHandler
  ],
  computed: {
    ...mapGetters({
      getClients: 'backendAPI/apiClient/get'
    }),
    columns () {
      return [
        {
          field: 'settings',
          sortable: false,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          label: this.$t('backend.apiClients.list.username'),
          field: 'name'
        },
        {
          label: this.$t('backend.apiClients.list.last_used_at'),
          field: 'token.last_used_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
          dateOutputFormat: 'dd/MM/yy HH:mm:ss',
          thClass: 'u-text-left',
          tdClass: 'u-text-left vgt-min-width'
        },
        {
          label: this.$t('backend.apiClients.list.created_at'),
          field: 'token.created_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
          dateOutputFormat: 'dd/MM/yy HH:mm:ss',
          thClass: 'u-text-left',
          tdClass: 'u-text-left vgt-min-width'
        },
        {
          label: this.$t('backend.apiClients.list.updated_at'),
          field: 'token.updated_at',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
          dateOutputFormat: 'dd/MM/yy HH:mm:ss',
          thClass: 'u-text-left',
          tdClass: 'u-text-left vgt-min-width'
        }
      ]
    },
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    }
  },
  mounted () {
    this.listClients()
  },
  methods: {
    ...mapActions({
      listClients: 'backendAPI/apiClient/list',
      refreshApiKey: 'backendAPI/apiClient/refreshApiKey'
    }),
    createNewApiKey (clientId) {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'createNewApiKey',
        onOff: true,
        dialogProps: clientId
      })
    },
    deleteApiClient (client) {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'deleteApiClient',
        onOff: true,
        dialogProps: client
      })
    },
    editApiClient (client) {
      this.$_mx_dialogHandler_openDialog({
        dialog: 'editApiClient',
        onOff: true,
        dialogProps: client
      })
    }
  }
}
</script>
