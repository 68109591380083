<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.deleteAdmin.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.deleteAdmin.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p>{{ $t('dialogs.deleteAdmin.info') }}</p>
        <span
          class="u-color-b1 u-mt-size-standard"
        >
          {{ dialogProps.adminData.email }}
        </span>
        <p class="u-mt-size-standard">
          {{ $t('common.noUndo') }}
        </p>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="delete-admin-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
          />
          <button-base
            id="delete-admin-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return doDeleteAdmin() }"
            style-color="gray"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Dialog Delete Admin
     */
export default {
  name: 'DeleteAdmin',
  components: {
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'deleteAdmin',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      dialogProps: state => state.dialogs.dialogProps.deleteAdmin
    })
  },
  methods: {
    ...mapActions({
      deleteAdmin: 'backendAPI/user/delete'
    }),
    ...mapMutations({}),
    close () {
      this.mx_dialogs_closeDialog()
    },
    async doDeleteAdmin () {
      await this.deleteAdmin(this.dialogProps.adminData.id)
      this.close()
    }
  }
}
</script>
