<template>
  <dialog-frame
    :targetDialog="targetDialog"
    :title=" $t('dialogs.bugReport.title') "
    :subtitle=" $t('dialogs.bugReport.subtitle') "
    :style-dialog-z-index="1500"
  >
    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <form class="c-form">
          <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
            <div class="c-dialog__instruction-col">
              <div class="u-flex u-fd-row">
                <file-upload
                  id="create-pad-logo"
                  :accept="'image/png, image/jpeg, application/pdf, text/csv, text/plain'"
                  :label-default-message="$t('dialogs.createPad.imageFrame.dropPreview')"
                  :file-max-size="config.files.single.report"
                  :file-total-max-size="config.files.total.report"
                  :multiple="true"
                  style-drop-area-height="180px"
                  style-drop-area-width="180px"
                  @change="(e) => {
                    mx_validate_updateErrorsArray(e)
                    report.files = e.value
                  }"
                  @register="mx_validate_registerInputsInErrorsArray"
                />
              </div>
            </div>
            <div class="c-dialog__instruction-col u-text-right">
              <input-field
                id="create-template-name"
                v-model="report.subject"
                :is-store-mode="false"
                :label="$t('dialogs.bugReport.form.subject')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="350px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <textarea-field
                id="create-template-message"
                v-model="report.message"
                :is-store-mode="false"
                :label="$t('dialogs.bugReport.form.message')"
                :required="true"
                :rows="8"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-textarea-width="350px"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
            </div>
          </div>
        </form>
      </div>

      <div class="c-dialog__confirm">
        <div
          class="c-dialog__confirm-child u-ai-end"
          :class="{'c-dialog__confirm-child--flex-end': !error}"
        >
          <span
            v-if="error"
            class="u-color-o u-flex u-fd-col u-p-size-standard"
          >
            {{ $t('errors.report') }}
            <span
              v-if="error.data && error.data.errors"
              class="u-flex u-fd-col"
            >
              <span
                v-for="(e, k) in error.data.errors"
                :key="k"
              >
                <span v-if="Array.isArray(e)">
                  <template
                    v-for="(item, iKey) in e"
                  >
                    <span
                      :key="iKey"
                      class="u-fsize-m"
                    >{{ formatFileName(item) }}</span>
                  </template>
                </span>
              </span>
            </span>
          </span>
          <button-base
            id="create-template-save"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('dialogs.bugReport.form.send')"
            :on-click-action="() => { return send() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { apiMethods, mediaRequestNames, routes } from '@/helper/apiHelper'
import config from '@/config'
import _ from 'lodash'

import MixinValidate from '@/mixins/validate'
import MixinDialog from '@/mixins/dialogs'

import DialogFrame from '@/components/common/DialogsFrame'
import ButtonBase from '@/components/common/ButtonBase'
import InputField from '@/components/common/InputField'
import FileUpload from '@/components/common/FileUpload'
import TextareaField from '@/components/common/TextareaField'

export default {
  name: 'BugReportDialog',
  components: {
    TextareaField,
    FileUpload,
    DialogFrame,
    ButtonBase,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate
  ],
  data: () => {
    return {
      config: config,
      targetDialog: 'bugReport',
      report: {
        subject: '',
        message: '',
        files: []
      },
      error: null
    }
  },
  computed: {
    ...mapState({
      token: state => state.backendAPI.login.token
    }),
    ...mapGetters({
      me: 'backendAPI/user/get'
    })
  },
  methods: {
    async send () {
      const formData = new FormData()
      let files = []

      if (Object.hasOwnProperty.call(this.report.files, 'newFiles')) {
        files = _.isArray(this.report.files.newFiles) ? this.report.files.newFiles : [this.report.files.newFiles]
      }
      if (files.length) {
        for (const file of files) {
          formData.append(`${mediaRequestNames.uploads}[]`, file)
        }
      }
      formData.append('subject', this.report.subject)
      formData.append('message', this.report.message)

      this.error = null
      return await apiMethods.request('post', routes.report, formData, this.token, 'multipart/form-data')
        .then(response => {
          this.$toasted.show(this.$t('toast.report'), { duration: 7000, type: 'success' })
          this.mx_dialogs_closeDialog()
          return response.data.data
        }).catch(error => {
          this.error = error.response
          return null
        })
    },
    formatFileName (text) {
      if (text.indexOf('uploads') !== -1) {
        const t1 = text.replace('uploads', 'data ')
        const start = t1.indexOf('.')
        const end = t1.indexOf(' ', start)
        const numPart = t1.slice(start, end)
        const num = Number.parseInt(t1.slice(start + 1, end)) + 1

        return t1.replace(numPart, 'nr ' + num)
      }
      return text
    }
  }
}
</script>
