<template>
  <sub-nav-dialogs-frame
    :cannotClose="cannotClose"
    :hide-close="hideClose"
    :hideTitle="hideTitle"
    :subtitle=" $t('subNav.dialogs.measurementSettings.subtitle') "
    :targetSubNavDialog="targetSubNavDialog"
    :title=" $t('subNav.dialogs.measurementSettings.title') "
    @abort-button-pressed="abort"
  >
    <template>
      <div class="c-nav-sub-dialog__content">
        <!-- SCREEN 1 -->
        <div
          v-if="showedSubscreen === 1 || showedSubscreen === 4 || showedSubscreen === 7"
          class="c-nav-sub-dialog__screen"
        >
          <!-- Content -->
          <div
            v-if="!hideClose"
            class="c-nav-sub-dialog__instruction"
          >
            <p>
              {{ $t('subNav.dialogs.measurementSettings.screen_1.text_1') + $settings.lengthUnit
                + $t('subNav.dialogs.measurementSettings.screen_1.text_2') }}
            </p>
          </div>
          <div
            v-if="hideClose"
            class="c-nav-sub-dialog__instruction c-nav-sub-dialog__instruction--long"
          >
            <p v-if="!end && showedSubscreen === 4">
              {{
                $t('subNav.dialogs.measurementSettings.screen_3.text_1')
              }}
            </p>
            <p v-if="!end && showedSubscreen === 7">
              {{
                $t('subNav.dialogs.measurementSettings.screen_4.text_1')
              }}
            </p>
          </div>

          <!-- Buttons -->
          <div class="c-nav-sub-dialog__confirm">
            <div class="c-nav-sub-dialog__confirm-child">
              <button-base
                id="subnav-measurement-settings-next"
                :label="$t('common.next')"
                :on-click-action="() => { return goToNextSubscreen() }"
              />
              <button-base
                id="subnav-measurement-settings-save-exit"
                :classes="{'u-hidden': !hideClose}"
                :label="$t('common.save_and_exit')"
                :on-click-action="() => { return saveAndExit() }"
              />
            </div>
          </div>
        </div>

        <!-- SCREEN 2 4 and 6 -->
        <div
          v-if="showedSubscreen === 2 || showedSubscreen === 5 || showedSubscreen === 8"
          class="c-nav-sub-dialog__screen"
        >
          <!-- Content -->
          <div class="c-nav-sub-dialog__step">
            <div v-if="!end">
              1
            </div>
            <div v-if="end">
              2
            </div>
          </div>
          <div class="c-nav-sub-dialog__instruction c-nav-sub-dialog__instruction--long">
            <p v-if="!end">
              {{ $t('subNav.dialogs.measurementSettings.screen_2.text_1')
              }}
            </p>
            <p v-if="end">
              {{ $t('subNav.dialogs.measurementSettings.screen_2.text_2') }}
            </p>
            <div
              :class="[
                'c-nav-sub-dialog__draw-line',
                start ? 'c-nav-sub-dialog__draw-line--first-point' : '',
                end ? 'c-nav-sub-dialog__draw-line--second-point' : '']"
            />
          </div>

          <!-- Buttons -->
          <div class="c-nav-sub-dialog__confirm">
            <div class="c-nav-sub-dialog__confirm-child">
              <button-base
                id="subnav-measurement-settings-next"
                :disabled="!end"
                :label="$t('common.next')"
                :on-click-action="() => { return goToNextSubscreen() }"
              />
            </div>
          </div>
        </div>

        <!-- SCREEN 3 5 and 7 -->
        <div
          v-if="showedSubscreen === 3 || showedSubscreen === 6 || showedSubscreen === 9"
          class="c-nav-sub-dialog__screen"
        >
          <!-- Content -->
          <div class="c-nav-sub-dialog__step">
            <div>3</div>
          </div>
          <div class="c-nav-sub-dialog__instruction c-nav-sub-dialog__instruction--long c-form">
            <p>{{ $t('subNav.dialogs.measurementSettings.screen_5.text_1') }}</p>

            <input-field
              id="subnav-measure-panel-to-unit"
              v-model="toUnit"
              style-input-width="120px"
              :suffix="$settings.lengthUnit"
              :validate="(v) => {return mx_validate_validatePositiveNumber(v)}"
              class="u-mt-size-0"
            />
          </div>

          <!-- Buttons -->
          <div class="c-nav-sub-dialog__confirm">
            <div class="c-nav-sub-dialog__confirm-child">
              <button-base
                id="subnav-measurement-settings-next-save"
                :disabled="error"
                :on-click-action="() => { return goToNextSubscreen() }"
              >
                <template #content>
                  <span v-if="showedSubscreen < 7">{{ $t('common.next') }}</span>
                  <span v-else>{{ $t('common.save') }}</span>
                </template>
              </button-base>
            </div>
          </div>
        </div>
      </div>
    </template>
  </sub-nav-dialogs-frame>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'
import * as editorEventTypes from '@/events/editor-event-types'

import SubNavDialogsFrame from '@/components/common/SubNavDialogsFrame'
import InputField from '@/components/common/InputField'

import MixinSubNav from '@/mixins/subnav'
import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinDialog from '@/mixins/dialogs'
import MixinMath from '@/mixins/math'
import MixinValidate from '@/mixins/validate'
import ButtonBase from '@/components/common/ButtonBase'

export default {
  name: 'SubNavMeasurementSettings',
  components: {
    ButtonBase,
    SubNavDialogsFrame,
    InputField
  },
  mixins: [
    MixinSubNav,
    MixinDialogHandler,
    MixinDialog,
    MixinMath,
    MixinValidate
  ],
  data: () => {
    return {
      targetSubNavDialog: 'measurementSettings',
      hideTitle: false,
      hideClose: false,
      submenuUnitVisible: false,
      tempToUnit: 3,
      error: false
    }
  },
  computed: {
    ...mapState({
      start: state => state.project.measurement.reference.start,
      end: state => state.project.measurement.reference.end,
      measure: state => state.project.measurement.measure,
      stored: state => state.project.measurement.stored,
      subscreen: state => state.navigation.dialogs.subscreen.measurementSettings
    }),
    availableUnits () {
      return [this.$settings.lengthUnit]
    },
    toUnit: {
      get () {
        return this.tempToUnit
      },
      set (payload) {
        this.tempToUnit = payload.value
        this.error = payload.error
      }
    },
    showedSubscreen: {
      get () {
        return this.subscreen
      },
      set (val) {
        this.mx_subnav_setSubscreen(val)
      }
    },
    cannotClose () {
      return (this.start !== null && this.end === null)
    }
  },
  mounted () {
    this.setCoverState(true)
  },
  methods: {
    ...mapActions({
      updateFromPixels: 'project/measurement/updateFromPixels',
      updateMeasurements: 'project/measurement/updateMeasurements'
    }),
    ...mapMutations({
      setCoverState: 'editor/' + mutationTypes.SET_EDITOR_COVER,
      storeReferenceLine: 'project/measurement/' + mutationTypes.STORE_REF_POINTS,
      storeToUnit: 'project/measurement/' + mutationTypes.STORE_TO_UNIT
    }),
    goToNextSubscreen () {
      switch (this.showedSubscreen) { // currently showed subscreen
        case 1:
          this.hideTitle = true
          this.setCoverState(false)
          this.createNewReferenceLine()
          break
        case 2:
        case 5:
        case 8:
          this.storeReferenceLine()
          this.setCoverState(true)
          break
        case 3:
        case 6:
          this.storeToUnit(this.tempToUnit)
          this.createNewReferenceLine()
          this.hideClose = true
          break
        case 4:
        case 7:
          this.setCoverState(false)
          this.hideClose = false
          break
        case 9:
          this.storeToUnit(this.tempToUnit)
          this.updateFromPixels()
          this.close()
          break
      }
      this.showedSubscreen++
    },
    abort () {
      this.storeReferenceLine()
      this.mx_dialogs_resetMeasurements()
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'move')
    },
    saveAndExit () {
      this.$_mx_dialogHandler_openDialog({ dialog: 'abortMeasurements', onOff: true })
      this.close()
    },

    /* ------------------------------------------
            *  Helpers
            *------------------------------------------ */

    createNewReferenceLine () {
      this.$events.fire(editorEventTypes.REFERENCE_LINE_CREATE)
      this.$events.fire(editorEventTypes.EDITOR_CHANGE_CTRL_MODE, 'refline')
    },
    close () {
      this.mx_subnav_closeDialog()
    },
    toggleSubmenuUnitVisibility () {
      this.submenuUnitVisible = !this.submenuUnitVisible
    }
  }
}
</script>
