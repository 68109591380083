<template>
  <backend>
    <template #dialogs>
      <create-manufacture />
      <delete-manufacture />
      <create-admin />
      <delete-admin />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-60">
        <div class="o-grid__col u-10/12">
          <user-cat-menu :current-cat="cat" />
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-30">
        <div class="o-grid__col u-10/12">
          <div
            v-if="cat === 'manufacturers'"
            id="user-management-manufactures"
          >
            <manufactures />
          </div>

          <div
            v-if="cat === 'admins'"
            id="user-management-admins"
          >
            <admins />
          </div>
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'
import UserCatMenu from '@/components/backend/userManagement/partials/UserCatMenu'

import Manufactures from '@/components/backend/userManagement/partials/manufacturers/Manufactures'
import CreateManufacture from '@/components/backend/userManagement/partials/manufacturers/dialogs/CreateManufacture'
import DeleteManufacture from '@/components/backend/userManagement/partials/manufacturers/dialogs/DeleteManufacture'

import Admins from '@/components/backend/userManagement/partials/admins/Admins'
import CreateAdmin from '@/components/backend/userManagement/partials/admins/dialogs/CreateAdmin'
import DeleteAdmin from '@/components/backend/userManagement/partials/admins/dialogs/DeleteAdmin'

/**
 * @displayName Materials Component
 */
export default {
  name: 'UserManagement',
  components: {
    Backend,
    UserCatMenu,
    Manufactures,
    DeleteManufacture,
    CreateManufacture,
    Admins,
    CreateAdmin,
    DeleteAdmin
  },
  mixins: [MixinDialogHandler],
  props: {
    cat: {
      type: String,
      required: true
    }
  }
}
</script>
