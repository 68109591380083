<template>
  <context-menu
    v-if="menu.ctrl === 'menu-mouse-handle'"
    :id="'handle'"
    :title=" $t('editor.contextMenu.handle.title') "
  >
    <button @click="resetHandles()">
      <icon classes="fas fa-redo-alt" />
      <span>{{ $t('editor.contextMenu.handle.reset') }}</span>
    </button>
    <button @click="deleteHandles()">
      <icon classes="fas fa-2x fa-trash-alt" />
      <span>{{ $t('editor.contextMenu.handle.delete') }}</span>
    </button>
  </context-menu>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import ContextMenu from '@/components/common/ContextMenu'
import MixinTrackState from '@/mixins/trackstate'
import Icon from '@/components/common/Icon'

export default {
  name: 'MenuHandle',
  components: {
    Icon,
    ContextMenu
  },
  mixins: [
    MixinTrackState
  ],
  data () {
    return {}
  },
  computed: {
    ...mapState({
      menu: state => state.events.contextMenu
    }),
    ...mapGetters({
      getObject: 'project/objects/getById',
      getCutObject: 'project/objects/getCutObject'
    }),
    workingObject () {
      return (!this.menu.isCutOut) ? this.getObject(this.menu.object) : this.getCutObject
    }
  },
  methods: {
    ...mapActions({
      resetPointHandle: 'project/resetPointHandle',
      transformPoint: 'project/transformObjectPoint'
    }),
    ...mapMutations({
      hideMouseMenu: 'events/' + mutationTypes.EVT_CLOSE_CONTEXT_MENU
    }),
    resetHandles () {
      this.hideMouseMenu()
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.resetPointHandle({
            circle: this.menu.circle,
            object: this.menu.object,
            isCutOut: this.menu.isCutOut
          })
        },
        runSave: !this.workingObject.isCutOut
      })
    },
    deleteHandles () {
      this.hideMouseMenu()
      this.mx_trackstate_trackStateWithCondition({
        commands: () => {
          this.transformPoint({
            circle: this.menu.circle,
            object: this.menu.object,
            isCutOut: this.menu.isCutOut,
            type: 'L'
          })
        },
        runSave: !this.workingObject.isCutOut
      })
    }
  }
}
</script>
