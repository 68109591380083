import Vue from 'vue'
import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Seamtape Store
 *
 * @returns {{seamtapes: [], specialToUpdate: null, specialSeamtapes: []}}
 */
const defaultState = () => {
  return {
    specialSeamtapes: [],
    seamtapes: [],
    specialToUpdate: null,
    meta: null
  }
}

const state = defaultState()

const getters = {
  getMeta (state) {
    return state.meta
  },
  /**
     * Has seamtapes
     *
     * @param state
     * @returns {boolean}
     */
  hasSeamtapes (state) {
    return !_.isEmpty(state.seamtapes)
  },
  /**
     * Get seamtapes
     *
     * @param state
     * @returns {[]}
     */
  getSeamtapes (state) {
    return state.seamtapes
  },
  /**
     * Has special seamtapes
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialSeamtapes (state) {
    return !_.isEmpty(state.specialSeamtapes)
  },
  /**
     * Get special seamtapes
     *
     * @param state
     * @returns {[]}
     */
  getSpecialSeamtapes (state) {
    return state.specialSeamtapes
  },
  /**
     * Has product to update
     *
     * @param state
     * @returns {boolean}
     */
  hasSpecialToUpdate (state) {
    return state.specialToUpdate !== null
  },
  /**
     * Get special seamtape for update
     *
     * @param state
     * @returns {{}|null}
     */
  getSpecialForUpdate: (state) => {
    return (state.specialToUpdate !== null) ? _.cloneDeep(state.specialToUpdate) : null
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_SEAMTAPE_STATE)
  },
  /**
     * Reset PIM state
     *
     * @param dispatch
     * @param commit
     */
  resetPIMState ({ dispatch, commit }) {
    commit(types.RESET_SEAMTAPE_PIM_STATE)
  },
  /**
     * Set special product to update
     *
     * @param commit
     * @param specialSeamtape
     */
  setSpecialToUpdate ({ commit }, specialSeamtape) {
    commit(types.PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPE_TO_UPDATE, specialSeamtape)
  },
  /**
     * Get all seamtapes for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.location + `/${rootState.backendAPI.product.locationId}/seamtapes`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SEAMTAPE_SET_SEAMTAPES, response.data.data)
        commit(types.PRODUCT_SEAMTAPE_SET_META, response.data.meta)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update all seamtapes for location
     * - Location Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param state
     * @param seamtapes
     * @returns {Promise<void>}
     */
  async update ({ rootState, dispatch, commit, state }, seamtapes) {
    await apiMethods.request('patch', routes.location + `/${rootState.backendAPI.product.locationId}/seamtapes`, { update: seamtapes }, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SEAMTAPE_SET_SEAMTAPES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Update location seamtape
     *
     * @param dispatch
     * @param seamtape
     */
  updateLocation ({ dispatch }, seamtape) {
    seamtape.relate_to_latest = true
    dispatch('update', [seamtape])
  },
  /**
     * Get all special seamtapes for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @returns {Promise<void>}
     */
  async listSpecial ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.seamtape, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     * Create a special seamtape for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialSeamtape
     * @returns {Promise<boolean>}
     */
  async createSpecial ({ rootState, dispatch, commit }, specialSeamtape) {
    if (!Object.prototype.hasOwnProperty.call(specialSeamtape.data, 'group_id')) {
      specialSeamtape.data.group_id = rootState.backendAPI.user.me.group.id
    }

    const createdPedestal = await apiMethods.request('post', routes.seamtape, specialSeamtape.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SEAMTAPE_ADD_SPECIAL_SEAMTAPE, response.data.data)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return true
      })

    if (createdPedestal !== null) {
      const response = await specialSeamtape.fileCallback(routes.seamtape + `/${createdPedestal.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE, response.postMediaResponse)
        }
      }
    }
  },
  /**
     * Update a special seamtape for distributor, manufacture
     * - Distributor Based
     *
     * @param rootState
     * @param state
     * @param dispatch
     * @param commit
     * @param {{data: Object, fileCallback: CallableFunction}} specialSeamtape
     * @returns {Promise<void>}
     */
  async updateSpecial ({ rootState, state, dispatch, commit }, specialSeamtape) {
    const updatedSeamtape = await apiMethods.request('patch', routes.seamtape + `/${state.specialToUpdate.id}`, specialSeamtape.data, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE, response.data.data)
        commit(types.PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPE_TO_UPDATE, null)
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })

    if (updatedSeamtape !== null) {
      const response = await specialSeamtape.fileCallback(routes.seamtape + `/${updatedSeamtape.id}/asset`, rootState.backendAPI.login.token, dispatch)

      if (response !== null) {
        if (response.deleteMediaResponse !== null) {
          commit(types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE, response.deleteMediaResponse)
        }
        if (response.postMediaResponse !== null) {
          commit(types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE, response.postMediaResponse)
        }
      }
    }
  },
  /**
   * Delete a special seamtape for distributor, manufacture
   *
   * @param rootState
   * @param dispatch
   * @param commit
   * @param {[Number]} seamtapeIds
   * @returns {Promise<void>}
   */
  async deleteSpecial ({ rootState, dispatch, commit }, seamtapeIds) {
    await apiMethods.request('delete', routes.seamtape, { ids: seamtapeIds }, rootState.backendAPI.login.token)
      .then(response => {
        if (response.data.deleted) {
          commit(types.PRODUCT_SEAMTAPE_DELETE_SPECIAL_SEAMTAPE, seamtapeIds)
        }
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_SEAMTAPE_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.RESET_SEAMTAPE_PIM_STATE] (state) {
    state.seamtapes = []
  },
  [types.PRODUCT_SEAMTAPE_SET_SEAMTAPES] (state, seamtapes) {
    state.seamtapes = seamtapes
  },
  [types.PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPES] (state, specialSeamtapes) {
    state.specialSeamtapes = specialSeamtapes
  },
  [types.PRODUCT_SEAMTAPE_ADD_SPECIAL_SEAMTAPE] (state, specialSeamtape) {
    state.specialSeamtapes.push(specialSeamtape)
  },
  [types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE] (state, specialSeamtape) {
    const index = state.specialSeamtapes.findIndex(i => i.id === specialSeamtape.id)

    if (index !== -1) {
      Vue.set(state.specialSeamtapes, index, specialSeamtape)
    }
  },
  [types.PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPE_TO_UPDATE] (state, specialSeamtape) {
    state.specialToUpdate = specialSeamtape
  },
  [types.PRODUCT_SEAMTAPE_DELETE_SPECIAL_SEAMTAPE] (state, seamtapeIds) {
    state.specialSeamtapes = state.specialSeamtapes.filter(seamtape => {
      return seamtape.id !== seamtapeIds.filter(seamtapeId => seamtapeId === seamtape.id)[0]
    })
  },
  [types.PRODUCT_SEAMTAPE_SET_META] (state, meta) {
    state.meta = meta
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
