import * as types from './../mutation-types'
import { withHistoryMutation } from '../history-helper'
import { underlayStates } from '@/helper/apiHelper'

const state = {
  underlayState: underlayStates.old,
  currentSurfaceUnderlay: null,
  rawDataSurfaceUnderlay: null,
  show: {
    underlay: true
  }
}

const getters = {
  getUnderlayState (state) {
    return state.underlayState
  },
  getUnderlayDataUrl (state) {
    return state.currentSurfaceUnderlay !== null ? state.currentSurfaceUnderlay.src : null
  },
  getPseudoServerUnderlay (state) {
    if (state.rawDataSurfaceUnderlay) {
      const rawData = state.rawDataSurfaceUnderlay
      return {
        url: rawData.url,
        name: 'current underlay',
        size: rawData.size,
        type: 'image/' + rawData.type,
        id: 0
      }
    }
    return null
  }
}

const actions = {
  /**
   * Loads base64 encoded source string and returns image
   * @param commit
   * @param payload
   * @returns {Promise<HTMLImageElement>}
   */
  async loadBase64RemoteImage ({ commit }, payload) {
    const img = new Image()
    img.src = payload.base64
    return img
  },
  /**
   * Stores a new underlay state
   * @param dispatch
   * @param {underlayStates} newState
   */
  setUnderlayState ({ commit }, newState) {
    commit(types.FILES_SET_UNDERLAY_STATE, newState)
  },
  /**
   * Stores a new underlay
   * @param dispatch
   * @param commit
   * @param rootGetters
   * @param {{file: File, size: Number, type: String}} data
   */
  storeSurfaceUnderlay ({ dispatch, commit, rootGetters }, data) {
    const reader = new FileReader()

    reader.readAsDataURL(data.file)
    reader.onload = evt => {
      const image = new Image()
      const url = evt.target.result

      image.src = url
      image.onload = () => {
        commit(types.FILES_STORE_SURFACE_UNDERLAY, image)
        commit(types.FILES_STORE_RAW_DATA_SURFACE_UNDERLAY, {
          url: url,
          size: data.size,
          type: data.type
        })
        dispatch('setUnderlayState', underlayStates.new)

        const saveUnderlayPromise = dispatch('backendAPI/project/saveUnderlay',
          {
            projectId: rootGetters['project/backendMeta'].id,
            underlay: rootGetters['files/getUnderlayDataUrl']
          },
          { root: true }
        )
        saveUnderlayPromise.then(response => {
          if (response === false) {
            dispatch('deleteSurfaceUnderlay')
          }
        })
      }
    }
  },
  /**
   * Deletes an underlay
   * @param dispatch
   * @param commit
   * @param rootGetters
   */
  deleteSurfaceUnderlay ({ dispatch, commit, rootGetters }) {
    dispatch('setUnderlayState', underlayStates.delete)
    commit(types.FILES_DELETE_SURFACE_UNDERLAY)
    dispatch('backendAPI/project/removeUnderlay', rootGetters['project/backendMeta'].id, { root: true })
  }
}

const mutations = withHistoryMutation({
  [types.FILES_SET_UNDERLAY_STATE] (state, newState) {
    if (Object.prototype.hasOwnProperty.call(underlayStates, newState)) {
      state.underlayState = newState
    }
  },
  [types.FILES_STORE_SURFACE_UNDERLAY] (state, image) {
    state.currentSurfaceUnderlay = image
  },
  [types.FILES_STORE_RAW_DATA_SURFACE_UNDERLAY] (state, data) {
    state.rawDataSurfaceUnderlay = data
  },
  [types.FILES_TOGGLE_SURFACE_UNDERLAY] (state) {
    state.show.underlay = !state.show.underlay
  },
  [types.FILES_DELETE_SURFACE_UNDERLAY] (state) {
    state.currentSurfaceUnderlay = null
    state.rawDataSurfaceUnderlay = null
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
