import Paper from 'paper'

function paperPlugin (Vue) {
  if (paperPlugin.installed) return

  const paper = new Vue({
    data: {
      paper: null
    },
    created () {
      this.paper = new Paper.PaperScope().setup(1, 1)
    },
    methods: {
      get () {
        return this.paper
      },
      /**
             * @param {string} d
             * @returns {paper.Path}
             */
      path (d) {
        return new Paper.Path(d)
      },
      /**
             * @param {string} d
             * @returns {paper.CompoundPath}
             */
      compoundPath (d = null) {
        if (d) {
          return new Paper.CompoundPath(d)
        }
        return new Paper.CompoundPath()
      },
      group () {
        return new Paper.Group()
      },
      /**
             * @param {{}} center
             * @param radius
             * @returns {paper.Shape.Circle}
             */
      circle (center, radius) {
        return new Paper.Shape.Circle({
          center: [center.x, center.y],
          radius: radius
        })
      },
      /**
             * @param {{}} point
             * @returns {paper.Point}
             */
      point (point) {
        return new Paper.Point(point.x, point.y)
      },
      pointText (point) {
        return new Paper.PointText(new Paper.Point(point.x, point.y))
      },
      /**
             * @param {{}} bounds
             * @returns {paper.Rectangle}
             */
      rectangle (bounds) {
        return new Paper.Rectangle({
          point: bounds.point,
          size: bounds.size
        })
      },
      /**
             * @param {Paper.Point} from
             * @param {Paper.Point} to
             * @returns {paper.Path.Rectangle}
             */
      rectangleFromTo (from, to) {
        return new Paper.Path.Rectangle(from, to)
      },
      algorithm_circle (center, radius) {
        return new Paper.CompoundPath(new Paper.Path.Circle({ center: [center.x, center.y], radius: radius }))
      },
      /**
             * @param {{}} bounds
             * @returns {paper.Shape}
             */
      shapeRectangle (bounds) {
        return new Paper.Shape.Rectangle({
          point: bounds.point,
          size: bounds.size
        })
      },
      /**
             * @param instance
             * @returns {boolean}
             */
      instanceOfPath (instance) {
        return (instance instanceof Paper.Path)
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$paper', {
    get () {
      return paper
    }
  })

  Vue.mixin({})
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(paperPlugin)
}

export default paperPlugin
