import { mapActions, mapState } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'
import config from '@/config'

/**
 * @displayName Viewbox pan
 */
export default {
  name: 'ViewboxMixin',
  data: () => {
    return {
      $_mx_viewboxPan_surface_bounds: null,
      $_mx_viewboxPan_mouse_timer: null,
      $_mx_viewboxPan_latest_mouse_position: null,
      $_mx_viewboxPan_latest_mouse_event: null
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.VIEWBOX_UPDATED, (payload) => {
      this.surface_bounds = payload
    })
    this.$events.on(editorEventTypes.DRAW_START, () => {
      this.$_mx_viewboxPan_add_events()
    })
  },
  computed: {
    ...mapState({
      $_mx_viewboxPan_ctrl: state => state.events.ctrl,
      $_mx_viewboxPan_last_internal_action: state => state.editor.lastInternalAction,
      $_mx_viewboxPan_pan_amount: state => state.editor.viewbox.panAmount,
      $_mx_viewboxPan_viewbox_offset_y: state => state.editor.viewbox.offset.y
    })
  },
  methods: {
    ...mapActions({
      $_mx_viewboxPan_move_pan_x: 'editor/viewbox/move_pan_x',
      $_mx_viewboxPan_move_pan_y: 'editor/viewbox/move_pan_y'
    }),
    /**
     * Modifies the viewbox in drawing mode
     * @param e Event
     * @public
     */
    mx_viewboxPan_hit_test (e) {
      let point = null
      if (e instanceof MouseEvent) {
        point = { x: e.clientX, y: e.clientY }
        this.$_mx_viewboxPan_latest_mouse_event = e
        this.$_mx_viewboxPan_latest_mouse_position = point
        this.$_mx_viewboxPan_setInterval()
      } else {
        point = e
      }

      const hitEdge = this.$_mx_viewboxPan_hit(point)

      if (hitEdge.left && hitEdge.top) {
        this.$_mx_viewboxPan_move_pan_y(config.editor.panMovement)
        this.$_mx_viewboxPan_move_pan_x(config.editor.panMovement)
      } else if (hitEdge.left && hitEdge.bottom) {
        this.$_mx_viewboxPan_move_pan_y((-1) * config.editor.panMovement)
        this.$_mx_viewboxPan_move_pan_x(config.editor.panMovement)
      } else if (hitEdge.right && hitEdge.top) {
        this.$_mx_viewboxPan_move_pan_x((-1) * config.editor.panMovement)
        this.$_mx_viewboxPan_move_pan_y(config.editor.panMovement)
      } else if (hitEdge.right && hitEdge.bottom) {
        this.$_mx_viewboxPan_move_pan_x((-1) * config.editor.panMovement)
        this.$_mx_viewboxPan_move_pan_y((-1) * config.editor.panMovement)
      } else if (hitEdge.top) {
        this.$_mx_viewboxPan_move_pan_y(config.editor.panMovement)
      } else if (hitEdge.left) {
        this.$_mx_viewboxPan_move_pan_x(config.editor.panMovement)
      } else if (hitEdge.bottom) {
        this.$_mx_viewboxPan_move_pan_y((-1) * config.editor.panMovement)
      } else if (hitEdge.right) {
        this.$_mx_viewboxPan_move_pan_x((-1) * config.editor.panMovement)
      }

      if (hitEdge.top || hitEdge.right || hitEdge.bottom || hitEdge.left) {
        this.$events.fire(editorEventTypes.DRAW_UPDATE_PSEUDO_MOUSE, this.$_mx_viewboxPan_latest_mouse_event)
      }

      if (!hitEdge.top && !hitEdge.right && !hitEdge.bottom && !hitEdge.left) {
        clearInterval(this.$_mx_viewboxPan_mouse_timer)
      }
    },
    $_mx_viewboxPan_setInterval () {
      clearInterval(this.$_mx_viewboxPan_mouse_timer)
      this.$_mx_viewboxPan_mouse_timer = setInterval(() => {
        this.mx_viewboxPan_hit_test(this.$_mx_viewboxPan_latest_mouse_position, false)
      }, 200)
    },
    $_mx_viewboxPan_hit (point) {
      const bounds_y = (this.surface_bounds.x) ? this.surface_bounds.x : this.surface_bounds.top
      // const bounds_x = (this.surface_bounds.y) ? this.surface_bounds.y : this.surface_bounds.left

      return {
        left: config.editor.edge_tolerance.left > point.x,
        right: this.surface_bounds.width - config.editor.edge_tolerance.right < point.x,
        top: bounds_y + config.editor.edge_tolerance.top > point.y,
        bottom: this.surface_bounds.height + this.$_mx_viewboxPan_viewbox_offset_y - config.editor.edge_tolerance.bottom < point.y
      }
    },
    $_mx_viewboxPan_add_events () {
      if (process.env.NODE_ENV !== 'test') {
        if (this.$_mx_viewboxPan_ctrl === 'draw' || this.$_mx_viewboxPan_ctrl === 'drawDefault') {
          document.addEventListener('mousemove', this.mx_viewboxPan_hit_test)
        }
      }
    },
    $_mx_viewboxPan_remove_events () {
      if (process.env.NODE_ENV !== 'test') {
        document.removeEventListener('mousemove', this.mx_viewboxPan_hit_test)
        clearInterval(this.$_mx_viewboxPan_mouse_timer)
      }
    }
  },
  watch: {
    $_mx_viewboxPan_ctrl (val) {
      switch (val) {
        case 'draw':
        case 'drawDefault':

          break
        default:
          this.$_mx_viewboxPan_remove_events()
      }
    },
    $_mx_viewboxPan_last_internal_action (val) {
      if (val !== null && val.action) {
        switch (val.action) {
          case 'scale':
            this.$_mx_viewboxPan_add_events()
            break
          default:
            this.$_mx_viewboxPan_remove_events()
        }
      }
    }
  }
}
