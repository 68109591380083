import { Point/*, Path */ } from '@/classes/objects'
import * as editorEventTypes from '@/events/editor-event-types'

/**
 * Tools - Default Object Pattern
 * @displayName Rectangle Object
 */
export default {
  name: 'ToolsRectangleMixin',
  mounted () {
    this.$events.on(editorEventTypes.DRAW_DEFAULT_FINISH_RECTANGLE, this.$_mx_tools_rectangle_finishRectangle)
  },
  methods: {
    /**
     * Create a rectangle
     * @returns {VNode}
     * @public
     */
    mx_tools_rectangle_rectangle (keyboardInput) {
      this.pseudoPoints = []
      let circles = this.defaultObject.points.map((point, index, points) => {
        if (point.type !== 'M' && index <= points.length - 1) {
          this.pseudoPoints.push({ x: point.x, y: point.y })
          return this.defaultCircle(point)
        }
      })

      let lastPoint = { x: 0, y: 0 }

      circles = circles.filter(item => {
        if (item !== undefined) {
          return item
        }
      })

      const nextPoint = this.mx_tools_rectangle_get_point(this.mouse, keyboardInput)

      circles.map((circle, index) => {
        const circleData = circle.data.attrs

        this.pseudoPoints.push({ x: nextPoint.x, y: circleData.cy })
        lastPoint = { x: circleData.cx, y: nextPoint.y }
      })

      circles.push(this.defaultCircle(nextPoint))
      this.pseudoPoints.push(nextPoint)
      this.pseudoPoints.push(lastPoint)

      return this.$createElement('g', {
        class: {
          'c-object c-object__group': true
        }
      }, [this.defaultPattern(this.pseudoPoints), circles])
    },
    mx_tools_rectangle_get_point (mouse, keyboardInput, overridePoint = null) {
      let nextPoint = mouse
      if (keyboardInput > 0) {
        const lastPoint = (overridePoint !== null) ? overridePoint : this.pseudoPoints.slice(-1).pop()
        const calcPoint = this.mx_math_getPointInDistance(lastPoint, {
          x: this.mouse.x,
          y: lastPoint.y
        }, keyboardInput, this.measure)

        nextPoint = this.mx_math_getPointInDistance(calcPoint,
          { x: calcPoint.x, y: this.mouse.y }, keyboardInput, this.measure)
      }

      return nextPoint
    },
    /**
     * Finish the predefined rectangle
     * @private
     */
    $_mx_tools_rectangle_finishRectangle (keyboardInput) {
      const mPoint = this.defaultObject.points[0]
      const point = this.mx_tools_rectangle_get_point(this.mouse, keyboardInput, mPoint)
      const startPoint = this.defaultObject.points[1]
      const p2 = new Point(point.x, startPoint.y)
      const p3 = new Point(point.x, point.y)
      const p4 = new Point(startPoint.x, point.y)

      this.defaultObject.points = [mPoint, startPoint, p2, p3, p4, new Point(0, 0, 'Z')]

      if (!this.cut) {
        this.finishPredefinedObject()
      } else {
        this.preparePredefinedObject()
      }
    }
  }
}
