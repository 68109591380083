import _ from 'lodash'
import * as types from '@/vuex/backend-api-mutation-types'
import { productTypes } from '@/helper/api/types'
import { apiMethods, routes } from '@/helper/apiHelper'

/**
 * Default state for: Project Products Store
 * @returns {{}}
 */
const defaultState = () => {
  return {
    products: [],
    infills: [],
    subbases: [],
    pads: [],
    pedestals: [],
    seamtapes: [],
    adhesives: [],
    consumables: [
      {
        id: 1,
        name: 'Self-adhesive seaming tape (10m)',
        thumb: 'Schiebemesser_Roberts.png',
        attributes: {
          length: 10,
          unit: 'ft',
          price: 23.00,
          currency: '$'
        }
      },
      {
        id: 10,
        name: 'Artificial turf adhesive (cartouche)',
        thumb: 'Rasenkleber_Kartusche.png',
        attributes: {
          length: 5,
          unit: 'ft',
          price: 9.90,
          currency: '$'
        }
      }
    ],
    tools: [
      {
        id: 2,
        name: 'Cutter knife',
        thumb: 'Schiebemesser_Roberts.png',
        attributes: {
          price: 38.00,
          currency: '$'
        }
      },
      {
        id: 3,
        name: 'Adhesive spreader',
        thumb: 'Rakel.png',
        attributes: {
          price: 119.00,
          currency: '$'
        }
      },
      {
        id: 4,
        name: 'Automatic knife with trapezoidal blades',
        thumb: 'Profimesser_Connex.png',
        attributes: {
          price: 18.00,
          currency: '$'
        }
      },
      {
        id: 5,
        name: 'Artificial turf adhesive 17 kg-Bucket (PC 21-010), range: 70 m',
        thumb: 'Rasenkleber_Eimer.png',
        attributes: {
          price: 102.00,
          currency: '$'
        }
      },
      {
        id: 6,
        name: 'Artificial turf adhesive 4 kg-Bucket (PC 21-010), range: 20 m',
        thumb: 'Rasenkleber_Eimer.png',
        attributes: {
          price: 29.00,
          currency: '$'
        }
      },
      {
        id: 7,
        name: 'Anker and nails (100 pcs / package)',
        thumb: 'Anker_u_Naegel.png',
        attributes: {
          price: 75.00,
          currency: '$'
        }
      },
      {
        id: 8,
        name: 'Head seam rail',
        thumb: 'Kopfnahtschiene.png',
        attributes: {
          price: 196.00,
          currency: '$'
        }
      },
      {
        id: 9,
        name: 'Seaming tape (0,3 x 100 m)',
        thumb: 'Vlies_Rasenkleber.png',
        attributes: {
          price: 74.00,
          currency: '$'
        }
      },
      {
        id: 11,
        name: 'Brushing machine',
        thumb: 'Buerstmaschine.png',
        attributes: {
          price: 1450.00,
          currency: '$'
        }
      }

    ],
    categories: {
      0: 'all',
      1: 'Artificial Turf'
    }
  }
}

const state = defaultState()

const getters = {
  /**
     * Return complete state
     *
     * @param state
     * @returns {any}
     */
  all: (state) => {
    return state
  },
  /**
     * Returns all products (turf, epdm, ...)
     *
     * @param state
     * @returns {any}
     */
  allProducts: (state) => {
    return state.products
  },
  /**
     * Has products
     *
     * @param state
     * @returns {number}
     */
  hasProducts: (state) => {
    return state.products.length
  },
  /**
     * Returns a product by id
     *
     * @param state
     * @returns {function(*): T}
     */
  getProductById: (state) => (key) => {
    return state.products.find((product) => {
      if (product.original.id === key) {
        return product
      }
    })
  },
  /**
     * Returns a product by category
     *
     * @param state
     * @returns {Function}
     */
  getProductsByCategory: (state) => (category) => {
    if (category === 'all') {
      return state.products
    }

    return state.products.filter((product) => {
      if (product.category === category) {
        return product
      }
    })
  },
  /**
     * Returns all subbases
     * Combined: others (subbases), pads, pedestals
     *
     * @param state
     * @returns {*}
     */
  getAllSubbases: (state) => {
    return _.sortBy(_.union(state.subbases, state.pads, state.pedestals), ['name'])
  },
  /**
     * Find subbase with id and type
     *
     * @param state
     * @returns {{}|null}
     */
  getSubbaseByIdType: (state) => (data) => {
    const findSubbase = (el) => {
      if (el.original.id === data.id) {
        return el
      }
    }

    let foundSubbase

    switch (data.type) {
      case productTypes.PRODUCT_OTHER_SUBBASE_TYPE:
        foundSubbase = state.subbases.find(findSubbase)
        break
      case productTypes.PRODUCT_PAD_SUBBASE_TYPE:
        foundSubbase = state.pads.find(findSubbase)
        break
      case productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE:
        foundSubbase = state.pedestals.find(findSubbase)
        break
    }

    return (foundSubbase !== undefined) ? foundSubbase : null
  },
  /**
     *
     * @param state
     * @returns {{}|null}
     */
  getInfillById: (state) => (id) => {
    const infill = state.infills.find(infill => {
      if (infill.original.id === id) {
        return infill
      }
    })

    return (infill !== undefined) ? infill : null
  },
  /**
     *
     * @param state
     * @returns {boolean}
     */
  hasSeamtapes: (state) => {
    return state.seamtapes.length > 0
  },
  /**
     *
     * @param state
     * @returns {[]}
     */
  getSeamtapes: (state) => {
    return state.seamtapes
  },
  /**
     *
     * @param state
     * @returns {{}|undefined}
     */
  getSeamtapeById: (state) => (id) => {
    return state.seamtapes.find(seamtape => seamtape.original.id === id)
  },
  /**
     *
     * @param state
     * @returns {boolean}
     */
  hasAdhesives: (state) => {
    return state.adhesives.length > 0
  },
  /**
     *
     * @param state
     * @returns {[]}
     */
  getAdhesives: (state) => {
    return state.adhesives
  },
  /**
     *
     * @param state
     * @returns {{}|undefined}
     */
  getAdhesiveById: (state) => (id) => {
    return state.adhesives.find(adhesive => adhesive.original.id === id)
  },
  /**
     *
     * @param state
     * @returns {{}|undefined}
     */
  getSubbaseOtherById: (state) => (id) => {
    return state.subbases.find(subbase => subbase.original.id === id)
  },
  /**
     *
     * @param state
     * @returns {{}|undefined}
     */
  getSubbasePadById: (state) => (id) => {
    return state.pads.find(pad => pad.original.id === id)
  },
  /**
     *
     * @param state
     * @returns {{}|undefined}
     */
  getSubbasePedestalById: (state) => (id) => {
    return state.pedestals.find(pedestal => pedestal.original.id === id)
  },
  // TODO: REWORK BELOW !!!
  /**
     * Returns all available products category
     * @param state
     * @returns {getters.categories|(function(*))|string|string[]|state.categories|{"0", "1", "2"}}
     */
  categories: (state) => {
    return state.products.categories
  },
  /**
     * Returns all tools
     * @param state
     * @returns {*[]|string}
     */
  tools: (state) => {
    return state.tools
  },
  /**
     * Returns all consumables
     * @param state
     * @returns {*[]|string}
     */
  consumables: (state) => {
    return state.consumables
  },
  getConsumableById: (state) => (id) => {
    return state.consumables.find((item) => {
      if (item.id === id) {
        return item
      }
    })
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_LOADED_PROJECT_STATES)
  },
  async listAvailableProductForProject ({ rootState, dispatch }, payload) {
    payload = (payload === null) ? { id: rootState.project.backendMeta.id } : payload
    await dispatch('listTurfs', payload.id)
    dispatch('listInfills', payload.id)
    dispatch('listSubases', payload.id)
    dispatch('listPads', payload.id)
    dispatch('listPedestals', payload.id)
    dispatch('listSeamtapes', payload.id)
    dispatch('listAdhesives', payload.id)
  },
  async listTurfs ({ rootState, dispatch, commit }, id) {
    await apiMethods.request('get', routes.project + `/${id}/available/turfs`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_TURFS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listInfills ({ rootState, dispatch, commit }, id) {
    apiMethods.request('get', routes.project + `/${id}/available/infills`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_INFILLS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listSubases ({ rootState, dispatch, commit }, id) {
    apiMethods.request('get', routes.project + `/${id}/available/subbases`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_SUBBASES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listPads ({ rootState, dispatch, commit }, id) {
    apiMethods.request('get', routes.project + `/${id}/available/pads`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_PADS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listPedestals ({ rootState, dispatch, commit }, id) {
    apiMethods.request('get', routes.project + `/${id}/available/pedestals`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_PEDESTALS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listSeamtapes ({ rootState, dispatch, commit }, id) {
    apiMethods.request('get', routes.project + `/${id}/available/seamtapes`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_SEAMTAPES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async listAdhesives ({ rootState, dispatch, commit }, id) {
    apiMethods.request('get', routes.project + `/${id}/available/adhesives`, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PRODUCT_ADHESIVES, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {
  [types.RESET_LOADED_PROJECT_STATES] (state) {
    Object.assign(state, defaultState())
  },
  [types.PROJECT_SET_PRODUCT_TURFS] (state, turfs) {
    state.products = _.sortBy(turfs, ['name'])
  },
  [types.PROJECT_SET_PRODUCT_INFILLS] (state, infills) {
    infills = infills.map(infill => {
      infill.type = productTypes.PRODUCT_INFILL_TYPE
      return infill
    })

    state.infills = _.sortBy(infills, ['name'])
  },
  [types.PROJECT_SET_PRODUCT_SUBBASES] (state, subbases) {
    subbases = subbases.map(subbase => {
      subbase.type = productTypes.PRODUCT_OTHER_SUBBASE_TYPE
      return subbase
    })

    state.subbases = _.sortBy(subbases, ['name'])
  },
  [types.PROJECT_SET_PRODUCT_PADS] (state, pads) {
    pads = pads.map(pad => {
      pad.type = productTypes.PRODUCT_PAD_SUBBASE_TYPE
      return pad
    })

    state.pads = _.sortBy(pads, ['name'])
  },
  [types.PROJECT_SET_PRODUCT_PEDESTALS] (state, pedestals) {
    pedestals = pedestals.map(pedestal => {
      pedestal.type = productTypes.PRODUCT_PEDESTAL_SUBBASE_TYPE
      return pedestal
    })

    state.pedestals = _.sortBy(pedestals, ['name'])
  },
  [types.PROJECT_SET_PRODUCT_SEAMTAPES] (state, seamtapes) {
    state.seamtapes = _.sortBy(seamtapes, ['name'])
  },
  [types.PROJECT_SET_PRODUCT_ADHESIVES] (state, adhesives) {
    state.adhesives = _.sortBy(adhesives, ['name'])
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
