<template>
  <svg
    id="order-down-total-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M20,34.76,6.83,21.63a2.5,2.5,0,0,1,3.54-3.53L20,27.69l9.8-9.79a2.5,2.5,0,0,1,3.53,3.53Z"
    />
    <path
      class="u-fill-w"
      d="M20,23.37,6.83,10.24A2.5,2.5,0,0,1,10.37,6.7L20,16.3l9.8-9.8A2.5,2.5,0,0,1,33.29,10Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'OrderDownTotalIcon'
}
</script>
