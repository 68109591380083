const models = {
  group: {
    name: '',
    address: {
      name: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      country: '',
      website: ''
    },
    measurement: '',
    currency_sign: '',
    currency_short: '',
    logo: {
      thumb: null,
      full: null
    },
    theme: null
  },
  admin: {
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: ''
  },
  manufacture: {
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    group_id: null,
    preferred_language: ''
  },
  distributor: {
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    group_id: null,
    preferred_language: ''
  },
  employee: {
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    group_id: null,
    preferred_language: ''
  },
  user: {
    userId: null,
    firstname: '',
    lastname: ''
  },
  location: {
    name: '',
    address: {
      street: '',
      city: '',
      zip: '',
      state: '',
      country: ''
    },
    currency_short: ''
  },
  client: {
    salutation: '',
    firstname: '',
    lastname: '',
    email: '',
    group_id: null,
    address: {
      name: '',
      phone: '',
      street: '',
      city: '',
      zip: '',
      state: '',
      country: ''
    }
  },
  project: {
    name: '',
    client_id: null,
    address: {
      street: '',
      city: '',
      zip: '',
      state: '',
      country: ''
    }
  },
  template: {
    name: '',
    description: '',
    public: false,
    width: 0,
    height: 0,
    template: ''
  },
  apiClient: {
    name: '',
    group_id: null
  }
}

export default models
