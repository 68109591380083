<template>
  <panel-container
    :title=" $t('editor.lineOfSight.product.properties.title')
      + defaultProductSize.width + ' x ' + defaultProductSize.length"
  >
    <input-field
      id="turf-width"
      v-model="width"
      :isStoreMode="false"
      :label="$t('editor.lineOfSight.product.properties.width', {unit: $settings.turfUnits.width})"
      :validate="(v) => {return mx_validate_validatePositiveNumber(v) && defaultProductSize.width >= parseFloat(v) && parseFloat(v) >= 1 }"
      type="number"
      @change="setWidth"
    />

    <input-field
      id="turf-length"
      v-model="length"
      :isStoreMode="false"
      :label="$t('editor.lineOfSight.product.properties.length', {unit: $settings.turfUnits.length})"
      :validate="(v) => {return mx_validate_validatePositiveNumber(v) && defaultProductSize.length >= parseFloat(v) && parseFloat(v) >= 1 }"
      type="number"
      @change="setLength"
    />

    <button-base
      id="product-properties-set"
      :disabled="error.length === true || error.width === true"
      :label="$t('editor.lineOfSight.product.properties.set')"
      :on-click-action="() => { return update() }"
      classes="u-mr-size-0"
      style-min-width="180px"
    />

    <div
      v-if="error.width || error.length"
      class="c-los__warning"
      v-html="$t('editor.lineOfSight.product.properties.warning',
                 {unit: getObjectProduct.unit, maxWidth: defaultProductSize.width, maxLength: defaultProductSize.length})"
    />
  </panel-container>
</template>

<script>
import { mapGetters } from 'vuex'

import PanelContainer from '@/components/common/PanelContainer'
import InputField from '@/components/common/InputField'

import MixinValidate from '@/mixins/validate'
import ButtonBase from '@/components/common/ButtonBase'

/**
 * Configure product properties
 * - Width, Length
 * @displayName LineOfSight Panel - Product - Properties
 */
export default {
  name: 'ProductProperties',
  components: {
    ButtonBase,
    PanelContainer,
    InputField
  },
  mixins: [
    MixinValidate
  ],
  data () {
    return {
      width: 0,
      length: 0,
      error: {
        width: false,
        length: false
      }
    }
  },
  computed: {
    ...mapGetters({
      getObject: 'lineOfSightNew/getObject',
      getObjectProduct: 'lineOfSightNew/getObjectProduct'
    }),
    defaultProductSize () {
      return {
        width: Number(this.getObjectProduct.max.width),
        length: Number(this.getObjectProduct.max.length)
      }
    }
  },
  watch: {
    getObject: {
      handler: function (n, o) {
        this.width = Number(this.getObjectProduct.width)
        this.length = Number(this.getObjectProduct.length)
        this.error = {
          width: false,
          length: false
        }
      },
      deep: true
    }
  },
  mounted () {
    this.width = Number(this.getObjectProduct.width)
    this.length = Number(this.getObjectProduct.length)
  },
  methods: {
    setWidth (data) {
      this.error.width = data.error
    },
    setLength (data) {
      this.error.length = data.error
    },
    update () {
      this.$emit('changeProductProperties', { action: 'changeProductProperties', data: { width: Number(this.width), length: Number(this.length) } })
    }
  }
}
</script>
