import Paper from 'paper'
import config from '@/config'
// import { Path } from '@/classes/objects'
import _ from 'lodash'

/**
 * The algorithm to calculate tracks
 * @displayName Line of sight - Algorithm Intersect
 */
export default {
  name: 'AlgorithmIntersectMixin',
  data: () => {
    return {
      mx_algorithm_static: {},
      mx_algorithm_global: {},
      mx_algorithm_storage: {},
      mx_algorithm_fragment_x_positions: null,
      mx_algorithm_fragment_x_position: null,
      mx_algorithm_result: null
    }
  },
  methods: {
    /**
         * Calculate turf tracks by given params
         *
         * @param {Path} element
         * @param {object} params
         * @returns {object}
         * @public
         */
    mx_algorithm_calculate_tracks (element, params) {
      this.mx_algorithm_result = new Map()
      this.mx_algorithm_fragment_x_positions = new Map()

      params.angles.forEach(angle => {
        this.$_mx_algorithm_initialize_storage(angle)
        const object = this.$_mx_algorithm_object(element)
        const clonedObject = object.clone()

        this.$_mx_algorithm_initialize_global(params)
        this.$_mx_algorithm_initialize_static(params, angle, Number(element.id.replace(config.objects.ID, '')), clonedObject.bounds.center)

        if (this.mx_algorithm_static.angle > 0) {
          object.rotate(-this.mx_algorithm_static.angle, clonedObject.bounds.center)
        }

        if (this.mx_algorithm_global.perfectVisualResult) {
          this.mx_algorithm_global.threshold = this.mx_algorithm_global.turfLength
        }

        this.mx_algorithm_global.shift = (this.mx_algorithm_global.shift === this.mx_algorithm_global.turfWidth) ? 0 : this.mx_algorithm_global.shift

        const fObjects = this.$_mx_algorithm_fractalize(object, params.turfWidth, this.mx_algorithm_global.shift, params.alignment)

        fObjects.forEach((fragment, index) => {
          // set column x-position for current fragment iteration
          this.mx_algorithm_fragment_x_position = this.mx_algorithm_fragment_x_positions.get(fragment.id)

          // Paper.Path (fragment without child elements)
          if (fragment instanceof Paper.Path && fragment.bounds.width >= 1 && fragment.clockwise) {
            this.$_mx_algorithm_possible_to_use_garbage(fragment.bounds.clone(), this.mx_algorithm_global.turfLength, this.mx_algorithm_global.perfectVisualResult)
          }

          // Paper.CompoundPath (fragment with one or more child elements)
          if (fragment instanceof Paper.CompoundPath && fragment.bounds.width >= 1) {
            fragment.children.forEach(child => {
              if (child.clockwise) {
                this.$_mx_algorithm_possible_to_use_garbage(child.bounds.clone(), this.mx_algorithm_global.turfLength, this.mx_algorithm_global.perfectVisualResult)
              }
            })
          }
        })

        this.mx_algorithm_storage.tracks = this.mx_algorithm_storage.tracks.map(track => {
          return track.rotate(this.mx_algorithm_static.angle, clonedObject.bounds.center)
        })

        object.remove()
        clonedObject.remove()
        this.mx_algorithm_result.set(angle, this.mx_algorithm_storage)
        this.mx_algorithm_fragment_x_positions.clear()
      })

      return new Promise(resolve => {
        setTimeout(() => {
          resolve(this.mx_algorithm_result)
        }, 100)
      })
    },
    /**
         * @param object
         * @returns {null | paper.CompoundPath}
         */
    $_mx_algorithm_object (object) {
      let paper_object = null

      switch (object.type) {
        case config.objects.types.PATH:
          paper_object = new Paper.CompoundPath(object.d())
          break
        case config.objects.types.CIRCLE: {
          const props = object.getCircleProperties()
          paper_object = new Paper.CompoundPath(new Paper.Path.Circle({
            center: [props.middle.x, props.middle.y],
            radius: props.radius
          }))
        }
      }
      return paper_object
    },
    /**
         * @param {Number} angle
         */
    $_mx_algorithm_initialize_storage (angle) {
      this.mx_algorithm_storage = {
        tracks: [],
        movableLines: [],
        garbage: [],
        angle: angle,
        turfRoll: {
          used: 1,
          part: 1,
          edgeLength: 0
        }
      }
    },
    /**
         * @param {Object} params
         */
    $_mx_algorithm_initialize_global (params) {
      this.mx_algorithm_global = JSON.parse(JSON.stringify(params))
    },
    /**
         * @param {Object} params
         * @param {Number} angle
         * @param {Number} objectID
         * @param {Object} rotationPoint
         */
    $_mx_algorithm_initialize_static (params, angle, objectID, rotationPoint) {
      this.mx_algorithm_static.turfLength = params.turfLength
      this.mx_algorithm_static.turfThreshold = params.threshold
      this.mx_algorithm_static.angle = Number(angle)
      this.mx_algorithm_static.objectID = Number(objectID)
      this.mx_algorithm_static.rotationPoint = rotationPoint
    },
    /**
         * Fractalize given object in parts by turf width
         *
         * @param {paper.Path|paper.CompoundPath} object
         * @param {Number} turfWidth
         * @param {Number} shift
         * @param {boolean} alignment
         * @returns {paper.Item[]}
         */
    $_mx_algorithm_fractalize (object, turfWidth, shift = 0, alignment = false) {
      const max_infinity_turf_rolls = Math.ceil((object.bounds.width + shift) / turfWidth)
      const fragments = []

      for (let i = 0; i < max_infinity_turf_rolls; i++) {
        const x = (alignment) ? (object.bounds.x - shift) + (turfWidth * i)
          : (object.bounds.x + object.bounds.width + shift) - (turfWidth * (i + 1))

        const from = new Paper.Point(x, object.bounds.y)
        const part_size = new Paper.Size(turfWidth, object.bounds.height)

        const rectangle = new Paper.Path.Rectangle(from, part_size)

        const result = rectangle.intersect(object)

        fragments.push(result)
        this.mx_algorithm_fragment_x_positions.set(result.id, x)
        rectangle.remove()
      }

      return fragments
    },
    /**
         * Decide by perfect visual result flag:
         *      true: use for each fragment a new roll
         *      false: check if a roll can be reused
         *
         * @param {Object} fragmentBounds
         * @param {Number} currentTurfLength
         * @param {boolean} perfectVisualResult
         */
    $_mx_algorithm_possible_to_use_garbage (fragmentBounds, currentTurfLength, perfectVisualResult) {
      if (perfectVisualResult) {
        this.$_mx_algorithm_inspect_fragment(fragmentBounds, currentTurfLength)
      } else {
        if (this.mx_algorithm_storage.garbage.length > 0) {
          // consider which garbage track with the lowest difference can be used
          let lowest = { difference: Number.POSITIVE_INFINITY }
          let highest = { difference: Number.NEGATIVE_INFINITY }
          let temporary_difference = 0

          for (let i = 0; i <= this.mx_algorithm_storage.garbage.length - 1; i++) {
            if (this.mx_algorithm_storage.garbage[i].rest > this.mx_algorithm_global.threshold) {
              temporary_difference = Number(this.mx_algorithm_storage.garbage[i].rest - fragmentBounds.height)

              if (temporary_difference < lowest.difference && temporary_difference >= 0) {
                lowest = {
                  difference: temporary_difference,
                  key: i
                }
              }
              if (temporary_difference > highest.difference && temporary_difference >= 0) {
                highest = {
                  difference: temporary_difference,
                  key: i
                }
              }
            }
          }

          if (lowest.difference !== Number.POSITIVE_INFINITY) {
            this.$_mx_algorithm_is_possible_to_add_track(fragmentBounds, fragmentBounds.height, lowest.key)
          } else {
            this.$_mx_algorithm_inspect_fragment(fragmentBounds, currentTurfLength)
          }
        } else {
          this.$_mx_algorithm_inspect_fragment(fragmentBounds, currentTurfLength)
        }
      }
    },
    /**
         * Inspect fragment
         *
         * @param {Object} fragmentBounds
         * @param {Number} turfLength
         */
    $_mx_algorithm_inspect_fragment (fragmentBounds, turfLength) {
      const clonedFragmentBounds = fragmentBounds.clone({ insert: false })

      if (clonedFragmentBounds.height < this.mx_algorithm_global.threshold) { // case 1.0 - section is smaller than threshold
        this.$_mx_algorithm_is_possible_to_add_track(clonedFragmentBounds, clonedFragmentBounds.height)
      } else if (clonedFragmentBounds.height >= this.mx_algorithm_global.threshold) { // case 2.0 section is greater than or equal threshold
        let fragmentRestLength = clonedFragmentBounds.height - Number(turfLength)

        if (fragmentRestLength >= this.mx_algorithm_global.threshold) { // case 2.1 fragment rest length is greater than threshold
          this.$_mx_algorithm_is_possible_to_add_track(clonedFragmentBounds, turfLength)
          clonedFragmentBounds.height -= Number(turfLength)
          clonedFragmentBounds.y += Number(turfLength)
          this.$_mx_algorithm_inspect_fragment(clonedFragmentBounds, Number(this.mx_algorithm_global.turfLength))
        } else if (fragmentRestLength > 0) { // case 2.2 fragment rest length is greater than zero but smaller than threshold
          clonedFragmentBounds.height = (this.mx_algorithm_global.perfectVisualResult) ? this.mx_algorithm_global.threshold : Number(this.mx_algorithm_global.turfLength)

          // check next fragment smaller than threshold
          if (fragmentRestLength < this.mx_algorithm_static.turfThreshold) {
            const difference = this.mx_algorithm_static.turfThreshold - fragmentRestLength

            clonedFragmentBounds.height = clonedFragmentBounds.height - difference
            fragmentRestLength = fragmentRestLength + difference
          }

          this.$_mx_algorithm_is_possible_to_add_track(clonedFragmentBounds, clonedFragmentBounds.height)

          if (this.mx_algorithm_global.perfectVisualResult) {
            clonedFragmentBounds.y = Number(clonedFragmentBounds.y + clonedFragmentBounds.height)
            clonedFragmentBounds.height = Number(fragmentRestLength)
            this.$_mx_algorithm_inspect_fragment(clonedFragmentBounds, Number(this.mx_algorithm_global.threshold))
          } else {
            clonedFragmentBounds.y = clonedFragmentBounds.y + clonedFragmentBounds.height
            clonedFragmentBounds.height = fragmentRestLength
            this.$_mx_algorithm_possible_to_use_garbage(clonedFragmentBounds, this.mx_algorithm_global.turfLength, this.mx_algorithm_global.perfectVisualResult)
          }
        } else { // case 2.3 fragment rest length is smaller or equal than zero
          this.$_mx_algorithm_is_possible_to_add_track(clonedFragmentBounds, clonedFragmentBounds.height)
        }
      }
    },
    /**
         * Is possible to add the current created track to storage
         *
         * @param {Object} bounds
         * @param {Number} length
         * @param {Number} garbageKey
         */
    $_mx_algorithm_is_possible_to_add_track (bounds, length, garbageKey = null) {
      // create track
      const track = new Paper.Path.Rectangle(new Paper.Point(this.mx_algorithm_fragment_x_position, bounds.y),
        new Paper.Size(this.mx_algorithm_global.turfWidth, length))

      // check current track
      if (this.mx_algorithm_storage.tracks.length > 0) {
        const has = this.$_mx_algorithm_check_inside(track, this.mx_algorithm_storage.tracks)

        // has not passed and is not in a track
        if (!has.passed && !has.self) {
          has.findings.forEach(el => {
            this.$_mx_algorithm_rollback_storage(el)
          })
        }

        // is current track the inside track -> ignore
        if (!has.self) {
          this.$_mx_algorithm_add_track(track, garbageKey)
        }
      } else {
        this.$_mx_algorithm_add_track(track, garbageKey)
      }

      track.remove()
    },
    /**
         *
         * @param {paper.Path} track
         */
    $_mx_algorithm_rollback_storage (track) {
      if (track.data.garbage !== null) {
        const key = track.data.garbage
        const garbage = this.mx_algorithm_storage.garbage[key]

        this.mx_algorithm_storage.garbage[key].rest = garbage.rest + track.bounds.height
        this.mx_algorithm_storage.garbage[key].part = Number(garbage.part - 1)
      }

      if (this.mx_algorithm_global.perfectVisualResult) {
        this.mx_algorithm_global.turfLength = Number(this.mx_algorithm_static.turfLength)
        this.mx_algorithm_storage.turfRoll.part = 1
        this.mx_algorithm_storage.turfRoll.used -= 1
      }

      this.mx_algorithm_storage.turfRoll.edgeLength -= Math.ceil(track.bounds.height)

      this.mx_algorithm_storage.tracks = _.cloneDeep(this.mx_algorithm_storage.tracks.filter(t => t.name !== track.name))
    },
    /**
         *
         * @param {paper.Path} track
         * @param {paper.Item[]} tracks
         *
         * @returns {{findings: paper.Item[], passed: boolean}}
         */
    $_mx_algorithm_check_inside (track, tracks) {
      const clone = track.clone({ insert: false })
      const cp = new Paper.CompoundPath()
      let isSelf = false

      cp.addChildren(tracks)

      // find other tracks inside this current track
      const findings = cp.getItems({ inside: clone.bounds })

      // if nothing found, check if this current track is inside others
      if (findings.length === 0) {
        const overlaps = cp.getItems({ overlapping: clone.bounds })

        overlaps.forEach(overlap => {
          if (clone.isInside(overlap.bounds)) {
            isSelf = true
          }
        })
      }

      clone.remove()
      cp.removeChildren()
      cp.remove()

      return {
        passed: findings.length === 0,
        findings: findings,
        self: isSelf
      }
    },
    /**
         * Add the track to storage
         *
         * @param {paper.Path} track
         * @param {Number} garbageKey
         */
    $_mx_algorithm_add_track (track, garbageKey = null) {
      let trackName = ''

      if (garbageKey !== null) {
        const garbage = this.mx_algorithm_storage.garbage[garbageKey]
        trackName = `R${garbage.used}.${garbage.part}`

        // update garbage data
        this.mx_algorithm_storage.garbage[garbageKey].rest = garbage.rest - track.bounds.height
        this.mx_algorithm_storage.garbage[garbageKey].part = Number(garbage.part + 1)
      } else {
        trackName = `R${this.mx_algorithm_storage.turfRoll.used}.${this.mx_algorithm_storage.turfRoll.part}`
        this.$_mx_algorithm_update_turf_roll(track.bounds.height)
      }

      track.name = trackName
      track.data.garbage = garbageKey

      this.mx_algorithm_storage.turfRoll.edgeLength += Math.ceil(track.bounds.height)

      this.mx_algorithm_storage.tracks.push(track)
    },
    /**
         * Update turf roll or use garbage roll
         *
         * @param {Number} lengthToRemove
         */
    $_mx_algorithm_update_turf_roll (lengthToRemove) {
      const rollLengthRest = Number(this.mx_algorithm_global.turfLength) - Number(lengthToRemove)

      if (rollLengthRest >= this.mx_algorithm_global.threshold) {
        this.mx_algorithm_global.turfLength = Number(rollLengthRest)
        this.mx_algorithm_storage.turfRoll.part += 1
      } else {
        this.mx_algorithm_global.turfLength = Number(this.mx_algorithm_static.turfLength)
        this.mx_algorithm_storage.turfRoll.part = 1

        if (rollLengthRest > 0) {
          this.mx_algorithm_storage.garbage.push({
            used: this.mx_algorithm_storage.turfRoll.used,
            part: Number(this.mx_algorithm_storage.turfRoll.part) + 1,
            rest: rollLengthRest
          })
        }

        this.mx_algorithm_storage.turfRoll.used += 1
      }
    }
  }
}
