<template>
  <div
    v-show="requestOverlayVisible"
    class="request-overlay"
  >
    <div class="spinner" />
  </div>
</template>
<script>
import { mapState } from 'vuex'

/**
 * The RequestOverlay Component.
 * @displayName Request Overlay
 */

export default {
  name: 'RequestOverlay',
  computed: {
    ...mapState({
      requestOverlayVisible: state => state.dialogs.visible.requestOverlay
    })
  }
}
</script>
<style scoped lang="scss">
.request-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 99999;

  .spinner {
    width: 50px;
    height: 50px;
    background: url('~@/assets/img/loading_oval.svg');
    background-size: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

}
</style>
