<!-- NearestPaths.vue -->
<template>
  <g id="tool-get-line-interaction">
    <circle
      v-if="show"
      ref="nearestLocationPoint"
      :cx="nearestPoint.x"
      :cy="nearestPoint.y"
      :r="circleRadius"
      :stroke-width="strokeWidth"
      opacity="0"
      @click="handleMouseEvents"
      @mousedown="handleMouseEvents"
      @mouseleave="handleMouseEvents"
      @mouseup="handleMouseEvents"
    />
  </g>
</template>

<script>
import paperjs from 'paper'

import MixinMath from '@/mixins/math'
import _ from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'InteractWithLines',
  mixins: [MixinMath],
  props: {
    /**
     * data [{id: String, path: String, [type: String]},...]
     */
    data: {
      type: [Array, Object],
      required: false,
      default: null
    },
    maxDistance: {
      type: Number,
      required: false,
      default: 5
    }
  },
  data: () => {
    return {
      cp: null,
      nearest: [],
      position: null,
      nearestPoint: null,
      location: null
    }
  },
  computed: {
    ...mapState({
      commonSvgSettings: state => state.common.svg
    }),
    circleRadius () {
      return this.mx_math_handleElementsOnScale(5)
    },
    strokeWidth () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
    },
    show () {
      return this.nearestPoint !== null && this.position !== null
    }
  },
  watch: {
    data: {
      handler (n, o) {
        if (!_.isEqual(n, o)) {
          this.createPathsToCheck()
        }
      },
      deep: true
    },
    nearestPoint: {
      handler (n, o) {
        if (!_.isEqual(n, o)) {
          if (this.show) {
            const selectedData = this.data.find(sD => {
              return sD.id === this.location
            })
            this.$emit('hover', { selected: selectedData })
          } else {
            this.$emit('hover', { selected: null })
          }
        }
      },
      deep: true
    }
  },
  mounted () {
    this.cp = new paperjs.CompoundPath()
    this.createPathsToCheck()
    window.addEventListener('mousemove', this.updateMousePosition)
  },
  beforeDestroy () {
    if (this.nearest.length > 0) {
      this.$_clearTemporaryData()
    }
    window.removeEventListener('mousemove', this.updateMousePosition)
  },
  methods: {
    createPathsToCheck () {
      if (this.nearest.length > 0) {
        this.$_clearTemporaryData()
      }

      if (this.data.length > 0) {
        this.data.forEach(obj => {
          const nearest = new paperjs.Path(obj.path)
          nearest.name = obj.id

          this.nearest.push(nearest)
        })
        this.cp.addChildren(this.nearest)
      } else {
        this.nearest = []
      }
    },
    updateMousePosition (evt) {
      if (this.nearest.length > 0) {
        const point = this.mx_math_getCoordinatesFromEvent(evt)
        const nearestPoint = this.cp.getNearestPoint(point)

        // distance between point and nearest point
        const distance = this.mx_math_getDistance(point, nearestPoint)

        if (distance <= this.mx_math_handleElementsOnScale(this.maxDistance)) {
          let location = null

          this.cp.children.filter(child => {
            const offset = child.getOffsetOf(nearestPoint)
            const pathLocation = child.getLocationAt(offset)

            if (pathLocation !== null) {
              location = pathLocation.path.name

              if (!_.isEqual(this.location, location)) {
                this.location = location
              }
            }
          })

          this.nearestPoint = nearestPoint
          this.position = point
        } else {
          this.location = null
          this.nearestPoint = null
          this.position = null
        }
      } else {
        this.location = null
        this.nearestPoint = null
        this.position = null
      }
    },
    handleMouseEvents (evt) {
      if (evt.type === 'mousedown') {
        this.$el.addEventListener('mousemove', this.handleMouseEvents)
      }
      if (evt.type === 'mouseup' || evt.type === 'mouseleave') {
        this.$el.removeEventListener('mousemove', this.handleMouseEvents)
      }

      const selectedData = this.data.find(sD => {
        return sD.id === this.location
      })

      this.$emit(evt.type, { selected: selectedData, evt: evt })
    },
    $_clearTemporaryData () {
      this.nearest.forEach(nL => nL.remove())
      this.cp.children.forEach(nL => nL.remove())
      this.nearest = []
      this.cp.remove()
      this.cp = new paperjs.CompoundPath()
    }
  }
}
</script>
