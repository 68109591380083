import * as types from '@/vuex/backend-api-mutation-types'
import { apiMethods, routes, underlayStates } from '@/helper/apiHelper'
import {
  checkRelatedProduct,
  getProjectState,
  restoreProjectState,
  updateProjectState
} from '@/vuex/modules/backendAPI/helper/project-helper'
import router from '@/router'
import _ from 'lodash'
import Vue from 'vue'

/**
 * Default state for: Project Store
 *
 * @returns {{projects: []}}
 */
const defaultState = () => {
  return {
    projects: [],
    loadedProject: null,
    relatedProducts: null
  }
}

const state = defaultState()

const getters = {
  getAll (state) {
    return state.projects
  },
  /**
     *
     * @param state
     * @returns {Object|null}
     */
  getById: (state) => (id) => {
    if (state.projects.length > 0) {
      const project = state.projects.find(project => project.id === id)
      return (project !== undefined) ? project : null
    }

    return null
  }
}

const actions = {
  /**
     * Reset state
     *
     * @param commit
     */
  resetState ({ commit }) {
    commit(types.RESET_PROJECT_STATE)
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     */
  async list ({ rootState, dispatch, commit }) {
    await apiMethods.request('get', routes.project, null, rootState.backendAPI.login.token)
      .then(response => {
        commit(types.PROJECT_SET_PROJECTS, response.data.data)
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  /**
     *
     * @param rootState
     * @param rootGetters
     * @param dispatch
     * @param commit
     * @param {Number} projectId
     * @returns {Promise<boolean>}
     */
  async load ({ rootState, rootGetters, dispatch, commit }, projectId) {
    // load products
    await dispatch('products/listAvailableProductForProject', { id: projectId }, { root: true })

    // load project
    const projectResponse = await apiMethods.request('get', routes.project + `/${projectId}/projectdata`, null, rootState.backendAPI.login.token)
      .then(response => {
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })

    if (projectResponse !== null) {
      if (projectResponse.projectData !== null) {
        dispatch('editor/viewbox/origin', null, { root: true })
        const update = checkRelatedProduct(rootGetters, projectResponse.projectData.related_products)
        Vue.world.initCurrency(projectResponse.location.currency_short)
        Vue.units.init(projectResponse.location.currency_short)
        if (update.need) {
          commit(types.PROJECT_SET_PROJECT_RESPONSE, projectResponse)
          commit(types.PROJECT_SET_PROJECT_UPDATED_RELATED_PRODUCTS, update.state)
          dispatch('dialogs/dialogToggle', { dialog: 'projectNeedUpdate', onOff: true }, { root: true })
        } else {
          await router.push({ name: 'Melos-Editor' })
          await restoreProjectState(projectResponse.projectData, projectId, dispatch, commit)
          dispatch('project/setBackendMeta', projectResponse, { root: true })
          dispatch('history/setProjectInitialState', null, { root: true })
        }
      }
    }
  },
  async doUpdateProjectProducts ({ rootState, dispatch, commit, state }) {
    dispatch('dialogs/dialogToggle', { dialog: 'projectNeedUpdate', onOff: false }, { root: true })
    const projectResponse = _.cloneDeep(state.loadedProject)
    const relatedProducts = _.cloneDeep(state.relatedProducts)

    const newProjectState = updateProjectState(projectResponse.projectData.state, relatedProducts)

    const project = await apiMethods.request('patch', routes.project + `/${projectResponse.id}/projectdata`, {
      status: projectResponse.status,
      state: newProjectState,
      related_products: relatedProducts
    }, rootState.backendAPI.login.token).then(response => {
      return response.data.data
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
      return null
    })

    if (project !== null) {
      await dispatch('products/listAvailableProductForProject', { id: project.id }, { root: true })
      await router.push({ name: 'Melos-Editor' })
      await restoreProjectState(project.projectData, project.id, dispatch, commit)
      dispatch('project/setBackendMeta', project, { root: true })
      dispatch('history/setProjectInitialState', null, { root: true })

      commit(types.PROJECT_SET_PROJECT_RESPONSE, null)
      commit(types.PROJECT_SET_PROJECT_UPDATED_RELATED_PRODUCTS, null)
    }
  },
  /**
     * Default load project
     * do nothing
     *
     * @param dispatch
     * @param commit
     * @param state
     * @returns {Promise<void>}
     */
  async doLoadProjectWithoutUpdate ({ dispatch, commit, state }) {
    const projectResponse = state.loadedProject

    dispatch('dialogs/dialogToggle', { dialog: 'projectNeedUpdate', onOff: false }, { root: true })
    await router.push({ name: 'Melos-Editor' })
    await restoreProjectState(projectResponse.projectData, projectResponse.id, dispatch, commit)
    dispatch('project/setBackendMeta', projectResponse, { root: true })
    dispatch('history/setProjectInitialState', null, { root: true })

    commit(types.PROJECT_SET_PROJECT_RESPONSE, null)
    commit(types.PROJECT_SET_PROJECT_UPDATED_RELATED_PRODUCTS, null)
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {routes.project} project
     */
  async create ({ rootState, dispatch, commit }, project) {
    await apiMethods.request('post', routes.project, project, rootState.backendAPI.login.token).then(response => {
      commit(types.PROJECT_ADD_PROJECT, response.data.data)
      Vue.world.initCurrency(response.data.data.location.currency_short)
      dispatch('project/setBackendMeta', response.data.data, { root: true })
      dispatch('history/setProjectInitialState', null, { root: true })
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
    })
    await dispatch('products/listAvailableProductForProject', null, { root: true })
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Object} project
     */
  async update ({ rootState, dispatch, commit }, project) {
    const data = {
      name: project.name
    }

    if (project.address) {
      data.address = {
        name: project.address.name,
        phone: project.address.phone,
        street: project.address.street,
        city: project.address.city,
        zip: project.address.zip,
        state: project.address.state,
        country: project.address.country
      }
    }

    return await apiMethods.request('patch', routes.project + `/${project.id}`, data, rootState.backendAPI.login.token).then(response => {
      dispatch('list')
      return true
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
      return false
    })
  },
  /**
     * Save current project state
     *
     * @param rootState
     * @param rootGetters
     * @param dispatch
     */
  async updateState ({ rootState, rootGetters, dispatch }) {
    const backendMeta = rootGetters['project/backendMeta']
    const underlayState = rootGetters['files/getUnderlayState']

    const projectState = getProjectState(rootState)

    if (underlayState === underlayStates.new) {
      await dispatch('saveUnderlay', {
        projectId: backendMeta.id,
        underlay: rootGetters['files/getUnderlayDataUrl']
      })
    }

    if (!underlayState === underlayStates.delete) {
      await dispatch('removeUnderlay', backendMeta.id)
    }

    dispatch('files/setUnderlayState', underlayStates.old, { root: true })

    await apiMethods.request('patch', routes.project + `/${backendMeta.id}/projectdata`, {
      status: backendMeta.status,
      state: JSON.stringify(projectState.state),
      related_products: projectState.related_products
    }, rootState.backendAPI.login.token).then(response => {
      if (rootState.project.backendMeta.id && rootState.project.backendMeta.id === response.data.data.id) {
        dispatch('project/setBackendMeta', response.data.data, { root: true })
      }
      dispatch('history/setProjectInitialState', null, { root: true })
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
    })
  },
  /**
     *
     * @param rootState
     * @param rootGetters
     * @param dispatch
     * @param {{projectId:Number,underlay:String}} data
     */
  async saveUnderlay ({ rootState, rootGetters, dispatch }, data) {
    return await apiMethods.request('patch', routes.project + `/${data.projectId}/underlay`, {
      underlay: data.underlay
    }, rootState.backendAPI.login.token).then(response => {
      return true
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
      return false
    })
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param {Number} projectId
     */
  async removeUnderlay ({ rootState, dispatch }, projectId) {
    await apiMethods.request('delete', routes.project + `/${projectId}/underlay`, null, rootState.backendAPI.login.token).then(response => {
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
    })
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param projectId
     * @returns {Promise<AxiosResponse<any>>}
     */
  async getUnderlay ({ rootState, dispatch }, projectId) {
    return await apiMethods.request('get', routes.project + `/${projectId}/underlay`, null, rootState.backendAPI.login.token).then(response => {
      return response.data.data
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
    })
  },
  /**
     *
     * @param rootState
     * @param dispatch
     * @param commit
     * @param {Number} projectId
     */
  async delete ({ rootState, dispatch, commit }, projectId) {
    await apiMethods.request('delete', routes.project + `/${projectId}`, {}, rootState.backendAPI.login.token).then(response => {
      if (response.data.deleted) {
        commit(types.PROJECT_DELETE_PROJECT, projectId)
      }
    }).catch(error => {
      dispatch('backendAPI/error', error, { root: true })
    })
  }
}

const mutations = {
  [types.RESET_PROJECT_STATE] (state) {
    Object.assign(state, defaultState())
  },
  [types.PROJECT_SET_PROJECTS] (state, projects) {
    state.projects = projects.map(project => {
      project.created_at = apiMethods.convertDate(project.created_at)
      project.updated_at = apiMethods.convertDate(project.updated_at)

      return project
    })
  },
  [types.PROJECT_ADD_PROJECT] (state, project) {
    project.created_at = apiMethods.convertDate(project.created_at)
    project.updated_at = apiMethods.convertDate(project.updated_at)
    state.projects.push(project)
  },
  [types.PROJECT_DELETE_PROJECT] (state, projectId) {
    state.projects = state.projects.filter(project => project.id !== projectId)
  },
  [types.PROJECT_UPDATE_PROJECT] (state, project) {
    const p = state.projects.find(p => p.id === project.id)

    if (p !== undefined) {
      apiMethods.updateStoreObjectProperties(p, project)
    }
  },
  [types.PROJECT_SET_PROJECT_RESPONSE] (state, projectResponse) {
    state.loadedProject = projectResponse
  },
  [types.PROJECT_SET_PROJECT_UPDATED_RELATED_PRODUCTS] (state, relatedProducts) {
    state.relatedProducts = relatedProducts
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
