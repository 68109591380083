<template>
  <backend>
    <template #dialogs>
      <create-client-dialog />
      <delete-client-dialog />
      <update-client-dialog />
    </template>
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <button-base
            id="client-create-client"
            :label="$t('backend.clients.newClient')"
            :on-click-action="() => { return _event() }"
            classes="u-mt-size-40 u-ml-size-0"
            icon-classes="fa fa-plus"
            style-size="s"
          />
          <p>{{ $t('backend.clients.totalRecords') }} {{ totalRecords }}</p>
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-10/12">
          <async-client-list @updateTotalRecords="updateTotalRecords" />
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import { mapActions } from 'vuex'
import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'

import CreateClientDialog from '@/components/backend/clients/dialogs/CreateClient'
import UpdateClientDialog from '@/components/backend/clients/dialogs/UpdateClient'
import DeleteClientDialog from '@/components/backend/clients/dialogs/DeleteClient'
import AsyncClientList from '@/components/backend/clients/AsyncClientList'

import ButtonBase from '@/components/common/ButtonBase'

/**
     * @displayName Clients Component
     */
export default {
  name: 'Clients',
  components: {
    ButtonBase,
    Backend,
    CreateClientDialog,
    UpdateClientDialog,
    DeleteClientDialog,
    AsyncClientList
  },
  mixins: [MixinDialogHandler],
  data: () => {
    return {
      totalRecords: 0
    }
  },
  methods: {
    ...mapActions({
      initLocations: 'backendAPI/location/read'
    }),
    async _event () {
      await this.initLocations()
      this.$_mx_dialogHandler_openDialog({
        dialog: 'createClient',
        onOff: true
      })
    },
    updateTotalRecords (totalRecords) {
      this.totalRecords = totalRecords
    }
  }
}
</script>
