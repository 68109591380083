var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"pagination-options":{
      enabled: true,
      perPage: 20,
      perPageDropdown: [10, 20, 50],
      nextLabel: _vm.texts.nextLabel,
      prevLabel: _vm.texts.prevLabel,
      rowsPerPageLabel: _vm.texts.rowsPerPageLabel,
      ofLabel: _vm.texts.ofLabel,
      pageLabel: _vm.texts.pageLabel, // for 'pages' mode
      allLabel: _vm.texts.allLabel,
    },"rows":_vm.groups,"search-options":{
      enabled: true,
      skipDiacritics: true,
      placeholder: _vm.texts.searchLabel
    },"sort-options":{
      enabled: true,
      initialSortBy: [{field: 'address.name', type: 'asc'}, {field: 'address.name', type: 'asc'}]
    },"theme":"melos"},scopedSlots:_vm._u([{key:"emptystate",fn:function(){return [_c('div',{staticClass:"u-m-size-40 c-backend__nothing-to-show"},[_c('div',{staticClass:"c-typo__h3 c-backend__nothing-to-show"},[_c('icon',{attrs:{"classes":"far fa-2x fa-folder-open"}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.nothingToShow')))])],1)])]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field ==='logo')?_c('span',[(props.row.logo !== null)?_c('span',{staticClass:"vgt-icon",style:(`background-image: url('${props.row.logo.thumb}');`)}):_vm._e()]):(props.column.field ==='settings')?_c('span',{staticClass:"vgt-button-wrapper"},[_c('button-base',{attrs:{"id":`group-list-update-group-${props.row.originalIndex}`,"on-click-action":() => { return _vm.openDialog({type: 'updateGroup', groupData: props.row}) },"tooltip-disabled":false,"classes":"vgt-button","icon-classes":"fa fa-edit"},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]},proxy:true}],null,true)}),_c('button-base',{attrs:{"id":`group-list-delete-group-${props.row.originalIndex}`,"on-click-action":() => { return _vm.openDialog({type: 'deleteGroup', groupData: props.row}) },"tooltip-disabled":false,"classes":"vgt-button","icon-classes":"fa fa-trash"},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]},proxy:true}],null,true)})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }