<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.firstProductAdded.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.firstProductAdded.title') "
  >
    <template #content>
      <div class="c-dialog__instruction">
        <p class="u-pb-size-20">
          {{ $t('dialogs.firstProductAdded.text_1') }}
        </p>
        <p
          class="u-pb-size-10"
          v-html="$t('dialogs.firstProductAdded.text_2')"
        />
        <icon
          classes="c-c-dialog__icon"
          style-width="70px"
          style-height="70px"
        >
          <los-eye-blue-icon />
        </icon>
      </div>

      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="to-products-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="to-products-yes"
            :label="$t('common.yes')"
            :on-click-action="() => { return gotoProductSettings() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import DialogFrame from '@/components/common/DialogsFrame'
import MixinDialog from '@/mixins/dialogs'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'
import LosEyeBlueIcon from '@/components/common/icons/los/LosEyeBlueIcon'

export default {
  name: 'FirstProductAdded',
  components: {
    LosEyeBlueIcon,
    Icon,
    ButtonBase,
    DialogFrame
  },
  mixins: [
    MixinDialog
  ],
  data: () => {
    return {
      targetDialog: 'firstProductAdded',
      cannotClose: false
    }
  },
  computed: {
    ...mapState({
      currentSelectedObjects: state => state.events.currentSelectedObjects
    }),
    ...mapGetters({
      getObject: 'project/objects/getById'
    })
  },
  methods: {
    ...mapActions({
      toggleLineOfSightMode: 'events/toggleLineOfSightMode',
      setObjectToWorkWith: 'lineOfSightNew/setObjectToWorkWith'
    }),
    ...mapMutations({}),
    close () {
      this.mx_dialogs_closeDialog()
    },
    gotoProductSettings () {
      const object = this.getObject(this.currentSelectedObjects[0])
      this.toggleLineOfSightMode()
      this.setObjectToWorkWith(object)
      this.close()
    }
  }
}
</script>
