<template>
  <dialog-frame
    :target-dialog="targetDialog"
    :cannot-close="cannotClose"
    :style-content-flip-scrollbar-color="true"
    :subtitle=" $t('dialogs.product_page.subtitle') "
    :title=" $t('dialogs.product_page.title') "
    :style-content-no-frame="true"
    style-dialog-width="1280px"
    style-dialog-height="670px"
  >
    <template #header />

    <template #content>
      <div
        class="c-product-page"
      >
        <div
          v-if="product"
          class="c-product-page__container"
        >
          <!-----------------------------------------
          Content left
          ------------------------------------------->
          <div class="c-product-page-desc">
            <h2 class="c-typo__h2 u-mb-size-20">
              {{ mx_product_in_language(product.original, ['name']) }}
            </h2>

            <hooper
              v-if="product.original.assets.media_images.length > 0"
              :auto-play="true"
              :hover-pause="true"
              :play-speed="5000"
              :transition="1000"
              class="u-mb-size-40"
            >
              <slide
                v-for="(image, index) in product.original.assets.media_images"
                :key="index"
              >
                <img
                  :src="image.url"
                  alt="product picture"
                  class="c-product-page-desc__pic"
                >
              </slide>
              <hooper-pagination #hooperAddons />
            </hooper>

            <div class="c-product-page-desc__specs">
              <!-- Description -->
              <div
                v-if="product.original.description"
                class="u-mb-size-40"
              >
                <h4 class="c-typo__h4">
                  {{ $t('dialogs.product_page.details.description') }}
                </h4>
                <div
                  v-if="product.original.description"
                  class="c-product-page-desc__desc"
                >
                  <p>{{ mx_product_in_language(product.original, ['description']) }}</p>
                </div>
              </div>

              <!-- PDF -->
              <div
                v-if="product.original.assets.media_documents
                  && product.original.assets.media_documents.length > 0"
                class="u-mb-size-40"
              >
                <h4 class="c-product-page-desc__pdf-title c-typo__h4">
                  {{ $t('dialogs.product_page.details.specification') }}
                </h4>
                <a
                  v-for="(product_sheet, sheetIndex) in product.original.assets.media_documents"
                  :key="sheetIndex"
                  :href="product_sheet.url"
                  class="c-product-page-desc__pdf-pic"
                  target="_blank"
                >
                  <img
                    :src="product_sheet.conversions.thumb"
                    alt="Product sheet"
                  >
                </a>
              </div>
            </div>
          </div>

          <!-----------------------------------------
          Content right
          ------------------------------------------->

          <div class="c-product-page-info">
            <h2 class="c-typo__h2 u-mb-size-standard">
              {{ $t('dialogs.product_page.additional_info') }}
            </h2>
            <hr class="u-mb-size-40">

            <h3 class="c-typo__h3 u-mb-size-standard">
              {{ $t('dialogs.product_page.details.foot_traffic') }}
            </h3>
            <div class="u-mb-size-20">
              <span
                v-for="(star, sIndex) in 5"
                :key="sIndex"
              >
                <icon
                  v-if="product.original.details && product.original.details.foot_traffic >= star"
                  classes="fas fa-star"
                />
                <icon
                  v-if="product.original.details && product.original.details.foot_traffic < star"
                  classes="far fa-star"
                />
              </span>
            </div>
            <h3 class="c-typo__h3 u-mb-size-standard">
              {{ $t('dialogs.product_page.details.softness') }}
            </h3>

            <div class="u-mb-size-40">
              <span
                v-for="(star, sIndex) in 5"
                :key="sIndex"
              >
                <icon
                  v-if="product.original.details && Number(product.original.details.softness) >= star"
                  classes="fas fa-star"
                />
                <icon
                  v-if="product.original.details && Number(product.original.details.softness) < star"
                  classes="far fa-star"
                />
              </span>
            </div>

            <div
              v-if="product.original.details"
              class="c-product-page-info__table-wrapper u-mt-size-40"
            >
              <table
                v-if="productDetails"
                class="u-mb-size-40"
              >
                <tr
                  v-for="info in productDetails"
                  :key="info.key"
                >
                  <th>{{ info.label }}</th>
                  <td>
                    <p v-html="info.value" />
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import * as editorEventTypes from '@/events/editor-event-types'
import { Hooper, Pagination as HooperPagination, Slide } from 'hooper'
import DialogFrame from '@/components/common/DialogsFrame'
import Icon from '@/components/common/Icon'
import MixinProductInLanguage from '@/modules/translation/services/productInLanguage'

export default {
  name: 'ProductPageDialog',
  components: {
    Icon,
    Hooper,
    Slide,
    HooperPagination,
    DialogFrame
  },
  mixins: [
    MixinProductInLanguage
  ],
  data: () => {
    return {
      targetDialog: 'productPage',
      product: null,
      productDetails: [],
      cannotClose: false,
      notAllowedProperties: ['softness', 'foot_traffic']
    }
  },
  watch: {
    product: {
      handler: function (n, o) {
        this.productDetails = []
        if (n !== null) {
          this.prepareFields(this.product.original.details, '')
        }
      }
    }
  },
  mounted: function () {
    this.$events.on(editorEventTypes.PRODUCT_SHOW_PAGE, (payload) => {
      this.product = payload
    })
  },
  methods: {
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    prepareFields (values, path, keys = []) {
      for (const [k, v] of Object.entries(values)) {
        if (!this.notAllowedProperties.includes(k) && v !== null) {
          if (typeof v !== 'object') {
            this.productDetails.push({
              key: path.replace('.', '_') + '_' + k,
              label: this.$t(`dialogs.product_page.details${path}.${k}`),
              value: this.mx_product_in_language(this.product.original, ['details', ...keys, k])
            })
          } else {
            this.prepareFields(v, `${path}.${k}`, [k])
          }
        }
      }
    }
  }
}
</script>
