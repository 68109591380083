import * as types from '@/vuex/mutation-types'
import config from '@/config'
import { withHistoryMutation } from '../../history-helper'
import devlog from '@/helper/devlog'

/**
 * Returns lowest layer order (should be 1)
 * @param state
 * @returns {number} orderNumber
 */
const minLayerOrderNumber = (state) => {
  let orderNumber = 1

  state.layers.forEach(layer => {
    orderNumber = (layer.order < orderNumber) ? layer.order : orderNumber
  })

  return orderNumber
}

/**
 * Returns highest layer order
 * @param state
 * @returns {number} orderNumber
 */
const maxLayerOrderNumber = (state) => {
  let orderNumber = 0

  state.layers.forEach(layer => {
    orderNumber = (layer.order > orderNumber) ? layer.order : orderNumber
  })

  return orderNumber
}

/**
 * Returns layer which contains object given by id
 * @param state
 * @param id
 * @returns {Layer} layer object from state.layers array
 */
const findLayerByObject = (state, id) => {
  return state.layers.find(layer => {
    return layer.object === id
  })
}

/**
 * Helper function logging all layers (can be used after sort, delete, etc)
 * @param state
 */
const logAllLayers = (state) => {
  state.layers.forEach(layer => {
    // const currentLayer = Object.assign({}, layer)
    // devlog.log('%cLayer: %s, Order: %s, %cobject: %s', 'color: yellow; background-color: #222', currentLayer.id, currentLayer.order, '', currentLayer.object)
  })
}

const state = {
  layers: [],
  id: 0
}

const getters = {
  /**
     * Returns true if some of selected objects is in background (is the last one in layers array)
     * @param state
     * @param id selected object or array of such ones
     * @returns {function(*=): boolean} isInBackground
     */
  isObjectInBackground: (state) => (id) => {
    id = Array.isArray(id) ? id : [id]
    let isInBackground = false

    id.forEach(item => {
      if (findLayerByObject(state, item).order === minLayerOrderNumber(state)) {
        isInBackground = true
      }
    })
    return isInBackground
  },
  /**
     * Returns true if some of selected objects is in foreground (is the last one in layers array)
     * @param state
     * @param id selected object or array of such ones
     * @returns {function(*=): boolean} isInForeground
     */
  isObjectInForeground: (state) => (id) => {
    id = Array.isArray(id) ? id : [id]
    let isInForeground = false

    id.forEach(item => {
      if (findLayerByObject(state, item).order === maxLayerOrderNumber(state)) {
        isInForeground = true
      }
    })
    return isInForeground
  }
}

const actions = {
  /**
     * Adds a new layer for given object and connects them
     * @param state
     * @param dispatch
     * @param commit
     * @param {String|Number} obj contains single id of an object
     */
  addNewObjectToLayer ({ state, dispatch, commit }, obj) {
    const id = (typeof obj === 'string') ? obj : config.objects.ID + obj
    const layerID = state.id + 1
    const layerOrder = maxLayerOrderNumber(state) + 1

    const layer = {
      id: layerID,
      order: layerOrder,
      object: id
    }

    commit(types.ADD_LAYER, layer)
    commit(types.UPDATE_LAST_LAYER_ID, layerID)
    dispatch('project/objects/initLayer', { obj: id, layer: layerID, order: layerOrder, position: 0 }, { root: true })
  },
  /**
     * Removes one or more layer by object ID
     * @param state
     * @param dispatch
     * @param commit
     * @param {String|[String]} payload contains single id of an object or an array of such ones
     */
  removeLayer ({ state, dispatch, commit }, payload) {
    payload = Array.isArray(payload) ? payload : [payload]

    // devlog.log('Removing layers', payload)

    const topLayer = maxLayerOrderNumber(state)

    dispatch('project/layers/objectUp', payload, { root: true })
    commit(types.REMOVE_LAYER, { index: topLayer - payload.length, deleteCount: payload.length })

    // devlog.log('Layers after delete:')
    logAllLayers(state)
  },

  /**
     * Sends one or more layers to the very top of an array
     * @param state
     * @param dispatch
     * @param commit
     * @param {String|[String]} payload contains single id of an object or an array of such ones
     */
  objectUp ({ state, dispatch, commit }, payload) {
    payload = Array.isArray(payload) ? payload : [payload]

    const layersToMove = [] // local array of layers that should be moved
    payload.forEach(objId => layersToMove.push(findLayerByObject(state, objId)))

    layersToMove.sort((a, b) => { // sort them to be sure they will stay in initial order after operation
      return a.order - b.order
    })

    layersToMove.map(item => item.id) // take only indexes of layers from our local array
      .forEach(layerId => { // for every of those layers
        const movedLayer = state.layers.filter(layer => layer.id === layerId)[0] // find it in state anew to make sure that we have actual state (not an old copy)
        const layers = []

        // devlog.log('movedLayer: ', movedLayer)

        state.layers.forEach(stateLayer => {
          const currentLayer = Object.assign({}, stateLayer)

          if (currentLayer.order >= movedLayer.order) { // if moved currentLayer lays lower than movedLayer it can just stay where it is
            currentLayer.order = (currentLayer.order === movedLayer.order) ? maxLayerOrderNumber(state) : currentLayer.order - 1 // if it's our layer (is equal) it goes to top, otherwise one step down
          }
          dispatch('project/objects/updateObjectOrder', {
            obj: currentLayer.object,
            layer: currentLayer.id,
            order: currentLayer.order,
            position: 0
          }, { root: true })
          layers.push(currentLayer)
        })
        commit(types.UPDATE_ALL_LAYERS, layers)
      })
  },
  /**
     * Sends one or more layers to the very bottom of an array
     * @param state
     * @param dispatch
     * @param commit
     * @param {String|[String]} payload contains single id of an object or an array of such ones
     */
  objectDown ({ state, dispatch, commit }, payload) {
    payload = Array.isArray(payload) ? payload : [payload]

    const layersToMove = [] // local array of layers that should be moved
    payload.forEach(objId => layersToMove.push(findLayerByObject(state, objId)))

    layersToMove.sort((a, b) => { // sort them to be sure they will stay in initial order after operation
      return b.order - a.order
    })

    layersToMove.map(item => item.id) // take only indexes of layers from our local array
      .forEach(layerId => { // for every of those layers
        const movedLayer = state.layers.filter(layer => layer.id === layerId)[0] // find it in state anew to make sure that we have actual state (not an old copy)
        const layers = []

        devlog.log('movedLayer: ', movedLayer)

        state.layers.forEach(stateLayer => {
          const currentLayer = Object.assign({}, stateLayer)

          if (currentLayer.order <= movedLayer.order) { // if moved currentLayer lays higher than movedLayer it can just stay where it is
            currentLayer.order = (currentLayer.order === movedLayer.order) ? minLayerOrderNumber(state) : currentLayer.order + 1 // if it's our layer (is equal) it goes to bottom, otherwise one step up
          }
          dispatch('project/objects/updateObjectOrder', {
            obj: currentLayer.object,
            layer: currentLayer.id,
            order: currentLayer.order,
            position: 0
          }, { root: true })
          layers.push(currentLayer)
        })
        commit(types.UPDATE_ALL_LAYERS, layers)
      })
  },

  /**
     * Moves one or more layers one step(position) up in layers array
     * @param state
     * @param dispatch
     * @param commit
     * @param {String|[String]} payload contains single id of an object or an array of such ones
     */
  objectStepUp ({ state, dispatch, commit }, payload) {
    payload = Array.isArray(payload) ? payload : [payload]

    const layersToMove = [] // local array of layers that should be moved
    payload.forEach(objId => layersToMove.push(findLayerByObject(state, objId)))

    layersToMove.sort((a, b) => { // sort them to be sure they will stay in initial order after operation
      return b.order - a.order
    })

    devlog.log('layersToMove: ', layersToMove)

    layersToMove.map(item => item.id) // take only indexes of layers from our local array
      .forEach(layerId => { // for every of those layers
        const upLayer = Object.assign({}, state.layers.filter(layer => layer.id === layerId)[0]) // find it in state anew to make sure that we have actual state (not an old copy)
        const indexOf = state.layers.findIndex(layer => {
          return layer.id === layerId
        })
        const downLayer = Object.assign({}, state.layers[indexOf + 1])

        devlog.log('movedLayer: ', upLayer)

        upLayer.order += 1
        downLayer.order -= 1
        dispatch('project/objects/updateObjectOrder', {
          obj: upLayer.object,
          layer: upLayer.id,
          order: upLayer.order,
          position: 0
        }, { root: true })
        dispatch('project/objects/updateObjectOrder', {
          obj: downLayer.object,
          layer: downLayer.id,
          order: downLayer.order,
          position: 0
        }, { root: true })
        commit(types.UPDATE_LAYER, { layer: upLayer, index: indexOf })
        commit(types.UPDATE_LAYER, { layer: downLayer, index: indexOf + 1 })

        logAllLayers(state)
      })
  },
  /**
     * Moves one or more layers one step(position) down in layers array
     * @param state
     * @param dispatch
     * @param commit
     * @param {String|[String]} payload contains single id of an object or an array of such ones
     */
  objectStepDown ({ state, dispatch, commit }, payload) {
    payload = Array.isArray(payload) ? payload : [payload]

    const layersToMove = [] // local array of layers that should be moved
    payload.forEach(objId => layersToMove.push(findLayerByObject(state, objId)))

    layersToMove.sort((a, b) => { // sort them to be sure they will stay in initial order after operation
      return a.order - b.order
    })

    devlog.log('layersToMove: ', layersToMove)

    layersToMove.map(item => item.id) // take only indexes of layers from our local array
      .forEach(layerId => { // for every of those layers
        const downLayer = Object.assign({}, state.layers.filter(layer => layer.id === layerId)[0]) // find it in state anew to make sure that we have actual state (not an old copy)
        const indexOf = state.layers.findIndex(layer => {
          return layer.id === layerId
        })
        const upLayer = Object.assign({}, state.layers[indexOf - 1])

        devlog.log('movedLayer: ', downLayer)

        downLayer.order -= 1
        upLayer.order += 1
        dispatch('project/objects/updateObjectOrder', {
          obj: downLayer.object,
          layer: downLayer.id,
          order: downLayer.order,
          position: 0
        }, { root: true })
        dispatch('project/objects/updateObjectOrder', {
          obj: upLayer.object,
          layer: upLayer.id,
          order: upLayer.order,
          position: 0
        }, { root: true })
        commit(types.UPDATE_LAYER, { layer: downLayer, index: indexOf })
        commit(types.UPDATE_LAYER, { layer: upLayer, index: indexOf - 1 })

        logAllLayers(state)
      })
  }
}

const mutations = withHistoryMutation({
  [types.ADD_LAYER] (state, layer) {
    state.layers.push(layer)
  },
  [types.REMOVE_LAYER] (state, payload) {
    state.layers.splice(payload.index, payload.deleteCount)
  },
  [types.UPDATE_LAST_LAYER_ID] (state, id) {
    state.id = id
  },
  [types.UPDATE_ALL_LAYERS] (state, layers) {
    layers.sort((a, b) => a.order - b.order)
    state.layers = layers

    logAllLayers(state)
  },
  [types.UPDATE_LAYER] (state, payload) {
    state.layers[payload.index] = payload.layer
    const layers = Object.assign([], state.layers)
    layers.sort((a, b) => a.order - b.order)
    state.layers = layers
  }
})

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
