<template>
  <dialog-frame
    :cannot-close="cannotClose"
    :subtitle=" $t('dialogs.createGroup.subtitle') "
    :target-dialog="targetDialog"
    :title=" $t('dialogs.createGroup.title') "
    @abort-button-pressed="close"
  >
    <template #header />

    <template #content>
      <div class="c-dialog__instruction c-dialog__instruction--no-center">
        <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
          <div class="c-dialog__instruction-col">
            <file-upload
              id="create-group-logo"
              :accept="'image/png, image/jpeg'"
              :label-default-message="$t('dialogs.createGroup.imageFrame.dropCompanyLogo')"
              :file-max-size="config.files.single.backend"
              style-drop-area-height="200px"
              style-drop-area-width="200px"
              @change="prepareFile"
              @register="mx_validate_registerInputsInErrorsArray"
            />
          </div>
          <div class="c-dialog__instruction-col u-text-center">
            <form class="c-form">
              <input-field
                id="create-group-name"
                v-model="group.address.name"
                :focus-on-mount="true"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-group-address-website"
                v-model="group.address.website"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.website')"
                :required="true"
                :validate="(v) => {return mx_validate_validateURL(v)}"
                classes="u-mt-size-40"
                placeholder="https://google.com"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-group-address-street"
                v-model="group.address.street"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.address.street')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-group-address-city"
                v-model="group.address.city"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.address.city')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-group-address-zip"
                v-model="group.address.zip"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.address.zip')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-group-address-state"
                v-model="group.address.state"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.address.state')"
                :validate="(v) => {return true}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-field
                id="create-group-address-country"
                v-model="group.address.country"
                :is-store-mode="false"
                :label="$t('dialogs.createGroup.address.country')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <drop-down
                v-if="isAdmin"
                id="create-group-measurement"
                v-model="group.measurement"
                :current-item-allowed-signs="15"
                :items="config.measureSystem"
                :label="$t('dialogs.createGroup.measure')"
                :required="true"
                classes="u-mt-size-standard"
                label-key="label"
                style-current-item-width="240px"
                value-key="label"
              />
            </form>
          </div>
        </div>
      </div>
      <div class="c-dialog__confirm">
        <div class="c-dialog__confirm-child">
          <button-base
            id="create-group-cancel"
            :label="$t('common.cancel')"
            :on-click-action="() => { return close() }"
            style-color="gray"
          />
          <button-base
            id="create-group-ok"
            :disabled="mx_validate_isConfirmBlocked"
            :label="$t('common.ok')"
            :on-click-action="() => { return createNewGroup() }"
          />
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import config from '@/config'
import { mapActions, mapGetters } from 'vuex'
import { apiMethods, roles } from '@/helper/apiHelper'

import MixinDialog from '@/mixins/dialogs'
import MixinValidate from '@/mixins/validate'
import MixinFile from '@/mixins/file'

import DialogFrame from '@/components/common/DialogsFrame'
import InputField from '@/components/common/InputField'
import FileUpload from '@/components/common/FileUpload'
import ButtonBase from '@/components/common/ButtonBase'
import DropDown from '@/components/common/DropDown'

/**
 * @displayName Dialog Create Group
 */
export default {
  name: 'CreateGroup',
  components: {
    FileUpload,
    DropDown,
    ButtonBase,
    DialogFrame,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinValidate,
    MixinFile
  ],
  data: () => {
    return {
      config: config,
      group: apiMethods.getModel('group'),
      targetDialog: 'createGroup',
      cannotClose: false
    }
  },
  computed: {
    ...mapGetters({
      getUserRole: 'backendAPI/user/getRole'
    }),
    isAdmin () {
      return this.getUserRole === roles.admin
    }
  },
  methods: {
    ...mapActions({
      createGroup: 'backendAPI/group/create'
    }),
    prepareFile (e) {
      this.mx_validate_updateErrorsArray(e)
      this.mx_file_file = e.value
    },
    async createNewGroup () {
      this.group.name = this.group.address.name
      await this.createGroup({ group: this.group, fileCallback: this.mx_file_doMediaRequests })
      this.close()
    },
    close () {
      this.group = apiMethods.getModel('group')
      this.mx_file_clearData()
      this.mx_dialogs_closeDialog()
    }
  }
}
</script>
