const devlog = {
  log (...params) {
    if (JSON.parse(process.env.VUE_APP_DEVLOG)) {
      console.log(...params)
    }
  },
  error (...params) {
    if (JSON.parse(process.env.VUE_APP_DEVLOG)) {
      console.error(...params)
    }
  },
  info (...params) {
    if (JSON.parse(process.env.VUE_APP_DEVLOG)) {
      console.info(...params)
    }
  }
}

export default devlog
