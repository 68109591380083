<template>
  <div class="c-nav-sub">
    <div
      v-if="!subNavDialogVisible && !dialogVisible"
      class="c-nav-sub__container"
    >
      <!-- Show always -->

      <default-pan />

      <!-- One object selected -->

      <duplicate-pan />

      <!-- Two objects selected -->

      <operations-pan />

      <!-- One object selected -->

      <collar-pan />

      <!-- One or more objects selected -->

      <template-pan />

      <!-- Order options - always visible -->

      <order-pan />

      <!-- Delete button - move mode -->

      <delete-pan />

      <!-- Point (Circle) options - one point in edit mode selected -->

      <circle-handle-pan />

      <!-- Products options - object with product selected -->

      <products-pan />

      <!-- Checkout - minimum one object with product existing -->

      <checkout-pan />
    </div>

    <!-- Dialogs -->

    <div
      v-if="subNavDialogVisible"
      class="c-nav-sub__container"
    >
      <sub-nav-measurement-settings-dialog />
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

import SubNavMeasurementSettingsDialog from '@/components/subnav/dialogs/MeasurementSettings'

import DefaultPan from '@/components/subnav/DefaultPan.vue'
import DuplicatePan from '@/components/subnav/DuplicatePan.vue'
import OperationsPan from '@/components/subnav/OperationsPan.vue'
import DeletePan from '@/components/subnav/DeletePan.vue'
import OrderPan from '@/components/subnav/OrderPan'
import CircleHandlePan from '@/components/subnav/CircleHandlePan'
import ProductsPan from '@/components/subnav/ProductsPan'
import CheckoutPan from '@/components/subnav/CheckoutPan'
import TemplatePan from '@/components/subnav/TemplatePan'
import CollarPan from '@/components/subnav/CollarPan'

export default {
  name: 'SubNav',
  components: {
    SubNavMeasurementSettingsDialog,
    DefaultPan,
    DuplicatePan,
    DeletePan,
    OrderPan,
    CircleHandlePan,
    OperationsPan,
    ProductsPan,
    CheckoutPan,
    TemplatePan,
    CollarPan
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters({
      subNavDialogVisible: 'navigation/dialogVisible',
      dialogVisible: 'dialogs/dialogVisible'
    })
  }
}
</script>
