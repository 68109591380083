<template>
  <dialog-frame
    :targetDialog="targetDialog"
    :title=" $t('dialogs.templates.create.title') "
    :subtitle=" $t('dialogs.templates.create.subtitle') "
  >
    <template #content>
      <div class="c-dialog__instruction u-p-size-0">
        <div class="c-dialog__instruction c-dialog__instruction--no-center">
          <div class="o-grid o-grid--center c-dialog__instruction--no-wrap">
            <div class="c-dialog__instruction-col">
              <div
                class="c-templates__template-preview"
                v-html="svg"
              />
            </div>
            <div class="c-dialog__instruction-col u-text-center">
              <input-field
                id="create-template-name"
                v-model="form.name"
                :is-store-mode="false"
                :label="$t('dialogs.templates.form.name')"
                :required="true"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                classes="u-mt-size-0"
                style-input-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <textarea-field
                id="create-template-description"
                v-model="form.description"
                :is-store-mode="false"
                :label="$t('dialogs.templates.form.description')"
                :required="true"
                :rows="2"
                :validate="(v) => {return mx_validate_validateNotEmpty(v)}"
                style-textarea-width="240px"
                type="text"
                @change="mx_validate_updateErrorsArray"
                @register="mx_validate_registerInputsInErrorsArray"
              />
              <input-checkbox
                id="create-template-public"
                v-model="form.public"
                :is-store-mode="false"
                :label="$t('dialogs.templates.form.public')"
                style-component="1"
              />
              <input-field
                id="create-template-width"
                v-model="currentWidth"
                :disabled="true"
                :is-store-mode="false"
                :label="$t('dialogs.templates.form.width')"
                :suffix="$settings.lengthUnit"
                classes="u-mt-size-20"
                style-input-width="240px"
                type="text"
              />
              <input-field
                id="create-template-height"
                v-model="currentHeight"
                :disabled="true"
                :is-store-mode="false"
                :label="$t('dialogs.templates.form.height')"
                :suffix="$settings.lengthUnit"
                style-input-width="240px"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="c-dialog__confirm">
          <div class="c-dialog__confirm-child u-jc-end">
            <button-base
              id="create-template-save"
              :disabled="mx_validate_isConfirmBlocked"
              :label="$t('dialogs.templates.form.save')"
              :on-click-action="() => { return saveAsTemplate() }"
            />
          </div>
        </div>
      </div>
    </template>
  </dialog-frame>
</template>

<script>
import config from '@/config'
import Paper from 'paper'

import { mapState, mapActions, mapGetters } from 'vuex'
import { apiMethods } from '@/helper/apiHelper'

import DialogFrame from '@/components/common/DialogsFrame'

import MixinDialog from '@/mixins/dialogs'
import MixinMath from '@/mixins/math'
import InputCheckbox from '@/components/common/InputCheckbox'
import ButtonBase from '@/components/common/ButtonBase'
import InputField from '@/components/common/InputField'
import MixinValidate from '@/mixins/validate'
import TextareaField from '@/components/common/TextareaField'

export default {
  name: 'TemplatesDialog',
  components: {
    TextareaField,
    DialogFrame,
    InputCheckbox,
    ButtonBase,
    InputField
  },
  mixins: [
    MixinDialog,
    MixinMath,
    MixinValidate
  ],
  data: () => {
    return {
      targetDialog: 'addTemplate',
      objects: [],
      form: apiMethods.getModel('template'),
      svg: null,
      paperScope: null,
      pathData: []
    }
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    }),
    ...mapGetters({
      getObject: 'project/objects/getById'
    }),
    currentWidth () {
      return this.mx_math_roundDecimal(this.mx_math_getCl(this.form.width, this.measure))
    },
    currentHeight () {
      return this.mx_math_roundDecimal(this.mx_math_getCl(this.form.height, this.measure))
    }
  },
  mounted () {
    const props = this.mx_dialogs_getDialogProps(this.targetDialog)
    if (Object.prototype.hasOwnProperty.call(props, 'objectIds')) {
      props.objectIds.forEach(id => {
        this.objects.push(this.getObject(id))
      })
      this.init()
    }
  },
  methods: {
    ...mapActions({
      addTemplate: 'template/add'
    }),
    saveAsTemplate () {
      this.addTemplate(this.form)
      this.mx_dialogs_closeDialog()
    },
    init () {
      const objectsGroup = this.$_init_selected_objects()

      this.form.width = objectsGroup.bounds.width
      this.form.height = objectsGroup.bounds.height

      const templateScope = new Paper.PaperScope().setup(new Paper.Size(objectsGroup.bounds.width, objectsGroup.bounds.height))

      templateScope.project.activeLayer.addChildren(objectsGroup.getItems())

      this.svg = templateScope.project.exportSVG({
        asString: true
      })

      this.form.template = JSON.stringify(this.pathData)
    },
    $_init_selected_objects () {
      const group = new Paper.Group()

      group.addChildren(this.$_get_paper_objects())
      group.translate(new Paper.Point(
        0 - group.bounds.x,
        0 - group.bounds.y
      ))

      return group
    },
    $_get_paper_objects () {
      const cpObjects = []

      this.objects.forEach((object) => {
        let obj = null
        if (object.type === config.objects.types.CIRCLE) {
          const props = object.getCircleProperties()
          obj = new Paper.CompoundPath(new Paper.Path.Circle({
            center: [props.middle.x, props.middle.y],
            radius: props.radius
          }))
        } else {
          obj = new Paper.CompoundPath(object.d(true))

          if (obj.hasChildren()) {
            object.children.forEach(child => {
              const childPath = new Paper.Path(child.d())
              childPath.fillRule = 'evenodd'
              obj = obj.subtract(childPath)
              childPath.remove()
            })
          }
        }

        this.pathData.push(obj.pathData)
        cpObjects.push(obj)
      })

      return cpObjects.map(obj => {
        obj.fillRule = 'evenodd'
        obj.strokeColor = 'black'
        obj.fillColor = new Paper.Color(0.5, 0.4)

        return obj
      })
    }
  }
}
</script>
