import currency from 'currency.js'
import config from '@/config'

function currencyPlugin (Vue) {
  if (currencyPlugin.installed) return

  const currencyjs = new Vue({
    data: () => {
      return {
        key: ''
      }
    },
    methods: {
      init (value) {
        this.key = value
      },
      convert (value, symbol = false) {
        return currency(value, config.currency[this.key]).format(symbol)
      }
    }
  })

  Object.defineProperty(Vue.prototype, '$currency', {
    get () {
      return currencyjs
    }
  })

  Vue.mixin({})
  Vue.currency = Vue.prototype.$currency
}

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(currencyPlugin)
}

export default currencyPlugin
