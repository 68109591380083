<!-- AddNearestPointToPath.vue -->
<template>
  <g
    v-if="nearestPoint !== null"
    id="tool-add-closest-point-to-path"
  >
    <!--        <line class="c-object__new-point__line" :x1="position.x" :y1="position.y" :x2="nearestPoint.x" :y2="nearestPoint.y" :stroke-width="strokeWidth"-->
    <!--              :stroke-dasharray="strokeDash"/>-->
    <circle
      :cx="nearestPoint.x"
      :cy="nearestPoint.y"
      :r="circleRadius"
      :stroke-width="strokeWidth"
      class="c-object__new-point__circle"
      @mousedown="emitCurrentPosition"
    />
  </g>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'

import MixinMath from '@/mixins/math'

/**
     * Helper to add a new point on a object path
     * @displayName Object tools - Add nearest point to path
     */
export default {
  name: 'AddNearestPointToPath',
  mixins: [MixinMath],
  props: {
    path: {
      type: String,
      required: true
    },
    disable: {
      type: Boolean,
      required: true
    },
    pointsToCheck: {
      type: Array || Object,
      required: false,
      default: null
    }
  },
  data: () => {
    return {
      cp: null,
      nearestPoint: null,
      curveLocation: null,
      position: null,
      maxDistance: 5
    }
  },
  computed: {
    ...mapState({
      commonSvgSettings: state => state.common.svg
    }),
    circleRadius () {
      return this.mx_math_handleElementsOnScale(5)
    },
    strokeWidth () {
      return this.mx_math_handleElementsOnScale(this.commonSvgSettings.lineStrokeWidth)
    },
    strokeDash () {
      return this.mx_math_handleElementsOnScale(4)
    }
  },
  watch: {
    path (nPath, oPath) {
      this.updateCurrentPath(nPath)
    },
    curveLocation (nCurveLocation, oCurveLocation) {
      this.$emit('locationChanged', nCurveLocation)
    }
  },
  mounted () {
    this.updateCurrentPath(this.path)
    window.addEventListener('mousemove', this.updateCurrentMousePoint)
  },
  beforeDestroy () {
    window.removeEventListener('mousemove', this.updateCurrentMousePoint)
  },
  methods: {
    /**
             * Update the current path
             * @param path
             * @public
             */
    updateCurrentPath (path) {
      this.cp = this.$paper.compoundPath(path)
    },
    /**
             * Event handler
             * Update current mouse point
             * @param evt
             * @private
             */
    updateCurrentMousePoint (evt) {
      if (!this.disable) {
        // current mouse position and nearest point on path
        const point = this.mx_math_getCoordinatesFromEvent(evt)
        const nearestPoint = this.cp.getNearestPoint(point)

        // distance between point and nearest point
        const distance = this.mx_math_getDistance(point, nearestPoint)

        let showNearestPoint = false

        if (distance <= this.mx_math_handleElementsOnScale(this.maxDistance)) {
          let curveLocation = null
          const tolerance = this.mx_math_handleElementsOnScale(6)
          this.cp.children.filter(child => {
            const offset = child.getOffsetOf(nearestPoint)
            const pathLocation = child.getLocationAt(offset)

            if (pathLocation !== null) {
              curveLocation = {
                point1: pathLocation.curve.point1,
                point2: pathLocation.curve.point2
              }

              if (!_.isEqual(this.curveLocation, curveLocation)) {
                this.curveLocation = curveLocation
              }
            }
          })
          if (this.pointsToCheck !== null) {
            showNearestPoint = true
            this.pointsToCheck.forEach(ptc => {
              if (nearestPoint.isClose(ptc, tolerance)) {
                showNearestPoint = false
              }
            })
          } else {
            showNearestPoint = !(nearestPoint.isClose(curveLocation.point1, tolerance) || nearestPoint.isClose(curveLocation.point2, tolerance))
          }
        }

        this.nearestPoint = (showNearestPoint) ? nearestPoint : null
        this.position = (showNearestPoint) ? point : null

        if (!showNearestPoint) {
          this.curveLocation = null
        }
      } else {
        this.nearestPoint = null
        this.position = null
      }
    },
    /**
             * Get called by mouse click
             * Emit current position to parent
             * @private
             */
    emitCurrentPosition () {
      this.$emit('addNewPoint', { nearestPoint: this.nearestPoint, location: this.curveLocation })
      this.disable = false
      this.nearestPoint = null
      this.position = null
    }
  }
}
</script>
