<template>
  <div
    id="cSidebar"
    :class="{'c-sidebar--closed': open !== true, 'c-sidebar--hidden': shouldBlockGui}"
    class="c-sidebar"
  >
    <div
      :class="{'c-sidebar__head--closed': (open !== true)}"
      class="c-sidebar__head"
      @click="toggleSidebar"
    >
      <icon classes="fas fa-angle-double-left" />
      <h4 class="c-sidebar__head-title">
        {{ $t('editor.sidebar.products.title') }}
      </h4>
    </div>
    <div
      :class="{'c-sidebar__group--closed': (open !== true)}"
      class="c-sidebar__group"
    >
      <!-- Tooltip -->
      <v-popover
        :disabled="firstProductAdded || !showTooltip || ctrl !== 'move' || shouldBlockGui"
        :popover-inner-class="['popover-inner', 'u-p-size-0']"
        placement="left-end"
      >
        <!-- This will be the popover target (for the events and position) -->

        <!--Products layer manager-->
        <div
          class="c-sidebar__item"
          @mouseleave="showTooltip = false"
          @mouseover="showTooltip = true"
        >
          <!-- Product info -->
          <div
            v-if="info && ctrl === 'move' && !shouldBlockGui"
            class="c-sidebar__product-info"
          >
            <h4 class="c-typo__h4 u-mt-size-0">
              {{ $t('editor.sidebar.products.details')
              }}
            </h4>
            <div class="c-sidebar__product-info-container">
              <span>{{ $t('editor.sidebar.products.width') }}</span> <span>{{ $units.convert(info.overlay.roll_width) }} {{ $settings.turfUnits.width }}</span>
              <span>{{ $t('editor.sidebar.products.length') }}</span> <span>{{ $units.convert(info.overlay.roll_length) }} {{ $settings.turfUnits.length }}</span>
              <span>{{ $t('editor.sidebar.products.price') }}</span> <span>{{ $world.currencyConvert(info.overlay.price_each) }} {{ $world.getCurrencySymbol() }}</span>
              <span>{{ $t('editor.sidebar.products.category') }}</span> <span>{{ info.original.category }}</span>
            </div>
          </div>

          <!-- Category switcher -->
          <div
            v-if="currentCategory !== null"
            class="c-sidebar__product-categories"
          >
            <div
              class="c-sidebar__product-categories-arrow c-sidebar__product-categories-arrow--left"
              @click="changeCategory(-1)"
            />
            <div class="c-sidebar__product-categories-name">
              {{ categoriesArray[currentCategory] }}
            </div>
            <div
              class="c-sidebar__product-categories-arrow c-sidebar__product-categories-arrow--right"
              @click="changeCategory(+1)"
            />
          </div>

          <!-- Products -->
          <div
            v-if="currentCategory !== null"
            class="c-sidebar__product-container"
          >
            <template v-if="products">
              <sidebar-product
                v-for="(product, index) in products"
                :key="`sidebar-drag-${index}`"
                :product="product"
                @sidebar-product-infos="getProductInfos"
                @stop-product-tooltip="hideHelpInfos"
              />
            </template>
          </div>
        </div>

        <!--This will be the content of the popover -->
        <template #popover>
          <products-anim />
        </template>
      </v-popover>

      <!-- Future layer manager -->
      <!--<div class="c-sidebar__item"></div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SidebarProduct from '@/components/editor/sidebar/Product.vue'

import ProductsAnim from '@/components/subnav/tooltips/ProductsAnim'
import Icon from '@/components/common/Icon'

export default {
  name: 'Sidebar',
  components: {
    Icon,
    SidebarProduct,
    ProductsAnim
  },
  data () {
    return {
      products: null,
      open: false,
      info: null,
      currentCategory: null,
      categoriesArray: [],
      showTooltip: false
    }
  },
  computed: {
    ...mapState({
      categories: state => state.products.categories,
      ctrl: state => state.events.ctrl,
      firstProductAdded: state => state.dialogs.showedOnce.firstProductAdded,
      objectsArray: state => state.project.objects.objects
    }),
    ...mapGetters({
      getProductsByCategory: 'products/getProductsByCategory',
      shouldBlockGui: 'events/shouldBlockGui',
      hasProducts: 'products/hasProducts'
    }),
    filterProducts () {
      return this.getProductsByCategory(this.categoriesArray[this.currentCategory])
    }
  },
  watch: {
    objectsArray (n, o) {
      if (n.length === 1) {
        if (n[0].id === 'object-1') {
          this.open = true
        }
      }
    }
  },
  mounted () {
    /* init categoriesArray for 'slider' */
    Object.keys(this.categories).forEach(category => this.categoriesArray.push(this.categories[category]))
    if (this.categoriesArray.length !== 0) {
      this.currentCategory = 0

      /* init currentCategory and products by filter */
      this.products = this.filterProducts
    }
  },
  methods: {
    getProductInfos (payload) {
      this.info = payload
    },
    changeCategory (payload) {
      this.currentCategory += payload
      if (this.currentCategory < 0) this.currentCategory = this.categoriesArray.length - 1
      if (this.currentCategory >= this.categoriesArray.length) this.currentCategory = 0
      this.products = this.filterProducts
    },
    hideHelpInfos () {
      this.showTooltip = false
      this.showTooltip = false
    },

    /* ------------------------------------------
            *  Helpers
            *------------------------------------------ */

    toggleSidebar () {
      this.open = !this.open
    }
  }
}
</script>
