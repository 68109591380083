import * as mutationTypes from './mutation-types'

const checkCalculatedObjects = (state) => {
  const objects = state.project.objects.objects
  const calculatedObjects = objects.filter(item => item.productNeedUpdate === false)
  return calculatedObjects.length && calculatedObjects.length === objects.length ? 1 : 0
}

export default (state, store) => {
  // watch object changes and set project to calculated
  const calculatedObjectStatus = checkCalculatedObjects(state)
  store.commit('project/' + mutationTypes.PROJECT_SET_BACKEND_META, { status: calculatedObjectStatus })
}
