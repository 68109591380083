<template>
  <svg
    id="handles-quadratic-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-b1"
      d="M28.08,17.66a1.42,1.42,0,0,1-.74-.2L23,15a1.5,1.5,0,0,1,1.48-2.61l4.35,2.46a1.5,1.5,0,0,1,.56,2A1.48,1.48,0,0,1,28.08,17.66Zm-9.57-5.43a1.51,1.51,0,0,1-.74-.19L13.42,9.57A1.5,1.5,0,0,1,14.9,7l4.35,2.47a1.5,1.5,0,0,1-.74,2.8Z"
    />
    <path
      class="u-fill-b1"
      d="M14.18,33.2a1.5,1.5,0,0,1-.74-2.81l4.36-2.45a1.5,1.5,0,1,1,1.47,2.62L14.91,33A1.39,1.39,0,0,1,14.18,33.2Zm9.59-5.39A1.5,1.5,0,0,1,23,25l4.36-2.44a1.5,1.5,0,1,1,1.46,2.61L24.5,27.62A1.47,1.47,0,0,1,23.77,27.81Z"
    />
    <path
      class="u-fill-w"
      d="M6.2,37.91,4.26,35.62c5-4.2,7.76-9.64,7.83-15.31S9.37,8.84,4.23,4.29L6.22,2c5.8,5.14,9,11.64,8.87,18.31S11.85,33.14,6.2,37.91Z"
    />
    <circle
      class="u-fill-w"
      cx="6.23"
      cy="35.77"
      r="4"
    />
    <circle
      class="u-fill-w"
      cx="6.23"
      cy="4.27"
      r="4"
    />
    <circle
      class="u-fill-b1"
      cx="35.77"
      cy="19.97"
      r="4"
    />
  </svg>
</template>

<script>
export default {
  name: 'HandlesQuadraticIcon'
}
</script>
