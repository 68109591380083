var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.getClients,"pagination-options":{
      enabled: true,
      perPage: 20,
      perPageDropdown: [10, 20, 50],
      nextLabel: _vm.texts.nextLabel,
      prevLabel: _vm.texts.prevLabel,
      rowsPerPageLabel: _vm.texts.rowsPerPageLabel,
      ofLabel: _vm.texts.ofLabel,
      pageLabel: _vm.texts.pageLabel, // for 'pages' mode
      allLabel: _vm.texts.allLabel,
    },"search-options":{
      enabled: true,
      skipDiacritics: true,
      placeholder: _vm.texts.searchLabel
    },"sort-options":{
      enabled: true,
      initialSortBy: [{field: 'name', type: 'asc'}]
    },"theme":"melos"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field ==='settings')?_c('span',{staticClass:"vgt-button-wrapper"},[_c('button-base',{attrs:{"id":`api-client-edit-${props.row.originalIndex}`,"on-click-action":() => { return _vm.editApiClient(props.row)},"tooltip-disabled":false,"classes":"vgt-button","icon-classes":"fas fa-user-edit"},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.edit'))+" ")]},proxy:true}],null,true)}),_c('button-base',{attrs:{"id":`api-client-refresh-key-${props.row.originalIndex}`,"on-click-action":() => { return _vm.createNewApiKey(props.row.id)},"tooltip-disabled":false,"classes":"vgt-button","icon-classes":"fas fa-sync"},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('backend.apiClients.refreshKey'))+" ")]},proxy:true}],null,true)}),_c('button-base',{attrs:{"id":`api-client-delete-${props.row.originalIndex}`,"on-click-action":() => { return _vm.deleteApiClient(props.row)},"tooltip-disabled":false,"classes":"vgt-button","icon-classes":"fa fa-trash"},scopedSlots:_vm._u([{key:"popup",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]},proxy:true}],null,true)})],1):(props.column.field === 'last_used_at')?_c('span',[_vm._v(" "+_vm._s((props.formattedRow[props.column.field]) ? props.formattedRow[props.column.field] : '')+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }