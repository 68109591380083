<template>
  <svg
    id="operations-exclude-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M24.28,4v8.33h-9a3,3,0,0,0-3,3v9H3.93a3,3,0,0,1-3-3V4a3,3,0,0,1,3-3H21.31A3,3,0,0,1,24.28,4Z"
    />
    <rect
      class="u-fill-w"
      x="15.47"
      y="15.79"
      width="23.32"
      height="23.32"
      rx="2.99"
    />
  </svg>
</template>

<script>
export default {
  name: 'OperationsExcludeIcon'
}
</script>
