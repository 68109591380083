<template>
  <div class="c-nav-sub__group">
    <button-head-nav
      id="sub-nav-button-order"
      :classes="['c-nav-sub__submenu-group-container']"
      :disabled="shouldBlockGui"
      :label="$t('subNav.general.file')"
      :theme="getTheme"
      icon-classes="c-nav-sub__icon"
      @mouseenter="showSubmenu.file = true"
      @mouseleave="showSubmenu.file = false"
    >
      <template #icon>
        <icon classes="fas fa-file" />
      </template>
      <template #submenu>
        <transition name="c-nav-sub__submenu-group">
          <div
            v-if="showSubmenu.file && !shouldBlockGui"
            class="c-nav-sub__submenu-group"
          >
            <button
              id="sub-nav-button-save"
              :disabled="blockMenuButtons"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="doUpdateProject()"
            >
              <icon classes="fas fa-2x fa-save" />
              {{ $t('headNav.save') }}
            </button>

            <button
              id="sub-nav-button-templates"
              :disabled="blockMenuButtons"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="openTemplates()"
            >
              <icon classes="c-context-menu__icon">
                <template-icon />
              </icon>
              {{ $t('headNav.template') }}
            </button>

            <button
              id="sub-nav-button-import"
              :disabled="blockMenuButtons"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="openImport()"
            >
              <icon classes="fas fa-2x fa-file-import" />
              {{ $t('headNav.import') }}
            </button>

            <button
              id="sub-nav-button-export"
              :disabled="blockMenuButtons"
              class="c-nav-sub__item c-nav-sub__submenu-item"
              @click="mx_export_project_as()"
            >
              <icon classes="fas fa-2x fa-file-download" />
              {{ $t('headNav.export') }}
            </button>
          </div>
        </transition>
      </template>
    </button-head-nav>
    <button-head-nav
      id="sub-nav-button-undo"
      :disabled="shouldBlockGui || !undoPossible"
      :label="$t('subNav.general.undo')"
      :on-click-action="()=>{return $events.fire(editorEventTypes.PROJECT_UNDO)}"
      :theme="getTheme"
      icon-classes="fas fa-2x fa-undo"
    />
    <button-head-nav
      id="sub-nav-button-redo"
      :disabled="shouldBlockGui || !redoPossible"
      :label="$t('subNav.general.redo')"
      :on-click-action="()=>{return $events.fire(editorEventTypes.PROJECT_REDO)}"
      :theme="getTheme"
      icon-classes="fas fa-2x fa-redo"
    />

    <button-head-nav
      id="sub-nav-button-underlay"
      :category="$t('subNav.categories.measurements')"
      :classes="['u-ml-size-standard']"
      :disabled="shouldBlockGui"
      :label="$t('subNav.measurements.surfacePlan')"
      :on-click-action="()=>{return $events.fire('dialog-add-surface-plan')}"
      :theme="getTheme"
      icon-classes="fas fa-2x fa-map"
    />
    <button-head-nav
      id="sub-nav-button-rescale"
      :category="$t('subNav.categories.measurements')"
      :disabled="shouldBlockGui"
      :label="$t('subNav.measurements.rescale')"
      :on-click-action="()=>{return openMeasurementSettings()}"
      :theme="getTheme"
      icon-classes="fas fa-2x fa-drafting-compass"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import * as editorEventTypes from '@/events/editor-event-types'

import MixinDialogHandler from '@/mixins/dialogHandler'
import MixinExport from '@/mixins/export'

import ButtonHeadNav from '@/components/common/ButtonHeadNav'
import Icon from '@/components/common/Icon'
import TemplateIcon from '@/components/common/icons/templates/TemplateIcon'

export default {
  name: 'DefaultPan',
  components: {
    TemplateIcon,
    Icon,
    ButtonHeadNav
  },
  mixins: [MixinDialogHandler, MixinExport],
  data: () => {
    return {
      showSubmenu: {
        file: false
      },
      editorEventTypes: editorEventTypes
    }
  },
  computed: {
    ...mapState({
      ctrl: state => state.events.ctrl,
      target: state => state.events.target,
      isCutOut: state => state.project.objects.cutOut,
      projectMeta: state => state.project.backendMeta
    }),
    ...mapGetters({
      undoPossible: 'history/undoPossible',
      redoPossible: 'history/redoPossible',
      shouldBlockGui: 'events/shouldBlockGui',
      subNavDialogVisible: 'navigation/dialogVisible',
      dialogVisible: 'dialogs/dialogVisible',
      getTheme: 'gui/getTheme'
    }),
    blockMenuButtons () {
      return this.shouldBlockGui || this.subNavDialogVisible || this.dialogVisible
    }
  },
  mounted () {
    this.$events.on(editorEventTypes.PROJECT_SAVE, this.doUpdateProject)
  },
  methods: {
    ...mapActions({
      updateProject: 'backendAPI/project/updateState'
    }),
    openMeasurementSettings () {
      this.$events.fire('sub-nav-dialog-measurement-settings')
    },
    /**
     * Sends the current project state to the server
     * @returns {Promise<void>}
     */
    doUpdateProject () {
      if (this.projectMeta.id) {
        this.updateProject()
      } else {
        this.$_mx_dialogHandler_openDialog({ dialog: 'createProject', onOff: true })
      }
    },
    openImport () {
      this.$_mx_dialogHandler_openDialog({ dialog: 'importFile', onOff: true })
    },
    openTemplates () {
      this.$_mx_dialogHandler_openDialog({ dialog: 'templates', onOff: true })
    }
  }
}
</script>
