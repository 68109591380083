<template>
  <g
    :id="'pathGroup-' + id"
    :fill="fill"
    stroke-linecap="round"
    stroke="black"
    :stroke-width="strokeWidth"
  >
    <path :d="d" />
    <line
      v-if="line"
      :x1="line.x1"
      :y1="line.y1"
      :x2="line.x2"
      :y2="line.y2"
      :stroke-width="strokeWidth"
    />
    <slot name="angles" />
    <slot name="lengths" />
    <slot name="area-calc" />
  </g>
</template>

<script>
import MixinPoints from '@/mixins/points'
import MixinMath from '@/mixins/math'
import { mapState } from 'vuex'

/**
     * Displays the path in drawing mode
     * @displayName Pseudo path
     */
export default {
  name: 'SvgPath',
  mixins: [MixinPoints, MixinMath],
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    line: {
      type: [Object, Array],
      required: false,
      default: null
    }
  },
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      commonLineStrokeWidth: state => state.common.svg.lineStrokeWidth
    }),
    fill () {
      return 'rgba(255,151,0,0.5)'
    },
    d () {
      let d = ''
      const firstPoint = this.points[0]
      const xy = firstPoint.x + ' ' + firstPoint.y + ' '
      d += 'M ' + xy + firstPoint.type + ' ' + xy + ' '

      for (const point in this.points) {
        if (point === 0) {
          continue
        }
        const _p = this.points[point]
        d += _p.type + ' ' + _p.x + ' ' + _p.y
      }

      return d
    },
    strokeWidth () {
      return this.mx_math_handleElementsOnScale(this.commonLineStrokeWidth)
    }
  }
}
</script>
