<template>
  <svg
    id="draw-circle-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M20,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm0-31.9A14,14,0,1,0,34,20,14,14,0,0,0,20,6.05Z"
    />
    <path
      class="u-fill-w"
      d="M36.53,21.5H20a1.5,1.5,0,0,1,0-3H36.53a1.5,1.5,0,0,1,0,3Z"
    />
    <circle
      class="u-fill-b1"
      cx="20"
      cy="20"
      r="5"
    />
    <circle
      class="u-fill-b1"
      cx="35"
      cy="19.89"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'DrawCircleIcon'
}
</script>
