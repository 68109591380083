<template>
  <div>
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: 20,
        perPageDropdown: [10, 20, 50],
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: texts.pageLabel, // for 'pages' mode
        allLabel: texts.allLabel,
      }"
      :rows="groups"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'address.name', type: 'asc'}, {field: 'address.name', type: 'asc'}]
      }"
      theme="melos"
    >
      <template #emptystate>
        <div
          class="u-m-size-40 c-backend__nothing-to-show"
        >
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open" />
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template
        #table-row="props"
      >
        <span v-if="props.column.field ==='logo'">
          <span
            v-if="props.row.logo !== null"
            :style="`background-image: url('${props.row.logo.thumb}');`"
            class="vgt-icon"
          />
        </span>

        <span v-else-if="props.column.field ==='address.website'">
          <a
            :href="props.formattedRow[props.column.field]"
            target="_blank"
          >{{ props.formattedRow[props.column.field] }}</a>
        </span>

        <!-- Buttons -->
        <span
          v-else-if="props.column.field ==='settings'"
          class="vgt-button-wrapper"
        >
          <button-base
            :id="`group-list-update-group-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'updateGroup', groupData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-edit"
          >
            <template #popup>
              {{ $t('common.edit') }}
            </template>
          </button-base>
          <button-base
            :id="`group-list-delete-group-${props.row.originalIndex}`"
            :on-click-action="() => { return openDialog({type: 'deleteGroup', groupData: props.row}) }"
            :tooltip-disabled="false"
            classes="vgt-button"
            icon-classes="fa fa-trash"
          >
            <template #popup>
              {{ $t('common.delete') }}
            </template>
          </button-base>
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'

import MixinDialogHandler from '@/mixins/dialogHandler'
import ButtonBase from '@/components/common/ButtonBase'
import Icon from '@/components/common/Icon'

/**
 * @displayName Group List Component
 */
export default {
  name: 'GroupList',
  components: {
    Icon,
    ButtonBase,
    VueGoodTable
  },
  mixins: [MixinDialogHandler],
  data () {
    return {
      columns: [
        {
          field: 'settings',
          sortable: false,
          html: true,
          tdClass: 'vgt-button-col-start',
          thClass: 'vgt-no-width vgt-no-min-width vgt-no-padding'
        },
        {
          field: 'logo',
          sortable: false,
          html: true,
          tdClass: 'vgt-icon-container',
          thClass: 'vgt-no-min-width'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.name,
          field: 'address.name'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.website,
          field: 'address.website'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.address.street,
          field: 'address.street'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.address.city,
          field: 'address.city'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.address.zip,
          field: 'address.zip'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.address.state,
          field: 'address.state'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.groups.tableLabel.address.country,
          field: 'address.country'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      groups: 'backendAPI/group/getAll'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    }
  },
  methods: {
    openDialog (payload) {
      this.$_mx_dialogHandler_openDialog(
        { dialog: payload.type, onOff: true, dialogProps: { groupData: payload.groupData } })
    }
  }
}
</script>
