import { mapState } from 'vuex'
import PaperLayer from '@/classes/paperLayer'
import { TurfTrack } from '@/classes/products'
import _ from 'lodash'

/**
 * The algorithm to calculate
 * @displayName Line of sight - Algorithm Helper
 */
export default {
  name: 'AlgorithmHelperMixin',
  data: () => {
    return {}
  },
  computed: {
    ...mapState({
      measure: state => state.project.measurement.measure
    })
  },
  methods: {
    /**
         * only for checkout process
         * @param object
         * @param result
         * @public
         */
    mx_algorithm_helper_calculate_result (object, result) {
      const turfTracks = []

      result.tracks.forEach(child => {
        const points = PaperLayer.makePointsFromPaperSegments(child.segments)
        const dimensions = this.$_mx_algorithm_helper_get_track_dimensions(child, object.product.angle)

        const turfTrack = new TurfTrack(child.name)
        turfTrack.setPoints(points)
        turfTrack.setWidth(dimensions.width)
        turfTrack.setLength(dimensions.length)
        turfTracks.push(turfTrack)
      })

      this.$store.commit('project/objects/OBJECT_UPDATE_PRODUCT_PROPERTIES', {
        object: object.id,
        product: { lastTrackId: result.turfRoll.used - 1, tracks: turfTracks }
      })
    },
    $_mx_algorithm_helper_get_track_dimensions (track, angle) {
      const _track = _.clone(track)
      _track.rotate(-angle)

      return {
        width: this.$_mx_algorithm_helper_get_value_by_measurement(_track.bounds.width).toFixed(2),
        length: this.$_mx_algorithm_helper_get_value_by_measurement(_track.bounds.height).toFixed(2)
      }
    },
    $_mx_algorithm_helper_get_value_by_measurement (value) {
      return parseFloat(((value / this.measure.fromPixels) * this.measure.toUnit).toFixed(2))
    },
    $_mx_algorithm_helper_convert_to_pixels (value) {
      return (value / this.measure.toUnit) * this.measure.fromPixels
    },
    /**
         * Convert width and length unit to pixels
         * @param object
         * @returns {{width: number, length: number}}
         * @public
         */
    mx_algorithm_helper_get_lawn_roll_by_measurement (object) {
      return {
        width: (object.product.width / this.measure.toUnit) * this.measure.fromPixels,
        length: parseFloat(((object.product.length / this.measure.toUnit) * this.measure.fromPixels).toFixed(2))
      }
    },
    /**
         * Returns the scale factor horizontal/vertical
         * @param bounds
         * @param container
         * @returns {{factorY: number, factorX: number}}
         * @public
         */
    mx_algorithm_helper_get_scale_factor (bounds, container) {
      const a = bounds.width
      const b = bounds.height
      // const r = container / 2

      const y = a / b
      const alpha = Math.atan(y)

      const aMax = (Math.sin(alpha) * (container - 90))
      const bMax = aMax / y

      return {
        factorX: parseFloat((aMax / bounds.width).toFixed(5)),
        factorY: parseFloat((bMax / bounds.height).toFixed(5))
      }
    }
  }
}
