import * as types from './../mutation-types'
import config from '@/config'
import Vue from 'vue'
import { withHistoryMutation } from '../history-helper'

const initState = () => {
  return {
    mode: {
      cut: false,
      pan: false,
      los: false
    },
    ctrl: 'move',
    contextMenu: {
      ctrl: null,
      position: null,
      object: null,
      circle: null,
      isCutOut: false
    },
    action: null,
    target: null,
    storedTarget: null,
    currentSelectedObjects: [],
    currentSelectedProducts: [],
    booleanOperations: {
      unite: false,
      subtract: false,
      exclude: false,
      fragment: false
    },
    currentMovingPoint: null,
    currentlyActiveModifier: null,
    inputFocus: false
  }
}

const state = () => initState()

const getters = {
  isObjectSelected: (state) => (object) => {
    return (state.currentSelectedObjects.includes(object))
  },
  isProductSelected: (state) => (product) => {
    return (state.currentSelectedProducts.includes(product))
  },
  isPointMoving: (state) => (point) => {
    return state.currentMovingPoint === point
  },
  selectedObjectsCount: (state) => {
    return state.currentSelectedObjects.length
  },
  shouldBlockGui: (state, getters, rootState) => {
    return state.target !== null ||
            state.mode.cut === true ||
            state.mode.pan === true ||
            state.mode.los === true ||
            state.ctrl === 'refline' ||
            rootState.project.measurement.reference.start !== null
  },
  shouldBlockShortcuts: (state, getters, rootState, rootGetters) => {
    return rootGetters['dialogs/dialogVisible'] ||
            state.inputFocus ||
            state.mode.los === true
  }
}

const actions = {
  setEditorCTRL ({ commit, dispatch }, ctrl) {
    dispatch('pseudo/setKeyboardInput', 0, { root: true })
    commit(types.EVT_CTRL, ctrl)
  },
  addObjectToSelection ({ state, commit }, payload) {
    payload.addToSelection = (state.mode.cut) ? true : payload.addToSelection
    commit(types.EVT_ADD_OBJECT_SELECT, payload)
  },
  clearObjectSelection ({ dispatch, commit }) {
    commit(types.EVT_CLEAR_OBJECT_SELECTION)
    commit(types.EVT_UPDATE_EDITOR_MODE, { cut: false })
    dispatch('project/objects/clearSelectionMode', config.objects.modes.UNSELECTED, { root: true })
  },
  removeProductSelection ({ state, commit }, payload) {
    commit(types.EVT_REMOVE_PRODUCT_FROM_SELECTION, payload)
  },
  resetCurrentActiveModifier ({ state, commit }) {
    commit(types.EVT_RESET_CURRENT_ACTIVE_MODIFIER)
  },
  openContextMenu ({ commit }, payload) {
    commit(types.EVT_OPEN_CONTEXT_MENU, payload)
  },
  toggleLineOfSightMode ({ state, dispatch, commit }) {
    const newState = !state.mode.los
    commit(types.EVT_UPDATE_EDITOR_MODE, { los: newState })
    dispatch('project/objects/setSelectionMode', {
      object: state.currentSelectedObjects[0],
      selectionMode: (newState) ? config.objects.modes.LOS : config.objects.modes.SELECTED
    }, { root: true })

    if (!newState) {
      dispatch('lineOfSightNew/clearState', null, { root: true })
    }
    // if (state.mode.los) {
    //     dispatch('lineOfSight/setDataToWorkWith', object, {root: true})
    // }
  }
}

const mutations = withHistoryMutation({
  [types.EVT_TARGET] (state, target) {
    state.target = target
  },
  [types.EVT_ACTION] (state, action) {
    state.action = action
  },
  [types.EVT_CTRL] (state, ctrl) {
    state.ctrl = ctrl
  },
  [types.EVT_OPEN_CONTEXT_MENU] (state, attrs) {
    state.contextMenu = attrs
  },
  [types.EVT_CLOSE_CONTEXT_MENU] (state) {
    state.contextMenu.ctrl = null
  },
  [types.EVT_CLEAR_OBJECT_SELECTION] (state) {
    if (!state.mode.los) {
      state.currentSelectedObjects = []
      state.currentSelectedProducts = []
      state.booleanOperations = {
        unite: false,
        subtract: false,
        exclude: false,
        fragment: false
      }
    }
  },
  [types.EVT_ADD_OBJECT_SELECT] (state, attrs) {
    if (!state.mode.cut) {
      if (!attrs.addToSelection) {
        state.currentSelectedObjects = []
        state.currentSelectedProducts = []
      }
      if (!state.currentSelectedObjects.includes(attrs.object)) state.currentSelectedObjects.push(attrs.object)
      if (attrs.product) {
        if (!state.currentSelectedProducts.includes(attrs.product)) state.currentSelectedProducts.push(attrs.product)
      }
    } else if (!state.currentSelectedObjects.includes(attrs.object) && attrs.object === config.OBJECT_CUT_ID) {
      state.currentSelectedObjects.push(config.OBJECT_CUT_ID)
    }
  },
  [types.EVT_ADD_CUT_OBJECT_TO_SELECT] (state) {
    state.currentSelectedObjects.push(config.OBJECT_CUT_ID)
  },
  [types.EVT_REMOVE_OBJECT_SELECT] (state, attrs) {
    state.currentSelectedObjects = state.currentSelectedObjects.filter(item => item !== attrs.object)
  },
  [types.EVT_SET_CURRENT_MOVING_POINT] (state, point) {
    state.currentMovingPoint = point
  },
  [types.EVT_SET_CURRENT_ACTIVE_MODIFIER] (state, point) {
    state.currentlyActiveModifier = point
  },
  [types.EVT_RESET_CURRENT_ACTIVE_MODIFIER] (state) {
    state.currentlyActiveModifier = null
  },
  [types.EVT_UPDATE_BOOLEAN_OPERATIONS] (state, payload) {
    state.booleanOperations = payload
  },
  [types.EVT_UPDATE_EDITOR_MODE] (state, payload) {
    for (const key in payload) {
      if (Object.prototype.hasOwnProperty.call(state.mode, key)) {
        Vue.set(state.mode, key, payload[key])
      }
    }
  },
  [types.EVT_REMOVE_PRODUCT_FROM_SELECTION] (state, payload) {
    state.currentSelectedProducts = state.currentSelectedProducts.filter(item => item !== payload.product)
  },
  [types.SET_INPUT_FOCUS] (state, payload) {
    state.inputFocus = payload
  }
}, initState)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
