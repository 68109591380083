<template>
  <nav class="c-nav-head">
    <!-- Visible overall: Bug Report -->
    <template>
      <button-head-nav
        id="sub-nav-button-bug-report"
        :label="$t('headNav.bug')"
        :on-click-action="()=>{return openReportIssue()}"
        :theme="getTheme"
        icon-classes="fas fa-2x fa-bug"
      />
    </template>

    <!-- Visible in project -->

    <template v-if="$router.currentRoute.name === 'Melos-Editor'">
      <button-head-nav
        id="sub-nav-button-help"
        :disabled="blockMenuButtons"
        :label="$t('headNav.help')"
        :on-click-action="()=>{return openHelp()}"
        :theme="getTheme"
        icon-classes="fas fa-2x fa-question-circle"
      />
      <button-head-nav
        id="sub-nav-button-close"
        :classes="['u-ml-size-standard']"
        :disabled="blockMenuButtons"
        :label="$t('headNav.close')"
        :on-click-action="()=>{return doCloseProject('Projects')}"
        :theme="getTheme"
        icon-classes="fas fa-2x fa-sign-out-alt"
      />
    </template>

    <!-- Burger Menu - Visible in project-->

    <button-head-nav
      id="sub-nav-button-head-side-nav"
      :disabled="blockMenuButtons"
      :tooltip-disabled="true"
      :theme="getTheme"
      icon-classes="fas fa-bars"
      @click.native="sideNavClickHandler"
    >
      <template #submenu>
        <HeadSideNav />
      </template>
    </button-head-nav>
  </nav>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as mutationTypes from '@/vuex/mutation-types'

import MixinDialogHandler from '@/mixins/dialogHandler'
import HeadSideNav from './HeadSideNav'
import ButtonHeadNav from '@/components/common/ButtonHeadNav'

export default {
  name: 'HeadNav',
  components: {
    ButtonHeadNav,
    HeadSideNav
  },
  mixins: [MixinDialogHandler],
  data: () => {
    return {
    }
  },
  computed: {
    ...mapState({
      projectMeta: state => state.project.backendMeta
    }),
    ...mapGetters({
      shouldBlockGui: 'events/shouldBlockGui',
      subNavDialogVisible: 'navigation/dialogVisible',
      dialogVisible: 'dialogs/dialogVisible',
      getTheme: 'gui/getTheme'
    }),
    blockMenuButtons () {
      return this.shouldBlockGui || this.subNavDialogVisible || this.dialogVisible
    }
  },
  methods: {
    ...mapMutations({
      setSideNav: 'navigation/' + mutationTypes.HEADNAV_SET_SIDENAV
    }),
    ...mapActions({
      resetToInitialState: 'history/resetToInitialState',
      navCloseProject: 'navigation/closeProject',
      projectHasChanges: 'history/projectHasChanges'
    }),
    sideNavClickHandler (e) {
      e.preventDefault()
      this.setSideNav('editor')
    },
    /**
     * Opens help dialog
     */
    openHelp () {
      this.$_mx_dialogHandler_openDialog({ dialog: 'helpPage', onOff: true })
    },
    /**
     * Opens bug report dialog
     */
    openReportIssue () {
      this.$_mx_dialogHandler_openDialog({ dialog: 'bugReport', onOff: true })
    },
    /**
     * Checks if the project has changes and open a dialog or close the project
     * @returns {Promise<void>}
     */
    async doCloseProject (route) {
      const changes = await this.projectHasChanges()
      if (this.$router.currentRoute.name === 'Melos-Editor' && changes) {
        this.$_mx_dialogHandler_openDialog({
          dialog: 'closeProject',
          onOff: true,
          dialogProps: { route: route }
        })
      } else {
        this.navCloseProject(route)
        await this.$router.push({ name: route })
      }
    }
  }

}
</script>
