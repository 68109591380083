<template>
  <g
    class="c-object__default-menu"
    :transform="`translate( ${coordinates.x} ${coordinates.y} )
                    scale(${mx_math_handleElementsOnScale(1)} ${mx_math_handleElementsOnScale(1)})`"
  >
    <rect
      class="c-object__default-menu-bkg"
      width="80"
      height="45"
      rx="6"
    />
    <g>
      <g
        class="c-object__default-menu-button"
        @click="accept"
      >
        <rect
          height="25"
          width="30"
          x="10"
          y="10"
        />
        <path d="M20.68,32.44l-9-9a1.39,1.39,0,0,1,0-2l2-2a1.39,1.39,0,0,1,2,0l6.08,6.07,13-13a1.39,1.39,0,0,1,2,0l2,2a1.37,1.37,0,0,1,0,2l-16,16a1.39,1.39,0,0,1-2,0Z" />
      </g>
      <g
        class="c-object__default-menu-button"
        @click="reject"
      >
        <rect
          height="25"
          width="25"
          x="45"
          y="10"
        />
        <path d="M60.61,22.33l5.87-5.87a1.85,1.85,0,0,0,0-2.61l-1.3-1.31a1.85,1.85,0,0,0-2.61,0l-5.88,5.87-5.87-5.87a1.85,1.85,0,0,0-2.61,0L46.9,13.85a1.85,1.85,0,0,0,0,2.61l5.88,5.87L46.9,28.21a1.85,1.85,0,0,0,0,2.61l1.31,1.3a1.85,1.85,0,0,0,2.61,0l5.87-5.87,5.88,5.87a1.85,1.85,0,0,0,2.61,0l1.3-1.3a1.85,1.85,0,0,0,0-2.61Z" />
      </g>
    </g>
  </g>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import MixinMath from '@/mixins/math'
import MixinOperations from '@/mixins/operations'
import MixinTrackState from '@/mixins/trackstate'
import * as mutationTypes from '@/vuex/mutation-types'

export default {
  name: 'ObjectCutMenu',
  mixins: [
    MixinMath,
    MixinOperations,
    MixinTrackState
  ],
  computed: {
    ...mapState({
      obj: state => state.project.objects.cutOut
    }),
    coordinates () {
      let x = 0
      let y = 0
      const points = this.obj.points
      if (points.length <= 4) { // it's circle
        x = points[1].x
        y = points[1].y
      } else { // it's at least triangle or something more complicated
        for (let i = 1; i < points.length - 1; i++) { // take all but first and last point
          x += points[i].x
          y += points[i].y
        }
        x = x / (points.length - 2)
        y = y / (points.length - 2)
      }

      return {
        x: x - this.mx_math_handleElementsOnScale(40),
        y: y - this.mx_math_handleElementsOnScale(22)
      }
    }
  },
  methods: {
    ...mapActions({
      removeObjectCut: 'project/removeObjectCut',
      clearObjectSelection: 'events/clearObjectSelection'
    }),
    ...mapMutations({
      setEventMode: 'events/' + mutationTypes.EVT_UPDATE_EDITOR_MODE
    }),
    accept () {
      this.mx_operations_predefinedBooleanOperation(this.obj)
      this.mx_trackstate_endStateTransaction()
    },
    reject () {
      this.setEventMode({ cut: false })
      this.clearObjectSelection()
      this.removeObjectCut()
      this.mx_trackstate_breakStateTransaction()
    }
  }
}
</script>
