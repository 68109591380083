<template>
  <div class="c-utility-file">
    <input
      id="utility-file-upload"
      :accept="accept"
      class="c-single-file-upload__input--hide"
      type="file"
      @change="processFile"
    >
    <label
      ref="inputLabel"
      class="c-button-base c-button-base--color-blue c-button-base--size-s u-ml-size-0"
      for="utility-file-upload"
    >
      {{ buttonText }}
    </label>
    <div
      :class="{hasFile: 'c-single-file-upload__drop-area-container--image-loaded'}"
      class="c-single-file-upload__drop-area-container"
      style="width: 250px; height: 250px"
      @dragover="handleDragOver($event)"
      @drop="processFile($event)"
      @click="$refs.inputLabel.click()"
    >
      <div class="c-single-file-upload__drop-area">
        <img
          v-if="svg !== null"
          :src="getSVGPreview"
        >
        <p
          v-else
          v-html="getDefaultText.main"
        />
      </div>
    </div>
  </div>
</template>

<script>
/**
 * Drop area for files
 * @displayName Utility drop area for files
 */
export default {
  name: 'UtilityFile',
  props: {
    /**
     * String list of file types which the field accepts
     * e.g. image/png,image/jpeg,...
     * complete list of standard media types: http://www.iana.org/assignments/media-types/media-types.xhtml
     * @values file_extension, audio/*, video/*, image/*, media_type
     */
    accept: {
      type: String,
      required: false,
      default: 'image/*'
    }
  },
  data: () => {
    return {
      svg: null
    }
  },
  computed: {
    hasFile () {
      return this.svg !== null
    },
    getSVGPreview () {
      return URL.createObjectURL(this.svg)
    },
    buttonText () {
      if (this.hasFile) {
        return this.$i18n.messages[this.$i18n.locale].components.singleFileUpload.addImage.change
      } else {
        return this.$i18n.messages[this.$i18n.locale].components.singleFileUpload.addImage.choose
      }
    },
    getDefaultText () {
      const textsPath = 'components.fileUpload'
      const main = this.$i18n.t(`${textsPath}.addImage.chooseFile`)

      return { main }
    }
  },
  methods: {
    /**
     * Gets called if a file is added.
     * Handles events by file type.
     * @public
     */
    processFile (e) {
      e.stopPropagation()
      e.preventDefault()

      let file = null
      if (e.target.files !== undefined && e.target.files.length > 0) {
        file = e.target.files[0]
      }

      if (e.dataTransfer !== undefined && e.dataTransfer.files.length > 0) {
        file = e.dataTransfer.files[0]
      }

      if (file !== null && file instanceof File) {
        switch (file.type) {
          case 'image/svg+xml':
            this.svg = file
            this.$emit('svgAdded', file)
            break
        }
      }
    },
    /**
     * Handles the drag over element event
     */
    handleDragOver (e) {
      e.stopPropagation()
      e.preventDefault()
    }
  }
}
</script>
