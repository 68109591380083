import Paper from 'paper'
import moment from 'moment'
import { mapGetters } from 'vuex'
import config from '@/config'

export default {
  name: 'ExportSVGMixin',
  data: () => {
    return {}
  },
  computed: {
    ...mapGetters({
      projectMeta: 'project/backendMeta',
      getObjects: 'project/objects/getAll'
    })
  },
  methods: {
    /**
         * Export project drawing as svg (paperJS)
         * @public
         */
    mx_svg_project () {
      const objects = this.getObjects

      if (objects.length > 0) {
        const group = new Paper.Group()

        // combine objects
        objects.forEach(object => {
          const hasChildren = object.hasChildren()

          let cp = null
          if (object.type === config.objects.types.CIRCLE) {
            const props = object.getCircleProperties()
            cp = new Paper.CompoundPath(new Paper.Path.Circle({
              center: [props.middle.x, props.middle.y],
              radius: props.radius
            }))
          } else {
            cp = new Paper.CompoundPath(object.d(true))
          }

          cp.fillRule = 'evenodd'

          // if object has children, subtract children from base cp
          if (hasChildren) {
            object.children.forEach(child => {
              const childPath = new Paper.Path(child.d())
              childPath.fillRule = 'evenodd'
              cp = cp.subtract(childPath)
            })
          }

          cp.strokeColor = 'black'
          group.addChild(cp)
        })

        // set layer to position 0,0
        const delta = new Paper.Point(
          0 - group.bounds.x,
          0 - group.bounds.y
        )

        group.translate(delta)

        // create project scope with layer bounds
        const projectScope = new Paper.PaperScope().setup(new Paper.Size(group.bounds.width, group.bounds.height))
        projectScope.project.activeLayer.addChildren(group.getItems())

        const svg = projectScope.project.exportSVG({
          asString: true
        })

        this.$_download_svg(svg)
      }
    },
    /**
         * Download the project
         *
         * @param value
         */
    $_download_svg (value) {
      const blob = new Blob([value])
      const el = document.createElement('a')
      const name = (this.projectMeta.name !== null) ? this.projectMeta.name.replace(/[^a-z0-9]/gi, '_').toLowerCase() : 'default_project'

      const today = moment()

      el.download = `${name}_${today.format('YYYY-MM-DD')}.svg`
      el.href = URL.createObjectURL(blob)
      el.click()
      el.remove()
    }
  }
}
