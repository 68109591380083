<template>
  <div :class="['c-tabs', classes]">
    <button
      v-for="(tab, index) in tabs"
      :key="index"
      :class="{'c-tabs__tab--active': index === currentTab}"
      :style="tabWidth"
      class="c-tabs__tab"
      @click="tabClicked(index)"
    >
      {{ tab }}
    </button>
  </div>
</template>

<script>

/**
 * The Tabs Component.
 * @displayName Tabs
 */

export default {
  name: 'Tabs',
  props: {
    /**
     * Tabs names array
     */
    tabs: {
      type: Array,
      required: true
    },
    /**
     * Current tab number (0 to n)
     */
    currentTab: {
      type: [Number, String],
      required: false,
      default: 0
    },
    /**
     * Additional CSS classes for the whole component
     */
    classes: {
      type: [String, Array],
      required: false,
      default: ''
    },
    /**
     * Defines custom tabs width (%)
     */
    styleTabsWidth: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => {
    return {}
  },
  computed: {
    tabWidth () {
      const width = this.styleTabsWidth ? this.styleTabsWidth : 100 / this.tabs.length

      return 'width: calc(' + width + '% - 1px);'
    }
  },
  methods: {
    tabClicked (payload) {
      /**
       * Triggers when the tab is clicked
       * @property {number} payload - numeric ID of the clicked tab
       */
      this.$emit('tabClicked', payload)
    }
  }
}
</script>
