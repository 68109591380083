import * as types from '@/vuex/mutation-types'

const state = {
  raster: 1.0,
  show: true,
  snap: false
}

const getters = {}

const actions = {}

const mutations = {
  [types.TOGGLE_GRID_SHOW] (state) {
    state.show = !state.show
  },
  [types.TOGGLE_GRID_SNAP] (state) {
    state.snap = !state.snap
  },
  [types.SET_GRID_RASTER] (state, raster) {
    state.raster = raster
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
