<script>
import InputField from '@/components/common/InputField'
import MixinValidate from '@/mixins/validate'
export default {
  name: 'TurfDetailsRenderer',
  components: { InputField },
  mixins: [
    MixinValidate
  ],
  props: {
    formData: {
      type: Object,
      required: true
    },
    formFieldTypes: {
      type: Object,
      required: true
    },
    isCreateForm: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data () {
    return {
      fields: []
    }
  },
  beforeMount () {
    /* Recurrent preparing fields; starting from the beginning of object structure (path: '') */
    this.prepareFields(this.formData, this.formFieldTypes, '')
  },
  methods: {
    prepareFields (values, types, path) {
      for (const [k, v] of Object.entries(types)) {
        if (Object.prototype.hasOwnProperty.call(v, '_value')) {
          this.isCreateForm
            ? this.fields.push(
              <input-field
                id={ 'create-turf-details' + path.replace('.', '-') + '-' + k }
                v-model={ values[k]._value }
                is-store-mode={false}
                label={this.$t(`dialogs.createTurf.details${path}.${v._name}`)}
                required={true}
                validate={(v) => { return this.mx_validate_validateNotEmpty(v) }}
                style-input-width="240px"
                type={v._type}
                onChange={this.emitChangeField}
                onRegister={this.emitRegisterField}
              />
            )
            : this.fields.push(
              <input-field
                id={ 'update-turf-details' + path.replace('.', '-') + '-' + k }
                v-model={ values[k] }
                is-store-mode={false}
                label={this.$t(`dialogs.updateTurf.details${path}.${v._name}`)}
                required={true}
                validate={(v) => { return this.mx_validate_validateNotEmpty(v) }}
                style-input-width="240px"
                type={v._type}
                onChange={this.emitChangeField}
                onRegister={this.emitRegisterField}
              />
            )
        /* if an object doesn't contain '_value' it's only a container for other fields;
        * we can ignore it and use as a starting point for recurrent usage of this function */
        } else {
          this.prepareFields(values[k], types[k], `${path}.${k}`)
        }
      }
    },
    emitRegisterField (payload) {
      this.$emit('register-field', payload)
    },
    emitChangeField (payload) {
      this.$emit('change-field', payload)
    }
  },
  render: function () {
    if (this.fields.length) {
      return this.$createElement('div', this.fields.map(function (field) {
        return field
      }))
    } else {
      return this.$createElement('div', 'No details found.')
    }
  }
}
</script>
