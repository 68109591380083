import { apiMethods, specialSubProductRoutes } from '@/helper/apiHelper'
import downloadjs from 'downloadjs'

const defaultState = () => {
  return {}
}

const state = defaultState()

const getters = {}

const actions = {
  async getByType ({ rootState, dispatch }, type) {
    await apiMethods.request('get', `${process.env.VUE_APP_API_URL}/api/${type}/csv`, null, rootState.backendAPI.login.token)
      .then(response => {
        downloadjs(new Blob([response.data]), `${type}.csv`, 'text/csv')
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  },
  async updateByCsvAndType ({ rootState, dispatch, commit }, data) {
    const formData = new FormData()
    formData.append('csv', data.file)

    await apiMethods.request('post', `${process.env.VUE_APP_API_URL}/api/${data.type}/csv`, formData, rootState.backendAPI.login.token, 'multipart/form-data')
      .then(response => {
        const routeToCall = specialSubProductRoutes[data.type]
        commit(`backendAPI/product/${routeToCall.module}/${routeToCall.mutations.list}`, response.data.data, { root: true })
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
      })
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
