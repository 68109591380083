<template>
  <svg
    id="cut-all-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M33.56,11.07a7.11,7.11,0,0,0-7.12,1.78,7.22,7.22,0,0,0-1.74,2.84,7,7,0,0,0-.35,2.41l-2.86-.89L21,15.46,17.58,4.6A4.77,4.77,0,0,0,12.1,1.5a.39.39,0,0,0-.3.49l3.66,11.76L16,15.51,2.48,11.31a.37.37,0,0,0-.48.3,4.73,4.73,0,0,0,3.1,5.47L17.71,21l.89,2.85a7.15,7.15,0,0,0-7,9.22,7,7,0,0,0,8.82,4.56,7.14,7.14,0,0,0,4.67-8.88h0L23.2,22.7l6,1.88v0a7.13,7.13,0,0,0,8.88-4.66A7,7,0,0,0,33.56,11.07ZM20.85,33.49a3.68,3.68,0,1,1,0-5.24A3.71,3.71,0,0,1,20.85,33.49ZM34,20.35a3.68,3.68,0,1,1,0-5.23A3.69,3.69,0,0,1,34,20.35Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CutAllIcon'
}
</script>
