import Vue from 'vue'
import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'

import * as types from '@/vuex/backend-api-mutation-types'
import models from '@/helper/api/models'

const productTypes = {
  turf: 'turf',
  epdm: 'epdm'
}

const csvTypes = {
  adhesive: 'adhesive',
  infill: 'infill',
  pad: 'pad',
  pedestal: 'pedestal',
  seamtape: 'seamtape',
  subbase: 'subbase',
  turf: 'turf'
}

const mediaRequestNames = {
  file: 'file',
  image: 'image',
  media_images: 'media_images',
  media_documents: 'media_documents',
  uploads: 'uploads'
}

const underlayStates = {
  old: 'old',
  new: 'new',
  delete: 'delete'
}

const roles = {
  admin: 'USER_ROLE_ADMIN',
  manufacture: 'USER_ROLE_MANUFACTURE',
  distributor: 'USER_ROLE_DISTRIBUTOR',
  employee: 'USER_ROLE_EMPLOYEE',
  routes: {
    admin: 'Groups',
    manufacture: 'Groups',
    distributor: 'Projects',
    employee: 'Projects'
  }
}

/**
 * Default Routes
 *
 * @type {{pedestal: string, infill: string, project: string, login: string, employee: string, distributor: string, logout: string, pad: string, subbase: string, seamtape: string, client: string, location: string, adhesive: string, user: string, group: string}}
 */
const routes = {
  login: process.env.VUE_APP_API_URL + '/oauth/token',
  logout: process.env.VUE_APP_API_URL + '/api/logout',
  group: process.env.VUE_APP_API_URL + '/api/group',
  admin: process.env.VUE_APP_API_URL + '/api/user/admin',
  manufacture: process.env.VUE_APP_API_URL + '/api/user/manufacturer',
  distributor: process.env.VUE_APP_API_URL + '/api/user/distributor',
  employee: process.env.VUE_APP_API_URL + '/api/user/employee',
  user: process.env.VUE_APP_API_URL + '/api/user',
  location: process.env.VUE_APP_API_URL + '/api/location',
  client: process.env.VUE_APP_API_URL + '/api/client',
  project: process.env.VUE_APP_API_URL + '/api/project',
  turf: process.env.VUE_APP_API_URL + '/api/turf',
  infill: process.env.VUE_APP_API_URL + '/api/infill',
  subbase: process.env.VUE_APP_API_URL + '/api/subbase',
  pad: process.env.VUE_APP_API_URL + '/api/pad',
  pedestal: process.env.VUE_APP_API_URL + '/api/pedestal',
  seamtape: process.env.VUE_APP_API_URL + '/api/seamtape',
  adhesive: process.env.VUE_APP_API_URL + '/api/adhesive',
  pdf: process.env.VUE_APP_API_URL + '/api/pdf',
  stats: process.env.VUE_APP_API_URL + '/api/stats',
  template: process.env.VUE_APP_API_URL + '/api/project/template',
  apiUser: process.env.VUE_APP_API_URL + '/api/user/apiuser',
  report: process.env.VUE_APP_API_URL + '/api/contact',
  language: process.env.VUE_APP_API_URL + '/api/user/language'
}

/**
 * Routes for SubProductHandler:
 * - Location Based
 * - Used for List / Updates
 *
 * @type {{pedestal: {mutation: *, route: string, module: string}, infill: {mutation: *, route: string, module: string}, pad: {mutation: *, route: string, module: string}, subbase: {mutation: *, route: string, module: string}, seamtape: {mutation: *, route: string, module: string}, adhesive: {mutation: *, route: string, module: string}}}
 */
const subProductRoutes = {
  turf: {
    module: 'turf',
    route: 'turfs',
    mutation: types.PRODUCT_TURF_SET_TURFS
  },
  infill: {
    module: 'infill',
    route: 'infills',
    mutation: types.PRODUCT_INFILL_SET_INFILLS
  },
  subbase: {
    module: 'subbase',
    route: 'subbases',
    mutation: types.PRODUCT_SUBBASE_SET_SUBBASES
  },
  pad: {
    module: 'pad',
    route: 'pads',
    mutation: types.PRODUCT_PAD_SET_PADS
  },
  pedestal: {
    module: 'pedestal',
    route: 'pedestals',
    mutation: types.PRODUCT_PEDESTAL_SET_PEDESTALS
  },
  seamtape: {
    module: 'seamtape',
    route: 'seamtapes',
    mutation: types.PRODUCT_SEAMTAPE_SET_SEAMTAPES
  },
  adhesive: {
    module: 'adhesive',
    route: 'adhesives',
    mutation: types.PRODUCT_ADHESIVE_SET_ADHESIVES
  }
}

/**
 *
 * @type {{pedestal: {route: string, mutations: {update: string, list: string}, module: string}, infill: {route: string, mutations: {update: string, list: string}, module: string}, pad: {route: string, mutations: {update: string, list: string}, module: string}, subbase: {route: string, mutations: {update: string, list: string}, module: string}, seamtape: {route: string, mutations: {update: string, list: string}, module: string}, adhesive: {route: string, mutations: {create: string, update: string, list: string}, module: string}}}
 */
const specialSubProductRoutes = {
  turf: {
    module: 'turf',
    route: routes.turf,
    allowed: [roles.manufacture],
    mutations: {
      list: types.PRODUCT_TURF_SET_SPECIAL_TURFS,
      update: types.PRODUCT_TURF_UPDATE_SPECIAL_TURF
    }
  },
  infill: {
    module: 'infill',
    route: routes.infill,
    allowed: [roles.manufacture, roles.distributor],
    mutations: {
      list: types.PRODUCT_INFILL_SET_SPECIAL_INFILLS,
      update: types.PRODUCT_INFILL_UPDATE_SPECIAL_INFILL
    }
  },
  subbase: {
    module: 'subbase',
    route: routes.subbase,
    allowed: [roles.manufacture, roles.distributor],
    mutations: {
      list: types.PRODUCT_SUBBASE_SET_SPECIAL_SUBBASES,
      update: types.PRODUCT_SUBBASE_UPDATE_SPECIAL_SUBBASE
    }
  },
  pad: {
    module: 'pad',
    route: routes.pad,
    allowed: [roles.manufacture, roles.distributor],
    mutations: {
      list: types.PRODUCT_PAD_SET_SPECIAL_PADS,
      update: types.PRODUCT_PAD_UPDATE_SPECIAL_PAD
    }
  },
  pedestal: {
    module: 'pedestal',
    route: routes.pedestal,
    allowed: [roles.manufacture, roles.distributor],
    mutations: {
      list: types.PRODUCT_PEDESTAL_SET_SPECIAL_PEDESTALS,
      update: types.PRODUCT_PEDESTAL_UPDATE_SPECIAL_PEDESTAL
    }
  },
  seamtape: {
    module: 'seamtape',
    route: routes.seamtape,
    allowed: [roles.manufacture, roles.distributor],
    mutations: {
      list: types.PRODUCT_SEAMTAPE_SET_SPECIAL_SEAMTAPES,
      update: types.PRODUCT_SEAMTAPE_UPDATE_SPECIAL_SEAMTAPE
    }
  },
  adhesive: {
    module: 'adhesive',
    route: routes.adhesive,
    allowed: [roles.manufacture, roles.distributor],
    mutations: {
      list: types.PRODUCT_ADHESIVE_SET_SPECIAL_ADHESIVES,
      create: types.PRODUCT_ADHESIVE_ADD_SPECIAL_ADHESIVE,
      update: types.PRODUCT_ADHESIVE_UPDATE_SPECIAL_ADHESIVE
    }
  }
}

const _compileIncomingModel = function (obj) {
  for (const [k, v] of Object.entries(obj)) {
    if (k === '_type') {
      obj.__type = obj[k]
      switch (v) {
        case 'FLOAT':
        case 'INTEGER':
          obj[k] = 'number'
          obj._value = null
          break
        case 'TEXT':
        case 'VARCHAR':
          obj[k] = 'text'
          obj._value = ''
          break
        case 'JSON': {
          // pick the structure of '_fields' and move it to the object (one level higher)
          const fields = obj._fields
          // delete all other fields
          for (const key of Object.keys(obj)) {
            delete obj[key]
          }
          Object.assign(obj, fields)
          break
        }
        default:
          Vue.prototype.$devLog.log('new type needs to be added to _compileIncomingModel in apiHelper')
          break
      }
    } else if (typeof v === 'object') {
      _compileIncomingModel(v)
    }
  }
  return obj
}

const apiMethods = {
  /**
   * Update store object properties
   *  - VUEX State Objects
   *
   * @param original
   * @param update
   */
  updateStoreObjectProperties (original, update) {
    for (const key in update) {
      if (Object.prototype.hasOwnProperty.call(original, key)) {
        Vue.set(original, key, update[key])
      }
    }
  },
  /**
   * Get default route for user role
   *
   * @param {String} role
   * @returns {String}
   */
  getDefaultRoute (role) {
    const key = _.invert(roles)[role]
    return roles.routes[key]
  },
  /**
   * Check entered route is allowed for user by role
   *
   * @param {String} role
   * @param {[]} allowedFor
   * @returns {boolean}
   */
  isRouteAllowed (role, allowedFor) {
    return allowedFor.includes(role)
  },
  /**
   * Convert Date
   *
   * @param {String} date
   * @param {String} format
   * @returns {String}
   */
  convertDate (date, format = 'YYYY-MM-DD HH:mm:ss') {
    return moment(date).format(format)
  },
  /**
   * Get Model for Object creation
   *
   * @param type
   * @returns {null|Object}
   */
  getModel (type) {
    if (Object.prototype.hasOwnProperty.call(models, type)) {
      return _.cloneDeep(models[type])
    }

    return null
  },
  /**
   * Send Request by given parameters
   *
   * @param method
   * @param url
   * @param data
   * @param token
   * @param contentType
   * @returns {AxiosPromise}
   */
  request (method, url, data, token, contentType = 'application/json') {
    return axios({
      method: method,
      url: url,
      data: data,
      headers: {
        'Content-Type': contentType,
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      }
    })
  },
  /**
   * Requests given product model
   *
   * @param {string} route - URL
   * @param token
   * @returns {Object}
   */
  async getServerProductModel (route, token) {
    return await apiMethods.request('options', route, null, token)
      .then((response) => {
        return _compileIncomingModel(response.data.data.fields)
      }).catch((error) => {
        Vue.prototype.$devLog.log(error)
      })
  },
  /**
   * Compiles an object to a structure accepted on the sever side
   *
   * @param {Object} obj
   * @returns {Object}
   */
  compileOutgoingProduct (obj) {
    // ignore if not an object
    if (obj && typeof obj === 'object') {
      // if an endpoint (contains '_value') override it with its '_value'
      // if empty override it with 'null' (later important!)
      if (Object.prototype.hasOwnProperty.call(obj, '_value')) {
        switch (obj._value) {
          case null:
          case '':
            return null
          default:
            return obj._value
        }
      } else {
        const newVal = {}
        for (const [k] of Object.entries(obj)) {
          // make the same for subfields
          const subField = apiMethods.compileOutgoingProduct(obj[k])
          // save a subfield in the new object if it's not 'null' (saved before)
          // or '{}' (happens if all sub-objects were empty)
          if (subField !== null &&
            !(Object.keys(subField).length === 0 && subField.constructor === Object)) {
            newVal[k] = subField
          }
        }
        return newVal
      }
    }
  },
  /**
   * Files upload
   *
   * @param {String} route
   * @param {{files: (File|File[]), name: String} | {files: (File|File[]), name: String}[]} data
   * @param {String} token
   * @param dispatch
   * @returns {Promise<T>}
   */
  async uploadFiles (route, token, dispatch, data) {
    const formData = new FormData()
    const fileStructures = _.isArray(data) ? data : [data]

    for (const fileStructure of fileStructures) {
      const name = fileStructure.name
      const files = _.isArray(fileStructure.files) ? fileStructure.files : [fileStructure.files]

      for (const file of files) {
        formData.append(`${name}[]`, file)
      }
    }
    return await apiMethods.request('post', route, formData, token, 'multipart/form-data')
      .then(response => {
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  },
  /**
   * Files delete
   *
   * @param {String} route
   * @param {Number[]} fileIds
   * @param {String} token
   * @param dispatch
   * @returns {Promise<T>}
   */
  async deleteFiles (route, token, dispatch, fileIds) {
    return await apiMethods.request('delete', route, { ids: fileIds }, token)
      .then(response => {
        return response.data.data
      }).catch(error => {
        dispatch('backendAPI/error', error, { root: true })
        return null
      })
  }
}

export { routes, subProductRoutes, specialSubProductRoutes, roles, productTypes, csvTypes, apiMethods, mediaRequestNames, underlayStates }
