<template>
  <svg
    id="draw-tri-icon"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="u-fill-w"
      d="M35.54,34.8H4.87a2,2,0,1,1,0-4H35.54a2,2,0,1,1,0,4Z"
    />
    <path
      class="u-fill-w"
      d="M35.54,34.8a2,2,0,0,1-1.73-1L18.27,7.22a2,2,0,0,1,3.46-2L37.27,31.8a2,2,0,0,1-.72,2.73A1.94,1.94,0,0,1,35.54,34.8Z"
    />
    <path
      class="u-fill-w"
      d="M4.87,34.8a2,2,0,0,1-1-.26,2,2,0,0,1-.75-2.72L18.26,5.23a2,2,0,0,1,3.48,2L6.61,33.79A2,2,0,0,1,4.87,34.8Z"
    />
    <circle
      class="u-fill-b1"
      cx="35"
      cy="32.8"
      r="5"
    />
    <circle
      class="u-fill-b1"
      cx="5"
      cy="32.8"
      r="5"
    />
  </svg>
</template>

<script>
export default {
  name: 'DrawTriIcon'
}
</script>
