<template>
  <div class="c-layout u-bgcolor-s3">
    <page-header />
    <div class="c-layout__content u-bgcolor-s3">
      <div class="o-grid o-grid--center u-mt-size-20">
        <div class="o-grid__col u-5/12 u-text-center login-min-width">
          <div class="o-grid o-grid--center">
            <div class="o-grid__col u-text-center">
              <div class="c-typo">
                <h1 class="c-typo__h1 u-color-w">
                  {{ $t('login.title') }}
                </h1>
                <p
                  v-if="!resetPassForm"
                  class="c-typo__text c-typo__text--18 u-color-w"
                >
                  {{ $t('login.login_desc') }}
                </p>
                <p
                  v-else
                  class="c-typo__text c-typo__text--18 u-color-w"
                >
                  {{ $t('login.reset_desc') }}
                </p>
                <div class="login-buttons-panel">
                  <!--                                <div class="c-button-base-group">
                                        <button class="c-button-base-group__item c-button-base-group__item&#45;&#45;color-green">{{
                                            $t('login.register') }}
                                        </button>
                                        <button class="c-button-base-group__item c-button-base-group__item&#45;&#45;color-green c-button-base-group__item&#45;&#45;active">
                                            {{ $t('login.login') }}
                                        </button>
                                    </div>-->
                  <div class="c-form">
                    <form
                      v-if="!resetPassForm"
                      action=""
                      method="post"
                      @submit.prevent="doLogin"
                    >
                      <input
                        v-model="credentials.username"
                        :placeholder=" $t('login.email') "
                        autocomplete="username"
                        class="c-input-field c-input-field--color-green"
                        type="text"
                      >
                      <input
                        v-model="credentials.password"
                        :placeholder=" $t('login.pass') "
                        autocomplete="current-password"
                        class="c-input-field c-input-field--color-green"
                        type="password"
                      >

                      <button-base
                        id="login-page-button-login"
                        :label="$t('login.login')"
                        style-color="green"
                        style-size="xl"
                        type="submit"
                      />

                      <div
                        v-if="responseMessage"
                        class="u-mb-size-20"
                      >
                        <p :class="responseMessage.error ? 'u-color-o' : 'u-color-g1'">
                          {{
                            responseMessage.message }}
                        </p>
                      </div>
                      <p
                        class="u-color-g1"
                        @click="switchScreen"
                      >
                        {{ $t('login.toReset') }}
                      </p>
                    </form>

                    <form
                      v-if="resetPassForm"
                      action=""
                      method="post"
                      @submit.prevent="doResetPassword"
                    >
                      <input
                        v-model="credentials.username"
                        :placeholder=" $t('login.email') "
                        autocomplete="username"
                        class="c-input-field c-input-field--color-green"
                        type="text"
                      >

                      <button-base
                        id="login-page-button-reset"
                        :label="$t('login.reset')"
                        style-color="green"
                        style-size="xl"
                        type="submit"
                      />

                      <div
                        v-if="responseMessage"
                        class="u-mb-size-20"
                      >
                        <p :class="responseMessage.error ? 'u-color-o' : 'u-color-g1'">
                          {{
                            responseMessage.message }}
                        </p>
                      </div>
                      <p
                        class="u-color-g1"
                        @click="switchScreen"
                      >
                        {{ $t('login.toLogin') }}
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { apiMethods } from '@/helper/apiHelper'

import PageHeader from './partials/Header'
import ButtonBase from '@/components/common/ButtonBase'

export default {
  name: 'Login',
  components: {
    PageHeader,
    ButtonBase
  },
  data () {
    return {
      credentials: {
        username: null,
        password: null
      },
      resetPassForm: false,
      responseMessage: null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.credentials = {
        username: null,
        password: null
      }
    })
  },
  computed: {
    ...mapGetters({
      userRole: 'backendAPI/user/getRole'
    })
  },
  methods: {
    ...mapActions({
      login: 'backendAPI/login',
      resetPassword: 'backendAPI/user/resetPassword'
    }),
    switchScreen () {
      this.resetPassForm = !this.resetPassForm
      this.responseMessage = null
    },
    async doLogin () {
      if (this.credentials.password && this.credentials.username) {
        const response = await this.login(this.credentials)

        if (response === true) {
          await this.$router.push({ name: apiMethods.getDefaultRoute(this.userRole) })
        } else if (typeof response === 'object') {
          this.responseMessage = response
        }
      }
    },
    async doResetPassword () {
      if (this.credentials.username) {
        const response = await this.resetPassword(this.credentials.username)

        if (typeof response === 'object') {
          this.responseMessage = response
        }
      }
    }
  }
}
</script>

<style scoped>

    .login-min-width {
        min-width: 600px;
    }

    .login-buttons-panel {
        max-width: 700px;
        display: inline-table;
    }
</style>
