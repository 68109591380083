<template>
  <backend>
    <template #dialogs />
    <template #content>
      <div class="o-grid o-grid--center u-mt-size-60">
        <div class="o-grid__col u-10/12">
          <location-cat-menu
            :current-cat="cat"
            :current-sub="sub"
            :location-id="id"
          />
        </div>
      </div>
      <div class="o-grid o-grid--center u-mt-size-30">
        <div class="o-grid__col u-10/12">
          <!-- Products -->
          <div
            v-if="cat === 'products'"
            id="location-materials-products"
          >
            <turf v-if="sub === 'turf'" />
            <epdm v-if="sub === 'epdm'" />
          </div>

          <!-- Subbases -->
          <div
            v-if="cat === 'subbases'"
            id="location-materials-subbases"
          >
            <Others v-if="sub === 'others'" />
            <Pedestals v-if="sub === 'pedestals'" />
            <Pads v-if="sub === 'pads'" />
          </div>

          <!-- Infills -->
          <div
            v-if="cat === 'infills'"
            id="location-materials-infills"
          >
            <Infills />
          </div>

          <!-- Consumables -->
          <div
            v-if="cat === 'consumables'"
            id="location-materials-consumables"
          >
            <Seamtape v-if="sub === 'seamtape'" />
            <Adhesives v-if="sub === 'adhesives'" />
          </div>
        </div>
      </div>
    </template>
  </backend>
</template>

<script>
import MixinDialogHandler from '@/mixins/dialogHandler'

import Backend from '@/views/Backend'
import LocationCatMenu from '@/components/backend/locations/materials/partials/LocationCatMenu'

import Turf from '@/components/backend/locations/materials/partials/products/Turfs'
import Epdm from '@/components/backend/locations/materials/partials/products/EPDM'

import Pads from '@/components/backend/locations/materials/partials/subbases/Pads'
import Pedestals from '@/components/backend/locations/materials/partials/subbases/Pedestals'
import Others from '@/components/backend/locations/materials/partials/subbases/Subbase'

import Infills from '@/components/backend/locations/materials/partials/infills/Infills'

import Seamtape from '@/components/backend/locations/materials/partials/consumables/Seamtape'
import Adhesives from '@/components/backend/locations/materials/partials/consumables/Adhesives'

/**
 * @displayName Location Materials Component
 */
export default {
  name: 'LocationMaterials',
  components: {
    LocationCatMenu,
    Backend,

    Turf,
    Epdm,

    Pads,
    Pedestals,
    Others,

    Infills,

    Seamtape,
    Adhesives
  },
  mixins: [MixinDialogHandler],
  props: {
    id: {
      type: Number,
      required: true
    },
    cat: {
      type: String,
      required: true
    },
    sub: {
      type: String,
      required: true
    }
  }
}
</script>
